import moment from 'moment';
import { Switch } from 'antd';
import { toLower, upperFirst } from 'lodash';
import { GiPencil } from 'react-icons/gi';
import { ACTION, PERMISSION } from 'constant';

import { EMPTY } from 'constant';

const userManagementColumns = (
  url,
  isUserHasPermissionInFeature,
  sortBy,
  orderBy,
  onEdit,
  onStatusChange
) => [
  {
    dataIndex: 'username',
    title: 'Username',
    sorter: true,
    sortOrder: sortBy === 'username' ? orderBy : null,
  },
  {
    dataIndex: 'email',
    title: 'Email',
    sorter: true,
    sortOrder: sortBy === 'email' ? orderBy : null,
  },
  {
    dataIndex: 'roles',
    title: 'Role',
    sorter: true,
    sortOrder: sortBy === 'roles' ? orderBy : null,
    render: (text) => (
      <div className='flex flex-row'>
        {text.map((v, index) => (
          <span className='px-2 py-1 mr-2 font-bold rounded-lg bg-[#2D3D5A20] text-[#2D3D5A]' key={index}>
            {
              upperFirst(toLower(v.name))
            }
          </span>
        ))}
      </div>  
    )
  },
  {
    dataIndex: 'company',
    title: 'Company',
    sorter: true,
    sortOrder: sortBy === 'company' ? orderBy : null
  },
  {
    dataIndex: 'created_at',
    title: 'Created Date',
    sorter: true,
    sortOrder: sortBy === 'created_at' ? orderBy : null,
    render: (text) => (
      <span>
        {
          text
            ? moment(text).format('DD MMM YYYY')
            : EMPTY.RECORD
        }
      </span>
    )
  },
  {
    dataIndex: 'is_active',
    title: 'Status',
    sorter: true,
    sortOrder: sortBy === 'status' ? orderBy : null,
    render: (text, record) => (
      <>
        {
          isUserHasPermissionInFeature(url, PERMISSION.UPDATE) && (
            <Switch checked={text} onChange={() => onStatusChange(record.id, text)} />
          )
        }
      </>
    )
  },
  {
    dataIndex: 'type',
    title: 'User Type',
    sorter: true,
    sortOrder: sortBy === 'type' ? orderBy : null,
    render: (text) => (
      <span className={`font-bold italic text-[${toLower(text).includes('internal') ? '#462F92' : '#D91C5C'}]`}>
        {text}
      </span>
    )
  },
  {
    title: 'Action',
    render: (text, record) => (
      <div className="flex flex-row justify-center items-center gap-x-3">
        {
          isUserHasPermissionInFeature(url, PERMISSION.UPDATE) && (
            <div
              className="rounded-full bg-[#2D3D5A] cursor-pointer"
              onClick={() => onEdit(record.id, ACTION.EDIT)}
            >
              <GiPencil
                size="24px"
                className="p-1 text-white"
              />
            </div>
          )
        }
      </div>
    )
  }
];

export { userManagementColumns };