import { InputNumber, Checkbox, Tooltip } from 'antd';
import moment from 'moment';
import { FiEye, FiUpload } from 'react-icons/fi';

import { EMPTY } from 'constant';

import {
  formatWithEnLocale,
  parseFromEnLocale,
  preventNonNumber
} from 'utils/CurrencyFormatter';

const generateQualityChecklistColumns = (
  isDetailMode,
  onUpload
) => [
  {
    dataIndex: 'number',
    title: 'Drilling Plan Version',
    width: '130px',
    onCell: (record) => ({
      /**
       * @param {string} record.number
       * because we set any `category` to `record.number`
       * so, any NaN `record.number` will take `question` column
       * */
      colSpan: isNaN(Number(record.number))
        ? 3
        : 1
    }),
    render: (text, record) => (
      <>
        {
          isNaN(Number(record.number))
            ? (
              <>{record.number}</>
            )
            : (
              <>{`V${record.number}`}</>
            )
        }
      </>
    )
  },
  {
    dataIndex: 'month',
    title: 'Month',
    width: '100px',
    onCell: (record) => ({
      /**
       * if `record.number` is NaN we make `question` column zero
       * to make room for `record.number`
       * */
      colSpan: isNaN(Number(record.number))
        ? 0
        : 1
    })
  },
  {
    dataIndex: 'updated_date',
    title: 'Updated Date',
    width: '150px',
    onCell: (record) => ({
      /**
       * if `record.number` is NaN we make `question` column zero
       * to make room for `record.number`
       * */
      colSpan: isNaN(Number(record.number))
        ? 0
        : 1
    }),
    render: (text, record, index) => (
      <>
        {
          isNaN(Number(record.number))
            ? (
              <></>
            )
            : (
              <span>{
                text
                  ? moment(text).format('DD/MMM/YYYY HH:mm:ss')
                  : EMPTY.RECORD
              }</span>
            )
        }
      </>
    )
  },
  {
    dataIndex: 'information',
    title: 'Information',
    width: '400px'
  },
  {
    dataIndex: 'evidence',
    title: 'Evidence',
    
    render: (text, record, index) => (
      <>
        {
          isNaN(Number(record.number))
            ? (
              <></>
            )
            : (
              <div className="flex flex-row items-center justify-center gap-x-3">
                {
                  (record.evidence && record.evidence?.length)
                    ? (
                      <span className="text-[#2D3D5A]">
                        {record.evidence?.length} File(s) Uploaded
                      </span>
                    )
                    : (<></>)
                }
                <div
                  key={`submission-evidence-${index + 1}`}
                  className="p-1 rounded-full bg-[#2D3D5A] cursor-pointer"
                  onClick={() => onUpload(record)}
                >
                  {
                    !isDetailMode 
                      ? (
                        <Tooltip title={'Upload'} trigger={'hover'}>
                          <FiUpload
                            size="18px"
                            className="text-white"
                          />
                        </Tooltip>
                      )
                      : (
                        <Tooltip title={'View Detail'} trigger={'hover'}>
                          <FiEye
                            size="18px"
                            className="text-white"
                          />
                        </Tooltip>
                      )
                  }
                </div>
              </div>
            )
        }
      </>
    )
  },
  {
    dataIndex: 'quality_checklist',
    title: 'Quality Checklist',
    width: '100px',
    render: (text, record, index) => (
      <>
        {
          isNaN(Number(record.number))
            ? (
              <></>
            )
            : (
              <Checkbox
                key={`quality-check-${index + 1}`}
                checked={record.quality_checklist}
                disabled
              />
            )
        }
      </>
    )
  },
  {
    dataIndex: 'weight_percentage',
    title: 'Weight Percentage',
    width: '100px',
    render: (text, record, index) => (
      <>
        {
          isNaN(Number(record.number))
            ? (
              <></>
            )
            : (
              <InputNumber
                key={`weight-percentage-${index + 1}`}
                min={1}
                style={{ width: '100%' }}
                value={record.weight_percentage}
                formatter={formatWithEnLocale}
                parser={parseFromEnLocale}
                onKeyDown={preventNonNumber}
                disabled
              />
            )
        }
      </>
    )
  },
  {
    dataIndex: 'quality_score',
    title: 'Quality Score',
    width: '110px',
    render: (text, record, index) => (
      <>
        {
          isNaN(Number(record.number))
            ? (
              <></>
            )
            : (
              <InputNumber
                key={`quality-score-${index + 1}`}
                min={1}
                style={{ width: '100%' }}
                value={!record.quality_checklist ? 0 : record.weight_percentage}
                formatter={formatWithEnLocale}
                parser={parseFromEnLocale}
                onKeyDown={preventNonNumber}
                disabled
              />
            )
        }
      </>
    )
  }
];

const generateQualityChecklistData = (data) => {
  if (data) {
    const transformedData = [];

    data.grouped_parameters.forEach((category) => {
      transformedData.push({
        quality_checklist_id: null,
        number: category.category,
        month: null,
        information: null,
        evidence: null,
        quality_checklist: null,
        weight_percentage: null,
        quality_score: null,
        updated_date: null
      });
      
      category.parameters.forEach((question) => {
        transformedData.push({
          quality_checklist_id: question.parameter_id,
          number: question.drilling_plan_version,
          version: `V${question.drilling_plan_version}`,
          month: question.month,
          information: question.information,
          evidence: question.evidence,
          quality_checklist: question.quality_checklist,
          weight_percentage: question.weight_percentage,
          quality_score: question.quality_score,
          updated_date: question.updated_date
        });
      });
    });
    
    return transformedData;
  }
  
  return [];
};

const generateBody = (
  isDraft,
  data,
  version
) => {
  return {
    version: version,
    is_draft: isDraft,
    content: data.map((item) => ({
      parameter_id: item.quality_checklist_id,
      evidences: item.evidence?.map(v => {
        return {
          id: v.id,
          updated_date: v.updated_date
        };
      }),
      information: item.information,
      quality_score: item.quality_score,
      quality_checked: item.quality_checklist,
      weight_percentage: item.weight_percentage
    })).filter((item) => Boolean(item.parameter_id))
  };
};

export {
  generateQualityChecklistColumns,
  generateQualityChecklistData,
  generateBody
};