import { DatePicker, Form, Input, InputNumber } from 'antd';
import { useLazyGetOeByIdQuery, usePostOeMutation, usePutOeMutation } from 'api/MaintenanceOe';
import ButtonAccent from 'components/ButtonAccent';
import Toast from 'components/Toast';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { transformError } from 'utils/ErrorTransformer';

const FormOmeAP = (props) => {
  const { isEdit, oeId, onSubmit } = props;

  const [form] = Form.useForm();
  const values = Form.useWatch([], form);

  const [
    postOe,
    {
      isLoading: postOeIsLoading,
      isError: postOeIsError,
      error: postOeError,
      isSuccess: postOeIsSuccess,
    },
  ] = usePostOeMutation();

  const [
    getOeById,
    {
      data: oeData,
      isError: getOeIsError,
      error: getOeError,
    },
  ] = useLazyGetOeByIdQuery();

  const [
    putOe,
    {
      isLoading: putOeIsLoading,
      isError: putOeIsError,
      error: putOeError,
      isSuccess: putOeIsSuccess,
    },
  ] = usePutOeMutation();

  const onFinish = (values) => {
    const body = {
      'category': values.category,
      'description': values.description,
      'unit': values.unit,
      'year': moment(new Date(values.year)).format('YYYY'),
      'jan': values.jan,
      'feb': values.feb,
      'mar': values.mar,
      'apr': values.apr,
      'may': values.may,
      'jun': values.jun,
      'jul': values.jul,
      'aug': values.aug,
      'sep': values.sep,
      'oct': values.oct,
      'nov': values.nov,
      'dec': values.dec
    };

    if (isEdit) {
      putOe({ id: oeId, body });
    } else {
      postOe(body);
    }
  };

  useEffect(() => {
    if (isEdit) {
      getOeById({ id: oeId });
    }
  }, [oeId, isEdit]);

  useEffect(() => {
    if (!isEmpty(oeData)) {
      form.setFieldsValue({
        'category': oeData.category,
        'description': oeData.description,
        'unit': oeData.unit,
        'year': dayjs(oeData.year),
        'jan': oeData.jan,
        'feb': oeData.feb,
        'mar': oeData.mar,
        'apr': oeData.apr,
        'may': oeData.may,
        'jun': oeData.jun,
        'jul': oeData.jul,
        'aug': oeData.aug,
        'sep': oeData.sep,
        'oct': oeData.oct,
        'nov': oeData.nov,
        'dec': oeData.dec
      });
    }
  }, [oeData]);

  useEffect(() => {
    if (postOeIsError) {
      toast.error(
        transformError(postOeError).message,
        { toastId: 'oe-post-error' }
      );
    }
    if (putOeIsError) {
      toast.error(
        transformError(putOeError).message,
        { toastId: 'oe-put-error' }
      );
    }
    if (getOeIsError) {
      toast.error(
        transformError(getOeError).message,
        { toastId: 'get-oe-id-error' }
      );
    }
    if (postOeIsSuccess) {
      toast.success(
        <Toast message={'SUCCESS'} detailedMessage={'Successfuly create new data.'} />,
        { toastId: 'oe-post-success' }
      );
      onSubmit();
    }
    if (putOeIsSuccess) {
      toast.success(
        <Toast message={'SUCCESS'} detailedMessage={'Successfuly update the data.'} />,
        { toastId: 'oe-put-success' }
      );
      onSubmit();
    }
  }, [
    postOeIsError,
    putOeIsError,
    getOeIsError,
    postOeIsSuccess,
    putOeIsSuccess
  ]);

  return (
    <Form
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      style={{
        maxWidth: 600,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      autoComplete="off"
      labelAlign="left"
      colon={false}
      requiredMark={false}
      form={form}
    >
      <Form.Item
        label="Category"
        name="category"
        rules={[
          {
            required: true,
            message: 'Please input category',
          },
        ]}
      >
        <Input placeholder='Please input category' />
      </Form.Item>

      <Form.Item
        label="Description"
        name="description"
        rules={[
          {
            required: true,
            message: 'Please input description',
          },
        ]}
      >
        <Input placeholder='Please input description' />
      </Form.Item>

      <Form.Item
        label="Unit"
        name="unit"
        rules={[
          {
            required: true,
            message: 'Please input unit',
          },
        ]}
      >
        <Input placeholder='Please input unit' />
      </Form.Item>
      

      <Form.Item
        label="Year"
        name="year"
        rules={[
          {
            required: true,
            message: 'Please input year',
          },
        ]}
      >
        <DatePicker className='w-full' picker='year' />
      </Form.Item>

      <Form.Item
        label="Jan"
        name="jan"
        rules={[
          {
            required: !(
              values?.jan || values?.feb || values?.mar || values?.apr || values?.may || values?.jun
              || values?.jul || values?.aug || values?.sep || values?.oct || values?.nov || values?.dec
            ),
            message: 'Please input jan',
          },
        ]}
      >
        <InputNumber className='w-full' placeholder='Please input jan' />
      </Form.Item>

      <Form.Item
        label="Feb"
        name="feb"
        rules={[
          {
            required: !(
              values?.jan || values?.feb || values?.mar || values?.apr || values?.may || values?.jun
              || values?.jul || values?.aug || values?.sep || values?.oct || values?.nov || values?.dec
            ),
            message: 'Please input feb',
          },
        ]}
      >
        <InputNumber className='w-full' placeholder='Please input feb' />
      </Form.Item>
      
      <Form.Item
        label="Mar"
        name="mar"
        rules={[
          {
            required: !(
              values?.jan || values?.feb || values?.mar || values?.apr || values?.may || values?.jun
              || values?.jul || values?.aug || values?.sep || values?.oct || values?.nov || values?.dec
            ),
            message: 'Please input mar',
          },
        ]}
      >
        <InputNumber className='w-full' placeholder='Please input mar' />
      </Form.Item>

      <Form.Item
        label="Apr"
        name="apr"
        rules={[
          {
            required: !(
              values?.jan || values?.feb || values?.mar || values?.apr || values?.may || values?.jun
              || values?.jul || values?.aug || values?.sep || values?.oct || values?.nov || values?.dec
            ),
            message: 'Please input apr',
          },
        ]}
      >
        <InputNumber className='w-full' placeholder='Please input apr' />
      </Form.Item>

      <Form.Item
        label="May"
        name="may"
        rules={[
          {
            required: !(
              values?.jan || values?.feb || values?.mar || values?.apr || values?.may || values?.jun
              || values?.jul || values?.aug || values?.sep || values?.oct || values?.nov || values?.dec
            ),
            message: 'Please input may',
          },
        ]}
      >
        <InputNumber className='w-full' placeholder='Please input may' />
      </Form.Item>

      <Form.Item
        label="Jun"
        name="jun"
        rules={[
          {
            required: !(
              values?.jan || values?.feb || values?.mar || values?.apr || values?.may || values?.jun
              || values?.jul || values?.aug || values?.sep || values?.oct || values?.nov || values?.dec
            ),
            message: 'Please input jun',
          },
        ]}
      >
        <InputNumber className='w-full' placeholder='Please input jun' />
      </Form.Item>

      <Form.Item
        label="Jul"
        name="jul"
        rules={[
          {
            required: !(
              values?.jan || values?.feb || values?.mar || values?.apr || values?.may || values?.jun
              || values?.jul || values?.aug || values?.sep || values?.oct || values?.nov || values?.dec
            ),
            message: 'Please input jul',
          },
        ]}
      >
        <InputNumber className='w-full' placeholder='Please input jul' />
      </Form.Item>

      <Form.Item
        label="Aug"
        name="aug"
        rules={[
          {
            required: !(
              values?.jan || values?.feb || values?.mar || values?.apr || values?.may || values?.jun
              || values?.jul || values?.aug || values?.sep || values?.oct || values?.nov || values?.dec
            ),
            message: 'Please input aug',
          },
        ]}
      >
        <InputNumber className='w-full' placeholder='Please input aug' />
      </Form.Item>

      <Form.Item
        label="Sep"
        name="sep"
        rules={[
          {
            required: !(
              values?.jan || values?.feb || values?.mar || values?.apr || values?.may || values?.jun
              || values?.jul || values?.aug || values?.sep || values?.oct || values?.nov || values?.dec
            ),
            message: 'Please input sep',
          },
        ]}
      >
        <InputNumber className='w-full' placeholder='Please input sep' />
      </Form.Item>

      <Form.Item
        label="Oct"
        name="oct"
        rules={[
          {
            required: !(
              values?.jan || values?.feb || values?.mar || values?.apr || values?.may || values?.jun
              || values?.jul || values?.aug || values?.sep || values?.oct || values?.nov || values?.dec
            ),
            message: 'Please input oct',
          },
        ]}
      >
        <InputNumber className='w-full' placeholder='Please input oct' />
      </Form.Item>

      <Form.Item
        label="Nov"
        name="nov"
        rules={[
          {
            required: !(
              values?.jan || values?.feb || values?.mar || values?.apr || values?.may || values?.jun
              || values?.jul || values?.aug || values?.sep || values?.oct || values?.nov || values?.dec
            ),
            message: 'Please input nov',
          },
        ]}
      >
        <InputNumber className='w-full' placeholder='Please input nov' />
      </Form.Item>

      <Form.Item
        label="Dec"
        name="dec"
        rules={[
          {
            required: !(
              values?.jan || values?.feb || values?.mar || values?.apr || values?.may || values?.jun
              || values?.jul || values?.aug || values?.sep || values?.oct || values?.nov || values?.dec
            ),
            message: 'Please input dec',
          },
        ]}
      >
        <InputNumber className='w-full' placeholder='Please input dec' />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <ButtonAccent
          title={isEdit ? 'Update' : 'Submit'}
          htmlType="submit"
          isLoading={putOeIsLoading || postOeIsLoading}
          isDisabled={putOeIsLoading || postOeIsLoading}
          className="w-full"
        />
      </Form.Item>
    </Form>
  );
};

export default FormOmeAP;