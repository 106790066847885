import { EMPTY } from 'constant';
import { RxCross2 } from 'react-icons/rx';
import { InputNumber } from 'antd';
import { isEmpty } from 'lodash';
import { GoPlus } from 'react-icons/go';
import { populateCoalRecoveryFormula } from './TableCoalSeamRecovery';

const generateThicknessTable = (
  isDetailMode,
  payload, 
  onChange, 
  onAdd, 
  onDeleteRow,
  onAddSampleId,
  deleteSampleId
) => [
  {
    dataIndex: 'action',
    title: 'Action',
    width: 5,
    sorter: false,
    align: 'center',
    fixed: 'left',
    render: (_, record, index) => {
      let isParent = record?.hasOwnProperty('drill_hole_name');   
      let dataDrillHoleName = payload.filter(item => item?.hasOwnProperty('drill_hole_name')); 
      return (
        <div className="flex justify-center">
          {payload.length <= 1 || dataDrillHoleName.length <= 1 ? (
            <></>
          ) : (isParent && !isDetailMode) && (
            <RxCross2
              size={28}
              color={'red'}
              className={'rounded-full p-1 cursor-pointer bg-red-200 '}
              onClick={() => {
                onDeleteRow(record);
              }}
            />
          )}
        </div>
      );
    },
  },
  {
    dataIndex: 'drill_hole_name',
    title: 'Drill Hole Name',
    width: '166px',
    align: 'center',
    fixed: 'left',
    sorter: false,
    render: (value, record) => {
      let isParent = record?.hasOwnProperty('drill_hole_name');
      return isParent && (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {value}
        </div>
      );
    },
  },
  {
    dataIndex: 'sample_id',
    title: 'Sample ID',
    width: '200px',
    align: 'center',
    fixed: 'left',
    sorter: false,
    render: (value, record) => {
      let isParent = record?.hasOwnProperty('drill_hole_name');
      return (
        <div className='flex flex-row gap-2'>
          {
            !isDetailMode && 
            <>
              {!isParent? (
                <RxCross2
                  size={28}
                  color={'red'}
                  className={'rounded-full p-1 cursor-pointer bg-red-200 '}
                  onClick={() => deleteSampleId(record)}
                />
              ):(
                <GoPlus
                  size={28}
                  color={'green'}
                  className={'rounded-full p-1 cursor-pointer bg-green-200'}
                  onClick={onAddSampleId} 
                />
              )}
            </>
          }
          <div className='w-full flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
            {value}
          </div>
        </div>
      );
    },
  },
  {
    title: 'Geophysical Depth (m)',
    align: 'center',
    sorter: false,
    children:[ 
      {
        dataIndex: 'geophysical_depth_from',
        title: 'From',
        width: '196px',
        sorter: false,
        align: 'center',
        render: (value, record) => {
          return (
            <InputNumber
              disabled={isDetailMode}
              className='w-full'
              placeholder={'Enter from'}
              value={value}
              type='number'
              onChange={(val) => {
                onChange('thickness', record?.localId, record?.localChildrenId, 'geophysical_depth_from',val);                  
                onChange('thickness', record?.localId, record?.localChildrenId, 'geophysical_depth_thickness', Number(record?.geophysical_depth_to - val));
                onChange('coal_seam_recovery', record?.localId, record?.localChildrenId, 'coal_recovery', 
                  populateCoalRecoveryFormula(record?.drill_hole_thickness, Number(record?.geophysical_depth_to - val))
                );
              }}
              status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
            /> 
          );
        },
      },
      {
        dataIndex: 'geophysical_depth_to',
        title: 'To',
        width: '196px',
        sorter: false,
        align: 'center',
        render: (value, record) => {
          return (
            <InputNumber
              disabled={isDetailMode}
              className='w-full'
              placeholder={'Enter to'}
              value={value}
              type='number'
              onChange={(val) => {
                onChange('thickness', record?.localId, record?.localChildrenId, 'geophysical_depth_to', val);
                onChange('thickness', record?.localId, record?.localChildrenId, 'geophysical_depth_thickness', Number(val - record?.geophysical_depth_from));
                onChange('coal_seam_recovery', record?.localId, record?.localChildrenId, 'coal_recovery', 
                  populateCoalRecoveryFormula(record?.drill_hole_thickness, Number(val - record?.geophysical_depth_from))
                );
              }}
              status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
            /> 
          );
        },
      },
      {
        dataIndex: 'geophysical_depth_thickness',
        title: 'Thickness',
        width: '196px',
        sorter: false,
        align: 'center',
        render: (value) => {
          return (
            <div className='flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
              { value }
            </div>
          );
        },
      },
    ]
  },
  {
    dataIndex: 'drill_hole_thickness',
    title: 'Drill Hole Thickness',
    width: '196px',
    align: 'center',
    sorter: false,
    render: (value, record) => {
      return (
        <InputNumber
          disabled={isDetailMode}
          className='w-full'
          placeholder={'Enter drill hole thickness'}
          value={value}
          type='number'
          onChange={(val) => {
            onChange('thickness', record?.localId, record?.localChildrenId, 'drill_hole_thickness', val);
            onChange('coal_seam_recovery', record?.localId, record?.localChildrenId, 'coal_recovery', 
              populateCoalRecoveryFormula(val, record?.geophysical_depth_thickness)
            ); 
          }}
          status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
        /> 
      );
    },
  },
];

const generateThicknessData = (data) => {
  if(!isEmpty(data)) {
    let dataSampleInformation = data?.find(item => item.thickness)?.thickness;
    let result = [];
    dataSampleInformation?.forEach((sample, indexSample) => {
      sample?.children?.forEach((child, index) => {
        result.push({
          localId: sample?.localId || indexSample + 1,
          ...(index === 0) && {drill_hole_name:sample.drill_hole_name},
          ...child
        });
      });
    });
    return result;
  }
};

export { generateThicknessTable, generateThicknessData };