import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

const initialState = {
  form: null,
  production: null,
  obUnit: null,
  obCapacity: null,
  coalUnit: null,
  coalCapacity: null,
  obHauler: null,
  pitReserve: null,
  mpScenario: null,
  summary: null
};

const assumption = createSlice({
  name: 'assumption',
  initialState,
  reducers: {
    saveState: (state, action) => {
      const { key, value } = action.payload;

      state[key] = value;
    },
    resetState: (state, action) => {
      const { key } = action.payload;

      state[key] = null;
    },
    resetAllState: (state) => {
      state = initialState;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      return initialState;
    });
  }
});

export const {
  saveState,
  resetState,
  resetAllState
} = assumption.actions;
export default assumption.reducer;