import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSideBarExpanded: true
};

const preferences = createSlice({
  name: 'preferences',
  initialState,
  reducers: {
    setSideBarExpandState: (state, action) => {
      state.isSideBarExpanded = action.payload;
    }
  }
});

export const { setSideBarExpandState } = preferences.actions;
export default preferences.reducer;