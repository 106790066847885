import {
  Checkbox,
  Spin,
  Switch
} from 'antd';
import { FiEye, FiTrash } from 'react-icons/fi';
import { GiPencil } from 'react-icons/gi';
import {
  isEmpty,
  toLower,
  toUpper
} from 'lodash';

import { ACTION, PERMISSION } from 'constant';

const excludedRoles = ['administrator', 'mine planner', 'pic confident level'];

const groupColumns = (
  isDisabled,
  sortBy,
  orderBy,
  onView,
  onEdit,
  onDelete,
  onStatusChange
) => [
  {
    dataIndex: 'name',
    title: 'Group Name',
    sorter: true,
    sortOrder: sortBy === 'name' ? orderBy : null,
  },
  {
    dataIndex: 'is_active',
    title: 'Status',
    render: (text, record) => (
      <>
        {
          isDisabled
            ? (
              <Spin size="small" />
            )
            : !excludedRoles.some((role) => toLower(record.name).includes(toLower(role))) && (
              <Switch
                disabled={isDisabled}
                checked={text}
                onChange={() => onStatusChange(record, ACTION.SWITCH_STATUS, text)}
              />
            )
        }
      </>
    )
  },
  {
    title: 'Action',
    render: (text, record) => (
      <>
        {
          (excludedRoles.some((role) => toLower(record.name).includes(toLower(role))) || isDisabled)
            ? (<></>)
            : (
              <div className="flex flex-row justify-center items-center gap-x-3">
                <div
                  className="rounded-full bg-[#2D3D5A] cursor-pointer"
                  onClick={() => onView(record, ACTION.DETAIL)}
                >
                  <FiEye
                    size="24px"
                    className="p-1 text-white"
                  />
                </div>
                <div
                  className="rounded-full bg-[#2D3D5A] cursor-pointer"
                  onClick={() => onEdit(record, ACTION.EDIT)}
                >
                  <GiPencil
                    size="24px"
                    className="p-1 text-white"
                  />
                </div>
                <div
                  className="rounded-full bg-pink-700 cursor-pointer"
                  onClick={() => onDelete(record, ACTION.DELETE)}
                >
                  <FiTrash
                    size="24px"
                    className="p-1 text-white"
                  />
                </div>
              </div>
            )
        }
      </>
    )
  }
];

const permissionColumns = (isDetailMode, permissionData, onPermissionChange) => {
  const isPermissionChecked = (record, type) => {
    if (isEmpty(permissionData)) {
      return false;
    }

    return permissionData.some(
      (permission) => record.id === permission.id
        && permission.permissions.some(
          (permissionItem) => toLower(permissionItem.name).includes(PERMISSION[toUpper(type)])
        )
    );
  };

  const isMenuHasPermission = (record, type) => {
    return record.permissions.some(
      (permission) => toLower(permission.name).includes(PERMISSION[toUpper(type)])
    );
  };

  const getPermissionData = (record, type) => {
    const { id, name, permissions } = record;
    return {
      id,
      name,
      permissions: [permissions.find(
        (permission) => toLower(permission.name).includes(PERMISSION[toUpper(type)])
      )]
    };
  };

  return [
    {
      dataIndex: 'name',
      title: 'Menu'
    },
    {
      title: 'Create',
      render: (text, record) => (
        <>
          {
            isMenuHasPermission(record, PERMISSION.CREATE) && (
              <Checkbox
                disabled={isDetailMode}
                checked={isPermissionChecked(record, PERMISSION.CREATE)}
                onChange={(ev) => onPermissionChange(
                  getPermissionData(record, PERMISSION.CREATE),
                  ev.target.checked
                )}
              />
            )
          }
        </>
      )
    },
    {
      title: 'Read',
      render: (text, record) => (
        <>
          {
            isMenuHasPermission(record, PERMISSION.READ) && (
              <Checkbox
                disabled={isDetailMode}
                checked={isPermissionChecked(record, PERMISSION.READ)}
                onChange={(ev) => onPermissionChange(
                  getPermissionData(record, PERMISSION.READ),
                  ev.target.checked
                )}
              />
            )
          }
        </>
      )
    },
    {
      title: 'Update',
      render: (text, record) => (
        <>
          {
            isMenuHasPermission(record, PERMISSION.UPDATE) && (
              <Checkbox
                disabled={isDetailMode}
                checked={isPermissionChecked(record, PERMISSION.UPDATE)}
                onChange={(ev) => onPermissionChange(
                  getPermissionData(record, PERMISSION.UPDATE),
                  ev.target.checked
                )}
              />
            )
          }
        </>
      )
    },
    {
      title: 'Delete',
      render: (text, record) => (
        <>
          {
            isMenuHasPermission(record, PERMISSION.DELETE) && (
              <Checkbox
                disabled={isDetailMode}
                checked={isPermissionChecked(record, PERMISSION.DELETE)}
                onChange={(ev) => onPermissionChange(
                  getPermissionData(record, PERMISSION.DELETE),
                  ev.target.checked
                )}
              />
            )
          }
        </>
      )
    },
    {
      title: 'Acknowledge',
      render: (text, record) => (
        <>
          {
            isMenuHasPermission(record, PERMISSION.ACKNOWLEDGE) && (
              <Checkbox
                disabled={isDetailMode}
                checked={isPermissionChecked(record, PERMISSION.ACKNOWLEDGE)}
                onChange={(ev) => onPermissionChange(
                  getPermissionData(record, PERMISSION.ACKNOWLEDGE),
                  ev.target.checked
                )}
              />
            )
          }
        </>
      )
    },
    {
      title: 'Approve',
      render: (text, record) => (
        <>
          {
            isMenuHasPermission(record, PERMISSION.APPROVE) && (
              <Checkbox
                disabled={isDetailMode}
                checked={isPermissionChecked(record, PERMISSION.APPROVE)}
                onChange={(ev) => onPermissionChange(
                  getPermissionData(record, PERMISSION.APPROVE),
                  ev.target.checked
                )}
              />
            )
          }
        </>
      )
    }
  ];
};

export { groupColumns, permissionColumns };