import { Tooltip } from 'antd';
import { isEmpty, isNumber } from 'lodash';
import moment from 'moment';
import { RxCross2 } from 'react-icons/rx';

const generateDrillingTimeDepthAnalysisTable = (payload, isDetailMode, onDeleteRow) => [
  {
    dataIndex: 'action',
    title: 'Action',
    width: 5,
    sorter: false,
    align: 'center',
    fixed: 'left',
    render: (_, record, index) => {
      return (
        <div className="flex justify-center">
          {payload.length <= 1 ? (
            <></>
          ) : (
            <Tooltip trigger={'hover'} title='Delete Row'> 
              <RxCross2
                size={28}
                color={`${isDetailMode? 'gray' : 'red'}`}
                className={`rounded-full p-1 ${isDetailMode? 'bg-gray-200':'cursor-pointer bg-red-200 '}`}
                onClick={() => onDeleteRow(index)}
              />
            </Tooltip>
          )}
        </div>
      );
    },
  },
  {
    dataIndex: 'actual_time',
    title: 'Actual Time (day)',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {Number(value).toFixed(2)}
        </div>
      );
    },
  },
  {
    dataIndex: 'start_late_time',
    title: 'Start Late Time',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {isNumber(value) ? Number(value).toFixed(2):''}
        </div>
      );
    },
  },
  {
    dataIndex: 'pending_time',
    title: 'Pending Time',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {Number(value).toFixed(2)}
        </div>
      );
    },
  },
  {
    dataIndex: 'pending_plan_depth',
    title: 'Pending Plan Depth',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {Number(value).toFixed(2)}
        </div>
      );
    },
  },
];

const actualTimeFormula = (index, otherData) => {
  let result = 0;
  if(otherData.drilling_date[index].date_start_drill_actual) {
    const dateStartDrill = moment(otherData.drilling_date[index].date_start_drill_actual, 'DD/MM/YYYY');
    const dateFinalLog = moment(otherData.drilling_date[index].date_final_log_actual, 'DD/MM/YYYY'); 
    result = Number(dateFinalLog.diff(dateStartDrill, 'days') + 1);
  }
  return result;
};

const startLateTimeFormula = (index, plansData, otherData, record) => {
  let result = '';
  let choosedPlan = plansData?.find(plan => plan.drill_hole_name === record.drill_hole_name);
  let area = choosedPlan?.area;
  let finalLogPlan = moment(choosedPlan?.date_final_log_plan, 'DD/MM/YYYY');
  let dateStartDrillPlan = moment(choosedPlan?.date_start_drill_plan, 'DD/MM/YYYY');
  const dateStartDrillActual = moment(otherData.drilling_date[index].date_start_drill_actual, 'DD/MM/YYYY');
  if(!area) {
    result = '';
  } else {
    let caseDrill = otherData.drill_hole_information[index].case_drill;
    if(caseDrill === 'Pending') {
      result = finalLogPlan.diff(dateStartDrillPlan, 'days');
    } else {
      if(dateStartDrillActual < dateStartDrillPlan) {
        result = 0;
      } else {
        result = Number(dateStartDrillActual.diff(dateStartDrillPlan, 'days'));
      }
    }
  }
  return result;
};

const pendingTimeFormula = (index, plansData, otherData, record) => {
  let result = '';
  let choosedPlan = plansData?.find(plan => plan.drill_hole_name === record.drill_hole_name);
  let caseDrill = otherData.drill_hole_information[index].case_drill;
  let finalLogPlan = moment(choosedPlan?.date_final_log_plan, 'DD/MM/YYYY');
  let dateStartDrillPlan = moment(choosedPlan?.date_start_drill_plan, 'DD/MM/YYYY');
  if(caseDrill === 'Pending'){
    result =  finalLogPlan.diff(dateStartDrillPlan, 'days');
  }else{
    result = 0;
  }
  return result;
};

const pendingPlanDepthFormula = (index, plansData, otherData, record) => {
  let result = '';
  let choosedPlan = plansData?.find(plan => plan.drill_hole_name === record.drill_hole_name);
  let caseDrill = otherData.drill_hole_information[index].case_drill;
  if(caseDrill === 'Pending') {
    result = choosedPlan?.plan_drilling_depth;
  }else{
    result = 0;
  }
  return result;
};

const generateDrillingTimeDepthAnalysisActualData = (file) => {
  if(!isEmpty(file)) {
    const dataDrillHoleName = file.properties[0].drill_hole_information[0].drill_hole_name;
    const data = file.properties[4]['time_&_depth_analysis'];
    let finalResult = dataDrillHoleName.map((drillHoleName, index) => {
      return data.reduce((result, currentObject, indexObject) => {
        const key = Object.keys(currentObject)[0];
        const value = currentObject[key][index]?.value;
        result[key] = value;
        if(indexObject === data?.length -1){
          result['drill_hole_name'] = drillHoleName?.value;
        }
        return result;
      }, {});
    });
    return finalResult;
  }
};

export { 
  generateDrillingTimeDepthAnalysisTable,
  generateDrillingTimeDepthAnalysisActualData, 
  actualTimeFormula, 
  startLateTimeFormula,
  pendingTimeFormula,
  pendingPlanDepthFormula
};