import { isEmpty } from 'lodash';
import { DEFAULT, EMPTY } from 'constant';

const getInitialName = (name) => {
  if (!isEmpty(name)) {
    const splitName = name.split(' ');

    if (splitName.length > 1) {
      return `${splitName[0].charAt(0)}${splitName[1].charAt(0)}`;
    }

    return `${splitName[0].charAt(0)}`;
  }

  return EMPTY.STRING;
};

const ellipsized = (message, max) => {
  if (message) {
    return message.length > (max || DEFAULT.MAX_STRING)
      ? `${message.slice(EMPTY.NUMBER, (max || 5))}...`
      : message;
  }
  
  return EMPTY.STRING;
};

export { getInitialName, ellipsized };