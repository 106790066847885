const PreventNavigation = (isDetailMode, handleCloseSite) => {
  
  if(!isDetailMode){
    window.addEventListener('beforeunload',handleCloseSite);
    return () => {
      window.removeEventListener('beforeunload', handleCloseSite);
    };
  }
};

export default PreventNavigation;