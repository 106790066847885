import { Form, Select } from 'antd';
import { useGetMasterDataGoeCompaniesQuery } from 'api/MasterData';
import ButtonAccent from 'components/ButtonAccent';
import SelectSearchAccent from 'components/SelectSearchAccent';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { transformError } from 'utils/ErrorTransformer';
import { useModalConfirmationContext } from 'components/ModalConfirmationProvider';
import PreventNavigation from 'constant/PreventNavigation';
import ModalInformation from 'pages/DrillingPlan/components/ModalInformation';
import { useLazyGetListPlanNameQuery } from 'api/DrillingSummary';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { saveState } from 'store/DrillingActual';
import { getFullPath } from 'utils/PathUtility';
import { createSearchParams } from 'react-router-dom';
import { ACTION } from 'constant';

const InputActualForm = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const navigate = useNavigate();

  const [informationModal, setInformationModal] = useState(false);
  const {showModal, resetModal} = useModalConfirmationContext();

  const { 
    data: dataCompanies, 
    isFetching: companiesIsFetching
  } = useGetMasterDataGoeCompaniesQuery({ 
    refetchOnMountOrArgChange: true 
  });

  const [getListPlanningName, {
    data: planningNameData,
    isFetching: planningNameIsFetching
  }] = useLazyGetListPlanNameQuery();

  const onFinish = async (values) => {
    const newValue = {
      company: JSON.parse(values.company),
      planning_type: values.planning_type,
      planning_name: JSON.parse(values.planning_name)
    };

    await dispatch(saveState({ key: 'input_actual', value: newValue }));

    goToPath(null, ACTION.CREATE, 'summary-drilling-actual');
  };

  const handleBackButton = () => {
    showModal({
      isShown: true,
      type: 'confirmation',
      title:'Are you sure want to leave this page?',
      message: 'Changes you made may not be saved',
      onSubmit: () => {
        navigate(-1);
        resetModal();
      }
    });
  };

  const handleCloseSite = useCallback((ev) => ev.preventDefault() ,[]);
  
  useEffect(() => {
    PreventNavigation(false, handleCloseSite);
  }, []);

  useEffect(() => {
    if (!isEmpty(values?.company) && !isEmpty(values?.planning_type)) {
      const parseCompany = JSON.parse(values.company);
      getListPlanningName({
        params: {
          company: parseCompany.alias_name,
          plan_type: values.planning_type,

        }
      });
    }
  }, [values]);

  const goToPath = (id, type, child)=> navigate({
    pathname: getFullPath({
      parent: 'summary-drilling',
      child: child,
      type
    },'geology-oe'),
    ...(id)
      ? { search: createSearchParams({ id }).toString() }
      : undefined
  });

  return (
    <div>
      <Form form={form} onFinish={onFinish}>
        <div className="flex grid-cols-3 bg-white px-6 pt-6 gap-6 rounded-2xl items-center justify-center">
          <label className="w-full">
            <span className="font-bold">Mine Site</span>
            <Form.Item
              name="company"
              rules={
                [
                  {
                    required: true,
                    message: 'Please input Mine Site',
                  },
                ]
              }
            >
              <SelectSearchAccent
                isStringify
                labelKey="alias_name"
                valueKey="id"
                options={dataCompanies}
                placeholder="Select mine site"
                isLoading={companiesIsFetching}
                className={'h-12 mt-2'}
              />
            </Form.Item>
          </label>
          <div className="w-full">
            <span className="font-bold">Drilling Stage</span>
            <Form.Item
              name="planning_type"
              rules={[
                {
                  required: true,
                  message: 'Please input Drilling Stage',
                },
              ]}
            >
              <Select
                allowClear
                placeholder="Select drilling stage"
                options={[
                  { label: 'Pre-production', value: 'PREPRODUCTION' },
                  { label: 'Exploration', value: 'EXPLORATION' },
                ]}
                className="mt-2 w-full h-12"
              />
            </Form.Item>
          </div>
          <label className="w-full">
            <span className="font-bold">Planning Name</span>
            <Form.Item
              name="planning_name"
              rules={[
                {
                  required: false,
                  message: 'Please input planning name',
                },
              ]}
            >
              <SelectSearchAccent
                isStringify
                labelKey="name"
                options={planningNameData}
                placeholder="Select planning name"
                isLoading={planningNameIsFetching}
                className={'h-12 mt-2'}
                isDisabled={!(values?.company && values?.planning_type)}
              />
            </Form.Item>
          </label>
        </div>
        <div className="flex flex-row justify-between mt-16">
          <ButtonAccent
            isBordered
            title="Cancel"
            isLoading={false}
            onClick={handleBackButton}
          />
          <ButtonAccent
            title="Next"
            isLoading={false}
            htmlType="submit"
          />
        </div>
      </Form>
      <ModalInformation
        isModalOpen={informationModal}
        setIsModalOpen={setInformationModal}
        errorMessage={`${transformError(false).message}`}
      />
    </div>
  );
};

export default InputActualForm;
