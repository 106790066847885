import { createSlice } from '@reduxjs/toolkit';

import { DEFAULT, EMPTY } from 'constant';

const initialState = {
  limits: [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 30, label: '30' },
    { value: 40, label: '40' },
    { value: 50, label: '50' },
  ],
  page: DEFAULT.PAGE,
  pageSize: DEFAULT.PAGE_SIZE,
  sortBy: EMPTY.STRING,
  orderBy: EMPTY.STRING,
  search: EMPTY.STRING
};

const table = createSlice({
  name: 'table',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setOrderBy: (state, action) => {
      state.orderBy = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    reset: (state) => {
      state.page = DEFAULT.PAGE;
      state.pageSize = DEFAULT.PAGE_SIZE;
      state.sortBy = EMPTY.STRING;
      state.orderBy = DEFAULT.SORT_ASC;
      state.search = EMPTY.STRING;
    }
  }
});

export const {
  setPage,
  setPageSize,
  setSortBy,
  setOrderBy,
  setSearch,
  reset
} = table.actions;
export default table.reducer;