import moment from 'moment';
import {
  useEffect,
  useReducer,
  useRef,
  useState
} from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import { Divider, Table } from 'antd';
import { toast } from 'react-toastify';
import {
  cloneDeep,
  isEmpty,
  isEqual,
  toLower
} from 'lodash';

import { useSelector } from 'react-redux';
import { useGetAssumptionByIdQuery } from 'api/Assumption';
import {
  useLazyGetLossTimeByIdQuery,
  useLazyGetLatestLossTimeQuery,
  usePostLossTimeMutation,
  usePutLossTimeMutation
} from 'api/Production';
import { EMPTY, UNIT, URL } from 'constant';
import {
  generateHeaderColumns,
  generateHeaderDefaultData,
  calculateHeaderData,
  generateTableColumns,
  generateTableDefaultData,
  generateSummaryColumns,
  generateTableSummaries,
  calculateSummary,
  generateBody,
  pickSummaryData,
  validateData,
  stateKey
} from 'constant/TableProduction';
import {
  getFullPath,
  getPathType,
  shouldRedirect
} from 'utils/PathUtility';
import { convertPeriodToTags } from 'utils/PeriodUtility';
import { transformError } from 'utils/ErrorTransformer';
import { useAssumptionManager } from 'utils/AssumptionManager';
import { useTableWrapper } from 'utils/TableResponsive';

import BannerPitPeriod from 'components/BannerPitPeriod';
import TableRaw from 'components/TableRaw';
import ButtonAccent from 'components/ButtonAccent';
import LoadingText from 'components/LoadingText';

const Production = () => {
  const loc = useLocation();
  const navigate = useNavigate();
  const { production } = useSelector((state) => state.assumption);

  const initialState = {
    obHeaderColumns: [],
    obHeaderData: [],
    obTableColumns: [],
    obTableData: [],
    obSummaries: [],
    coalHeaderColumns: [],
    coalHeaderData: [],
    coalTableColumns: [],
    coalTableData: [],
    coalSummaries: [],
    latestData: []
  };

  const [isValid, setIsValid] = useState(false);
  const [productionType, setProductionType] = useState('overBurden');

  const isDraftRef = useRef(false);
  const isDetailInitialization = useRef(false);
  const isDetailTableInitialization = useRef(false);
  const currentTypeRef = useRef(EMPTY.STRING);
  const lossTimeIdRef = useRef(null);
  const prodTypeRef = useRef('overBurden');
  const periodRef = useRef([]);
  const periodNameRef = useRef(EMPTY.STRING);
  const unitRef = useRef(UNIT.MONTH);
  const obHeaderDataRef = useRef([]);
  const coalHeaderDataRef = useRef([]);
  const obTableDataRef = useRef([]);
  const coalTableDataRef = useRef([]);

  const {
    createBackQueryParams,
    isPathHasBackQueryParams,
    isPathMaxBackQueryParams,
    saveLatestState,
    resetLatestState
  } = useAssumptionManager();
  const { wrapperWidth } = useTableWrapper();

  const assumptionId = new URLSearchParams(loc.search).get('id');
  const backQueries = new URLSearchParams(loc.search).getAll('isBack');
  const isBack = isPathHasBackQueryParams(loc.pathname, backQueries);
  const isCreateMode = getPathType(loc) === 'create';
  const isDetailMode = getPathType(loc) === 'detail';
  const isEditMode = getPathType(loc) === 'edit';

  const reducer = (state, action) => {
    const isOverBurden = action.key === stateKey.ob.key;

    switch (action.type) {
      case 'resize':
        return {
          ...state,
          obHeaderData: production?.obHeaderData,
          obTableData: production?.obTableData,
          coalHeaderData: production?.coalHeaderData,
          coalTableData: production?.coalTableData
        };
      case stateKey.ob.headerColumn:
      case stateKey.coal.headerColumn:
        return {
          ...state,
          [isOverBurden
            ? 'obHeaderColumns'
            : 'coalHeaderColumns']: action.payload
        };
      case stateKey.ob.headerData:
      case stateKey.coal.headerData:
        return {
          ...state,
          [isOverBurden
            ? 'obHeaderData'
            : 'coalHeaderData']: action.payload
        };
      case stateKey.ob.headerChange:
      case stateKey.coal.headerChange: {
        const { value, dataIndex } = action.payload;

        const newObHeaderState = state.obHeaderData.map(
          (item) =>
            calculateHeaderData(
              state.obHeaderData,
              item,
              value,
              dataIndex
            )
        );
        const newCoalHeaderState = state.coalHeaderData.map(
          (item) =>
            calculateHeaderData(
              state.coalHeaderData,
              item,
              value,
              dataIndex
            )
        );

        if (prodTypeRef.current === 'overBurden') {
          return {
            ...state,
            obHeaderData: newObHeaderState,
            coalHeaderData: newCoalHeaderState
          };
        }

        return {
          ...state,
          coalHeaderData: newCoalHeaderState
        };
      }
      case stateKey.ob.tableColumn:
      case stateKey.coal.tableColumn:
        return {
          ...state,
          [isOverBurden
            ? 'obTableColumns'
            : 'coalTableColumns']: action.payload
        };
      case stateKey.ob.tableData:
      case stateKey.coal.tableData:
        return {
          ...state,
          [isOverBurden
            ? 'obTableData'
            : 'coalTableData']: action.payload
        };
      case stateKey.ob.tableChange:
      case stateKey.coal.tableChange: {
        const { isSameClone, cloneRecord, type } = action.payload;

        const newObTableState = state.obTableData.map(
          (item) => ({
            ...isSameClone(item)
              ? { ...cloneRecord }
              : { ...item }
          })
        );
        
        const newCoalTableState = state.coalTableData.map(
          (item) => ({
            ...isSameClone(item)
              ? { ...cloneRecord }
              : { ...item }
          })
        );

        if (isCreateMode && !isBack && prodTypeRef.current === 'overBurden') {
          return {
            ...state,
            obTableData: newObTableState,
            coalTableData: newCoalTableState
          };
        }

        if (type === 'overBurden') {
          return {
            ...state,
            obTableData: newObTableState
          };
        }
        
        return {
          ...state,
          coalTableData: newCoalTableState
        };
      }
      case stateKey.ob.summaryColumn:
      case stateKey.coal.summaryColumn:
        return {
          ...state,
          [isOverBurden
            ? 'obSummaries'
            : 'coalSummaries']: action.payload
        };
      case stateKey.ob.summaryData:
      case stateKey.coal.summaryData: {
        const momentPeriod = (unit, item) => {
          return moment(item, toLower(unit) === UNIT.DAY ? 'DD MMM YYYY' : 'MMM YYYY')
            .format(toLower(unit) === UNIT.DAY ? 'DD_MMM_YYYY' : 'MMM_YYYY');
        };

        const newObSummaries = state.obSummaries.length
          ? state.obSummaries.map((item) =>
            calculateSummary(
              true,
              unitRef.current,
              periodNameRef.current,
              periodRef.current,
              state.obHeaderData,
              state.obTableData,
              state.coalHeaderData,
              state.coalTableData,
              item.key,
              item,
              momentPeriod
            )
          )
          : state.obSummaries;

        const newCoalSummaries = state.coalSummaries.length
          ? state.coalSummaries.map((item) =>
            calculateSummary(
              false,
              unitRef.current,
              periodNameRef.current,
              periodRef.current,
              state.obHeaderData,
              state.obTableData,
              state.coalHeaderData,
              state.coalTableData,
              item.key,
              item,
              momentPeriod
            )
          )
          : state.coalSummaries;
        
        if (prodTypeRef.current === 'overBurden') {
          return {
            ...state,
            obSummaries: newObSummaries,
            coalSummaries: newCoalSummaries
          };
        }
        
        return {
          ...state,
          coalSummaries: newCoalSummaries
        };
      }
      case 'latest_data':
        return {
          ...state,
          latestData: action.payload
        };
      default:
        return {
          ...state
        };
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  
  const {
    data: assumption,
    isFetching: assumptionIsFetching,
    isError: assumptionIsError,
    error: assumptionError
  } = useGetAssumptionByIdQuery(
    { id: assumptionId },
    { refetchOnMountOrArgChange: true }
  );

  const [getLossTimeById, {
    data: lossTimeByIdData,
    isFetching: lossTimeByIdDataIsFetching,
    isError: lossTimeByIdDataIsError,
    error: lossTimeByIdDataError
  }] = useLazyGetLossTimeByIdQuery();

  const [getLatestLossTime, {
    data: latestLossTimeData,
    isFetching: latestLossTimeDataIsFetching,
    isError: latestLossTimeDataIsError,
    error: latestLossTimeDataError
  }] = useLazyGetLatestLossTimeQuery();
  
  const [postLossTime, {
    isLoading: lossTimeIsLoading,
    isSuccess: lossTimeIsSuccess,
    isError: lossTimeIsError,
    error: lossTimeError
  }] = usePostLossTimeMutation();

  const [putLossTime, {
    isLoading: putLossTimeIsLoading,
    isSuccess: putLossTimeIsSuccess,
    isError: putLossTimeIsError,
    error: putLossTimeError
  }] = usePutLossTimeMutation();

  const productionTypes = [
    { key: 'overBurden', title: 'Overburden Production Loss Time', subtitle: 'Overburden Loss Time' },
    { key: 'coal', title: 'Coal Production Loss Time', subtitle: 'Coal Loss Time' }
  ];
  
  const momentPeriod = (unit, item) => {
    return moment(item, toLower(unit) === UNIT.DAY ? 'DD MMM YYYY' : 'MMM YYYY')
      .format(toLower(unit) === UNIT.DAY ? 'DD_MMM_YYYY' : 'MMM_YYYY');
  };

  const getSubtitle = () => productionTypes.find((item) => productionType === item.key).subtitle;
  
  const redirectPathToCreate = () => navigate({
    pathname: getFullPath({
      parent: 'assumption',
      child: 'production-loss-time',
      type: 'create'
    }),
    search: createSearchParams({ id: assumptionId }).toString()
  });

  const goToPath = (type) => navigate({
    pathname: getFullPath({
      parent: 'assumption',
      child: type === 'back'
        ? 'form'
        : 'select-over-burden-unit',
      type: getPathType(loc)
    }),
    search: createSearchParams({
      id: assumptionId,
      ...type === 'back'
        ? {
          isBack: isBack
            ? backQueries
            : createBackQueryParams(loc.pathname)
        }
        : {
          ...isPathMaxBackQueryParams(loc.pathname, backQueries)
            ? undefined
            : { isBack: backQueries }
        }
    }).toString()
  });

  const changeProdType = (type) => {
    prodTypeRef.current = type;
    setProductionType(type);
  };

  const handleOnInputChange = (value, record, dataIndex) => {
    dispatch({
      type: stateKey.ob.headerChange,
      payload: { value, record, dataIndex }
    });
    
    dispatch({
      type: stateKey.coal.headerChange,
      payload: { value, record, dataIndex }
    });
  };
  
  const handleOnInputTableChange = (value, record, unitFormats, type) => {
    const cloneRecord = cloneDeep(record);
    const workingDays = (prodTypeRef.current === 'overBurden' ? obHeaderDataRef.current : coalHeaderDataRef.current)
      .find((item) => toLower(item.description) === 'working days');
    const momentPeriod = (item) => moment(item, unitFormats[0]).format(unitFormats[1]);
    const isSameClone = (item) => Boolean(
      (toLower(item.codeKey[0]) === toLower(cloneRecord.codeKey[0]))
      && (toLower(item.description) === toLower(cloneRecord.description))
    );
    
    const calculateSpecificData = (record, workingDays) => {
      switch (true) {
        case record.codeKey[1] === 'sholat_jum\'at':
        case record.codeKey[1] === 'safety_talk_mingguan':
        case record.codeKey[1] === 'safety_talk_bulanan':
          return Number(value);
        case record.codeKey[1] === 'p2h':
          return (((Number(value)/60)*2) * Number(workingDays));
        default:
          return (Number(value) * Number(workingDays));
      }
    };

    cloneRecord.remark = value;

    switch (unitFormats) {
      case 'week': {
        cloneRecord.week = calculateSpecificData(cloneRecord, workingDays.week);

        break;
      }
      default: {
        periodRef.current.forEach((item) => {
          cloneRecord[momentPeriod(item)] = calculateSpecificData(cloneRecord, workingDays[momentPeriod(item)]);
        });

        break;
      }
    }
    
    if (type === 'overBurden') {
      dispatch({
        type: stateKey.ob.tableChange,
        payload: { isSameClone, cloneRecord, type }
      });
    } else {
      dispatch({
        type: stateKey.coal.tableChange,
        payload: { isSameClone, cloneRecord, type }
      });
    }
  };
  
  const recalculateTable = () => {
    const isObDataAvailable = Boolean(state.obTableData && state.obTableData.length);
    const isCoalDataAvailable = Boolean(state.coalTableData && state.coalTableData.length);

    const generateUnitFormat = () => {
      switch (true) {
        case toLower(periodNameRef.current) === 'weekly':
          return 'week';
        case toLower(periodNameRef.current) === 'monthly':
          return ['MMM YYYY', 'MMM_YYYY'];
        default:
          return toLower(unitRef.current) === UNIT.DAY
            ? ['DD MMM YYYY', 'DD_MMM_YYYY']
            : ['MMM YYYY', 'MMM_YYYY'];
      }
    };

    if (isObDataAvailable && isCoalDataAvailable) {
      if (prodTypeRef.current === 'overBurden') {
        state.obTableData.forEach((record) => {
          handleOnInputTableChange(
            record.remark,
            record,
            generateUnitFormat(),
            'overBurden'
          );
        });

        state.coalTableData.forEach((record) => {
          handleOnInputTableChange(
            record.remark,
            record,
            generateUnitFormat(),
            'coal'
          );
        });

        return;
      }

      state.coalTableData.forEach((record) => {
        handleOnInputTableChange(
          record.remark,
          record,
          generateUnitFormat(),
          'coal'
        );
      });
    }
  };

  const postProduction = (isDraft) => {
    isDraftRef.current = isDraft;

    const body = generateBody(
      isDraftRef.current,
      assumptionId,
      periodRef.current,
      periodNameRef.current,
      unitRef.current,
      state.obHeaderData,
      state.obTableData,
      state.obSummaries,
      state.coalHeaderData,
      state.coalTableData,
      state.coalSummaries,
      momentPeriod
    );

    if (isBack || isEditMode) {
      const { assumption_id, ...item } = body;
      putLossTime({
        id: lossTimeIdRef.current,
        body: { ...item }
      });

      return;
    }

    postLossTime(body);
  };

  useEffect(() => {
    window.addEventListener('resize', () => {
      dispatch({ type: 'resize' });
    });

    return () => {
      window.removeEventListener('resize', () => {});

      resetLatestState('production');
    };
  }, []);

  useEffect(() => {
    if (isEditMode) {
      currentTypeRef.current = getPathType(loc);

      return;
    }

    if (currentTypeRef.current === 'edit' && getPathType(loc) === 'create') {
      window.location.reload();
    }
  }, [loc]);
  
  useEffect(() => {
    periodNameRef.current = toLower(assumption?.category?.name);
    unitRef.current = toLower(assumption?.category?.unit); 
    periodRef.current = convertPeriodToTags(
      unitRef.current,
      assumption?.start_date,
      assumption?.end_date
    );

    if (isBack || isDetailMode || isEditMode) {
      getLossTimeById({ id: assumptionId });

      /**
       * Header Section
       * */
      dispatch({
        type: stateKey.ob.headerColumn,
        key: stateKey.ob.key,
        payload: generateHeaderColumns(
          isDetailMode,
          'overBurden',
          unitRef.current,
          periodNameRef.current,
          periodRef.current,
          handleOnInputChange
        ) 
      });
      
      dispatch({
        type: stateKey.coal.headerColumn,
        key: stateKey.coal.key,
        payload: generateHeaderColumns(
          isDetailMode,
          'coal',
          unitRef.current,
          periodNameRef.current,
          periodRef.current,
          handleOnInputChange
        )
      });

      /**
       * Table Section
       * */
      dispatch({
        type: stateKey.ob.tableColumn,
        key: stateKey.ob.key,
        payload: generateTableColumns(
          isDetailMode,
          'overBurden',
          unitRef.current,
          periodNameRef.current,
          periodRef.current,
          handleOnInputTableChange
        )
      });

      dispatch({
        type: stateKey.coal.tableColumn,
        key: stateKey.coal.key,
        payload: generateTableColumns(
          isDetailMode,
          'coal',
          unitRef.current,
          periodNameRef.current,
          periodRef.current,
          handleOnInputTableChange
        )
      });
      /**
       * Summary Section
       * */
      dispatch({
        type: stateKey.ob.summaryColumn,
        key: stateKey.ob.key,
        payload: generateTableSummaries(
          unitRef.current,
          periodNameRef.current,
          periodRef.current
        )
      });
      
      dispatch({
        type: stateKey.coal.summaryColumn,
        key: stateKey.coal.key,
        payload: generateTableSummaries(
          unitRef.current,
          periodNameRef.current,
          periodRef.current
        ) 
      });

      return;
    }
    
    /**
     * Header Section
     * */
    dispatch({
      type: stateKey.ob.headerColumn,
      key: stateKey.ob.key,
      payload: generateHeaderColumns(
        isDetailMode,
        'overBurden',
        unitRef.current,
        periodNameRef.current,
        periodRef.current,
        handleOnInputChange
      )
    });
    
    dispatch({
      type: stateKey.coal.headerColumn,
      key: stateKey.coal.key,
      payload: generateHeaderColumns(
        isDetailMode,
        'coal',
        unitRef.current,
        periodNameRef.current,
        periodRef.current,
        handleOnInputChange
      )
    });
    
    dispatch({
      type: stateKey.ob.headerData,
      key: stateKey.ob.key,
      payload: generateHeaderDefaultData(
        unitRef.current,
        periodNameRef.current,
        periodRef.current
      )
    });
    
    dispatch({
      type: stateKey.coal.headerData,
      key: stateKey.coal.key,
      payload: generateHeaderDefaultData(
        unitRef.current,
        periodNameRef.current,
        periodRef.current
      )
    });
    
    /**
     * Table Section
     * */
    dispatch({
      type: stateKey.ob.tableColumn,
      key: stateKey.ob.key,
      payload: generateTableColumns(
        isDetailMode,
        'overBurden',
        unitRef.current,
        periodNameRef.current,
        periodRef.current,
        handleOnInputTableChange
      )
    });

    dispatch({
      type: stateKey.ob.tableData,
      key: stateKey.ob.key,
      payload: generateTableDefaultData(
        unitRef.current,
        periodNameRef.current,
        periodRef.current
      )
    });

    dispatch({
      type: stateKey.coal.tableColumn,
      key: stateKey.coal.key,
      payload: generateTableColumns(
        isDetailMode,
        'coal',
        unitRef.current,
        periodNameRef.current,
        periodRef.current,
        handleOnInputTableChange
      )
    });

    dispatch({
      type: stateKey.coal.tableData,
      key: stateKey.coal.key,
      payload: generateTableDefaultData(
        unitRef.current,
        periodNameRef.current,
        periodRef.current
      )
    });

    /**
     * Summary Section
     * */
    dispatch({
      type: stateKey.ob.summaryColumn,
      key: stateKey.ob.key,
      payload: generateTableSummaries(
        unitRef.current,
        periodNameRef.current,
        periodRef.current
      )
    });

    dispatch({
      type: stateKey.coal.summaryColumn,
      key: stateKey.coal.key,
      payload: generateTableSummaries(
        unitRef.current,
        periodNameRef.current,
        periodRef.current
      )
    });

    getLatestLossTime({ id: assumptionId });
  }, [assumption]);

  useEffect(() => {
    if (isEditMode && shouldRedirect(lossTimeByIdData)) {
      redirectPathToCreate();

      return;
    }

    isDetailInitialization.current = true;
    isDetailTableInitialization.current = true;
    lossTimeIdRef.current = lossTimeByIdData?.id;

    dispatch({
      type: stateKey.ob.headerData,
      key: stateKey.ob.key,
      payload: generateHeaderDefaultData(
        unitRef.current,
        periodNameRef.current,
        periodRef.current,
        lossTimeByIdData,
        'production_lost_time_ob'
      )
    });

    dispatch({
      type: stateKey.coal.headerData,
      key: stateKey.coal.key,
      payload: generateHeaderDefaultData(
        unitRef.current,
        periodNameRef.current,
        periodRef.current,
        lossTimeByIdData,
        'production_lost_time_coal'
      )
    });

    dispatch({
      type: stateKey.ob.tableData,
      key: stateKey.ob.key,
      payload: generateTableDefaultData(
        unitRef.current,
        periodNameRef.current,
        periodRef.current,
        lossTimeByIdData,
        'production_lost_time_ob'
      )
    });

    dispatch({
      type: stateKey.coal.tableData,
      key: stateKey.coal.key,
      payload: generateTableDefaultData(
        unitRef.current,
        periodNameRef.current,
        periodRef.current,
        lossTimeByIdData,
        'production_lost_time_coal'
      )
    });
  }, [lossTimeByIdData]);

  useEffect(() => {
    if (!isEmpty(latestLossTimeData)
      && !Boolean(latestLossTimeData?.error)
      && !isEmpty(latestLossTimeData?.previous_contents)
    ) {
      dispatch({
        type: 'latest_data',
        payload: latestLossTimeData.previous_contents
      });
    }
  }, [latestLossTimeData]);

  useEffect(() => {
    const isShouldIntegrateWithLatest = Boolean(
      !isEmpty(state.obHeaderData)
      && !isEmpty(state.obTableData)
      && !isEmpty(state.coalHeaderData)
      && !isEmpty(state.coalTableData)
      && !isEmpty(state.latestData)
    );

    if (isCreateMode
      && !isBack
      && isShouldIntegrateWithLatest
    ) {
      dispatch({
        type: stateKey.ob.headerData,
        key: stateKey.ob.key,
        payload: generateHeaderDefaultData(
          unitRef.current,
          periodNameRef.current,
          periodRef.current,
          { contents: state.latestData },
          'production_lost_time_ob',
          true
        )
      });

      dispatch({
        type: stateKey.coal.headerData,
        key: stateKey.coal.key,
        payload: generateHeaderDefaultData(
          unitRef.current,
          periodNameRef.current,
          periodRef.current,
          { contents: state.latestData },
          'production_lost_time_coal',
          true
        )
      });

      dispatch({
        type: stateKey.ob.tableData,
        key: stateKey.ob.key,
        payload: generateTableDefaultData(
          unitRef.current,
          periodNameRef.current,
          periodRef.current,
          { contents: state.latestData },
          'production_lost_time_ob',
          true
        )
      });

      dispatch({
        type: stateKey.coal.tableData,
        key: stateKey.coal.key,
        payload: generateTableDefaultData(
          unitRef.current,
          periodNameRef.current,
          periodRef.current,
          { contents: state.latestData },
          'production_lost_time_coal',
          true
        )
      });
    }
  }, [state.latestData]);
  
  useEffect(() => {
    obHeaderDataRef.current = state.obHeaderData;
    coalHeaderDataRef.current = state.coalHeaderData;

    if (isDetailInitialization.current) {
      isDetailInitialization.current = false;

      return;
    }

    recalculateTable();
    setIsValid(
      validateData(
        unitRef.current,
        periodNameRef.current,
        periodRef.current,
        state.obHeaderData,
        state.coalHeaderData,
        state.obTableData,
        state.coalTableData
      )
    );

    if (!isEqual(production?.obHeaderData, state.obHeaderData)
      || !isEqual(production?.coalHeaderData, state.coalHeaderData)
    ) {
      saveLatestState(
        'production',
        {
          obHeaderData: state.obHeaderData,
          coalHeaderData: state.coalHeaderData,
          obTableData: state.obTableData,
          coalTableData: state.coalTableData
        }
      );
    }
  }, [state.obHeaderData, state.coalHeaderData]);

  useEffect(() => {
    obTableDataRef.current = state.obTableData;
    coalTableDataRef.current = state.coalTableData;

    if (isDetailTableInitialization.current) {
      if (!isEmpty(state.obTableData) && !isEmpty(state.coalTableData)) {
        isDetailTableInitialization.current = false;
        recalculateTable();

        return;
      }
    }

    dispatch({
      type: stateKey.ob.summaryData,
      key: stateKey.ob.key
    });

    dispatch({
      type: stateKey.coal.summaryData,
      key: stateKey.coal.key
    });

    setIsValid(
      validateData(
        unitRef.current,
        periodNameRef.current,
        periodRef.current,
        state.obHeaderData,
        state.coalHeaderData,
        state.obTableData,
        state.coalTableData
      )
    );

    if (!isEqual(production?.obTableData, state.obTableData)
      || !isEqual(production?.coalTableData, state.coalTableData)
    ) {
      saveLatestState(
        'production',
        {
          obHeaderData: state.obHeaderData,
          coalHeaderData: state.coalHeaderData,
          obTableData: state.obTableData,
          coalTableData: state.coalTableData
        }
      );
    }
  }, [state.obTableData, state.coalTableData]);

  useEffect(() => {
    if (assumptionIsError
      || lossTimeByIdDataIsError
      || latestLossTimeDataIsError
    ) {
      const generateToastId = () => {
        switch (true) {
          case assumptionIsError:
            return 'assumption';
          case lossTimeByIdDataIsError:
            return 'loss-time-by-id';
          case latestLossTimeDataIsError:
            return 'latest-loss_time';
          default:
            return 'default';
        }
      };
      
      toast.error(
        transformError(assumptionError
          || lossTimeByIdDataError
          || latestLossTimeDataError
        ).message,
        { toastId: `${generateToastId()}-toast-error` }
      );
    }
  }, [
    assumptionIsError,
    assumptionError,
    lossTimeByIdDataIsError,
    lossTimeByIdDataError,
    latestLossTimeDataIsError,
    latestLossTimeDataError
  ]);

  useEffect(() => {
    if (lossTimeIsSuccess || putLossTimeIsSuccess) {
      if (isDraftRef.current) {
        navigate(`/${URL.ACTIVITY_LEVEL}/${URL.MINE_PLANNING_OE}/assumption`);

        return;
      }

      goToPath('next');

      return;
    }
    
    if (lossTimeIsError || putLossTimeIsError) {
      toast.error(
        transformError(lossTimeError || putLossTimeError).message,
        { toastId: 'post-production-toast-error' }
      );

      return;
    }

    return () => {};
  }, [
    lossTimeIsSuccess,
    lossTimeIsError,
    lossTimeError,
    putLossTimeIsSuccess,
    putLossTimeIsError,
    putLossTimeError
  ]);
  
  return (
    <div className="flex flex-col">
      <BannerPitPeriod
        isLoading={assumptionIsFetching}
        companyName={assumption?.company?.alias_name}
        contractorName={assumption?.contractor?.alias_name}
        pitName={assumption?.pit?.name}
        periodMode={assumption?.category?.name}
        periods={assumption
          ? periodRef.current
          : []}
      />

      <div className="my-5 flex flex-row items-center gap-x-3">
        {
          productionTypes.map((item, index) => (
            <div
              key={`type-${index + 1}`}
              className={`p-2 w-1/2 flex flex-row rounded-lg gap-x-3 cursor-pointer
              ${productionType === item.key ? 'bg-[#2D3D5A]' : 'bg-black/5'}`}
              onClick={() => changeProdType(item.key)}
            >
              <div className={`w-2 rounded ${productionType === item.key ? 'bg-white' : 'bg-[#2D3D5A]'}`} />
              <span className={`font-bold ${productionType === item.key ? 'text-white' : 'text-[#2D3D5A]'}`}>
                {item.title}
              </span>
            </div>
          ))
        }
      </div>

      <div className="p-5 flex flex-col bg-white card rounded-lg">
        <div className="flex flex-row items-center">
          <span className="mb-5 font-bold text-lg">
            {getSubtitle()}
          </span>
          {
            (isBack || isDetailMode || isEditMode)
              ? (
                <div className="ml-auto">
                  {
                    (lossTimeByIdDataIsFetching || latestLossTimeDataIsFetching) && (<LoadingText />)
                  }
                </div>
              )
              : (<></>)
          }
        </div>

        <div
          className="overflow-x-auto"
          style={{ maxWidth: wrapperWidth }}
        >
          <TableRaw
            scrollWidth="max-content"
            columns={
              productionType === 'overBurden'
                ? state.obHeaderColumns
                : state.coalHeaderColumns
            }
            dataSource={
              productionType === 'overBurden'
                ? state.obHeaderData
                : state.coalHeaderData
            }
          />
        </div>

        <Divider />

        <div
          className="overflow-x-auto"
          style={{ maxWidth: wrapperWidth }}
        >
          <TableRaw
            scrollWidth="max-content"
            columns={
              productionType === 'overBurden'
                ? state.obTableColumns
                : state.coalTableColumns
            }
            dataSource={
              productionType === 'overBurden'
                ? state.obTableData
                : state.coalTableData
            }
            summary={() => (
              <Table.Summary>
                {
                  prodTypeRef.current === 'overBurden'
                    ? (
                      <>
                        {
                          (state.obSummaries && state.obSummaries.length)
                          && state.obSummaries.map((item, index) => (
                            <Table.Summary.Row
                              key={`key-sum-${index}`}
                              className="font-bold bg-pink-700/25 text-pink-700"
                            >
                              <Table.Summary.Cell
                                index={0}
                                colSpan={2}
                                className="rounded-tl-lg rounded-bl-lg"
                              >
                                {item.title}
                              </Table.Summary.Cell>
                              <Table.Summary.Cell index={1}>
                                {item.unit}
                              </Table.Summary.Cell>
                              <Table.Summary.Cell index={2}>
                                {item.period}
                              </Table.Summary.Cell>
                              {
                                generateSummaryColumns(periodNameRef.current, periodRef.current)
                                  .map((period, pIndex) => (
                                    <Table.Summary.Cell
                                      key={`key-sum-${(3 + pIndex)}`}
                                      index={(3 + pIndex)}
                                      className={pIndex === (periodRef.current.length - 1) && 'rounded-tr-lg rounded-br-lg'}
                                    >
                                      {
                                        pickSummaryData(
                                          unitRef.current,
                                          periodNameRef.current,
                                          period,
                                          item
                                        )
                                      }
                                    </Table.Summary.Cell>
                                  ))
                              }
                            </Table.Summary.Row>
                          ))
                        }
                      </>
                    )
                    : (
                      <>
                        {
                          (state.coalSummaries && state.coalSummaries.length)
                          && state.coalSummaries.map((item, index) => (
                            <Table.Summary.Row
                              key={`key-sum-${index}`}
                              className="font-bold bg-pink-700/25 text-pink-700"
                            >
                              <Table.Summary.Cell
                                index={0}
                                colSpan={2}
                                className="rounded-tl-lg rounded-bl-lg"
                              >
                                {item.title}
                              </Table.Summary.Cell>
                              <Table.Summary.Cell index={1}>
                                {item.unit}
                              </Table.Summary.Cell>
                              <Table.Summary.Cell index={2}>
                                {item.period}
                              </Table.Summary.Cell>
                              {
                                generateSummaryColumns(periodNameRef.current, periodRef.current)
                                  .map((period, pIndex) => (
                                    <Table.Summary.Cell
                                      key={`key-sum-${(3 + pIndex)}`}
                                      index={(3 + pIndex)}
                                      className={pIndex === (periodRef.current.length - 1) && 'rounded-tr-lg rounded-br-lg'}
                                    >
                                      {
                                        pickSummaryData(
                                          unitRef.current,
                                          periodNameRef.current,
                                          period,
                                          item
                                        )
                                      }
                                    </Table.Summary.Cell>
                                  ))
                              }
                            </Table.Summary.Row>
                          ))
                        }
                      </>
                    )
                }
              </Table.Summary>
            )}
          />
        </div>
      </div>

      <div className="mt-5 w-full flex flex-row items-center">
        <ButtonAccent
          isDisabled={isDetailMode}
          isLoading={lossTimeIsLoading || putLossTimeIsLoading}
          size="lg"
          title="Save As Draft"
          onClick={() => postProduction(true)}
        />

        <div className="ml-auto flex flex-row items-center gap-x-3">
          <ButtonAccent
            isBordered
            title="Back"
            onClick={() => goToPath('back')}
          />
          <ButtonAccent
            isDisabled={!isValid}
            isLoading={lossTimeIsLoading || putLossTimeIsLoading}
            title="Next"
            onClick={() => isDetailMode
              ? goToPath('next')
              : postProduction(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default Production;