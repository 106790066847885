import { DatePicker, Divider } from 'antd';
import { useGetMasterDataGoeCompaniesQuery } from 'api/MasterData';
import { useGetReconciliationListQuery } from 'api/Reconciliation';
import ButtonAccent from 'components/ButtonAccent';
import FilterContainer from 'components/FilterContainer';
import SelectSearchAccent from 'components/SelectSearchAccent';
import TableData from 'components/TableData';
import { ACTION } from 'constant';
import { generateReconciliationTable } from 'constant/TableReconciliation';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { transformError } from 'utils/ErrorTransformer';
import { getFullPath } from 'utils/PathUtility';

const Reconciliation = () => {
  const navigate = useNavigate();
  let initialFilter = {
    mine_site: undefined,
    year_of_period: undefined
  };
  const {
    page,
    pageSize,
  } = useSelector((state) => state.table);
  const [filter, setFilter] = useState(initialFilter);

  const { 
    data: dataCompanies, 
    isFetching: companiesIsFetching, 
    isError: companyIsError,
    error: companyError
  } = useGetMasterDataGoeCompaniesQuery({ refetchOnMountOrArgChange: true });

  const {
    data: reconciliationsData,
    isFetching: reconciliationsIsFetching,
    isError: reconciliationsIsError,
    error: reconciliationsError
  } = useGetReconciliationListQuery(
    {
      page,
      limit: pageSize,
      year: filter.year_of_period? moment(new Date(filter.year_of_period)).format('YYYY'):undefined,
      company: filter.mine_site
    },
  );

  const resetFilter = () => {
    setFilter(initialFilter);
  };
  const onFilter = (key, value) => {
    setFilter((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const calculatePixel = (remVal) => {
    return `${window.innerWidth - remVal * 16}px`;
  };

  const goToPath = (id, type, child)=> navigate({
    pathname: getFullPath({
      parent: 'reconciliation',
      child: child,
      type
    },'geology-oe'),
    ...(id)
      ? { search: createSearchParams({ id }).toString() }
      : undefined
  });

  const handleActionButton = (id, type, company, year) => {
    let actionType = '';
    if(type === 'edit') {
      actionType = ACTION.EDIT;
    } else {
      actionType = ACTION.DETAIL;
    }
    navigate({
      pathname: getFullPath({
        parent: 'reconciliation',
        child: 'input-actual',
        type: actionType
      },'geology-oe'),
      ...(id)
        ? { search: createSearchParams({ id }).toString() }
        : undefined
    },{state: {company: dataCompanies?.find(item => item.alias_name === company), year} });
  };

  useEffect(() => {
    if (companyIsError || reconciliationsIsError) {
      const generateToastId = () => {
        switch (true) {
          case companyError:
            return 'get-company';
          case reconciliationsError:
            return 'get-reconciliation-list';
          default:
            return 'default';
        }
      };
      
      toast.error(
        transformError(companyError || reconciliationsError ).message,
        { toastId: `${generateToastId()}-toast-error` }
      );
    }
  }, [companyIsError, reconciliationsIsError]);
  return (
    <div className="mt-5 bg-white p-6 rounded-2xl">
      <FilterContainer onReset={resetFilter}>
        <label>
          <span className="font-bold">Year of Period</span>
          <DatePicker.YearPicker
            allowClear
            className="mt-2 w-full h-12"
            value={filter['year_of_period']}
            onChange={(val) => onFilter('year_of_period', val)}
          />
        </label>
        <label>
          <span className="font-bold">Mine Site</span>
          <SelectSearchAccent
            onChange={(val) => onFilter('mine_site', val)}
            value={filter['mine_site']||null}
            labelKey="alias_name"
            valueKey="alias_name"
            options={dataCompanies}
            placeholder="Select mine site"
            isLoading={companiesIsFetching}
            className={'h-12 mt-2'}
          />
        </label>
      </FilterContainer>
      <Divider />
      <div
        className="mt-5 overflow-x-auto"
        style={{ maxWidth: calculatePixel(23) }}
      > 
        <TableData
          scrollHeight={'50vh'}
          isLoading={reconciliationsIsFetching}
          notSearchable={true}
          scrollWidth="max-content"
          columns={generateReconciliationTable(handleActionButton)}
          dataSource={reconciliationsData?.data}
          totalData={reconciliationsData?.total_data}
          rowKey='id'
          headerBg='#B1BACB20'
          headerColor='black'
        >
          <ButtonAccent
            className={'h-12 w-44'}
            title="Input Actual"
            onClick={() => goToPath(null, ACTION.CREATE, 'input-plan')}
          />
        </TableData>
      </div>
    </div>
  );
};

export default Reconciliation;