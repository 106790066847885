import { generateSampleInformationTable } from 'constant/TableReconciliation/TableSampleInformation';
import TableDrillingSumarry from 'pages/DrillingPlan/DrillingSummary/TableDrillingSummary';

const SampleInformation = (props) => {
  const { sampleInformationData, onChange, removeRowTable, locationData, pitNameData, isDetailMode } = props;
  return <TableDrillingSumarry 
    columns={generateSampleInformationTable(sampleInformationData, onChange, removeRowTable, locationData, pitNameData, isDetailMode)} 
    tableData={sampleInformationData} 
  />;
};

export default SampleInformation;