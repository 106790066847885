import { isEmpty } from 'lodash';
import { calculateCheckWellCad } from './TableDrillingDateActual';
import { IoMdCloudDownload } from 'react-icons/io';
import { EMPTY } from 'constant';
const { DatePicker, Tooltip } = require('antd');
const dayjs = require('dayjs');
const { RxCross2 } = require('react-icons/rx');

const generateDrillingEvidenceTable = (isDetailMode, payload, onChange, onDeleteRow, otherData, downloadFile) =>[
  {
    dataIndex: 'action',
    title: 'Action',
    width: 5,
    sorter: false,
    align: 'center',
    fixed: 'left',
    render: (_, __, index) => {
      return (
        <div className="flex justify-center">
          {payload.length <= 1 ? (
            <></>
          ) : (
            <RxCross2
              size={28}
              color={`${isDetailMode? 'gray' : 'red'}`}
              className={`rounded-full p-1 ${isDetailMode? 'bg-gray-200':'cursor-pointer bg-red-200 '}`}
              onClick={() => onDeleteRow(index)}
            />
          )}
        </div>
      );
    },
  },
  {
    title:'Soft File PDF 20',
    children:[
      {
        title:'Date',
        dataIndex:'soft_pdf_20',
        width: '196px',
        sorter: false,
        align: 'center',
        render: (value, _, index) => {
          return (
            <DatePicker
              allowClear
              value={value?.date? dayjs(value?.date,'DD/MM/YYYY'):''}
              format={'DD/MM/YYYY'}
              disabled={isDetailMode}
              placeholder="dd/mm/yyyy"
              className="w-full text-left"
              onChange={(val) => {
                onChange(index, 'drilling_evidence', 'soft_pdf_20',val? dayjs(val).format('DD/MM/YYYY') : null);
              }}
              status={!isDetailMode && !value.date ? 'error' : EMPTY.STRING}
            />
          );
        },
      },
      {
        title:'Upload Evidence',
        dataIndex:'soft_pdf_20',
        width: '196px',
        sorter: false,
        align: 'center',
        render: (value) => {
          return !isEmpty(value?.files) && (
            <div className='flex flex-row gap-2 justify-center'>
                1 File Uploaded 
              <Tooltip title={'Download'} trigger={'hover'}>
                <IoMdCloudDownload 
                  className='cursor-pointer' size={24} 
                  onClick={() => downloadFile(value?.files)} /> 
              </Tooltip>
            </div>
          );
        },
      }
    ]
  },
  {
    title:'Soft File PDF 100',
    children:[
      {
        title:'Date',
        dataIndex:'soft_pdf_100',
        width: '196px',
        sorter: false,
        align: 'center',
        render: (value, _, index) => {
          return (
            <DatePicker
              allowClear
              value={value?.date? dayjs(value?.date,'DD/MM/YYYY'):''}
              format={'DD/MM/YYYY'}
              disabled={isDetailMode}
              placeholder="dd/mm/yyyy"
              className="w-full text-left"
              onChange={(val) => {
                onChange(index, 'drilling_evidence', 'soft_pdf_100', val? dayjs(val).format('DD/MM/YYYY') : null);
              }}
              status={!isDetailMode && !value.date ? 'error' : EMPTY.STRING}
            />
          );
        },
      },
      {
        title:'Upload Evidence',
        dataIndex:'soft_pdf_100',
        width: '196px',
        sorter: false,
        align: 'center',
        render: (value) => {
          return !isEmpty(value?.files) && (
            <div className='flex flex-row gap-2 justify-center'>
                1 File Uploaded 
              <Tooltip title={'Download'} trigger={'hover'}>
                <IoMdCloudDownload 
                  className='cursor-pointer' 
                  size={24} 
                  onClick={() => downloadFile(value?.files)} /> 
              </Tooltip>
            </div>
          );
        },
      }
    ]
  },
  {
    title:'Hard 20',
    children:[
      {
        title:'Date',
        dataIndex:'hard_20',
        width: '196px',
        sorter: false,
        align: 'center',
        render: (value, _, index) => {
          return (
            <DatePicker
              allowClear
              value={value?.date? dayjs(value?.date,'DD/MM/YYYY'):''}
              format={'DD/MM/YYYY'}
              disabled={isDetailMode}
              placeholder="dd/mm/yyyy"
              className="w-full text-left"
              onChange={(val) => {
                onChange(index, 'drilling_evidence', 'hard_20',val? dayjs(val).format('DD/MM/YYYY'): null);
              }}
              status={!isDetailMode && !value.date ? 'error' : EMPTY.STRING}
            />
          );
        },
      },
      {
        title:'Upload Evidence',
        dataIndex:'hard_20',
        width: '196px',
        sorter: false,
        align: 'center',
        render: (value) => {
          return !isEmpty(value?.files) && (
            <div className='flex flex-row gap-2 justify-center'>
                1 File Uploaded 
              <Tooltip title={'Download'} trigger={'hover'}>
                <IoMdCloudDownload 
                  className='cursor-pointer' 
                  size={24} 
                  onClick={() => downloadFile(value?.files)} /> 
              </Tooltip>
            </div>
          );
        },
      }
    ]
  },
  {
    title:'Hard 100',
    children:[
      {
        title:'Date',
        dataIndex:'hard_100',
        width: '196px',
        sorter: false,
        align: 'center',
        render: (value, _, index) => {
          return (
            <DatePicker
              allowClear
              value={value?.date? dayjs(value?.date,'DD/MM/YYYY'):''}
              format={'DD/MM/YYYY'}
              disabled={isDetailMode}
              placeholder="dd/mm/yyyy"
              className="w-full text-left"
              onChange={(val) => {
                onChange(index, 'drilling_evidence', 'hard_100',val? dayjs(val).format('DD/MM/YYYY'): null);
              }}
              status={!isDetailMode && !value.date ? 'error' : EMPTY.STRING}
            />
          );
        },
      },
      {
        title:'Upload Evidence',
        dataIndex:'hard_100',
        width: '196px',
        sorter: false,
        align: 'center',
        render: (value) => {
          return !isEmpty(value?.files) && (
            <div className='flex flex-row gap-2 justify-center'>
                1 File Uploaded 
              <Tooltip title={'Download'} trigger={'hover'}>
                <IoMdCloudDownload 
                  className='cursor-pointer' 
                  size={24} 
                  onClick={() => downloadFile(value?.files)} /> 
              </Tooltip>
            </div>
          );
        },
      }
    ]
  },
  {
    title:'Photobox',
    children:[
      {
        title:'Date',
        dataIndex:'photo_core_box',
        width: '196px',
        sorter: false,
        align: 'center',
        render: (value, _, index) => {
          return (
            <DatePicker
              allowClear
              value={value?.date? dayjs(value?.date,'DD/MM/YYYY'):''}
              format={'DD/MM/YYYY'}
              disabled={isDetailMode}
              placeholder="dd/mm/yyyy"
              className="w-full text-left"
              onChange={(val) => {
                onChange(index, 'drilling_evidence', 'photobox',val? dayjs(val).format('DD/MM/YYYY'): null);
              }}
              status={!isDetailMode && !value.date ? 'error' : EMPTY.STRING}
            />
          );
        },
      },
      {
        title:'Upload Evidence',
        dataIndex:'photo_core_box',
        width: '196px',
        sorter: false,
        align: 'center',
        render: (value) => {
          return !isEmpty(value?.files) && (
            <div className='flex flex-row gap-2 justify-center'>
                1 File Uploaded 
              <Tooltip title={'Download'} trigger={'hover'}>
                <IoMdCloudDownload 
                  className='cursor-pointer' 
                  size={24} 
                  onClick={() => downloadFile(value?.files)} /> 
              </Tooltip>
            </div>
          );
        },
      }
    ]
  },
  {
    title:'Well CAD',
    children:[
      {
        title:'Date',
        dataIndex:'wellcad',
        width: '196px',
        sorter: false,
        align: 'center',
        render: (value, _, index) => {
          return (
            <DatePicker
              allowClear
              value={value?.date? dayjs(value?.date,'DD/MM/YYYY'):''}
              format={'DD/MM/YYYY'}
              disabled={isDetailMode}
              placeholder="dd/mm/yyyy"
              className="w-full text-left"
              onChange={(val) => {
                onChange(index, 'drilling_evidence', 'wellcad',val? dayjs(val).format('DD/MM/YYYY'): null);
                onChange(index, 'drilling_date', 'check_date_final_log_wellcad', calculateCheckWellCad(index, otherData));
              }}
              status={!isDetailMode && !value.date ? 'error' : EMPTY.STRING}
            />
          );
        },
      },
      {
        title:'Upload Evidence',
        dataIndex:'wellcad',
        width: '196px',
        sorter: false,
        align: 'center',
        render: (value) => {
          return !isEmpty(value?.files) && (
            <div className='flex flex-row gap-2 justify-center'>
                1 File Uploaded 
              <Tooltip title={'Download'} trigger={'hover'}>
                <IoMdCloudDownload 
                  className='cursor-pointer' 
                  size={24} 
                  onClick={() => downloadFile(value?.files)} /> 
              </Tooltip>
            </div>
          );
        },
      }
    ]
  },
  {
    title:'LAS File',
    children:[
      {
        title:'Date',
        dataIndex:'las_file',
        width: '196px',
        sorter: false,
        align: 'center',
        render: (value, _, index) => {
          return (
            <DatePicker
              allowClear
              value={value?.date? dayjs(value?.date,'DD/MM/YYYY'):''}
              format={'DD/MM/YYYY'}
              disabled={isDetailMode}
              placeholder="dd/mm/yyyy"
              className="w-full text-left"
              onChange={(val) => {
                onChange(index, 'drilling_evidence', 'las_file',val? dayjs(val).format('DD/MM/YYYY'): null);
              }}
              status={!isDetailMode && !value.date ? 'error' : EMPTY.STRING}
            />
          );
        },
      },
      {
        title:'Upload Evidence',
        dataIndex:'las_file',
        width: '196px',
        sorter: false,
        align: 'center',
        render: (value) => {
          return !isEmpty(value?.files) && (
            <div className='flex flex-row gap-2 justify-center'>
                1 File Uploaded 
              <Tooltip title={'Download'} trigger={'hover'}>
                <IoMdCloudDownload 
                  className='cursor-pointer' 
                  size={24} 
                  onClick={() => downloadFile(value?.files)}/> 
              </Tooltip>
            </div>
          );
        },
      }
    ]
  },
];

const generateDrillingEvidenceData = (file) =>{
  if(!isEmpty(file)) {
    const dataDrillHoleName = file.properties[0].drill_hole_information[0].drill_hole_name;
    const data = file.properties[5].drilling_evidence;
    let finalResult = dataDrillHoleName.map((drillHoleName, index) => {
      return data.reduce((result, currentObject, indexObject) => {
        const key = Object.keys(currentObject)[0];
        const value = currentObject[key][index]?.value;
        result[key] = value;
        if(indexObject === data?.length -1){
          result['drill_hole_name'] = drillHoleName?.value;
        }
        return result;
      }, {});
    });
    return finalResult;
  }
};

export { generateDrillingEvidenceTable, generateDrillingEvidenceData };