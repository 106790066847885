import moment from 'moment';
import dayJS from 'dayjs';
import {
  DatePicker,
  Image,
  Input,
  InputNumber
} from 'antd';
import { FiDownload, FiUpload } from 'react-icons/fi';
import { GiPencil } from 'react-icons/gi';
import { isEmpty, toLower } from 'lodash';

import { EMPTY, UNIT } from 'constant';
import { TYPE } from 'constant/MasterData';
import { generateFormat } from 'utils/PeriodUtility';
import {
  formatWithEnLocale,
  parseFromEnLocale,
  preventNonNumber
} from 'utils/CurrencyFormatter';

const formatPercentageOrThousand = (text, record) => {
  if (record.unit === '%') {
    if (isNaN(text)) {
      return '0 %';
    }

    return text
      ? `${Number(text).toFixed(1)} %`
      : EMPTY.NUMBER;
  }
  
  return text
    ? Number(Number(text).toFixed(1)).toLocaleString('en-US')
    : EMPTY.NUMBER;
};

const isError = (isCanBeZero, value) => {
  if (isCanBeZero) {
    return Boolean(value !== null && value !== undefined)
      ? EMPTY.STRING
      : 'error';
  }

  return Boolean(value)
    ? EMPTY.STRING
    : 'error';
};

const generateReserveColumns = (
  isDetailMode,
  onChange,
  onDateChange,
  onUpload,
  onDownload
) => [
  {
    dataIndex: 'description',
    title: 'Description',
    render: (text, record) => (
      <>
        {
          Boolean(record.codeKey === 'waste'
            || record.codeKey === 'coal'
            || record.codeKey === 'min_thickness'
            || record.codeKey === 'loss'
            || record.codeKey === 'dilution')
            ? (
              <span className="ml-20">
                {text}
                {' '}
                <span className="text-red-500">*</span>
              </span>
            )
            : record.codeKey === 'coal_parameter'
              ? text
              : (
                <span>
                  {text}
                  {' '}
                  <span className="text-red-500">*</span>
                </span>
              )
        }
      </>
    )
  },
  {
    dataIndex: 'name',
    title: 'Name',
    render: (text, record, index) => (
      <>
        {
          record.codeKey === 'coal_parameter'
            ? (<></>)
            : (
              <>
                {
                  Boolean(record.codeKey === 'min_thickness' || record.codeKey === 'loss' || record.codeKey === 'dilution')
                    ? (
                      <InputNumber
                        key={`input-${record.codeKey}-${index + 1}`}
                        disabled={isDetailMode}
                        placeholder="Enter Meter"
                        min={EMPTY.NUMBER}
                        value={record.name}
                        onChange={(ev) => onChange(record.codeKey, ev)}
                        onKeyDown={preventNonNumber}
                        status={isError(true, record.name)}
                        parser={parseFromEnLocale}
                        formatter={formatWithEnLocale}
                        className="w-56"
                      />
                    )
                    : (
                      <Input
                        key={`input-${record.codeKey}-${index + 1}`}
                        disabled={isDetailMode}
                        placeholder="Enter Name"
                        className="w-56"
                        value={record.name}
                        onChange={(ev) => onChange(record.codeKey, ev.target.value)}
                        status={isError(false, record.name)}
                      />
                    )
                }
              </>
            )
        }
      </>
    )
  },
  {
    dataIndex: 'update_date',
    title: 'Update Date',
    render: (text, record, index) => (
      <>
        {
          record.codeKey === 'coal_parameter'
            ? (<></>)
            : (
              <DatePicker
                key={`date-picker-${record.codeKey}-${index + 1}`}
                disabled={isDetailMode}
                format="DD MMM YYYY"
                value={record.update_date}
                onChange={(val) => onDateChange(record.codeKey, val)}
                status={isError(false, record.update_date)}
              />
            )
        }
      </>
    )
  },
  {
    dataIndex: 'file',
    title: 'File',
    render: (text, record) => (
      <>
        {/**
         * @param text
         * can be `null`, so we can't use falsy
        */}
        {
          text === false
            ? (
              <></>
            )
            : (
              <>
                <div className="flex flex-row items-center justify-center gap-x-3">
                  <>
                    {
                      record.file
                        ? (
                          <div
                            className="p-1 rounded-full bg-[#2D3D5A] cursor-pointer"
                            onClick={() => onDownload(record.codeKey, record.file)}
                          >
                            <FiDownload
                              size="18px"
                              className="text-white"
                            />
                          </div>
                        )
                        : (
                          <div
                            className="p-1 rounded-full bg-[#2D3D5A] cursor-pointer"
                            onClick={() => onUpload(record.codeKey, record.description)}
                          >
                            <FiUpload
                              size="18px"
                              className="text-white"
                            />
                          </div>
                        )
                    }
                  </>
                  {
                    record.file
                      ? (
                        <>
                          {
                            !isDetailMode && (
                              <div
                                className="p-1 rounded-full bg-[#2D3D5A] cursor-pointer"
                                onClick={() => onUpload(record.codeKey, record.description, record.file)}
                              >
                                <GiPencil
                                  size="18px"
                                  className="text-white"
                                />
                              </div>
                            )
                          }
                        </>
                      )
                      : <></>
                  }
                  {
                    record.file
                      ? (
                        <Image
                          preview={false}
                          width={24}
                          src={record?.file?.thumbnail}
                        />
                      )
                      : <></>
                  }
                </div>
              </>
            )
        }
      </>
    )
  }
];

const reserveDefaultData = [
  {
    codeKey: 'software',
    description: 'Software',
    name: '',
    update_date: '',
    file: false
  },
  {
    codeKey: 'schema',
    description: 'Schema',
    name: '',
    update_date: '',
    file: false
  },
  {
    codeKey: 'geology_model',
    description: 'Geology Model',
    name: '',
    update_date: '',
    file: false
  },
  {
    codeKey: 'topography',
    description: 'Topography',
    name: '',
    update_date: '',
    file: null
  },
  {
    codeKey: 'pit_design',
    description: 'Pit Design',
    name: '',
    update_date: '',
    file: null
  },
  {
    codeKey: 'dump_design',
    description: 'Dump Design',
    name: '',
    update_date: '',
    file: null
  },
  {
    codeKey: 'reserving',
    description: 'Reserving',
    name: '',
    update_date: '',
    file: false
  },
  {
    codeKey: 'waste',
    description: 'Waste',
    name: '',
    update_date: '',
    file: false
  },
  {
    codeKey: 'coal',
    description: 'Coal',
    name: '',
    update_date: '',
    file: false
  },
  {
    codeKey: 'coal_parameter',
    description: 'Coal Parameter',
    name: '',
    update_date: '',
    file: false
  },
  {
    codeKey: 'min_thickness',
    description: 'Min Thickness (Meter)',
    name: 0,
    update_date: '',
    file: false
  },
  {
    codeKey: 'loss',
    description: 'Loss (Meter)',
    name: 0,
    update_date: '',
    file: false
  },
  {
    codeKey: 'dilution',
    description: 'Dilution (Meter)',
    name: 0,
    update_date: '',
    file: false
  }
];

const generateReserveData = (data, isExcludeSome) => {
  if (data) {
    const excludedKeys = ['topography', 'pit_design', 'dump_design'];

    return reserveDefaultData.map((item) => {
      let newItem = { ...item };

      data?.contents?.properties.forEach((propItem) => {
        if (propItem.code === item.codeKey) {
          if (isExcludeSome) {
            newItem = excludedKeys.some((key) => propItem.code === key)
              ? { ...item }
              : {
                codeKey: item.codeKey,
                description: item.description,
                name: propItem.value.name,
                update_date: dayJS(propItem.value.update_date, 'DD-MM-YYYY'),
                /**
                 * `file` can be `false` or `null`
                 * we use `false` to hide icons
                 * */
                file: item.file === false
                  ? false
                  : propItem.value.file
              };

            return newItem;
          }
          
          newItem = {
            codeKey: item.codeKey,
            description: item.description,
            name: propItem.value.name,
            update_date: dayJS(propItem.value.update_date, 'DD-MM-YYYY'),
            /**
               * `file` can be `false` or `null`
               * we use `false` to hide icons
               * */
            file: item.file === false
              ? false
              : propItem.value.file
          };

          return newItem;
        } else {
          if (propItem.children && propItem.children.length) {
            const selectedChild = propItem.children.find((child) => child.code === item.codeKey);
            
            if (selectedChild) {
              if (isExcludeSome) {
                newItem = excludedKeys.some((key) => propItem.code === key)
                  ? { ...item }
                  : {
                    codeKey: item.codeKey,
                    description: item.description,
                    name: selectedChild.value.name,
                    update_date: dayJS(selectedChild.value.update_date, 'DD-MM-YYYY'),
                    /**
                   * `file` can be `false` or `null`
                   * we use `false` to hide icons
                   * */
                    file: item.file === false
                      ? false
                      : selectedChild.value.file
                  };

                return newItem;
              }
              
              newItem = {
                codeKey: item.codeKey,
                description: item.description,
                name: selectedChild.value.name,
                update_date: dayJS(selectedChild.value.update_date, 'DD-MM-YYYY'),
                /**
                 * `file` can be `false` or `null`
                 * we use `false` to hide icons
                 * */
                file: item.file === false
                  ? false
                  : selectedChild.value.file
              };
              
              return newItem; 
            }

            return newItem;
          }

          return newItem;
        }
      });
      
      return newItem;
    });
  }

  return reserveDefaultData;
};

const overBurdenColumns = [
  {
    dataIndex: 'equipment_name',
    title: 'Equipment Name'
  },
  {
    dataIndex: 'equipment_type',
    title: 'Equipment Type'
  },
  {
    dataIndex: 'equipment_no',
    title: 'Equipment No'
  },
  {
    dataIndex: 'capacity',
    title: 'Capacity',
    render: (text, record) => (
      <span>
        {
          text
            ? formatPercentageOrThousand(text, record)
            : '-'
        }
      </span>
    )
  }
];

const coalColumns = [
  {
    dataIndex: 'equipment_name',
    title: 'Equipment Name'
  },
  {
    dataIndex: 'equipment_type',
    title: 'Equipment Type',
    render: (text) => (
      <span>{text || '-'}</span>
    )
  },
  {
    dataIndex: 'equipment_no',
    title: 'Equipment No',
    render: (text) => (
      <span>{text || '-'}</span>
    )
  },
  {
    dataIndex: 'capacity',
    title: 'Capacity',
    render: (text, record) => (
      <span>
        {
          text
            ? formatPercentageOrThousand(text, record)
            : '-'
        }
      </span>
    )
  }
];

const generateCapacityData = (
  periods,
  periodName,
  period,
  data,
  unit,
  type
) => {
  const isWeekly = toLower(periodName) === 'weekly';
  const isMonthly = toLower(periodName) === 'monthly';
  const selectedData = data.find((item) => toLower(item.type) === toLower(type));

  if (isEmpty(selectedData)) {
    return [];
  }

  const selectEquipment = (equipments, code) => {
    const selectedEquipment = equipments.find((equip) => equip.code === code);

    if (selectedEquipment) {
      return selectedEquipment.value;
    }

    return EMPTY.STRING;
  };

  const selectItemProps = (item, period) => {
    return item.properties.find((prop) =>
      moment(
        moment(prop.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        'YYYY-MM-DD'
      ).isSame(
        moment(period, toLower(unit) === UNIT.DAY ? 'DD MMM YYYY' : 'MMM YYYY').format('YYYY-MM-DD'),
        'day'
      )
    );
  };

  const selectCapacity = (item) => {
    switch (true) {
      case isWeekly: {
        const selectedCapacity = selectItemProps(item, periods[0]);

        if (selectedCapacity) {
          return selectedCapacity.value;
        }

        return EMPTY.NUMBER;
      }
      case isMonthly: {
        const selectedCapacity = selectItemProps(item, periods[0]);

        if (selectedCapacity) {
          return selectedCapacity.value;
        }

        return EMPTY.NUMBER;
      }
      default: {
        const selectedCapacity = selectItemProps(item, period);

        if (selectedCapacity) {
          return selectedCapacity.value;
        }

        return EMPTY.NUMBER;
      }
    }
  };

  return selectedData.contents.map((item) => ({
    equipment_name: selectEquipment(item.equipment_detail, 'equipment_name'),
    equipment_type: selectEquipment(item.equipment_detail, 'equipment_type'),
    equipment_no: selectEquipment(item.equipment_detail, 'equipment_no'),
    capacity: selectCapacity(item.properties)
  }));
};

const minePlanGuidanceColumns = [
  {
    dataIndex: 'description',
    title: 'Description'
  },
  {
    dataIndex: 'desc',
    title: '',
    render: (text) => (
      <span className={`${toLower(text).includes('actual') && 'font-bold'}`}>
        {text}
      </span>
    )
  },
  {
    dataIndex: 'unit',
    title: 'Unit'
  },
  { title: 'Q1' },
  { title: 'Q2' },
  { title: 'Q3' },
  { title: 'Q4' },
];

const generateMinePlanGuidanceColumns = (unit, periods) => {
  if (isEmpty(periods)) {
    return minePlanGuidanceColumns;
  }

  const currentYear = moment(
    periods[0],
    toLower(unit) === UNIT.DAY ? 'DD MMM YYYY' : 'MMM YYYY'
  ).format('YYYY');

  return minePlanGuidanceColumns.map((item) => {
    if (item.title.includes('Q')) {
      switch (item.title) {
        case 'Q1':
          return {
            ...item,
            children: [1, 2, 3].map((number) => ({
              dataIndex: `01_${moment(number, 'M').format('MM')}_${currentYear}`,
              title: `${moment(number, 'M').format('MMM')} ${currentYear}`,
              render: (text, record) => (
                <span className={`${record.unit === '%' && 'font-bold'}`}>
                  {
                    Boolean(record.description === 'Production Achievement' || record.description === 'Mine Plan Indicator')
                      ? EMPTY.STRING
                      : formatPercentageOrThousand(text, record)
                  }
                </span>
              )
            }))
          };
        case 'Q2':
          return {
            ...item,
            children: [4, 5, 6].map((number) => ({
              dataIndex: `01_${moment(number, 'M').format('MM')}_${currentYear}`,
              title: `${moment(number, 'M').format('MMM')} ${currentYear}`,
              render: (text, record) => (
                <span className={`${record.unit === '%' && 'font-bold'}`}>
                  {
                    Boolean(record.description === 'Production Achievement' || record.description === 'Mine Plan Indicator')
                      ? EMPTY.STRING
                      : formatPercentageOrThousand(text, record)
                  }
                </span>
              )
            }))
          };
        case 'Q3':
          return {
            ...item,
            children: [7, 8, 9].map((number) => ({
              dataIndex: `01_${moment(number, 'M').format('MM')}_${currentYear}`,
              title: `${moment(number, 'M').format('MMM')} ${currentYear}`,
              render: (text, record) => (
                <span className={`${record.unit === '%' && 'font-bold'}`}>
                  {
                    Boolean(record.description === 'Production Achievement' || record.description === 'Mine Plan Indicator')
                      ? EMPTY.STRING
                      : formatPercentageOrThousand(text, record)
                  }
                </span>
              )
            }))
          };
        case 'Q4':
          return {
            ...item,
            children: [10, 11, 12].map((number) => ({
              dataIndex: `01_${moment(number, 'M').format('MM')}_${currentYear}`,
              title: `${moment(number, 'M').format('MMM')} ${currentYear}`,
              render: (text, record) => (
                <span className={`${record.unit === '%' && 'font-bold'}`}>
                  {
                    Boolean(record.description === 'Production Achievement' || record.description === 'Mine Plan Indicator')
                      ? EMPTY.STRING
                      : formatPercentageOrThousand(text, record)
                  }
                </span>
              )
            }))
          };
        default:
          return { ...item };
      }
    }

    return { ...item };
  });
};

const minePlanGuidanceDataTable = [
  {
    codeKey: null,
    description: 'Production Achievement',
  },
  {
    codeKey: 'ob_plan',
    description: 'Overburden',
    desc: 'Overburden Plan',
    unit: 'BCM'
  },
  {
    codeKey: 'ob_actual',
    desc: 'Overburden Actual',
    unit: 'BCM'
  },
  {
    codeKey: 'ob_achievement',
    desc: 'Achievement',
    unit: '%'
  },
  {
    codeKey: 'coal_plan',
    description: 'Coal',
    desc: 'Coal Plan',
    unit: 'Ton'
  },
  {
    codeKey: 'coal_actual',
    desc: 'Coal Actual',
    unit: 'Ton'
  },
  {
    codeKey: 'coal_achievement',
    description: '',
    desc: 'Achievement',
    unit: '%'
  },
  {
    codeKey: null,
    description: 'Mine Plan Indicator'
  },
  {
    codeKey: 'sr_plan',
    description: 'Stripping Ratio',
    desc: 'SR Plan',
    unit: 'BCM/Ton'
  },
  {
    codeKey: 'sr_actual',
    desc: 'SR Actual',
    unit: 'BCM/Ton'
  },
  {
    codeKey: 'dist_plan',
    description: 'Distance',
    desc: 'Distance Plan',
    unit: 'Kilo Meter'
  },
  {
    codeKey: 'dist_actual',
    desc: 'Distance Actual',
    unit: 'Kilo Meter'
  }
];

const generateMinePlanGuidanceData = (unit, periods, data) => {
  const currentYear = moment(
    periods[0],
    toLower(unit) === UNIT.DAY ? 'DD MMM YYYY' : 'MMM YYYY'
  ).format('YYYY');

  const allMonths = [...Array(12).keys()]
    .map((num) => `01-${moment((num + 1), 'M').format('MM')}-${currentYear}`);
  
  const calculateAchievement = (plan, actual) => {
    if (plan && actual) {
      return ((actual.value / plan.value) * 100).toFixed(2);
    }
    
    return 0;
  };

  return isEmpty(data)
    ? minePlanGuidanceDataTable
    : minePlanGuidanceDataTable.map((item) => {
      const newItem = { ...item };
    
      if (item.codeKey) {
        if (item.codeKey.includes('achievement')) {
          const achievementType = item.codeKey.split('_');

          if (isEmpty(data[`${achievementType[0]}_plan`]) || isEmpty(data[`${achievementType[0]}_actual`])) {
            return newItem;
          }

          allMonths.forEach((period) => {
            const byTypePlan = data[`${achievementType[0]}_plan`]
              .find((mpData) => moment(
                moment(mpData.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                'YYYY-MM-DD'
              ).isSame(
                moment(period, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                'day'
              ));

            const byTypeActual = data[`${achievementType[0]}_actual`]
              .find((mpData) => moment(
                moment(mpData.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                'YYYY-MM-DD'
              ).isSame(
                moment(period, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                'day'
              ));

            newItem[moment(period, 'DD-MM-YYYY').format('DD_MM_YYYY')] =
              calculateAchievement(byTypePlan, byTypeActual);
          });

          return newItem;
        }

        if (isEmpty(data[item.codeKey])) {
          return newItem;
        }

        data[item.codeKey].forEach((mpData) => {
          newItem[moment(mpData.date, 'DD-MM-YYYY').format('DD_MM_YYYY')] = mpData.value;
        });

        return newItem;
      }
    
      return newItem;
    });
};

const minePlanProposeColumns = [
  {
    dataIndex: 'description',
    title: 'Description'
  },
  {
    dataIndex: 'unit',
    title: 'Unit'
  },
  {
    dataIndex: 'reserve',
    title: 'Reserve',
    render: (text, record) => (
      <span>
        {text
          ? formatPercentageOrThousand(text, record)
          : '-'}
      </span>
    )
  }
];

const generateMinePLanProposeColumns = (unit, periods, periodName) => {
  const loweredUnit = toLower(unit);
  const isWeekly = toLower(periodName) === 'weekly';
  const isMonthly = toLower(periodName) === 'monthly';
  const momentPeriod = (item) => moment(item, generateFormat(periods, loweredUnit, true, false));

  const generateDataIndex = (item) => momentPeriod(item)
    .format(generateFormat(periods, loweredUnit, false, true));

  const generateTitle = (item) => momentPeriod(item)
    .format(generateFormat(periods, loweredUnit, false, false));
  
  if (isWeekly || isMonthly) {
    return [
      ...minePlanProposeColumns,
      {
        dataIndex: isWeekly
          ? 'week'
          : moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY'),
        title: isWeekly
          ? 'Week'
          : moment(periods[0], 'DD MMM YYYY').format('MMM YYYY'),
        render: (text, record) => (
          <span>
            {text
              ? formatPercentageOrThousand(text, record)
              : '-'}
          </span>
        )
      }
    ];
  }
  
  return [
    ...minePlanProposeColumns,
    ...periods.map((item) => ({
      dataIndex: generateDataIndex(item),
      title: generateTitle(item),
      render: (text, record) => (
        <span>
          {text
            ? formatPercentageOrThousand(text, record)
            : '-'}
        </span>
      )
    }))
  ];
};

const minePlanProposeDataTable = [
  {
    codeKey: 'ob',
    description: 'Overburden',
    unit: 'BCM',
    reserve: ''
  },
  {
    codeKey: 'coal',
    description: 'Coal',
    unit: 'Ton',
    reserve: ''
  },
  {
    codeKey: 'sr',
    description: 'SR',
    unit: 'BCM/Ton',
    reserve: ''
  }
];

const generateMinePlanProposeData = (unit, periods, periodName, data, dataPit) => {
  const loweredUnit = toLower(unit);
  const isWeekly = toLower(periodName) === 'weekly';
  const isMonthly = toLower(periodName) === 'monthly';

  if (isEmpty(data)) {
    return minePlanProposeDataTable;
  }

  const overBurdenData = data.find((item) => toLower(item.type) === toLower(TYPE.OVER_BURDEN));
  const coalData = data.find((item) => toLower(item.type) === toLower(TYPE.COAL));
  const pitReserveData = dataPit?.contents?.find((item) => toLower(item.code) === 'pit_reserve');

  if (isEmpty(overBurdenData) || isEmpty(coalData) || isEmpty(pitReserveData)) {
    return minePlanProposeDataTable;
  }

  const momentPeriod = (item) => moment(item, generateFormat(periods, loweredUnit, true, false));
  const generateDataIndex = (item) => momentPeriod(item)
    .format(generateFormat(periods, loweredUnit, false, true));

  const reduceFractionDigit = (value) => {
    if (value) {
      return value.toFixed(1);
    }

    return value;
  };

  const selectSummary = (selectedData, period) => {
    switch (true) {
      case isWeekly:
      case isMonthly: {
        const summaryProp = selectedData.summary.properties.find((prop) => moment(
          moment(prop.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          'YYYY-MM-DD'
        ).isSame(
          moment(periods[0], toLower(unit) === UNIT.DAY ? 'DD MMM YYYY' : 'MMM YYYY').format('YYYY-MM-DD'),
          'day'
        ));

        if (summaryProp) {
          return reduceFractionDigit(summaryProp.value);
        }

        return EMPTY.NUMBER;
      }
      default: {
        const summaryProp = selectedData.summary.properties.find((prop) => moment(
          moment(prop.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          'YYYY-MM-DD'
        ).isSame(
          moment(period, toLower(unit) === UNIT.DAY ? 'DD MMM YYYY' : 'MMM YYYY').format('YYYY-MM-DD'),
          'day'
        ));

        if (summaryProp) {
          return reduceFractionDigit(summaryProp.value);
        }

        return EMPTY.NUMBER;
      }
    }
  };

  const selectSummaryPit = (selectedData, period, type) => {
    switch (true) {
      case isWeekly:
      case isMonthly: {
        const summaryProp = selectedData.properties.find((prop) => moment(
          moment(prop.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          'YYYY-MM-DD'
        ).isSame(
          moment(periods[0], toLower(unit) === UNIT.DAY ? 'DD MMM YYYY' : 'MMM YYYY').format('YYYY-MM-DD'),
          'day'
        ));

        if (summaryProp) {
          switch (type) {
            case toLower(TYPE.OVER_BURDEN): {
              const summaryOB = summaryProp.properties.find((item) => toLower(item.code) === 'overburden');
              return reduceFractionDigit(summaryOB.value);
            }
            case toLower(TYPE.COAL): {
              const summaryCoal = summaryProp.properties.find((item) => toLower(item.code) === 'total');
              return reduceFractionDigit(summaryCoal.value);
            }
            default: {
              const summarySR = summaryProp.properties.find((item) => toLower(item.code) === 'sr');
              return reduceFractionDigit(summarySR.value);
            }
          }
        }

        return EMPTY.NUMBER;
      }
      default: {
        const summaryProp = selectedData.properties.find((prop) => moment(
          moment(prop.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          'YYYY-MM-DD'
        ).isSame(
          moment(period, toLower(unit) === UNIT.DAY ? 'DD MMM YYYY' : 'MMM YYYY').format('YYYY-MM-DD'),
          'day'
        ));

        if (summaryProp) {
          switch (type) {
            case toLower(TYPE.OVER_BURDEN): {
              const summaryOB = summaryProp.properties.find((item) => toLower(item.code) === 'overburden');
              return summaryOB.value;
            }
            case toLower(TYPE.COAL): {
              const summaryCoal = summaryProp.properties.find((item) => toLower(item.code) === 'total');
              return summaryCoal.value;
            }
            default: {
              const summarySR = summaryProp.properties.find((item) => toLower(item.code) === 'sr');
              return summarySR.value;
            }
          }
        }

        return EMPTY.NUMBER;
      }
    }
  };

  return minePlanProposeDataTable.map((item) => {
    const newItem = { ...item };

    switch (toLower(newItem.codeKey)) {
      case toLower(TYPE.OVER_BURDEN): {
        switch (true) {
          case isWeekly: {
            newItem.reserve = selectSummaryPit(pitReserveData, null, toLower(TYPE.OVER_BURDEN));
            newItem.week = selectSummary(overBurdenData);

            break;
          }
          case isMonthly: {
            newItem.reserve = selectSummaryPit(pitReserveData, null, toLower(TYPE.OVER_BURDEN));
            newItem[moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY')] =
              selectSummary(overBurdenData);

            break;
          }
          default: {
            let total = 0;
            periods.forEach((period) => {
              total += selectSummaryPit(pitReserveData, period, toLower(TYPE.OVER_BURDEN));
              newItem[generateDataIndex(period)] = selectSummary(overBurdenData, period);
            });
            newItem.reserve = reduceFractionDigit(total);

            break;
          }
        }

        return newItem;
      }
      case toLower(TYPE.COAL): {
        switch (true) {
          case isWeekly: {
            newItem.reserve = selectSummaryPit(pitReserveData, null, toLower(TYPE.COAL));
            newItem.week = selectSummary(coalData);

            break;
          }
          case isMonthly: {
            newItem.reserve = selectSummaryPit(pitReserveData, null, toLower(TYPE.COAL));
            newItem[moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY')] =
                selectSummary(coalData);
            
            break;
          }
          default: {
            let total = 0;
            periods.forEach((period) => {
              total += selectSummaryPit(pitReserveData, period, toLower(TYPE.COAL));
              newItem[generateDataIndex(period)] = selectSummary(coalData, period);
            });
            newItem.reserve = reduceFractionDigit(total);
            
            break;
          }
        }
        
        return newItem;
      }
      case 'sr': {
        switch (true) {
          case isWeekly: {
            newItem.reserve = selectSummaryPit(pitReserveData, null, 'sr');
            newItem.week = Boolean(selectSummary(overBurdenData) && selectSummary(coalData))
              ? selectSummary(overBurdenData) / selectSummary(coalData)
              : 0;

            break;
          }
          case isMonthly: {
            newItem.reserve = selectSummaryPit(pitReserveData, null, 'sr');
            newItem[moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY')] =
              Boolean(selectSummary(overBurdenData) && selectSummary(coalData))
                ? selectSummary(overBurdenData) / selectSummary(coalData)
                : 0;

            break;
          }
          default: {
            let total = 0;
            periods.forEach((period) => {
              total += selectSummaryPit(pitReserveData, period, 'sr');
              newItem[generateDataIndex(period)] = Boolean(selectSummary(overBurdenData, period) && selectSummary(coalData, period))
                ? (selectSummary(overBurdenData, period) / selectSummary(coalData, period))
                : 0;
            });
            newItem.reserve = reduceFractionDigit(total);

            break;
          }
        }
        
        return newItem;
      }
      default:
        return newItem;
    }
  });
};

const generateBody = (data) => {
  const specialData = [
    {
      code: 'reserving',
      children: ['waste', 'coal']
    },
    {
      code: 'coal_parameter',
      children: ['min_thickness', 'loss',  'dilution']
    }
  ];
  
  const preBody = {
    contents: {
      code: 'reserves_parameters',
      properties: data.map((item) => {
        if (specialData.some((parent) => parent.code === item.codeKey)) {
          const parentData = specialData.find((parent) => parent.code === item.codeKey);

          /**
           * `[something].update_date` is dayJS
           * */
          return {
            code: item.codeKey,
            value: {
              name: item.codeKey === 'coal_parameter'
                ? null
                : item.name,
              update_date: item.codeKey === 'coal_parameter'
                ? null
                : item.update_date.format('DD-MM-YYYY'),
              /**
               * `file` can be `null` or `false`
               * and we should turn any `false` to `null`
               * */
              file: item.file === false
                ? null
                : item.file
            },
            children: parentData.children.map((child) => {
              const selectedChild = data.find((childItem) => childItem.codeKey === child);

              return {
                code: child,
                value: {
                  name: selectedChild.name,
                  update_date: selectedChild.update_date.format('DD-MM-YYYY'),
                  file: null
                }
              };
            })
          };
        }

        return {
          code: item.codeKey,
          value: {
            name: item.name,
            update_date: item.update_date.format('DD-MM-YYYY'),
            /**
             * `file` can be `null` or `false`
             * and we should turn any `false` to `null`
             * */
            file: item.file === false
              ? null
              : item.file
          }
        };
      })
    }
  };
  
  return {
    contents: {
      ...preBody.contents,
      properties: preBody.contents.properties
        .filter((item) =>
          !specialData.some((parent) =>
            parent.children.some((child) => item.code === child)
          )
        )
    }
  };
};

export {
  reserveDefaultData,
  overBurdenColumns,
  coalColumns,
  generateReserveColumns,
  generateReserveData,
  generateCapacityData,
  generateMinePlanGuidanceColumns,
  generateMinePlanGuidanceData,
  generateMinePLanProposeColumns,
  generateMinePlanProposeData,
  generateBody
};