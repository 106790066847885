import {
  Alert,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Modal,
  Spin,
  Upload,
} from 'antd';
import {
  useGetMasterDataGoeCompaniesQuery,
  useGetMasterDataOmeSectionsQQuery,
} from 'api/MasterData';
import {
  useBulkCreateOmeQMutation,
  useDeleteOmeQMutation,
  useLazyExportOmeQExcelQuery,
  useLazyGetOmeQsQuery,
  usePostOmeQExcelMutation,
} from 'api/OmeQ';
import ButtonAccent from 'components/ButtonAccent';
import { useModalConfirmationContext } from 'components/ModalConfirmationProvider';
import SelectAccent from 'components/SelectAccent';
import TableData from 'components/TableData';
import TableRaw from 'components/TableRaw';
import { DEFAULT, EMPTY, FILES } from 'constant';
import {
  generateParamBulkCreateOmeQ,
  tableOmeQColumns,
} from 'constant/Maintenance/TableOmeQ';
import { isEmpty } from 'lodash';
import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { transformError } from 'utils/ErrorTransformer';
import { convertToMegaBytes } from 'utils/FileUtility';
import FormOmeQ from './Form';

const MaintenanceOmeQ = () => {
  const initialFilter = {
    company: EMPTY.STRING,
    sections: EMPTY.STRING,
    dates: [],
  };

  const loc = useLocation();
  const { showModal, resetModal } = useModalConfirmationContext();
  const [filter, setFilter] = useState(initialFilter);
  const [isModalShown, setIsModalShown] = useState(false);
  const [isModalPreviewShown, setIsModalPreviewShown] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [omeQId, setOmeQId] = useState('');
  const [loading, setLoading] = useState(false);
  const [isFileExceedLimit, setIsFileExceedLimit] = useState(false);
  const [dataTempExcel, setDataTempExcel] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const { page, pageSize, sortBy, orderBy, search } = useSelector(
    (state) => state.table
  );
  const [formFilter] = Form.useForm();

  const [
    getDataOmeQ,
    {
      data: omeQs,
      isFetching: omeQsIsFetching,
      isError: omeQsIsError,
      error: omeQsError,
    },
  ] = useLazyGetOmeQsQuery();

  const {
    data: masterDataSections,
    isFetching: masterDataSectionsIsFetching,
    error: masterDataSectionsError,
    isError: masterDataSectionsIsError,
  } = useGetMasterDataOmeSectionsQQuery({ refetchOnMountOrArgChange: true });

  const [deleteOmeQ, { isError: deleteOmeQIsError, error: deleteOmeQError }] =
    useDeleteOmeQMutation();

  const [
    uploadExcel,
    {
      data: fileUpload,
      isLoading: fileUploadIsLoading,
      isError: fileUploadIsError,
      error: fileUploadError,
      isSuccess: fileUploadIsSuccess,
    },
  ] = usePostOmeQExcelMutation();

  const [
    postBulkCreateOmeQ,
    {
      isLoading: bulkCreateOmeQIsLoading,
      isError: bulkCreateOmeQIsError,
      error: bulkCreateOmeQError,
      isSuccess: bulkCreateOmeQIsSuccess,
    },
  ] = useBulkCreateOmeQMutation();

  const [
    exportExcelOmeQ,
    { isError: exportExcelOmeQIsError, error: exportExcelOmeQError },
  ] = useLazyExportOmeQExcelQuery();

  const {
    data: masterDataCompanies,
    isFetching: masterDataCompaniesIsFetching,
  } = useGetMasterDataGoeCompaniesQuery({ refetchOnMountOrArgChange: true });

  const calculatePixel = (remVal) => {
    return `${window.innerWidth - remVal * 16}px`;
  };

  const onSetModal = () => {
    if (isModalShown) {
      setIsEdit(false);
    }
    setIsModalShown(!isModalShown);
  };

  const onEditModal = (id) => {
    setIsEdit(true);
    setIsModalShown(!isModalShown);
    setOmeQId(id);
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const onSubmitForm = () => {
    onSetModal();
    setTimeout(() => {
      fetchDataOmeQ();
    }, 1000);
  };

  const onSubmitUpload = () => {
    const body = generateParamBulkCreateOmeQ(dataTempExcel);

    postBulkCreateOmeQ(body);
    setIsModalPreviewShown(false);
    setDataTempExcel([]);
    setTimeout(() => {
      fetchDataOmeQ();
    }, 1000);
  };

  const onDeleteModal = (id) => {
    showModal({
      isShown: true,
      type: 'delete',
      message: 'Are you sure you want to delete ?',
      onSubmit: () => {
        deleteOmeQ({ id });
        resetModal();
        setTimeout(() => {
          fetchDataOmeQ();
          if (deleteOmeQIsError) {
            toast.error(deleteOmeQError, {
              toastId: 'delete-ome-q-error-toast',
            });
          } else {
            toast.success('Data Deleted', {
              toastId: 'delete-ome-q-success-toast',
            });
          }
        }, 1000);
      },
    });
  };

  const onFilter = (val, key) => {
    if (!val && filter[key]) {
      setFilter((prevState) => ({
        ...prevState,
        [key]: initialFilter[key],
      }));
    }
  };

  const submitFilter = (values) => {
    let dates = [];
    if (values.dates)
      dates = values.dates.map((item) => {
        return moment(new Date(item)).format('DD-MM-YYYY');
      });

    setFilter({
      sections: values.sections,
      dates,
    });
  };

  const beforeUploadFile = (file) => {
    const { size } = file;
    const tempTotalSize = totalSize + size;

    if (convertToMegaBytes(totalSize) > FILES.MAX) {
      setIsFileExceedLimit(true);
    } else {
      setTotalSize(tempTotalSize);
    }
  };

  const onChangeFile = (info) => {
    const { file } = info;
    const formData = new FormData();

    formData.append('file', file);
    setIsFileExceedLimit(false);

    uploadExcel(formData);
  };

  const fetchDataOmeQ = () => {
    const params = new URLSearchParams({
      page,
      limit: pageSize,
      sort_by: sortBy,
      order_by: orderBy,
      ...(search ? { search } : undefined),
    });

    if (filter.sections) params.append('sections', filter.sections);
    if (filter.dates) filter.dates?.map((item) => params.append('dates', item));

    getDataOmeQ(params.toString());
  };

  const exportDataOmeQ = () => {
    const params = new URLSearchParams({
      page,
      limit: pageSize,
      sort_by: sortBy,
      order_by: orderBy,
      ...(search ? { search } : undefined),
    });

    if (filter.sections) params.append('sections', filter.sections);
    if (filter.dates) filter.dates?.map((item) => params.append('dates', item));

    exportExcelOmeQ(params.toString());
  };

  useEffect(() => {
    fetchDataOmeQ();
  }, [filter, search, page, pageSize, sortBy, orderBy]);

  useEffect(() => {
    if (!isEmpty(fileUpload)) {
      setDataTempExcel(fileUpload);
      setIsModalPreviewShown(true);
    }
  }, [fileUpload]);

  useEffect(() => {
    if (omeQsIsError) {
      toast.error(transformError(omeQsError).message, {
        toastId: 'get-ome-q-error-toast',
      });
    }
    if (masterDataSectionsIsError) {
      toast.error(transformError(masterDataSectionsError).message, {
        toastId: 'master-data-system-error-toast',
      });
    }
    if (fileUploadIsError) {
      toast.error(transformError(fileUploadError).message, {
        toastId: 'upload-excel-ome-q-error-toast',
      });
    }
    if (bulkCreateOmeQIsError) {
      toast.error(transformError(bulkCreateOmeQError).message, {
        toastId: 'bulk-create-ome-q-error-toast',
      });
    }
    if (exportExcelOmeQIsError) {
      toast.error(transformError(exportExcelOmeQError).message, {
        toastId: 'export-excel-ome-q-error-toast',
      });
    }
    if (fileUploadIsSuccess) {
      toast.success('Successfuly upload excel document.', {
        toastId: 'ome-ap-upload-success',
      });
    }
    if (bulkCreateOmeQIsSuccess) {
      toast.success('Data upload Saved', {
        toastId: 'ome-ap-upload-success',
      });
    }
  }, [
    omeQsIsError,
    masterDataSectionsIsError,
    fileUploadIsError,
    bulkCreateOmeQIsError,
    exportExcelOmeQIsError,
    fileUploadIsSuccess,
    bulkCreateOmeQIsSuccess,
  ]);

  return (
    <div className="mt-5 bg-white p-6 rounded-2xl">
      <Drawer
        destroyOnClose
        title={`${isEdit ? 'Edit Record' : 'Add New Record'} OME Maintenance Q`}
        placement={isEdit ? 'right' : 'left'}
        onClose={onSetModal}
        open={isModalShown}
        loading={loading}
        maskClosable={false}
        key={isEdit ? 'right' : 'left'}
        width={'35vw'}
        styles={{
          headerStyle: { backgroundColor: '#EEE9FB' },
          bodyStyle: { backgroundColor: '#EEE9FB' },
        }}
      >
        {loading ? (
          <div className="w-full text-center">
            <Spin className="m-auto" />
          </div>
        ) : (
          <FormOmeQ isEdit={isEdit} omeQId={omeQId} onSubmit={onSubmitForm} />
        )}
      </Drawer>
      <Form form={formFilter} onFinish={submitFilter}>
        <div className="grid-cols-2 flex gap-4 p-4 bg-[#B1BACB20] rounded-lg">
          <div className="w-full">
            <span className="font-bold">Company</span>
            <Form.Item name="company">
              <SelectAccent
                allowClear
                className="mt-2 w-full h-10"
                placeholder="Select company"
                labelKey="alias_name"
                valueKey="alias_name"
                isStringify={false}
                options={masterDataCompanies}
                isLoading={masterDataCompaniesIsFetching}
                onChange={(val) => onFilter(val, 'company')}
              />
            </Form.Item>
          </div>
          <div className="w-full">
            <span className="font-bold">Section</span>
            <Form.Item name="sections">
              <SelectAccent
                className="mt-2 w-full h-10"
                allowClear
                placeholder="Select section"
                labelKey="name"
                valueKey="name"
                options={masterDataSections}
                isLoading={masterDataSectionsIsFetching}
                onChange={(val) => onFilter(val, 'sections')}
              />
            </Form.Item>
          </div>
          <div className="w-full">
            <span className="font-bold">Date</span>
            <Form.Item name="dates">
              <DatePicker.RangePicker
                allowClear
                className="mt-2 w-full h-10"
                format="DD-MM-YYYY"
                onChange={(val) => onFilter(val, 'dates')}
              />
            </Form.Item>
          </div>
          <div className="w-1/5 flex flex-row justify-center items-center gap-2 mt-1.5">
            <ButtonAccent title="View Report" htmlType="submit" />
          </div>
        </div>
      </Form>
      <Divider />
      {isFileExceedLimit && (
        <Alert
          type="error"
          message="File size exceed limit of 25MB, please choose another one."
          className="mb-5"
        />
      )}
      <div className="flex justify-between pl-5 pr-7">
        <Upload
          className="mr-3"
          showUploadList={false}
          disabled={fileUploadIsLoading}
          beforeUpload={beforeUploadFile}
          customRequest={(opt) => onChangeFile(opt)}
          accept=".xlsx, .xlsm, .xlsb, .xls, .xml, .xlam, .xla"
        >
          <div className="flex flex-row items-center">
            <ButtonAccent
              title="Upload"
              isDisabled={fileUploadIsLoading}
              isLoading={fileUploadIsLoading}
            ></ButtonAccent>
            {fileUploadIsLoading && <Spin className="mx-5" size="small"></Spin>}
          </div>
        </Upload>
        <ButtonAccent
          className="bg-[#00C2AA]"
          title="Export Data"
          onClick={exportDataOmeQ}
          isDisabled={isEmpty(omeQs?.data)}
        />
      </div>
      <div
        className="mt-5 overflow-x-auto"
        style={{ maxWidth: calculatePixel(23) }}
      >
        <TableData
          scrollWidth="max-content"
          rowKey="id"
          isTransparent
          dataSource={omeQs?.data}
          totalData={omeQs?.total_data}
          isLoading={omeQsIsFetching}
          columns={tableOmeQColumns(
            loc.pathname,
            sortBy,
            orderBy === DEFAULT.KEY_SORT_ASC
              ? DEFAULT.SORT_ASC
              : DEFAULT.SORT_DESC,
            onEditModal,
            onDeleteModal
          )}
        >
          {<ButtonAccent title="New Record" onClick={onSetModal} />}
        </TableData>
      </div>
      <Modal
        title="Preview Data Ome Q Upload"
        centered
        footer={null}
        open={isModalPreviewShown}
        onOk={() => setIsModalPreviewShown(false)}
        onCancel={() => setIsModalPreviewShown(false)}
        width={1000}
      >
        <div className="overflow-x-auto my-5">
          <TableRaw
            isLoading={false}
            scrollWidth="max-content"
            columns={tableOmeQColumns(
              loc.pathname,
              sortBy,
              orderBy === DEFAULT.KEY_SORT_ASC
                ? DEFAULT.SORT_ASC
                : DEFAULT.SORT_DESC,
              () => {},
              () => {},
              isModalPreviewShown
            ).filter((column) => column.title !== 'Action')}
            dataSource={dataTempExcel}
          />
        </div>
        <div className="w-full text-right">
          <ButtonAccent
            title="Submit"
            onClick={onSubmitUpload}
            isDisabled={bulkCreateOmeQIsLoading}
            isLoading={bulkCreateOmeQIsLoading}
          />
        </div>
      </Modal>
    </div>
  );
};

export default MaintenanceOmeQ;
