
import { useModalConfirmationContext } from 'components/ModalConfirmationProvider';
import {
  generateDrillHoleInformationData,
  generateDrillHoleInformationModelTable
} from 'constant/ModelDrillHoleValidation/TableDrillHoleInformation';
import TableDrillingSumarry from 'pages/DrillingPlan/DrillingSummary/TableDrillingSummary';

const DrillHoleInformationModel = (props) => {
  const { isDetailMode, payload, drillHoleNameProperties, onChange, onDeleteRow, onAddType, onDeleteType } = props;
  const {showModal, resetModal} = useModalConfirmationContext();
  const tableData = generateDrillHoleInformationData(payload);
  const columns = generateDrillHoleInformationModelTable(
    isDetailMode,
    tableData, 
    drillHoleNameProperties, 
    onChange, 
    onDeleteRow,
    onAddType,
    onDeleteType,
    showModal,
    resetModal
  );
  return <TableDrillingSumarry columns={columns} tableData={tableData} />;
};

export default DrillHoleInformationModel;