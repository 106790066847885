import { Select, Tag } from 'antd';
import { AiOutlineClose } from 'react-icons/ai';

import { EMPTY } from 'constant';

const SelectAddAccent = (props) => {
  const {
    isDisabled,
    isLoading,
    isStringify,
    isSingle,
    isError,
    placeholder = 'Select here...',
    labelKey,
    valueKey,
    value,
    options,
    onChange,
    isNotTag,
    className
  } = props;
  
  const generateCustomTag = (selectProps) => {
    const {
      closable,
      label,
      value,
      onClose
    } = selectProps;

    const isTagNew = !transformOptions.some((item) => item.value === value);

    return (
      isNotTag ? <div>{label}</div>:
        <Tag
          closable={closable}
          onClose={onClose}
          closeIcon={<AiOutlineClose className="text-white" />}
          className={`flex flex-row items-center text-white font-bold rounded-lg ${isTagNew ? 'bg-green-600' : 'bg-[#2D3D5A]'}`}
        >
          {label}
        </Tag>
    );
  };

  const transformOptions = options && options.length
    ? options.map((item) => {
      if (isStringify) {
        return {
          label: labelKey
            ? item[labelKey]
            : JSON.stringify(item),
          value: JSON.stringify(item)
        };
      }

      return {
        label: labelKey
          ? item[labelKey]
          : item,
        value: valueKey
          ? item[valueKey]
          : item
      };
    })
    : [];

  const handleChange = (value) => {
    if (value && !value.length) {
      onChange(undefined);
      return;
    }

    if (isStringify) {
      const transformedValues = value.map((item) => {
        const parsedItem = JSON.parse(item);

        return {
          label: labelKey
            ? parsedItem[labelKey]
            : JSON.stringify(item),
          value: JSON.stringify(item)
        };
      });

      onChange(isSingle
        ? [transformedValues[transformedValues.length - 1]]
        : transformedValues
      );
      return;
    }

    onChange(isSingle
      ? [value[value.length - 1]]
      : value);
  };
  
  return (
    <Select
      allowClear
      mode="tags"
      disabled={isDisabled || isLoading}
      status={isError ? 'error' : EMPTY.STRING}
      placeholder={placeholder}
      value={value}
      options={transformOptions}
      tagRender={generateCustomTag}
      onChange={handleChange}
      className={`w-full ${className} ${isLoading && 'animate-pulse animate-infinite'}`}
    />
  );
};

export default SelectAddAccent;