import { Base } from './Base';

const TopoCollarValidation = Base.injectEndpoints({
  endpoints: (build)=>({
    validateTopoCollar: build.mutation({
      query: (body) => ({
        url: '/goe/drillings/actuals/topo-collar-validations/validation',
        method: 'POST',
        body
      })
    }),
    uploadTopoCollarExcel: build.mutation({
      query: ({body, plan_id, params}) => ({
        url: `/goe/drillings/actuals/${plan_id}/topo-collar-validations/upload-excel`,
        method: 'POST',
        body,
        ...params
          ? { params }
          : undefined,
        // 10 minutes of upload timeout
        timeout: Number(10 * (60 * 1000))
      })
    }),
    postTopoCollar: build.mutation({
      query: ({body, plan_id}) => ({
        url: `/goe/drillings/actuals/${plan_id}/topo-collar-validations`,
        method: 'POST',
        body
      })
    }),
    getListTopoCollarValidation: build.query({
      query: (params) => ({
        url: '/goe/drillings/actuals/topo-collar-validations',
        method: 'GET',
        ...params
          ? {params}
          : undefined
      })
    }),
    getTopoCollarValidationById: build.query({
      query: ({id}) =>({
        url: `/goe/drillings/actuals/topo-collar-validations/${id}`,
        method: 'GET'
      })
    }),
    exportTopoCollarValidation: build.query({
      query:({id, company, year, drillingStage})=>({
        url:`/goe/drillings/actuals/topo-collar-validations/${id}/export-excel`,
        method: 'GET',
        responseHandler: async (response) => {
          const docLink = document.createElement('a');
          docLink.href = window.URL.createObjectURL(await response.blob());
          docLink.target = '_blank';
          docLink.download = `topo_vs_collar_validation_${year}_${company}_${drillingStage}.xlsx`;
          docLink.click();
        },
        cache: 'no-cache',
      })
    })
  }),
});

export const {
  useValidateTopoCollarMutation,
  useUploadTopoCollarExcelMutation,
  usePostTopoCollarMutation,
  useGetListTopoCollarValidationQuery,
  useLazyGetTopoCollarValidationByIdQuery, 
  useLazyExportTopoCollarValidationQuery
} = TopoCollarValidation;

