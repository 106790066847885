import { sampleId } from 'constant/TableReconciliation';
import { generateFcTable } from 'constant/TableReconciliation/TableFc';
import TableDrillingSumarry from 'pages/DrillingPlan/DrillingSummary/TableDrillingSummary';

const Fc = (props) => {
  const { isDetailMode, fcData, onChange, onDeleteRow, otherData } = props;
  const columns = generateFcTable(
    fcData,
    isDetailMode,
    onChange,
    onDeleteRow
  );

  const populateSampleId = () => {
    let finalData = fcData.map((data, index) => {
      return {
        sample_id: otherData.sample_information[index].sample_id,
        ...data 
      };
    });
    return finalData;
  };
  columns.splice(1, 0, sampleId);

  return <TableDrillingSumarry 
    columns={ columns }
    tableData={ populateSampleId() }
  />;
};

export default Fc;