
import { generateTopoColarValueTopoCollarValidationTable } from 'constant/TopoColarValidation/TableTopoColarValue';
import TableDrillingSumarry from 'pages/DrillingPlan/DrillingSummary/TableDrillingSummary';

const TopoCollarValueTopoCollarValidation = (props) => {
  const {isDetailMode, payload, onChange} = props;
  const columns = generateTopoColarValueTopoCollarValidationTable(isDetailMode, onChange);
  return <TableDrillingSumarry columns={columns} tableData={payload} />;
};

export default TopoCollarValueTopoCollarValidation;