import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { setToken, setRefreshToken } from 'store/Auth';
import { logout } from 'store/Auth/Thunk';

const interceptor = () => {
  const baseQuery = fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_API_URL}`,
    timeout: 60000,
    prepareHeaders: (headers, { getState, endpoint }) => {
      if (endpoint !== '/auth/login') {
        const { token } = getState().auth;
        headers.set('Authorization', `Bearer ${token}`);

        return headers;
      }

      return headers; 
    }
  });
  
  return async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    switch (true) {
      case args?.url === '/auth/login':
        return result;
      case result.error && result.error.status === 401: {
        const { refreshToken } = api.getState().auth;
        const refreshTokenResult = await baseQuery(
          {
            url: '/auth/refresh-token',
            method: 'GET',
            params: { refresh_token: refreshToken }
          },
          api,
          extraOptions
        );

        if (refreshTokenResult.error
          && refreshTokenResult.error.status >= 400) {
          await api.dispatch(logout());
          window.location.replace('/login');
        }

        if (refreshTokenResult.data) {
          const { token, refresh_token } = refreshTokenResult.data;
          await api.dispatch(setToken(token));
          await api.dispatch(setRefreshToken(refresh_token));

          result = await baseQuery(args, api, extraOptions);
        }

        return result;
      }
      default:
        return result;
    }
  };
};

const Base = createApi({
  reducerPath: 'api',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 1,
  baseQuery: interceptor(),
  endpoints: () => ({})
});

export { Base };