import { useLocation, useNavigate } from 'react-router';
import { useRef, useEffect, useState } from 'react';
import {
  Form,
  Table,
  Image
} from 'antd';
import { EMPTY, UNIT, URL } from 'constant';
import {
  getPathType, 
  getFullPath, 
  shouldRedirect 
} from 'utils/PathUtility';
import { createSearchParams } from 'react-router-dom';
import { useGetAssumptionByIdQuery } from 'api/Assumption';
import { useGetHourCapacityByIdQuery } from 'api/HourCapacity';
import { convertPeriodToTags } from 'utils/PeriodUtility';
import { TYPE } from 'constant/MasterData';
import { isEmpty, toLower } from 'lodash';
import moment from 'moment';
import { generateFormat } from 'utils/PeriodUtility';
import {
  generateHeaderColumns,
  generateTopHeaderColumns,
  generateHeaderData,
  generateBody
} from 'constant/TablePitReserve';
import { toast } from 'react-toastify';
import { transformError } from 'utils/ErrorTransformer';
import { useAssumptionManager } from 'utils/AssumptionManager';

import Toast from 'components/Toast';
import SelectAccent from 'components/SelectAccent';
import SelectAddAccent from 'components/SelectAddAccent';
import TableRaw from 'components/TableRaw';
import BannerPitPeriod from 'components/BannerPitPeriod';
import ButtonAccent from 'components/ButtonAccent';
import { useLazyGetMasterDataSeamsQuery } from 'api/MasterData';
import { 
  usePostPitReserveMutation, 
  useLazyGetPitReserveByIdQuery,
  usePutPitReserveMutation
} from 'api/PitReserve';
import LoadingText from 'components/LoadingText';

const PitReserve = () => {
  const [form] = Form.useForm();
  const loc = useLocation();
  const navigate = useNavigate();

  const {
    createBackQueryParams,
    isPathHasBackQueryParams,
    isPathMaxBackQueryParams
  } = useAssumptionManager();

  const assumptionId = new URLSearchParams(loc.search).get('id');
  const backQueries = new URLSearchParams(loc.search).getAll('isBack');
  const isBack = isPathHasBackQueryParams(loc.pathname, backQueries);
  const isDetailMode = getPathType(loc) === 'detail';
  const isEditMode = getPathType(loc) === 'edit';
  const isNeedToFetchDetail = Boolean(isBack || isDetailMode || isEditMode);
  
  const isDraftRef = useRef(false);
  const isWeeklyRef = useRef(false);
  const isMonthlyRef = useRef(false);
  const periodNameRef = useRef(EMPTY.STRING);
  const periodRef = useRef([]);
  const unitRef = useRef(UNIT.MONTH);
  const pitReserveIdRef = useRef(null);

  const [headerColumns, setHeaderColumn] = useState([]);
  const [pitReserveColumns, setPitReserveColumn] = useState([]);
  const [coalExposeColumns, setCoalExposeColumn] = useState([]);
  const [footerColumns, setFooterColumn] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [pitReserveData, setPitReserveData] = useState([]);
  const [coalExposeDefaultData, setCoalExposeDefaultData] = useState({});
  const [coalExposeData, setCoalExposeData] = useState([]);
  const [footerData, setFooterData] = useState([]);

  const {
    data: assumption,
    isFetching: assumptionIsFetching,
    isError: assumptionIsError,
    error: assumptionError
  } = useGetAssumptionByIdQuery(
    { id: assumptionId },
    { refetchOnMountOrArgChange: true }
  );

  const {
    data: obCapacity,
    isFetching: obCapacityIsFetching,
    isError: obCapacityIsError,
    error: obCapacityError
  } = useGetHourCapacityByIdQuery(
    { id: assumptionId,
      params: { type: TYPE.OVER_BURDEN }
    },
    { refetchOnMountOrArgChange: true }
  );

  const {
    data: coalCapacity,
    isFetching: coalCapacityIsFetching,
    isError: coalCapacityIsError,
    error: coalCapacityError
  } = useGetHourCapacityByIdQuery(
    { id: assumptionId,
      params: { type: TYPE.COAL }
    },
    { refetchOnMountOrArgChange: true }
  );

  const [getSeamsOption, {
    data: seamsData,
    isError: seamsIsError,
    error: seamsError
  }] = useLazyGetMasterDataSeamsQuery(
    { refetchOnMountOrArgChange: false }
  );

  const [getPitReserveById, {
    data: getPitData,
    isFetching: getPitDataIsFetching,
    isError: getPitDataIsError,
    error: getPitDataError
  }] = useLazyGetPitReserveByIdQuery();

  const [postPitReserve, {
    isLoading: pitReserveIsLoading,
    isSuccess: pitReserveIsSuccess,
    isError: pitReserveIsError,
    error: pitReserveError
  }] = usePostPitReserveMutation();

  const [putPitReserve, {
    isLoading: putPitReserveIsLoading,
    isSuccess: putPitReserveIsSuccess,
    isError: putPitReserveIsError,
    error: putPitReserveError
  }] = usePutPitReserveMutation();

  const footerDefaultData = [
    { coal_quality: 'HCV', unit: 'Ton' },
    { coal_quality: 'LCV', unit: 'Ton' },
    { coal_quality: 'Unknown', unit: 'Ton' },
  ];

  const calculatePixel = (remVal) => {
    /**
     * `1rem` is `16px`
     * */
    return `${(window.innerWidth - (remVal * 16))}px`;
  };

  const redirectPathToCreate = () => navigate({
    pathname: getFullPath({
      parent: 'assumption',
      child: 'pit-reserve',
      type: 'create'
    }),
    search: createSearchParams({ id: assumptionId }).toString()
  });

  const goToPath = (type) => navigate({
    pathname: getFullPath({
      parent: 'assumption',
      child: type === 'back'
        ? 'over-burden-hauler'
        : 'main-plan-scenario',
      type: getPathType(loc)
    }),
    search: createSearchParams({
      id: assumptionId,
      ...type === 'back'
        ? {
          isBack: isBack
            ? backQueries
            : createBackQueryParams(loc.pathname)
        }
        : {
          ...isPathMaxBackQueryParams(loc.pathname, backQueries)
            ? undefined
            : { isBack: backQueries }
        }
    }).toString()
  });

  const onAddRow = () => {
    setCoalExposeData((prevState) => {
      if (prevState && prevState.length) {
        return [
          ...prevState,
          ...[coalExposeDefaultData]
        ];
      }

      return [coalExposeDefaultData];
    });
  };

  const onDeleteRow = (idx) => {
    setCoalExposeData((prevState) => prevState.filter((_, index) => index !== idx));
  };

  const onCoalExposeChange = (ev, index, dataIndex) => {
    setCoalExposeData((prevState) =>
      prevState.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            [dataIndex]: ev
          };
        }
        
        return { ...item };
      })
    );
  };

  const onPitReserveChange = (ev, index, dataIndex) => {
    setPitReserveData((prevState) =>
      prevState.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            [dataIndex]: ev
          };
        }
        
        return { ...item };
      })
    );
  };

  const generateTotal = (period) => {
    const loweredUnit = toLower(unitRef.current);
    const momentPeriod = (item) => {
      return moment(item, loweredUnit === UNIT.DAY ? 'DD MMM YYYY' : 'MMM YYYY')
        .format(loweredUnit === UNIT.DAY ? 'DD_MMM_YYYY' : 'MMM_YYYY');
    };
    
    const generatePeriodFormat = () => {
      switch (true) {
        case isWeeklyRef.current:
          return 'week';
        case isMonthlyRef.current:
          return moment(periodRef.current[0], 'DD MMM YYYY').format('MMM_YYYY');
        default:
          return momentPeriod(period);
      }
    };

    const sum = coalExposeData?.reduce((accumulator, currentValue) => {
      return parseFloat(accumulator) + parseFloat(currentValue[generatePeriodFormat()]);
    }, 0);

    return sum || 0;
  };

  const generateTotalSr = (period) => {
    const loweredUnit = toLower(unitRef.current);
    const momentPeriod = (item) => {
      return moment(item, loweredUnit === UNIT.DAY ? 'DD MMM YYYY' : 'MMM YYYY')
        .format(loweredUnit === UNIT.DAY ? 'DD_MMM_YYYY' : 'MMM_YYYY');
    };

    const generatePeriodFormat = () => {
      switch (true) {
        case isWeeklyRef.current:
          return 'week';
        case isMonthlyRef.current:
          return moment(periodRef.current[0], 'DD MMM YYYY').format('MMM_YYYY');
        default:
          return momentPeriod(period);
      }
    };

    const sum = pitReserveData?.reduce((accumulator, currentValue) => {
      return parseFloat(accumulator) + parseFloat(currentValue[generatePeriodFormat()]);
    }, 0);

    const totalSr = sum > 0 && parseFloat(generateTotal(period)) > 0 ? sum / parseFloat(generateTotal(period)) : 0;

    return totalSr || 0;
  };

  const generateGrandTotalCoalQuality = (period) => {
    const loweredUnit = toLower(unitRef.current);
    const momentPeriod = (item) => {
      return moment(item, loweredUnit === UNIT.DAY ? 'DD MMM YYYY' : 'MMM YYYY')
        .format(loweredUnit === UNIT.DAY ? 'DD_MMM_YYYY' : 'MMM_YYYY');
    };

    const generatePeriodFormat = () => {
      switch (true) {
        case isWeeklyRef.current:
          return 'week';
        case isMonthlyRef.current:
          return moment(periodRef.current[0], 'DD MMM YYYY').format('MMM_YYYY');
        default:
          return momentPeriod(period);
      }
    };

    const sum = footerData?.reduce((accumulator, currentValue) => {
      return parseFloat(accumulator) + parseFloat(currentValue[generatePeriodFormat()]);
    }, 0);

    return sum || 0;
  };

  const postPit = (isDraft) => {
    if (isDetailMode) {
      goToPath('next');

      return;
    }

    isDraftRef.current = isDraft;
    
    const body = generateBody(
      isDraftRef.current,
      assumptionId,
      unitRef.current,
      periodNameRef.current,
      periodRef.current,
      headerData,
      pitReserveData,
      coalExposeData,
      footerData
    );

    if (isBack || isEditMode) {
      putPitReserve({ id: pitReserveIdRef.current, body });

      return;
    }

    postPitReserve(body);
  };

  useEffect(() => {
    const isWeekly = toLower(periodNameRef.current) === 'weekly';
    const isMonthly = toLower(periodNameRef.current) === 'monthly';
    const momentPeriod = (item) => moment(item, generateFormat(periodRef.current, toLower(unitRef.current), true, false));
    const newCoalQuality = footerDefaultData.map((item) => {
      const newItem = { ...item };

      switch (true) {
        case isWeekly: {
          const totalValue = coalExposeData.filter((v) => v.quality === item.coal_quality).reduce((accumulator, currentValue) => {
            return parseFloat(accumulator) + parseFloat(currentValue['week']);
          }, 0);
          newItem.week = totalValue;

          return newItem;
        }
        case isMonthly: {
          const totalValue = coalExposeData.filter((v) => v.quality === item.coal_quality).reduce((accumulator, currentValue) => {
            return parseFloat(accumulator) + parseFloat(currentValue[moment(periodRef.current[0], 'DD MMM YYYY').format('MMM_YYYY')]);
          }, 0);
          newItem[moment(periodRef.current[0], 'DD MMM YYYY').format('MMM_YYYY')] = totalValue;

          return newItem;
        }
        default: {
          const itemPeriod = (period) => momentPeriod(period)
            .format(generateFormat(periodRef.current, toLower(unitRef.current), false, true));

          periodRef.current.forEach((period) => {
            const totalValue = coalExposeData.filter((v) => v.quality === item.coal_quality).reduce((accumulator, currentValue) => {
              return parseFloat(accumulator) + parseFloat(currentValue[itemPeriod(period)]);
            }, 0);
            newItem[itemPeriod(period)] = totalValue;
          });

          return newItem;
        }
      }
    });

    setFooterData(newCoalQuality);
  }, [coalExposeData]);

  useEffect(() => {
    unitRef.current = toLower(assumption?.category?.unit);
    periodNameRef.current = toLower(assumption?.category?.name);
    periodRef.current = convertPeriodToTags(
      unitRef.current,
      assumption?.start_date,
      assumption?.end_date
    );
    isWeeklyRef.current = periodNameRef.current === 'weekly';
    isMonthlyRef.current = periodNameRef.current === 'monthly';
    
    getSeamsOption({
      pitId: assumption?.pit_id
    });

    if (isNeedToFetchDetail) {
      getPitReserveById({
        id: assumptionId
      });
    }

    /**
     * Header Section
     * */

    setHeaderColumn(
      generateTopHeaderColumns(
        unitRef.current,
        periodNameRef.current,
        periodRef.current
      )
    );

    setPitReserveColumn(
      generateHeaderColumns(
        'pit',
        unitRef.current,
        periodNameRef.current,
        periodRef.current,
        isDetailMode,
        onPitReserveChange
      )
    );

    setFooterColumn(
      generateHeaderColumns(
        'footer',
        unitRef.current,
        periodNameRef.current,
        periodRef.current,
        isDetailMode,
        () => {}
      )
    );

    /**
     * Data Pit Reserve & Coal Expose Section
     * */

    let tempPitReserveData = {
      description: 'Over Burden',
      unit: 'BCM'
    };

    let tempCoalExposeData = {
      description: [],
      unit: 'Ton',
      quality: null
    };

    const generatePrData = generateHeaderColumns(
      'pit',
      unitRef.current,
      periodNameRef.current,
      periodRef.current,
      isDetailMode,
      () => {}
    );

    generatePrData.forEach(i => {
      if (!['description', 'unit', 'quality'].includes(i.dataIndex)) {
        tempPitReserveData[i.dataIndex] = 0;
        tempCoalExposeData[i.dataIndex] = 0;
      }
    });

    setPitReserveData([tempPitReserveData]);
    setCoalExposeDefaultData(tempCoalExposeData);
  }, [assumption]);

  useEffect(() => {
    if (isEditMode && shouldRedirect(getPitData)) {
      redirectPathToCreate();

      return;
    }

    if (!isEmpty(getPitData)
    && !Boolean(getPitData?.error)
    && isNeedToFetchDetail
    ) {
      pitReserveIdRef.current = getPitData.id;

      let tempPitReserveData = {
        description: 'Over Burden',
        unit: 'BCM'
      };
  
      let tempCoalExposeData = {
        description: [],
        unit: 'Ton',
        quality: null
      };

      const generatePrData = generateHeaderColumns(
        'pit',
        unitRef.current,
        periodNameRef.current,
        periodRef.current,
        isDetailMode,
        () => {}
      );

      const momentPeriod = (item) => moment(item, generateFormat(periodRef.current, toLower(unitRef.current), true, false));
      const generateDataIndex = (item) => momentPeriod(item)
        .format(generateFormat(periodRef.current, toLower(unitRef.current), false, true));

      const selectPitReserveData = getPitData?.contents?.find((item) => item.code === 'pit_reserve');
      const selectedPitProperties = selectPitReserveData.properties[0];
      const dataPit = selectedPitProperties.properties.find((item) => item.code === 'overburden');
      const dataCoalExpose = selectedPitProperties.properties.find((item) => item.code === 'coal_expose').properties;

      switch (true) {
        case isWeeklyRef.current: {
          generatePrData.forEach(i => {
            if (!['description', 'unit', 'quality'].includes(i.dataIndex)) {
              tempPitReserveData[i.dataIndex] = dataPit.value;
              tempCoalExposeData[i.dataIndex] = 0;
            }
          });
          form.setFieldValue('pit_week_1', dataPit.value);

          setPitReserveData([tempPitReserveData]);

          const mapExposeData = dataCoalExpose.map((item) => {
            return item.value.map((v) => ({
              [v.code === 'coal' ? 'week' : v.code]: v.value
            }));
          });

          const tempCoalExpose = mapExposeData.map((item) => {
            return item.reduce(
              (obj, v) => Object.assign(obj, {[Object.keys(v)[0]]: Object.values(v)[0]}), {});
          });

          tempCoalExpose.forEach((item, index) => {
            Object.keys(item).forEach((key) => {
              form.setFieldValue(`expose_${key}_${index + 1}`, item[key]);
            });
          });
          setCoalExposeData(tempCoalExpose);
          break;
        }
        case isMonthlyRef.current: {
          generatePrData.forEach(i => {
            if (!['description', 'unit', 'quality'].includes(i.dataIndex)) {
              tempPitReserveData[i.dataIndex] = dataPit.value;
              tempCoalExposeData[i.dataIndex] = 0;
            }
          });
          form.setFieldValue(`pit_${moment(periodRef.current[0], 'DD MMM YYYY').format('MMM_YYYY')}_1`, dataPit.value);

          setPitReserveData([tempPitReserveData]);

          const mapExposeData = dataCoalExpose.map((item) => {
            return item.value.map((v) => ({
              [v.code === 'coal' ? moment(periodRef.current[0], 'DD MMM YYYY').format('MMM_YYYY') : v.code]: v.value
            }));
          });

          const tempCoalExpose = mapExposeData.map((item) => {
            return item.reduce(
              (obj, v) => Object.assign(obj, {[Object.keys(v)[0]]: Object.values(v)[0]}), {});
          });

          tempCoalExpose.forEach((item, index) => {
            Object.keys(item).forEach((key) => {
              form.setFieldValue(`expose_${key}_${index + 1}`, item[key]);
            });
          });
          setCoalExposeData(tempCoalExpose);
          break;
        }
        default: {
          periodRef.current.forEach((period) => {
            const selectedPitProperties = selectPitReserveData.properties
              .forEach((item) => generateDataIndex(item.date) === generateDataIndex(period));
            const dataPit = selectedPitProperties.properties.find((item) => item.code === 'overburden');
            const dataCoalExpose = selectedPitProperties.properties.find((item) => item.code === 'coal_expose').properties;

            generatePrData.forEach(i => {
              if (i.dataIndex === generateDataIndex(period)) {
                tempPitReserveData[i.dataIndex] = dataPit.value;
                tempCoalExposeData[i.dataIndex] = 0;
              }
            });
            form.setFieldValue(`pit_${generateDataIndex(period)}_1`, dataPit.value);

            const mapExposeData = dataCoalExpose.map((item) => {
              return item.value.map((v) => ({
                [v.code === 'coal' ? generateDataIndex(period) : v.code]: v.value
              }));
            });
          });

          setPitReserveData([tempPitReserveData]);
          break;
        }
      }
    }
  }, [getPitData]);

  useEffect(() => {
    if (seamsData) {
      const tempCoalExposeColumn = generateHeaderColumns(
        'expose',
        unitRef.current,
        periodNameRef.current,
        periodRef.current,
        isDetailMode,
        onCoalExposeChange
      ).concat([
        { 
          dataIndex: 'quality',
          title: 'Quality',
          sorter: true,
          render: (text, record, index) => (
            <Form.Item
              name={`expose_quality_${index + 1}`}
              rules={[{ required: true, message: '' }]}
              className='mb-0'
            >
              <SelectAccent
                options={['HCV', 'LCV', 'Unknown']}
                isDisabled={isDetailMode}
                value={record.quality}
                onChange={(ev) => onCoalExposeChange(ev, index, 'quality')}
              />
            </Form.Item>
          )
        },
        {
          dataIndex: 'action',
          title: 'Action',
          render: (data, record, index) => (
            <div>
              {
                !isDetailMode &&
                <Image
                  className='cursor-pointer'
                  preview={false}
                  src={`${process.env.PUBLIC_URL}/assets/button-delete.svg`}
                  onClick={() => onDeleteRow(index)}
                />
              }
            </div>
          )
        }
      ]);

      tempCoalExposeColumn[0] = { 
        dataIndex: 'description',
        title: 'Description',
        sorter: true,
        render: (text, record, index) => (
          <Form.Item
            name={`expose_description_${index + 1}`}
            rules={[{ required: true, message: '' }]}
            className='mb-0'
          >
            <SelectAddAccent
              isSingle
              options={seamsData}
              value={record.description}
              onChange={(ev) => onCoalExposeChange(ev, index, 'description')}
              isDisabled={isDetailMode}
            />
          </Form.Item>
        )
      };

      setCoalExposeColumn(tempCoalExposeColumn);
    }
  }, [seamsData]);

  useEffect(() => {
    let dataOb = {};
    let dataCoal = {};
    let dataSr = {
      codeKey: 'sr',
      sr_by_equipment: 'SR',
      unit: 'BCM/Ton'
    };
    const conData = [];
    if (obCapacity) {
      dataOb = generateHeaderData(
        'ob',
        unitRef.current,
        periodNameRef.current,
        periodRef.current,
        obCapacity,
      )[0];
    }
    if (coalCapacity) {
      dataCoal = generateHeaderData(
        'coal',
        unitRef.current,
        periodNameRef.current,
        periodRef.current,
        coalCapacity,
      )[0];
    }
    conData.push(dataOb);
    conData.push(dataCoal);
    const removeKey = [
      'codeKey',
      'sr_by_equipment',
      'unit'
    ];
    Object.keys(dataOb).map((k) => {
      if (!removeKey.includes(k)) {
        const value = !dataOb[k] || !dataCoal[k];
        dataSr[k] = value ? 0 : dataOb[k] / dataCoal[k];
      }
      return dataSr;
    });
    
    conData.push(dataSr);
    setHeaderData(
      conData
    );
  }, [obCapacity, coalCapacity]);

  useEffect(() => {
    if (assumptionIsError) {
      const errorMsg = assumptionError;

      toast.error(<Toast message={'ERROR'} detailedMessage={`${transformError(errorMsg).message}`} />);
      return;
    }
    if (coalCapacityIsError) {
      const errorMsg = coalCapacityError;

      toast.error(<Toast message={'ERROR'} detailedMessage={`${transformError(errorMsg).message}`} />);
      return;
    }
    if (seamsIsError) {
      const errorMsg = seamsError;

      toast.error(<Toast message={'ERROR'} detailedMessage={`${transformError(errorMsg).message}`} />);
      return;
    }
    if (obCapacityIsError) {
      const errorMsg = obCapacityError;

      toast.error(<Toast message={'ERROR'} detailedMessage={`${transformError(errorMsg).message}`} />);
      return;
    }

    return () => {};
  }, [assumptionIsError, coalCapacityIsError, obCapacityIsError, seamsIsError]);

  useEffect(() => {
    if (getPitDataIsError) {
      const errorMsg = getPitDataError;

      toast.error(<Toast message={'ERROR'} detailedMessage={`${transformError(errorMsg).message}`} />);
      return;
    }
  }, [getPitDataIsError]);

  useEffect(() => {
    if (pitReserveIsSuccess || putPitReserveIsSuccess) {
      if (isDraftRef.current) {
        navigate(`/${URL.ACTIVITY_LEVEL}/${URL.MINE_PLANNING_OE}/assumption`);
        
        return;
      }
      
      goToPath('next');
      
      return;
    }
    
    if (pitReserveIsError || putPitReserveIsError) {
      const errorMsg = pitReserveError || putPitReserveError;

      toast.error(<Toast message={'ERROR'} detailedMessage={`${transformError(errorMsg).message}`} />);
      
      return;
    }
    
    return () => {};
  }, [
    pitReserveIsSuccess,
    pitReserveIsError,
    putPitReserveIsSuccess,
    putPitReserveIsError
  ]);

  return (
    <div className="flex flex-col">
      <Form
        layout="vertical"
        autoComplete="off"
        form={form}
        requiredMark={false}
        onFinish={() => postPit(false)}
      >
        <BannerPitPeriod
          isLoading={assumptionIsFetching}
          companyName={assumption?.company?.alias_name}
          contractorName={assumption?.contractor?.alias_name}
          pitName={assumption?.pit?.name}
          periodMode={assumption?.category?.name}
          periods={assumption
            ? periodRef.current
            : []}
        />

        <div className="my-5 p-5 bg-white flex flex-col rounded-lg">
          <div
            className="overflow-x-auto"
            style={{ maxWidth: calculatePixel(20) }}
          >
            <TableRaw
              isLoading={assumptionIsFetching || obCapacityIsFetching || coalCapacityIsFetching}
              scrollWidth="max-content"
              columns={headerColumns}
              dataSource={headerData}
            />
          </div>
        </div>

        <div className="p-5 bg-white flex flex-col rounded-lg">
          <div className="mb-5 flex flex-row items-center">
            <span className="font-bold text-lg">Pit Reserve</span>
            {
              (isNeedToFetchDetail)
                ? (
                  <div className="ml-auto">
                    {
                      (getPitDataIsFetching) && (<LoadingText />)
                    }
                  </div>
                )
                : (<></>)
            }
          </div>

          <div
            className="overflow-x-auto"
            style={{ maxWidth: calculatePixel(20) }}
          >
            <TableRaw
              isLoading={assumptionIsFetching}
              scrollWidth="max-content"
              columns={pitReserveColumns}
              dataSource={pitReserveData}
            />
          </div>

          <div className='my-5'>
            <span className="font-bold text-lg mr-5">Coal Expose</span>
            {
              !isDetailMode &&
              <ButtonAccent
                title="Add New Row"
                onClick={() => onAddRow()}
              />
            }
          </div>

          <div
            className="overflow-x-auto"
            style={{ maxWidth: calculatePixel(20) }}
          >
            <TableRaw
              isLoading={assumptionIsFetching}
              scrollWidth="max-content"
              columns={coalExposeColumns}
              dataSource={coalExposeData}
              summary={() => (
                <>
                  <Table.Summary.Row className="font-bold bg-pink-700/25 text-pink-700">
                    <Table.Summary.Cell index={0} className="rounded-tl-lg rounded-bl-lg">Total</Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>Ton</Table.Summary.Cell>
                    {
                      !['weekly', 'monthly'].includes(periodNameRef.current) &&
                      periodRef.current.map((item, index) => (
                        <Table.Summary.Cell
                          key={`sum-cell-${index + 1}`}
                          index={(1 + index)}
                        >
                          { Number(generateTotal(item)).toLocaleString('en-US') }
                        </Table.Summary.Cell>
                      ))
                    }
                    {
                      ['weekly', 'monthly'].includes(periodNameRef.current) &&
                      <Table.Summary.Cell index={2}>
                        { Number(generateTotal('')).toLocaleString('en-US') }
                      </Table.Summary.Cell>
                    }
                    <Table.Summary.Cell
                      index={['weekly', 'monthly'].includes(periodNameRef.current) ? 3 : periodRef.current.length + 1}
                    />
                    <Table.Summary.Cell
                      index={['weekly', 'monthly'].includes(periodNameRef.current) ? 4 : periodRef.current.length + 1}
                      className="rounded-tr-lg rounded-br-lg"
                    />
                  </Table.Summary.Row>
                  <Table.Summary.Row className="font-bold bg-pink-700/25 text-pink-700">
                    <Table.Summary.Cell index={0} className="rounded-tl-lg rounded-bl-lg">SR</Table.Summary.Cell>
                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                    {
                      !['weekly', 'monthly'].includes(periodNameRef.current) &&
                      periodRef.current.map((item, index) => (
                        <Table.Summary.Cell
                          key={`sum-cell-${index + 1}`}
                          index={(1 + index)}
                        >
                          { Number(generateTotalSr(item)).toLocaleString('en-US') }
                        </Table.Summary.Cell>
                      ))
                    }
                    {
                      ['weekly', 'monthly'].includes(periodNameRef.current) &&
                      <Table.Summary.Cell index={2}>
                        { Number(generateTotalSr('')).toLocaleString('en-US') }
                      </Table.Summary.Cell>
                    }
                    <Table.Summary.Cell
                      index={['weekly', 'monthly'].includes(periodNameRef.current) ? 3 : periodRef.current.length + 1}
                    />
                    <Table.Summary.Cell
                      index={['weekly', 'monthly'].includes(periodNameRef.current) ? 4 : periodRef.current.length + 1}
                      className="rounded-tr-lg rounded-br-lg"
                    />
                  </Table.Summary.Row>
                </>
              )}
            />
          </div>
        </div>

        <div className="my-5 p-5 bg-white flex flex-col rounded-lg">
          <div
            className="overflow-x-auto"
            style={{ maxWidth: calculatePixel(20) }}
          >
            <TableRaw
              isLoading={assumptionIsFetching}
              columns={footerColumns}
              dataSource={footerData}
              summary={() => (
                <Table.Summary.Row className="font-bold bg-pink-700/25 text-pink-700">
                  <Table.Summary.Cell index={0} className="rounded-tl-lg rounded-bl-lg">Grand Total</Table.Summary.Cell>
                  <Table.Summary.Cell index={1} />
                  {
                    !['weekly', 'monthly'].includes(periodNameRef.current) &&
                    periodRef.current.map((item, index) => (
                      <Table.Summary.Cell
                        key={`sum-cell-${index + 1}`}
                        index={(1 + index)}
                        className={index === (periodRef.current.length - 1) && 'rounded-tr-lg rounded-br-lg'}
                      >
                        { Number(generateGrandTotalCoalQuality(item)).toLocaleString('en-US') }
                      </Table.Summary.Cell>
                    ))
                  }
                  {
                    ['weekly', 'monthly'].includes(periodNameRef.current) &&
                      <Table.Summary.Cell index={2} className="rounded-tr-lg rounded-br-lg">
                        { Number(generateGrandTotalCoalQuality('')).toLocaleString('en-US') }
                      </Table.Summary.Cell>
                  }
                </Table.Summary.Row>
              )}
            />
          </div>
        </div>

        <Form.Item>
          <div className="mt-5 w-full flex flex-row items-center">
            <ButtonAccent
              isLoading={pitReserveIsLoading || putPitReserveIsLoading}
              isDisabled={isDetailMode}
              size="lg"
              title="Save As Draft"
              onClick={() => postPit(true)}
            />

            <div className="ml-auto flex flex-row items-center gap-x-3">
              <ButtonAccent
                isLoading={pitReserveIsLoading || putPitReserveIsLoading}
                isBordered
                title="Back"
                onClick={() => goToPath('back')}
              />
              <ButtonAccent
                isLoading={pitReserveIsLoading || putPitReserveIsLoading}
                isDisabled={!coalExposeData.length}
                title="Next"
                htmlType="submit"
              />
            </div>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PitReserve;