import { Base } from './Base';

const QualityChecklist = Base.injectEndpoints({
  endpoints: (build)=>({
    postQualityCheck: build.mutation({
      query: ({ id, body }) => ({
        url: `/goe/drillings/plans/${id}/quality-checklist`,
        method: 'POST',
        body
      })
    }),
    getQualityCheck: build.query({
      query: ({ id, version })=>({
        url: `/goe/drillings/plans/${id}/quality-checklist`,
        method: 'GET',
        params: {
          version: version
        }
      })
    }),
    getQualityLogs: build.query({
      query: ({ id, version }) => ({
        url: `/goe/drillings/plans/${id}/quality-checklist/logs`,
        method: 'GET',
        params: {
          version: version
        }
      })
    }),
    approveOrReviseQuality: build.mutation({
      query: ({ id, type, body }) => ({
        url: `/goe/drillings/plans/${id}/quality-checklist/${type}`,
        method: 'POST',
        ...body
          ? { body }
          : undefined
      })
    })
  })
});

export const {
  usePostQualityCheckMutation,
  useLazyGetQualityCheckQuery,
  useApproveOrReviseQualityMutation,
  useGetQualityLogsQuery
} = QualityChecklist;

