import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

import { EMPTY } from 'constant';

const initialState = {
  isAuthenticated: false,
  token: EMPTY.STRING,
  refreshToken: EMPTY.STRING,
  user: EMPTY.OBJECT
};

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthentication: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    registerAuth: (state, action) => {
      const { token, refresh_token, user } = action.payload;

      state.token = token;
      state.refreshToken = refresh_token;
      state.user = user;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      return initialState;
    });
  }
});

export const {
  setAuthentication,
  setToken,
  setRefreshToken,
  setUser,
  registerAuth
} = auth.actions;
export default auth.reducer;