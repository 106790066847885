import { Base } from './Base';

const MasterData = Base.injectEndpoints({
  endpoints: (build) => ({
    getMasterData: build.query({
      query: ({ code, type, companyCode }) => ({
        url: '/master-data',
        method: 'GET',
        params: {
          master_data_code: code,
          ...companyCode
            ? { company_code: companyCode }
            : undefined,
          ...type
            ? { type }
            : undefined
        }
      })
    }),
    getMasterDataPits: build.query({
      query: ({ companyCode }) => ({
        url: '/master-data/pits',
        method: 'GET',
        params: {
          ...companyCode
            ? { company_code: companyCode }
            : undefined
        }
      })
    }),
    getMasterDataPeriods: build.query({
      query: () => ({
        url: '/master-data/period-categories',
        method: 'GET'
      })
    }),
    getMasterDataSeams: build.query({
      query: ({ pitId }) => ({
        url: `/master-data/pits/${pitId}/seams`,
        method: 'GET'
      })
    }),
    getMasterDataUnits: build.query({
      query: ({ type, contractorCode }) => ({
        url: '/master-data/equipment-units',
        method: 'GET',
        params: {
          ...type
            ? { type }
            : undefined,
          ...contractorCode
            ? { contractor_code: contractorCode }
            : undefined
        }
      })
    }),
    getMasterDataTrucks: build.query({
      query: ({ contractorCode }) => ({
        url: '/master-data/trucks',
        method: 'GET',
        params: {
          ...contractorCode
            ? { contractor_code: contractorCode }
            : undefined
        }
      })
    }),
    getMasterDataCompanies: build.query({
      query: () => ({
        url: '/master-data/companies',
        method: 'GET'
      })
    }),
    getMasterDataGoeCompanies: build.query({
      query: () => ({
        url: '/goe/companies',
        method: 'GET'
      })
    }),
    getMasterDataContractors: build.query({
      query: () => ({
        url: '/master-data/contractors',
        method: 'GET'
      })
    }),
    getMasterDataAreas: build.query({
      query: () => ({
        url: '/master-data/areas',
        method: 'GET'
      })
    }),
    getMasterDataLosses: build.query({
      query: ({ type, menu }) => ({
        url: '/master-data/goe/losses',
        method: 'GET',
        params: {
          ...type
            ? { type: type }
            : undefined,
          ...menu
            ? {menu: menu}
            : undefined
        }
      })
    }),
    getMasterDataOmeSections: build.query({
      query: () => ({
        url: '/master-data/ome-sections',
        method: 'GET'
      })
    }),
    getMasterDataOmeLocations: build.query({
      query: () => ({
        url: '/master-data/ome-locations',
        method: 'GET'
      })
    }),
    getMasterDataOmeAreas: build.query({
      query: () => ({
        url: '/master-data/ome-areas',
        method: 'GET'
      })
    }),
    getMasterDataOmeMaintenanceParameter: build.query({
      query: ({ type, subType }) => ({
        url: '/master-data/ome-maintenance-parameter',
        method: 'GET',
        params: {
          ...type
            ? { 'type-loss': type }
            : undefined,
          ...subType
            ? { 'specific-sub-type-loss': subType }
            : undefined
        }
      })
    }),
    getMasterDataOmeSectionsQ: build.query({
      query: () => ({
        url: '/master-data/ome-sections-q',
        method: 'GET'
      })
    }),
    getMasterDataOmeCases: build.query({
      query: () => ({
        url: '/master-data/ome-cases',
        method: 'GET'
      })
    }),
    getMasterDataOmeSystems: build.query({
      query: () => ({
        url: '/master-data/ome-systems',
        method: 'GET'
      })
    }),
    getMasterDataOmeAssets: build.query({
      query: () => ({
        url: '/master-data/ome-assets',
        method: 'GET'
      })
    })
  })
});

export const {
  useGetMasterDataPitsQuery,
  useGetMasterDataPeriodsQuery,
  useLazyGetMasterDataSeamsQuery,
  useLazyGetMasterDataTrucksQuery,
  useLazyGetMasterDataUnitsQuery,
  useGetMasterDataCompaniesQuery,
  useGetMasterDataContractorsQuery,
  useGetMasterDataAreasQuery,
  useGetMasterDataLossesQuery,
  useGetMasterDataGoeCompaniesQuery,
  useGetMasterDataOmeLocationsQuery,
  useGetMasterDataOmeSectionsQuery,
  useGetMasterDataOmeAreasQuery,
  useLazyGetMasterDataOmeMaintenanceParameterQuery,
  useGetMasterDataOmeSectionsQQuery,
  useGetMasterDataOmeCasesQuery,
  useGetMasterDataOmeSystemsQuery,
  useGetMasterDataOmeAssetsQuery,
} = MasterData;