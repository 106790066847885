import { ConfigProvider, Table } from 'antd';

const TableDrillingSumarry = (props) => {

  const { tableData, columns } = props;

  return <ConfigProvider
    theme={{
      components: {
        Table: {
          borderColor: 'white',
          colorBgContainer: '#dfdfdf',
          colorSplit: 'red',
          lineWidth: 4,
          headerBg: '#2D3D5A',
          headerColor: 'white',
          rowHoverBg: 'unset',
        },
      },
    }}
  >
    <Table
      pagination={false}
      bordered
      scroll={{ x: 'max-content' }}
      dataSource={tableData}
      columns={columns}
    />
  </ConfigProvider>;
};

export default TableDrillingSumarry;