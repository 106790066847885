import './TableData.module.css';
import { useEffect } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import {
  Col,
  ConfigProvider,
  Input,
  Row,
  Select,
  Table
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import {
  reset,
  setPage,
  setPageSize,
  setSearch,
  setSortBy,
  setOrderBy
} from 'store/Table';
import { DEFAULT, EMPTY } from 'constant';

import LoadingIcon from 'components/LoadingIcon';

const TableData = (props) => {
  const {
    isLoading,
    isTransparent = false,
    rowKey,
    totalData = EMPTY.NUMBER,
    dataSource,
    columns,
    children,
    additionalChildren,
    scrollWidth,
    customRow,
    notSearchable,
    expandableColumn,
    headerBg,
    headerColor,
    scrollHeight,
    isBordered = false
  } = props;
  const dispatch = useDispatch();
  const {
    limits,
    page,
    pageSize,
    search
  } = useSelector((state) => state.table);

  const onLimitChange = async (value) => {
    await dispatch(setPageSize(value));
  };

  const generateCustomRow = (record, index) => {
    if (customRow) {
      return customRow(record, index);
    }

    return index % 2 === 0 ? 'bg-white' : 'bg-gray-100';
  };

  const onSearch = async (ev) => {
    if (isEmpty(ev.target.value)|| (ev.type === 'keydown' && ev.key === 'Enter')) {
      await dispatch(setSearch(ev.target.value));
    }
  };

  const onTableDataChange = (pagination, filters, sorter) => {
    const { current } = pagination;
    const { field, order } = sorter;

    dispatch(setPage(current));
    dispatch(setSortBy(field));
    dispatch(
      setOrderBy(order === DEFAULT.SORT_ASC
        ? DEFAULT.KEY_SORT_ASC
        : DEFAULT.KEY_SORT_DESC
      ));
  };

  useEffect(() => {
    return () => dispatch(reset());
  }, []);

  return (
    <div className={`p-5 ${isTransparent ? EMPTY.STRING : 'rounded-lg bg-white shadow-md'}`}>
      <Row className="mb-5">
        <Col flex={4}>
          {children}
        </Col>
        <Col flex={4}>
          <div className="w-full flex flex-row justify-center items-center">
            <span>Show</span>
            <Select
              disabled={!dataSource}
              value={pageSize}
              options={limits}
              onChange={onLimitChange}
              className="mx-2 w-16"
            />
            <span>Data</span>
          </div>
        </Col>
        <Col flex={4}>
          <div className="w-full flex flex-row justify-end">
            {!notSearchable ? (
              <Input
                allowClear
                placeholder="search here..."
                disabled={!dataSource}
                prefix={<AiOutlineSearch className="text-pink-700" />}
                defaultValue={search}
                className="w-52"
                onChange={onSearch}
                onPressEnter={onSearch}
              />
            ) : (
              <div className="w-52" />
            )}
          </div>
        </Col>
      </Row>
      {
        isLoading
          ? (
            <div className="flex flex-row justify-center items-center">
              <LoadingIcon />
            </div>
          )
          : (
            <>
              {additionalChildren}
              <ConfigProvider
                theme={{
                  components: {
                    Table: {
                      headerBg: headerBg || '#2D3D5A',
                      headerColor: headerColor ||'white',
                    },
                  },
                }}
              >
                <Table
                  scroll={{ x: scrollWidth || 0, ...scrollHeight && {y: scrollHeight} }}
                  dataSource={dataSource}
                  columns={columns}
                  rowKey={(record) => record[rowKey]}
                  showSorterTooltip={false}
                  pagination={
                    dataSource
                      ? {
                        showSizeChanger: false,
                        position: ['bottomRight'],
                        current: page,
                        pageSize: pageSize,
                        showTotal: (total, range) => (
                          <span className="absolute left-0">
                            {`Showing ${range[0]} - ${range[1]} of ${total} data`}
                          </span>
                        ),
                        total: Number(totalData)
                      }
                      : false
                  }
                  {...expandableColumn}
                  rowClassName={(record, index) => generateCustomRow(record, index)}
                  onChange={onTableDataChange}
                  bordered={isBordered}
                />
              </ConfigProvider>
              
            </>
          )
      }
    </div>
  );
};

export default TableData;
