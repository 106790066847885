import { Button } from 'antd';

const ButtonAccent = (props) => {
  const {
    isBordered = false,
    isBorderError = false,
    isSuccess = false,
    isDisabled,
    isLoading,
    icon = null,
    size,
    title,
    htmlType,
    onClick = () => {},
    className
  } = props;

  const generateSize = () => {
    if (icon) {
      switch (size) {
        case 'lg':
          return 'w-48';
        case 'xl':
          return 'w-52';
        case '2xl':
          return 'w-64';
        default:
          return 'w-36';
      }
    }

    switch (size) {
      case 'lg':
        return 'w-36';
      case 'xl':
        return 'w-40';
      case '2xl':
        return 'w-52';
      default:
        return 'w-32';
    }
  };
  
  return (
    <Button
      disabled={isDisabled || isLoading}
      className={`h-10 font-bold text-[16px]
      ${className}
      ${generateSize()}
      ${isBordered
        ? `border-2
        ${isBorderError
          ? 'text-red-400 border-red-400 hover:!text-red-400 hover:!border-red-200 disabled:opacity-50 disabled:border-2 disabled:border-red-400 disabled:text-red-400 disabled:bg-transparent'
          : 'text-[#2D3D5A] border-[#2D3D5A] hover:!text-[#2D3D5A] hover:!border-[#2D3D5A] disabled:opacity-50 disabled:border-2 disabled:border-[#462F92] disabled:bg-transparent'}`
        : `${isSuccess
          ? 'text-white bg-green-500 hover:!text-white hover:!bg-green-500 disabled:!bg-green-500 disabled:opacity-25 disabled:text-white'
          : 'text-white bg-[#2D3D5A] hover:!text-white hover:!border-transparent disabled:opacity-50 disabled:text-gray-500' }`}
        ${isLoading && 'animate-pulse animate-infinite'}`}
      htmlType={htmlType}
      icon={icon}
      onClick={onClick}
    >
      {title}
    </Button>
  );
};

export default ButtonAccent;