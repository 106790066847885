import { sampleId } from 'constant/TableReconciliation';
import { generateRemarkTable } from 'constant/TableReconciliation/TableRemark';
import TableDrillingSumarry from 'pages/DrillingPlan/DrillingSummary/TableDrillingSummary';

const Remark = (props) => {
  const { isDetailMode, remarkData, onChange, onDeleteRow, otherData } = props;
  const columns = generateRemarkTable(
    remarkData,
    isDetailMode,
    onChange,
    onDeleteRow
  );

  const populateSampleId = () => {
    let finalData = remarkData.map((data, index) => {
      return {
        sample_id: otherData.sample_information[index].sample_id,
        ...data 
      };
    });
    return finalData;
  };
  columns.splice(1, 0, sampleId);

  return <TableDrillingSumarry 
    columns={ columns }
    tableData={ populateSampleId() }
  />;
};

export default Remark;