import { ACTION } from 'constant';
import { FiTrash } from 'react-icons/fi';
import { GiPencil } from 'react-icons/gi';

const maintenanceOEColumns = (
  url,
  onEdit,
  onDelete
) => [
  {
    title: 'No',
    render: (text, record, index) => index + 1,
  },
  {
    dataIndex: 'year',
    title: 'Year',
    render: (text) => (
      <div className='w-[100px]'>
        {text}
      </div>
    ),
  },
  {
    dataIndex: 'description',
    title: 'Description',
    render: (text) => (
      <div className='w-[400px]'>
        {text}
      </div>
    ),
  },
  {
    dataIndex: 'unit',
    title: 'Unit',
    render: (text) => (
      <div className='w-[100px] text-center'>
        {text}
      </div>
    ),
  },
  {
    dataIndex: 'jan',
    title: 'Jan',
  },
  {
    dataIndex: 'feb',
    title: 'Feb',
  },
  {
    dataIndex: 'mar',
    title: 'Mar',
  },
  {
    dataIndex: 'apr',
    title: 'Apr',
  },
  {
    dataIndex: 'may',
    title: 'May',
  },
  {
    dataIndex: 'jun',
    title: 'Jun',
  },
  {
    dataIndex: 'jul',
    title: 'Jul',
  },
  {
    dataIndex: 'aug',
    title: 'Aug',
  },
  {
    dataIndex: 'sep',
    title: 'Sep',
  },
  {
    dataIndex: 'oct',
    title: 'Oct',
  },
  {
    dataIndex: 'nov',
    title: 'Nov',
  },
  {
    dataIndex: 'dec',
    title: 'Dec',
  },
  {
    title: 'Action',
    align: 'center',
    render: (text, record) => (
      <div className="flex flex-row justify-center items-center gap-x-3">
        {
          <div
            className="rounded-full bg-[#2D3D5A] cursor-pointer"
            onClick={() => onEdit(record.id, ACTION.EDIT)}
          >
            <GiPencil size="24px" className="p-1 text-white" />
          </div>
        }
        {
          <div
            className="rounded-full bg-pink-700 cursor-pointer"
            onClick={() => onDelete(record.id, ACTION.DELETE)}
          >
            <FiTrash size="24px" className="p-1 text-white" />
          </div>
        }
      </div>
    ),
  },
];

export { maintenanceOEColumns };
