import { 
  Card,
  Modal,
  Image
} from 'antd';
import { convertToMegaBytes } from 'utils/FileUtility';

const ModalEvidence = (props) => {
  const { isModalOpen, setIsModalOpen, evidenceData } = props;

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        title="Evidence"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        width={600}
        style={{ overflowY: 'auto', maxHeight: '80%' }}
      >
        <hr className='my-3' />
        {
          evidenceData.length !== 0 &&
          evidenceData.map((data, index) => (
            <Card className='rounded-2xl bg-[#EBEDF8]' key={index}>
              <div className='w-full flex item-center'>
                <Image
                  width={100}
                  src={data.thumbnail}
                  className="rounded-lg"
                />
                <div className='ml-3'>
                  <p>{data.fileName}</p>
                  <p className='text-[#AAAAAA]'>{convertToMegaBytes(data.size)} MB</p>
                </div>
              </div>
            </Card>
          ))
        }
      </Modal>
    </>
  );
};

export default ModalEvidence;