const ListModule = [
  {
    id:1,
    name:'Geology OE',
    picture:`${process.env.PUBLIC_URL}/assets/logo-geology-oe.svg`,
    disabledPicture:`${process.env.PUBLIC_URL}/assets/logo-geology-oe-disabled.svg`,
    active: true,
    children:[
      {
        id:'1_1',
        name: 'Input Data',
        aliasName: 'geology_oe',
        path: 'geology-oe/drilling-plan',
      },
      {
        id:'1_2',
        name: 'Dashboard Overview',
        aliasName: 'geology_dashboard',
        path: 'geology-dashboard',
      }
    ],
  },
  {
    id:2,
    name:'Geotech OE',
    picture:`${process.env.PUBLIC_URL}/assets/logo-geotech-oe.svg`,
    disabledPicture:`${process.env.PUBLIC_URL}/assets/logo-geotech-oe-disabled.svg`,
    path:'',
    active: false
  },
  {
    id:3,
    name:'Mine Planning OE',
    aliasName: 'mpoe',
    picture:`${process.env.PUBLIC_URL}/assets/logo-mine-planning-oe.svg`,
    disabledPicture:`${process.env.PUBLIC_URL}/assets/logo-mine-planning-oe-disabled.svg`,
    path:'mine-planning-oe/dashboard',
    active: true
  },
  {
    id:4,
    name:'Mine Operation OE',
    picture:`${process.env.PUBLIC_URL}/assets/logo-mine-operation-oe.svg`,
    disabledPicture:`${process.env.PUBLIC_URL}/assets/logo-mine-operation-oe-disabled.svg`,
    path:'',
    active: false
  },
  {
    id:5,
    name:'Survey OE',
    picture:`${process.env.PUBLIC_URL}/assets/logo-survey-oe.svg`,
    disabledPicture:`${process.env.PUBLIC_URL}/assets/logo-survey-oe-disabled.svg`,
    path:'',
    active: false
  },
  {
    id:6,
    name:'Coal Hauling OE',
    picture:`${process.env.PUBLIC_URL}/assets/logo-coal-hauling-oe.svg`,
    disabledPicture:`${process.env.PUBLIC_URL}/assets/logo-coal-hauling-oe-disabled.svg`,
    path:'',
    active: false
  },
  {
    id:7,
    name:'Crushing Plant OE',
    picture:`${process.env.PUBLIC_URL}/assets/logo-crushing-plant-oe.svg`,
    disabledPicture:`${process.env.PUBLIC_URL}/assets/logo-crushing-plant-oe-disabled.svg`,
    path:'',
    active: false
  },
  {
    id:8,
    name:'Port OE',
    picture:`${process.env.PUBLIC_URL}/assets/logo-port-oe.svg`,
    disabledPicture:`${process.env.PUBLIC_URL}/assets/logo-port-oe-disabled.svg`,
    path:'',
    active: false
  },
  {
    id:9,
    name:'Coal Quality OE',
    picture:`${process.env.PUBLIC_URL}/assets/logo-coal-quality-oe.svg`,
    disabledPicture:`${process.env.PUBLIC_URL}/assets/logo-coal-quality-oe-disabled.svg`,
    path:'',
    active: false
  },
  {
    id:10,
    name:'Marine & Logistic OE',
    picture:`${process.env.PUBLIC_URL}/assets/logo-marine-oe.svg`,
    disabledPicture:`${process.env.PUBLIC_URL}/assets/logo-marine-oe-disabled.svg`,
    path:'',
    active: false
  },    
  {
    id:11,
    name:'Warehouse OE',
    picture:`${process.env.PUBLIC_URL}/assets/logo-warehouse-oe.svg`,
    disabledPicture:`${process.env.PUBLIC_URL}/assets/logo-warehouse-oe-disabled.svg`,
    path:'',
    active: false
  },
  {
    id:12,
    name:'Maintenance OE',
    picture:`${process.env.PUBLIC_URL}/assets/logo-maintenance-oe.svg`,
    disabledPicture:`${process.env.PUBLIC_URL}/assets/logo-maintenance-oe-disabled.svg`,
    path:'maintenance-oe/plan-stop',
    active: true
  },
  {
    id:13,
    name:'Procurement OE',
    picture:`${process.env.PUBLIC_URL}/assets/logo-procurement-oe.svg`,
    disabledPicture:`${process.env.PUBLIC_URL}/assets/logo-procurement-oe-disabled.svg`,
    path:'',
    active: false
  },
  {
    id:14,
    name:'Mine Closure OE',
    picture:`${process.env.PUBLIC_URL}/assets/logo-mine-closure-oe.svg`,
    disabledPicture:`${process.env.PUBLIC_URL}/assets/logo-mine-closure-oe-disabled.svg`,
    path:'',
    active: false
  },
  {
    id:15,
    name:'PT TRUST OE',
    picture:`${process.env.PUBLIC_URL}/assets/logo-pt-trust-oe.svg`,
    disabledPicture:`${process.env.PUBLIC_URL}/assets/logo-pt-trust-oe-disabled.svg`,
    path:'',
    active: false
  },
];

export { ListModule };