import { isEmpty } from 'lodash';
import { EMPTY, ERROR } from 'constant';

export const transformError = (error) => {
  switch (true) {
    case !isEmpty(error?.data): {
      const {
        data: {
          error: errorBody
        }
      } = error;

      return {
        code: errorBody.code || EMPTY.NUMBER,
        message: `${isEmpty(errorBody.message)
          ? ERROR.DEFAULT
          : errorBody.message}`
      };
    }
    case error?.name === 'AbortError':
      return {
        code: EMPTY.NUMBER,
        message: 'Server takes too much time to process your request, please try again or contact your administrator'
      };
    default:
      return {
        code: error?.code || EMPTY.NUMBER,
        message: `${isEmpty(error?.message)
          ? ERROR.DEFAULT
          : error?.message?.error?.message || error?.message}`
      };
  }
};