import {
  Outlet,
  useLocation,
  useNavigate
} from 'react-router';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { isEmpty } from 'lodash';

import { KEY } from 'constant';

import NavBottom from 'components/NavBottom';
import NavSide from 'components/NavSide';
import NavTop from 'components/NavTop';

const Base = () => {
  const loc = useLocation();
  const navigate = useNavigate();
  const {
    isAuthenticated,
    user,
    token
  } = useSelector((state) => state.auth);
  const { isSideBarExpanded } = useSelector((state) => state.preferences);

  const guardRoute = async () => {
    if (isEmpty(token) || !isAuthenticated) {
      /*
      * to avoid being purged when user logout because of unauthorized
      * */
      loc.search
        ? localStorage.setItem(KEY.LAST_ROUTE, `${loc.pathname}${loc.search}`)
        : localStorage.setItem(KEY.LAST_ROUTE, loc.pathname);
      
      navigate('/login');

      return;
    }

    if (isEmpty(user) || isEmpty(user.company)) {
      navigate('/not-found');
    }
  };

  useEffect(() => {
    guardRoute();
  }, []);
  
  return (
    <div className="bg-[#ECEEF2]">
      {/* Mobile Screen */}
      <div className="lg:hidden">
        <div className="p-5 h-[calc(100vh-7rem)] overscroll-y-contain overflow-y-auto">
          <Outlet />
        </div>
        <div className="p-3 w-full fixed bottom-0">
          <NavBottom />
        </div>
      </div>
      {/* Desktop Screen */}
      <div className="p-5 hidden lg:h-screen lg:flex lg:flex-row overflow-hidden">
        <NavSide className={isSideBarExpanded ? 'w-[280px]' : 'w-20'} />
        <div className="ml-5 w-full flex flex-col lg:h-screen">
          <NavTop />
          <div className="mt-5 overscroll-y-contain overflow-y-auto mb-10">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Base;