import { ACTION } from 'constant';
import dayjs from 'dayjs';
import { FiTrash } from 'react-icons/fi';
import { GiPencil } from 'react-icons/gi';

const tableOmeQColumns = (
  url,
  sortBy,
  orderBy,
  onEdit,
  onDelete,
  isPreview = false
) => [
  {
    title: 'No',
    render: (text, record, index) => index + 1,
  },
  {
    title: 'Action',
    align: 'center',
    render: (text, record) => (
      <div className="flex flex-row justify-center items-center gap-x-3">
        {
          <div
            className="rounded-full bg-[#2D3D5A] cursor-pointer"
            onClick={() => onEdit(record.id, ACTION.EDIT)}
          >
            <GiPencil size="24px" className="p-1 text-white" />
          </div>
        }
        {
          <div
            className="rounded-full bg-pink-700 cursor-pointer"
            onClick={() => onDelete(record.id, ACTION.DELETE)}
          >
            <FiTrash size="24px" className="p-1 text-white" />
          </div>
        }
      </div>
    ),
    visible: !isPreview,
  },
  {
    dataIndex: 'date',
    title: 'Date',
    sorter: true,
    sortOrder: sortBy === 'date' ? orderBy : null,
  },
  {
    dataIndex: isPreview ? 'section' : 'ome_section_q',
    title: 'Section',
    sorter: false,
    sortOrder:
      sortBy === (isPreview ? 'section' : 'ome_section_q') ? orderBy : null,
    render: (text) => text?.name,
  },
  {
    dataIndex: 'work_order',
    title: 'Work Order',
    sorter: true,
    sortOrder: sortBy === 'work_order' ? orderBy : null,
  },
  {
    dataIndex: 'wo_description',
    title: 'Work Description',
    sorter: true,
    sortOrder: sortBy === 'wo_description' ? orderBy : null,
  },
  {
    dataIndex: isPreview ? 'case' : 'ome_case',
    title: 'Case',
    sorter: true,
    sortOrder: sortBy === (isPreview ? 'case' : 'ome_case') ? orderBy : null,
    render: (text) => text?.name,
  },
  {
    dataIndex: isPreview ? 'system' : 'ome_system',
    title: 'System',
    sorter: true,
    sortOrder:
      sortBy === (isPreview ? 'system' : 'ome_system') ? orderBy : null,
    render: (text) => text?.name,
  },
  {
    dataIndex: 'description',
    title: 'Description',
    sorter: true,
    sortOrder: sortBy === 'description' ? orderBy : null,
  },
  {
    dataIndex: isPreview ? 'asset' : 'ome_asset',
    title: 'Asset',
    sorter: true,
    sortOrder: sortBy === (isPreview ? 'asset' : 'ome_asset') ? orderBy : null,
    render: (text) => text?.name,
  },
  {
    dataIndex: 'pic',
    title: 'Pic',
    sorter: true,
    sortOrder: sortBy === 'pic' ? orderBy : null,
  },
  {
    dataIndex: 'time_start',
    title: 'Time Start',
    sorter: true,
    sortOrder: sortBy === 'time_start' ? orderBy : null,
  },
  {
    dataIndex: 'time_end',
    title: 'Time End',
    sorter: true,
    sortOrder: sortBy === 'time_end' ? orderBy : null,
  },
  {
    dataIndex: 'total_time_loss',
    title: 'Total Time Loss',
    sorter: true,
    sortOrder: sortBy === 'total_time_loss' ? orderBy : null,
  },
  {
    dataIndex: 'note',
    title: 'Note',
    sorter: true,
    sortOrder: sortBy === 'note' ? orderBy : null,
  },
];

const generateParamBulkCreateOmeQ = (data) => {
  return data.map((v) => {
    return {
      date: dayjs(v.date, 'DD-MM-YYYY').format('DD-MM-YYYY'),
      ome_section_q_id: v.section.id,
      work_order: v.work_order,
      wo_description: v.wo_description,
      ome_case_id: v.case.id,
      ome_system_id: v.system.id,
      description: v.description,
      ome_asset_id: v.asset.id,
      pic: v.pic,
      time_start: dayjs(v.time_start, 'HH:mm:ss').format('HH:mm'),
      time_end: dayjs(v.time_start, 'HH:mm:ss').format('HH:mm'),
      note: v.note,
    };
  });
};

export { generateParamBulkCreateOmeQ, tableOmeQColumns };
