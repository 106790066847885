import { Base } from './Base';

const HourCapacity = Base.injectEndpoints({
  endpoints: (build) => ({
    getHourCapacityById: build.query({
      query: ({ id, params }) => ({
        url: `/hour-capacities/${id}`,
        method: 'GET',
        ...params
          ? { params }
          : undefined
      })
    }),
    getLatestHourCapacity: build.query({
      query: ({ id, params }) => ({
        url: `/hour-capacities/${id}/latest`,
        method: 'GET',
        ...params
          ? { params }
          : undefined
      })
    }),
    postHourCapacity: build.mutation({
      query: (body) => ({
        url: '/hour-capacities',
        method: 'POST',
        body
      })
    }),
    putHourCapacity: build.mutation({
      query: ({ id, body }) => ({
        url: `/hour-capacities/${id}`,
        method: 'PUT',
        body
      })
    })
  })
});

export const {
  useGetHourCapacityByIdQuery,
  useLazyGetHourCapacityByIdQuery,
  useLazyGetLatestHourCapacityQuery,
  usePostHourCapacityMutation,
  usePutHourCapacityMutation
} = HourCapacity;