import { DatePicker, Form, Select } from 'antd';
import { usePostPlanMutation } from 'api/DrillingPlan';
import { useGetMasterDataGoeCompaniesQuery } from 'api/MasterData';
import ButtonAccent from 'components/ButtonAccent';
import SelectSearchAccent from 'components/SelectSearchAccent';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { transformError } from 'utils/ErrorTransformer';
import ModalInformation from '../components/ModalInformation';
import { getFullPath } from 'utils/PathUtility';
import { createSearchParams } from 'react-router-dom';
import { useModalConfirmationContext } from 'components/ModalConfirmationProvider';
import PreventNavigation from 'constant/PreventNavigation';

const DrillingForm = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [informationModal, setInformationModal] = useState(false);
  const {showModal, resetModal} = useModalConfirmationContext();
  const [
    postPlan,
    {
      isLoading: postPlanIsLoading,
      isError: postPlanIsError,
      error: postPlanError,
      isSuccess: postPlanIsSuccess,
      data: planData
    },
  ] = usePostPlanMutation();
  

  const { data: dataCompanies, isFetching: companiesIsFetching } =
    useGetMasterDataGoeCompaniesQuery({ refetchOnMountOrArgChange: true });

  const onFinish = (values) => {
    const company = dataCompanies.find((val) => val.id === values.company);
    const payload = {
      year: moment(new Date(values.year)).format('YYYY'),
      company: {
        id: company.id,
        name: company.name,
        alias_name: company.alias_name,
      },
      plan_type: values.planning_type,
    };
    postPlan(payload);
  };

  const handleBackButton = () => {
    showModal({
      isShown: true,
      type: 'confirmation',
      title:'Are you sure want to leave this page?',
      message: 'Changes you made may not be saved',
      onSubmit: () => {
        navigate(-1);
        resetModal();
      }
    });
  };

  useEffect(() => {
    if (postPlanIsError) {
      setInformationModal(true);
      return;
    }
  }, [postPlanIsError]);
  useEffect(() => {
    if (postPlanIsSuccess) {
      const id = planData.id;
      const version = 1;
      navigate({
        pathname: getFullPath({
          parent: 'drilling-plan',
          child: 'quality-checklist',
          type:'create'
        },'geology-oe'),
        ...{ search: createSearchParams({ id, version }).toString() }
      });
    }
  }, [postPlanIsSuccess]);
  const handleCloseSite = useCallback((ev) => ev.preventDefault() ,[]);
  
  useEffect(() => {
    PreventNavigation(false, handleCloseSite);
  }, []);
  return (
    <div>
      <Form form={form} onFinish={onFinish}>
        <div className="flex grid-cols-3 bg-white p-6 gap-6 rounded-2xl items-center justify-center">
          <label className="w-full">
            <span className="font-bold">Year</span>
            <Form.Item
              name="year"
              rules={[
                {
                  required: true,
                  message: 'Please input Year',
                },
              ]}
            >
              <DatePicker.YearPicker
                allowClear
                disabledDate={(current) => current.isBefore(moment())}
                className="mt-2 w-full h-12"
              />
            </Form.Item>
          </label>
          <label className="w-full">
            <span className="font-bold">Mine Site</span>
            <Form.Item
              name="company"
              rules={
                [
                  {
                    required: true,
                    message: 'Please input Mine Site',
                  },
                ]
              }
            >
              <SelectSearchAccent
                labelKey="alias_name"
                valueKey="id"
                options={dataCompanies}
                placeholder="Select mine site"
                isLoading={companiesIsFetching}
                className={'h-12 mt-2'}
              />
            </Form.Item>
          </label>
          <div className="w-full">
            <span className="font-bold">Drilling Stage</span>
            <Form.Item
              name="planning_type"
              rules={[
                {
                  required: true,
                  message: 'Please input Drilling Stage',
                },
              ]}
            >
              <Select
                allowClear
                placeholder="Select drilling stage"
                options={[
                  { label: 'Pre-production', value: 'PREPRODUCTION' },
                  { label: 'Exploration', value: 'EXPLORATION' },
                ]}
                className="mt-2 w-full h-12"
              />
            </Form.Item>
          </div>
        </div>
        <div className="flex flex-row justify-between mt-16">
          <ButtonAccent
            isBordered
            title="Cancel"
            isLoading={postPlanIsLoading}
            onClick={handleBackButton}
          />
          <ButtonAccent
            title="Next"
            isLoading={postPlanIsLoading}
            htmlType="submit"
          />
        </div>
      </Form>
      <ModalInformation
        isModalOpen={informationModal}
        setIsModalOpen={setInformationModal}
        errorMessage={`${transformError(postPlanError).message}`}
      />
    </div>
  );
};

export default DrillingForm;
