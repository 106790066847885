import { ACTION } from 'constant';
import moment from 'moment';
import { FiTrash } from 'react-icons/fi';
import { GiPencil } from 'react-icons/gi';

const tablePlanStopColumns = (
  url,
  sortBy,
  orderBy,
  onEdit,
  onDelete
) => [
  {
    title: 'No',
    render: (text, record, index) => index + 1,
  },
  {
    title: 'Action',
    align: 'center',
    render: (text, record) => (
      <div className="flex flex-row justify-center items-center gap-x-3">
        {
          <div
            className="rounded-full bg-[#2D3D5A] cursor-pointer"
            onClick={() => onEdit(record.id, ACTION.EDIT)}
          >
            <GiPencil size="24px" className="p-1 text-white" />
          </div>
        }
        {
          <div
            className="rounded-full bg-pink-700 cursor-pointer"
            onClick={() => onDelete(record.id, ACTION.DELETE)}
          >
            <FiTrash size="24px" className="p-1 text-white" />
          </div>
        }
      </div>
    ),
  },
  {
    dataIndex: 'date',
    title: 'Date',
    sorter: true,
    sortOrder: sortBy === 'date' ? orderBy : null,
    render: (value, record) => {
      return value && <div>{moment(value).format('DD-MM-YYYY')}</div>;
    },
  },
  {
    dataIndex: 'time_start',
    title: 'Time Start',
    sorter: true,
    sortOrder: sortBy === 'time_start' ? orderBy : null,
  },
  {
    dataIndex: 'time_end',
    title: 'Time End',
    sorter: true,
    sortOrder: sortBy === 'time_end' ? orderBy : null,
  },
  {
    dataIndex: 'plan_stop',
    title: 'Plan Stop',
    sorter: true,
    sortOrder: sortBy === 'plan_stop' ? orderBy : null,
  },
  {
    dataIndex: 'detailed_plan_stop',
    title: 'Detailed Plan Stop',
    sorter: true,
    sortOrder: sortBy === 'detailed_plan_stop' ? orderBy : null,
  },
  {
    dataIndex: 'remarks',
    title: 'Remark',
    sorter: true,
    sortOrder: sortBy === 'remark' ? orderBy : null,
  },
];

export { tablePlanStopColumns };
