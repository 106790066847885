import moment from 'moment';
import { FiEye, FiTrash } from 'react-icons/fi';
import { GiPencil } from 'react-icons/gi';
import { toLower, upperFirst } from 'lodash';

import {
  ACTION,
  EMPTY,
  PERMISSION
} from 'constant';

const generateStatusBg = (status) => {
  const loweredStatus = toLower(status);
  const isStatusIncludes = (value) => loweredStatus.includes(value);

  switch (true) {
    case isStatusIncludes('draft'):
      return 'bg-yellow-500/25 text-yellow-500';
    case isStatusIncludes('waiting') && isStatusIncludes('acknowledge'):
      return 'bg-gradient-to-r from-blue-200/25 to-blue-500/25 text-blue-500';
    case isStatusIncludes('waiting') && isStatusIncludes('approve'):
      return 'bg-gradient-to-r from-emerald-200/25 to-emerald-400/25 text-emerald-400';
    case isStatusIncludes('acknowledge'):
      return 'bg-blue-500/25 text-blue-500';
    case isStatusIncludes('submitted'):
      return 'bg-[#2D3D5A20] text-[#2D3D5A]';
    case isStatusIncludes('approved'):
      return 'bg-emerald-400/25 text-emerald-400';
    case isStatusIncludes('rejected'):
      return 'bg-pink-400/25 text-pink-700';
    default:
      return 'bg-black/25 text-black';
  }
};

const assumptionColumns = (
  url,
  isUserHasPermissionInFeature,
  sortBy,
  orderBy,
  onView,
  onEdit,
  onDelete
) => [
  {
    dataIndex: 'date',
    title: 'Date',
    sorter: true,
    sortOrder: sortBy === 'date' ? orderBy : null,
    render: (text) => (
      <span>
        {
          text
            ? moment(text).format('DD MMM YYYY')
            : EMPTY.RECORD
        }
      </span>
    )
  },
  {
    dataIndex: 'category',
    title: 'Category',
    sorter: true,
    sortOrder: sortBy === 'category' ? orderBy : null,
  },
  {
    dataIndex: 'company',
    title: 'Company Owner',
    sorter: true,
    sortOrder: sortBy === 'company' ? orderBy : null,
  },
  {
    dataIndex: 'contractor',
    title: 'Contractor',
    sorter: true,
    sortOrder: sortBy === 'contractor' ? orderBy : null,
  },
  {
    dataIndex: 'status',
    title: 'Status',
    sorter: true,
    sortOrder: sortBy === 'status' ? orderBy : null,
    render: (text) => (
      <div className={`px-2 py-1 flex flex-row justify-center font-bold rounded-lg ${generateStatusBg(text)}`}>
        {upperFirst(toLower(text))}
      </div>
    )
  },
  {
    title: 'Action',
    render: (text, record) => (
      <div className="flex flex-row justify-center items-center gap-x-3">
        {
          isUserHasPermissionInFeature(url, PERMISSION.READ) && (
            <div
              className="rounded-full bg-[#2D3D5A] cursor-pointer"
              onClick={() => onView(record.id, ACTION.DETAIL)}
            >
              <FiEye
                size="24px"
                className="p-1 text-white"
              />
            </div>
          )
        }
        {
          (
            !toLower(record.status).includes('submitted')
            && !toLower(record.status).includes('waiting')
            && isUserHasPermissionInFeature(url, PERMISSION.UPDATE)
          )
          && (
            <div
              className="rounded-full bg-[#2D3D5A] cursor-pointer"
              onClick={() => onEdit(record.id, ACTION.EDIT)}
            >
              <GiPencil
                size="24px"
                className="p-1 text-white"
              />
            </div>
          )
        }
        {
          (
            !toLower(record.status).includes('submitted')
            && !toLower(record.status).includes('waiting')
            && isUserHasPermissionInFeature(url, PERMISSION.DELETE)
          )
          && (
            <div
              className="rounded-full bg-pink-700 cursor-pointer"
              onClick={() => onDelete(record.id, ACTION.DELETE)}
            >
              <FiTrash
                size="24px"
                className="p-1 text-white"
              />
            </div>
          )
        }
      </div>
    )
  }
];

export { assumptionColumns };