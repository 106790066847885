import { 
  generateDrillingHoleLocationTable
} from 'constant/TableDrillingSumarry/TableDrillingHoleLocation';
import TableDrillingSumarry from '../TableDrillingSummary';

const DrillingHoleLocation = (props) => {
  const { drillingHoleLocationData, drillHoleName } = props;
  const columns = generateDrillingHoleLocationTable(false);
  columns.unshift(drillHoleName);
  return <TableDrillingSumarry 
    columns={ columns }
    tableData={ drillingHoleLocationData }
  />;
};

export default DrillingHoleLocation;