import { drillHoleName } from 'constant/TableDrillingSummary';
import { generateDrillingEvidenceTable } from 'constant/TableDrillingSummaryActual/TableDrillingEvidence';
import TableDrillingSumarry from 'pages/DrillingPlan/DrillingSummary/TableDrillingSummary';

const DrillingEvidenceActual = (props) => {
  const {drillingEvidanceData, onChange, onDeleteRow, otherData, isDetailMode, downloadFile} = props;
  const columns = generateDrillingEvidenceTable(isDetailMode, drillingEvidanceData, onChange, onDeleteRow, otherData, downloadFile);
  columns.splice(1, 0, drillHoleName);
  const populateDrillHoleName = () => {
    let finalData = drillingEvidanceData.map((data, index) => {
      return {
        ...data, 
        drill_hole_name: otherData.drill_hole_information[index]?.drill_hole_name,
      };
    });
    return finalData;
  };
  return <TableDrillingSumarry columns={columns} tableData={populateDrillHoleName()}/>;
};

export default DrillingEvidenceActual;