import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { logout } from 'store/Auth/Thunk';

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutUser = async () => {
    try {
      await dispatch(logout()).unwrap();
      navigate('/login');
    } catch (error) {
      navigate('/login');
    }
  };

  return (
    <div className="h-full flex flex-col">
      <div className="mt-auto flex flex-col">
        <span className="text-xs text-gray-500 self-end">
          {`v. ${process.env.REACT_APP_VERSION}`}
        </span>
        <Button
          className="bg-[#2D3D5A] text-white"
          onClick={logoutUser}
        >
          Logout
        </Button>
      </div>
    </div>
  );
};

export default Profile;