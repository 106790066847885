import { Tag, Table } from 'antd';

import moment from 'moment';
import {
  useEffect,
  useState
} from 'react';
import { useLocation, useNavigate } from 'react-router';
import {
  generateSubmissionColumns,
  generateSubmissionData
} from 'constant/TableSubmissionMinePlan';
import { useGetSubmissionByIdQuery } from 'api/ConfidentLevel';
import { toast } from 'react-toastify';
import { transformError } from 'utils/ErrorTransformer';

import Toast from 'components/Toast';
import TableRaw from 'components/TableRaw';
import ButtonAccent from 'components/ButtonAccent';
import ModalEvidence from './ModalEvidence';

const MainPlan = () => {
  const navigate = useNavigate();
  const loc = useLocation();
  const confidentLevelId = new URLSearchParams(loc.search).get('id');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [evidenceData, setEvidenceData] = useState([]);

  const {
    data: submissionByIdData,
    isFetching: submissionByIdDataIsFetching,
    isError: submissionByIdDataIsError,
    error: submissionByIdDataError
  } = useGetSubmissionByIdQuery(
    { id: confidentLevelId },
    { refetchOnMountOrArgChange: true }
  );

  const generateSubmissionRowStyle = (record, index) => {
    if (isNaN(Number(record.number))) {
      return 'font-bold bg-[#2D3D5A] text-white hover:text-[#2D3D5A]';
    }

    return index % 2 === 0 ? 'bg-white' : 'bg-gray-100';
  };

  const calculatePixel = (remVal) => {
    /**
     * `1rem` is `16px`
     * */
    return `${(window.innerWidth - (remVal * 16))}px`;
  };
  
  const onViewEvidence = (data) => {
    let tempEvidence = evidenceData;
    tempEvidence = data;
    setEvidenceData(tempEvidence);
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (submissionByIdDataIsError) {
      const errorMsg = submissionByIdDataError;

      toast.error(<Toast message={'ERROR'} detailedMessage={`${transformError(errorMsg).message}`} />);
      return;
    }
  }, [submissionByIdDataIsError]);
  
  return (
    <>
      <div className="p-5 flex flex-col bg-white rounded-xl">
        <div className="flex flex-row items-center justify-start">
          <div className="mr-auto flex flex-row items-center">
            <span className="mr-3">Main Plan Submission</span>
            <Tag className="px-3 font-bold rounded-xl bg-pink-700/25 text-pink-700 border-none">
              {
                submissionByIdData ?
                  `${moment(submissionByIdData.date_from).format('MMM YYYY')} - ${moment(submissionByIdData.date_to).format('MMM YYYY')}`
                  : ''
              }
            </Tag>
          </div>
        </div>

        <div
          className="mt-5 overflow-x-auto"
          style={{ maxWidth: calculatePixel(20) }}
        >
          <TableRaw
            scrollWidth="max-content"
            isLoading={submissionByIdDataIsFetching}
            columns={
              generateSubmissionColumns(
                onViewEvidence
              )
            }
            dataSource={
              generateSubmissionData(
                submissionByIdData
              )
            }
            customRow={(record, index) => generateSubmissionRowStyle(record, index)}
            summary={() => (
              <Table.Summary.Row className="font-bold bg-pink-700/25 text-pink-700">
                <Table.Summary.Cell index={0} className="rounded-tl-lg rounded-bl-lg">Total Score</Table.Summary.Cell>
                <Table.Summary.Cell index={1} />
                <Table.Summary.Cell index={2} />
                <Table.Summary.Cell index={3} />
                <Table.Summary.Cell index={4} />
                <Table.Summary.Cell index={5} />
                <Table.Summary.Cell index={6}>
                  <span className="mx-2">
                    {submissionByIdData ? submissionByIdData.total_score : 0}
                  </span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7} />
                <Table.Summary.Cell index={8} />
                <Table.Summary.Cell index={9} className='rounded-tr-lg rounded-br-lg' />
              </Table.Summary.Row>
            )}
          />
        </div>
      </div>
      <div className="mt-5 flex flex-row gap-x-3">
        <ButtonAccent
          title="Back"
          onClick={() => navigate(-1)}
        />
      </div>
      <ModalEvidence
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        evidenceData={evidenceData}
      />
    </>
  );
};

export default MainPlan;