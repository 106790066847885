import { Base } from './Base';

const Assumption = Base.injectEndpoints({
  endpoints: (build) => ({
    getAssumptions: build.query({
      query: (params) => ({
        url: '/assumptions',
        method: 'GET',
        ...params
          ? { params }
          : undefined
      })
    }),
    getAssumptionById: build.query({
      query: ({ id }) => ({
        url: `/assumptions/${id}`,
        method: 'GET'
      })
    }),
    getAssumptionReserveParameterById: build.query({
      query: ({ id }) => ({
        url: `/assumptions/reserve-parameter/${id}`,
        method: 'GET'
      })
    }),
    getLatestAssumptionReserveParameter: build.query({
      query: ({ id }) => ({
        url: `/assumptions/reserve-parameter/${id}/latest`,
        method: 'GET'
      })
    }),
    getAssumptionSummaryCapacityById: build.query({
      query: ({ id }) => ({
        url: `/assumptions/summary-capacities/${id}`,
        method: 'GET'
      })
    }),
    getAssumptionMinePlanGuidanceById: build.query({
      query: ({ id, params }) => ({
        url: `/assumptions/mine-plan-guidance/${id}`,
        method: 'GET',
        ...params
          ? { params }
          : undefined
      })
    }),
    getLogs: build.query({
      query: ({ id }) => ({
        url: `/assumptions/${id}/workflow/logs`,
        method: 'GET'
      })
    }),
    postAssumption: build.mutation({
      query: (body) => ({
        url: '/assumptions',
        method: 'POST',
        body
      })
    }),
    putAssumption: build.mutation({
      query: ({ id, body }) => ({
        url: `/assumptions/${id}`,
        method: 'PUT',
        body
      })
    }),
    submitSummary: build.mutation({
      query: ({ id, action, body }) => ({
        url: `/assumptions/${id}/workflow/submit/${action}`,
        method: 'POST',
        body
      })
    }),
    approveOrReviseSummary: build.mutation({
      query: ({ id, type, body }) => ({
        url: `/assumptions/${id}/workflow/${type}`,
        method: 'POST',
        ...body
          ? { body }
          : undefined
      })
    })
  })
});

export const {
  useGetAssumptionsQuery,
  useGetAssumptionByIdQuery,
  useLazyGetAssumptionByIdQuery,
  useLazyGetAssumptionReserveParameterByIdQuery,
  useLazyGetLatestAssumptionReserveParameterQuery,
  useGetAssumptionSummaryCapacityByIdQuery,
  useGetLogsQuery,
  useLazyGetAssumptionMinePlanGuidanceByIdQuery,
  usePostAssumptionMutation,
  usePutAssumptionMutation,
  useSubmitSummaryMutation,
  useApproveOrReviseSummaryMutation
} = Assumption;