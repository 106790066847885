import { generateTimeDepthAnalysisTable } from 'constant/TableDrillingSumarry/TableTimeDepthAnalysis';
import TableDrillingSumarry from '../TableDrillingSummary';

const TimeDepthAnalysis = (props) => {
  const {drillHoleName, drillingDepthAnalysis} = props;
  const columns = generateTimeDepthAnalysisTable();
  columns.unshift(drillHoleName);
  return <TableDrillingSumarry columns={columns} tableData={drillingDepthAnalysis}/>;
};

export default TimeDepthAnalysis;