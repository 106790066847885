import { Image } from 'antd';

const ComingSoon = () => {

  return (
    <div className="p-5 w-full h-screen flex flex-col">
      <div className="mt-20 flex flex-col items-center justify-center">
        <Image
          preview={false}
          src={`${process.env.PUBLIC_URL}/assets/logo-coming-soon.svg`}
        />
        <span className="mt-16 text-[#232323] text-xl text-center font-bold">
          Coming Soon
        </span>
        <span className="mt-3 text-[#232323] text-sm text-center font-thin">
          The content is not available at the moment. Please check back soon for updates.
        </span>
      </div>
    </div>
  );
};

export default ComingSoon;