import { drillHoleName } from 'constant/TableDrillingSummary';
import { generateOmeTable } from 'constant/TableDrillingSummaryActual/TableOme';
import _, { isNumber } from 'lodash';
import moment from 'moment';
import TableDrillingSumarry from 'pages/DrillingPlan/DrillingSummary/TableDrillingSummary';

const Ome = (props) => {

  const {
    isDetailMode,
    omeData,
    onChange,
    onDeleteRow,
    availabilityData,
    qualityData,
    performanceData,
    otherData,
    plansData
  } = props;
  const columns = generateOmeTable(
    omeData,
    isDetailMode,
    onChange,
    availabilityData,
    qualityData,
    performanceData,
    onDeleteRow
  );

  const populateDrillHoleName = () => {
    let finalData = omeData.map((data, index) => {
      const drillHoleNameActual = otherData.drill_hole_information[index].drill_hole_name;
      const dataPlan = plansData.find(v => v.drill_hole_name === drillHoleNameActual);
      const caseDrillData = otherData.drill_hole_information[index].case_drill;
      const dateStartDrillActual = otherData.drilling_date[index].date_start_drill_actual ? 
        new Date(
          moment(otherData.drilling_date[index].date_start_drill_actual, 'DD/MM/YYYY')
            .format('YYYY-MM-DD')
        )
        : '';
      const dateStartDrillPlan = dataPlan?.date_start_drill_plan ? 
        new Date(moment(dataPlan?.date_start_drill_plan, 'DD/MM/YYYY').format('YYYY-MM-DD'))
        : '';;
      const actualDrillingDepth = otherData['drilling_depth'][index].actual_drilling_depth;
      const actualTimeData = otherData['time_&_depth_analysis'][index].actual_time;
      const startLateTime = otherData['time_&_depth_analysis'][index].start_late_time;
      const pendingTime = otherData['time_&_depth_analysis'][index].pending_time;
      const depthDeviation = otherData.drilling_depth[index].depth_deviation;
      const valuePercentA = calculatePercentA(dateStartDrillActual, startLateTime, dataPlan?.plan_time);
      const valuePercentP = calculatePercentP(actualTimeData, dataPlan?.plan_time, pendingTime);
      const valuePercentQ = calculatePercentQ(dataPlan?.plan_drilling_depth, depthDeviation);

      return {
        ...data,
        drill_hole_name: drillHoleNameActual,
        categories_a_loss: calculateCategoryALoss(
          dataPlan?.area,
          caseDrillData,
          dateStartDrillActual,
          dateStartDrillPlan
        ),
        categories_p_loss: calculateCategoryPLoss(
          dataPlan?.area,
          caseDrillData,
          actualTimeData,
          dataPlan?.plan_time
        ),
        categories_q_loss: calculateCategoryQLoss(
          dataPlan?.area,
          caseDrillData,
          dataPlan?.plan_drilling_depth,
          actualDrillingDepth
        ),
        a: valuePercentA,
        p: valuePercentP,
        q: valuePercentQ,
        ome: calculatePercentOme(
          valuePercentA,
          valuePercentP,
          valuePercentQ
        )
      };
    });
    return finalData;
  };
  columns.splice(1, 0, drillHoleName);

  const calculateCategoryALoss = (area, case_drill, date_start_drill_actual, date_start_drill_plan) => {
    let aLossData = '';
    if (area) {
      if (['Re-dril', 'As plan'].includes(case_drill)) {
        if (date_start_drill_actual && date_start_drill_actual > date_start_drill_plan) {
          aLossData = 'Availability Loss';
        }
      }

      if (case_drill && case_drill === 'Pending') {
        aLossData = 'Availability Loss';
      }
    }

    return aLossData;
  };

  const calculateCategoryPLoss = (area, case_drill, actual_time, plan_time) => {
    let pLossData = '';
    if (area) {
      if (['Re-dril', 'As plan'].includes(case_drill)) {
        if (actual_time >= 0 && actual_time > plan_time) {
          pLossData = 'Performance Loss';
        }
      }
    }

    return pLossData;
  };

  const calculateCategoryQLoss = (area, case_drill, plan_drilling_depth, actual_drilling_depth) => {
    let qLossData = '';
    if (area) {
      if (['Pending', 'Additional', 'Cancel'].includes(case_drill)) {
        qLossData = 'Quality Loss';
      } else if (actual_drilling_depth >= 0 && actual_drilling_depth > plan_drilling_depth) {
        qLossData = 'Quality Loss';
      }
    }

    return qLossData;
  };

  const calculatePercentA = (date_start_drill_actual, start_late_time, plan_time) => {
    let percentA = '';
    if (date_start_drill_actual > 0) {
      if (start_late_time >= 0 && start_late_time > plan_time) {
        percentA = 0;
      } else {
        percentA = parseFloat((plan_time - start_late_time) / plan_time) * 100;
      }
    }

    return percentA;
  };

  const calculatePercentP = (actual_time, plan_time, pending_time) => {
    let percentP = '';

    if (actual_time && actual_time > 0) {
      if (actual_time <= (plan_time - pending_time)) {
        percentP = 100;
      } else if (actual_time >= (plan_time * 2)) {
        percentP = 0;
      } else {
        const value = (((plan_time - pending_time) - (actual_time - (plan_time - pending_time)))/(plan_time - pending_time ));
        percentP = parseFloat(value * 100);
      }
    }

    return percentP;
  };

  const calculatePercentQ = (plan_drilling_depth, depth_deviation) => {
    let percentQ = '';
    if (plan_drilling_depth) {
      percentQ = parseFloat(((plan_drilling_depth - depth_deviation)/plan_drilling_depth)) * 100;
    }

    return percentQ;
  };

  const calculatePercentOme = (a, p, q) => {
    let percentOme = '';
    let deduction = 0;

    const tempA = isNumber(a) ? a : 0;
    const tempP = isNumber(p) ? p : 0;
    const tempQ = isNumber(q) ? q : 0;

    percentOme = parseFloat(((tempA + tempP + tempQ)/3));

    if (percentOme > 100 && percentOme < 200) {
      const tempOme = _.cloneDeep(percentOme);
      deduction = tempOme - 100;
  
      percentOme = parseFloat(tempOme - deduction);
    }

    return percentOme;
  };

  return <TableDrillingSumarry 
    columns={ columns }
    tableData={ populateDrillHoleName() }
  />;
};

export default Ome;