import moment from 'moment';
import {
  useEffect,
  useRef,
  useState
} from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import {
  Alert,
  Col,
  Form,
  Row
} from 'antd';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';
import { URL } from 'constant';

import { useGetMasterDataPitsQuery, useGetMasterDataPeriodsQuery } from 'api/MasterData';
import {
  useLazyGetAssumptionByIdQuery,
  usePostAssumptionMutation,
  usePutAssumptionMutation
} from 'api/Assumption';
import { useGetCompaniesQuery, useGetContractorsQuery } from 'api/User';
import { getPathType, getFullPath } from 'utils/PathUtility';
import { transformError } from 'utils/ErrorTransformer';
import { useAssumptionManager } from 'utils/AssumptionManager';

import SelectAccent from 'components/SelectAccent';
import SelectSearchAccent from 'components/SelectSearchAccent';
import SelectAddAccent from 'components/SelectAddAccent';
import SelectDateRange from 'components/SelectDateRange';
import ButtonAccent from 'components/ButtonAccent';
import LoadingText from 'components/LoadingText';

const AssumptionForm = () => {
  const loc = useLocation();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const [isValid, setIsValid] = useState(false);
  const [category, setCategory] = useState(null);

  const isDateChanged = useRef(false);

  const [form] = Form.useForm();
  const values = Form.useWatch([], form);

  const { isPathHasBackQueryParams, isPathMaxBackQueryParams } = useAssumptionManager();

  const assumptionId = new URLSearchParams(loc.search).get('id');
  const backQueries = new URLSearchParams(loc.search).getAll('isBack');
  const isBack = isPathHasBackQueryParams(loc.pathname, backQueries);
  const isDetailMode = getPathType(loc) === 'detail';
  const isEditMode = getPathType(loc) === 'edit';
  
  const {
    data: periods,
    isFetching: periodIsFetching,
    isError: periodIsError,
    error: periodError
  } = useGetMasterDataPeriodsQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );

  const {
    data: pits,
    isFetching: pitIsFetching,
    isError: pitIsError,
    error: pitError
  } = useGetMasterDataPitsQuery(
    { companyCode: user?.company?.alias_name },
    { refetchOnMountOrArgChange: true }
  );

  const {
    data: companies,
    isFetching: companyIsFetching,
    isError: companyIsError,
    error: companyError
  } = useGetCompaniesQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );

  const {
    data: contractors,
    isFetching: contractorIsFetching,
    isError: contractorIsError,
    error: contractorError
  } = useGetContractorsQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );

  const [getAssumptionById, {
    data: assumptionData,
    isFetching: assumptionDataIsFetching,
    isError: assumptionDataIsError,
    error: assumptionDataError
  }] = useLazyGetAssumptionByIdQuery();

  const [postAssumption, {
    data: assumption,
    isLoading: assumptionIsLoading,
    isError: assumptionIsError,
    error: assumptionError
  }] = usePostAssumptionMutation();

  const [putAssumption, {
    isLoading: putAssumptionIsLoading,
    isSuccess: putAssumptionIsSuccess,
    isError: putAssumptionIsError,
    error: putAssumptionError
  }] = usePutAssumptionMutation();
  
  const goToPath = (type) => {
    if (type === 'back') {
      navigate(`/${URL.ACTIVITY_LEVEL}/${URL.MINE_PLANNING_OE}/assumption`);

      return;
    }

    navigate({
      pathname: getFullPath({
        parent: 'assumption',
        child: 'production-loss-time',
        type: getPathType(loc)
      }),
      search: createSearchParams({
        id: assumptionId,
        ...isPathMaxBackQueryParams(loc.pathname, backQueries)
          ? undefined
          : { isBack: backQueries }
      }).toString()
    });
  };

  const parseValue = (key) => JSON.parse(values[key]);

  const onSubmit = () => {
    const body = {
      category_id: parseValue('category').id,
      start_date: moment(values.date[0]).format('YYYY-MM-DD'),
      end_date: moment(values.date[1]).format('YYYY-MM-DD'),
      pit: values.pit[0],
      company_id: parseValue('company').id,
      contractor_id: parseValue('contractor').id
    };

    if (assumptionId || isEditMode) {
      putAssumption({ id: assumptionId, body });

      return;
    }

    postAssumption(body);
  };

  useEffect(() => {
    if (periods && pits && companies && contractors) {
      if (isDetailMode || isEditMode || assumptionId) {
        getAssumptionById({ id: assumptionId });
      }
    }
  }, [
    periods,
    pits,
    companies,
    contractors
  ]);

  useEffect(() => {
    if (assumptionData) {
      isDateChanged.current = true;
      const selectedPeriod = periods.data.find((period) => period.id === assumptionData.category.id);
      const selectedPit = pits.find((pit) => pit === assumptionData.pit.name);
      const selectedCompany = companies.find((company) => company.id === assumptionData.company.id);
      const selectedContractor = contractors.find((contractor) => contractor.id === assumptionData.contractor.id);

      form.setFieldValue('category', JSON.stringify(selectedPeriod));
      form.setFieldValue('pit', [selectedPit] || [assumptionData.pit.name]);
      form.setFieldValue('company', JSON.stringify(selectedCompany));
      form.setFieldValue('contractor', JSON.stringify(selectedContractor));
    }
  }, [assumptionData]);

  useEffect(() => {
    if (periodIsError
      || pitIsError
      || companyIsError
      || contractorError
      || assumptionDataIsError
      || putAssumptionIsError
    ) {
      const generateToastId = () => {
        switch (true) {
          case periodIsError:
            return 'period';
          case pitIsError:
            return 'pit';
          case companyIsError:
            return 'company';
          case contractorIsError:
            return 'contractor';
          case assumptionDataIsError:
            return 'assumption-by-id';
          case putAssumptionIsError:
            return 'put-assumption';
          default:
            return 'default';
        }
      };

      toast.error(
        transformError(
          periodError
          || pitError
          || companyError
          || contractorError
          || assumptionDataError
          || putAssumptionError
        ).message,
        { toastId: `${generateToastId()}-toast-error` }
      );
    }
  }, [
    periodIsError,
    pitIsError,
    companyIsError,
    contractorIsError,
    assumptionDataIsError,
    putAssumptionIsError,
    periodError,
    pitError,
    companyError,
    contractorError,
    assumptionDataError,
    putAssumptionError
  ]);

  useEffect(() => {
    setCategory((prevState) => {
      if (values && values.category) {
        const categoryVal = JSON.parse(values.category);

        if (prevState) {
          return categoryVal.id !== prevState.id
            ? categoryVal
            : prevState;
        }

        return categoryVal;
      }
        
      return undefined;
    });

    form.validateFields({ validateOnly: true })
      .then(
        // onFulfilled
        () => {
          setIsValid(true);
        },

        // onRejected
        () => {
          setIsValid(false);
        }
      );
  }, [values]);

  useEffect(() => {
    if (isDateChanged.current) {
      form.setFieldValue('date', [assumptionData.start_date, assumptionData.end_date]);

      isDateChanged.current = false;
      return;
    }

    form.setFieldValue('date', undefined);
  }, [category]);

  useEffect(() => {
    if (putAssumptionIsSuccess || assumption) {
      navigate({
        pathname: getFullPath({
          parent: 'assumption',
          child: 'production-loss-time',
          type: getPathType(loc)
        }),
        search: createSearchParams({
          id: Boolean(isBack || isEditMode)
            ? assumptionId
            : assumption.id,
          ...isPathMaxBackQueryParams(loc.pathname, backQueries)
            ? undefined
            : { isBack: backQueries }
        }).toString()
      });
    }
  }, [
    putAssumptionIsSuccess,
    assumption
  ]);
  
  return (
    <>
      <div className="p-5 rounded-lg bg-white shadow-md">
        <div className="flex flex-row items-center">
          <span className="mr-auto font-bold text-lg">Form Create Assumption</span>
        </div>
        {
          assumptionIsError && (
            <Alert
              type="error"
              message={`[${transformError(assumptionError).code}] ${transformError(assumptionError).message}`}
              className="mt-5"
            />
          )
        }
        <Form
          form={form}
          labelCol={{ span: 24 }}
          name="validateOnly"
          layout="vertical"
          className="mt-5"
        >
          <Row gutter={[32, 0]}>
            <Col span={12}>
              <Form.Item
                name="category"
                label="Category"
                rules={[{ required: true }]}
              >
                <SelectAccent
                  isStringify
                  isLoading={periodIsFetching}
                  isDisabled={periodIsError || isDetailMode}
                  isError={periodIsError}
                  labelKey="name"
                  valueKey="alias_name"
                  value={values?.category}
                  options={periods?.data}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="date"
                label="Date"
                rules={[{ required: true }]}
              >
                <SelectDateRange
                  isDisabled={isEmpty(category) || isDetailMode}
                  mode={category?.unit}
                  name={category?.name}
                  minRange={category?.from}
                  maxRange={category?.to}
                  value={values?.date}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="pit"
                label="PIT"
                rules={[{ required: true }]}
              >
                <SelectAddAccent
                  isSingle
                  isLoading={pitIsFetching}
                  isDisabled={pitIsError || isDetailMode}
                  isError={pitIsError}
                  options={pits}
                  value={values?.pit}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="company"
                label="Company Owner"
                rules={[{ required: true }]}
              >
                <SelectSearchAccent
                  isStringify
                  isLoading={companyIsFetching}
                  isDisabled={companyIsError || isDetailMode}
                  isError={companyIsError}
                  labelKey="name"
                  valueKey="id"
                  options={companies}
                  value={values?.company}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="contractor"
                label="Contractor"
                rules={[{ required: true }]}
              >
                <SelectSearchAccent
                  isStringify
                  isLoading={contractorIsFetching}
                  isDisabled={contractorIsError || isDetailMode}
                  isError={contractorIsError}
                  labelKey="name"
                  valueKey="id"
                  options={contractors}
                  value={values?.contractor}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <div className="mt-5 w-full flex flex-row items-center">
        {
          (isDetailMode || isEditMode || assumptionId)
            ? (
              <>
                {
                  assumptionDataIsFetching && (<LoadingText />)
                }
              </>
            )
            : (<></>)
        }
        <div className="ml-auto flex flex-row gap-x-3">
          <ButtonAccent
            isBordered
            isLoading={assumptionIsLoading || putAssumptionIsLoading}
            title="Cancel"
            onClick={() => goToPath('back')}
          />
          <ButtonAccent
            title="Next"
            isDisabled={!isValid}
            isLoading={assumptionIsLoading || putAssumptionIsLoading}
            onClick={() => isDetailMode
              ? goToPath('next')
              : onSubmit()}
          />
        </div>
      </div>
    </>
  );
};

export default AssumptionForm;