import {
  useEffect,
  useRef,
  useState
} from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isEmpty, toLower } from 'lodash';

import { useGetAssumptionByIdQuery } from 'api/Assumption';
import { useLazyGetMasterDataUnitsQuery } from 'api/MasterData';
import {
  usePostUnitMutation,
  usePutUnitMutation,
  useLazyGetUnitByIdQuery,
  useLazyGetLatestUnitQuery
} from 'api/Unit';
import { EMPTY, UNIT, URL } from 'constant';
import { TYPE } from 'constant/MasterData';
import { generateTableColumns, generateBody } from 'constant/TableCoalUnit';
import { getPathType, getFullPath, shouldRedirect } from 'utils/PathUtility';
import { convertPeriodToTags } from 'utils/PeriodUtility';
import { transformError } from 'utils/ErrorTransformer';
import { useAssumptionManager } from 'utils/AssumptionManager';

import BannerPitPeriod from 'components/BannerPitPeriod';
import TableRaw from 'components/TableRaw';
import ButtonAccent from 'components/ButtonAccent';
import SelectMultipleAccent from 'components/SelectMultipleAccent';
import LoadingText from 'components/LoadingText';
import { useModalConfirmationContext } from 'components/ModalConfirmationProvider';

const CoalUnit = () => {
  const loc = useLocation();
  const navigate = useNavigate();

  const [isValid, setIsValid] = useState(false);
  const [masterUnits, setMasterUnits] = useState([]);
  const [tempUnits, setTempUnits] = useState([]);
  const [units, setUnits] = useState([]);
  const [tableData, setTableData] = useState([]);

  const isAddedRef = useRef(false);
  const isDraftRef = useRef(false);
  const isDetailInitialization = useRef(false);
  const currentTypeRef = useRef(EMPTY.STRING);
  const unitIdRef = useRef(null);
  const periodRef = useRef([]);
  const unitRef = useRef(UNIT.MONTH);

  const { showModal, resetModal } = useModalConfirmationContext();
  const {
    createBackQueryParams,
    isPathHasBackQueryParams,
    isPathMaxBackQueryParams
  } = useAssumptionManager();

  const assumptionId = new URLSearchParams(loc.search).get('id');
  const backQueries = new URLSearchParams(loc.search).getAll('isBack');
  const isBack = isPathHasBackQueryParams(loc.pathname, backQueries);
  const isCreateMode = getPathType(loc) === 'create';
  const isDetailMode = getPathType(loc) === 'detail';
  const isEditMode = getPathType(loc) === 'edit';

  const {
    data: assumption,
    isFetching: assumptionIsFetching,
    isError: assumptionIsError,
    error: assumptionError
  } = useGetAssumptionByIdQuery(
    { id: assumptionId },
    { refetchOnMountOrArgChange: true }
  );

  const [getMasterDataUnit, {
    data: masterDataUnit,
    isFetching: masterDataUnitIsFetching,
    isError: masterDataUnitIsError,
    error: masterDataUnitError
  }] = useLazyGetMasterDataUnitsQuery();

  const [getLatestUnit, {
    data: latestUnitData,
    isFetching: latestUnitIsFetching,
    isError: latestUnitIsError,
    error: latestUnitError
  }] = useLazyGetLatestUnitQuery();

  const [getUnitById, {
    data: unitByIdData,
    isFetching: unitByIdDataIsFetching,
    isError: unitByIdDataIsError,
    error: unitByIdDataError
  }] = useLazyGetUnitByIdQuery();

  const [postUnit, {
    isLoading: unitIsLoading,
    isSuccess: unitIsSuccess,
    isError: unitIsError,
    error: unitError
  }] = usePostUnitMutation();

  const [putUnit, {
    isLoading: putUnitIsLoading,
    isSuccess: putUnitIsSuccess,
    isError: putUnitIsError,
    error: putUnitError
  }] = usePutUnitMutation();

  const filteredUnits = () => {
    if (masterDataUnit && masterDataUnit?.data.length) {
      if (units.length) {
        return masterDataUnit?.data.filter((item) =>
          !units.some((unit) => {
            const parsedUnit = JSON.parse(unit);
            return item.id === parsedUnit.id;
          }));
      }

      return masterDataUnit?.data;
    }

    return [];
  };

  const addUnits = () => {
    isAddedRef.current = true;

    setUnits((prevState) => {
      if (prevState && prevState.length) {
        return [
          ...prevState,
          ...tempUnits
        ];
      }

      return tempUnits;
    });

    setTempUnits([]);
  };

  const showDeleteModal = (record) => {
    showModal({
      isShown: true,
      type: 'delete',
      message: 'Are you sure you want to delete the coal unit?',
      onSubmit: () => deleteUnit(record)
    });
  };

  const deleteUnit = (record) => {
    const unit = tableData.find((data) => data.id === record.id);

    if (unit) {
      isAddedRef.current = false;
      setUnits((prevState) => prevState.filter((item) => JSON.parse(item).id !== unit.id));
    }

    resetModal();
  };

  const redirectPathToCreate = () => navigate({
    pathname: getFullPath({
      parent: 'assumption',
      child: 'select-coal-unit',
      type: 'create'
    }),
    search: createSearchParams({ id: assumptionId }).toString()
  });

  const goToPath = (type) => navigate({
    pathname: getFullPath({
      parent: 'assumption',
      child: type === 'back'
        ? 'over-burden-hours-capacity'
        : 'coal-hours-capacity',
      type: getPathType(loc)
    }),
    search: createSearchParams({
      id: assumptionId,
      ...type === 'back'
        ? {
          isBack: isBack
            ? backQueries
            : createBackQueryParams(loc.pathname)
        }
        : {
          ...isPathMaxBackQueryParams(loc.pathname, backQueries)
            ? undefined
            : { isBack: backQueries }
        }
    }).toString()
  });

  const postUnits = (isDraft) => {
    isDraftRef.current = isDraft;

    const body = generateBody(
      isDraft,
      assumptionId,
      tableData
    );

    if (isBack || isEditMode) {
      const { assumption_id, ...item } = body;
      putUnit({
        id: unitIdRef.current,
        body: { ...item }
      });

      return;
    }

    postUnit(body);
  };

  useEffect(() => {
    if (isEditMode) {
      currentTypeRef.current = getPathType(loc);

      return;
    }

    if (currentTypeRef.current === 'edit' && getPathType(loc) === 'create') {
      window.location.reload();
    }
  }, [loc]);

  useEffect(() => {
    unitRef.current = toLower(assumption?.category?.unit);
    periodRef.current = convertPeriodToTags(
      unitRef.current,
      assumption?.start_date,
      assumption?.end_date
    );

    getMasterDataUnit({
      type: TYPE.COAL,
      contractorCode: assumption?.contractor?.alias_name
    });
  }, [assumption]);

  useEffect(() => {
    getLatestUnit({
      id: assumptionId,
      params: { type: TYPE.COAL }
    });

    if (!isEmpty(masterDataUnit)) {
      setMasterUnits(filteredUnits());

      if (isBack || isDetailMode || isEditMode) {
        getUnitById({
          id: assumptionId,
          params: { type: TYPE.COAL }
        });
      }
    }
  }, [masterDataUnit]);

  useEffect(() => {
    if (isEditMode && shouldRedirect(unitByIdData)) {
      redirectPathToCreate();

      return;
    }

    if (unitByIdData?.contents?.length) {
      isDetailInitialization.current = true;
      unitIdRef.current = unitByIdData?.id;

      const selectedUnits = unitByIdData.contents.map(
        ({ contractor, ...item }) =>
          JSON.stringify({ ...item, parents: [contractor] })
      );

      setTempUnits(selectedUnits);
    }
  }, [unitByIdData]);

  useEffect(() => {
    if (isCreateMode
      && !isBack
      && !isEmpty(latestUnitData?.previous_contents)
    ) {
      isDetailInitialization.current = true;
      const selectedUnits = latestUnitData.previous_contents.map(
        ({ contractor, ...item }) =>
          JSON.stringify({
            ...item,
            ...contractor
              ? { parents: [contractor] }
              : undefined
          })
      );

      setTempUnits(selectedUnits);
    }
  }, [latestUnitData]);

  useEffect(() => {
    if (isDetailInitialization.current) {
      addUnits();
    }
  }, [tempUnits]);

  useEffect(() => {
    if (assumptionIsError
      || masterDataUnitIsError
      || unitByIdDataIsError
      || latestUnitIsError
    ) {
      const generateToastId = () => {
        switch (true) {
          case assumptionIsError:
            return 'assumption';
          case masterDataUnitIsError:
            return 'master-data-unt';
          case unitByIdDataIsError:
            return 'unit-by-id-data';
          case latestUnitIsError:
            return 'latest-unit-data';
          default:
            return 'default';
        }
      };
      
      toast.error(
        transformError(
          assumptionError
          || masterDataUnitError
          || unitByIdDataError
          || latestUnitError
        ).message,
        { toastId: `${generateToastId()}-toast-error` }
      );
    }
  }, [
    assumptionIsError,
    assumptionError,
    masterDataUnitIsError,
    masterDataUnitError,
    unitByIdDataIsError,
    unitByIdDataError,
    latestUnitIsError,
    latestUnitError
  ]);

  useEffect(() => {
    if (isAddedRef.current) {
      isAddedRef.current = false;

      isDetailInitialization.current
        ? (isDetailInitialization.current = false)
        : showModal({
          isShown: true,
          type: 'success',
          message: 'Successfully added a coal unit'
        });
    }

    setMasterUnits(filteredUnits());
    setTableData(() => {
      if (units.length) {
        return units.map((item) => {
          const {
            id,
            alias_name,
            name,
            parents
          } = JSON.parse(item);

          return {
            id,
            alias_name,
            name,
            parents
          };
        });
      }
        
      return [];
    });
  }, [units]);

  useEffect(() => {
    setIsValid(
      Boolean(tableData && tableData.length)
    );
  }, [tableData]);

  useEffect(() => {
    if (unitIsSuccess || putUnitIsSuccess) {
      if (isDraftRef.current) {
        navigate(`/${URL.ACTIVITY_LEVEL}/${URL.MINE_PLANNING_OE}/assumption`);

        return;
      }

      goToPath('next');

      return;
    }

    if (unitIsError || putUnitIsError) {
      toast.error(
        transformError(unitError || putUnitError).message,
        { toastId: 'post-unit-toast-error' }
      );

      return;
    }

    return () => {};
  }, [
    unitIsSuccess,
    unitIsError,
    unitError,
    putUnitIsSuccess,
    putUnitIsError,
    putUnitError
  ]);
  
  return (
    <div className="flex flex-col">
      <BannerPitPeriod
        isLoading={assumptionIsFetching}
        companyName={assumption?.company?.alias_name}
        contractorName={assumption?.contractor?.alias_name}
        pitName={assumption?.pit?.name}
        periodMode={assumption?.category?.name}
        periods={assumption
          ? periodRef.current
          : []}
      />

      <div className="my-5 p-5 bg-white flex flex-row items-center gap-x-3 rounded-lg">
        <SelectMultipleAccent
          isStringify
          isLoading={masterDataUnitIsFetching}
          isDisabled={masterDataUnitIsError || isDetailMode}
          isError={masterDataUnitIsError}
          labelKey="alias_name"
          placeholder="Select Coal Unit"
          options={masterUnits}
          value={tempUnits}
          onChange={(val) => setTempUnits(val)}
        />

        <ButtonAccent
          isDisabled={isDetailMode}
          title="Add"
          onClick={addUnits}
        />
      </div>

      <div className="p-5 bg-white flex flex-col rounded-lg">
        <div className="mb-5 flex flex-row items-center">
          <span className="font-bold text-lg">
            Coal List Unit
          </span>
          {
            (isBack || isDetailMode || isEditMode)
              ? (
                <div className="ml-auto">
                  {
                    (unitByIdDataIsFetching || latestUnitIsFetching) && (<LoadingText />)
                  }
                </div>
              )
              : (<></>)
          }
        </div>

        <TableRaw
          columns={generateTableColumns(isDetailMode, showDeleteModal)}
          dataSource={tableData}
        />
      </div>
      <div className="mt-5 w-full flex flex-row items-center">
        <ButtonAccent
          isDisabled={isDetailMode}
          isLoading={unitIsLoading || putUnitIsLoading}
          size="lg"
          title="Save As Draft"
          onClick={() => postUnits(true)}
        />

        <div className="ml-auto flex flex-row items-center gap-x-3">
          <ButtonAccent
            isBordered
            title="Back"
            onClick={() => goToPath('back')}
          />
          <ButtonAccent
            isLoading={unitIsLoading || putUnitIsLoading}
            isDisabled={!isValid}
            title="Next"
            onClick={() => isDetailMode
              ? goToPath('next')
              : postUnits(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default CoalUnit;