import { DatePicker, Divider, Select } from 'antd';
import { useGetPlansQuery } from 'api/DrillingPlan';
import { useGetMasterDataGoeCompaniesQuery } from 'api/MasterData';
import ButtonAccent from 'components/ButtonAccent';
import FilterContainer from 'components/FilterContainer';
import SelectSearchAccent from 'components/SelectSearchAccent';
import TableData from 'components/TableData';
import { ACTION, EMPTY } from 'constant';
import {
  generateDrillingPlanColumns,
  generateDrillingPlanData,
} from 'constant/TableDrillingPlan';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { transformError } from 'utils/ErrorTransformer';
import { getFullPath } from 'utils/PathUtility';
const DrillingPlan = () => {
  const initialFilter = { 
    year: EMPTY.STRING,
    company: EMPTY.STRING,
    planningType: EMPTY.STRING
  };
  const [filter, setFilter] = useState(initialFilter);
  const [version, setVersion] = useState(null);
  const navigate = useNavigate();
  const {
    page,
    pageSize,
    sortBy,
    orderBy,
    search
  } = useSelector((state) => state.table);

  const {
    data: plansData,
    isFetching: plansIsFetching,
    isError: plansIsError,
    error: plansError
  } = useGetPlansQuery(
    {
      page,
      limit: pageSize,
      sort_by: sortBy,
      order_by: orderBy,
      year:filter.year? moment(new Date(filter.year)).format('YYYY'):undefined,
      company: filter.company,
      planning_type: filter.planningType,
      ...search
        ? { search }
        : undefined
    },
    { refetchOnMountOrArgChange: true }
  );

  const onVersionChange = (key, value) => {
    setVersion((prevState) => ({
      ...prevState,
      [key]: { label: `v${value}`, value },
    }));
  };

  const resetFilter = () => {
    setFilter(initialFilter);
  };
  const onFilter = (key, value) => {
    setFilter((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const generateSubmissionRowStyle = (record, index) => {
    if (!record.planning_type) {
      return 'font-bold bg-[#2D3D5A] text-white';
    }

    return index % 2 === 0 ? 'bg-white' : 'bg-gray-100';
  };

  const calculatePixel = (remVal) => {
    return `${window.innerWidth - remVal * 16}px`;
  };

  const { 
    data: dataCompanies, 
    isFetching: companiesIsFetching, 
    isError: companyIsError,
    error: companyError 
  } = useGetMasterDataGoeCompaniesQuery({ refetchOnMountOrArgChange: true });

  const goToPath = (id, type, child, version)=> navigate({
    pathname: getFullPath({
      parent: 'drilling-plan',
      child: child,
      type
    },'geology-oe'),
    ...(id && version)
      ? { search: createSearchParams({ id, version }).toString() }
      : undefined
  });


  const handleContinuePlanning = (key) => {
    let tempData = generateDrillingPlanData(plansData);
    let choosedData = tempData.find(data => data.key === key);
    const planData = plansData.data.find(plan=> {
      return plan.id === key;
    });
    let totalLength = planData.properties[0]?.versions?.length;
    const choosedDocument = planData.properties.map((property) => {
      let validationStatus = property.versions?.find(utils => utils.status === 'On Progress' || utils.status === 'Draft');
      if(property.versions.length < totalLength || validationStatus) {
        return property; 
      }
      return {};
    });
    if(!isEmpty(choosedDocument)){
      let resultChoosedDocument = choosedDocument.filter(item => !isEmpty(item))[0];
      const documentVersion = resultChoosedDocument.versions[0].value;
      const path = ['quality-checklist', 'permit-timeline', 'drilling-summary'];
      const statuses = choosedData?.status;
      for (let i = 0; i < path.length; i++) {
        if(statuses[i]?.includes('DRAFT')) {
          goToPath(key, ACTION.EDIT, path[i], documentVersion);
          return;
        }
        if (statuses[i]?.includes('ON PROGRESS') || statuses[i]?.includes('REVISED')) {
          const getStatus = choosedData.children[i].utils[0].value;
          goToPath(key, ACTION.CREATE, path[i], getStatus);
          return;
        }
        if(resultChoosedDocument.versions.length < totalLength) {
          goToPath(key, ACTION.CREATE, resultChoosedDocument?.planning_name?.toLowerCase()?.replace(' ', '-'), documentVersion + 1 );
          return;
        }
      }
    }
  };

  const handleActionButton = (id, pName, version) => {
    let path = pName.toLowerCase().replace(/ /g, '-');
    goToPath(id, ACTION.DETAIL, path, version);
  };

  const handleEditPlanning = (id, version) => {
    goToPath(id, ACTION.CREATE, 'quality-checklist', version + 1);
  };

  useEffect(() => {
    if (( plansIsError && plansError ) || ( companyIsError && companyError )) {
      toast.error(
        transformError(plansError || companyError).message,
        { toastId: 'drilling-plan-list-error' }
      );
    }

  }, [plansIsError, companyIsError]);

  return (
    <div className="mt-5 bg-white p-6 rounded-2xl">
      <FilterContainer onReset={resetFilter}>
        <label>
          <span className="font-bold">Year</span>
          <DatePicker.YearPicker
            allowClear
            className="mt-2 w-full h-12"
            value={filter['year']}
            onChange={(val) => onFilter('year', val)}
          />
        </label>
        <label>
          <span className="font-bold">Mine Site</span>
          <SelectSearchAccent
            onChange={(val) => onFilter('company', val)}
            value={filter['company']||null}
            labelKey="alias_name"
            valueKey="alias_name"
            options={dataCompanies}
            placeholder="Select mine site"
            isLoading={companiesIsFetching}
            className={'h-12 mt-2'}
          />
        </label>
        <label>
          <span className="font-bold">Drilling Stage</span>
          <Select
            allowClear
            placeholder="Select drilling stage"
            value={filter['planningType'] || null}
            options={[
              { label: 'Pre-production', value: 'PREPRODUCTION' },
              { label: 'Exploration', value: 'EXPLORATION' },
            ]}
            className="mt-2 w-full h-12"
            onChange={(val) => onFilter('planningType', val)}
          />
        </label>
      </FilterContainer>
      <Divider />
      <div
        className="mt-5 overflow-x-auto"
        style={{ maxWidth: calculatePixel(23) }}
      >
        <TableData
          isLoading={plansIsFetching}
          notSearchable={true}
          scrollWidth="max-content"
          scrollHeight={'50vh'}
          columns={generateDrillingPlanColumns(
            version, 
            onVersionChange, 
            handleContinuePlanning, 
            handleActionButton,
            handleEditPlanning
          )}
          dataSource={generateDrillingPlanData(plansData)}
          totalData={plansData?.total_data}
          rowKey='id'
          headerBg='#B1BACB20'
          headerColor='black'
          expandableColumn={{
            expandable: {
              defaultExpandAllRows: true,
              expandRowByClick:true,
              expandIcon: ({ expanded, _, record }) =>
                !record?.planning_type ? (
                  expanded ? (
                    <IoIosArrowUp
                      className="right-10 absolute cursor-pointer"
                      size={24}
                    />
                  ) : (
                    <IoIosArrowDown
                      className="right-10 absolute cursor-pointer"
                      size={24}
                    />
                  )
                ) : null,
            },
          }}
          customRow={(record, index) =>
            generateSubmissionRowStyle(record, index)
          }
        >
          <ButtonAccent
            className={'h-12 w-44'}
            title="Create Planning"
            onClick={() => goToPath(null, ACTION.CREATE, 'create-planning')}
          />
        </TableData>
      </div>
    </div>
  );
};

export default DrillingPlan;
