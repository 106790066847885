import {
  useEffect,
  useState,
  useReducer
} from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Input, Switch } from 'antd';
import { toast } from 'react-toastify';
import {
  every,
  isEmpty,
  isNull,
  toLower,
  toUpper
} from 'lodash';

import { useGetPermissionsQuery } from 'api/Permission';
import {
  useLazyGetGroupByIdQuery,
  usePostGroupMutation,
  usePutGroupMutation
} from 'api/Group';
import { EMPTY, PERMISSION, URL } from 'constant';
import { permissionColumns } from 'constant/TableGroupManagement';
import { getPathType } from 'utils/PathUtility';
import { transformError } from 'utils/ErrorTransformer';

import LoadingText from 'components/LoadingText';
import TableRaw from 'components/TableRaw';
import ButtonAccent from 'components/ButtonAccent';

const Form = () => {
  const loc = useLocation();
  const navigate = useNavigate();

  const initialState = {
    name: EMPTY.STRING,
    status: false,
    permissions: []
  };
  
  const groupId = new URLSearchParams(loc.search).get('id');
  const isDetailMode = getPathType(loc) === 'detail';
  const isEditMode = getPathType(loc) === 'edit';
  
  const [isValid, setIsValid] = useState(false);

  const reducer = (state, action) => {
    const { type, payload } = action;
    
    switch (type) {
      case 'name_change':
        return {
          ...state,
          name: payload
        };
      case 'status_change':
        return {
          ...state,
          status: payload
        };
      case 'permission_initialization': {
        let tempPermissions = [];

        payload.permissions.forEach(({ id, menu, name }) => {
          if (!isEmpty(tempPermissions)) {
            const isMenuExist = tempPermissions.some((permission) => permission.id === menu.id);
            const isPermissionNotExist = tempPermissions.some(
              (permission) => permission.permissions.some(
                (permissionItem) => toLower(name) !== PERMISSION[toUpper(permissionItem.name)]
              )
            );

            if (!isMenuExist) {
              tempPermissions.push({
                id: menu.id,
                name: menu.name,
                permissions: [{
                  id,
                  name,
                  menu_id: menu.id
                }]
              });

              return;
            }

            if (isMenuExist && isPermissionNotExist) {
              tempPermissions = tempPermissions.map((permission) => {
                if (permission.id === menu.id) {
                  return {
                    ...permission,
                    permissions: [...permission.permissions, { id, name, menu_id: menu.id }]
                  };
                }

                return { ...permission };
              });
            }

            return;
          }

          tempPermissions.push({
            id: menu.id,
            name: menu.name,
            permissions: [{
              id,
              name,
              menu_id: menu.id
            }]
          });
        });

        return {
          ...state,
          permissions: tempPermissions
        };
      }
      case 'permission_change': {
        const { permission, isChecked } = payload;

        if (isEmpty(state.permissions)) {
          return {
            ...state,
            permissions: [permission]
          };
        }

        if (isChecked) {
          const isCanAddNewPermission = state.permissions.some(({ id, name, permissions }) => {
            const isMenuExist = permission.id === id;
            const isPermissionNotExist = permissions.some(
              (permissionItem) => permission.permissions.some(
                (payloadPermission) => toLower(payloadPermission.name) !== PERMISSION[toUpper(permissionItem.name)]
              )
            );

            return Boolean(isMenuExist && isPermissionNotExist);
          });

          if (isCanAddNewPermission) {
            const newPermissions = state.permissions.map((item) => {
              if (item.id === permission.id) {
                return {
                  ...item,
                  permissions: [
                    ...item.permissions,
                    ...permission.permissions
                  ]
                };
              }

              return { ...item };
            });

            return {
              ...state,
              permissions: newPermissions
            };
          }

          /**
           * if we make new array with ex:
           * const newPermissions = state.permissions
           * newPermissions will have additional item out of nowhere, SMH~
           * */
          const newPermissions = state.permissions.map((item) => item);
          newPermissions.push(permission);

          return {
            ...state,
            permissions: newPermissions
          };
        }

        const filteredPermissions = state.permissions.map(
          (statePermission) => {
            if (statePermission.id === permission.id) {
              return {
                ...statePermission,
                permissions: statePermission.permissions.filter(
                  ({ id }) => permission.permissions.some(
                    (permissionItem) => permissionItem.id !== id
                  )
                )
              };
            }

            return { ...statePermission };
          }
        );

        return {
          ...state,
          permissions: filteredPermissions
        };
      }
      default:
        return { ...state };
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const goBack = () => navigate(`/${URL.ACTIVITY_LEVEL}/${URL.MINE_PLANNING_OE}/group-management`);;

  const {
    data: permissions,
    isFetching: permissionIsFetching,
    isError: permissionIsError,
    error: permissionError
  } = useGetPermissionsQuery();

  const [getGroupById, {
    data: groupByIdData,
    isFetching: groupByIdDataIsFetching,
    isError: groupByIdDataIsError,
    error: groupByIdDataError
  }] = useLazyGetGroupByIdQuery();

  const [postGroup, {
    isLoading: postGroupIsLoading,
    isSuccess: postGroupIsSuccess,
    isError: postGroupIsError,
    error: postGroupError
  }] = usePostGroupMutation();

  const [putGroup, {
    isLoading: putGroupIsLoading,
    isSuccess: putGroupIsSuccess,
    isError: putGroupIsError,
    error: putGroupError
  }] = usePutGroupMutation();

  const submitGroup = () => {
    const { name, status, permissions } = state;
    const body = {
      name,
      is_active: status,
      permission_ids: [].concat(...permissions.map((item) => item.permissions.map(({ id }) => id)))
    };

    if (isEditMode) {
      putGroup({ id: groupId, body });

      return;
    }

    postGroup(body);
  };
  
  useEffect(() => {
    if (isDetailMode || isEditMode) {
      getGroupById({ id: groupId });
    }
  }, []);
  
  useEffect(() => {
    const isNameValid = Boolean(state.name);
    const isPermissionsValid = !isEmpty(state.permissions)
      && every(
        state.permissions.map(
          (item) => Boolean(!isEmpty(item) && !isEmpty(item.permissions))
        )
      );

    setIsValid(Boolean(isNameValid && isPermissionsValid));
  }, [state.name, state.permissions]);

  useEffect(() => {
    if (!isNull(groupByIdData)
      && !Boolean(groupByIdData?.error)
      && !isEmpty(groupByIdData)
    ) {
      dispatch({
        type: 'name_change',
        payload: groupByIdData.name
      });

      dispatch({
        type: 'status_change',
        payload: groupByIdData.is_active
      });

      dispatch({
        type: 'permission_initialization',
        payload: groupByIdData
      });
    }
  }, [groupByIdData]);

  useEffect(() => {
    if (permissionIsError
      || postGroupIsError
      || putGroupIsError
      || groupByIdDataIsError
    ) {
      const generateToastId = () => {
        switch (true) {
          case permissionIsError:
            return 'permission-error';
          case postGroupIsError:
            return 'post-group-error';
          case putGroupIsError:
            return 'put-group-error';
          case groupByIdDataIsError:
            return 'group-by-id-error';
          default:
            return 'default';
        }
      };
      
      toast.error(
        transformError(permissionError
          || postGroupError
          || putGroupError
          || groupByIdDataError
        ).message,
        { toastId: `${generateToastId()}-toast` }
      );
    }
  }, [
    permissionIsError,
    permissionError,
    groupByIdDataIsError,
    groupByIdDataError,
    postGroupIsError,
    postGroupError,
    putGroupIsError,
    putGroupError
  ]);

  useEffect(() => {
    if (postGroupIsSuccess || putGroupIsSuccess) {
      const generateToast = () => {
        switch (true) {
          case postGroupIsSuccess:
            return {
              id: 'post-group-success',
              message: 'Group created successfully'
            };
          case putGroupIsSuccess:
            return {
              id: 'put-group-success',
              message: 'Group edited successfully'
            };
          default:
            return {
              id: 'default',
              message: 'Unknown'
            };
        }
      };
      
      toast.success(
        generateToast().message,
        { toastId: `${generateToast().id}-toast` }
      );

      goBack();
    }
  }, [postGroupIsSuccess, putGroupIsSuccess]);

  return (
    <>
      <div className="p-5 bg-white rounded-lg">
        <span className="font-bold text-lg">Group Info</span>
        <div className="mt-5 w-full flex flex-row">
          <div className="pr-2 w-1/2 flex flex-col gap-y-3">
            <span className="font-bold">
              Group Name
            </span>
            <Input
              disabled={isDetailMode}
              placeholder="Enter Group Name"
              value={state.name}
              onChange={(ev) => dispatch({ type: 'name_change', payload: ev.target.value })}
            />
          </div>
          <div className="pl-2 w-1/2 flex flex-col gap-y-3">
            <span className="font-bold">
              Is Active
            </span>
            <Switch
              disabled={isDetailMode}
              checked={state.status}
              className="w-12"
              onChange={(checked) => dispatch({ type: 'status_change', payload: checked })}
            />
          </div>
        </div>
      </div>
      <div className="mt-5 p-5 bg-white rounded-lg">
        <div className="mb-5 flex flex-row items-center">
          <span className="mr-auto font-bold text-lg">Access Permission</span>
          {
            (permissionIsFetching || groupByIdDataIsFetching) && (<LoadingText />)
          }
        </div>
        <TableRaw
          columns={
            permissionColumns(
              isDetailMode,
              state.permissions,
              (permission, isChecked) => dispatch({
                type: 'permission_change',
                payload: { permission, isChecked }
              })
            )}
          dataSource={permissions}
        />
      </div>
      <div className="mt-5 flex flex-row">
        <div className="ml-auto flex flex-row items-center gap-x-3">
          <ButtonAccent
            isBordered
            isDisabled={postGroupIsLoading || putGroupIsLoading}
            title={isDetailMode ? 'Back' : 'Cancel'}
            onClick={goBack}
          />
          {
            !isDetailMode && (
              <ButtonAccent
                isDisabled={!isValid}
                isLoading={postGroupIsLoading || putGroupIsLoading}
                title={isEditMode ? 'Edit' : 'Submit'}
                onClick={submitGroup}
              />
            )
          }
        </div>
      </div>
    </>
  );
};

export default Form;