import { capitalize, isEmpty } from 'lodash';

const generateDrillingHoleInformationTable = () => [
  {
    dataIndex: 'drill_hole_name',
    title: 'Drill Hole Name',
    width: '196px',
    sorter: false,
    align: 'center',
    fixed: 'left',
    render: (value) => {
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>{value}</div>
      );
    },
  },
  {
    dataIndex: 'area',
    title: 'Area',
    width: '196px',
    sorter: false,
    align: 'center',
    fixed: 'left',
    render: (value) => {
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>{capitalize(value)}</div>
      );
    },
  },
  {
    dataIndex: 'pit_location',
    title: 'Pit Location',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>{value}</div>
      );
    },
  },
  {
    dataIndex: 'pit_year',
    title: 'Pit Year',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>{value}</div>
      );
    },
  },
  {
    dataIndex: 'contractor_name',
    title: 'Contractor Name',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>{value}</div>
      );
    },
  }
];

const generateDataDrillingHoleInformation = (file) => {
  if(!isEmpty(file)) {
    const data = file.properties[0].drill_hole_information;
    let finalResult = data[0].drill_hole_name.map((_, index) => {
      return data.reduce((result, currentObject) => {
        const key = Object.keys(currentObject)[0];
        const value = currentObject[key][index].value;
        result[key] = value;
        return result;
      }, {});
    });
    return finalResult;
  }
};

export { generateDrillingHoleInformationTable, generateDataDrillingHoleInformation };
