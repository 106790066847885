import { FiTrash } from 'react-icons/fi';

import { EMPTY } from 'constant';
import { TYPE } from 'constant/MasterData';

const generateTableColumns = (isDetailMode, onDelete) => [
  {
    dataIndex: 'name',
    title: 'Equipment Name',
    render: (text, record, index) => (
      <span>Coal Excavator {index + 1}</span>
    )
  },
  {
    dataIndex: 'alias_name',
    title: 'Equipment Type',
    render: (text) => {
      const splitAliasName = text.split('-');

      return (
        <span>
          {
            splitAliasName.length
              ? splitAliasName[0]
              : EMPTY.STRING
          }
        </span>
      );
    }
  },
  {
    dataIndex: 'alias_name',
    title: 'Equipment No',
    render: (text) => {
      const splitAliasName = text.split('-');

      return (
        <span>
          {
            splitAliasName.length
              ? splitAliasName[1]
              : EMPTY.STRING
          }
        </span>
      );
    }
  },
  {
    dataIndex: 'parents',
    title: 'Contractor',
    render: (text, record) => (
      <>
        {
          (record.parents && record.parents.length)
            && record.parents.map((item, index) => (
              <span key={`key-parent-contractor-${index + 1}`}>
                {item.alias_name}
              </span>
            ))
        }
      </>
    )
  },
  {
    dataIndex: 'action',
    title: 'Action',
    render: (text, record) => (
      <>
        {
          isDetailMode
            ? (<></>)
            : (
              <div className="flex flex-row gap-x-3">
                <div
                  className="rounded-full bg-pink-700 cursor-pointer"
                  onClick={() => onDelete(record)}
                >
                  <FiTrash
                    size="24px"
                    className="p-1 text-white"
                  />
                </div>
              </div>
            )
        }
      </>
    )
  }
];

const generateBody = (isDraft, id, units) => {
  return {
    assumption_id: id,
    type: TYPE.COAL,
    is_draft: isDraft,
    contents: units.map(({ parents, company, ...item }) => ({ ...item }))
  };
};

export { generateTableColumns, generateBody };