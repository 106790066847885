import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { persistentStore, store } from 'store';

import App from './App';
import ImagePreview from './components/ImagePreviewProvider';
import ModalConfirmation from './components/ModalConfirmationProvider';
import ModalComment from './components/ModalCommentProvider';
import SideCarApprovalHistory from './components/SideCarApprovalHistoryProvider';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate
          loading={null}
          persistor={persistentStore}
        >
          <ImagePreview>
            <ModalConfirmation>
              <ModalComment>
                <SideCarApprovalHistory>
                  <ConfigProvider 
                    theme={{ 
                      token: { fontFamily: 'Segoe-UI' } 
                    }}
                  >
                    <App />
                  </ConfigProvider>
                </SideCarApprovalHistory>
              </ModalComment>
            </ModalConfirmation>
          </ImagePreview>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorkerRegistration.unregister();
