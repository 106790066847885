import { drillHoleName } from 'constant/TableDrillingSummary';
import { 
  generateDrillingHoleLocationTable
} from 'constant/TableDrillingSummaryActual/TableDrillingHoleLocation';
import TableDrillingSumarry from 'pages/DrillingPlan/DrillingSummary/TableDrillingSummary';

const DrillingHoleLocation = (props) => {
  const { isDetailMode, drillingHoleLocationData, onChange, onDeleteRow, otherData } = props;
  const columns = generateDrillingHoleLocationTable(
    drillingHoleLocationData,
    isDetailMode,
    onChange,
    onDeleteRow
  );

  const populateDrillHoleName = () => {
    let finalData = drillingHoleLocationData.map((data, index) => {
      return {
        ...data,
        drill_hole_name: otherData.drill_hole_information[index].drill_hole_name,
      };
    });
    return finalData;
  };
  columns.splice(1, 0, drillHoleName);

  return <TableDrillingSumarry 
    columns={ columns }
    tableData={ populateDrillHoleName() }
  />;
};

export default DrillingHoleLocation;