import { Base } from './Base';

const PitReserve = Base.injectEndpoints({
  endpoints: (build) => ({
    getPitReserveById: build.query({
      query: ({ id }) => ({
        url: `/pit-reserves/${id}`,
        method: 'GET'
      })
    }),
    postPitReserve: build.mutation({
      query: (body) => ({
        url: '/pit-reserves',
        method: 'POST',
        body
      })
    }),
    putPitReserve: build.mutation({
      query: ({ id, body }) => ({
        url: `/pit-reserves/${id}`,
        method: 'PUT',
        body
      })
    })
  })
});

export const {
  usePostPitReserveMutation,
  useLazyGetPitReserveByIdQuery,
  usePutPitReserveMutation
} = PitReserve;