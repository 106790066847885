import { Form, Image, Input, Button , ConfigProvider, Alert, Divider } from 'antd';
import { isEmpty } from 'lodash';

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';

import { useLoginMutation } from 'api/Auth';
import { KEY, URL } from 'constant';

const Login = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [credential, setCredential] = useState({
    username: '',
    password: ''
  });
  const [errorMsg, setErrorMsg] = useState('');

  const [login, {
    error,
    isSuccess,
    isError
  }] = useLoginMutation();

  const onLogin = async () => {
    setIsLoading(true);
    login(credential);
  };

  useEffect(() => {
    if (isError) {
      setIsLoading(false);

      const { error: errorData } = error;
      setErrorMsg(errorData || error);
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(async () => {
        setIsLoading(false);
        /**
         * we don't store `lastRoute` in redux-persist
         * to avoid being purged when user logout because of unauthorized
         * */
        const lastRoute = localStorage.getItem(KEY.LAST_ROUTE);

        if (isEmpty(lastRoute)) {
          navigate(`/${URL.ACTIVITY_LEVEL}`);

          return;
        }

        localStorage.removeItem(KEY.LAST_ROUTE);
        navigate(lastRoute);
      }, 1000);
    }
  }, [isSuccess]);

  return (
    <div className='h-screen px-[8vw] py-[5vh] bg-gradient-to-tr from-[#2BB8A4] to-[#2D3D5A]'>
      <div className='login-container'>
        <div className='grid grid-cols-2 gap-3 h-full pt-5'>
          <div className='lg:col-span-1 max-lg:col-span-2 mx-[6vw]'>
            <div className='flex flex-col justify-center items-center'>
              <Image
                preview={false}
                width={100}
                src='assets/logo-oe.svg'
              />
              <div className='font-[Segoe-UI-Bold] items-center text-center text-[18px] md:text-[26px] w-[250px] md:w-[315px] text-[#2D3D5A]'><p>Operational Excellence Data Platform</p></div>
            </div>
            <Divider className='m-2 md:m-8'/>
            <div className='text-left'>
              <p className='font-semibold text-[18px] font-[Segoe-UI-Bold]'>Get Started</p>
              <p className='text-[14px]'>Managing and Planning Data Entry Faster</p>
            </div>
            {
              errorMsg &&
              <Alert
                className='mt-2 bg-[#EB5545] text-white'
                message={errorMsg.message}
                type='error'
              />
            }
            <Form name="form-login" className='pt-4' onFinish={onLogin}>
              <Form.Item
                name="username"
                style={{ marginBottom: '15px' }}
                rules={[{ required: true, message: 'username is required' }]}
              >
                <div>
                  <p className='font-bold text-[14px] font-[Segoe-UI-Bold]'>Username</p>
                  <Input
                    placeholder={'Enter username'}
                    value={credential.username}
                    onChange={(e) => setCredential({...credential, username: e.target.value})}
                    disabled={isLoading}
                  /> 
                </div>
              </Form.Item>
              <Form.Item
                name="password"
                style={{ marginBottom: '15px' }}
                rules={[{ required: true, message: 'password is required' }]}
              >
                <div>
                  <p className='font-bold text-[14px] font-[Segoe-UI-Bold]'>Password</p>
                  <Input.Password
                    placeholder='Enter Password'
                    value={credential.password}
                    onChange={(e) => setCredential({...credential, password: e.target.value})}
                    disabled={isLoading}
                  />
                </div>
              </Form.Item>
              <div className={`flex flex-col ${errorMsg?.message? 'mt-4 md:mt-16':'mt-16'}`}>
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: 'white',
                    },
                  }}
                >
                  <Button
                    className='bg-[#2D3D5A] text-white font-[Segoe-UI-Bold]'
                    type='text'
                    htmlType='submit'
                    loading={isLoading}
                  >
                    Login
                  </Button>
                </ConfigProvider>
                <span className="mt-2 text-xs text-primary self-end">{`v. ${process.env.REACT_APP_VERSION}`}</span>
              </div>
            </Form>
          </div>
          <div className='lg:col-span-1 max-lg:col-span-2 max-lg:hidden w-full h-full pr-4 pb-2'>
            <div className='login-bg-img w-full h-full rounded-[36px]' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;