import 'react-toastify/dist/ReactToastify.css';
import 'react-quill/dist/quill.snow.css';
import './App.css';
import { ToastContainer } from 'react-toastify';
import { useRoutes } from 'react-router';

import { createRoutes } from 'routes';

const App = () => {
  return (
    <>
      <ToastContainer
        closeOnClick
        draggable
        pauseOnHover
        newestOnTop
        hideProgressBar={false}
        rtl={false}
        position="top-right"
        theme="colored"
        autoClose={5000}
      />
      {useRoutes(createRoutes())}
    </>
  );
};

export default App;
