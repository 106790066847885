import { Select } from 'antd';
import { toLower } from 'lodash';

import { EMPTY } from 'constant';

const SelectSearchAccent = (props) => {
  const {
    isDisabled,
    isLoading,
    isStringify,
    isError,
    placeholder = 'Select here...',
    labelKey,
    valueKey,
    options,
    value,
    onChange,
    className
  } = props;
  
  const transformOptions = options && options.length
    ? options.map((item) => {
      if (isStringify) {
        return {
          label: labelKey
            ? item[labelKey]
            : item,
          value: JSON.stringify(item)
        };
      }
      
      return {
        label: labelKey
          ? item[labelKey]
          : item,
        value: valueKey
          ? item[valueKey]
          : item
      };
    })
    : [];
  
  return (
    <Select
      allowClear
      showSearch
      disabled={isDisabled || isLoading}
      status={isError ? 'error' : EMPTY.STRING}
      placeholder={placeholder}
      options={transformOptions}
      value={value}
      onChange={onChange}
      filterOption={(input, option) =>
        toLower(option?.label ?? EMPTY.STRING).includes(toLower(input))
      }
      className={`w-full ${className} ${isLoading && 'animate-pulse animate-infinite'}`}
    />
  );
};

export default SelectSearchAccent;