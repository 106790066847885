import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { isEmpty, isNumber } from 'lodash';
import moment from 'moment';
import { RxCross2 } from 'react-icons/rx';
import { actualTimeFormula, startLateTimeFormula } from './TableTimeDepthAnalysis';
import { EMPTY } from 'constant';
import { calculateCategoryALoss, calculateCategoryPLoss, calculatePercentA, calculatePercentOme, calculatePercentP } from './TableOme';

const generateDrillingDateActualTable = (
  payload, 
  isDetailMode, 
  onChange, 
  onDeleteRow, 
  otherData, 
  plansData
) => [
  {
    dataIndex: 'action',
    title: 'Action',
    width: 5,
    sorter: false,
    align: 'center',
    fixed: 'left',
    render: (_, __, index) => {
      return (
        <div className="flex justify-center">
          {payload.length <= 1 ? (
            <></>
          ) : (
            <RxCross2
              size={28}
              color={`${isDetailMode? 'gray' : 'red'}`}
              className={`rounded-full p-1 ${isDetailMode? 'bg-gray-200':'cursor-pointer bg-red-200 '}`}
              onClick={() => onDeleteRow(index)}
            />
          )}
        </div>
      );
    },
  },
  {
    dataIndex: 'date_start_drill_actual',
    title: 'Date Start Drill (actual)',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, record, index) => {
      const populateTimeDepthAnalysis = () => {
        onChange(index, 'time_&_depth_analysis', 'actual_time', actualTimeFormula(index, otherData));
        onChange(index, 'time_&_depth_analysis', 'start_late_time', 
          startLateTimeFormula(index, plansData, otherData, record)
        );
        onChange(index, 'drilling_date', 'exceed_time', exceedTimeFormula(index, otherData, plansData, record));
        onChange(index, 'drilling_date', 'actual_rate', actualRateFormula(index, otherData));
        onChange(index, 'drilling_date', 'drilling_rate_by_final_log', drillingRateByFinalLogFormula(index, otherData));
        onChange(index, 'drilling_date', 'exceed_time', exceedTimeFormula(index, otherData, plansData, record));
        onChange(index, 'drilling_date', 'speed', speedFormula(index, otherData, plansData, record));
      };
      const populateOme = () => {
        onChange(index, 'ome', 'categories_a_loss', 
          calculateCategoryALoss(index, plansData, otherData)
        );
        onChange(index, 'ome', 'categories_p_loss', 
          calculateCategoryPLoss(index, plansData, otherData)
        );
        onChange(index, 'ome', 'a', 
          calculatePercentA(index, plansData, otherData)
        );
        onChange(index, 'ome', 'p', 
          calculatePercentP(index, plansData, otherData)
        );
        onChange(index, 'ome', 'ome', 
          calculatePercentOme(index, otherData)
        );
      };
      return (
        <DatePicker
          allowClear
          value={value? dayjs(value,'DD/MM/YYYY'):''}
          format={'DD/MM/YYYY'}
          disabled={isDetailMode}
          placeholder="dd/mm/yyyy"
          className="w-full text-left"
          onChange={(val) => {
            onChange(index, 'drilling_date', 'date_start_drill_actual' ,val? dayjs(val).format('DD/MM/YYYY'): null);
            populateTimeDepthAnalysis();
            populateOme();
          }}
          status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
        />
      );
    },
  },
  {
    dataIndex: 'date_final_log_actual',
    title: 'Date Final Log (actual)',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, record, index) => {
      return (
        <DatePicker
          allowClear
          value={value? dayjs(value,'DD/MM/YYYY'):''}
          format={'DD/MM/YYYY'}
          disabled={isDetailMode}
          placeholder="dd/mm/yyyy"
          className="w-full text-left"
          onChange={(val) => {
            onChange(index, 'drilling_date', 'date_final_log_actual',val? dayjs(val).format('DD/MM/YYYY') : null);
            onChange(index, 'time_&_depth_analysis', 'actual_time', actualTimeFormula(index, otherData));
            onChange(index, 'drilling_date', 'exceed_time', exceedTimeFormula(index, otherData, plansData, record));
            onChange(index, 'drilling_date', 'actual_rate', actualRateFormula(index, otherData));
            onChange(index, 'drilling_date', 'drilling_rate_by_final_log', drillingRateByFinalLogFormula(index, otherData));
            onChange(index, 'drilling_date', 'speed', speedFormula(index, otherData, plansData, record));
            onChange(index, 'drilling_date', 'check_date_final_log_wellcad', calculateCheckWellCad(index, otherData));
            onChange(index, 'ome', 'category_p_loss', calculateCategoryPLoss(index, plansData, otherData));
            onChange(index, 'ome', 'p', calculatePercentP(index, plansData, otherData));
          }}
          status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
        />
      );
    },
  },
  {
    dataIndex: 'date_geophysical_logging_date_date_vertical_logging',
    title: 'Geophysic & Vertical Logging Date',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, _, index) => {
      return (
        <DatePicker
          allowClear
          value={value? dayjs(value,'DD/MM/YYYY'):''}
          format={'DD/MM/YYYY'}
          disabled={isDetailMode}
          placeholder="dd/mm/yyyy"
          className="w-full text-left"
          onChange={(val) => {
            onChange(index, 'drilling_date', 'date_geophysical_logging_date_date_vertical_logging', val? dayjs(val).format('DD/MM/YYYY'): null);
          }}
          status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
        />
      );
    },
  },
  {
    dataIndex: 'check_date_final_log_wellcad',
    title: 'Check Date Final Log & Well CAD',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className='flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {value}
        </div>
      );
    },
  },
  {
    dataIndex: 'drilling_rate_by_final_log',
    title: 'Drilling Rate by Final Log',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className='flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {Number(value).toFixed(2)}
        </div>
      );
    },
  },
  {
    dataIndex: 'speed',
    title: '% Drilling Speed',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      const populateValue= ()=>{
        if(isNumber(value)){
          if(Number(value) === value && value % 1 !== 0){
            return `${value.toFixed(0)}%`;
          }else {
            return `${value}%`;
          }
        }
        return value;
      };
      return (
        <div className='flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {populateValue()}
        </div>
      );
    },
  },
  {
    dataIndex: 'actual_rate',
    title: 'Actual Rate',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className='flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {value?  Number(value).toFixed(2): ''}
        </div>
      );
    },
  },
  {
    dataIndex: 'exceed_time',
    title: 'Exceed Time',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className='flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {Number(value).toFixed(2)}
        </div>
      );
    },
  },
];

const exceedTimeFormula = (index, otherData, plansData, record) => {
  let result ='';
  let actualTime = otherData['time_&_depth_analysis'][index].actual_time;
  let choosedPlan = plansData?.find(plan => plan.drill_hole_name === record.drill_hole_name);
  let planTime = choosedPlan?.plan_time; 
  if(actualTime <= planTime) {
    result = 0;
  } else {
    result = Number(actualTime - planTime);
  }
  return result;
};

const actualRateFormula = (index, otherData) => {
  const dateFinalLog = moment(otherData.drilling_date[index].date_final_log_actual,'DD/MM/YYYY');
  const dateStartDrill = moment(otherData.drilling_date[index].date_start_drill_actual, 'DD/MM/YYYY');
  let ActualDrillingDepth = otherData.drilling_depth[index].actual_drilling_depth;
  let result = '';
  if(!dateFinalLog.isSame(dateStartDrill)){
    result = Number(ActualDrillingDepth / (dateFinalLog.diff(dateStartDrill, 'days')));
  }
  return result;
};

const drillingRateByFinalLogFormula = (index, otherData) => {
  const dateFinalLog = moment(otherData.drilling_date[index].date_final_log_actual,'DD/MM/YYYY');
  const dateStartDrill = moment(otherData.drilling_date[index].date_start_drill_actual, 'DD/MM/YYYY');
  let result = '';
  let ActualDrillingDepth = otherData.drilling_depth[index].actual_drilling_depth;
  if( dateFinalLog.isSame(dateStartDrill) ) {
    result = Number(ActualDrillingDepth / 1);
  } else {
    result = Number(ActualDrillingDepth/ (dateFinalLog.diff(dateStartDrill, 'days')));
  }
  return result;
};

const speedFormula = (index, otherData, plansData, record) => {
  let finalResult = '';
  const dateFinalLog = moment(otherData.drilling_date[index].date_final_log_actual,'DD/MM/YYYY');
  const dateStartDrill = moment(otherData.drilling_date[index].date_start_drill_actual, 'DD/MM/YYYY');
  let choosedPlan = plansData?.find(plan => plan.drill_hole_name === record.drill_hole_name);
  const dateFinalLogPlan = moment(choosedPlan?.date_final_log_plan, 'DD/MM/YYYY');
  const dateStartPlan = moment(choosedPlan?.date_start_drill_plan, 'DD/MM/YYYY');
  const temp1 = Number(1 + (dateFinalLog.diff(dateStartDrill,'days') ));
  const temp2 = Number(1 + (dateFinalLogPlan.diff(dateStartPlan, 'days') ));
  let ActualDrillingDepth = otherData.drilling_depth[index].actual_drilling_depth;
  let planDrillingDepth = choosedPlan?.plan_drilling_depth;
  if(dateStartDrill) {
    let actualDepth = Number(ActualDrillingDepth/temp1);
    let planDepth = Number(planDrillingDepth/temp2);
    finalResult = Number((actualDepth / planDepth * 100));
  }
  return finalResult;
};

const calculateCheckWellCad = (index, otherData) => {
  const dateFinalLog = moment(otherData.drilling_date[index].date_final_log_actual,'DD/MM/YYYY');
  const wellCad = moment(otherData.drilling_evidence[index].wellcad?.date,'DD/MM/YYYY');
  let result;
  if(new Date(dateFinalLog) <= new Date(wellCad)) {
    result = 'Ok';
  } else {
    result = 'Check';
  }
  return result;
};

const generateDrillingDateActualData = (file) => {
  if(!isEmpty(file)) {
    const dataDrillHoleName = file.properties[0].drill_hole_information[0].drill_hole_name;
    const data = file.properties[2].drilling_date;
    let finalResult = dataDrillHoleName.map((drillHoleName, index) => {
      return data.reduce((result, currentObject, indexObject) => {
        const key = Object.keys(currentObject)[0];
        const value = currentObject[key][index]?.value;
        result[key] = value;
        if(indexObject === data?.length -1){
          result['drill_hole_name'] = drillHoleName?.value;
        }
        return result;
      }, {});
    });
    return finalResult;
  }
};
export { 
  generateDrillingDateActualTable, 
  generateDrillingDateActualData, 
  actualRateFormula, 
  drillingRateByFinalLogFormula,
  speedFormula,
  calculateCheckWellCad
};