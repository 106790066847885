import dayJS from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useEffect, useState } from 'react';
import { DatePicker } from 'antd';
import { isEmpty, toLower } from 'lodash';

import { EMPTY } from 'constant';

const { RangePicker } = DatePicker;

dayJS.extend(isSameOrAfter);
dayJS.extend(isSameOrBefore);

const SelectDateRange = (props) => {
  const {
    isDisabled,
    isLoading,
    isError,
    mode = 'days',
    name,
    minRange,
    maxRange,
    value,
    onChange
  } = props;

  const [dates, setDates] = useState(undefined);
  
  const isMonthly = () => {
    if (name) {
      return Boolean(name && toLower(name) === 'monthly');
    }
    
    return false;
  };
  
  const isYearly = () => {
    if (name) {
      return Boolean(name && toLower(name) === 'yearly');
    }

    return false;
  };

  const transformDateValue = () => {
    if (value && value.length) {
      return setDates([dayJS(value[0]), dayJS(value[1])]);
    }

    return setDates(null);
  };

  const disabledDate = (current) => {
    if (!dates || !dates.length) {
      return false;
    }

    if (mode === 'days') {
      return dates[0]
        && ((current.diff(dates[0], 'days') < (minRange - 1)) || (current.diff(dates[0], 'days') >= maxRange));
    }

    const minMonth = dayJS(dates[0]).add((minRange - 1), 'month');
    const maxMonth = dayJS(dates[0]).add((maxRange - 1), 'month');

    return !(current.isSameOrAfter(minMonth, 'month') && current.isSameOrBefore(maxMonth, 'month'));
  };

  /**
  * @param value is `dayjs` (built-in from: `antd`)
  * for more references, visit https://day.js.org/docs/en/installation/installation
  **/
  const onDateChange = (value) => {
    if (!isEmpty(value)) {
      let startDate = null;
      let endDate = null;

      if (mode === 'days') {
        startDate = isMonthly()
          ? value.startOf('month').format('YYYY-MM-DDT00:00:00.000Z')
          : value[0].format('YYYY-MM-DDT00:00:00.000Z');
        endDate = isMonthly()
          ? value.endOf('month').format('YYYY-MM-DDT23:59:59.000Z')
          : value[1].format('YYYY-MM-DDT23:59:59.000Z');

        onChange([startDate, endDate]);
        return;
      }
      
      startDate = isYearly()
        ? value.startOf('year').format('YYYY-MM-DDT00:00:00.000Z')
        : value[0].startOf('month').format('YYYY-MM-DDT00:00:00.000Z');
      endDate = isYearly()
        ? value.endOf('year').format('YYYY-MM-DDT23:59:59.000Z')
        : value[1].endOf('month').format('YYYY-MM-DDT23:59:59.000Z');

      onChange([startDate, endDate]);
      return;
    }

    onChange(undefined);
  };
  
  const onCalendarChange = (value) => setDates(value);

  const onCalendarOpenStateChange = (isOpen) => {
    if (!isOpen && dates && dates.length) {
      if (!dates[1]) {
        setDates(null);
      }
    }
  };

  useEffect(() => {
    transformDateValue();
  }, [value]);

  return (
    <>
      {
        (isMonthly() || isYearly())
          ? (
            <DatePicker
              allowClear
              picker={isYearly() ? 'year' : 'month'}
              disabled={isDisabled || isLoading}
              status={isError ? 'error' : EMPTY.STRING}
              value={
                Boolean(dates && dates.length)
                  ? dates[0]
                  : undefined
              }
              onChange={onDateChange}
              className={`w-full ${isLoading && 'animate-pulse animate-infinite'}`}
            />
          )
          : (
            <RangePicker
              allowClear
              changeOnBlur
              picker={mode === 'days' ? 'date' : 'month'}
              disabled={isDisabled || isLoading}
              disabledDate={disabledDate}
              status={isError ? 'error' : EMPTY.STRING}
              value={dates}
              onCalendarChange={onCalendarChange}
              onOpenChange={onCalendarOpenStateChange}
              onChange={onDateChange}
              className={`w-full ${isLoading && 'animate-pulse animate-infinite'}`}
            />
          )
      }
    </>
  );
};

export default SelectDateRange;