
import { useModalConfirmationContext } from 'components/ModalConfirmationProvider';
import { generateSampleInformationCoreRecoveryData, generateSampleInformationCoreRecoveryTable } from 'constant/TableCoreRecovery/TableSampleInformation';
import TableDrillingSumarry from 'pages/DrillingPlan/DrillingSummary/TableDrillingSummary';

const SampleInformationCoreRecovery = (props) => {
  const { isDetailMode, payload, drillHoleNameProperties, onChange, onDeleteRow, onAddSampleId, deleteSampleId } = props;
  const {showModal, resetModal} = useModalConfirmationContext();
  const tableData = generateSampleInformationCoreRecoveryData(payload);
  const columns = generateSampleInformationCoreRecoveryTable(
    isDetailMode,
    tableData, 
    drillHoleNameProperties, 
    onChange, 
    onDeleteRow,
    onAddSampleId,
    deleteSampleId,
    showModal,
    resetModal
  );
  return <TableDrillingSumarry columns={columns} tableData={tableData}/>;
};

export default SampleInformationCoreRecovery;