import moment from 'moment';
import dayJS from 'dayjs';
import {
  useEffect,
  useRef,
  useState
} from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { FaHistory } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import {
  Divider,
  Image,
  Table,
  Tag
} from 'antd';
import { toast } from 'react-toastify';
import {
  every,
  isEmpty,
  sumBy,
  toUpper,
  toLower
} from 'lodash';

import { useSelector } from 'react-redux';
import {
  useGetAssumptionByIdQuery,
  useGetAssumptionSummaryCapacityByIdQuery,
  useGetLogsQuery,
  useLazyGetAssumptionReserveParameterByIdQuery,
  useLazyGetLatestAssumptionReserveParameterQuery,
  useLazyGetAssumptionMinePlanGuidanceByIdQuery,
  useSubmitSummaryMutation,
  useApproveOrReviseSummaryMutation
} from 'api/Assumption';
import { 
  useLazyGetPitReserveByIdQuery
} from 'api/PitReserve';
import {
  ACTION,
  EMPTY,
  PERMISSION,
  UNIT,
  URL
} from 'constant';
import { TYPE } from 'constant/MasterData';
import {
  reserveDefaultData,
  overBurdenColumns,
  coalColumns,
  generateReserveColumns,
  generateReserveData,
  generateCapacityData,
  generateMinePlanGuidanceColumns,
  generateMinePlanGuidanceData,
  generateMinePLanProposeColumns,
  generateMinePlanProposeData,
  generateBody
} from 'constant/TableSummary';
import { getFullPath, getPathType } from 'utils/PathUtility';
import { convertPeriodToTags } from 'utils/PeriodUtility';
import { transformError } from 'utils/ErrorTransformer';
import { usePermissionManager } from 'utils/PermissionManager';
import { useAssumptionManager } from 'utils/AssumptionManager';
import { useTableWrapper } from 'utils/TableResponsive';

import BannerPitPeriod from 'components/BannerPitPeriod';
import InputFileAccent from 'components/InputFileAccent';
import TableRaw from 'components/TableRaw';
import ButtonAccent from 'components/ButtonAccent';
import { useModalConfirmationContext } from 'components/ModalConfirmationProvider';
import { useModalCommentContext } from 'components/ModalCommentProvider';
import { useSideCarContext } from 'components/SideCarApprovalHistoryProvider';

const Summary = () => {
  const loc = useLocation();
  const navigate = useNavigate();
  const { summary } = useSelector((state) => state.assumption);

  const [isModalShown, setIsModalShown] = useState(false);
  const [reserveData, setReserveData] = useState([]);
  const [capacityData, setCapacityData] = useState([]);
  const [pitReserveData, setPitReserveData] = useState([]);
  const [obCapacityData, setObCapacityData] = useState([]);
  const [coalCapacityData, setCoalCapacityData] = useState([]);
  const [minePlanData, setMinePlanData] = useState([]);
  const [tableTabs, setTableTabs] = useState([]);
  const [activeTableTab, setActiveTableTab] = useState(EMPTY.STRING);
  const [isValid, setIsValid] = useState(false);

  const isWeeklyRef = useRef(false);
  const isMonthlyRef = useRef(false);
  const isQuarterlyRef = useRef(false);
  const isYearlyRef = useRef(false);
  const isApproveRef = useRef(false);
  const unitRef = useRef(UNIT.MONTH);
  const periodRef = useRef([]);
  const periodNameRef = useRef(EMPTY.STRING);
  const fileCodeKeyRef = useRef(EMPTY.STRING);
  const fileDescriptionRef = useRef(EMPTY.STRING);
  const fileRef = useRef(null);
  const logsRef = useRef([]);
  const docStatusRef = useRef(EMPTY.STRING);

  const { showModal } = useModalConfirmationContext();
  const { showCommentModal, resetCommentModal } = useModalCommentContext();
  const { showSideCar } = useSideCarContext();
  const { isUserHasPermissionInFeature } = usePermissionManager();
  const {
    createBackQueryParams,
    saveLatestState,
    resetLatestState
  } = useAssumptionManager();
  const { wrapperWidth } = useTableWrapper();
  
  const assumptionId = new URLSearchParams(loc.search).get('id');
  const isCreateMode = getPathType(loc) === 'create';
  const isDetailMode = getPathType(loc) === 'detail';
  const isEditMode = getPathType(loc) === 'edit';

  const {
    data: assumption,
    isFetching: assumptionIsFetching,
    isError: assumptionIsError,
    error: assumptionError
  } = useGetAssumptionByIdQuery(
    { id: assumptionId },
    { refetchOnMountOrArgChange: true }
  );

  const {
    data: capacity,
    isFetching: capacityIsFetching,
    isError: capacityIsError,
    error: capacityError
  } = useGetAssumptionSummaryCapacityByIdQuery(
    { id: assumptionId },
    { refetchOnMountOrArgChange: true }
  );
  
  const { data: logs } = useGetLogsQuery(
    { id: assumptionId },
    { refetchOnMountOrArgChange: true }
  );

  const [getReserveParameter, {
    data: reserveParameter,
    isFetching: reserveParameterIsFetching,
    isError: reserveParameterIsError,
    error: reserveParameterError
  }] = useLazyGetAssumptionReserveParameterByIdQuery();

  const [getLatestReserveParameter, {
    data: latestReserveParameter,
    isFetching: latestReserveParameterIsFetching,
    isError: latestReserveParameterIsError,
    error: latestReserveParameterError
  }] = useLazyGetLatestAssumptionReserveParameterQuery();

  const [getMinePlanGuidance, {
    data: minePlanGuidance,
    isFetching: minePlanGuidanceIsFetching,
    isError: minePlanGuidanceIsError,
    error: minePlanGuidanceError
  }] = useLazyGetAssumptionMinePlanGuidanceByIdQuery();
  
  const [postAssumptionSum, {
    isLoading: assumptionSumIsLoading,
    isSuccess: assumptionSumIsSuccess,
    isError: assumptionSumIsError,
    error: assumptionSumError
  }] = useSubmitSummaryMutation();
  
  const [approveOrRevise, {
    isLoading: approveOrReviseIsLoading,
    isSuccess: approveOrReviseIsSuccess,
    isError: approveOrReviseIsError,
    error: approveOrReviseError
  }] = useApproveOrReviseSummaryMutation();

  const [getPitReserveById, {
    data: getPitData,
    isFetching: getPitDataIsFetching,
    isError: getPitDataIsError,
    error: getPitDataError
  }] = useLazyGetPitReserveByIdQuery();

  const momentPeriod = (unit, item) => {
    return moment(item, toLower(unit) === UNIT.DAY ? 'DD MMM YYYY' : 'MMM YYYY')
      .format(toLower(unit) === UNIT.DAY ? 'DD_MMM_YYYY' : 'MMM_YYYY');
  };

  const generateMinePlanningGuidanceRowStyle = (record, index) => {
    if (toLower(record.description) === 'production achievement'
    || toLower(record.description) === 'mine plan indicator') {
      return 'font-bold bg-pink-700/20 text-pink-700';
    }

    return index % 2 === 0 ? 'bg-white' : 'bg-gray-100';
  };

  const goToPath = (type) => {
    if (type === 'back') {
      saveLatestState('summary', reserveData);

      navigate({
        pathname: getFullPath({
          parent: 'assumption',
          child: 'main-plan-scenario',
          type: getPathType(loc)
        }),
        search: createSearchParams({
          id: assumptionId,
          isBack: createBackQueryParams(loc.pathname)
        }).toString()
      });
      
      return;
    }
    
    navigate(`/${URL.ACTIVITY_LEVEL}/${URL.MINE_PLANNING_OE}/assumption`);
  };
  
  const generatePeriodTagInPropose = () => {
    switch (true) {
      case isWeeklyRef.current: {
        return (
          <Tag className="px-3 font-bold rounded-xl bg-pink-700/25 text-pink-700 border-none">
            {
              periodRef.current.length
                ? `${periodRef.current[0]} - ${periodRef.current[periodRef.current.length - 1]}`
                : EMPTY.STRING
            }
          </Tag>
        );
      }
      case isMonthlyRef.current: {
        return (
          <Tag className="px-3 font-bold rounded-xl bg-pink-700/25 text-pink-700 border-none">
            {
              periodRef.current.length
                ? moment(periodRef.current[0], 'DD MMM YYYY').format('MMM YYYY')
                : EMPTY.STRING
            }
          </Tag>
        );
      }
      case isQuarterlyRef.current: {
        return (
          <Tag className="px-3 font-bold rounded-xl bg-pink-700/25 text-pink-700 border-none">
            {
              periodRef.current.length
                ? `${periodRef.current[0]} - ${periodRef.current[periodRef.current.length - 1]}`
                : EMPTY.STRING
            }
          </Tag>
        );
      }
      case isYearlyRef.current:
      default: {
        return (
          <Tag className="px-3 font-bold rounded-xl bg-pink-700/25 text-pink-700 border-none">
            {
              periodRef.current.length
                ? moment(
                  periodRef.current[0],
                  toLower(unitRef.current) === UNIT.DAY
                    ? 'DD MMM YYYY'
                    : 'MMM YYYY')
                  .format('YYYY')
                : EMPTY.STRING
            }
          </Tag>
        );
      }
    }
    
    
  };

  const changeModalVisibility = () => setIsModalShown((prevState) => !prevState);

  const onNameChange = (codeKey, val) => {
    setReserveData((prevState) =>
      prevState.map((item) => {
        const newItem = { ...item };
        
        if (newItem.codeKey === codeKey) {
          newItem.name = val;
          
          return newItem;
        }
        
        return newItem;
      })
    );
  };

  const onDateChange = (codeKey, val) => {
    setReserveData((prevState) =>
      prevState.map((item) => {
        const newItem = { ...item };
        
        if (newItem.codeKey === codeKey) {
          newItem.update_date = val;
          
          return newItem;
        }
        
        return newItem;
      })
    );
  };

  const onUpload = (codeKey, description, file) => {
    fileCodeKeyRef.current = codeKey;
    fileDescriptionRef.current = description;
    
    if (file) {
      addTemporaryAttachment(file);
    }

    changeModalVisibility();
  };
  
  const onDownload = (codeKey, file) => {
    const docLink = document.createElement('a');
    docLink.href = file.url;

    document.body.appendChild(docLink);
    docLink.click();
    document.body.removeChild(docLink);
  };

  const onCloseAttachment = () => {
    fileCodeKeyRef.current = EMPTY.STRING;
    fileRef.current = null;
    changeModalVisibility();
  };

  const addTemporaryAttachment = (file) => {
    fileRef.current = file;
  };

  const addAttachment = () => {
    setReserveData((prevState) =>
      prevState.map((item) => {
        const newItem = { ...item };
        
        if (item.codeKey === fileCodeKeyRef.current) {
          newItem.file = fileRef.current;
          
          return newItem;
        }
        
        return newItem;
      })
    );

    fileCodeKeyRef.current = EMPTY.STRING;
    fileRef.current = null;
    changeModalVisibility();

    showModal({
      isShown: true,
      type: 'success',
      message: 'Successfully upload a file'
    });
  };

  const postSummaries = () => {
    const body = generateBody(reserveData);
    const isReviseDoc = () => {
      if (Boolean(docStatusRef.current)) {
        return toLower(docStatusRef.current) === 'revise';
      }
      
      return false;
    };

    postAssumptionSum({
      id: assumptionId,
      action: isReviseDoc()
        ? toUpper(ACTION.RE_SUBMIT)
        : toUpper(ACTION.SUBMIT),
      body
    });
  };

  const reviseSummaries = () => {
    isApproveRef.current = false;
    
    showCommentModal({
      isShown: true,
      message: 'Are you sure you want to ask submitter to revise this mine plan assumption?',
      isShowDescription: true,
      onSubmit: (comment) => {
        approveOrRevise({
          id: assumptionId,
          type: ACTION.REVISE,
          body: { comment }
        });

        resetCommentModal();
      }
    });
  };

  const approveSummaries = () => {
    isApproveRef.current = true;
    
    approveOrRevise({
      id: assumptionId,
      type: ACTION.APPROVE
    });
  };

  useEffect(() => {
    if (isDetailMode || isEditMode) {
      getReserveParameter({ id: assumptionId });
    }

    getPitReserveById({
      id: assumptionId
    });
  }, []);

  useEffect(() => {
    periodNameRef.current = toLower(assumption?.category?.name);
    unitRef.current = toLower(assumption?.category?.unit);
    periodRef.current = convertPeriodToTags(
      unitRef.current,
      assumption?.start_date,
      assumption?.end_date
    );
    docStatusRef.current = toLower(assumption?.status);
    isWeeklyRef.current = periodNameRef.current === 'weekly';
    isMonthlyRef.current = periodNameRef.current === 'monthly';
    isQuarterlyRef.current = periodNameRef.current === 'quarterly';
    isYearlyRef.current = periodNameRef.current === 'yearly';

    switch (true) {
      case isWeeklyRef.current: {
        setTableTabs([{ codeKey: 'week', title: 'Week' }]);

        setActiveTableTab({ codeKey: 'week', title: 'Week' });

        break;
      }
      case isMonthlyRef.current: {
        setTableTabs([{
          codeKey: moment(periodRef.current[0], 'DD MMM YYYY').format('MMM_YYYY'),
          title: moment(periodRef.current[0], 'DD MMM YYYY').format('MMM YYYY')
        }]);

        setActiveTableTab({
          codeKey: moment(periodRef.current[0], 'DD MMM YYYY').format('MMM_YYYY'),
          title: moment(periodRef.current[0], 'DD MMM YYYY').format('MMM YYYY')
        });

        break;
      }
      default: {
        setTableTabs(
          periodRef.current.map((period) => ({
            codeKey: momentPeriod(unitRef.current, period),
            title: period
          }))
        );

        setActiveTableTab({
          codeKey: momentPeriod(unitRef.current, periodRef.current[0]),
          title: periodRef.current[0]
        });

        break;
      }
    }

    if (isCreateMode) {
      isEmpty(summary)
        ? setReserveData([...reserveDefaultData])
        : setReserveData(summary.map((item) => ({
          ...item,
          update_date: !isEmpty(item.update_date)
            ? dayJS(item.update_date)
            : EMPTY.STRING
        })));

      if (isEmpty(summary)) {
        getLatestReserveParameter({ id: assumptionId });
      }
    }

    if (periodRef.current && periodRef.current.length) {
      getMinePlanGuidance({
        id: assumptionId,
        params: {
          year: moment(
            periodRef.current[0],
            toLower(unitRef.current) === UNIT.DAY
              ? 'DD MMM YYYY'
              : 'MMM YYYY'
          ).format('YYYY')
        }
      });
    }
  }, [assumption]);

  useEffect(() => {
    setCapacityData(capacity?.error
      ? []
      : capacity);
  }, [capacity]);

  useEffect(() => {
    setPitReserveData(getPitData?.error
      ? []
      : getPitData);
  }, [getPitData]);

  useEffect(() => {
    if (Boolean(capacityData && capacityData.length) && activeTableTab) {
      setObCapacityData(
        generateCapacityData(
          periodRef.current,
          periodNameRef.current,
          activeTableTab.title,
          capacityData,
          unitRef.current,
          TYPE.OVER_BURDEN
        )
      );

      setCoalCapacityData(
        generateCapacityData(
          periodRef.current,
          periodNameRef.current,
          activeTableTab.title,
          capacityData,
          unitRef.current,
          TYPE.COAL
        )
      );
    }
  }, [activeTableTab, capacityData]);

  useEffect(() => {
    if (reserveData && reserveData.length) {
      setIsValid(
        every(reserveData
          .map((item) => {
            switch (true) {
              case item.codeKey === 'coal_parameter':
                return true;
              case item.codeKey === 'min_thickness':
              case item.codeKey === 'loss':
              case item.codeKey === 'dilution':
                return Boolean(Number(item.name) && item.update_date);
              default: {
                /**
                 * `item.file` can be `null` or `false`
                 * so we can't use falsy
                 * */
                if (item.file !== false) {
                  return Boolean(
                    Boolean(item.name)
                    && Boolean(item.update_date)
                    && Boolean(item.file)
                  );
                }
                
                return Boolean(Boolean(item.name) && Boolean(item.update_date));
              }
            }
          })
        )
      );
    }
  }, [reserveData]);

  useEffect(() => {
    setMinePlanData(minePlanGuidance);
  }, [minePlanGuidance]);

  useEffect(() => {
    logsRef.current = logs || [];
  }, [logs]);

  useEffect(() => {
    if (!isEmpty(reserveParameter)) {
      setReserveData(
        generateReserveData(reserveParameter)
      );
    }
  }, [reserveParameter]);

  useEffect(() => {
    if (isCreateMode && !isEmpty(latestReserveParameter?.previous_contents)) {
      isEmpty(summary)
        ? setReserveData(
          generateReserveData(
            { contents: latestReserveParameter.previous_contents },
            true
          )
        )
        : setReserveData(summary.map((item) => ({
          ...item,
          update_date: !isEmpty(item.update_date)
            ? dayJS(item.update_date)
            : EMPTY.STRING
        })));
    }
  }, [latestReserveParameter]);
  
  useEffect(() => {
    if (assumptionIsError
      || capacityIsError
      || minePlanGuidanceIsError
      || reserveParameterIsError
      || latestReserveParameterIsError
      || getPitDataIsError
    ) {
      const generateToastId = () => {
        switch (true) {
          case assumptionIsError:
            return 'assumption';
          case capacityIsError:
            return 'capacity';
          case minePlanGuidanceIsError:
            return 'mine-plan-guidance';
          case reserveParameterIsError:
            return 'reserve-parameter';
          case latestReserveParameterIsError:
            return 'latest-reserve-parameter';
          case getPitDataIsError:
            return 'pit-reserve';
          default:
            return 'default';
        }
      };

      toast.error(
        transformError(
          assumptionError
          || capacityError
          || minePlanGuidanceError
          || reserveParameterError
          || latestReserveParameterError
          || getPitDataError
        ).message,
        { toastId: `${generateToastId()}-toast-error` }
      );
    }
  }, [
    assumptionIsError,
    assumptionError,
    capacityIsError,
    capacityError,
    minePlanGuidanceIsError,
    minePlanGuidanceError,
    reserveParameterIsError,
    reserveParameterError,
    latestReserveParameterIsError,
    latestReserveParameterError,
    getPitDataIsError,
    getPitDataError
  ]);
  
  useEffect(() => {
    if (assumptionSumIsSuccess || approveOrReviseIsSuccess) {
      const generateMessage = () => {
        if (approveOrReviseIsSuccess) {
          return isApproveRef.current
            ? 'approved'
            : 'revised';
        }
        
        return 'created';
      };
      
      toast.success(
        `You have successfully ${generateMessage()} summary`,
        { toastId: `${assumptionSumIsSuccess ? 'create-summary' : 'approve-or-revise'}-toast-success` }
      );

      resetLatestState('summary');
      goToPath('next');
      
      return;
    }
    
    const generateToastId = () => {
      switch (true) {
        case assumptionSumIsError:
          return 'assumption-sum';
        case approveOrReviseIsError:
          return 'approve-or-revise';
        default:
          return 'default';
      }
    };
    
    if (assumptionSumIsError || approveOrReviseIsError) {
      toast.error(
        transformError(assumptionSumError || approveOrReviseError).message,
        { toastId: `${generateToastId()}-toast-error` }
      );
    }
  }, [
    assumptionSumIsSuccess,
    assumptionSumIsError,
    assumptionSumError,
    approveOrReviseIsSuccess,
    approveOrReviseIsError,
    approveOrReviseError
  ]);

  return (
    <div className="flex flex-col">
      <BannerPitPeriod
        isHasButton
        buttonTitle="Approval History"
        buttonIcon={<FaHistory />}
        isLoading={assumptionIsFetching}
        companyName={assumption?.company?.alias_name}
        contractorName={assumption?.contractor?.alias_name}
        pitName={assumption?.pit?.name}
        periodMode={assumption?.category?.name}
        periods={assumption
          ? periodRef.current
          : []}
        onClick={() => showSideCar(logsRef.current, 'summary')}
      />

      <div className="my-5 p-5 flex flex-col bg-white rounded-lg">
        <span className="mb-5 font-bold text-lg">Reserves Parameters</span>
        <TableRaw
          isLoading={Boolean(isDetailMode || isEditMode)
            ? (reserveParameterIsFetching || latestReserveParameterIsFetching)
            : false
          }
          columns={
            generateReserveColumns(
              isDetailMode,
              onNameChange,
              onDateChange, 
              onUpload,
              onDownload
            )
          }
          dataSource={reserveData}
        />
      </div>

      <div className="mb-5 p-5 flex flex-col bg-white rounded-lg">
        <span className="mb-5 font-bold text-2xl">Summary Production Capacity</span>
        <div className="mb-5 flex flex-row gap-x-3">
          {
            tableTabs.map((item, index) => (
              <div
                key={`type-${index + 1}`}
                className={`p-2 flex flex-row rounded-lg gap-x-3 cursor-pointer
                ${activeTableTab.codeKey === item.codeKey ? 'bg-[#2D3D5A]' : 'bg-[#F2F5FC]'}`}
                onClick={() => setActiveTableTab(item)}
              >
                <div className={`w-2 rounded ${activeTableTab.codeKey === item.codeKey ? 'bg-white' : 'bg-[#2D3D5A]'}`} />
                <span className={`font-bold text-sm text-center self-center ${activeTableTab.codeKey === item.codeKey ? 'text-white' : 'text-[#2D3D5A]'}`}>
                  {item.title}
                </span>
              </div>
            ))
          }
        </div>
        <span className="mb-5 font-bold text-lg">Overburden Capacity</span>
        {
          (obCapacityData && obCapacityData.length)
            ? (
              <TableRaw
                isLoading={capacityIsFetching}
                columns={overBurdenColumns}
                dataSource={obCapacityData}
                summary={() => (
                  <Table.Summary.Row className="font-bold bg-pink-700/25 text-pink-700">
                    <Table.Summary.Cell index={0} className="rounded-tl-lg rounded-bl-lg">Total</Table.Summary.Cell>
                    <Table.Summary.Cell index={1} />
                    <Table.Summary.Cell index={2} />
                    <Table.Summary.Cell index={3} className="rounded-tr-lg rounded-br-lg">
                      {
                        Number(
                          sumBy(
                            obCapacityData,
                            (item) => item.capacity
                          )
                        ).toLocaleString('en-US')
                      }
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                )}
              />
            )
            : (
              <div className="w-full flex flex-col items-center justify-center">
                <Image
                  preview={false}
                  width={100}
                  src={`${process.env.PUBLIC_URL}/assets/icon-modal-confirmation.svg`}
                />
                <span className="mt-5">
                  No Overburden Data has been found
                </span>
              </div>
            )
        }
        <Divider />
        <span className="mb-5 font-bold text-lg">Coal Capacity</span>
        {
          (coalCapacityData && coalCapacityData.length)
            ? (
              <TableRaw
                isLoading={capacityIsFetching}
                columns={coalColumns}
                dataSource={coalCapacityData}
                summary={() => (
                  <Table.Summary.Row className="font-bold bg-pink-700/25 text-pink-700">
                    <Table.Summary.Cell index={0} className="rounded-tl-lg rounded-bl-lg">Total</Table.Summary.Cell>
                    <Table.Summary.Cell index={1} />
                    <Table.Summary.Cell index={2} />
                    <Table.Summary.Cell index={3} className="rounded-tr-lg rounded-br-lg">
                      {
                        Number(
                          sumBy(
                            coalCapacityData,
                            (item) => item.capacity
                          )
                        ).toLocaleString('en-US')
                      }
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                )}
              />
            )
            : (
              <div className="w-full flex flex-col items-center justify-center">
                <Image
                  preview={false}
                  width={100}
                  src={`${process.env.PUBLIC_URL}/assets/icon-modal-confirmation.svg`}
                />
                <span className="mt-5">
                  No Coal Data has been found
                </span>
              </div>
            )
        }
      </div>

      <div className="mb-5 p-5 flex flex-col bg-white rounded-lg">
        <span className="mb-5 font-bold text-lg">
          Monthly Mine Planning Guidance
          {' '}
          <>
            <Tag className="px-3 font-bold rounded-xl bg-pink-700/25 text-pink-700 border-none">
              {
                periodRef.current.length
                  ? moment(
                    periodRef.current[0],
                    toLower(unitRef.current) === UNIT.DAY
                      ? 'DD MMM YYYY'
                      : 'MMM YYYY')
                    .format('YYYY')
                  : EMPTY.STRING
              }
            </Tag>
          </>
        </span>
        <div
          className="overflow-x-auto"
          style={{ maxWidth: wrapperWidth }}
        >
          <TableRaw
            isLoading={minePlanGuidanceIsFetching}
            columns={generateMinePlanGuidanceColumns(unitRef.current, periodRef.current)}
            dataSource={
              generateMinePlanGuidanceData(
                unitRef.current,
                periodRef.current,
                minePlanData
              )
            }
            scrollWidth="max-content"
            customRow={(record, index) => generateMinePlanningGuidanceRowStyle(record, index)}
          />
        </div>
      </div>

      <div className="p-5 flex flex-col bg-white rounded-lg">
        <div className="flex flex-row items-center justify-center">
          <span className="mb-5 font-bold text-lg">
            Monthly Mine Planning Propose <span className="text-pink-700">
              {assumption?.pit?.name}
            </span>
          </span>
          <div className="ml-auto mb-5">
            <span className="mr-3 font-bold text-lg">
              Period
            </span>
            {generatePeriodTagInPropose()}
          </div>
        </div>
        <div
          className="overflow-x-auto"
          style={{ maxWidth: wrapperWidth }}
        >
          <TableRaw
            isLoading={capacityIsFetching || getPitDataIsFetching}
            columns={
              generateMinePLanProposeColumns(
                unitRef.current,
                periodRef.current,
                periodNameRef.current
              )
            }
            dataSource={
              generateMinePlanProposeData(
                unitRef.current,
                periodRef.current,
                periodNameRef.current,
                capacityData,
                pitReserveData
              )
            }
          />
        </div>
      </div>

      <div className="mt-5 flex flex-row items-center justify-between">
        <ButtonAccent
          isBordered
          title="Back"
          onClick={() => goToPath('back')}
        />
        
        <div className="flex flex-row gap-x-3">
          {
            (isUserHasPermissionInFeature(loc.pathname, PERMISSION.ACKNOWLEDGE)
              || isUserHasPermissionInFeature(loc.pathname, PERMISSION.APPROVE))
            && (
              <ButtonAccent
                isBordered
                isBorderError
                isLoading={approveOrReviseIsLoading}
                isDisabled={!isValid}
                title="Revise"
                onClick={() => reviseSummaries()}
              />
            )
          }
          {
            isUserHasPermissionInFeature(loc.pathname, PERMISSION.ACKNOWLEDGE) && (
              <ButtonAccent
                isSuccess
                isLoading={approveOrReviseIsLoading}
                isDisabled={!isValid}
                title="Acknowledge"
                onClick={() => approveSummaries()}
              />
            )
          }
          {
            isUserHasPermissionInFeature(loc.pathname, PERMISSION.APPROVE) && (
              <ButtonAccent
                isSuccess
                isLoading={approveOrReviseIsLoading}
                isDisabled={!isValid}
                title="Approve"
                onClick={() => approveSummaries()}
              />
            )
          }
          {
            isUserHasPermissionInFeature(loc.pathname, PERMISSION.CREATE) && (
              <ButtonAccent
                isLoading={assumptionSumIsLoading}
                isDisabled={!isValid}
                title="Submit"
                onClick={() => postSummaries()}
              />
            )
          }
        </div>
      </div>
      {
        isModalShown && (
          <div className="w-full h-full absolute flex flex-row items-center justify-center bg-black/25 left-0 top-0 z-20">
            <div className="p-5 w-2/3 flex flex-col bg-white rounded-lg">
              <div className="flex flex-row">
                <span className="font-bold text-lg">
                  Upload {fileDescriptionRef.current}
                </span>
                <div
                  className="ml-auto p-1 bg-violet-200 rounded-full cursor-pointer"
                  onClick={onCloseAttachment}
                >
                  <AiOutlineClose size="18px" className="text-[#2D3D5A]" />
                </div>
              </div>
              <Divider />
              <div className="mb-5 flex flex-col">
                <InputFileAccent
                  noTitle
                  isAllTypes
                  value={fileRef.current}
                  onChange={addTemporaryAttachment}
                  onDrop={addTemporaryAttachment}
                />
              </div>
              <div className="flex flex-row justify-between">
                <ButtonAccent
                  isBordered
                  size="xl"
                  title="Cancel"
                  onClick={onCloseAttachment}
                />
                <ButtonAccent
                  size="xl"
                  title="Add"
                  onClick={addAttachment}
                />
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default Summary;