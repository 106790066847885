import { Progress } from 'antd';

const LoadingText = () => {
  
  return (
    <div className="flex flex-col">
      <span className="font-bold text-[#2D3D5A]">
        Fetching Detail Data...
      </span>
      <Progress
        percent={100}
        status="active"
        strokeColor="#472F92"
        showInfo={false}
      />
    </div>
  );
};

export default LoadingText;