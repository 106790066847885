import { Base } from './Base';

const GeologyDashboard = Base.injectEndpoints({
  endpoints: (build) => ({
    getListKeyHighlights: build.query({
      query: (params) => ({
        url: '/goe/dashboards/key-highlights',
        method: 'GET',
        ...params
          ? { params }
          : undefined
      })
    }),
    postKeyHighlights: build.mutation({
      query: (body) => ({
        url: '/goe/dashboards/key-highlights/bulk',
        method: 'POST',
        body
      })
    }),
    postImprovements: build.mutation({
      query: (body) => ({
        url: '/goe/dashboards/improvements/bulk',
        method: 'POST',
        body
      })
    }),
    deleteKeyHighlights: build.mutation({
      query: ({ id }) => ({
        url: `/goe/dashboards/key-highlights/${id}`,
        method: 'DELETE'
      })
    }),
    getListImprovementRequirement: build.query({
      query: (params)=> ({
        url: '/goe/dashboards/improvements',
        method: 'GET',
        ...params
          ? {params}
          : undefined
      })
    }),
    deleteImprovement: build.mutation({
      query: ({ id }) => ({
        url: `/goe/dashboards/improvements/${id}`,
        method: 'DELETE'
      })
    }),
  })
});

export const { 
  useLazyGetListKeyHighlightsQuery, 
  usePostKeyHighlightsMutation, 
  useDeleteKeyHighlightsMutation,
  useLazyGetListImprovementRequirementQuery,
  usePostImprovementsMutation,
  useDeleteImprovementMutation
} = GeologyDashboard;