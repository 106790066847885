import moment from 'moment';
import {
  useEffect,
  useReducer,
  useRef,
  useState
} from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import { AiFillCheckCircle } from 'react-icons/ai';
import { Table } from 'antd';
import { toast } from 'react-toastify';
import { isEmpty, toLower } from 'lodash';

import { useGetAssumptionByIdQuery } from 'api/Assumption';
import { useLazyGetLossTimeByIdQuery } from 'api/Production';
import { useLazyGetUnitByIdQuery } from 'api/Unit';
import { 
  useLazyGetHourCapacityByIdQuery,
  useLazyGetLatestHourCapacityQuery,
  usePostHourCapacityMutation, 
  usePutHourCapacityMutation 
} from 'api/HourCapacity';
import { EMPTY, UNIT, URL } from 'constant';
import { TYPE } from 'constant/MasterData';
import {
  generateHeaderColumns,
  generateHeaderData,
  generateTableColumns,
  generateTableData,
  generateSummaries,
  generateBody,
  calculatePhysicalAvailability,
  calculateMechanicalAvailability,
  calculateUseOfAvailability,
  calculateEffectiveWorkingHours,
  calculateCoalProduction,
  calculateSummary,
  pickSummaryData,
  validateData,
  stateKey
} from 'constant/TableCoalCapacity';
import {
  getPathType,
  getFullPath,
  shouldRedirect
} from 'utils/PathUtility';
import { convertPeriodToTags } from 'utils/PeriodUtility';
import { transformError } from 'utils/ErrorTransformer';
import { useAssumptionManager } from 'utils/AssumptionManager';
import { useTableWrapper } from 'utils/TableResponsive';

import BannerPitPeriod from 'components/BannerPitPeriod';
import TableRaw from 'components/TableRaw';
import ButtonAccent from 'components/ButtonAccent';
import LoadingText from 'components/LoadingText';

const CoalCapacity = () => {
  const loc = useLocation();
  const navigate = useNavigate();

  const initialState = {
    headerColumns: [],
    headerData: [],
    maintenanceTableColumns: [],
    maintenanceTableData: [],
    maintenanceSummaries: [],
    paTableColumns: [],
    paTableData: [],
    paSummaries: [],
    maTableColumns: [],
    maTableData: [],
    maSummaries: [],
    uaTableColumns: [],
    uaTableData: [],
    uaSummaries: [],
    ewhTableColumns: [],
    ewhTableData: [],
    ewhSummaries: [],
    productivityTableColumns: [],
    productivityTableData: [],
    productivitySummaries: [],
    coalProductionTableColumns: [],
    coalProductionTableData: [],
    coalProductionSummaries: [],
    unitData: [],
    unitByIdData: {},
    previousData: {}
  };

  const reducer = (state, action) => {
    const transformKey = () => {
      if (action.key === stateKey.production.key) {
        return 'coalProduction';
      }

      return action.key;
    };

    /**
     * Generate columns, table and summary by its respective data
     * */
    switch (action.type) {
      case 'header_column':
        return {
          ...state,
          headerColumns: action.payload
        };
      case 'header_data':
        return {
          ...state,
          headerData: action.payload
        };
      case stateKey.maintenance.column:
      case stateKey.pa.column:
      case stateKey.ma.column:
      case stateKey.ua.column:
      case stateKey.ewh.column:
      case stateKey.productivity.column:
      case stateKey.production.column: {
        return {
          ...state,
          [`${transformKey()}TableColumns`]: action.payload
        }; 
      }
      case stateKey.maintenance.table:
      case stateKey.pa.table:
      case stateKey.ma.table:
      case stateKey.ua.table:
      case stateKey.ewh.table:
      case stateKey.productivity.table:
      case stateKey.production.table: {
        return {
          ...state,
          [`${transformKey()}TableData`]: action.payload
        };
      }
      case stateKey.maintenance.summary:
      case stateKey.pa.summary:
      case stateKey.ma.summary:
      case stateKey.ua.summary:
      case stateKey.ewh.summary:
      case stateKey.productivity.summary:
      case stateKey.production.summary: {
        return {
          ...state,
          [`${transformKey()}Summaries`]: action.payload
        };
      }
      case stateKey.maintenance.change:
      case stateKey.productivity.change: {
        const { ev, record, dataIndex } = action.payload;
        switch (action.key) {
          case stateKey.maintenance.key:
            return {
              ...state,
              maintenanceTableData: state.maintenanceTableData.map((item) => {
                if (item.id === record.id) {
                  return {
                    ...item,
                    [dataIndex]: ev
                  };
                }

                return { ...item };
              })
            };
          case stateKey.productivity.key:
            return {
              ...state,
              productivityTableData: state.productivityTableData.map((item) => {
                if (item.id === record.id) {
                  return {
                    ...item,
                    [dataIndex]: ev
                  };
                }

                return { ...item };
              })
            };
          default:
            return { ...state };
        }
      }
      case stateKey.pa.change: {
        const { period, maintenanceItem } = action.payload;
        const newState = state.paTableData.map(
          (paItem) => calculatePhysicalAvailability(
            state.headerData,
            unitRef.current,
            periodNameRef.current,
            periodRef.current,
            period,
            paItem,
            maintenanceItem
          )
        );
        
        return {
          ...state,
          paTableData: newState
        };
      }
      case stateKey.ma.change: {
        const { period, maintenanceItem } = action.payload;
        const newState = state.maTableData.map(
          (maItem) =>
            calculateMechanicalAvailability(
              lossTimeRef.current,
              state.headerData,
              unitRef.current,
              periodNameRef.current,
              periodRef.current,
              period,
              maItem,
              maintenanceItem
            )
        );

        return {
          ...state,
          maTableData: newState
        };
      }
      case stateKey.ua.change: {
        const { period, maintenanceItem } = action.payload;
        const newState = state.uaTableData.map(
          (uaItem) =>
            calculateUseOfAvailability(
              lossTimeRef.current,
              state.headerData,
              unitRef.current,
              periodNameRef.current,
              periodRef.current,
              period,
              uaItem,
              maintenanceItem
            )
        );

        return {
          ...state,
          uaTableData: newState
        };
      }
      case stateKey.ewh.change: {
        const { period, paItem, uaItem } = action.payload;
        const newState = state.ewhTableData.map(
          (ewhItem) =>
            calculateEffectiveWorkingHours(
              state.headerData,
              unitRef.current,
              periodNameRef.current,
              periodRef.current,
              period,
              ewhItem,
              paItem,
              uaItem
            )
        );

        return {
          ...state,
          ewhTableData: newState
        };
      }
      case stateKey.production.change: {
        const { period, ewhItem, productivityItem } = action.payload;
        const newState = state.coalProductionTableData.map(
          (coalProdItem) =>
            calculateCoalProduction(
              unitRef.current,
              periodNameRef.current,
              periodRef.current,
              period,
              coalProdItem,
              ewhItem,
              productivityItem
            )
        );

        return {
          ...state,
          coalProductionTableData: newState
        };
      }
      case 'unit_data':
      case 'unit_by_id_data':
      case 'unit_latest_data': {
        const { key, payload } = action;

        switch (key) {
          case 'unit_data': {
            return {
              ...state,
              unitData: payload
            };
          }
          case 'unit_by_id_data': {
            return {
              ...state,
              unitByIdData: payload
            };
          }
          case 'unit_latest_data': {
            return {
              ...state,
              previousData: payload
            };
          }
          default:
            return {
              ...state
            };
        }
      }
      default:
        return {
          ...state
        };
    }
  };

  const [activeTableTab, setActiveTableTab] = useState('maintenance');
  const [isValid, setIsValid] = useState(false);

  const isDraftRef = useRef(false);
  const isWeeklyRef = useRef(false);
  const isMonthlyRef = useRef(false);
  const periodRef = useRef([]);
  const periodNameRef = useRef(EMPTY.STRING);
  const unitRef = useRef(UNIT.MONTH);
  const lossTimeRef = useRef(null);
  const unitsRef = useRef(null);
  const hourCapacityIdRef = useRef(null);
  const currentTypeRef = useRef(EMPTY.STRING);

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    createBackQueryParams,
    isPathHasBackQueryParams,
    isPathMaxBackQueryParams
  } = useAssumptionManager();
  const { wrapperWidth } = useTableWrapper();

  const assumptionId = new URLSearchParams(loc.search).get('id');
  const backQueries = new URLSearchParams(loc.search).getAll('isBack');
  const isBack = isPathHasBackQueryParams(loc.pathname, backQueries);
  const isCreateMode = getPathType(loc) === 'create';
  const isDetailMode = getPathType(loc) === 'detail';
  const isEditMode = getPathType(loc) === 'edit';
  const isNeedToFetchDetail = Boolean(isBack || isDetailMode || isEditMode);

  const tableTabs = [
    {
      codeKey: stateKey.maintenance.key,
      title: 'Maintenance Time Hours',
      subtitle: 'Maintenance Time Hours'
    },
    {
      codeKey: stateKey.pa.key,
      title: 'PA',
      subtitle: 'PA (Physical Availability)'
    },
    {
      codeKey: stateKey.ma.key,
      title: 'MA',
      subtitle: 'MA (Mechanical Availability)'
    },
    {
      codeKey: stateKey.ua.key,
      title: 'UA',
      subtitle: 'UA (Use Of Availability)'
    },
    {
      codeKey: stateKey.ewh.key,
      title: 'EWH',
      subtitle: 'EWH (Effective Working Hours)'
    },
    {
      codeKey: stateKey.productivity.key,
      title: 'Productivity',
      subtitle: 'Productivity'
    },
    {
      codeKey: stateKey.production.key,
      title: 'Coal Production',
      subtitle: 'Coal Production'
    }
  ];

  const {
    data: assumption,
    isFetching: assumptionIsFetching,
    isError: assumptionIsError,
    error: assumptionError
  } = useGetAssumptionByIdQuery(
    { id: assumptionId },
    { refetchOnMountOrArgChange: true }
  );

  const [getLossTime, {
    data: lossTime,
    isFetching: lossTimeIsFetching,
    isError: lossTimeIsError,
    error: lossTimeError
  }] = useLazyGetLossTimeByIdQuery();

  const [getUnit, {
    data: unit,
    isFetching: unitIsFetching,
    isError: unitIsError,
    error: unitError
  }] = useLazyGetUnitByIdQuery();

  const [getLatestHourCapacity, {
    data: latestHourCapacityData,
    isFetching: latestHourCapacityDataIsFetching,
    isError: latestHourCapacityDataIsError,
    error: latestHourCapacityDataError
  }] = useLazyGetLatestHourCapacityQuery();

  const [getHourCapacityById, {
    data: hourCapacityByIdData,
    isFetching: hourCapacityByIdDataIsFetching,
    isError: hourCapacityByIdDataIsError,
    error: hourCapacityByIdDataError
  }] = useLazyGetHourCapacityByIdQuery();

  const [postHourCapacity, {
    isLoading: hourCapacityIsLoading,
    isSuccess: hourCapacityIsSuccess,
    isError: hourCapacityIsError,
    error: hourCapacityError
  }] = usePostHourCapacityMutation();

  const [putHourCapacity, {
    isLoading: putHourCapacityIsLoading,
    isSuccess: putHourCapacityIsSuccess,
    isError: putHourCapacityIsError,
    error: putHourCapacityError
  }] = usePutHourCapacityMutation();

  const momentPeriod = (unit, item) => {
    return moment(item, toLower(unit) === UNIT.DAY ? 'DD MMM YYYY' : 'MMM YYYY')
      .format(toLower(unit) === UNIT.DAY ? 'DD_MMM_YYYY' : 'MMM_YYYY');
  };

  const getSubtitle = () => {
    const { subtitle } = tableTabs.find((item) => item.codeKey === activeTableTab);

    return subtitle;
  };

  const redirectPathToCreate = () => navigate({
    pathname: getFullPath({
      parent: 'assumption',
      child: 'coal-hours-capacity',
      type: 'create'
    }),
    search: createSearchParams({ id: assumptionId }).toString()
  });

  const goToPath = (type) => navigate({
    pathname: getFullPath({
      parent: 'assumption',
      child: type === 'back'
        ? 'select-coal-unit'
        : 'over-burden-hauler',
      type: getPathType(loc)
    }),
    search: createSearchParams({
      id: assumptionId,
      ...type === 'back'
        ? {
          isBack: isBack
            ? backQueries
            : createBackQueryParams(loc.pathname)
        }
        : {
          ...isPathMaxBackQueryParams(loc.pathname, backQueries)
            ? undefined
            : { isBack: backQueries }
        }
    }).toString()
  });

  const transformValidationIcon = (isActive, codeKey) => {
    const isValid = validateData(
      codeKey,
      unitRef.current,
      periodNameRef.current,
      periodRef.current,
      state.maintenanceTableData,
      state.productivityTableData
    );
    
    if (isActive) {
      return isValid
        ? 'text-white'
        : 'text-white/50';
    }

    return isValid
      ? 'text-[#2D3D5A]'
      : 'text-[#2D3D5A]/25';
  };

  const switchTable = () => {
    switch (activeTableTab) {
      case stateKey.maintenance.key:
        return [
          state.maintenanceTableColumns,
          state.maintenanceTableData,
          state.maintenanceSummaries
        ];
      case stateKey.pa.key:
        return [
          state.paTableColumns,
          state.paTableData,
          state.paSummaries
        ];
      case stateKey.ma.key:
        return [
          state.maTableColumns,
          state.maTableData,
          state.maSummaries
        ];
      case stateKey.ua.key:
        return [
          state.uaTableColumns,
          state.uaTableData,
          state.uaSummaries
        ];
      case stateKey.ewh.key:
        return [
          state.ewhTableColumns,
          state.ewhTableData,
          state.ewhSummaries
        ];
      case stateKey.productivity.key:
        return [
          state.productivityTableColumns,
          state.productivityTableData,
          state.productivitySummaries
        ];
      case stateKey.production.key:
        return [
          state.coalProductionTableColumns,
          state.coalProductionTableData,
          state.coalProductionSummaries
        ];
      default:
        return [
          [],
          [],
          []
        ];
    }
  };

  const onMaintenanceChange = (ev, record, dataIndex) => {
    dispatch({
      type: stateKey.maintenance.change,
      key: stateKey.maintenance.key,
      payload: { ev, record, dataIndex }
    });
  };

  const onProductivityChange = (ev, record, dataIndex) => {
    dispatch({
      type: stateKey.productivity.change,
      key: stateKey.productivity.key,
      payload: { ev, record, dataIndex }
    });
  };

  const selectSummaryData = (item, itemIndex) => {
    const summary = pickSummaryData(
      unitRef.current,
      periodNameRef.current,
      periodRef.current,
      item,
      itemIndex
    );

    if (summary) {
      return !isNaN(summary)
        ? Number(summary).toLocaleString('en-US')
        : summary;
    }

    return 0;
  };

  const generateTotalByPeriodName = (weeklyValue, monthlyValue, defaultValue) => {
    switch (true) {
      case isWeeklyRef.current:
        return weeklyValue;
      case isMonthlyRef.current:
        return monthlyValue;
      default:
        return defaultValue;
    }
  };

  const generateValueByPeriodName = (
    summary,
    weeklyKey,
    monthlyKey,
    defaultKey,
    value
  ) => {
    switch (true) {
      case isWeeklyRef.current: {
        summary[weeklyKey] = value;

        break;
      }
      case isMonthlyRef.current: {
        summary[monthlyKey] = value;

        break;
      }
      default: {
        summary[defaultKey] = value;

        break;
      }
    }
  };

  const postHoursCapacities = (isDraft) => {
    isDraftRef.current = isDraft;
    
    const body = generateBody(
      isDraftRef.current,
      assumptionId,
      unitRef.current,
      periodNameRef.current,
      periodRef.current,
      state.headerData,
      unitsRef.current.contents,
      state.maintenanceTableData,
      state.paTableData,
      state.maTableData,
      state.uaTableData,
      state.ewhTableData,
      state.productivityTableData,
      state.coalProductionTableData,
      state.paSummaries,
      state.maSummaries,
      state.uaSummaries,
      state.ewhSummaries,
      state.productivitySummaries,
      state.coalProductionSummaries
    );
    
    if (isBack || isEditMode) {
      putHourCapacity({ id: hourCapacityIdRef.current, body });

      return;
    }

    postHourCapacity(body);
  };

  useEffect(() => {
    if (isEditMode) {
      currentTypeRef.current = getPathType(loc);

      return;
    }

    if (currentTypeRef.current === 'edit' && getPathType(loc) === 'create') {
      window.location.reload();
    }
  }, [loc]);

  useEffect(() => {
    unitRef.current = toLower(assumption?.category?.unit);
    periodNameRef.current = toLower(assumption?.category?.name);
    periodRef.current = convertPeriodToTags(
      unitRef.current,
      assumption?.start_date,
      assumption?.end_date
    );
    isWeeklyRef.current = periodNameRef.current === 'weekly';
    isMonthlyRef.current = periodNameRef.current === 'monthly';

    /**
     * Header Section
     * */
    dispatch({
      type: 'header_column',
      payload: generateHeaderColumns(
        unitRef.current,
        periodNameRef.current,
        periodRef.current
      )
    });
  }, [assumption]);

  useEffect(() => {
    lossTimeRef.current = lossTime;

    /**
     * Header Section
     * */
    dispatch({
      type: 'header_data',
      payload: generateHeaderData(
        unitRef.current,
        periodNameRef.current,
        periodRef.current,
        lossTime
      )
    });
  }, [lossTime]);

  useEffect(() => {
    if (!isEmpty(unit)) {
      unitsRef.current = unit;

      if (isNeedToFetchDetail) {
        getHourCapacityById({
          id: assumptionId,
          params: { type: TYPE.COAL }
        });
      }

      /**
       * Table Section
       * */
      const isCanBeZero = (key) => key !== stateKey.productivity.key;

      const isInputType = (key) => Boolean(key === stateKey.maintenance.key || key === stateKey.productivity.key);

      const selectChangeFunction = (key) => {
        switch (key) {
          case stateKey.maintenance.key:
            return onMaintenanceChange;
          case stateKey.productivity.key:
            return onProductivityChange;
          default:
            return undefined;
        }
      };

      const isPercentage = (key) => {
        switch (key) {
          case stateKey.pa.key:
            return true;
          case stateKey.ma.key:
            return true;
          case stateKey.ua.key:
            return true;
          default:
            return false;
        }
      };

      Object.keys(stateKey).forEach((key) => {
        dispatch({
          type: stateKey[key].column,
          key: stateKey[key].key,
          payload: generateTableColumns(
            isCanBeZero(stateKey[key].key),
            isDetailMode,
            isInputType(stateKey[key].key),
            isPercentage(stateKey[key].key),
            stateKey[key].key,
            unitRef.current,
            periodNameRef.current,
            periodRef.current,
            selectChangeFunction(stateKey[key].key)
          )
        });
      });

      if (!isNeedToFetchDetail) {
        const generateCode = (key) => {
          if (key === stateKey.maintenance.key) {
            return 'maintenance_hours';
          }

          return key;
        };

        Object.keys(stateKey).forEach((key) => {
          dispatch({
            type: stateKey[key].table,
            key: stateKey[key].key,
            payload: generateTableData(
              unitRef.current,
              periodNameRef.current,
              periodRef.current,
              unit?.contents,
              undefined,
              generateCode(stateKey[key].key)
            )
          });

          dispatch({
            type: stateKey[key].summary,
            key: stateKey[key].key,
            payload: generateSummaries(
              unitRef.current,
              periodNameRef.current,
              periodRef.current
            )
          });
        });
      }

      dispatch({
        type: 'unit_data',
        key: 'unit_data',
        payload: unit?.contents
      });
    }
  }, [unit]);

  useEffect(() => {
    if (isEditMode && shouldRedirect(hourCapacityByIdData)) {
      redirectPathToCreate();

      return;
    }

    if (!isEmpty(hourCapacityByIdData)
      && !Boolean(hourCapacityByIdData?.error)
      && isNeedToFetchDetail
    ) {
      hourCapacityIdRef.current = hourCapacityByIdData.id;

      const generateCode = (key) => {
        if (key === stateKey.maintenance.key) {
          return 'maintenance_hours';
        }

        return key;
      };

      Object.keys(stateKey).forEach((key) => {
        dispatch({
          type: stateKey[key].table,
          key: stateKey[key].key,
          payload: generateTableData(
            unitRef.current,
            periodNameRef.current,
            periodRef.current,
            state.unitData,
            hourCapacityByIdData.contents,
            generateCode(stateKey[key].key)
          )
        });

        dispatch({
          type: stateKey[key].summary,
          key: stateKey[key].key,
          payload: generateSummaries(
            unitRef.current,
            periodNameRef.current,
            periodRef.current
          )
        });
      });

      dispatch({
        type: 'unit_by_id_data',
        key: 'unit_by_id_data',
        payload: hourCapacityByIdData.contents.find((item) => item.code === 'equipments')
      });
    }
  }, [hourCapacityByIdData]);

  useEffect(() => {
    if (!isEmpty(latestHourCapacityData)
      && !Boolean(latestHourCapacityData?.error)
      && !isEmpty(latestHourCapacityData?.previous_contents)
    ) {
      dispatch({
        type: 'unit_latest_data',
        key: 'unit_latest_data',
        payload: latestHourCapacityData.previous_contents?.find((item) => item.code === 'equipments')
      });
    }
  }, [latestHourCapacityData]);

  useEffect(() => {
    switch (true) {
      case isEmpty(lossTime) && !isEmpty(state.headerColumns): {
        getLossTime({ id: assumptionId });

        break;
      }
      case isEmpty(unit) && !isEmpty(state.headerData): {
        getUnit({
          id: assumptionId,
          params: { type: TYPE.COAL }
        });

        break;
      }
      case !isEmpty(state.unitData): {
        getLatestHourCapacity({
          id: assumptionId,
          params: { type: TYPE.COAL }
        });

        break;
      }
      default:
        break;
    }
  }, [
    state.headerColumns,
    state.headerData,
    state.unitData
  ]);

  useEffect(() => {
    if (isCreateMode
      && !isBack
      && !isEmpty(state.previousData)
    ) {
      const generateCode = (key) => {
        if (key === stateKey.maintenance.key) {
          return 'maintenance_hours';
        }

        return key;
      };

      const mergedUnitWithPreviousAndDetail = state.unitData.map(
        (item) => {
          const existDetailUnit =
            !isEmpty(state.unitByIdData)
              ? state.unitByIdData.properties.find(
                (detailItem) => detailItem.value.some(
                  (propDetailItem) => propDetailItem.code === 'equipment_no' && item.alias_name.includes(propDetailItem.value)
                )
              )
              : undefined;

          const existUnit = state.previousData.properties.find(
            (prevItem) => prevItem.value.some(
              (propPrevItem) => propPrevItem.code === 'equipment_no' && item.alias_name.includes(propPrevItem.value)
            )
          );

          switch (true) {
            case !isEmpty(existDetailUnit):
              return { ...existDetailUnit };
            case !isEmpty(existUnit): {
              return { ...existUnit };
            }
            default:
              return { ...item };
          }
        }
      );

      const filteredMergedData = mergedUnitWithPreviousAndDetail.filter((item) => Boolean(item.code));

      Object.keys(stateKey).forEach((key) => {
        dispatch({
          type: stateKey[key].table,
          key: stateKey[key].key,
          payload: generateTableData(
            unitRef.current,
            periodNameRef.current,
            periodRef.current,
            state.unitData,
            [{ code: 'equipments', properties: filteredMergedData }],
            generateCode(stateKey[key].key)
          )
        });
      });
    }
  }, [state.previousData]);

  useEffect(() => {
    if (state.maintenanceTableData.length) {
      const summary = {};

      periodRef.current.forEach((period) => {
        let total = 0;

        state.maintenanceTableData.forEach((data) => {
          /**
           * Calculate Physical Availability
           * */
          dispatch({
            type: stateKey.pa.change,
            payload: { period, maintenanceItem: data }
          });

          /**
           * Calculate Mechanical Availability
           * */
          dispatch({
            type: stateKey.ma.change,
            payload: { period, maintenanceItem: data }
          });

          /**
           * Calculate Use of Availability
           * */
          dispatch({
            type: stateKey.ua.change,
            payload: { period, maintenanceItem: data }
          });

          total += generateTotalByPeriodName(
            data.week,
            data[moment(periodRef.current[0], 'DD MMM YYYY').format('MMM_YYYY')],
            data[momentPeriod(unitRef.current, period)]
          );
        });

        generateValueByPeriodName(
          summary,
          'week',
          moment(periodRef.current[0], 'DD MMM YYYY').format('MMM_YYYY'),
          momentPeriod(unitRef.current, period),
          (total / state.maintenanceTableData.length)
        );
      });

      const maintenanceSum = calculateSummary(
        unitRef.current,
        periodNameRef.current,
        periodRef.current,
        summary
      );

      dispatch({
        type: stateKey.maintenance.summary,
        key: stateKey.maintenance.key,
        payload: maintenanceSum
      });
    }

    setIsValid(
      validateData(
        null,
        unitRef.current,
        periodNameRef.current,
        periodRef.current,
        state.maintenanceTableData,
        state.productivityTableData
      )
    );
  }, [state.maintenanceTableData]);

  useEffect(() => {
    if (state.productivityTableData.length) {
      const summary = {};
      
      periodRef.current.forEach((period) => {
        let total = 0;
        
        state.productivityTableData.forEach((data) => {
          /**
           * Calculate Coal Production
           * */
          state.ewhTableData.forEach((ewhItem) => {
            dispatch({
              type: stateKey.production.change,
              payload: { period, ewhItem, productivityItem: data }
            });
          });

          total += generateTotalByPeriodName(
            data.week,
            data[moment(periodRef.current[0], 'DD MMM YYYY').format('MMM_YYYY')],
            data[momentPeriod(unitRef.current, period)]
          );
        });

        generateValueByPeriodName(
          summary,
          'week',
          moment(periodRef.current[0], 'DD MMM YYYY').format('MMM_YYYY'),
          momentPeriod(unitRef.current, period),
          (total / state.productivityTableData.length)
        );
      });

      const productivitySum = calculateSummary(
        unitRef.current,
        periodNameRef.current,
        periodRef.current,
        summary
      );

      dispatch({
        type: stateKey.productivity.summary,
        key: stateKey.productivity.key,
        payload: productivitySum
      });
    }
    
    setIsValid(
      validateData(
        null,
        unitRef.current,
        periodNameRef.current,
        periodRef.current,
        state.maintenanceTableData,
        state.productivityTableData
      )
    );
  }, [state.productivityTableData]);

  useEffect(() => {
    if (state.uaTableData.length) {
      const summary = {};

      periodRef.current.forEach((period) => {
        let total = 0;
        
        state.uaTableData.forEach((data) => {
          /**
           * Calculate Effective Working Hours
           * */
          state.paTableData.forEach((paItem) => {
            dispatch({
              type: stateKey.ewh.change,
              payload: { period, paItem, uaItem: data }
            });
          });
          
          total += generateTotalByPeriodName(
            data.week,
            data[moment(periodRef.current[0], 'DD MMM YYYY').format('MMM_YYYY')],
            data[momentPeriod(unitRef.current, period)]
          );
        });
        
        generateValueByPeriodName(
          summary,
          'week',
          moment(periodRef.current[0], 'DD MMM YYYY').format('MMM_YYYY'),
          momentPeriod(unitRef.current, period),
          (total / state.uaTableData.length)
        );
      });

      const uaSum = calculateSummary(
        unitRef.current,
        periodNameRef.current,
        periodRef.current,
        summary
      );

      dispatch({
        type: stateKey.ua.summary,
        key: stateKey.ua.key,
        payload: uaSum
      });
    }
  }, [state.uaTableData]);

  useEffect(() => {
    if (state.paTableData.length) {
      const summary = {};
      
      periodRef.current.forEach((period) => {
        let total = 0;
        
        state.paTableData.forEach((data) => {
          total += generateTotalByPeriodName(
            data.week,
            data[moment(periodRef.current[0], 'DD MMM YYYY').format('MMM_YYYY')],
            data[momentPeriod(unitRef.current, period)]
          );
        });

        generateValueByPeriodName(
          summary,
          'week',
          moment(periodRef.current[0], 'DD MMM YYYY').format('MMM_YYYY'),
          momentPeriod(unitRef.current, period),
          (total / state.paTableData.length)
        );
      });
      
      const paSum = calculateSummary(
        unitRef.current,
        periodNameRef.current,
        periodRef.current,
        summary
      );

      dispatch({
        type: stateKey.pa.summary,
        key: stateKey.pa.key,
        payload: paSum
      });
    }
  }, [state.paTableData]);

  useEffect(() => {
    if (state.maTableData.length) {
      const summary = {};

      periodRef.current.forEach((period) => {
        let total = 0;

        state.maTableData.forEach((data) => {
          total += generateTotalByPeriodName(
            data.week,
            data[moment(periodRef.current[0], 'DD MMM YYYY').format('MMM_YYYY')],
            data[momentPeriod(unitRef.current, period)]
          );
        });

        generateValueByPeriodName(
          summary,
          'week',
          moment(periodRef.current[0], 'DD MMM YYYY').format('MMM_YYYY'),
          momentPeriod(unitRef.current, period),
          (total / state.maTableData.length)
        );
      });

      const maSum = calculateSummary(
        unitRef.current,
        periodNameRef.current,
        periodRef.current,
        summary
      );

      dispatch({
        type: stateKey.ma.summary,
        key: stateKey.ma.key,
        payload: maSum
      });
    }
  }, [state.maTableData]);

  useEffect(() => {
    if (state.ewhTableData.length) {
      const summary = {};

      periodRef.current.forEach((period) => {
        let total = 0;

        state.productivityTableData.forEach((productivityItem) => {
          /**
           * Calculate Overburden Production
           * */
          state.ewhTableData.forEach((data) => {
            dispatch({
              type: stateKey.production.change,
              payload: { period, ewhItem: data, productivityItem }
            });

            total += generateTotalByPeriodName(
              data.week,
              data[moment(periodRef.current[0], 'DD MMM YYYY').format('MMM_YYYY')],
              data[momentPeriod(unitRef.current, period)]
            );
          });
        });

        generateValueByPeriodName(
          summary,
          'week',
          moment(periodRef.current[0], 'DD MMM YYYY').format('MMM_YYYY'),
          momentPeriod(unitRef.current, period),
          (total / (state.ewhTableData.length * state.productivityTableData.length))
        );
      });

      const ewhSum = calculateSummary(
        unitRef.current,
        periodNameRef.current,
        periodRef.current,
        summary
      );

      dispatch({
        type: stateKey.ewh.summary,
        key: stateKey.ewh.key,
        payload: ewhSum
      });
    }
  }, [state.ewhTableData]);

  useEffect(() => {
    if (state.coalProductionTableData.length) {
      const summary = {};

      periodRef.current.forEach((period) => {
        let total = 0;

        state.coalProductionTableData.forEach((data) => {
          total += generateTotalByPeriodName(
            data.week,
            data[moment(periodRef.current[0], 'DD MMM YYYY').format('MMM_YYYY')],
            data[momentPeriod(unitRef.current, period)]
          );
        });

        generateValueByPeriodName(
          summary,
          'week',
          moment(periodRef.current[0], 'DD MMM YYYY').format('MMM_YYYY'),
          momentPeriod(unitRef.current, period),
          total
        );
      });

      const coalProdSum = calculateSummary(
        unitRef.current,
        periodNameRef.current,
        periodRef.current,
        summary
      );

      dispatch({
        type: stateKey.production.summary,
        key: stateKey.production.key,
        payload: coalProdSum
      });
    }
  }, [state.coalProductionTableData]);

  useEffect(() => {
    if (assumptionIsError
      || lossTimeIsError
      || unitIsError
      || hourCapacityByIdDataIsError
      || latestHourCapacityDataIsError
    ) {
      const generateToastId = () => {
        switch (true) {
          case assumptionIsError:
            return 'assumption';
          case lossTimeIsError:
            return 'loss-time';
          case unitIsError:
            return 'unit';
          case hourCapacityByIdDataIsError:
            return 'hour-capacity-by-id';
          case latestHourCapacityDataIsError:
            return 'latest-hour-capacity';
          default:
            return 'default';
        }
      };

      toast.error(
        transformError(assumptionError
          || lossTimeError
          || unitError
          || hourCapacityByIdDataError
          || latestHourCapacityDataError
        ).message,
        { toastId: `${generateToastId()}-toast-error` }
      );
    }
  }, [
    assumptionIsError,
    assumptionError,
    lossTimeIsError,
    lossTimeError,
    unitIsError,
    unitError,
    hourCapacityByIdDataIsError,
    hourCapacityByIdDataError,
    latestHourCapacityDataIsError,
    latestHourCapacityDataError
  ]);

  useEffect(() => {
    if (hourCapacityIsSuccess || putHourCapacityIsSuccess) {
      if (isDraftRef.current) {
        navigate(`/${URL.ACTIVITY_LEVEL}/${URL.MINE_PLANNING_OE}/assumption`);
        
        return;
      }
      
      goToPath('next');
      
      return;
    }
    
    if (hourCapacityIsError || putHourCapacityIsError) {
      toast.error(
        transformError(hourCapacityError || putHourCapacityError).message,
        { toastId: 'post-hour-capacity-error' }
      );
      
      return;
    }
    
    return () => {};
  }, [
    hourCapacityIsSuccess,
    hourCapacityIsError,
    hourCapacityError,
    putHourCapacityIsSuccess,
    putHourCapacityIsError,
    putHourCapacityError
  ]);

  return (
    <div className="flex flex-col">
      <BannerPitPeriod
        isLoading={assumptionIsFetching}
        companyName={assumption?.company?.alias_name}
        contractorName={assumption?.contractor?.alias_name}
        pitName={assumption?.pit?.name}
        periodMode={assumption?.category?.name}
        periods={assumption
          ? periodRef.current
          : []}
      />

      <div className="my-5 p-5 flex flex-col bg-white rounded-lg">
        <div className="mb-5 flex flex-row items-center">
          <span className="mb-5 font-bold text-lg">Coal Hours and Capacity</span>
          {
            (isNeedToFetchDetail)
              ? (
                <div className="ml-auto">
                  {
                    (hourCapacityByIdDataIsFetching || latestHourCapacityDataIsFetching) && (<LoadingText />)
                  }
                </div>
              )
              : (<></>)
          }
        </div>

        <div
          className="overflow-x-auto"
          style={{ maxWidth: wrapperWidth }}
        >
          <TableRaw
            isLoading={assumptionIsFetching || lossTimeIsFetching}
            scrollWidth="max-content"
            columns={state.headerColumns}
            dataSource={state.headerData}
          />
        </div>
      </div>

      <div className="flex flex-row gap-x-3">
        {
          tableTabs.map((item, index) => (
            <div
              key={`type-${index + 1}`}
              className={`p-2 flex flex-row rounded-lg gap-x-3 cursor-pointer
              ${activeTableTab === item.codeKey ? 'bg-[#2D3D5A]' : 'bg-white/50'}`}
              onClick={() => setActiveTableTab(item.codeKey)}
            >
              <div className={`w-2 rounded ${activeTableTab === item.codeKey ? 'bg-white' : 'bg-[#2D3D5A]'}`} />
              <span className={`font-bold text-sm text-center self-center ${activeTableTab === item.codeKey ? 'text-white' : 'text-[#2D3D5A]'}`}>
                {item.title}
              </span>
              {
                Boolean(item.codeKey === 'maintenance' || item.codeKey === 'productivity') && (
                  <AiFillCheckCircle className={transformValidationIcon(activeTableTab === item.codeKey, item.codeKey)} />
                )
              }
            </div>
          ))
        }
      </div>

      <div className="mt-5 p-5 bg-white rounded-lg">
        <div className="mb-5">
          <span className="font-bold text-lg">
            {getSubtitle()}
          </span>
        </div>

        <div
          className="overflow-x-auto"
          style={{ maxWidth: wrapperWidth }}
        >
          <TableRaw
            isLoading={unitIsFetching}
            scrollWidth="max-content"
            columns={switchTable()[0]}
            dataSource={switchTable()[1]}
            summary={() => (
              <Table.Summary.Row className="font-bold bg-pink-700/25 text-pink-700">
                <Table.Summary.Cell index={0} className="rounded-tl-lg rounded-bl-lg" />
                <Table.Summary.Cell index={1}>
                  {
                    getSubtitle() === 'Coal Production' ? 'Total' : 'Average'
                  }
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} />
                {
                  switchTable()[2].map((item, index) => (
                    <Table.Summary.Cell
                      key={`sum-cell-${activeTableTab}-${index + 1}`}
                      index={(3 + index)}
                      className={index === (switchTable()[2].length - 1) && 'rounded-tr-lg rounded-br-lg'}
                    >
                      {selectSummaryData(item, index)}
                    </Table.Summary.Cell>
                  ))
                }
              </Table.Summary.Row>
            )}
          />
        </div>
      </div>

      <div className="mt-5 w-full flex flex-row items-center">
        <ButtonAccent
          isLoading={hourCapacityIsLoading || putHourCapacityIsLoading}
          isDisabled={isDetailMode}
          size="lg"
          title="Save As Draft"
          onClick={() => postHoursCapacities(true)}
        />

        <div className="ml-auto flex flex-row items-center gap-x-3">
          <ButtonAccent
            isBordered
            title="Back"
            onClick={() => goToPath('back')}
          />
          <ButtonAccent
            isLoading={hourCapacityIsLoading || putHourCapacityIsLoading}
            isDisabled={!isValid}
            title="Next"
            onClick={() => isDetailMode
              ? goToPath('next')
              : postHoursCapacities(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default CoalCapacity;