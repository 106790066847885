import moment from 'moment';
import { InputNumber } from 'antd';
import {
  every,
  isEmpty,
  sumBy,
  toLower
} from 'lodash';

import { EMPTY, UNIT } from 'constant';
import { generateFormat } from 'utils/PeriodUtility';
import {
  formatWithEnLocale,
  parseFromEnLocale,
  preventNonNumber
} from 'utils/CurrencyFormatter';

const formatThousand = (text) => {
  if (isNaN(text)) {
    return EMPTY.NUMBER;
  }

  return text
    ? Number(Number(text).toFixed(1)).toLocaleString('en-US')
    : EMPTY.NUMBER;
};

const stateKey = {
  ob: {
    key: 'ob',
    headerColumn: 'ob_header_column',
    headerData: 'ob_header_data',
    headerChange: 'ob_header_change',
    tableColumn: 'ob_table_column',
    tableData: 'ob_table_data',
    tableChange: 'ob_table_change',
    summaryColumn: 'ob_summary_column',
    summaryData: 'ob_summary_data'
  },
  coal: {
    key: 'coal',
    headerColumn: 'coal_header_column',
    headerData: 'coal_header_data',
    headerChange: 'coal_header_change',
    tableColumn: 'coal_table_column',
    tableData: 'coal_table_data',
    tableChange: 'coal_table_change',
    summaryColumn: 'coal_summary_column',
    summaryData: 'coal_summary_data'
  }
};

const headerColumns = [
  {
    dataIndex: 'description',
    title: 'Description',
    render: (text, record) => (
      <>
        {
          record.codeKey === 'holiday'
            ? (
              <span>
                {text}
                {' '}
                <span className="text-red-500">*</span>
              </span>
            )
            : text
        }
      </>
    )
  },
  { dataIndex: 'unit', title: 'Unit' }
];

const headerDefaultData = [
  { codeKey: 'calendar_days', description: 'Calendar Days', unit: 'Days' },
  { codeKey: 'holiday', description: 'Holiday', unit: 'Days' },
  { codeKey: 'working_days', description: 'Working Days', unit: 'Days' },
  { codeKey: 'total_hours', description: 'Total Hours', unit: 'Hours' },
  { codeKey: 'available_hours', description: 'Available Hours', unit: 'Hours' },
];

const generateHeaderColumns = (
  isDisabled,
  type,
  unit,
  periodName,
  periods,
  onChange
) => {
  const loweredUnit = toLower(unit);
  const isWeekly = toLower(periodName) === 'weekly';
  const isMonthly = toLower(periodName) === 'monthly';
  const isYearly = toLower(periodName) === 'yearly';
  const momentPeriod = (item) => moment(item, generateFormat(periods, loweredUnit, true, false));
  
  const generateDataIndex = (item) => momentPeriod(item)
    .format(generateFormat(periods, loweredUnit, false, true));
  
  const generateTitle = (item) => momentPeriod(item)
    .format(generateFormat(periods, loweredUnit, false, false));
  
  const isError = (record, period) => {
    /**
     * `holiday` value can be zero,
     * so we should explicitly check `null` or `undefined` value
     * */
    switch (true) {
      case isWeekly:
        return Boolean(record.week !== null && record.week !== undefined)
          ? EMPTY.STRING
          : 'error';
      case isMonthly:
        return Boolean(
          record[momentPeriod(periods[0], 'DD MMM YYYY').format('MMM_YYYY')] !== null
          && record[momentPeriod(periods[0], 'DD MMM YYYY').format('MMM_YYYY')] !== undefined
        )
          ? EMPTY.STRING
          : 'error';
      default:
        return Boolean(
          record[generateDataIndex(period)] !== null
          && record[generateDataIndex(period)] !== undefined
        )
          ? EMPTY.STRING
          : 'error';
    }
  };

  if (periods && periods.length) {
    if (isWeekly || isMonthly) {
      return [
        ...headerColumns,
        {
          dataIndex: isWeekly
            ? 'week'
            : moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY'),
          title: isWeekly
            ? 'Week'
            : moment(periods[0], 'DD MMM YYYY').format('MMM YYYY'),
          render: (text, record, index) => (
            <>
              {
                toLower(record.description) === 'holiday'
                  ? (
                    <InputNumber
                      key={`key-${isWeekly ? 'week' : 'monthly'}-${index + 1}`}
                      type="number"
                      disabled={isDisabled}
                      min={EMPTY.NUMBER}
                      value={isWeekly
                        ? record.week
                        : record[momentPeriod(periods[0], 'DD MMM YYYY').format('MMM_YYYY')]}
                      onChange={(ev) =>
                        onChange(
                          ev,
                          record,
                          isWeekly
                            ? 'week'
                            : momentPeriod(periods[0], 'DD MMM YYYY').format('MMM_YYYY')
                        )
                      }
                      status={isError(record)}
                      className="w-32"
                    />
                  )
                  : text
              }
            </>
          )
        }
      ];
    }

    return [
      ...headerColumns,
      ...periods.map((item) => {
        return {
          dataIndex: generateDataIndex(item),
          title: generateTitle(item),
          render: (text, record, index) => (
            <>
              {
                toLower(record.description) === 'holiday'
                  ? (
                    <InputNumber
                      key={`key-${type}-${index + 1}`}
                      type="number"
                      disabled={isDisabled}
                      min={EMPTY.NUMBER}
                      value={record[generateDataIndex(item)]}
                      onChange={(ev) => onChange(ev, record, generateDataIndex(item))}
                      status={isError(record, item)}
                      className={isYearly ? 'w-14': 'w-32'}
                    />
                  )
                  : text
              }
            </>
          )
        };
      })
    ];
  }

  return headerColumns;
};

const generateDefaultData = (
  description,
  periodName,
  periods,
  period,
  momentPeriod,
  data,
  dataKey,
  isExcludeSome
) => {
  let diffDays = 0;
  const selectedData = data?.contents?.find((content) => content.code === dataKey);
  const propSelectedData = selectedData?.properties?.find((propItem) => propItem.code === 'lost_time');
  
  if (period === 'week') {
    const momentStartDate = moment(
      momentPeriod(periods[0]),
      generateFormat(periods, 'days', true, false)
    );
    const momentEndDate = moment(
      momentPeriod(periods[periods.length - 1]),
      generateFormat(periods, 'days', true, false)
    );
    
    diffDays = (moment(momentEndDate).diff(momentStartDate, 'day') + 1);
  }
  
  switch (toLower(description)) {
    case 'calendar days': {
      const propCalendarDays = propSelectedData?.properties?.find((item) => item.code === 'calendar_days');
      
      if (period === 'week') {
        return propCalendarDays
          ? propCalendarDays.properties[0].value
          : Number(diffDays);
      }

      if (propCalendarDays) {
        const selectedDateData = propCalendarDays.properties.find((propItem) => propItem.date === momentPeriod(period).format('DD-MM-YYYY'));

        return selectedDateData
          ? selectedDateData.value
          : momentPeriod(period).endOf('months').format('DD');
      }

      return momentPeriod(period).endOf('months').format('DD');
    }
    case 'holiday': {
      const propHoliday = propSelectedData?.properties?.find((item) => item.code === 'holiday');

      if (period === 'week') {
        if (isExcludeSome) {
          return 0;
        }

        return propHoliday
          ? propHoliday.properties[0].value
          : 0;
      }

      if (propHoliday) {
        if (isExcludeSome) {
          return 0;
        }

        const selectedDateData = propHoliday.properties.find((propItem) => propItem.date === momentPeriod(period).format('DD-MM-YYYY'));

        return selectedDateData
          ? selectedDateData.value
          : momentPeriod(period).endOf('months').format('DD');
      }

      return 0;
    }
    case 'working days': {
      const propWorkingDays = propSelectedData?.properties?.find((item) => item.code === 'working_days');

      if (period === 'week') {
        return propWorkingDays
          ? propWorkingDays.properties[0].value
          : Number(diffDays);
      }

      if (propWorkingDays) {
        const selectedDateData = propWorkingDays.properties.find((propItem) => propItem.date === momentPeriod(period).format('DD-MM-YYYY'));

        return selectedDateData
          ? selectedDateData.value
          : Number(momentPeriod(period).endOf('months').format('DD'));
      }

      return Number(momentPeriod(period).endOf('months').format('DD'));
    }
    case 'total hours': {
      const propTotalHours = propSelectedData?.properties?.find((item) => item.code === 'total_hour' || item.code === 'total_hours');

      if (period === 'week') {
        return propTotalHours
          ? propTotalHours.properties[0].value
          : (Number(diffDays) * 24);
      }

      if (propTotalHours) {
        const selectedDateData = propTotalHours.properties.find((propItem) => propItem.date === momentPeriod(period).format('DD-MM-YYYY'));

        return selectedDateData
          ? selectedDateData.value
          : (Number(momentPeriod(period).endOf('months').format('DD')) * 24);
      }

      return (Number(momentPeriod(period).endOf('months').format('DD')) * 24);
    }
    case 'available hours': {
      const propAvailableHours = propSelectedData?.properties?.find((item) => item.code === 'available_hour' || item.code === 'available_hours');

      if (period === 'week') {
        return propAvailableHours
          ? propAvailableHours.properties[0].value
          : (Number(diffDays) * 24);
      }

      if (propAvailableHours) {
        const selectedDateData = propAvailableHours.properties.find((propItem) => propItem.date === momentPeriod(period).format('DD-MM-YYYY'));

        return selectedDateData
          ? selectedDateData.value
          : (Number(momentPeriod(period).endOf('months').format('DD')) * 24);
      }

      return (Number(momentPeriod(period).endOf('months').format('DD')) * 24);
    }
    default:
      return '';
  }
};

const generateHeaderDefaultData = (
  unit,
  periodName,
  periods,
  data,
  dataKey,
  isExcludeSome
) => {
  const loweredUnit = toLower(unit);
  const isWeekly = toLower(periodName) === 'weekly';
  const isMonthly = toLower(periodName) === 'monthly';
  const momentPeriod = (item) => moment(item, generateFormat(periods, loweredUnit, true, false));
  
  if (periods && periods.length) {
    return headerDefaultData.map((item) => {
      const newItem = { ...item };
      
      switch (true) {
        case isWeekly: {
          newItem.week = generateDefaultData(
            newItem.description,
            periodName,
            periods,
            'week',
            momentPeriod,
            data,
            dataKey,
            isExcludeSome
          );

          return newItem;
        }
        case isMonthly: {
          newItem[
            moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY')
          ] = generateDefaultData(
            newItem.description,
            periodName,
            null,
            periods[0],
            momentPeriod,
            data,
            dataKey,
            isExcludeSome
          );

          return newItem;
        }
        default: {
          periods.forEach((period) => {
            newItem[
              momentPeriod(period).format(generateFormat(periods, loweredUnit, false, true))
            ] = generateDefaultData(
              newItem.description,
              periodName,
              null,
              period,
              momentPeriod,
              data,
              dataKey,
              isExcludeSome
            );
          });

          return newItem;
        }
      }
    });
  }

  return headerDefaultData;
};

const calculateHeaderData = (
  data,
  item,
  value,
  dataIndex
) => {
  switch (toLower(item.description)) {
    case 'holiday':
      return {
        ...item,
        [dataIndex]: value
      };
    case 'working days': {
      const calendarDays = data.find((data) => toLower(data.description) === 'calendar days');
      return {
        ...item,
        [dataIndex]: (Number(calendarDays[dataIndex]) - Number(value))
      };
    }
    case 'available hours': {
      const calendarDays = data.find((data) => toLower(data.description) === 'calendar days');
      return {
        ...item,
        [dataIndex]: ((Number(calendarDays[dataIndex]) - Number(value)) * 24)
      };
    }
    default:
      return { ...item };
  }
};

const tableColumns = [
  {
    dataIndex: 'code',
    title: 'Code',
    width: '10%'
  },
  {
    dataIndex: 'description',
    title: 'Description',
    render: (text, record) => (
      <>
        {
          Boolean(record.codeKey[1] === 'rain'
            || record.codeKey[1] === 'slippery'
            || record.codeKey[1] === 'sholat_jum\'at'
            || record.codeKey[1] === 'safety_talk_mingguan'
            || record.codeKey[1] === 'safety_talk_bulanan'
          )
            ? (
              <span>
                {text}
                {' '}
                <span className="text-red-500">*</span>
              </span>
            )
            : text
        }
      </>
    )
  },
  {
    dataIndex: 'unit',
    title: 'Unit'
  },
  {
    dataIndex: 'remark',
    title: 'Remark'
  }
];

const tableDefaultData = [
  { code: 'Idle Time', codeKey: ['idle_time', 'rain'], description: 'Rain', unit: 'Hours', remark: 0 },
  { code: '', codeKey: ['idle_time', 'slippery'], description: 'Slippery', unit: 'Hours', remark: 0 },
  { code: '', codeKey: ['idle_time', 'change_shift'], description: 'Change Shift', unit: 'Hours', remark: 0 },
  { code: '', codeKey: ['idle_time', 'refueling'], description: 'Refueling', unit: 'Hours', remark: 0 },
  { code: '', codeKey: ['idle_time', 'break_&_rest'], description: 'Break & Rest', unit: 'Hours', remark: 0 },
  { code: '', codeKey: ['idle_time', 'pray'], description: 'Pray', unit: 'Hours', remark: 0 },
  { code: '', codeKey: ['idle_time', 'sholat_jum\'at'], description: 'Sholat Jum\'at (Friday Praying)', unit: 'Hours', remark: 0 },
  { code: '', codeKey: ['idle_time', 'blasting'], description: 'Blasting', unit: 'Hours', remark: 0 },
  { code: '', codeKey: ['idle_time', 'waiting_truck'], description: 'Waiting Truck', unit: 'Hours', remark: 0 },
  { code: '', codeKey: ['idle_time', 'p2h'], description: 'P2H', unit: 'Hours', remark: 0 },
  { code: '', codeKey: ['idle_time', 'front_repairing'], description: 'Front Repairing', unit: 'Hours', remark: 0 },
  { code: '', codeKey: ['idle_time', 'safety_talk_mingguan'], description: 'Safety Talk Mingguan (WST)', unit: 'Hours', remark: 0 },
  { code: '', codeKey: ['idle_time', 'safety_talk_bulanan'], description: 'Safety Talk Bulanan (GST)', unit: 'Hours', remark: 0 },
  { code: '', codeKey: ['idle_time', 'fog_&_dust'], description: 'Fog & Dust', unit: 'Hours', remark: 0 },
  { code: '', codeKey: ['idle_time', 'toilet'], description: 'Toilet', unit: 'Hours', remark: 0 },
  { code: '', codeKey: ['idle_time', 'stb'], description: 'STB/Wait. Equipment', unit: 'Hours', remark: 0 },
  { code: '', codeKey: ['idle_time', 'other_lost_time'], description: 'Other Lost Time', unit: 'Hours', remark: 0 },
  { code: 'Delay Time', codeKey: ['delay_time', 'travel_shift_change'], description: 'Travel-Shift-Change', unit: 'Hours', remark: 0 },
  { code: '', codeKey: ['delay_time', 'travel_maintenance'], description: 'Travel-Maintenance', unit: 'Hours', remark: 0 },
  { code: '', codeKey: ['delay_time', 'travel_blasting'], description: 'Travel-Blasting', unit: 'Hours', remark: 0 },
  { code: '', codeKey: ['delay_time', 'change_loading_point'], description: 'Change Loading Point', unit: 'Hours', remark: 0 },
  { code: '', codeKey: ['delay_time', 'waiting_truck'], description: 'Waiting Truck', unit: 'Hours', remark: 0 },
  { code: '', codeKey: ['delay_time', 'prepare_front'], description: 'Prepare Front', unit: 'Hours', remark: 0 },
  { code: '', codeKey: ['delay_time', 'engine_warm_up'], description: 'Engine Warm Up', unit: 'Hours', remark: 0 },
  { code: '', codeKey: ['delay_time', 'puasa'], description: 'Puasa (Fasting)', unit: 'Hours', remark: 0 },
  { code: '', codeKey: ['delay_time', 'others'], description: 'Others (Wait Survey, Transport, etc.)', unit: 'Hours', remark: 0 }
];

const generateTableColumns = (
  isDisabled,
  type,
  unit,
  periodName,
  periods,
  onChange
) => {
  const loweredUnit = toLower(unit);
  const isWeekly = toLower(periodName) === 'weekly';
  const isMonthly = toLower(periodName) === 'monthly';
  const isYearly = toLower(periodName) === 'yearly';
  const momentPeriod = (item) => moment(item, generateFormat(periods, loweredUnit, true, false));

  const generateDataIndex = (item) => momentPeriod(item)
    .format(generateFormat(periods, loweredUnit, false, true));

  const generateTitle = (item) => momentPeriod(item)
    .format(generateFormat(periods, loweredUnit, false, false));
  
  const generateUnitFormat = () => {
    switch (true) {
      case isWeekly:
        return 'week';
      case isMonthly:
        return ['MMM YYYY', 'MMM_YYYY'];
      default:
        return loweredUnit === UNIT.DAY
          ? ['DD MMM YYYY', 'DD_MMM_YYYY']
          : ['MMM YYYY', 'MMM_YYYY'];
    }
  };
  
  const generateDynamicColumns = () => {
    if (isWeekly) {
      return [{
        dataIndex: 'week',
        title: 'Week',
        render: (text) => (
          <span>
            {formatThousand(text)}
          </span>
        )
      }];
    }
    
    return isMonthly
      ? [{
        dataIndex: moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY'),
        title:  moment(periods[0], 'DD MMM YYYY').format('MMM YYYY'),
        render: (text) => (
          <span>
            {formatThousand(text)}
          </span>
        )
      }]
      : periods.map((item) => {
        return {
          dataIndex: generateDataIndex(item),
          title: generateTitle(item),
          render: (text) => (
            <span>
              {formatThousand(text)}
            </span>
          )
        };
      });
  };

  const isError = (record) => {
    /**
     * every value can be zero,
     * so we should explicitly check `null` or `undefined` value
     * */
    switch (true) {
      case record.codeKey[1] === 'rain':
      case record.codeKey[1] === 'slippery':
      case record.codeKey[1] === 'sholat_jum\'at':
      case record.codeKey[1] === 'safety_talk_mingguan':
      case record.codeKey[1] === 'safety_talk_bulanan':
        return Boolean(record.remark !== null && record.remark !== undefined)
          ? EMPTY.STRING
          : 'error';
      default:
        return EMPTY.STRING;
    }
  };
  
  const transformSpecificUnit = (record) => {
    switch (true) {
      case record.codeKey[1] === 'sholat_jum\'at':
      case record.codeKey[1] === 'safety_talk_mingguan':
        return isWeekly
          ? 'hrs/week'
          : 'hrs/month';
      case record.codeKey[1] === 'p2h':
        return 'minutes/shift';
      default:
        return 'hrs/day';
    }
  };
  
  if (periods && periods.length) {
    return [
      ...tableColumns.map((item) => {
        if (item.dataIndex === 'remark') {
          return {
            dataIndex: 'remark',
            title: 'Remark',
            render: (text, record, index) => (
              <div className="flex flex-row items-center gap-x-3">
                <InputNumber
                  key={`key-${type}-${index + 1}`}
                  disabled={isDisabled}
                  min={EMPTY.NUMBER}
                  value={record.remark}
                  onChange={(ev) => onChange(ev, record, generateUnitFormat(), type)}
                  onKeyDown={preventNonNumber}
                  status={isError(record)}
                  parser={parseFromEnLocale}
                  formatter={formatWithEnLocale}
                  className={isYearly ? 'w-14' : 'w-32'}
                />
                <span>{transformSpecificUnit(record)}</span>
              </div>
            )
          };
        }
        
        return { ...item };
      }),
      ...generateDynamicColumns()
    ];
  }
  
  return tableColumns;
};

const generateTableDefaultData = (
  unit,
  periodName,
  periods,
  data,
  dataKey,
  isExcludeSome
) => {
  const loweredUnit = toLower(unit);
  const isWeekly = toLower(periodName) === 'weekly';
  const isMonthly = toLower(periodName) === 'monthly';
  const momentPeriod = (item) => moment(item, generateFormat(periods, loweredUnit, true, false));
  const excludedKeys = ['rain', 'slippery', 'sholat_jum\'at', 'safety_talk_mingguan', 'safety_talk_bulanan'];

  const selectedData = data?.contents?.find((content) => content.code === dataKey);
  const propSelectedData = selectedData?.properties?.find((propItem) => propItem.code === 'production_lost_time');

  if (periods && periods.length) {
    return tableDefaultData.map((item) => {
      const newItem = { ...item };
      
      switch (true) {
        case isWeekly: {
          if (propSelectedData) {
            const selectedCodeData = propSelectedData.properties.find((propItem) => propItem.code === newItem.codeKey[0]);
            const selectedCodePropData = selectedCodeData.properties.find((propItem) => propItem.code === newItem.codeKey[1]);

            newItem.remark = Boolean(isExcludeSome && excludedKeys.some((key) => selectedCodePropData.code === key))
              ? undefined
              : selectedCodePropData.value;
            newItem.week = 0;

            return newItem;
          }

          newItem.remark = excludedKeys.some((key) => newItem.codeKey[1] === key)
            ? undefined
            : 0;
          newItem.week = 0;

          return newItem;
        }
        case isMonthly: {
          if (propSelectedData) {
            const selectedCodeData = propSelectedData.properties.find((propItem) => propItem.code === newItem.codeKey[0]);
            const selectedCodePropData = selectedCodeData.properties.find((propItem) => propItem.code === newItem.codeKey[1]);

            newItem.remark = Boolean(isExcludeSome && excludedKeys.some((key) => selectedCodePropData.code === key))
              ? undefined
              : selectedCodePropData.value;
            newItem[moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY')] = 0;

            return newItem;
          }

          newItem.remark = excludedKeys.some((key) => newItem.codeKey[1] === key)
            ? undefined
            : 0;
          newItem[moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY')] = 0;

          return newItem;
        }
        default: {
          if (propSelectedData) {
            const selectedCodeData = propSelectedData.properties.find((propItem) => propItem.code === newItem.codeKey[0]);
            const selectedCodePropData = selectedCodeData.properties.find((propItem) => propItem.code === newItem.codeKey[1]);

            newItem.remark = Boolean(isExcludeSome && excludedKeys.some((key) => selectedCodePropData.code === key))
              ? undefined
              : selectedCodePropData.value;
            periods.forEach((period) => {
              newItem[
                momentPeriod(period).format(generateFormat(periods, loweredUnit, false, true))
              ] = 0;
            });

            return newItem;
          }

          newItem.remark = excludedKeys.some((key) => newItem.codeKey[1] === key)
            ? undefined
            : 0;
          periods.forEach((period) => {
            newItem[
              momentPeriod(period).format(generateFormat(periods, loweredUnit, false, true))
            ] = 0;
          });

          return newItem;
        }
      }
    });
  }

  return tableDefaultData;
};

const tableSummaries = [
  { codeKey: 'total_lost_time', key: 'lostTime', title: 'Production Lost Time', unit: 'Hours', period: 'Monthly' },
  { codeKey: 'avg_effective_working_hours', key: 'avgEffectiveMonthly', title: 'Avg. Effective Working Hours', unit: 'Hours', period: 'Monthly' },
  { codeKey: 'avg_effective_working_hours', key: 'avgEffectiveDaily', title: 'Avg. Effective Working Hours', unit: 'Hours', period: 'Daily' },
  { codeKey: 'dry_weather_working_hr', key: 'dryWorkingMonthly', title: 'Dry Weather Working Hr.', unit: 'Hours', period: 'Monthly' },
  { codeKey: 'dry_weather_working_hr', key: 'dryWorkingDaily', title: 'Dry Weather Working Hr.', unit: 'Hours', period: 'Daily' }
];

const generateSummaryColumns = (periodName, periods) => {
  const isWeekly = toLower(periodName) === 'weekly';
  const isMonthly = toLower(periodName) === 'monthly';

  if (periods && periods.length) {
    if (isWeekly) {
      return ['week'];
    }

    return isMonthly
      ? [moment(periods[0], 'DD MMM YYYY').format('MMM YYYY')]
      : periods;
  }

  return [];
};

const generateTableSummaries = (unit, periodName, periods) => {
  const loweredUnit = toLower(unit);
  const isWeekly = toLower(periodName) === 'weekly';
  const isMonthly = toLower(periodName) === 'monthly';
  const momentPeriod = (item) => moment(item, generateFormat(periods, loweredUnit, true, false));

  if (periods && periods.length) {
    return tableSummaries.map((item) => {
      const newItem = { ...item };
      
      switch (true) {
        case isWeekly: {
          newItem.week = 0;

          return newItem;
        }
        case isMonthly: {
          newItem[moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY')] = 0;

          return newItem;
        }
        default: {
          periods.forEach((period) => {
            newItem[
              momentPeriod(period).format(generateFormat(periods, loweredUnit, false, true))
            ] = 0;
          });

          return newItem;
        }
      }
    });
  }

  return tableSummaries;
};

const pickSummaryData = (unit, periodName, period, item) => {
  const isWeekly = toLower(periodName) === 'weekly';
  const isMonthly = toLower(periodName) === 'monthly';
  const momentPeriod = (unit, period) => {
    return moment(period, toLower(unit) === UNIT.DAY ? 'DD MMM YYYY' : 'MMM YYYY')
      .format(toLower(unit) === UNIT.DAY ? 'DD_MMM_YYYY' : 'MMM_YYYY');
  };

  const reduceFractionDigit = (value) => {
    if (value) {
      return Number(value.toFixed(1)).toLocaleString('en-US');
    }

    return isNaN(value)
      ? EMPTY.NUMBER
      : value;
  };

  if (isWeekly) {
    return reduceFractionDigit(item.week);
  }

  return isMonthly
    ? reduceFractionDigit(item[moment(period, 'MMM YYYY').format('MMM_YYYY')])
    : reduceFractionDigit(item[momentPeriod(unit, period)]);
};

const calculateSummary = (
  isOb,
  unit,
  periodName,
  periods,
  obHeaderData,
  obTableData,
  coalHeaderData,
  coalTableData,
  key,
  item,
  momentPeriod
) => {
  const isWeekly = toLower(periodName) === 'weekly';
  const isMonthly = toLower(periodName) === 'monthly';
  const currentMonth = moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY');
  const selectedData = isOb ? obTableData : coalTableData;
  const availableHours = (isOb ? obHeaderData : coalHeaderData)
    .find((item) => toLower(item.description) === 'available hours');
  const workingDays = (isOb ? obHeaderData : coalHeaderData)
    .find((item) => toLower(item.description) === 'working days');
  const rain = (isOb ? obTableData : coalTableData)
    .find((item) => toLower(item.description) === 'rain');
  const slippery = (isOb ? obTableData : coalTableData)
    .find((item) => toLower(item.description) === 'slippery');

  if (isEmpty(selectedData)
    || isEmpty(availableHours)
    || isEmpty(workingDays)
    || isEmpty(rain)
    || isEmpty(slippery)
  ) {
    return { ...item };
  }

  switch (key) {
    case 'lostTime': {
      const newItem = { ...item };

      switch (true) {
        case isWeekly: {
          newItem.week = sumBy(selectedData, (tData) => tData.week);

          return newItem;
        }
        case isMonthly: {
          newItem[currentMonth] = sumBy(selectedData, (tData) => tData[currentMonth]);

          return newItem;
        }
        default: {
          periods.forEach((period) => {
            newItem[momentPeriod(unit, period)] =
              sumBy(selectedData, (tData) => tData[momentPeriod(unit, period)]);
          });

          return newItem;
        }
      }
    }
    case 'avgEffectiveMonthly': {
      const newItem = { ...item };

      switch (true) {
        case isWeekly: {
          newItem.week = (Number(availableHours.week) - sumBy(selectedData, (tData) => tData.week));

          return newItem;
        }
        case isMonthly: {
          newItem[currentMonth] =
            (
              Number(availableHours[currentMonth])
              - sumBy(selectedData, (tData) => tData[currentMonth])
            );

          return newItem;
        }
        default: {
          periods.forEach((period) => {
            newItem[momentPeriod(unit, period)] =
              (
                Number(availableHours[momentPeriod(unit, period)])
                - sumBy(selectedData, (tData) => tData[momentPeriod(unit, period)])
              );
          });

          return newItem;
        }
      }
    }
    case 'avgEffectiveDaily': {
      const newItem = { ...item };

      switch (true) {
        case isWeekly: {
          newItem.week = (
            (Number(availableHours.week)
              - sumBy(selectedData, (tData) => tData.week))
            / Number(workingDays.week)
          );

          return newItem;
        }
        case isMonthly: {
          newItem[currentMonth] =
            (
              (Number(availableHours[currentMonth])
                - sumBy(selectedData, (tData) => tData[currentMonth]))
              / Number(workingDays[currentMonth])
            );

          return newItem;
        }
        default: {
          periods.forEach((period) => {
            newItem[momentPeriod(unit, period)] =
              (
                (Number(availableHours[momentPeriod(unit, period)])
                  - sumBy(selectedData, (tData) => tData[momentPeriod(unit, period)]))
                / Number(workingDays[momentPeriod(unit, period)])
              );
          });

          return newItem;
        }
      }
    }
    case 'dryWorkingMonthly': {
      const newItem = { ...item };

      switch (true) {
        case isWeekly: {
          newItem.week =
            (
              Number(availableHours.week)
              - sumBy(selectedData, (tData) => tData.week)
              + Number(rain.week)
              + Number(slippery.week)
            );

          return newItem;
        }
        case isMonthly: {
          newItem[currentMonth] =
            (
              Number(availableHours[currentMonth])
              - sumBy(selectedData, (tData) => tData[currentMonth])
              + Number(rain[currentMonth])
              + Number(slippery[currentMonth])
            );

          return newItem;
        }
        default: {
          periods.forEach((period) => {
            newItem[momentPeriod(unit, period)] =
              (
                Number(availableHours[momentPeriod(unit, period)])
                - sumBy(selectedData, (tData) => tData[momentPeriod(unit, period)])
                + Number(rain[momentPeriod(unit, period)])
                + Number(slippery[momentPeriod(unit, period)])
              );
          });

          return newItem;
        }
      }
    }
    case 'dryWorkingDaily': {
      const newItem = { ...item };

      switch (true) {
        case isWeekly: {
          newItem.week =
            (
              (Number(availableHours.week)
                - sumBy(selectedData, (tData) => tData.week)
                + Number(rain.week)
                + Number(slippery.week))
              / Number(workingDays.week)
            );

          return newItem;
        }
        case isMonthly: {
          newItem[currentMonth] =
            (
              (Number(availableHours[currentMonth])
                - sumBy(selectedData, (tData) => tData[currentMonth])
                + Number(rain[currentMonth])
                + Number(slippery[currentMonth]))
              / Number(workingDays[currentMonth])
            );

          return newItem;
        }
        default: {
          periods.forEach((period) => {
            newItem[momentPeriod(unit, period)] =
              (
                (Number(availableHours[momentPeriod(unit, period)])
                  - sumBy(selectedData, (tData) => tData[momentPeriod(unit, period)])
                  + Number(rain[momentPeriod(unit, period)])
                  + Number(slippery[momentPeriod(unit, period)]))
                / Number(workingDays[momentPeriod(unit, period)])
              );
          });

          return newItem;
        }
      }
    }
    default:
      return { ...item };
  }
};

const validateData = (
  unit,
  periodName,
  periods,
  obHeaderData,
  coalHeaderData,
  obTableData,
  coalTableData
) => {
  const loweredUnit = toLower(unit);
  const isWeekly = toLower(periodName) === 'weekly';
  const isMonthly = toLower(periodName) === 'monthly';

  const momentPeriod = (item) => moment(item, generateFormat(periods, loweredUnit, true, false));
  
  if (isEmpty(obHeaderData)
    || isEmpty(coalHeaderData)
    || isEmpty(obTableData)
    || isEmpty(coalTableData)) {
    return false;
  }

  const transformedValidationData = () => {
    const obHeaderHoliday = obHeaderData.find((item) => item.codeKey === 'holiday');
    const coalHeaderHoliday = coalHeaderData.find((item) => item.codeKey === 'holiday');
    const obValidations = obTableData.filter((item) => item.codeKey[1] === 'rain'
      || item.codeKey[1] === 'slippery'
      || item.codeKey[1] === 'sholat_jum\'at'
      || item.codeKey[1] === 'safety_talk_mingguan'
      || item.codeKey[1] === 'safety_talk_bulanan'
    );
    const coalValidations = coalTableData.filter((item) => item.codeKey[1] === 'rain'
      || item.codeKey[1] === 'slippery'
      || item.codeKey[1] === 'sholat_jum\'at'
      || item.codeKey[1] === 'safety_talk_mingguan'
      || item.codeKey[1] === 'safety_talk_bulanan'
    );
    
    return {
      obHeader: obHeaderHoliday,
      coalHeader: coalHeaderHoliday,
      obTable: obValidations,
      coalTable: coalValidations
    };
  };

  if (periods && periods.length) {
    const dataToValidate = transformedValidationData();
    switch (true) {
      /**
       * every value can be zero,
       * so we should explicitly check `null` or `undefined` value
       * */
      case isWeekly: {
        const validations = [
          Boolean(dataToValidate.obHeader.week !== null && dataToValidate.obHeader.week !== undefined),
          Boolean(dataToValidate.coalHeader.week !== null && dataToValidate.coalHeader.week !== undefined),
          every(dataToValidate.obTable.map((item) => Boolean(item.remark !== null && item.remark !== undefined))),
          every(dataToValidate.coalTable.map((item) => Boolean(item.remark !== null && item.remark !== undefined)))
        ];
        
        return every(validations);
      }
      case isMonthly: {
        const validations = [
          Boolean(
            dataToValidate.obHeader[moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY')] !== null
            && dataToValidate.obHeader[moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY')] !== undefined
          ),
          Boolean(
            dataToValidate.coalHeader[moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY')] !== null
            && dataToValidate.coalHeader[moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY')] !== undefined
          ),
          every(dataToValidate.obTable.map((item) => Boolean(item.remark !== null && item.remark !== undefined))),
          every(dataToValidate.coalTable.map((item) => Boolean(item.remark !== null && item.remark !== undefined)))
        ];

        return every(validations);
      }
      default: {
        const validations = [
          every(
            periods.map((period) =>
              dataToValidate.obHeader[momentPeriod(period).format(generateFormat(periods, loweredUnit, false, true))] !== null
              && dataToValidate.obHeader[momentPeriod(period).format(generateFormat(periods, loweredUnit, false, true))] !== undefined
            )),
          every(
            periods.map((period) =>
              dataToValidate.coalHeader[momentPeriod(period).format(generateFormat(periods, loweredUnit, false, true))] !== null
              && dataToValidate.coalHeader[momentPeriod(period).format(generateFormat(periods, loweredUnit, false, true))] !== undefined
            )),
          every(dataToValidate.obTable.map((item) => Boolean(item.remark !== null && item.remark !== undefined))),
          every(dataToValidate.coalTable.map((item) => Boolean(item.remark !== null && item.remark !== undefined)))
        ];

        return every(validations);
      }
    }
  }
};

const generateBody = (
  isDraft,
  id,
  periods,
  periodName,
  unit,
  obHeaders,
  obTables,
  obSummaries,
  coalHeaders,
  coalTables,
  coalSummaries,
  momentPeriod
) => {
  const isWeekly = periodName === 'weekly';
  const isMonthly = periodName === 'monthly';

  const selectProductionValue = (item, periods, period) => {
    switch (true) {
      case isWeekly:
        return Number(item.week);
      case isMonthly:
        return Number(item[moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY')]);
      default:
        return Number(item[momentPeriod(unit, period)]);
    }
  };
  
  const selectSummaryValue = (isOb, codeKey, periodType, periods, period) => {
    const selectedData = (isOb ? obSummaries : coalSummaries)
      .find((item) => item.codeKey === codeKey && toLower(item.period) === toLower(periodType));

    switch (true) {
      case isWeekly:
        return selectedData.week;
      case isMonthly:
        return selectedData[moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY')];
      default:
        return selectedData[momentPeriod(unit, period)];
    }
  };
  
  return {
    assumption_id: id,
    is_draft: isDraft,
    contents: [
      {
        code: 'production_lost_time_ob',
        properties: [
          {
            code: 'lost_time',
            properties: obHeaders.map((item) => ({
              code: item.codeKey,
              properties: periods.map((period) => ({
                type: periodName,
                date: moment(period, generateFormat(periods, toLower(unit), true, false))
                  .format('DD-MM-YYYY'),
                value: selectProductionValue(item, periods, period)
              }))
            }))
          },
          {
            code: 'production_lost_time',
            properties: [
              {
                code: 'idle_time',
                properties: obTables
                  .filter((item) => item.codeKey[0] === 'idle_time')
                  .map((item) => ({
                    code: item.codeKey[1],
                    value: item.remark,
                    unit: item.codeKey[1] === 'p2h' ? 'minute/shift' : 'hrs/day'
                  }))
              },
              {
                code: 'delay_time',
                properties: obTables
                  .filter((item) => item.codeKey[0] === 'delay_time')
                  .map((item) => ({
                    code: item.codeKey[1],
                    value: item.remark,
                    unit: 'hrs/day'
                  }))
              }
            ]
          },
          {
            code: 'total_lost_time',
            properties: [
              {
                code: 'monthly',
                properties: periods.map((period) => ({
                  type: periodName,
                  date: moment(period, generateFormat(periods, toLower(unit), true, false))
                    .format('DD-MM-YYYY'),
                  value: selectSummaryValue(
                    true,
                    'total_lost_time',
                    'monthly',
                    periods,
                    period
                  )
                }))
              }
            ]
          },
          {
            code: 'avg_effective_working_hours',
            properties: [
              {
                code: 'monthly',
                properties: periods.map((period) => ({
                  type: periodName,
                  date: moment(period, generateFormat(periods, toLower(unit), true, false))
                    .format('DD-MM-YYYY'),
                  value: selectSummaryValue(
                    true,
                    'avg_effective_working_hours',
                    'monthly',
                    periods,
                    period
                  )
                }))
              },
              {
                code: 'daily',
                properties: periods.map((period) => ({
                  type: periodName,
                  date: moment(period, generateFormat(periods, toLower(unit), true, false))
                    .format('DD-MM-YYYY'),
                  value: selectSummaryValue(
                    true,
                    'avg_effective_working_hours',
                    'daily',
                    periods,
                    period
                  )
                }))
              }
            ]
          },
          {
            code: 'dry_weather_working_hr',
            properties: [
              {
                code: 'monthly',
                properties: periods.map((period) => ({
                  type: periodName,
                  date: moment(period, generateFormat(periods, toLower(unit), true, false))
                    .format('DD-MM-YYYY'),
                  value: selectSummaryValue(
                    true,
                    'dry_weather_working_hr',
                    'monthly',
                    periods,
                    period
                  )
                }))
              },
              {
                code: 'daily',
                properties: periods.map((period) => ({
                  type: periodName,
                  date: moment(period, generateFormat(periods, toLower(unit), true, false))
                    .format('DD-MM-YYYY'),
                  value: selectSummaryValue(
                    true,
                    'dry_weather_working_hr',
                    'daily',
                    periods,
                    period
                  )
                }))
              }
            ]
          }
        ]
      },
      {
        code: 'production_lost_time_coal',
        properties: [
          {
            code: 'lost_time',
            properties: coalHeaders.map((item) => ({
              code: item.codeKey,
              properties: periods.map((period) => ({
                type: periodName,
                date: moment(period, generateFormat(periods, toLower(unit), true, false))
                  .format('DD-MM-YYYY'),
                value: selectProductionValue(item, periods, period)
              }))
            }))
          },
          {
            code: 'production_lost_time',
            properties: [
              {
                code: 'idle_time',
                properties: coalTables
                  .filter((item) => item.codeKey[0] === 'idle_time')
                  .map((item) => ({
                    code: item.codeKey[1],
                    value: item.remark,
                    unit: item.codeKey[1] === 'p2h' ? 'minute/shift' : 'hrs/day'
                  }))
              },
              {
                code: 'delay_time',
                properties: coalTables
                  .filter((item) => item.codeKey[0] === 'delay_time')
                  .map((item) => ({
                    code: item.codeKey[1],
                    value: item.remark,
                    unit: 'hrs/day'
                  }))
              }
            ]
          },
          {
            code: 'total_lost_time',
            properties: [
              {
                code: 'monthly',
                properties: periods.map((period) => ({
                  type: periodName,
                  date: moment(period, generateFormat(periods, toLower(unit), true, false))
                    .format('DD-MM-YYYY'),
                  value: selectSummaryValue(
                    false,
                    'total_lost_time',
                    'monthly',
                    periods,
                    period
                  )
                }))
              }
            ]
          },
          {
            code: 'avg_effective_working_hours',
            properties: [
              {
                code: 'monthly',
                properties: periods.map((period) => ({
                  type: periodName,
                  date: moment(period, generateFormat(periods, toLower(unit), true, false))
                    .format('DD-MM-YYYY'),
                  value: selectSummaryValue(
                    false,
                    'avg_effective_working_hours',
                    'monthly',
                    periods,
                    period
                  )
                }))
              },
              {
                code: 'daily',
                properties: periods.map((period) => ({
                  type: periodName,
                  date: moment(period, generateFormat(periods, toLower(unit), true, false))
                    .format('DD-MM-YYYY'),
                  value: selectSummaryValue(
                    false,
                    'avg_effective_working_hours',
                    'daily',
                    periods,
                    period
                  )
                }))
              }
            ]
          },
          {
            code: 'dry_weather_working_hr',
            properties: [
              {
                code: 'monthly',
                properties: periods.map((period) => ({
                  type: periodName,
                  date: moment(period, generateFormat(periods, toLower(unit), true, false))
                    .format('DD-MM-YYYY'),
                  value: selectSummaryValue(
                    false,
                    'dry_weather_working_hr',
                    'monthly',
                    periods,
                    period
                  )
                }))
              },
              {
                code: 'daily',
                properties: periods.map((period) => ({
                  type: periodName,
                  date: moment(period, generateFormat(periods, toLower(unit), true, false))
                    .format('DD-MM-YYYY'),
                  value: selectSummaryValue(
                    false,
                    'dry_weather_working_hr',
                    'daily',
                    periods,
                    period
                  )
                }))
              }
            ]
          }
        ]
      }
    ]
  };
};

export {
  headerColumns,
  headerDefaultData,
  tableColumns,
  tableDefaultData,
  stateKey,
  generateHeaderColumns,
  generateHeaderDefaultData,
  calculateHeaderData,
  generateTableColumns,
  generateTableDefaultData,
  generateSummaryColumns,
  generateTableSummaries,
  calculateSummary,
  generateBody,
  pickSummaryData,
  validateData
};