
import { generateThicknessData, generateThicknessTable } from 'constant/TableCoreRecovery/TableThickness';
import TableDrillingSumarry from 'pages/DrillingPlan/DrillingSummary/TableDrillingSummary';

const Thickness = (props) => {
  const { isDetailMode, payload, onChange, onAdd, onDeleteRow, onAddSampleId, deleteSampleId } = props;
  const tableData = generateThicknessData(payload);
  const columns = generateThicknessTable(
    isDetailMode,
    tableData, 
    onChange, 
    onAdd, 
    onDeleteRow,
    onAddSampleId,
    deleteSampleId
  );
  return <TableDrillingSumarry columns={columns} tableData={tableData}/>;
};

export default Thickness;