import { Base } from './Base';

const MainPlanScenario = Base.injectEndpoints({
  endpoints: (build) => ({
    getMainPlanScenarioById: build.query({
      query: ({ id, params }) => ({
        url: `/main-plan-scenarios/${id}`,
        method: 'GET',
        ...params
          ? { params }
          : undefined
      })
    }),
    postMainPlanScenario: build.mutation({
      query: (body) => ({
        url: '/main-plan-scenarios',
        method: 'POST',
        body
      })
    }),
    putMainPlanScenario: build.mutation({
      query: ({ id, body }) => ({
        url: `/main-plan-scenarios/${id}`,
        method: 'PUT',
        body
      })
    })
  })
});

export const {
  useLazyGetMainPlanScenarioByIdQuery,
  usePostMainPlanScenarioMutation,
  usePutMainPlanScenarioMutation
} = MainPlanScenario;