import { Input } from 'antd';
import { isEmpty, isNumber } from 'lodash';
import { GoPlus } from 'react-icons/go';
import { RxCross2 } from 'react-icons/rx';

const generateCoalSeamRecoveryTable = (isDetailMode, payload, onAddSampleId, onDeleteSampleId, onDeleteRow, onChange) => [
  {
    dataIndex: 'action',
    title: 'Action',
    width: 5,
    sorter: false,
    align: 'center',
    fixed: 'left',
    render: (_, record, index) => {
      let isParent = record?.hasOwnProperty('drill_hole_name');   
      let dataDrillHoleName = payload.filter(item => item?.hasOwnProperty('drill_hole_name')); 
      return (
        <div className="flex justify-center">
          {payload.length <= 1 || dataDrillHoleName.length <= 1 ? (
            <></>
          ) : (isParent && !isDetailMode) && (
            <RxCross2
              size={28}
              color={'red'}
              className={'rounded-full p-1 cursor-pointer bg-red-200 '}
              onClick={() => {
                onDeleteRow(record);
              }}
            />
          )}
        </div>
      );
    },
  },
  {
    dataIndex: 'drill_hole_name',
    title: 'Drill Hole Name',
    width: '166px',
    align: 'center',
    fixed: 'left',
    sorter: false,
    render: (value) => {
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {value}
        </div>
      );
    },
  },
  {
    dataIndex: 'sample_id',
    title: 'Sample ID',
    width: '166px',
    align: 'center',
    fixed: 'left',
    sorter: false,
    render: (value, record) => {
      let isParent = record?.hasOwnProperty('drill_hole_name');
      return (
        <div className='flex flex-row gap-2'>
          {
            !isDetailMode && 
            <>
              {!isParent? (
                <RxCross2
                  size={28}
                  color={'red'}
                  className={'rounded-full p-1 cursor-pointer bg-red-200 '}
                  onClick={() => onDeleteSampleId(record)}
                />
              ):(
                <GoPlus
                  size={28}
                  color={'green'}
                  className={'rounded-full p-1 cursor-pointer bg-green-200'}
                  onClick={onAddSampleId} 
                />
              )}
            </>
          }
          <div className='w-full flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
            {value}
          </div>
        </div>
      );
    },
  },
  {
    dataIndex: 'coal_seam',
    title: 'Coal Seam',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, record) => {
      return (
        <Input
          disabled={isDetailMode}
          placeholder={'Enter Coal Seam'}
          value={value}
          onChange={(e) => {
            onChange('coal_seam_recovery', record?.localId, record?.localChildrenId, 'coal_seam', e.target.value);
          }}
        /> 
      );
    },
  },
  {
    dataIndex: 'coal_recovery',
    title: '% Coal Recovery',
    width: '166px',
    align: 'center',
    fixed: 'left',
    sorter: false,
    render: (value) => {
      const populateValue= ()=>{
        if(isNumber(value)){
          let finalResult = value * 100;
          if(Number(finalResult) === finalResult && finalResult % 1 !== 0){
            return `${finalResult.toFixed(2)}%`;
          }else {
            return `${finalResult}%`;
          }
        }
        return value;
      };
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {populateValue()}
        </div>
      );
    },
  },
];

const populateCoalRecoveryFormula = (drillThickness, geoThickness) => {
  if(drillThickness === null || !geoThickness) {
    return 'Incomplete Data';
  } else {
    return Number(drillThickness / geoThickness);
  }
};

const generateCoalSeamRecoveryData = (data) => {
  if(!isEmpty(data)) {
    let dataCoalSeamRecovery = data?.find(item => item.coal_seam_recovery)?.coal_seam_recovery;
    let result = [];
    dataCoalSeamRecovery?.forEach((coalSeam, indexSample) => {
      coalSeam?.children?.forEach((child, index) => {
        result.push({
          localId: coalSeam?.localId || indexSample + 1,
          ...(index === 0) && {drill_hole_name:coalSeam.drill_hole_name},
          ...child
        });
      });
    });
    return result;
  }
};

export { generateCoalSeamRecoveryTable, generateCoalSeamRecoveryData, populateCoalRecoveryFormula };