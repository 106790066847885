import { Navigate } from 'react-router';
import { URL } from 'constant';

import Splash from 'pages/Splash';
import Login from 'pages/Login';
import Base from 'pages/Base';
import Assumption from 'pages/Assumption';
import AssumptionForm from 'pages/Assumption/Form';
import AssumptionProduction from 'pages/Assumption/Production';
import AssumptionOverBurdenUnit from 'pages/Assumption/OverBurdenUnit';
import AssumptionOverBurdenCapacity from 'pages/Assumption/OverBurdenCapacity';
import AssumptionCoalUnit from 'pages/Assumption/CoalUnit';
import AssumptionCoalCapacity from 'pages/Assumption/CoalCapacity';
import AssumptionPitReserve from 'pages/Assumption/PitReserve';
import AssumptionMainPlanScenario from 'pages/Assumption/MainPlanScenario';
import AssumptionOverBurdenHauler from 'pages/Assumption/OverBurdenHauler';
import AssumptionSummary from 'pages/Assumption/Summary';
import ConfidentLevel from 'pages/ConfidentLevel';
import ConfidentLevelSubmissionPIC from 'pages/ConfidentLevel/Submission/PersonInCharge';
import ConfidentLevelSubmissionMinePlan from 'pages/ConfidentLevel/Submission/MainPlan';
import ConfidentLevelInitiation from 'pages/ConfidentLevel/Initiation';
import UserManagement from 'pages/UserManagement';
import GroupManagement from 'pages/GroupManagement';
import UserForm from 'pages/UserManagement/Form';
import GroupForm from 'pages/GroupManagement/Form';
import Profile from 'pages/Profile';
import NotFound from 'pages/NotFound';
import CreatePassword from 'pages/CreatePassword';
import Empty from 'pages/Empty';
import ActivityLevel from 'pages/ActivityLevel';
import DrillingPlan from 'pages/DrillingPlan';
import QualityChecklist from 'pages/DrillingPlan/QualityChecklist';
import ComingSoon from 'pages/ComingSoon';
import DrillingForm from 'pages/DrillingPlan/Form';
import PermitTimeline from 'pages/DrillingPlan/PermitTimeline';
import DrillingSummary from 'pages/DrillingPlan/DrillingSummary';
import SummaryDrilling from 'pages/DrillingActual';
import InputActualForm from 'pages/DrillingActual/Form';
import DrillingSummaryActual from 'pages/DrillingActual/DrillingSummary';
import Reconciliation from 'pages/Reconciliation';
import InputReconciliation from 'pages/Reconciliation/InputActual';
import ReconciliationForm from 'pages/Reconciliation/Form';
import GoeDashboard from 'pages/GoeDashboard';
import CoreRecoveryForm from 'pages/CoreRecovery/Form';
import CoreRecovery from 'pages/CoreRecovery';
import KeyHighLightImprovement from 'pages/KeyHighlightImprovement';
import InputCoreRecovery from 'pages/CoreRecovery/InputActual';
import TopoCollarValidation from 'pages/TopoCollarValidation';
import TopoCollarValidationForm from 'pages/TopoCollarValidation/Form';
import InputTopoCollarValidation from 'pages/TopoCollarValidation/InputActual';
import ModelDrillHoleValidation from 'pages/ModelDrillHoleValidation';
import ModelDrillHoleValidationForm from 'pages/ModelDrillHoleValidation/Form';
import InputModelDrillHoleValidation from 'pages/ModelDrillHoleValidation/InputActual';
import MaintenanceOmeAP from 'pages/Maintenance/OmeAP';
import MaintenancePlanStop from 'pages/Maintenance/PlanStop';
import MaintenanceOE from 'pages/Maintenance/OE';
import MaintenanceOmeQ from 'pages/Maintenance/OmeQ';

const createRoutes = () => [
  {
    path: '',
    element: <Navigate to="/splash" />
  },
  {
    path: 'splash',
    element: <Splash />
  },
  {
    path: 'login',
    element: <Login />
  },
  {
    path: 'verify-email',
    element: <CreatePassword />
  },
  /* MINE PLANNING OE MENU */
  {
    path: `${URL.ACTIVITY_LEVEL}/${URL.MINE_PLANNING_OE}/dashboard`,
    element: <Base />,
    children: [
      {
        path: '',
        element: <ComingSoon />
      }
    ]
  },
  {
    path: `${URL.ACTIVITY_LEVEL}/${URL.MINE_PLANNING_OE}/assumption`,
    element: <Base />,
    children: [
      {
        path: '',
        element: <Assumption />
      },
      {
        path: 'form/:type',
        element: <AssumptionForm />
      },
      {
        path: 'production-loss-time/:type',
        element: <AssumptionProduction />
      },
      {
        path: 'select-over-burden-unit/:type',
        element: <AssumptionOverBurdenUnit />
      },
      {
        path: 'over-burden-hours-capacity/:type',
        element: <AssumptionOverBurdenCapacity />
      },
      {
        path: 'select-coal-unit/:type',
        element: <AssumptionCoalUnit />
      },
      {
        path: 'coal-hours-capacity/:type',
        element: <AssumptionCoalCapacity />
      },
      {
        path: 'over-burden-hauler/:type',
        element: <AssumptionOverBurdenHauler />
      },
      {
        path: 'pit-reserve/:type',
        element: <AssumptionPitReserve />
      },
      {
        path: 'main-plan-scenario/:type',
        element: <AssumptionMainPlanScenario />
      },
      {
        path: 'summary-production/:type',
        element: <AssumptionSummary />
      }
    ]
  },
  {
    path: `${URL.ACTIVITY_LEVEL}/${URL.MINE_PLANNING_OE}/confident-level`,
    element: <Base />,
    children: [
      {
        path: '',
        element: <ConfidentLevel />
      },
      {
        path: 'submission-pic/:type',
        element: <ConfidentLevelSubmissionPIC />
      },
      {
        path: 'submission-mine-plan/:type',
        element: <ConfidentLevelSubmissionMinePlan />
      },
      {
        path: 'initiation/:type',
        element: <ConfidentLevelInitiation />
      }
    ]
  },
  {
    path: `${URL.ACTIVITY_LEVEL}/${URL.MINE_PLANNING_OE}/user-management`,
    element: <Base />,
    children: [
      {
        path: '',
        element: <UserManagement />
      },
      {
        path: 'form/:type',
        element: <UserForm />
      }
    ]
  },
  {
    path: `${URL.ACTIVITY_LEVEL}/${URL.MINE_PLANNING_OE}/group-management`,
    element: <Base />,
    children: [
      {
        path: '',
        element: <GroupManagement />
      },
      {
        path: 'form/:type',
        element: <GroupForm />
      }
    ]
  },
  {
    path: `${URL.ACTIVITY_LEVEL}/${URL.MINE_PLANNING_OE}/profile`,
    element: <Base />,
    children: [
      {
        path: '',
        element: <Profile />
      }
    ]
  },
  {
    path: `${URL.ACTIVITY_LEVEL}/${URL.MINE_PLANNING_OE}/logs`,
    element: <Base />,
    children: [
      {
        path: '',
        element: <ComingSoon />
      }
    ]
  },
  /* GEOLOGY OE MENU */
  {
    path: `${URL.ACTIVITY_LEVEL}/${URL.GEOLOGY_OE}/drilling-plan`,
    element: <Base />,
    children: [
      {
        path: '',
        element: <DrillingPlan />
      },
      {
        path:'create-planning/:type',
        element: <DrillingForm />
      },
      {
        path: 'quality-checklist/:type',
        element: <QualityChecklist />
      },
      {
        path: 'permit-timeline/:type',
        element: <PermitTimeline />
      },
      {
        path: 'drilling-summary/:type',
        element: <DrillingSummary />
      },
    ]
  },
  {
    path: `${URL.ACTIVITY_LEVEL}/${URL.GEOLOGY_OE}/summary-drilling`,
    element: <Base />,
    children: [
      {
        path: '',
        element: <SummaryDrilling />
      },
      {
        path:'input-actual/:type',
        element: <InputActualForm />
      },
      {
        path:'summary-drilling-actual/:type',
        element: <DrillingSummaryActual />
      },
    ]
  },
  {
    path: `${URL.ACTIVITY_LEVEL}/${URL.GEOLOGY_OE}/reconciliation`,
    element: <Base />,
    children: [
      {
        path: '',
        element: <Reconciliation />
      },
      {
        path: 'input-plan/:type',
        element: <ReconciliationForm />
      },
      {
        path: 'input-actual/:type',
        element: <InputReconciliation />
      }
    ]
  },
  {
    path: `${URL.ACTIVITY_LEVEL}/${URL.GEOLOGY_OE}/core-recovery-of-coal`,
    element: <Base />,
    children: [
      {
        path: '',
        element: <CoreRecovery />
      },
      {
        path: 'input-plan/:type',
        element: <CoreRecoveryForm />
      },
      {
        path: 'input-actual/:type',
        element: <InputCoreRecovery />
      },
    ]
  },
  {
    path: `${URL.ACTIVITY_LEVEL}/${URL.GEOLOGY_OE}/topo-collar-validation`,
    element: <Base />,
    children: [
      {
        path: '',
        element: <TopoCollarValidation />
      },
      {
        path: 'input-plan/:type',
        element: <TopoCollarValidationForm />
      },
      {
        path: 'input-actual/:type',
        element: <InputTopoCollarValidation />
      },
    ]
  },
  {
    path: `${URL.ACTIVITY_LEVEL}/${URL.GEOLOGY_OE}/key-highlight-improvement`,
    element: <Base />,
    children: [
      {
        path: '',
        element: <KeyHighLightImprovement />
      },
    ]
  },
  {
    path: `${URL.ACTIVITY_LEVEL}/${URL.GEOLOGY_OE}/model-drill-hole-validation`,
    element: <Base />,
    children: [
      {
        path: '',
        element: <ModelDrillHoleValidation />
      },
      {
        path: 'input-plan/:type',
        element: <ModelDrillHoleValidationForm />
      },
      {
        path: 'input-actual/:type',
        element: <InputModelDrillHoleValidation />
      },
    ]
  },
  /* MAIN MENU */
  {
    path: `${URL.ITM_LEVEL}`,
    element: <Base />,
    children:[
      {
        path: '',
        element: <ComingSoon />
      },
    ]
  },
  {
    path: `${URL.MINE_SITE_LEVEL}`,
    element: <Base />,
    children:[
      {
        path: '',
        element: <ComingSoon />
      },
    ]
  },
  {
    path: `${URL.ACTIVITY_LEVEL}`,
    element: <Base />,
    children:[
      {
        path: '',
        element: <ActivityLevel />
      },
    ]
  },
  {
    path: `${URL.OE_ASSESSMENT}`,
    element: <Base />,
    children:[
      {
        path: '',
        element: <ComingSoon />
      },
    ]
  },
  {
    path: `${URL.EXECUTIVE_SUMMARY}`,
    element: <Base />,
    children:[
      {
        path: '',
        element: <ComingSoon />
      },
    ]
  },
  /* MAINTENANCE OE MENU */
  {
    path: `${URL.ACTIVITY_LEVEL}/${URL.MAINTENANCE_OE}/plan-stop`,
    element: <Base />,
    children: [
      {
        path: '',
        element: <MaintenancePlanStop />,
      },
    ],
  },
  {
    path: `${URL.ACTIVITY_LEVEL}/${URL.MAINTENANCE_OE}/ome-ap`,
    element: <Base />,
    children: [
      {
        path: '',
        element: <MaintenanceOmeAP />
      },
    ]
  },
  {
    path: `${URL.ACTIVITY_LEVEL}/${URL.MAINTENANCE_OE}/ome-q`,
    element: <Base />,
    children: [
      {
        path: '',
        element: <MaintenanceOmeQ />
      },
    ]
  },
  {
    path: `${URL.ACTIVITY_LEVEL}/${URL.MAINTENANCE_OE}/oe`,
    element: <Base />,
    children: [
      {
        path: '',
        element: <MaintenanceOE />
      },
    ]
  },
  /* OTHER MENU */
  {
    path: 'not-found',
    element: <NotFound />
  },
  {
    path: '*',
    element: <Base />,
    children: [
      {
        path: '*',
        element: <Empty />
      }
    ]
  },
  /* Geology Dashboard */
  {
    path: `${URL.ACTIVITY_LEVEL}/${URL.GEOLOGY_DASHBOARD}`,
    element: <Base />,
    children: [
      {
        path: '',
        element: <GoeDashboard />
      }

    ]
  }
];

export { createRoutes };