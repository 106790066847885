import { isEmpty } from 'lodash';
import { RxCross2 } from 'react-icons/rx';
import { InputNumber } from 'antd';
import { EMPTY } from 'constant';

const generateDrillingHoleLocationTable = (
  payload,
  isDetailMode,
  onChange,
  onDeleteRow
) => [
  {
    dataIndex: 'action',
    title: 'Action',
    width: 5,
    sorter: false,
    align: 'center',
    fixed: 'left',
    render: (_, __, index) => {
      return (
        <div className="flex justify-center">
          {payload.length <= 1 ? (
            <></>
          ) : (
            <RxCross2
              size={28}
              color={`${isDetailMode? 'gray' : 'red'}`}
              className={`rounded-full p-1 ${isDetailMode? 'bg-gray-200':'cursor-pointer bg-red-200 '}`}
              onClick={() => onDeleteRow(index)}
            />
          )}
        </div>
      );
    },
  },
  {
    dataIndex: 'resurvey_easting',
    title: 'Resurvey Easting',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, _, index) => {
      return (
        <InputNumber
          disabled={isDetailMode}
          className='w-full'
          placeholder={'Enter resurvey easting'}
          formatter={(value) => value ? `${parseFloat(value).toFixed(2)}` : ''}
          value={value}
          onChange={(val)=>{
            onChange(index, 'drill_hole_location', 'resurvey_easting' ,val);
          }}
          status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
        /> 
      );
    },
  },
  {
    dataIndex: 'resurvey_northing',
    title: 'Resurvey Northing',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, _, index) => {
      return (
        <InputNumber
          disabled={isDetailMode}
          className='w-full'
          placeholder={'Enter resurvey norting'}
          formatter={(value) => value ? `${parseFloat(value).toFixed(2)}` : ''}
          value={value}
          onChange={(val)=>{
            onChange(index, 'drill_hole_location', 'resurvey_northing' ,val);
          }}
          status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
        /> 
      );
    },
  },
  {
    dataIndex: 'resurvey_elevation',
    title: 'Resurvey Elevation',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, _, index) => {
      return (
        <InputNumber
          disabled={isDetailMode}
          className='w-full'
          placeholder={'Enter resurvey elevation'}
          formatter={(value) => value ? `${parseFloat(value).toFixed(2)}` : ''}
          value={value}
          onChange={(val)=>{
            onChange(index, 'drill_hole_location', 'resurvey_elevation' ,val);
          }}
          status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
        /> 
      );
    },
  },
  {
    dataIndex: 'resurvey_latitude',
    title: 'Resurvey Latitude',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, _, index) => {
      return (
        <InputNumber
          disabled={isDetailMode}
          className='w-full'
          placeholder={'Enter resurvey latitude'}
          value={value}
          onChange={(val)=>{
            onChange(index, 'drill_hole_location', 'resurvey_latitude' ,val);
          }}
          status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
        /> 
      );
    },
  },
  {
    dataIndex: 'resurvey_longitude',
    title: 'Resurvey Longitude',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, _, index) => {
      return (
        <InputNumber
          disabled={isDetailMode}
          className='w-full'
          placeholder={'Enter resurvey longitude'}
          value={value}
          onChange={(val)=>{
            onChange(index, 'drill_hole_location', 'resurvey_longitude' ,val);
          }}
          status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
        /> 
      );
    },
  },
];

const generateDrillingHoleLocationActualData = (file) => {
  if(!isEmpty(file)) {
    const dataDrillHoleName = file.properties[0].drill_hole_information[0].drill_hole_name;
    const data = file.properties[1].drill_hole_location;
    let finalResult = dataDrillHoleName.map((drillHoleName, index) => {
      return data.reduce((result, currentObject, indexObject) => {
        const key = Object.keys(currentObject)[0];
        const value = currentObject[key][index]?.value;
        result[key] = value;
        if(indexObject === data?.length -1){
          result['drill_hole_name'] = drillHoleName?.value;
        }
        return result;
      }, {});
    });
    return finalResult;
  }
};

export { generateDrillingHoleLocationTable, generateDrillingHoleLocationActualData };
