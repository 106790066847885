const convertToMegaBytes = (sizes) => {
  if (sizes) {
    return (sizes / (1024*1024)).toFixed(2);
  }

  return 0;
};

const convertFileToUrl = (file, onLoad) => {
  const reader = new FileReader();
  reader.onload = (ev) => {
    onLoad(ev.target.result);
  };
  reader.readAsDataURL(file);
};

export { convertToMegaBytes, convertFileToUrl };