import moment from 'moment';
import { Divider, Tag } from 'antd';
import { toLower } from 'lodash';

import LoadingIcon from 'components/LoadingIcon';
import ButtonAccent from 'components/ButtonAccent';

const BannerPitPeriod = (props) => {
  const {
    isLoading,
    isHasButton = false,
    buttonTitle,
    buttonIcon,
    companyName,
    contractorName,
    pitName,
    periodMode,
    periods,
    onClick
  } = props;
  
  const generateTags = () => {
    if (periods && periods.length) {
      const isWeekly = toLower(periodMode) === 'weekly';
      const isMonthly = toLower(periodMode) === 'monthly';
      const isYearly = toLower(periodMode) === 'yearly';

      switch (true) {
        case isWeekly: {
          return (
            <Tag className="px-3 font-bold rounded-xl bg-pink-700/25 text-pink-700 border-none">
              {`${periods[0]} - ${periods[periods.length - 1]}`}
            </Tag>
          );
        }
        case isMonthly:
        case isYearly: {
          const periodTag = moment(periods[0], isMonthly ? 'DD MMM YYYY' : 'MMM YYYY')
            .format(isMonthly ? 'MMM YYYY' : 'YYYY');

          return (
            <Tag className="px-3 font-bold rounded-xl bg-pink-700/25 text-pink-700 border-none">
              {periodTag}
            </Tag>
          );
        }
        default: {
          return periods.map((item, index) => (
            <Tag
              key={`tag-${index + 1}`}
              className="px-3 font-bold rounded-xl bg-pink-700/25 text-pink-700 border-none"
            >
              {item}
            </Tag>
          ));
        }
      }
    }
    
    return (<></>);
  };

  const calculateCols = (length) => {
    const isWeekly = toLower(periodMode) === 'weekly';

    if (isHasButton) {
      switch (true) {
        case isWeekly:
          return 'grid-cols-1';
        default:
          return 'grid-cols-3';
      }
    }

    switch (true) {
      case isWeekly:
        return 'grid-cols-1';
      case length > 4:
        return 'grid-cols-5';
      default:
        return `grid-cols-${length}`;
    }
  };
  
  return (
    <div className="p-5 bg-white flex flex-row items-center gap-x-3 rounded-lg">
      {
        isLoading && (
          <LoadingIcon size="sm" />
        )
      }
      <span className="font-bold text-lg">
        {companyName}
      </span>
      <Divider type="vertical" className="h-7 bg-[#2D3D5A]/25" />
      <span className="font-bold text-lg">
        {contractorName}
      </span>
      <Divider type="vertical" className="h-7 bg-[#2D3D5A]/25" />
      <span className="font-bold text-lg">
        {pitName}
      </span>
      <Divider type="vertical" className="h-7 bg-[#2D3D5A]/25" />
      <span className="font-bold">Period</span>
      <div className={`ml-5 grid ${calculateCols(periods.length)} gap-3 ${isHasButton && 'mr-auto'}`}>
        {generateTags()}
      </div>
      {
        isHasButton && (
          <ButtonAccent
            size="lg"
            icon={buttonIcon}
            title={buttonTitle}
            onClick={onClick}
          />
        )
      }
    </div>
  );
};

export default BannerPitPeriod;