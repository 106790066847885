import { useState } from 'react';
import KeyHighlightIssueRequirement from './KeyHighlightIssueRequirement';
import Improvement from './Improvement';

const KeyHighLightImprovement = () => {
  const [selectedTab, setSelectedTab] = useState('keyHighlight');
  return (
    <div className='flex gap-4 flex-col'>
      <div className="flex flex-row gap-2 w-full">
        <div
          className={`${selectedTab === 'keyHighlight' ? 'bg-white' : 'border-white border-[2px]'}  p-4 rounded-xl cursor-pointer`}
          onClick={() => setSelectedTab('keyHighlight')}
        >
      Key Highlight & Issue Requirement
        </div>
        <div
          className={`${selectedTab === 'improvement' ? 'bg-white' : 'border-white border-[2px]'}  p-4 rounded-xl cursor-pointer`}
          onClick={() => setSelectedTab('improvement')}
        >
      Improvement Requirement
        </div>
      </div><div className="bg-white mb-10">
        {selectedTab === 'keyHighlight' ? (
          <KeyHighlightIssueRequirement />
        ) : (
          <Improvement />
        )}
      </div>
    </div>
  );
};

export default KeyHighLightImprovement;