import { DatePicker, Input, Select, Tooltip } from 'antd';
import ButtonAccent from 'components/ButtonAccent';
import dayjs from 'dayjs';
import { RxCross2 } from 'react-icons/rx';
import { TbPencil } from 'react-icons/tb';

const generateImprovementTabel = (
  onChange, 
  payload, 
  onDeleteRow, 
  detailLossOptions, 
  populateDetailLossOptions,
  isDetailLossLoading,
  onSubmit
) => [
  {
    dataIndex: 'action',
    title: 'Action',
    width: 5,
    sorter: false,
    align: 'center',
    fixed: 'left',
    render: (_, record, index) => {
      return (
        <div className="flex justify-center">
          {payload.length <= 1 && record.submit_status !== 'Submitted' ? (
            <></>
          ) : record.submit_status === 'Submitted'? (
            <div
              className="rounded-full bg-[#2D3D5A] cursor-pointer p-1"
              onClick={() => {
                onChange(index, 'submit_status', 'isBeingUpdated');
                populateDetailLossOptions(record?.category_loss, index);
              }}
            >
              <Tooltip title={'Edit'} trigger={'hover'}>
                <TbPencil
                  size="24px"
                  className="p-1 text-white"
                />
              </Tooltip>
            </div>
          ):record.submit_status !== 'isBeingUpdated' && (
            <RxCross2
              size={28}
              color={'red'}
              className={'rounded-full p-1 cursor-pointer bg-red-200'}
              onClick={() => onDeleteRow(record)}
            />
          )}
        </div>
      );
    },
  },
  {
    dataIndex: 'category_loss',
    title: 'Category Loss',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, record, index) => {
      return (
        <Select
          allowClear
          disabled={record.submit_status === 'Submitted'}
          placeholder="Select category loss"
          value={value || null}
          options={[
            { label: 'Availability Loss', value: 'AVAILABILITY_LOSS' },
            { label: 'Performance Loss', value: 'PERFORMANCE_LOSS' },
            { label: 'Quality Loss', value: 'QUALITY_LOSS' },
          ]}
          className="w-full"
          onChange={(val) => {
            onChange(index, 'category_loss', val);
            onChange(index, 'detail_loss', '');
            populateDetailLossOptions(val, record?.localId);
          }}
        />
      );
    },
  },
  {
    dataIndex: 'project_name',
    title: 'Project Name',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, record, index) => {
      return (
        <Input
          disabled={record.submit_status === 'Submitted'}
          placeholder={'Enter project name'}
          value={value}
          onChange={(e) => {
            onChange(index, 'project_name' , e.target.value);
          }}
        /> 
      );
    },
  },
  {
    dataIndex: 'detail_loss',
    title: 'Detail Loss',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, record, index) => {
      return (
        <Select
          allowClear
          disabled={record.submit_status === 'Submitted'}
          placeholder="Select category loss"
          value={value || null}
          options={record.category_loss? detailLossOptions?.find(item=>item.localId === record.localId)?.options: []}
          className={`w-full ${isDetailLossLoading() && 'animate-pulse animate-infinite'}`}
          onChange={(val) => onChange(index, 'detail_loss', val)}
        />
      );
    },
  },
  {
    dataIndex: 'status',
    title: 'Status',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, record, index) => {
      return (
        <Select
          allowClear
          disabled={record.submit_status === 'Submitted'}
          placeholder="Select status"
          value={value || null}
          options={[
            {label: 'In Progress', value: 'In Progress'},
            {label: 'Complete', value: 'Complete'},
            {label: 'Delay', value: 'Delay'},
            {label: 'Pending', value: 'Pending'},
            {label: 'Cancel', value: 'Cancel'},
          ]}
          className={'w-full'}
          onChange={(val) => onChange(index, 'status', val)}
        />
      );
    },
  },
  {
    dataIndex: 'submit_status',
    title: 'Submit Status',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className='flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {value !== 'isBeingUpdated' && value}
        </div>
      );
    },
  },
  {
    dataIndex: 'pic',
    title: 'PIC',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, record, index) => {
      return (
        <Input
          disabled={record.submit_status === 'Submitted'}
          placeholder={'Enter pic'}
          value={value}
          onChange={(e) => {
            onChange(index, 'pic' , e.target.value);
          }}
        /> 
      );
    },
  },
  {
    dataIndex: 'start_date',
    title: 'Start Date',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, record, index) => {
      return (
        <DatePicker
          allowClear
          disabled={record.submit_status === 'Submitted'}
          value={value? dayjs(value,'DD/MM/YYYY'):''}
          format={'DD/MM/YYYY'}
          placeholder="dd/mm/yyyy"
          className="w-full text-left"
          onChange={(val) => {
            onChange(index, 'start_date' , val? dayjs(val).format('DD/MM/YYYY') : null);
          }}
        />
      );
    },
  },
  {
    dataIndex: 'due_date',
    title: 'Due Date',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, record, index) => {
      return (
        <DatePicker
          allowClear
          value={value? dayjs(value,'DD/MM/YYYY') : ''}
          format={'DD/MM/YYYY'}
          placeholder="dd/mm/yyyy"
          className="w-full text-left"
          disabled={record.submit_status !== 'Submitted' && record.start_date? false : true}
          disabledDate={(currentDate) => currentDate.isBefore(dayjs(record.start_date,'DD/MM/YYYY'),'days') }
          onChange={(val) => {
            onChange(index, 'due_date' , val? dayjs(val).format('DD/MM/YYYY'): null);
          }}
        />
      );
    },
  },
  {
    dataIndex: 'save',
    title: 'Save',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (_, record) => {
      return (
        record.submit_status !== 'Submitted' && 
        <div className='flex flex-row gap-2'>
          <ButtonAccent title="Submit" onClick={() => onSubmit([record], false)} />
          {record.submit_status !== 'isBeingUpdated' && (
            <ButtonAccent isBordered title="Save As Draft" onClick={() => onSubmit([record], true)}  />
          )}
        </div>
      );
    },
  },
];

const initialImprovementData = {
  localId: 1,
  category_loss: '',
  project_name: '',
  detail_loss: '',
  status: '',
  submit_status: '',
  pic: '',
  start_date: '',
  due_date: ''
};

export { generateImprovementTabel, initialImprovementData };