import {
  createContext,
  useContext,
  useState
} from 'react';
import { Image } from 'antd';
import { BsArrowLeft } from 'react-icons/bs';
import { isEmpty } from 'lodash';

import { EMPTY } from 'constant';

const ImagePreviewContext = createContext();
const ImagePreview = (props) => {
  const { children } = props;
  
  const [source, setSource] = useState({
    name: EMPTY.STRING,
    size: EMPTY.NUMBER,
    url: EMPTY.STRING
  }); 
  
  const showPreview = (data) => {
    setSource(data);
  };

  return (
    <ImagePreviewContext.Provider value={{ showPreview }}>
      {
        !isEmpty(source.url) && (
          <div className="p-5 w-full h-full absolute flex flex-col left-0 top-0 bg-black z-40">
            <div className="flex flex-row gap-x-3">
              <BsArrowLeft
                size="24px"
                className="text-white cursor-pointer"
                onClick={() => setSource({
                  name: EMPTY.STRING,
                  size: EMPTY.NUMBER,
                  url: EMPTY.STRING
                })}
              />
              <span className="text-white font-bold">
                {source.name}
              </span>
            </div>

            <div className="my-auto w-full flex flex-row items-center justify-center self-center">
              <Image
                preview={false}
                src={source.url}
                className="self-center"
              />
            </div>
          </div>
        )
      }
      {children}
    </ImagePreviewContext.Provider>
  );
};

const useImagePreviewContext = () => useContext(ImagePreviewContext);

export default ImagePreview;
export { useImagePreviewContext };