import { Select, Tag } from 'antd';
import { AiOutlineClose } from 'react-icons/ai';

import { EMPTY } from 'constant';

const SelectMultipleAccent = (props) => {
  const {
    isLoading,
    isDisabled,
    isStringify,
    isError,
    placeholder = 'Select here...',
    labelKey,
    valueKey,
    value,
    options,
    onChange
  } = props;

  const generateCustomTag = (selectProps) => {
    const {
      closable,
      label,
      onClose
    } = selectProps;

    return (
      <Tag
        closable={closable}
        onClose={onClose}
        closeIcon={<AiOutlineClose className="text-white" />}
        className="px-3 flex flex-row items-center text-white font-bold rounded-lg bg-[#2D3D5A]"
      >
        {label}
      </Tag>
    );
  };

  const transformOptions = options && options.length
    ? options.map((item) => {
      if (isStringify) {
        return {
          label: labelKey
            ? item[labelKey]
            : item,
          value: JSON.stringify(item)
        };
      }

      return {
        label: labelKey
          ? item[labelKey]
          : item,
        value: valueKey
          ? item[valueKey]
          : item
      };
    })
    : [];
  
  return (
    <Select
      allowClear
      mode="multiple"
      disabled={isDisabled || isLoading}
      status={isError ? 'error' : EMPTY.STRING}
      placeholder={placeholder}
      value={value}
      options={transformOptions}
      tagRender={generateCustomTag}
      onChange={onChange}
      className={`w-full ${isLoading && 'animate-pulse animate-infinite'}`}
    />
  );
};

export default SelectMultipleAccent;