const categories = [
  'Drill Hole Information',
  'Drill Hole Location',
  'Drilling Depth',
  'Drilling Date',
  'Time & Depth Analysis',
  'Drilling Machine(RIG)',
  'Drilling Evidence',
  'Drilling Samples Information',
  'OME',
  'Remark',
];

const drillHoleName =  {
  dataIndex: 'drill_hole_name',
  title: 'Drill Hole Name',
  width: '196px',
  sorter: false,
  align: 'center',
  fixed: 'left',
  render: (value) => {
    return (
      <div className='flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
        { value }
      </div>
    );
  },
};

export { categories, drillHoleName };
