import { Base } from './Base';

const ConfidentLevel = Base.injectEndpoints({
  endpoints: (build) => ({
    getConfidentLevelsMp: build.query({
      query: (params) => ({
        url: '/confident-levels',
        method: 'GET',
        ...params
          ? { params }
          : undefined
      })
    }),
    getInitiationById: build.query({
      query: ({ id }) => ({
        url: `/confident-levels/${id}`,
        method: 'GET'
      })
    }),
    getSubmissionById: build.query({
      query: ({ id }) => ({
        url: `/confident-levels/${id}/submissions`,
        method: 'GET'
      })
    }),
    postInitiation: build.mutation({
      query: (body) => ({
        url: '/confident-levels',
        method: 'POST',
        body
      })
    }),
    postSubmission: build.mutation({
      query: (body) => ({
        url: '/confident-levels/submissions',
        method: 'POST',
        body
      })
    }),
    putInitiation: build.mutation({
      query: ({ id, body }) => ({
        url: `/confident-levels/${id}`,
        method: 'PUT',
        body
      })
    }),
  })
});

export const {
  useGetConfidentLevelsMpQuery,
  useLazyGetConfidentLevelsMpQuery,
  useGetSubmissionByIdQuery,
  usePostInitiationMutation,
  usePostSubmissionMutation,
  useLazyGetInitiationByIdQuery,
  usePutInitiationMutation
} = ConfidentLevel;