import { Base } from './Base';

const DrillingPlan = Base.injectEndpoints({
  endpoints: (build)=>({
    postPermitTimeline: build.mutation({
      query: ({ body, idPlan }) => ({
        url: `/goe/drillings/plans/${idPlan}/permit-timelines`,
        method: 'POST',
        body
      })
    }),
    getPermitTimelineById: build.query({
      query: ({id, params})=>({
        url: `/goe/drillings/plans/${id}/permit-timelines`,
        method: 'GET',
        ...params
          ? { params }
          : undefined
      })
    })
  })
});

export const {
  usePostPermitTimelineMutation,
  useLazyGetPermitTimelineByIdQuery,
} = DrillingPlan;

