import { 
  Modal,
  Col,
  Form,
  Row,
  Input,
  InputNumber,
  Typography
} from 'antd';
import { useEffect, useState } from 'react';

import SelectAccent from 'components/SelectAccent';
import ButtonAccent from 'components/ButtonAccent';

import {
  formatWithEnLocale,
  parseFromEnLocale,
  preventNonNumber
} from 'utils/CurrencyFormatter';
import { EMPTY } from 'constant';


const ModalUserOrganization = (props) => {
  const { openModal, setOpenModal, trucks, onAddTruck, truckData, isEditTruck, data } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { Title } = Typography;
  const [formTruck] = Form.useForm();

  const handleOk = (values) => {
    onAddTruck(values);
    setOpenModal(false);
    setIsLoading(false);
    formTruck.resetFields();
  };

  const handleCancel = () => {
    setOpenModal(false);
    setIsLoading(false);
    formTruck.resetFields();
  };

  const onSelectTruck = (v) => {
    if (v) {
      const findData = data.find(i => i.type_truck.some(k => k.id === JSON.parse(v).id));
      const findIndex = data.map(i => i.type_truck.findIndex(k => k.id === JSON.parse(v).id)).find(d => d >= 0);

      if (findData) {
        const matchMH = findData.maintenance_hours[findIndex];
        formTruck.setFieldValue('maintenance_hours', matchMH);
      } else {
        formTruck.setFieldValue('maintenance_hours', 0);
      }
    } else {
      formTruck.setFieldValue('maintenance_hours', 0);
    }
  };

  useEffect(() => {
    formTruck.setFieldsValue(truckData);
  }, [truckData]);

  return (
    <>
      <Modal
        open={openModal}
        centered
        footer={false}
        onOk={() => handleCancel()}
        onCancel={() => handleCancel()}
      >
        <div className='flex flex-row justify-start mb-5'><Title level={3}>{isEditTruck ? 'Edit' :'Input'} Truck</Title></div>
        <Form
          layout="vertical"
          autoComplete="off"
          requiredMark={false}
          form={formTruck}
          onFinish={handleOk}
        >
          <Row gutter={[32, 0]}>
            <Col span={12}>
              <Form.Item
                name="type_truck"
                label="Type Truck"
                rules={[{ required: true, message:'Type Truck is required' }]}
              >
                <SelectAccent
                  isStringify
                  labelKey="name"
                  valueKey="id"
                  options={trucks}
                  onChange={(e) => onSelectTruck(e)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="dump_area"
                label="Dump Area"
                rules={[{ required: true, message:'Dump Area is required' }]}
              >
                <Input placeholder="Enter Dump Area" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="distance"
                label="Distance (Meter)"
                rules={[{ required: true, message:'Distance (Meter) is required' }]}
              >
                <InputNumber
                  min={1}
                  style={{ width: '100%' }}
                  formatter={formatWithEnLocale}
                  parser={parseFromEnLocale}
                  onKeyDown={preventNonNumber}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="maintenance_hours"
                label="Maintenance Hours"
                rules={[{ required: true, message:'Maintenance Hours is required' }]}
              >
                <InputNumber
                  min={EMPTY.NUMBER}
                  style={{ width: '100%' }}
                  formatter={formatWithEnLocale}
                  parser={parseFromEnLocale}
                  onKeyDown={preventNonNumber}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="quantity"
                label="Quantity"
                rules={[{ required: true, message:'Quantity is required' }]}
              >
                <InputNumber
                  min={1}
                  style={{ width: '100%' }}
                  formatter={formatWithEnLocale}
                  parser={parseFromEnLocale}
                  onKeyDown={preventNonNumber}
                />
              </Form.Item>
            </Col>
          </Row>
          
          <div className='flex flex-row justify-end mt-5 justify-items-end'>
            <ButtonAccent
              isBordered
              isLoading={isLoading}
              title="Cancel"
              onClick={handleCancel}
            />
            <div className='ml-5'>
              <ButtonAccent
                title={isEditTruck ? 'Update' : 'Add'}
                htmlType="submit"
                isLoading={isLoading}
              />
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default ModalUserOrganization;