import { Base } from './Base';

const Permission = Base.injectEndpoints({
  endpoints: (build) => ({
    getPermissions: build.query({
      query: () => ({
        url: '/menus/permission',
        method: 'GET'
      })
    })
  })
});

export const { useGetPermissionsQuery } = Permission;