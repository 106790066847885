import { Base } from './Base';

const ModelDrillHoleValidation = Base.injectEndpoints({
  endpoints: (build)=>({
    validateModel: build.mutation({
      query: (body) => ({
        url: '/goe/drillings/actuals/model-drill-holes/validation',
        method: 'POST',
        body
      })
    }),
    postModel: build.mutation({
      query: ({ body, planId }) => ({
        url: `/goe/drillings/actuals/${planId}/model-drill-holes`,
        method: 'POST',
        body
      })
    }),
    uploadModelExcel: build.mutation({
      query: ({body, plan_id, params}) => ({
        url: `/goe/drillings/actuals/${plan_id}/model-drill-holes/upload-excel`,
        method: 'POST',
        body,
        ...params
          ? { params }
          : undefined,
        // 10 minutes of upload timeout
        timeout: Number(10 * (60 * 1000))
      })
    }),
    getModelList: build.query({
      query: (params) => ({
        url: '/goe/drillings/actuals/model-drill-holes',
        method: 'GET',
        ...(params)
          ? { params }
          : undefined
      })
    }),
    getModelById: build.query({
      query: ({id}) =>({
        url: `/goe/drillings/actuals/model-drill-holes/${id}`,
        method: 'GET'
      })
    }),
    exportModelExcel: build.query({
      query:({id, company, year, drillingStage})=>({
        url:`/goe/drillings/actuals/model-drill-holes/${id}/export-excel`,
        method: 'GET',
        responseHandler: async (response) => {
          const docLink = document.createElement('a');
          docLink.href = window.URL.createObjectURL(await response.blob());
          docLink.target = '_blank';
          docLink.download = `model_vs_drill_hole_validation_${year}_${company}_${drillingStage}.xlsx`;
          docLink.click();
        },
        cache: 'no-cache',
      })
    })
  }),
  
});

export const {
  useValidateModelMutation,
  usePostModelMutation,
  useUploadModelExcelMutation,
  useGetModelListQuery,
  useLazyGetModelByIdQuery,
  useLazyExportModelExcelQuery
} = ModelDrillHoleValidation;

