import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import { Divider, Select } from 'antd';
import { toast } from 'react-toastify';

import { useSelector } from 'react-redux';
import { useGetAssumptionsQuery } from 'api/Assumption';
import {
  ACTION,
  DEFAULT,
  EMPTY,
  PERMISSION
} from 'constant';
import { assumptionColumns } from 'constant/TableAssumption';
import { getFullPath } from 'utils/PathUtility';
import { transformError } from 'utils/ErrorTransformer';
import { usePermissionManager } from 'utils/PermissionManager';

import FilterContainer from 'components/FilterContainer';
import TableData from 'components/TableData';
import ButtonAccent from 'components/ButtonAccent';

const Assumption = () => {
  const loc = useLocation();
  const navigate = useNavigate();
  const {
    page,
    pageSize,
    sortBy,
    orderBy,
    search
  } = useSelector((state) => state.table);

  const initialFilter = { status: EMPTY.STRING };
  const [filter, setFilter] = useState(initialFilter);

  const { isUserHasPermissionInFeature } = usePermissionManager();

  const {
    data: assumptions,
    isFetching: assumptionIsFetching,
    isError: assumptionIsError,
    error: assumptionError
  } = useGetAssumptionsQuery(
    {
      page,
      limit: pageSize,
      sort_by: sortBy,
      order_by: orderBy,
      ...filter,
      ...search
        ? { search }
        : undefined
    },
    { refetchOnMountOrArgChange: true }
  );

  const statuses = [
    { label: 'Draft', value: 'draft' },
    { label: 'Submitted', value: 'submitted' }
  ];
  
  const goToPathByType = (id, type) => navigate({
    pathname: getFullPath({
      parent: 'assumption',
      child: 'form',
      type
    }),
    ...(type === ACTION.DETAIL || type === ACTION.EDIT)
      ? { search: createSearchParams({ id }).toString() }
      : undefined
  });

  const onFilter = (key, value) => {
    setFilter((prevState) => ({
      ...prevState,
      [key]: value
    }));
  };

  const resetFilter = () => {
    setFilter(initialFilter);
  };

  const onAction = (id, type) => {
    /**
     * @param {string} id
     * @param {string} type
     * */
    if (type === ACTION.DELETE) {
      toast.error(
        'This feature is not implemented yet',
        { toastId: 'delete-assumption-delete-toast' }
      );

      return;
    }
    
    goToPathByType(id, type);
  };

  useEffect(() => {
    if (assumptionIsError && assumptionError) {
      toast.error(
        transformError(assumptionError).message,
        { toastId: 'assumption-list-error' }
      );
    }

  }, [assumptionIsError]);

  return (
    <div className="p-5 bg-white rounded-lg">
      <FilterContainer onReset={resetFilter}>
        <label>
          <span className="font-bold">Status</span>
          <Select
            placeholder="Select status"
            value={filter['status'] || null}
            options={statuses}
            className="mt-2 w-full"
            onChange={(val) => onFilter('status', val)}
          />
        </label>
      </FilterContainer>
      <Divider />
      <TableData
        isTransparent
        rowKey="id"
        isLoading={assumptionIsFetching}
        columns={
          assumptionColumns(
            loc.pathname,
            isUserHasPermissionInFeature,
            sortBy,
            orderBy === DEFAULT.KEY_SORT_ASC
              ? DEFAULT.SORT_ASC
              : DEFAULT.SORT_DESC,
            onAction,
            onAction,
            onAction
          )
        }
        dataSource={assumptions?.assumptions}
        totalData={assumptions?.total_data}
      >
        {
          isUserHasPermissionInFeature(loc.pathname, PERMISSION.CREATE) && (
            <ButtonAccent
              title="Create"
              onClick={() => onAction(null, ACTION.CREATE)}
            />
          )
        }
      </TableData>
    </div>
  );
};

export default Assumption;