import { FiTrash } from 'react-icons/fi';
import { GiPencil } from 'react-icons/gi';

import {
  ACTION
} from 'constant';
import moment from 'moment';

const maintenanceOmeAPColumns = (
  url,
  sortBy,
  orderBy,
  onEdit,
  onDelete
) => [
  {
    title: 'No',
    sorter: false,
    render: (text, record, index) => (
      <>{index+1}</>
    )
  },
  {
    title: 'Action',
    sorter: false,
    render: (text, record) => (
      <div className="flex flex-row justify-center items-center gap-x-3">
        {
          <div
            className="rounded-full bg-[#2D3D5A] cursor-pointer"
            onClick={() => onEdit(record.id, ACTION.EDIT)}
          >
            <GiPencil
              size="24px"
              className="p-1 text-white"
            />
          </div>
        }
        {
          <div
            className="rounded-full bg-pink-700 cursor-pointer"
            onClick={() => onDelete(record.id, ACTION.DELETE)}
          >
            <FiTrash
              size="24px"
              className="p-1 text-white"
            />
          </div>
        }
      </div>
    )
  },
  {
    dataIndex: 'date',
    title: 'Date',
    sorter: true,
    sortOrder: sortBy === 'date' ? orderBy : null,
    render: (text) => (
      <div className='w-[100px]'>
        {moment(text).format('DD-MM-YYYY')}
      </div>
    ),
  },
  {
    dataIndex: 'ome_section',
    title: 'Section',
    sorter: true,
    sortOrder: sortBy === 'ome_section' ? orderBy : null,
    render: (text) => (
      <span>{ text.name }</span>
    )
  },
  {
    dataIndex: 'work_order',
    title: 'Work Order',
    sorter: true,
    sortOrder: sortBy === 'work_order' ? orderBy : null,
    render: (text) => (
      <div className='w-[200px]'>
        {text}
      </div>
    ),
  },
  {
    dataIndex: 'description',
    title: 'Description',
    sorter: true,
    sortOrder: sortBy === 'description' ? orderBy : null,
    render: (text) => (
      <div className='w-[200px]'>
        {text}
      </div>
    ),
  },
  {
    dataIndex: 'ome_location',
    title: 'Location',
    sorter: true,
    sortOrder: sortBy === 'ome_location' ? orderBy : null,
    render: (text) => (
      <div className='w-[150px]'>
        {text.name}
      </div>
    ),
  },
  {
    dataIndex: 'ome_area',
    title: 'Area',
    sorter: true,
    sortOrder: sortBy === 'ome_area' ? orderBy : null,
    render: (text) => (
      <div className='w-[100px]'>
        {text.name}
      </div>
    ),
  },
  {
    dataIndex: 'pic',
    title: 'PIC',
    sorter: true,
    sortOrder: sortBy === 'pic' ? orderBy : null,
  },
  {
    dataIndex: 'unit_status',
    title: 'Unit Status',
    sorter: true,
    sortOrder: sortBy === 'unit_status' ? orderBy : null,
    render: (text) => (
      <div className='w-[100px]'>
        {text}
      </div>
    ),
  },
  {
    title: 'Plan Work',
    className: 'p-0',
    children:[
      {
        dataIndex: 'time_start_plan',
        title: 'Time Start',
        sorter: true,
        sortOrder: sortBy === 'time_start_plan' ? orderBy : null,
        render: (text) => (
          <div className='w-[100px]'>
            {text}
          </div>
        ),
      },
      {
        dataIndex: 'time_end_plan',
        title: 'Time End',
        sorter: true,
        sortOrder: sortBy === 'time_end_plan' ? orderBy : null,
        render: (text) => (
          <div className='w-[100px]'>
            {text}
          </div>
        ),
      },
    ]
  },
  {
    title: 'Actual Work',
    className: 'p-0',
    children:[
      {
        dataIndex: 'time_start_actual',
        title: 'Time Start',
        sorter: true,
        sortOrder: sortBy === 'time_start_actual' ? orderBy : null,
        render: (text) => (
          <div className='w-[100px]'>
            {text}
          </div>
        ),
      },
      {
        dataIndex: 'time_end_actual',
        title: 'Time End',
        sorter: true,
        sortOrder: sortBy === 'time_end_actual' ? orderBy : null,
        render: (text) => (
          <div className='w-[100px]'>
            {text}
          </div>
        ),
      },
    ]
  },
  {
    dataIndex: 'ome_availability_loss',
    title: 'Availability Loss',
    sorter: true,
    sortOrder: sortBy === 'ome_availability_loss' ? orderBy : null,
    render: (text) => (
      <span>{ text.name }</span>
    )
  },
  {
    dataIndex: 'ome_availability_loss',
    title: 'Detailed A Loss',
    sorter: true,
    sortOrder: sortBy === 'ome_availability_loss' ? orderBy : null,
    render: (text) => (
      <div className='w-[250px]'>
        {text.detailed}
      </div>
    ),
  },
  {
    dataIndex: 'ome_performance_loss',
    title: 'Performance Loss',
    sorter: true,
    sortOrder: sortBy === 'ome_performance_loss' ? orderBy : null,
    render: (text) => (
      <div className='w-[250px]'>
        {text.name}
      </div>
    ),
  },
  {
    dataIndex: 'ome_performance_loss',
    title: 'Detailed P Loss',
    sorter: true,
    sortOrder: sortBy === 'ome_performance_loss' ? orderBy : null,
    render: (text) => (
      <div className='w-[250px]'>
        {text.detailed}
      </div>
    ),
  },
  {
    dataIndex: 'note_progress_pekerjaan',
    title: 'Note Progress',
    sorter: true,
    sortOrder: sortBy === 'note_progress_pekerjaan' ? orderBy : null,
    render: (text, record) => (
      <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '200px' }}>
        {text}
      </div>
    ),
  },
  {
    dataIndex: 'crew',
    title: 'Crew',
    sorter: true,
    sortOrder: sortBy === 'crew' ? orderBy : null,
    render: (text) => (
      <div className='w-[100px]'>
        {text}
      </div>
    ),
  },
  {
    title: 'Compare for A loss',
    className: 'p-0 text-center',
    children:[
      {
        dataIndex: 'start_late_time',
        title: 'Start Late Time',
        sorter: true,
        sortOrder: sortBy === 'start_late_time' ? orderBy : null,
        render: (text) => (
          <div className='w-[200px]'>
            {text}
          </div>
        ),
      },
    ]
  },
  {
    title: 'Compare for P loss',
    className: 'p-0 text-center',
    children:[
      {
        dataIndex: 'plan_time',
        title: 'Plan Time',
        sorter: true,
        sortOrder: sortBy === 'plan_time' ? orderBy : null,
        render: (text) => (
          <div className='w-[100px]'>
            {text}
          </div>
        ),
      },
      {
        dataIndex: 'actual_time',
        title: 'Actual Time',
        sorter: true,
        sortOrder: sortBy === 'actual_time' ? orderBy : null,
        render: (text) => (
          <div className='w-[100px]'>
            {text}
          </div>
        ),
      },
      {
        dataIndex: 'exceed_time',
        title: 'Exceed Time',
        sorter: true,
        sortOrder: sortBy === 'exceed_time' ? orderBy : null,
        render: (text) => (
          <div className='w-[100px]'>
            {text}
          </div>
        ),
      },
    ]
  },
];

const generateParamBulkCreate = (data) => {
  return data.map(v => {
    return {
      date: v.date,
      work_order: v.work_order,
      description: v.description,
      pic: v.pic,
      unit_status: v.unit_status,
      time_start_plan: v.time_start_plan,
      time_end_plan: v.time_end_plan,
      time_start_actual: v.time_start_actual,
      time_end_actual: v.time_end_actual,
      note_progress_pekerjaan: v.note_progress_pekerjaan,
      crew: v.crew,
      ome_section_id: v.ome_section.id,
      ome_location_id: v.ome_location.id,
      ome_area_id: v.ome_area.id,
      ome_availability_loss_id: v.ome_availability_loss.id,
      ome_performance_loss_id: v.ome_performance_loss.id,
    };
  });
};

export { maintenanceOmeAPColumns, generateParamBulkCreate };