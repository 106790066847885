import { RxCross2 } from 'react-icons/rx';
import SelectAddAccent from 'components/SelectAddAccent';
import _, { isEmpty, isNumber } from 'lodash';
import moment from 'moment';

const generateOmeTable = (
  payload,
  isDetailMode,
  onChange,
  availabilityLossProperties,
  performanceLossProperties,
  qualityLossProperties,
  onDeleteRow
) => [
  {
    dataIndex: 'action',
    title: 'Action',
    width: 5,
    sorter: false,
    align: 'center',
    fixed: 'left',
    render: (_, __, index) => {
      return (
        <div className="flex justify-center">
          {payload.length <= 1 ? (
            <></>
          ) : (
            <RxCross2
              size={28}
              color={`${isDetailMode? 'gray' : 'red'}`}
              className={`rounded-full p-1 ${isDetailMode? 'bg-gray-200':'cursor-pointer bg-red-200 '}`}
              onClick={() => onDeleteRow(index)}
            />
          )}
        </div>
      );
    },
  },
  {
    dataIndex: 'categories_a_loss',
    title: 'Category A Loss',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className='flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          { value }
        </div>
      );
    },
  },
  {
    dataIndex: 'categories_p_loss',
    title: 'Category P Loss',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className='flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          { value }
        </div>
      );
    },
  },
  {
    dataIndex: 'categories_q_loss',
    title: 'Category Q Loss',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className='flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          { value }
        </div>
      );
    },
  },
  {
    dataIndex: 'availability_loss_1',
    title: 'Availability Loss 1',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, record, index) => {
      let availabilityOption = availabilityLossProperties.data;
      let availabilityOptionIsFetching = availabilityLossProperties.isFetching;
      return (
        <SelectAddAccent
          isNotTag
          isDisabled={isDetailMode || !record.categories_a_loss}
          isSingle={true}
          value={value || undefined}
          placeholder="Select"
          options={availabilityOption}
          isLoading={availabilityOptionIsFetching}
          className={'w-full text-left'}
          onChange={(val) => {
            onChange(index, 'ome', 'availability_loss_1' ,val);
          }}
          
        />
      );
    },
  },
  {
    dataIndex: 'availability_loss_2',
    title: 'Availability Loss 2',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, record, index) => {
      let availabilityOption = availabilityLossProperties.data;
      let availabilityOptionIsFetching = availabilityLossProperties.isFetching;
      return (
        <SelectAddAccent
          isNotTag
          isDisabled={isDetailMode || !record.categories_a_loss}
          isSingle={true}
          value={value || undefined}
          placeholder="Select"
          options={availabilityOption}
          isLoading={availabilityOptionIsFetching}
          className={'w-full text-left'}
          onChange={(val) => {
            onChange(index, 'ome', 'availability_loss_2' ,val);
          }}
          
        />
      );
    },
  },
  {
    dataIndex: 'performance_loss_1',
    title: 'Performance Loss 1',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, record, index) => {
      let performanceOption = performanceLossProperties.data;
      let performanceOptionIsFetching = performanceLossProperties.isFetching;
      return (
        <SelectAddAccent
          isNotTag
          isDisabled={isDetailMode || !record.categories_p_loss}
          isSingle={true}
          value={value || undefined}
          placeholder="Select"
          options={performanceOption}
          isLoading={performanceOptionIsFetching}
          className={'w-full text-left'}
          onChange={(val) => {
            onChange(index, 'ome', 'performance_loss_1' ,val);
          }}
          
        />
      );
    },
  },
  {
    dataIndex: 'performance_loss_2',
    title: 'Performance Loss 2',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, record, index) => {
      let performanceOption = performanceLossProperties.data;
      let performanceOptionIsFetching = performanceLossProperties.isFetching;
      return (
        <SelectAddAccent
          isNotTag
          isDisabled={isDetailMode || !record.categories_p_loss}
          isSingle={true}
          value={value || undefined}
          placeholder="Select"
          options={performanceOption}
          isLoading={performanceOptionIsFetching}
          className={'w-full text-left'}
          onChange={(val) => {
            onChange(index, 'ome', 'performance_loss_2' ,val);
          }}
          
        />
      );
    },
  },
  {
    dataIndex: 'quality_loss_1',
    title: 'Quality Loss 1',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, record, index) => {
      let qualityOption = qualityLossProperties.data;
      let qualityOptionIsFetching = qualityLossProperties.isFetching;
      return (
        <SelectAddAccent
          isNotTag
          isDisabled={isDetailMode || !record.categories_q_loss}
          isSingle={true}
          value={value || undefined}
          placeholder="Select"
          options={qualityOption}
          isLoading={qualityOptionIsFetching}
          className={'w-full text-left'}
          onChange={(val) => {
            onChange(index, 'ome', 'quality_loss_1' ,val);
          }}
          
        />
      );
    },
  },
  {
    dataIndex: 'quality_loss_2',
    title: 'Quality Loss 2',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, record, index) => {
      let availabilityOption = availabilityLossProperties.data;
      let availabilityOptionIsFetching = availabilityLossProperties.isFetching;
      return (
        <SelectAddAccent
          isNotTag
          isDisabled={isDetailMode || !record.categories_q_loss}
          isSingle={true}
          value={value || undefined}
          placeholder="Select"
          options={availabilityOption}
          isLoading={availabilityOptionIsFetching}
          className={'w-full text-left'}
          onChange={(val) => {
            onChange(index, 'ome', 'quality_loss_2' ,val);
          }}
          
        />
      );
    },
  },
  {
    dataIndex: 'a',
    title: '% A',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      const populateValue= ()=>{
        if(isNumber(value) && value >= 0){
          if(Number(value) === value && value % 1 !== 0){
            return `${value.toFixed(0)}%`;
          }else {
            return `${value}%`;
          }
        }
        return '';
      };
      return (
        <div className='flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          { populateValue() }
        </div>
      );
    },
  },
  {
    dataIndex: 'p',
    title: '% P',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      const populateValue= ()=>{
        if(isNumber(value) && value >= 0){
          if(Number(value) === value && value % 1 !== 0){
            return `${value.toFixed(0)}%`;
          }else {
            return `${value}%`;
          }
        }
        return '';
      };
      return (
        <div className='flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          { populateValue() }
        </div>
      );
    },
  },
  {
    dataIndex: 'q',
    title: '% Q',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      const populateValue= ()=>{
        if(isNumber(value) && value >= 0){
          if(Number(value) === value && value % 1 !== 0){
            return `${value.toFixed(0)}%`;
          }else {
            return `${value}%`;
          }
        }
        return '';
      };
      return (
        <div className='flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          { populateValue() }
        </div>
      );
    },
  },
  {
    dataIndex: 'ome',
    title: '% OME',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      const populateValue= ()=>{
        if(Number(value) >= 0){
          if(Number(value) % 1 !== 0){
            return `${value.toFixed(0)}%`;
          }else {
            return `${value}%`;
          }
        }
        return '';
      };
      return (
        <div className='flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {populateValue()}
        </div>
      );
    },
  },
];

const calculateCategoryALoss = (index, plansData, otherData) => {
  let aLossData = '';

  const drillHoleNameActual = otherData.drill_hole_information[index].drill_hole_name;
  const dataPlan = plansData.find(v => v.drill_hole_name === drillHoleNameActual);
  const dateStartDrillActual = otherData.drilling_date[index].date_start_drill_actual;
  const dateStartDrillPlan = dataPlan?.date_start_drill_plan;

  const area = dataPlan?.area;
  const case_drill = otherData.drill_hole_information[index].case_drill;
  const date_start_drill_actual = dateStartDrillActual ? 
    new Date(
      moment(dateStartDrillActual, 'DD/MM/YYYY')
        .format('YYYY-MM-DD')
    )
    : '';
  const date_start_drill_plan = dateStartDrillPlan ? 
    new Date(moment(dateStartDrillPlan, 'DD/MM/YYYY').format('YYYY-MM-DD'))
    : '';

  if (area && case_drill) {
    if (['Re-dril', 'As plan'].includes(case_drill)) {
      if ((date_start_drill_actual && date_start_drill_plan) && date_start_drill_actual > date_start_drill_plan) {
        aLossData = 'Availability Loss';
      }
    }

    if (case_drill === 'Pending') {
      aLossData = 'Availability Loss';
    }
  }

  return aLossData;
};

const calculateCategoryPLoss = (index, plansData, otherData) => {
  let pLossData = '';
  
  const drillHoleNameActual = otherData.drill_hole_information[index].drill_hole_name;
  const dataPlan = plansData.find(v => v.drill_hole_name === drillHoleNameActual);

  const area = dataPlan?.area;
  const case_drill = otherData.drill_hole_information[index].case_drill;
  const plan_time = dataPlan?.plan_time;
  const actual_time = otherData['time_&_depth_analysis'][index].actual_time;

  if (area && case_drill) {
    if (['Re-drill', 'As plan'].includes(case_drill)) {
      if (actual_time >= 0 && actual_time > plan_time) {
        pLossData = 'Performance Loss';
      }
    }
  }

  return pLossData;
};

const calculateCategoryQLoss = (index, plansData, otherData) => {
  let qLossData = '';

  const drillHoleNameActual = otherData.drill_hole_information[index].drill_hole_name;
  const dataPlan = plansData.find(v => v.drill_hole_name === drillHoleNameActual);

  const area = dataPlan?.area;
  const case_drill = otherData.drill_hole_information[index].case_drill;
  const actual_drilling_depth = otherData['drilling_depth'][index].actual_drilling_depth;
  const plan_drilling_depth = dataPlan?.plan_drilling_depth;

  if (area && case_drill) {
    if (['Pending', 'Additional', 'Cancel'].includes(case_drill)) {
      qLossData = 'Quality Loss';
    }

    if (actual_drilling_depth >= 0 && actual_drilling_depth > plan_drilling_depth) {
      qLossData = 'Quality Loss';
    }
  }

  return qLossData;
};

const calculatePercentA = (index, plansData, otherData) => {
  let percentA = '';

  const drillHoleNameActual = otherData.drill_hole_information[index].drill_hole_name;
  const dataPlan = plansData.find(v => v.drill_hole_name === drillHoleNameActual);
  const dateStartDrillActual = otherData.drilling_date[index].date_start_drill_actual;

  const date_start_drill_actual = dateStartDrillActual ? 
    new Date(
      moment(dateStartDrillActual, 'DD/MM/YYYY')
        .format('YYYY-MM-DD')
    )
    : '';
  const start_late_time = otherData['time_&_depth_analysis'][index].start_late_time;
  const plan_time = dataPlan?.plan_time;

  if (date_start_drill_actual) {
    if (start_late_time >= 0 && start_late_time > plan_time) {
      percentA = 0;
    } else {
      percentA = parseFloat((plan_time - start_late_time) / plan_time) * 100;
    }
  }

  return percentA;
};

const calculatePercentP = (index, plansData, otherData) => {
  let percentP = '';

  const drillHoleNameActual = otherData.drill_hole_information[index].drill_hole_name;
  const dataPlan = plansData.find(v => v.drill_hole_name === drillHoleNameActual);

  const actual_time = otherData['time_&_depth_analysis'][index].actual_time;
  const plan_time = dataPlan?.plan_time;
  const pending_time = otherData['time_&_depth_analysis'][index].pending_time;

  if (actual_time && actual_time > 0) {
    if (actual_time <= (plan_time - pending_time)) {
      percentP = 1;
    } else if (actual_time >= (plan_time * 2)) {
      percentP = 0;
    } else {
      const value = (((plan_time - pending_time) - (actual_time - (plan_time - pending_time)))/(plan_time - pending_time ));
      percentP = parseFloat(value * 100);
    }
  }

  return percentP;
};

const calculatePercentQ = (index, plansData, otherData) => {
  let percentQ = '';

  const drillHoleNameActual = otherData.drill_hole_information[index].drill_hole_name;
  const dataPlan = plansData.find(v => v.drill_hole_name === drillHoleNameActual);

  const plan_drilling_depth = dataPlan?.plan_drilling_depth;
  const depth_deviation = otherData.drilling_depth[index].depth_deviation;

  if (plan_drilling_depth) {
    percentQ = parseFloat(((plan_drilling_depth - depth_deviation)/plan_drilling_depth));
  }

  return percentQ;
};

const calculatePercentOme = (index, otherData) => {
  let percentOme = '';
  let deduction = 0;

  const a = otherData.ome[index].a;
  const p = otherData.ome[index].p;
  const q = otherData.ome[index].q;

  const tempA = isNumber(a) ? a : 0;
  const tempP = isNumber(p) ? p : 0;
  const tempQ = isNumber(q) ? q : 0;

  percentOme = parseFloat(((tempA + tempP + tempQ)/3));

  if (percentOme > 100 && percentOme < 200) {
    const tempOme = _.cloneDeep(percentOme);
    deduction = tempOme - 100;
  
    percentOme = parseFloat(tempOme - deduction);
  }

  return percentOme;
};

const generateDrillingOmeActualData = (file) => {
  if(!isEmpty(file)) {
    const dataDrillHoleName = file.properties[0].drill_hole_information[0].drill_hole_name;
    const data = file.properties.find(v => Object.keys(v).includes('ome'))?.ome;
    let finalResult = dataDrillHoleName.map((drillHoleName, index) => {
      return data.reduce((result, currentObject, indexObject) => {
        const key = Object.keys(currentObject)[0];
        const value = currentObject[key][index]?.value;
        result[key] = value;
        if(indexObject === data?.length -1){
          result['drill_hole_name'] = drillHoleName?.value;
        }
        return result;
      }, {});
    });
    return finalResult;
  }
};

export {
  generateOmeTable,
  generateDrillingOmeActualData,
  calculateCategoryALoss,
  calculateCategoryPLoss,
  calculateCategoryQLoss,
  calculatePercentA,
  calculatePercentP,
  calculatePercentQ,
  calculatePercentOme
};
