import { Form, Image, Input, Button , ConfigProvider, Alert } from 'antd';

import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';

import { usePutNewPasswordMutation } from 'api/User';
import { setToken } from 'store/Auth';
import { useModalConfirmationContext } from 'components/ModalConfirmationProvider';

const CreatePassowrd = () => {
  const navigate = useNavigate();
  const loc = useLocation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { showModal, resetModal } = useModalConfirmationContext();

  const tokenData = new URLSearchParams(loc.search).get('token');

  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const [putPassword, {
    error,
    isSuccess,
    isError
  }] = usePutNewPasswordMutation();

  const onLogin = async (values) => {
    setIsLoading(true);
    const body = {
      password: values.password,
      confirm_password: values.confirm
    };
    putPassword(body);
  };

  useEffect(() => {
    if (tokenData) {
      dispatch(setToken(tokenData));
    }
  }, [tokenData]);

  useEffect(() => {
    if (isError) {
      setIsLoading(false);

      const { error: errorData } = error;
      setErrorMsg(errorData || error);
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      setIsLoading(false);
      showModal({
        isShown: true,
        type: 'success',
        title: 'Success!',
        message: 'Successfully create new password.',
        onSubmit: () => {
          resetModal();
          navigate('/login');
        }
      });
    }
  }, [isSuccess]);

  return (
    <div className='h-screen px-[8vw] py-[5vh] bg-gradient-to-r from-[#CDBAFA] to-[#CDDDF4]'>
      <div className='login-container'>
        <div className='grid grid-cols-2 gap-3 h-full items-center'>
          <div className='lg:col-span-1 max-lg:col-span-2 m-[7vw]'>
            <div className='flex text-left mb-5'>
              <Image
                preview={false}
                width={100}
                src='assets/logo-itm.svg'
              />
            </div>
            <div className='text-left'>
              <p className='font-semibold pt-1 text-[24px]'>Create New Password</p>
              <p className='text-[12px]'>Please create password for your account</p>
            </div>
            {
              errorMsg &&
              <Alert
                className='mt-5 bg-[#FF647C] text-white'
                message={errorMsg.message}
                type='error'
              />
            }
            <Form
              name="form-login"
              className='pt-5'
              layout='vertical'
              onFinish={onLogin}
              form={form}
              requiredMark={false}
            >
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
              >
                <div>
                  <p className='font-bold text-[14px]'>Create Password</p>
                  <Input.Password placeholder='Enter New Password' disabled={isLoading} />
                </div>
              </Form.Item>

              <Form.Item
                name="confirm"
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The new password that you entered do not match!'));
                    },
                  }),
                ]}
              >
                <div>
                  <p className='font-bold text-[14px]'>Confirm Password</p>
                  <Input.Password placeholder='Enter Confirm Password' disabled={isLoading} />
                </div>
              </Form.Item>
              <div className='flex flex-col mt-16'>
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: 'white',
                    },
                  }}
                >
                  <Button
                    className='bg-gradient-to-r from-[#462F92] to-[#7259C5] text-white font-bold'
                    type='text'
                    htmlType='submit'
                    loading={isLoading}
                  >
                    Save Password
                  </Button>
                </ConfigProvider>
              </div>
            </Form>
          </div>
          <div className='lg:col-span-1 max-lg:col-span-2 max-lg:hidden w-full h-full pr-4'>
            <div className='login-bg-img w-full h-full' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePassowrd;