import {
  useEffect,
  useRef,
  useState
} from 'react';
import { useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import { Select } from 'antd';
import { toast } from 'react-toastify';
import {  isEmpty } from 'lodash';

import { useSelector } from 'react-redux';
import {
  useGetGroupsQuery,
  usePutGroupMutation,
  useDeleteGroupMutation
} from 'api/Group';
import {
  ACTION,
  DEFAULT,
  EMPTY
} from 'constant';
import { groupColumns } from 'constant/TableGroupManagement';
import { getFullPath } from 'utils/PathUtility';
import { transformError } from 'utils/ErrorTransformer';

import FilterContainer from 'components/FilterContainer';
import TableData from 'components/TableData';
import ButtonAccent from 'components/ButtonAccent';
import { useModalConfirmationContext } from 'components/ModalConfirmationProvider';

const GroupManagement = () => {
  const navigate = useNavigate();
  const {
    page,
    pageSize,
    sortBy,
    orderBy,
    search
  } = useSelector((state) => state.table);

  const initialFilter = { name: EMPTY.STRING, is_active: EMPTY.STRING };
  const [filter, setFilter] = useState(initialFilter);
  const [groupNames, setGroupNames] = useState([]);

  const groupRef = useRef(null);
  const isDeleteRef = useRef(false);

  const { showModal, resetModal } = useModalConfirmationContext();

  const statuses = [
    { label: 'Active', value: 'true' },
    { label: 'Inactive', value: 'false' }
  ];

  const goToPathByType = (id, type) => navigate({
    pathname: getFullPath({
      parent: 'group-management',
      child: 'form',
      type
    }),
    ...(type === ACTION.DETAIL || type === ACTION.EDIT)
      ? { search: createSearchParams({ id }).toString() }
      : undefined
  });
  
  const {
    data: groups,
    refetch: groupRefetch,
    isFetching: groupIsFetching,
    isError: groupIsError,
    error: groupError
  } = useGetGroupsQuery(
    {
      page,
      limit: pageSize,
      sort_by: sortBy,
      order_by: orderBy,
      ...filter,
      ...search
        ? { search }
        : undefined
    },
    { refetchOnMountOrArgChange: true }
  );

  const [putGroup, {
    isLoading: putGroupIsLoading,
    isSuccess: putGroupIsSuccess,
    isError: putGroupIsError,
    error: putGroupError
  }] = usePutGroupMutation();

  const [deleteGroup, {
    isLoading: deleteGroupIsLoading,
    isSuccess: deleteGroupIsSuccess,
    isError: deleteGroupIsError,
    error: deleteGroupError
  }] = useDeleteGroupMutation();

  const onFilter = (key, value) => {
    setFilter((prevState) => ({
      ...prevState,
      [key]: value
    }));
  };

  const resetFilter = () => {
    setFilter(initialFilter);
  };
  
  const onAction = (record, type, text) => {
    /**
     * @param {Object} record
     * @param {string} record.id
     * @param {string} record.name
     * @param {boolean} record.is_active
     * @param {string} type
     * @param {boolean|undefined} text
     * */
    groupRef.current = record;

    switch (type) {
      case ACTION.CREATE: {
        isDeleteRef.current = false;
        goToPathByType(null, type);

        break;
      }
      case ACTION.SWITCH_STATUS: {
        const { id } = record;
        isDeleteRef.current = false;
        putGroup({
          id,
          body: { is_active: !text }
        });

        break;
      }
      case ACTION.DELETE: {
        const { id } = record;
        isDeleteRef.current = true;
        showModal({
          isShown: true,
          type: 'delete',
          message: `Are you sure you want to delete ${groupRef.current.name} ?`,
          onSubmit: () => {
            deleteGroup({ id });

            resetModal();
          }
        });

        break;
      }
      default: {
        const { id } = record;
        isDeleteRef.current = false;
        goToPathByType(id, type);

        break;
      }
    }
  };

  useEffect(() => {
    if (!isEmpty(groups?.roles) && !Boolean(groups?.error)) {
      setGroupNames(
        groups.roles.map(
          ({ name }) => ({ label: name, value: name })
        )
      );
    }
  }, [groups]);

  useEffect(() => {
    if (putGroupIsSuccess || deleteGroupIsSuccess) {
      const generateMessage = () => {
        if (isDeleteRef.current) {
          return 'deleted';
        }

        return groupRef.current.is_active
          ? 'deactivated'
          : 'activated';
      };

      toast.success(
        `You have successfully ${generateMessage()} ${groupRef.current.name}`,
        { toastId: 'put-group-success-toast' }
      );

      groupRefetch();
    }
  }, [putGroupIsSuccess, deleteGroupIsSuccess]);
  
  useEffect(() => {
    if (groupIsError
      || putGroupIsError
      || deleteGroupIsError
    ) {
      toast.error(
        transformError(groupError
          || putGroupError
          || deleteGroupError
        ).message,
        { toastId: 'group-list-error' }
      );
    }
  }, [
    groupIsError,
    groupError,
    putGroupIsError,
    putGroupError,
    deleteGroupIsError,
    deleteGroupError
  ]);
  
  return (
    <div className="p-5 bg-white rounded-lg">
      <FilterContainer onReset={resetFilter}>
        <label>
          <span className="font-bold">
            Group Name
          </span>
          <Select
            placeholder="Select Group Name"
            value={filter['name'] || null}
            options={groupNames}
            className="mt-2 w-full"
            onChange={(val) => onFilter('name', val)}
          />
        </label>
        <label>
          <span className="font-bold">
            Status
          </span>
          <Select
            placeholder="Select status"
            value={filter['is_active'] || null}
            options={statuses}
            className="mt-2 w-full"
            onChange={(val) => onFilter('is_active', val)}
          />
        </label>
      </FilterContainer>
      <TableData
        isTransparent
        rowKey="id"
        isLoading={groupIsFetching}
        columns={
          groupColumns(
            Boolean(putGroupIsLoading || deleteGroupIsLoading),
            sortBy,
            orderBy === DEFAULT.KEY_SORT_ASC
              ? DEFAULT.SORT_ASC
              : DEFAULT.SORT_DESC,
            onAction,
            onAction,
            onAction,
            onAction
          )
        }
        dataSource={groups?.roles}
        totalData={groups?.total_data}
      >
        <ButtonAccent
          title="Create"
          onClick={() => onAction(null, ACTION.CREATE)}
        />
      </TableData>
    </div>
  );
};

export default GroupManagement;