import {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { useLocation, useNavigate } from 'react-router';

import { AiOutlineClose } from 'react-icons/ai';
import {
  FiEye,
  FiTrash,
  FiDownload
} from 'react-icons/fi';
import {
  Tag,
  Divider,
  Image,
  Tooltip,
  Modal
} from 'antd';
import { toast } from 'react-toastify';

import { ACTION, PERMISSION, URL } from 'constant';
import { getPathType } from 'utils/PathUtility';
import { transformError } from 'utils/ErrorTransformer';
import { useTableWrapper } from 'utils/TableResponsive';
import { usePermissionManager } from 'utils/PermissionManager';
import { every, isEmpty, toLower } from 'lodash';
import { useGetPlanDetailByIdQuery } from 'api/DrillingPlan';
import { 
  usePostQualityCheckMutation,
  useApproveOrReviseQualityMutation,
  useGetQualityLogsQuery,
  useLazyGetQualityCheckQuery
} from 'api/QualityChecklist';
import { 
  generateQualityChecklistColumns,
  generateQualityChecklistData,
  generateBody
} from 'constant/TableQualityChecklist';

import TableRaw from 'components/TableRaw';
import ButtonAccent from 'components/ButtonAccent';
import InputFileAccent from 'components/InputFileAccent';
import { useImagePreviewContext } from 'components/ImagePreviewProvider';
import { useModalCommentContext } from 'components/ModalCommentProvider';
import { useSideCarContext } from 'components/SideCarApprovalHistoryProvider';
import PreventNavigation from 'constant/PreventNavigation';
import { useModalConfirmationContext } from 'components/ModalConfirmationProvider';
import moment from 'moment';

const QualityChecklist = () => {
  const loc = useLocation();
  const navigate = useNavigate();

  const [qualityCheckData, setQualityCheckData] = useState(null);
  const [status, setStatus] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isModalShown, setIsModalShown] = useState(false);
  const [showComment, setShowComment] =  useState(false);
  const [uploadFiles, setUploadFiles] = useState([]);

  const isDraftRef = useRef(false);
  const isApproveRef = useRef(false);
  const recordRef = useRef(null);
  const logsRef = useRef([]);

  const { showPreview } = useImagePreviewContext();
  const { showCommentModal, resetCommentModal } = useModalCommentContext();
  const { showSideCar } = useSideCarContext();
  const { wrapperWidth } = useTableWrapper();
  const { isUserHasPermissionInFeature } = usePermissionManager();
  const {showModal, resetModal} = useModalConfirmationContext();
  const isDetailMode = getPathType(loc) === 'detail';
  const qualityCheckId = new URLSearchParams(loc.search).get('id');
  const qualityCheckVers = new URLSearchParams(loc.search).get('version');

  const {
    data: planByIdData,
    isError: planByIdDataIsError,
    error: planByIdDataError
  } = useGetPlanDetailByIdQuery(
    { idPlan: qualityCheckId },
    { refetchOnMountOrArgChange: true }
  );

  const [
    getQualityCheck,
    {
      data: qualityTableData,
      isFetching: qualityTableIsFetching,
      isError: qualityTableIsError,
      error: qualityTableError
    }
  ] = useLazyGetQualityCheckQuery(
    { refetchOnMountOrArgChange: true }
  );

  const [postQuality, {
    isLoading: postQualityIsLoading,
    isSuccess: postQualityIsSuccess,
    isError: postQualityIsError,
    error: postQualityError
  }] = usePostQualityCheckMutation();

  const [approveOrRevise, {
    isLoading: approveOrReviseIsLoading,
    isSuccess: approveOrReviseIsSuccess,
    isError: approveOrReviseIsError,
    error: approveOrReviseError
  }] = useApproveOrReviseQualityMutation();

  const { data: logs } = useGetQualityLogsQuery(
    { 
      id: qualityCheckId,
      version: qualityCheckVers
    },
    { refetchOnMountOrArgChange: true }
  );

  const onUpload = (record) => {
    recordRef.current = record;

    setUploadFiles(record.evidence);
    setIsModalShown(true);
  };

  const postQualityCheck = (isDraft) => {
    isDraftRef.current = isDraft;

    const body = generateBody(
      isDraft,
      qualityCheckData,
      planByIdData?.latest_version
    );

    postQuality({
      id: qualityCheckId,
      body
    });
  };

  useEffect(() => {
    if (!isEmpty(qualityTableData)) {
      setQualityCheckData(generateQualityChecklistData(qualityTableData));
      setStatus(qualityTableData.status);
    }
  }, [qualityTableData]);

  useEffect(() => {
    if (qualityCheckData && qualityCheckData.length) {
      const qualityValidations = qualityCheckData.map((item) => {
        if (item.quality_checklist_id) {
          if (item.quality_checklist) {
            return Boolean(item.evidence && item.evidence.length);
          }

          return false;
        }
        
        return true;
      });

      setIsValid(every(qualityValidations));
    }
  }, [qualityCheckData]);

  useEffect(() => {
    logsRef.current = logs || [];
  }, [logs]);

  const generateQualityCheckRowStyle = (record) => {
    if (isNaN(Number(record.number))) {
      return 'font-bold bg-[#607496] text-white';
    }

    return 'bg-gray-100';
  };

  const onAdd = () => {
    setQualityCheckData((prevState) =>
      prevState.map((item) => {
        if (uploadFiles.some((file) => file.record?.quality_checklist_id === item.quality_checklist_id)) {
          return {
            ...item,
            quality_checklist: true,
            evidence: uploadFiles
          };
        }

        return { ...item };
      })
    );

    setUploadFiles([]);
    setIsModalShown(false);
  };

  const onCancel = () => {
    setUploadFiles([]);
    setIsModalShown(false);
  };

  const onUploadFile = (file, record) => {
    if (file) {
      setUploadFiles((prevState) => {
        if (prevState && prevState.length) {
          return [
            ...prevState,
            {
              ...file,
              record,
              updated_date: moment(new Date())
            }
          ];
        }

        return [{
          ...file,
          record,
          updated_date: moment(new Date())
        }];
      });
    }
  };

  const onDeleteFile = (file) => {
    setUploadFiles((prevState) => prevState.filter((item) => item.id !== file.id));
  };

  const handleBackButton = () => {
    if(isDetailMode) {
      navigate(`/${URL.ACTIVITY_LEVEL}/${URL.GEOLOGY_OE}/drilling-plan`);
    } else {
      showModal({
        isShown: true,
        type: 'confirmation',
        title:'Are you sure want to leave this page?',
        message: 'Changes you made may not be saved',
        onSubmit: () => {
          navigate(`/${URL.ACTIVITY_LEVEL}/${URL.GEOLOGY_OE}/drilling-plan`);
          resetModal();
        }
      });
    }
  };

  const generateUploadView = (record) => {
    return (
      <>
        {
          !isDetailMode && (
            <InputFileAccent
              noTitle
              isResetAfter
              isAllTypes
              onChange={(file) => onUploadFile(file, record)}
              onDrop={(file) => onUploadFile(file, record)}
              fileUploaded={uploadFiles}
            />
          )
        }
        {
          (uploadFiles && uploadFiles.length)
            ? (
              <>
                {
                  uploadFiles.map((item, index) => (
                    <div
                      key={`file-upload-${index + 1}`}
                      className="mb-3 p-3 flex flex-row items-center bg-violet-100 rounded-lg"
                    >
                      <Image
                        preview={false}
                        width={100}
                        src={item.thumbnail}
                        className="rounded-lg"
                      />
                      <span className="w-64 ml-3 mr-5 truncate">
                        {item.fileName}
                      </span>
                      <div className="ml-auto flex flex-row items-center gap-x-3">
                        <div
                          className="p-1 rounded-full bg-[#2D3D5A] cursor-pointer"
                          onClick={() => onDownload(item)}
                        >
                          <Tooltip title={'Download'} trigger={'hover'}>
                            <FiDownload
                              size="18px"
                              className="text-white"
                            />
                          </Tooltip>
                        </div>
                        <div
                          className="p-1 rounded-full bg-[#2D3D5A] cursor-pointer"
                          onClick={() => showPreview({
                            name: item.fileName,
                            size: item.size,
                            url: item.thumbnail
                          })}
                        >
                          <Tooltip title={'View Detail'} trigger={'hover'}>
                            <FiEye
                              size="18px"
                              className="text-white"
                            />
                          </Tooltip>
                        </div>
                        {
                          !isDetailMode && (
                            <div
                              className="p-1 bg-pink-700 rounded-full cursor-pointer"
                              onClick={() => onDeleteFile(item)}
                            >
                              <Tooltip title={'Delete'} trigger={'hover'}>
                                <FiTrash className="text-white" />
                              </Tooltip>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  ))
                }
              </>
            )
            : (<></>)
        }
      </>
    );
  };

  const onDownload = (file) => {
    const docLink = document.createElement('a');
    docLink.href = file.url;

    document.body.appendChild(docLink);
    docLink.click();
    document.body.removeChild(docLink);
  };

  const reviseQuality = () => {
    isApproveRef.current = false;
    
    showCommentModal({
      isShown: true,
      message: 'Are you sure you want to revise this planning?',
      isShowDescription: true,
      onSubmit: (comment) => {
        approveOrRevise({
          id: qualityCheckId,
          type: ACTION.REVISE,
          body: { comment }
        });

        resetCommentModal();
      }
    });
  };

  const approveQuality = () => {
    isApproveRef.current = true;
    
    showCommentModal({
      isShown: true,
      message: 'Are you sure you want to approve this planning?',
      isShowDescription: false,
      onSubmit: () => {
        approveOrRevise({
          id: qualityCheckId,
          type: ACTION.APPROVE
        });
        
        resetCommentModal();
      }
    });
  };

  useEffect(() => {
    if (postQualityIsSuccess) {
      toast.success(
        `You have successfully ${isDraftRef.current ? 'save quality checklist as draft' : 'submitted quality checklist'}`,
        { toastId: 'post-quality-toast-success' }
      );
      
      navigate(`/${URL.ACTIVITY_LEVEL}/${URL.GEOLOGY_OE}/drilling-plan`);
    }
  }, [postQualityIsSuccess]);

  useEffect(() => {
    if (approveOrReviseIsSuccess) {
      const generateMessage = () => {
        if (approveOrReviseIsSuccess) {
          return isApproveRef.current
            ? 'approved'
            : 'revised';
        }
      
        return 'created';
      };
    
      toast.success(
      `You have successfully ${generateMessage()} quality checklist`,
      { toastId: `${approveOrReviseIsSuccess ? 'create-summary' : 'approve-or-revise'}-toast-success` }
      );
    
      navigate(`/${URL.ACTIVITY_LEVEL}/${URL.GEOLOGY_OE}/drilling-plan`);
      return;
    }
  }, [approveOrReviseIsSuccess]);

  useEffect(() => {
    if (planByIdDataIsError
      || qualityTableIsError
      || postQualityIsError
      || approveOrReviseIsError
    ) {
      const generateToastId = () => {
        switch (true) {
          case planByIdDataIsError:
            return 'plan-by-id';
          case qualityTableIsError:
            return 'quality-table';
          case postQualityIsError:
            return 'post-quality';
          case approveOrReviseIsError:
            return 'approval-quality';
          default:
            return 'default';
        }
      };
      
      toast.error(
        transformError(
          planByIdDataError
          || qualityTableError
          || postQualityError
          || approveOrReviseError
        ).message,
        { toastId: `${generateToastId()}-toast-error` }
      );
    }
  }, [
    planByIdDataIsError,
    qualityTableIsError,
    postQualityIsError,
    approveOrReviseIsError
  ]);

  useEffect(() => {
    if(qualityCheckId && !isEmpty(planByIdData)) {
      getQualityCheck({id:qualityCheckId, version: isDetailMode? qualityCheckVers : planByIdData?.latest_version});
    }
  },[qualityCheckVers, qualityCheckId, planByIdData, isDetailMode]);

  const handleCloseSite = useCallback((ev) => ev.preventDefault() ,[]);
  
  useEffect(() => {
    PreventNavigation(isDetailMode, handleCloseSite);
  }, []);
  
  return (
    <>
      <div className='bg-white rounded-xl'>
        <div className='pt-5 px-5 flex flex-row justify-between items-center'>
          <span className='text-lg font-bold'>Quality Checklist</span>
          <div className='flex items-center'>
            <Tag className="px-3 py-2 font-bold rounded-2xl bg-[#2BB8A4]/[0.1] text-[#01B59C] border-none">
              <span className='text-sm'>
                {
                `
                  V${isDetailMode ? qualityCheckVers : planByIdData?.latest_version} / 
                  ${planByIdData?.year} / 
                  ${planByIdData?.company?.alias_name} / 
                  Drilling Plan
                `
                }
              </span>
            </Tag>
            {
              toLower(status).includes('revised') &&
              <ButtonAccent
                title='Show Comment'
                onClick={() => setShowComment(true)}
                className='w-36 mr-2'
              />
            }
            {
              !toLower(status).includes('progress') &&
              <ButtonAccent
                title='History'
                onClick={() => showSideCar(logsRef.current, 'drilling')}
              />
            }
          </div>
        </div>
        <div
          className="p-5 overflow-x-auto"
          style={{ maxWidth: wrapperWidth }}
        >
          <TableRaw
            isLoading={qualityTableIsFetching}
            scrollWidth="max-content"
            columns={
              generateQualityChecklistColumns(
                isDetailMode,
                onUpload
              )
            }
            dataSource={qualityCheckData}
            customRow={(record) => generateQualityCheckRowStyle(record)}
          />
        </div>
        {
          isModalShown && (
            <div className="py-5 w-full h-full absolute flex flex-row items-start justify-center bg-black/25 left-0 top-0 z-20 overflow-y-auto">
              <div className="p-5 w-1/2 flex flex-col bg-white rounded-lg">
                <div className="flex flex-row items-center">
                  <span className="font-bold">
                    Upload Evidence
                  </span>
                  <div
                    className="ml-auto p-1 bg-violet-200 rounded-full cursor-pointer"
                    onClick={() => setIsModalShown(false)}
                  >
                    <AiOutlineClose size="18px" className="text-[#2D3D5A]" />
                  </div>
                </div>
                <Divider />
                {
                  generateUploadView(recordRef.current)
                }
                <div className="mt-5 w-full flex flex-row items-center">
                  <div className="ml-auto flex flex-row gap-x-3">
                    <ButtonAccent
                      isBordered
                      title="Cancel"
                      onClick={onCancel}
                    />
                    {
                      !isDetailMode && (
                        <ButtonAccent
                          title="Upload"
                          onClick={onAdd}
                          isDisabled={!(uploadFiles && uploadFiles.length)}
                        />
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </div>
      <div className="mt-5 w-full flex flex-row items-center">
        {
          !isDetailMode
          && ['draft', 'revised', 'on progress'].includes(toLower(status))
          && isUserHasPermissionInFeature(loc.pathname, PERMISSION.CREATE)
          && (
            <ButtonAccent
              isBordered
              isDisabled={postQualityIsLoading}
              isLoading={postQualityIsLoading}
              size="lg"
              title="Save As Draft"
              onClick={() => postQualityCheck(true)}
            />
          )
        }

        <div className="ml-auto flex flex-row items-center gap-x-3">
          <ButtonAccent
            isBordered
            title="Back"
            onClick={() =>handleBackButton() }
          />
          {
            !isDetailMode
            && ['draft', 'revised', 'on progress'].includes(toLower(status))
            && isUserHasPermissionInFeature(loc.pathname, PERMISSION.CREATE)
            && (
              <ButtonAccent
                isDisabled={!isValid}
                isLoading={postQualityIsLoading}
                title="Submit"
                onClick={() => postQualityCheck(false)}
              />
            )
          }
          {
            toLower(status).includes('waiting')
            && isUserHasPermissionInFeature(loc.pathname, PERMISSION.APPROVE)
            && (
              <ButtonAccent
                isBordered
                isBorderError
                isLoading={approveOrReviseIsLoading}
                isDisabled={!isValid}
                title="Revise"
                onClick={() => reviseQuality()}
              />
            )
          }
          {
            toLower(status).includes('waiting')
            && isUserHasPermissionInFeature(loc.pathname, PERMISSION.APPROVE)
            && (
              <ButtonAccent
                isSuccess
                isLoading={approveOrReviseIsLoading}
                isDisabled={!isValid}
                title="Approve"
                onClick={() => approveQuality()}
              />
            )
          }
        </div>
      </div>
      <Modal
        title="Revised Comment(s)"
        open={showComment}
        onOk={() => {setShowComment(false);}}
        onCancel={() => {setShowComment(false);}}
        footer={null}
      >
        <p>{logs?.find(v => v.action === 'Send Back')?.comment}</p>
      </Modal>
    </>
  );
};

export default QualityChecklist;