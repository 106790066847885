import moment from 'moment';
import { FiEye } from 'react-icons/fi';
import { GiPencil } from 'react-icons/gi';
import { toLower, upperFirst } from 'lodash';
import { ACTION, PERMISSION } from 'constant';

const generateStatusBg = (status) => {
  const loweredStatus = toLower(status);
  const isStatusIncludes = (value) => loweredStatus.includes(value);

  switch (true) {
    case isStatusIncludes('draft'):
      return 'bg-yellow-500/25 text-yellow-500';
    case isStatusIncludes('submitted'):
      return 'bg-[#2D3D5A20] text-[#2D3D5A]';
    case isStatusIncludes('approved'):
      return 'bg-emerald-400/25 text-emerald-400';
    case isStatusIncludes('rejected'):
      return 'bg-pink-400/25 text-pink-700';
    default:
      return 'bg-black/25 text-black';
  }
};

const isPeriodNotExpired = (dateFrom, dateTo) => {
  return Boolean(
    moment().isSameOrAfter(moment(dateFrom), 'month') && moment().isSameOrBefore(moment(dateTo), 'month')
  );
};

const confidentLevelColumns = (
  url,
  isUserHasPermissionInFeature,
  sortBy,
  orderBy,
  onView,
  onEdit,
  page,
  pageSize
) => [
  {
    dataIndex: 'no',
    title: 'No',
    render: (text, record, index) => (
      <>{((page-1) * pageSize) + (index + 1)}</>
    )
  },
  {
    dataIndex: 'title',
    title: 'Title',
    sorter: true,
    sortOrder: sortBy === 'title' ? orderBy : null,
  },
  {
    dataIndex: 'period',
    title: 'Periode',
    sorter: true,
    sortOrder: sortBy === 'period' ? orderBy : null,
    render: (text, record) => (
      <>{`${moment(record.date_from).format('MMMM YYYY')} - ${moment(record.date_to).format('MMMM YYYY')}`}</>
    )
  },
  {
    dataIndex: 'status',
    title: 'Status',
    sorter: true,
    sortOrder: sortBy === 'status' ? orderBy : null,
    render: (text) => (
      <div className={`px-2 py-1 flex flex-row justify-center font-bold rounded-lg ${generateStatusBg(text)}`}>
        {upperFirst(toLower(text))}
      </div>
    )
  },
  {
    title: 'Action',
    render: (text, record) => (
      <div className="flex flex-row justify-center items-center gap-x-3">
        {
          isUserHasPermissionInFeature(url, PERMISSION.READ) && (
            <div
              className="rounded-full bg-[#2D3D5A] cursor-pointer"
              onClick={() => onView(record.id || record.confident_level_id, ACTION.DETAIL)}
            >
              <FiEye
                size="24px"
                className="p-1 text-white"
              />
            </div>
          )
        }
        {
          (isPeriodNotExpired(record.date_from, record.date_to) && isUserHasPermissionInFeature(url, PERMISSION.UPDATE))
          && (
            <div
              className="rounded-full bg-[#2D3D5A] cursor-pointer"
              onClick={() => onEdit(record.id || record.confident_level_id, ACTION.EDIT)}
            >
              <GiPencil
                size="24px"
                className="p-1 text-white"
              />
            </div>
          )
        }
      </div>
    )
  }
];

export { confidentLevelColumns };