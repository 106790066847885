import { Select, Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { FaCaretDown } from 'react-icons/fa';
import { FiEye } from 'react-icons/fi';

const generateDrillingPlanColumns = (
  version, 
  onChangeVersion, 
  handleContinuePlanning, 
  handleActionButton, 
  handleEditPlanning
) => [
  {
    dataIndex: 'planning_name',
    title: 'Planning Name',
    width: '150px',
    sorter: true,
    onCell: (record) => ({      
      colSpan: !record.planning_type? 6:1,
      className: 'pr-0'
    }),
    render: (value, record) => {
      if (value === `${record.company} ${record.year}`) {
        let totalFirstLength = record.status[0].length;
        const nextElementsCheck = record.status
          ?.every((subArray) =>
          {
            if(subArray.length < totalFirstLength) {
              return false;
            }else {
              return subArray.every((item) => item !== 'DRAFT' && item !== 'ON PROGRESS');
            }
          }
          );
        const isWaitingForApproval = record.status.some(
          (innerArray) => innerArray.includes('WAITING FOR APPROVAL')
        );
        return (
          <div className="flex flex-row gap-4 items-center font-bold">
            {value}
            {!isWaitingForApproval && !nextElementsCheck && (
              <div onClick={() => handleContinuePlanning(record.key)} className="bg-[#607496] text-white text-[12px] rounded-lg px-5 py-1 text-center w-40 cursor-pointer">
                Continue Planning
              </div>
            )}
            {nextElementsCheck && (
              <div 
                onClick={() => {
                  let version = record?.children[0]?.utils[record?.children[0]?.utils.length - 1]?.value;
                  handleEditPlanning(record.key, version);
                }}
                className="bg-[#607496] text-white text-[12px] font-[Segoe-UI-Bold] rounded-lg px-2 py-1 w-20 text-center cursor-pointer"
              >
                  Edit
              </div>
            )}
          </div>
        );
      }
      return <div>{value}</div>;
    },
  },
  {
    dataIndex: 'planning_type',
    title: 'Drilling Stage',
    width: '150px',
    sorter: true,
    onCell:(record)=>({
      colSpan: !record.planning_type && 0
    }),
    render: (value) => {
      const populateDrillingStage = () =>{
        if(value === 'PREPRODUCTION'){
          return 'Pre-production';
        }else{
          return 'Exploration';
        }
      };
      return <div>{populateDrillingStage()}</div>;
    },
  },
  {
    dataIndex: 'utils',
    title: 'Version',
    width: '150px',
    sorter: true,
    onCell:(record)=>({
      colSpan: !record.planning_type && 0
    }),
    render: (value, record) => {
      const versionAttribute = `${record.company}_${record.year}_${record.planning_name}`;
      const formattedVersions = value?.map((val) => ({
        label: val.label,
        value: val.value,
      }));
      return (
        value && (
          <Select
            bordered={false}
            suffixIcon={<FaCaretDown size={15} color="#2BB8A4" />}
            className="border-0 w-fit"
            value={
              !isEmpty(version) && !isEmpty(version[versionAttribute])
                ? version[versionAttribute]
                : formattedVersions[0]
            }
            options={formattedVersions?.reverse()}
            onChange={(val) => onChangeVersion(versionAttribute, val)}
          />
        )
      );
    },
  },
  {
    dataIndex: 'utils',
    title: 'Status',
    width: '150px',
    sorter: true,
    onCell:(record)=>({
      colSpan: !record.planning_type && 0
    }),
    render: (value, record) => {
      const versionAttribute = `${record.company}_${record.year}_${record.planning_name}`;
      let status = value?.find((val) => {
        return version?.hasOwnProperty(versionAttribute)
          ? val.value === version[versionAttribute]?.value
          : val.value === value[0].value;
      })?.status;
      switch (status?.toUpperCase()) {
        case 'APPROVED':
          return (
            <div className="bg-[#26A1AF12] w-fit text-[#26A1AF] font-[Segoe-UI-Bold] text-[12px] text-center px-3 py-2 rounded-lg">
              Approved
            </div>
          );
        case 'REVISED':
          return (
            <div className="bg-[#EB554510] w-fit text-[#EB5545] font-[Segoe-UI-Bold] text-[12px] text-center px-3 py-2 rounded-lg">
              Revise
            </div>
          );
        case 'DRAFT':
          return (
            <div className="bg-[#E4E4E4] w-fit text-[#AAAAAA] font-[Segoe-UI-Bold] text-[12px] text-center px-3 py-2 rounded-lg">
              Draft
            </div>
          );
        case 'ON PROGRESS':
          return (
            <div className="bg-[#0084F410] w-fit text-[#3B88CA] font-[Segoe-UI-Bold] text-[12px] text-center px-3 py-2 rounded-lg">
              On Progress
            </div>
          );
        case 'DONE':
          return (
            <div className="bg-[#2BB8A410] w-fit text-[#2BB8A4] font-[Segoe-UI-Bold] text-[12px] text-center px-3 py-2 rounded-lg">
              Done
            </div>
          );
        case 'WAITING FOR APPROVAL':
          return (
            <div className="bg-[#FF730110] w-fit text-[#FF7301] font-[Segoe-UI-Bold] text-[12px] text-center px-3 py-2 rounded-lg">
              Waiting for Approval
            </div>
          );
        default:
          return <div></div>;
      }
    },
  },
  {
    dataIndex: 'utils',
    title: 'Date',
    width: '150px',
    sorter: true,
    onCell:(record)=>({
      colSpan: !record.planning_type && 0
    }),
    render: (value, record) => {
      const versionAttribute = `${record.company}_${record.year}_${record.planning_name}`;
      let date = value?.find((val) => {
        return version?.hasOwnProperty(versionAttribute)
          ? val.value === (version ? version[versionAttribute]?.value : 1)
          : val.value === value[0].value;
      })?.date;
      return date && <div>{moment(date).format('DD/MMM/YYYY')}</div>;
    },
  },
  {
    dataIndex: 'action',
    title: 'Action',
    width: '100px',
    align:'center',
    sorter: false,
    onCell:(record)=>({
      colSpan: !record.planning_type && 0
    }),
    render: (_, record) =>
    {
      const versionAttribute = `${record.company}_${record.year}_${record.planning_name}`;
      const choosedVersion = version? version[versionAttribute]?.value : record.utils? record.utils[0].value : 1;
      let id = record.key;
      return record.planning_type && (
        <div className="flex flex-row justify-center items-center gap-x-3">
          <div
            className="rounded-full bg-[#2BB8A4] cursor-pointer"
            onClick={() => handleActionButton(id,record.planning_name, choosedVersion )}
          >
            <Tooltip title={'View Detail'} trigger={'hover'}>
              <FiEye size="24px" className="p-1 text-white" />
            </Tooltip>
          </div>
        </div>
      );}
  },
];

const generateDrillingPlanData = (data) => {
  if (data) {
    let transformedData = [];
    data.data.forEach((drillingPlan) => {
      let status = drillingPlan.properties.map((props) =>
        props.versions.map((version) => version.status.toUpperCase())
      );

      transformedData.push({
        planning_name: `${drillingPlan.company} ${drillingPlan.year}`,
        company: drillingPlan.company,
        year: drillingPlan.year,
        status,        
        planning_type: null,
        version: null,
        date: null,
        key: drillingPlan.id,
        id: `${drillingPlan.id}-${drillingPlan.company}-${drillingPlan.year}`
      });

      let children = [];
      drillingPlan.properties.forEach((properti) => {
        children.push({
          planning_name: properti.planning_name,
          planning_type: drillingPlan.planning_type,
          utils: properti.versions,
          company: drillingPlan.company,
          year: drillingPlan.year,
          key: drillingPlan.id,
          id: `${drillingPlan.id}-${properti.planning_name}`
        });
      });
      let temp = transformedData.map((data) => {
        if( data?.children ){
          return {...data};
        }else{
          return {...data, children};  
        }
      });
      transformedData = temp;
    });


    return transformedData;
  }

  return [];
};

export { generateDrillingPlanColumns, generateDrillingPlanData };
