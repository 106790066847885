import { drillHoleName } from 'constant/TableDrillingSummary';
import { 
  generateDrillingSamplesInformationTable
} from 'constant/TableDrillingSummaryActual/TableDrillingSamplesInformation';
import TableDrillingSumarry from 'pages/DrillingPlan/DrillingSummary/TableDrillingSummary';

const DrillingSamplesInformation = (props) => {

  const { isDetailMode, drillingSamplesInformationData, onChange, onDeleteRow, otherData } = props;
  const columns = generateDrillingSamplesInformationTable(
    drillingSamplesInformationData,
    isDetailMode,
    onChange,
    onDeleteRow
  );

  const populateDrillHoleName = () => {
    let finalData = drillingSamplesInformationData.map((data, index) => {
      return {
        ...data, 
        drill_hole_name: otherData.drill_hole_information[index].drill_hole_name,
      };
    });
    return finalData;
  };
  columns.splice(1, 0, drillHoleName);

  return <TableDrillingSumarry 
    columns={ columns }
    tableData={ populateDrillHoleName() }
  />;
};

export default DrillingSamplesInformation;