import { RxCross2 } from 'react-icons/rx';
import { GoPlus } from 'react-icons/go';
import { isEmpty } from 'lodash';

const generateResurveyTable = (
  payload, 
  onChange, 
  onAdd, 
  onDeleteRow,
  onAddSampleId,
  deleteSampleId,
  isDetailMode
) => [
  {
    dataIndex: 'action',
    title: 'Action',
    width: 5,
    sorter: false,
    align: 'center',
    fixed: 'left',
    render: (_, record, index) => {
      let isParent = record?.hasOwnProperty('drill_hole_name');   
      let dataDrillHoleName = payload.filter(item => item?.hasOwnProperty('drill_hole_name')); 
      return (
        <div className="flex justify-center">
          {payload.length <= 1 || dataDrillHoleName.length <= 1 ? (
            <></>
          ) : isParent && !isDetailMode && (
            <RxCross2
              size={28}
              color={'red'}
              className={'rounded-full p-1 cursor-pointer bg-red-200 '}
              onClick={() => {
                onDeleteRow(record);
              }}
            />
          )}
        </div>
      );
    },
  },
  {
    dataIndex: 'drill_hole_name',
    title: 'Drill Hole Name',
    width: '166px',
    align: 'center',
    fixed: 'left',
    sorter: false,
    render: (value, record) => {
      let isParent = record?.hasOwnProperty('drill_hole_name');
      return isParent && (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {value}
        </div>
      );
    },
  },
  {
    dataIndex: 'sample_id',
    title: 'Sample ID',
    width: '200px',
    align: 'center',
    fixed: 'left',
    sorter: false,
    render: (value, record, index) => {
      let isParent = record?.hasOwnProperty('drill_hole_name');
      return (
        <div className='flex flex-row gap-2'>
          {!isDetailMode && (
            !isParent? (
              <RxCross2
                size={28}
                color={'red'}
                className={'rounded-full p-1 cursor-pointer bg-red-200 '}
                onClick={() => deleteSampleId(record)}
              />
            ):(
              <GoPlus
                size={28}
                color={'green'}
                className={'rounded-full p-1 cursor-pointer bg-green-200'}
                onClick={onAddSampleId} 
              />
            )
          )}
          <div className='w-full flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
            {value}
          </div>
        </div>
      );
    },
  },
  {
    dataIndex: 'resurvey_easting',
    title: 'Resurvey Easting',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {value}
        </div>
      );
    },
  },
  {
    dataIndex: 'resurvey_northing',
    title: 'Resurvey Northing',
    width: '196px',
    align: 'center',
    sorter: false,
    render: (value) => {
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {value}
        </div>
      );
    },
  },
  {
    dataIndex: 'resurvey_latitude',
    title: 'Resurvey Latitude',
    width: '196px',
    align: 'center',
    sorter: false,
    render: (value) => {
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {value}
        </div>
      );
    },
  },
  {
    dataIndex: 'resurvey_longitude',
    title: 'Resurvey Longitude',
    width: '196px',
    align: 'center',
    sorter: false,
    render: (value) => {
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {value}
        </div>
      );
    },
  },
];

const generateResurveyData = (data) => {
  if(!isEmpty(data)) {
    let dataSampleInformation = data?.find(item => item.resurvey)?.resurvey;
    let result = [];
    dataSampleInformation?.forEach((sample, indexSample) => {
      sample?.children?.forEach((child, index) => {
        result.push({
          localId: sample?.localId || indexSample + 1,
          ...(index === 0) && {drill_hole_name:sample.drill_hole_name},
          ...child
        });
      });
    });
    return result;
  }
};

export { generateResurveyTable, generateResurveyData };