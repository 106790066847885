import { useLocation, useNavigate } from 'react-router';
import { toLower } from 'lodash';

import { generateMenu } from 'constant/Menu';
import { EMPTY } from 'constant';
import { ellipsized } from 'utils/StringManipulator';
import { usePermissionManager } from 'utils/PermissionManager';

const NavBottom = (props) => {
  const { className } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const { isUserHasPermissionInMenu } = usePermissionManager();

  const isCurrentPath = (path) => {
    return toLower(location.pathname).includes(toLower(path));
  };

  const goToPath = (path) => navigate(path);

  const authorizedMenus = generateMenu('mpoe').filter((item) => isUserHasPermissionInMenu(false, item.name));

  return (
    <div className={`p-3 w-full rounded-lg shadow bg-[#2D3D5A] ${className}`}>
      <div className="w-full flex flex-row justify-center gap-x-3">
        {
          authorizedMenus.map((item, index) => (
            <div
              key={`menu-${index + 1}`}
              className={`flex flex-col justify-center items-center cursor-pointer gap-y-2 ${isCurrentPath(item.url) ? 'p-2 rounded-lg bg-[#2BB8A4]' : EMPTY.STRING}`}
              onClick={() => goToPath(item.url)}
            >
              {
                item.icon(isCurrentPath(item.url))
              }
              <span className={`text-xs text-white ${isCurrentPath(item.url) ? 'font-bold' : EMPTY.STRING}`}>
                {ellipsized(item.name)}
              </span>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default NavBottom;