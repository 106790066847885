import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { BREAKPOINTS } from 'constant';

const useTableWrapper = () => {
  const { isSideBarExpanded } = useSelector((state) => state.preferences);
  const [remVal, setRemVal] = useState(22);
  const [wrapperWidth, setWrapperWidth] = useState(`${(window.innerWidth - (remVal * 16))}px`);

  const setDefaultRemVal = (val) => setRemVal(val);
  const resizeTableWrapper = () => {
    if (window.innerWidth < BREAKPOINTS.LG) {
      setWrapperWidth(`${window.innerWidth}px`);

      return;
    }
    /**
     * `1rem` is `16px`
     * */
    setWrapperWidth(isSideBarExpanded
      ? `${(window.innerWidth - (remVal * 16))}px`
      /**
       * 20 is width for mini-sideBar
       * */
      : `${(window.innerWidth - ((remVal - 11) * 16))}px`
    );
  };

  useEffect(() => {
    window.addEventListener('resize', resizeTableWrapper);

    return () => window.removeEventListener('resize', resizeTableWrapper);
  }, []);

  useEffect(() => {
    resizeTableWrapper();
  }, [isSideBarExpanded, remVal]);

  return { wrapperWidth, setDefaultRemVal };
};

export { useTableWrapper };