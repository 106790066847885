import moment from 'moment';
import {
  useEffect,
  useReducer,
  useRef
} from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Tag } from 'antd';
import { toast } from 'react-toastify';
import { isEmpty, toLower } from 'lodash';
import { createSearchParams } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { useGetConfidentLevelsMpQuery } from 'api/ConfidentLevel';
import { useGetConfidentLevelsQuery as useGetPICConfidentLevels } from 'api/User';
import {
  ACTION,
  CONFIDENT_LEVEL,
  DEFAULT,
  PERMISSION
} from 'constant';
import { confidentLevelColumns } from 'constant/TableConfidentLevel';
import { getFullPath } from 'utils/PathUtility';
import { transformError } from 'utils/ErrorTransformer';
import { usePermissionManager } from 'utils/PermissionManager';

import Toast from 'components/Toast';
import TableData from 'components/TableData';
import ButtonAccent from 'components/ButtonAccent';

const ConfidentLevel = () => {
  const loc = useLocation();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const {
    page,
    pageSize,
    sortBy,
    orderBy,
    search
  } = useSelector((state) => state.table);
  
  const initialState = {
    userType: null,
    uiType: null,
    mpData: undefined,
    picData: undefined,
    confidentLevelData: []
  };

  const isRefetchRef = useRef(false);

  const reducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
      case 'pic_initialization':
      case 'pic_refetch': {
        isRefetchRef.current = false;

        return {
          ...state,
          picData: payload,
          confidentLevelData: state.uiType === CONFIDENT_LEVEL.TYPE.PIC
            ? payload
            : state.mpData
        };
      }
      case 'mp_initialization':
      case 'mp_refetch': {
        isRefetchRef.current = false;

        return {
          ...state,
          mpData: payload,
          confidentLevelData: state.uiType === CONFIDENT_LEVEL.TYPE.PIC
            ? state.picData
            : payload
        };
      }
      case 'user_type_change':
        return {
          ...state,
          userType: payload
        };
      case 'ui_type_change': {
        return {
          ...state,
          uiType: payload,
          confidentLevelData: payload === CONFIDENT_LEVEL.TYPE.PIC
            ? state.picData
            : state.mpData
        };
      }
      default:
        return { ...state };
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const { isUserHasPermissionInFeature } = usePermissionManager();

  const {
    data: mpConfidentLevels,
    refetch: mpConfidentLevelRefetch,
    isFetching: mpConfidentLevelIsFetching,
    isError: mpConfidentLevelIsError,
    error: mpConfidentLevelError
  } = useGetConfidentLevelsMpQuery(
    {
      page,
      limit: pageSize,
      sort_by: sortBy,
      order_by: orderBy,
      ...search
        ? { search }
        : undefined
    },
    { refetchOnMountOrArgChange: true }
  );
  
  const {
    data: picConfidentLevels,
    refetch: picConfidentLevelRefetch,
    isFetching: picConfidentLevelIsFetching,
    isError: picConfidentLevelIsError,
    error: picConfidentLevelError
  } = useGetPICConfidentLevels(
    {
      page,
      limit: pageSize,
      sort_by: sortBy,
      order_by: orderBy,
      ...search
        ? { search }
        : undefined
    },
    { refetchOnMountOrArgChange: true }
  );

  const isUserAdmin = user?.roles?.some((role) => toLower(role.name) === 'administrator');
  
  const isUserMinePlanner = user?.roles?.some(
    (role) => toLower(role.name)
      .includes(
        toLower(CONFIDENT_LEVEL.TYPE.MINE_PLANNER)
      )
  );

  const isUserConfidentPIC = user?.roles?.some(
    (role) => toLower(role.name)
      .includes(
        toLower(CONFIDENT_LEVEL.TYPE.PIC)
      )
  );

  const generateChildByType = (type) => {
    if (state.uiType === CONFIDENT_LEVEL.TYPE.PIC) {
      return 'submission-pic';
    }

    return type !== ACTION.EDIT
      ? 'submission-mine-plan'
      : 'initiation';
  };

  const goToPathByRole = (id, type) => navigate({
    pathname: getFullPath({
      parent: 'confident-level',
      child: generateChildByType(type),
      ...(type === ACTION.DETAIL || type === ACTION.EDIT)
        ? { type }
        : undefined
    }),
    ...(type === ACTION.DETAIL || type === ACTION.EDIT)
      ? { search: createSearchParams({ id }).toString() }
      : undefined
  });

  const onAction = (id, type) => {
    /**
     * @param {string} id
     * @param {string} type
     * */
    goToPathByRole(id, type);
  };

  const switchUI = () => {
    isRefetchRef.current = true;
    mpConfidentLevelRefetch();
    picConfidentLevelRefetch();

    dispatch({
      type: 'ui_type_change',
      payload: state.uiType === CONFIDENT_LEVEL.TYPE.PIC
        ? CONFIDENT_LEVEL.TYPE.MINE_PLANNER
        : CONFIDENT_LEVEL.TYPE.PIC
    });
  };
  
  useEffect(() => {
    switch (true) {
      case isUserAdmin:
      case isUserMinePlanner && isUserConfidentPIC: {
        dispatch({
          type: 'user_type_change',
          payload: CONFIDENT_LEVEL.TYPE.ALL
        });
        
        dispatch({
          type: 'ui_type_change',
          payload: CONFIDENT_LEVEL.TYPE.PIC
        });
        
        return; 
      }
      case isUserMinePlanner: {
        dispatch({
          type: 'user_type_change',
          payload: CONFIDENT_LEVEL.TYPE.MINE_PLANNER
        });

        dispatch({
          type: 'ui_type_change',
          payload: CONFIDENT_LEVEL.TYPE.MINE_PLANNER
        });
        
        return;
      }
      case isUserConfidentPIC: {
        dispatch({
          type: 'user_type_change',
          payload: CONFIDENT_LEVEL.TYPE.PIC
        });

        dispatch({
          type: 'ui_type_change',
          payload: CONFIDENT_LEVEL.TYPE.PIC
        });
        
        return;
      }
      default: {
        dispatch({
          type: 'user_type_change',
          payload: null
        });
        
        return;
      }
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(mpConfidentLevels)
      && !isEmpty(picConfidentLevels)
    ) {
      if (isRefetchRef.current) {
        dispatch({
          type: 'mp_refetch',
          payload: mpConfidentLevels
        });

        dispatch({
          type: 'pic_refetch',
          payload: picConfidentLevels
        });

        return;
      }

      dispatch({
        type: 'mp_initialization',
        payload: mpConfidentLevels
      });

      dispatch({
        type: 'pic_initialization',
        payload: picConfidentLevels
      });
    }
  }, [mpConfidentLevels, picConfidentLevels]);

  useEffect(() => {
    if (mpConfidentLevelIsError) {
      const errorMsg = mpConfidentLevelError;

      toast.error(<Toast message={'ERROR'} detailedMessage={`${transformError(errorMsg).message}`} />);
      return;
    }
    
    if (picConfidentLevelIsError) {
      toast.error(
        transformError(picConfidentLevelError).message,
        { toastId: 'pic-confident-level-error' }
      );
    }
  }, [
    mpConfidentLevelIsError,
    picConfidentLevelIsError,
    picConfidentLevelError
  ]);

  return (
    <div className="p-5 bg-white rounded-lg">
      {
        state.userType === CONFIDENT_LEVEL.TYPE.ALL && (
          <div className="flex flex-row items-center justify-end">
            <ButtonAccent
              size="2xl"
              title={state.uiType === CONFIDENT_LEVEL.TYPE.PIC ? 'Switch to Mine Planner' : 'Switch to PIC'}
              onClick={switchUI}
            />
          </div>
        )
      }
      <TableData
        isTransparent
        rowKey={state.uiType === CONFIDENT_LEVEL.TYPE.PIC ? 'confident_level_id' : 'id'}
        isLoading={
          state.uiType === CONFIDENT_LEVEL.TYPE.PIC
            ? picConfidentLevelIsFetching
            : mpConfidentLevelIsFetching
        }
        columns={
          confidentLevelColumns(
            loc.pathname,
            isUserHasPermissionInFeature,
            sortBy,
            orderBy === DEFAULT.KEY_SORT_ASC
              ? DEFAULT.SORT_ASC
              : DEFAULT.SORT_DESC,
            onAction,
            onAction,
            page,
            pageSize
          )
        }
        dataSource={
          state.uiType === CONFIDENT_LEVEL.TYPE.PIC
            ? state.confidentLevelData?.confident_levels
            : state.confidentLevelData?.data
        }
        totalData={state.confidentLevelData?.total_data}
        additionalChildren={!state.uiType === CONFIDENT_LEVEL.TYPE.PIC
          ? (
            <div className="mb-5 flex flex-row items-center">
              <span className="mr-3 font-bold">Mine Plan Submission List</span>
              <Tag className="px-3 font-bold rounded-xl bg-pink-700/25 text-pink-700 border-none">
                Yearly Plan {moment().format('YYYY')}
              </Tag>
            </div>
          )
          : undefined
        }
      >
        {
          state.uiType === CONFIDENT_LEVEL.TYPE.PIC
            ? (
              <div className="mb-5 flex flex-row items-center">
                <span className="mr-3 font-bold">PIC Submission List</span>
                <Tag className="px-3 font-bold rounded-xl bg-pink-700/25 text-pink-700 border-none">
                  Yearly Plan {moment().format('YYYY')}
                </Tag>
              </div>
            )
            : (
              <>
                {
                  isUserHasPermissionInFeature(loc.pathname, PERMISSION.CREATE) && (
                    <ButtonAccent
                      title="Initiate"
                      onClick={() => navigate(getFullPath({
                        parent: 'confident-level',
                        child: 'initiation',
                        type: 'create'
                      }))}
                    />
                  )
                }
              </>
            )
        }
      </TableData>
    </div>
  );
};

export default ConfidentLevel;