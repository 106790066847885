import { DatePicker, Divider, Select } from 'antd';
import { useGetDrillingSummaryActualListQuery } from 'api/DrillingSummary';
import { useGetMasterDataGoeCompaniesQuery } from 'api/MasterData';
import ButtonAccent from 'components/ButtonAccent';
import FilterContainer from 'components/FilterContainer';
import SelectSearchAccent from 'components/SelectSearchAccent';
import TableData from 'components/TableData';
import { ACTION } from 'constant';
import { generateDrillingActualListData, generateDrillingActualTable } from 'constant/TableDrillingActual';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { transformError } from 'utils/ErrorTransformer';
import { getFullPath } from 'utils/PathUtility';

const SummaryDrilling = () => {
  let initialFilter = {
    mine_site: undefined,
    year_of_period: undefined,
    drilling_stage: undefined
  };
  const [filter, setFilter] = useState(initialFilter);
  const navigate = useNavigate();
  const {
    page,
    pageSize,
    sortBy,
    orderBy
  } = useSelector((state) => state.table);

  const { 
    data: dataCompanies, 
    isFetching: companiesIsFetching, 
    isError: companyIsError,
    error: companyError
  } = useGetMasterDataGoeCompaniesQuery({ refetchOnMountOrArgChange: true });

  const {
    data: actualsData,
    isFetching: actualsIsFetching,
    isError: actualsIsError,
    error: actualsError
  } = useGetDrillingSummaryActualListQuery(
    {
      page,
      limit: pageSize,
      sort_by: sortBy,
      order_by: orderBy,
      year:filter.year_of_period? moment(new Date(filter.year_of_period)).format('YYYY'):undefined,
      company: filter.mine_site,
      plan_type: filter.drilling_stage,
    },
    { refetchOnMountOrArgChange: true }
  );

  const resetFilter = () => {
    setFilter(initialFilter);
  };
  const onFilter = (key, value) => {
    setFilter((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const calculatePixel = (remVal) => {
    return `${window.innerWidth - remVal * 16}px`;
  };

  const goToPath = (id, type, child)=> navigate({
    pathname: getFullPath({
      parent: 'summary-drilling',
      child: child,
      type
    },'geology-oe'),
    ...(id)
      ? { search: createSearchParams({ id }).toString() }
      : undefined
  });

  useEffect(() => {
    if (companyIsError || actualsIsError) {
      const generateToastId = () => {
        switch (true) {
          case companyError:
            return 'get-drill-hole-name';
          case actualsError:
            return 'get-drill-actuals';
          default:
            return 'default';
        }
      };
      
      toast.error(
        transformError(companyError || actualsError).message,
        { toastId: `${generateToastId()}-toast-error` }
      );
    }
  }, [companyIsError, actualsIsError]);

  const handleActionButton = (id, type) => {
    let actionType = '';
    if(type === 'edit') {
      actionType = ACTION.EDIT;
    } else {
      actionType = ACTION.DETAIL;
    }
    goToPath(id, actionType, 'summary-drilling-actual');
  };

  return (
    <div className="mt-5 bg-white p-6 rounded-2xl">
      <FilterContainer onReset={resetFilter}>
        <label>
          <span className="font-bold">Mine Site</span>
          <SelectSearchAccent
            onChange={(val) => onFilter('mine_site', val)}
            value={filter['mine_site']||null}
            labelKey="alias_name"
            valueKey="alias_name"
            options={dataCompanies}
            placeholder="Select mine site"
            isLoading={companiesIsFetching}
            className={'h-12 mt-2'}
          />
        </label>
        <label>
          <span className="font-bold">Year of Period</span>
          <DatePicker.YearPicker
            allowClear
            className="mt-2 w-full h-12"
            value={filter['year_of_period']}
            onChange={(val) => onFilter('year_of_period', val)}
          />
        </label>
        <label>
          <span className="font-bold">Drilling Stage</span>
          <Select
            allowClear
            placeholder="Select drilling stage"
            value={filter['drilling_stage'] || null}
            options={[
              { label: 'Pre-production', value: 'PREPRODUCTION' },
              { label: 'Exploration', value: 'EXPLORATION' },
            ]}
            className="mt-2 w-full h-12"
            onChange={(val) => onFilter('drilling_stage', val)}
          />
        </label>
      </FilterContainer>
      <Divider />
      <div
        className="mt-5 overflow-x-auto"
        style={{ maxWidth: calculatePixel(23) }}
      >
        <TableData
          isLoading={actualsIsFetching}
          notSearchable={true}
          scrollWidth="max-content"
          scrollHeight={'50vh'}
          columns={generateDrillingActualTable(handleActionButton)}
          dataSource={generateDrillingActualListData(actualsData)}
          totalData={actualsData?.total_data}
          rowKey='id'
          headerBg='#B1BACB20'
          headerColor='black'
        >
          <ButtonAccent
            className={'h-12 w-44'}
            title="Input Actual"
            onClick={() => goToPath(null, ACTION.CREATE, 'input-actual')}
          />
        </TableData>
       
      </div>
    </div>
  );
};

export default SummaryDrilling;