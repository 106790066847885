import { Base } from './Base';
import { registerAuth, setAuthentication } from 'store/Auth';

const Auth = Base.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation({
      query: (body) => ({
        url: '/auth/login',
        method: 'POST',
        body
      }),
      transformErrorResponse: (response) => response.data.error,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data.token && data.refresh_token) {
            await dispatch(registerAuth(data));
            await dispatch(setAuthentication(true));
          }
        } catch (error) {
          return error;
        }
      }
    })
  })
});

export const { useLoginMutation } = Auth;