import { Base } from './Base';

const DrillingPlan = Base.injectEndpoints({
  endpoints: (build)=>({
    postPlan: build.mutation({
      query: (body) => ({
        url: '/goe/drillings/plans',
        method: 'POST',
        body
      })
    }),
    getPlans: build.query({
      query: (params) => ({
        url: '/goe/drillings/plans',
        method: 'GET',
        ...params
          ? { params }
          : undefined
      })
    }),
    getPlanDetailById: build.query({
      query: ({ idPlan })=>({
        url: `/goe/drillings/plans/${idPlan}`,
        method: 'GET'
      })
    })
  })
});

export const {
  usePostPlanMutation,
  useGetPlansQuery,
  useLazyGetPlanDetailByIdQuery,
  useGetPlanDetailByIdQuery
} = DrillingPlan;

