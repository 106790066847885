import { Select } from 'antd';

import { EMPTY } from 'constant';

const SelectAccent = (props) => {
  const {
    isDisabled,
    isLoading,
    isStringify,
    isError,
    placeholder = 'Select here...',
    labelKey,
    valueKey,
    value,
    options,
    onChange,
    size,
    className,
    onClear = () => {}
  } = props;
  
  const transformOptions = options && options.length
    ? options.map((item) => {
      if (isStringify) {
        return {
          label: labelKey
            ? item[labelKey]
            : item,
          value: JSON.stringify(item)
        };
      }

      return {
        label: labelKey
          ? item[labelKey]
          : item,
        value: valueKey
          ? item[valueKey]
          : item
      };
    })
    : [];
  
  return (
    <Select
      allowClear
      disabled={isDisabled || isLoading}
      status={isError ? 'error' : EMPTY.STRING}
      placeholder={placeholder}
      value={value}
      options={transformOptions}
      onChange={onChange}
      className={`${className} w-full ${isLoading && 'animate-pulse animate-infinite'}`}
      size={size}
      onClear={onClear}
    />
  );
};

export default SelectAccent;