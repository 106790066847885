import { useState } from 'react';
import {
  Avatar,
  Col,
  Row,
  Breadcrumb
} from 'antd';
import { AiOutlineBell, AiOutlineLogout } from 'react-icons/ai';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import { isEmpty } from 'lodash';

import { DEFAULT, ERROR, BREADCRUMB_MAP } from 'constant';
import { logout } from 'store/Auth/Thunk';
import { getInitialName } from 'utils/StringManipulator';
import { useModalConfirmationContext } from 'components/ModalConfirmationProvider';

const NavTop = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {showModal, resetModal} = useModalConfirmationContext();

  const { pathname } = useLocation();
  const pathSnippets = pathname.split('/').filter((i) => i);

  const [isMenuShown, setIsMenuShown] = useState(false);

  const logoutUser = async () => {
    try {
      await dispatch(logout());

      navigate('/login');
    } catch (error) {
      navigate('/login');
    }
  };

  const goToPath = (path) => {
    if(location.pathname.includes('create') || location.pathname.includes('edit')) {
      showModal({
        isShown: true,
        type: 'confirmation',
        title:'Are you sure want to leave this page?',
        message: 'Changes you made may not be saved',
        onSubmit: () => {
          navigate(path);
          resetModal();
        }
      });
    } else{
      navigate(path);
    }
  };

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const i = pathSnippets.length === 4 ? 0 : 1;
    const url = `/${pathSnippets.slice(0, index + i).join('/')}`;
    return !BREADCRUMB_MAP[url] ? {key:'',title:<div/>} : {
      key: url,
      title: index !== pathSnippets.length - 1 || pathSnippets.length === 1 ?
        <div
          className='px-2 hover:bg-gray-300 cursor-pointer rounded'
          onClick={() => goToPath(url)}
        >
          <span className='text-[#2D3D5A]'>
            {BREADCRUMB_MAP[url]}
          </span>
        </div> : 
        <span className='text-white text-[14px] bg-[#2BB8A4] px-2 py-1 rounded-md'>{BREADCRUMB_MAP[url]}</span>,
    };
  });

  const getUserRoles = () => {
    if (!isEmpty(user)) {
      if (user.roles && user.roles.length) {
        return user.roles.length > 1
          ? DEFAULT.MULTI_ROLE
          : user.roles[0].name;
      }

      return ERROR.NO_ROLES;
    }

    return ERROR.NO_ROLES;
  };

  return (
    <Row gutter={[8, 8]}>
      <Col flex={8}>
        <div>
          <div className='font-bold text-lg font-[Segoe-UI-Bold]'>
            {BREADCRUMB_MAP[pathname]}
          </div>
          <Breadcrumb params={'id'} items={extraBreadcrumbItems.filter(v => v.key !== '')} />
        </div>
      </Col>
      <Col flex={4}>
        <div className="flex flex-row justify-end items-center gap-x-2">
          <div className="p-2 bg-[#D91C5C] flex flex-col justify-center rounded-2xl">
            <AiOutlineBell
              size="24px"
              className="text-white"
            />
          </div>

          <div
            className="p-2 relative bg-white rounded-2xl cursor-pointer"
            onClick={() => setIsMenuShown((prevState) => !prevState)}
          >
            <div className="flex flex-row justify-center items-center">
              <Avatar className="bg-[#2D3D5A] rounded-xl">
                {
                  getInitialName(isEmpty(user)
                    ? DEFAULT.UNKNOWN
                    : user.username)
                }
              </Avatar>
              <span className="ml-3 mr-5 font-bold">
                {
                  isEmpty(user)
                    ? DEFAULT.UNKNOWN
                    : user.username
                }
              </span>
              {
                isMenuShown
                  ? <BsChevronUp className="text-[#2D3D5A]" />
                  : <BsChevronDown className="text-[#2D3D5A]" />
              }
            </div>

            {
              isMenuShown && (
                <div className="mt-5 p-2 w-full absolute bg-white rounded-lg shadow-md left-0 z-10">
                  <div className="flex flex-col">
                    <span className="font-bold text-xs">
                      {getUserRoles()}
                    </span>
                    <div
                      className="mt-2 p-2 flex flex-row items-center gap-x-3 rounded-lg cursor-pointer hover:bg-gray-300"
                      onClick={logoutUser}
                    >
                      <AiOutlineLogout />
                      <span className="font-bold">Logout</span>
                    </div>
                    <span className="mt-5 text-xs text-gray-500 self-end">
                      {`v. ${process.env.REACT_APP_VERSION}`}
                    </span>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default NavTop;