import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { isEmpty } from 'lodash';
import { Image, Progress } from 'antd';

import { logout } from 'store/Auth/Thunk';
import { URL } from 'constant';

const Splash = () => {
  const { token, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const checkUserAuthentication = async () => {
    try {
      if (isEmpty(token) || isEmpty(user)) {
        await dispatch(logout());
        navigate('/login');

        return;
      }

      navigate(`/${URL.ACTIVITY_LEVEL}`);
    } catch (error) {
      navigate('/login');
    }
  };

  useEffect(() => {
    checkUserAuthentication();
  }, []);

  return (
    <>
      <div className="p-5 h-screen flex flex-col items-center bg-gradient-to-r from-[#CDBAFA] to-[#CDDDF4]">
        <Image
          preview={false}
          width={100}
          src={`${process.env.PUBLIC_URL}/assets/logo-itm.svg`}
        />

        <div className="mt-auto flex flex-col items-center">
          <span className="text-sm">{`(v. ${process.env.REACT_APP_VERSION}) Checking some things, please wait...`}</span>
          <Progress
            percent={100}
            status="active"
            strokeColor="#472F92"
            showInfo={false}
          />
        </div>
      </div>
    </>
  );
};

export default Splash;