import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { DatePicker, Divider, Drawer, Spin, Form, Pagination, Table } from 'antd';
import { toast } from 'react-toastify';

import { useSelector } from 'react-redux';
import { transformError } from 'utils/ErrorTransformer';
import { useTableWrapper } from 'utils/TableResponsive';

import ButtonAccent from 'components/ButtonAccent';
import FormOe from './Form';
import TableRaw from 'components/TableRaw';
import { maintenanceOEColumns } from 'constant/Maintenance/TableOE';
import Toast from 'components/Toast';
import { useModalConfirmationContext } from 'components/ModalConfirmationProvider';
import { useDeleteOeMutation, useLazyExportOeExcelQuery, useLazyGetOesQuery } from 'api/MaintenanceOe';
import { isEmpty } from 'lodash';
import moment from 'moment';
import LoadingIcon from 'components/LoadingIcon';
import SelectAccent from 'components/SelectAccent';
import { useGetMasterDataGoeCompaniesQuery } from 'api/MasterData';

const MaintenanceOmeAP = () => {
  const loc = useLocation();
  const {
    page,
    pageSize
  } = useSelector((state) => state.table);

  const { showModal, resetModal } = useModalConfirmationContext();
  const { wrapperWidth } = useTableWrapper();
  const [isEdit, setIsEdit] = useState(false);
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [loading, setLoading] = useState(false);
  const [isModalShown, setIsModalShown] = useState(false);
  const [oeId, setOeId] = useState(null);
  const [isExportDisable, setIsExportDisable] = useState(true);

  const [
    getListOe,
    {
      data: oeData,
      isFetching: oeIsFetching,
      error: oeError,
      isError: oeIsError
    }
  ] = useLazyGetOesQuery();

  const [
    deleteOe,
    {
      isError: deleteOeIsError,
      error: deleteOeError,
      isSuccess: deleteOeIsSuccess,
    },
  ] = useDeleteOeMutation();

  const [exportExcelOe, {
    isLoading: exportExcelIsLoading,
    isError: exportExcelIsError,
    error: exportExcelError
  }] = useLazyExportOeExcelQuery();

  const { 
    data: dataCompanies, 
    isFetching: companiesIsFetching, 
  } = useGetMasterDataGoeCompaniesQuery({ refetchOnMountOrArgChange: true });

  const fetchListOe = (dates) => {
    const year = dates ? moment(new Date(dates)).format('YYYY') : null;
    getListOe({
      page,
      limit: pageSize,
      ...year
        ? { year }
        : undefined,
    });
  };

  useEffect(() => {
    fetchListOe();
  }, []);

  const onApplyFilter = (values) => {
    fetchListOe(values.date);
    setIsExportDisable(isEmpty(values?.date));
  };

  const onSetModal = () => {
    if (isModalShown) {
      setIsEdit(false);
    } else {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
    setIsModalShown(!isModalShown);
  };

  const onEdit = (id) => {
    setOeId(id);
    setIsEdit(true);
    setIsModalShown(!isModalShown);
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const onSubmitForm = () => {
    onSetModal();
    setTimeout(() => {
      fetchListOe();
    }, 1000);
  };

  const onDelete = (id) => {
    showModal({
      isShown: true,
      type: 'delete',
      message: 'Are you sure you want to delete ?',
      onSubmit: () => {
        deleteOe({ id });
        resetModal();
        setTimeout(() => {
          fetchListOe();
          if (deleteOeIsError) {
            toast.error(deleteOeError, {
              toastId: 'delete-plan-stop-error-toast',
            });
          } else {
            toast.success('Data Deleted', {
              toastId: 'delete-plan-stop-success-toast',
            });
          }
        }, 1000);
      },
    });
  };

  useEffect(() => {
    if (oeIsError) {
      toast.error(
        transformError(oeError).message,
        { toastId: 'oe-list-error' }
      );
    }
    if (deleteOeIsSuccess) {
      toast.success(
        <Toast message={'SUCCESS'} detailedMessage={'Successfuly delete related document.'} />,
        { toastId: 'oe-delete-success' }
      );

      fetchListOe();
    }
    if (exportExcelIsError) {
      toast.error(
        transformError(exportExcelError).message,
        { toastId: 'oe-export-error' }
      );
    }
  }, [
    oeIsError,
    deleteOeIsSuccess,
    exportExcelIsError
  ]);

  return (
    <div className="p-5 bg-white rounded-lg w-full">
      <Drawer
        destroyOnClose
        title={`${isEdit ? 'Edit Record' : 'Add New Record'} OME Maintenance AP`}
        placement={isEdit ? 'right' : 'left'}
        onClose={onSetModal}
        open={isModalShown}
        loading={loading}
        maskClosable={false}
        key={isEdit ? 'right' : 'left'}
        width={'35vw'}
        styles={{
          headerStyle: { backgroundColor: '#EEE9FB' },
          bodyStyle: { backgroundColor: '#EEE9FB'}
        }}
      >
        {
          loading 
            ? <div className='w-full text-center'><Spin className='m-auto' /> </div>
            : <FormOe isEdit={isEdit}
              oeId={oeId}
              onSubmit={onSubmitForm}
            />
        }
      </Drawer>

      <Form form={form} onFinish={onApplyFilter}>
        <div className={'grid-cols-2 flex gap-4 p-4 bg-[#B1BACB20] rounded-lg'}>
          <div className='w-full'>
            <span className="font-bold">Company</span>
            <Form.Item
              name="mine_site"
            >
              <SelectAccent
                className='mt-2'
                isStringify={false}
                isLoading={companiesIsFetching}
                labelKey="alias_name"
                valueKey="alias_name"
                options={dataCompanies || []}
                size="large"
              />
            </Form.Item>
          </div>
          <div className='w-full'>
            <span className="font-bold">Year</span>
            <Form.Item
              name="date"
            >
              <DatePicker
                size="large"
                allowClear
                className="mt-2 w-full"
                picker="year"
              />
            </Form.Item>
          </div>
          <div className="w-1/5 flex flex-row justify-center items-center gap-2">
            <ButtonAccent
              title="Apply Filter"
              htmlType="submit"
              isLoading={false}
            />
          </div>
        </div>
      </Form>
      <Divider />
      <div className='p-5' style={{ maxWidth: wrapperWidth }}>
        <div className='flex justify-between'>
          {
            <ButtonAccent
              title="New Record"
              onClick={onSetModal}
            />
          }
          <ButtonAccent 
            isBordered 
            size={'xl'} 
            title="Export Excel" 
            isLoading={exportExcelIsLoading}
            onClick={() => 
              exportExcelOe({
                year: moment(new Date(values.date)).format('YYYY'),
              })
            }  
            isDisabled={isEmpty(oeData?.data) || isExportDisable}
          />
        </div>
        {
          oeIsFetching 
            ? <div className="flex flex-row justify-center items-center">
              <LoadingIcon />
            </div>
            : !isEmpty(oeData?.data) 
              ? oeData?.data?.map((value, index) => (
                <div key={index} className='mt-5'>
                  <div className='text-center'>{ value.category }</div>
                  <div className="mt-5 overflow-x-auto">
                    <TableRaw
                      isTransparent
                      scrollWidth="max-content"
                      rowKey={value.id}
                      isLoading={false}
                      columns={
                        maintenanceOEColumns(
                          loc.pathname,
                          onEdit,
                          onDelete
                        )
                      }
                      dataSource={value.datas}
                      summary={() => (
                        <Table.Summary.Row className="font-bold bg-[#2C3D5A] text-white">
                          <Table.Summary.Cell index={0} className="rounded-bl-lg"></Table.Summary.Cell>
                          <Table.Summary.Cell index={1}></Table.Summary.Cell>
                          <Table.Summary.Cell index={2}>Performance</Table.Summary.Cell>
                          <Table.Summary.Cell index={3}>%</Table.Summary.Cell>
                          <Table.Summary.Cell index={4}>
                            { 
                              value.performance.jan !== 'error'
                                ? Number(
                                  (value.performance.jan * 100).toFixed(1)
                                ).toLocaleString('en-US')
                                : 0
                            }%
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={5}>
                            { 
                              value.performance.feb !== 'error'
                                ? Number(
                                  (value.performance.feb * 100).toFixed(1)
                                ).toLocaleString('en-US')
                                : 0
                            }%
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={6}>
                            { 
                              value.performance.mar !== 'error'
                                ? Number(
                                  (value.performance.mar * 100).toFixed(1)
                                ).toLocaleString('en-US')
                                : 0
                            }%
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={7}>
                            { 
                              value.performance.apr !== 'error'
                                ? Number(
                                  (value.performance.apr * 100).toFixed(1)
                                ).toLocaleString('en-US')
                                : 0
                            }%
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={8}>
                            { 
                              value.performance.may !== 'error'
                                ? Number(
                                  (value.performance.may * 100).toFixed(1)
                                ).toLocaleString('en-US')
                                : 0
                            }%
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={9}>
                            { 
                              value.performance.jun !== 'error'
                                ? Number(
                                  (value.performance.jun * 100).toFixed(1)
                                ).toLocaleString('en-US')
                                : 0
                            }%
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={10}>
                            { 
                              value.performance.jul !== 'error'
                                ? Number(
                                  (value.performance.jul * 100).toFixed(1)
                                ).toLocaleString('en-US')
                                : 0
                            }%
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={11}>
                            { 
                              value.performance.aug !== 'error'
                                ? Number(
                                  (value.performance.aug * 100).toFixed(1)
                                ).toLocaleString('en-US')
                                : 0
                            }%
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={12}>
                            { 
                              value.performance.sep !== 'error'
                                ? Number(
                                  (value.performance.sep * 100).toFixed(1)
                                ).toLocaleString('en-US')
                                : 0
                            }%
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={13}>
                            { 
                              value.performance.oct !== 'error'
                                ? Number(
                                  (value.performance.oct * 100).toFixed(1)
                                ).toLocaleString('en-US')
                                : 0
                            }%
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={14}>
                            { 
                              value.performance.nov !== 'error'
                                ? Number(
                                  (value.performance.nov * 100).toFixed(1)
                                ).toLocaleString('en-US')
                                : 0
                            }%
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={15}>
                            { 
                              value.performance.dec !== 'error'
                                ? Number(
                                  (value.performance.dec * 100).toFixed(1)
                                ).toLocaleString('en-US')
                                : 0
                            }%
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={16}></Table.Summary.Cell>
                        </Table.Summary.Row>
                      )}
                    >
                    </TableRaw>
                  </div>
                </div>
              ))
              : <div className='text-center'>Empty Data</div>
        }
        <div className='text-end mt-5'>
          <Pagination defaultCurrent={page} total={oeData?.total_data ?? 0} />
        </div>
      </div>
    </div>
  );
};

export default MaintenanceOmeAP;