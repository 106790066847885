const GoeDashboard = () => {
  return (
    <div className="flex h-full flex-col gap-4">
      <div className="bg-white">
        <iframe 
          title="OE Dashboard" 
          width="100%"
          className="h-screen"
          src="https://app.powerbi.com/reportEmbed?reportId=e1938cc0-28e4-490d-8637-b0c98f54b338&autoAuth=true&ctid=bbb8da8f-f374-490f-9190-2242176e117c" 
          allowFullScreen={true}
        />
      </div>
    </div>
  );
};

export default GoeDashboard;