import { Input } from 'antd';

const generateVersionResultTopoCollarValidationTable = ( isDetailMode, onChange ) => [
  {
    dataIndex: 'drill_hole_name',
    title: 'Drill Hole Name',
    width: '166px',
    align: 'center',
    fixed: 'left',
    sorter: false,
    render: (value) => {
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {value}
        </div>
      );
    },
  },
  {
    dataIndex: 'topo_version',
    title: 'Topo Version',
    width: '166px',
    align: 'center',
    fixed: 'left',
    sorter: false,
    render: (value, record) => {
      return (
        <Input
          disabled={isDetailMode}
          placeholder={'Enter topo version'}
          value={value}
          onChange={e => {
            onChange('topo_version', record.id, e.target.value);
          }}
        /> 
      );
    },
  },
  {
    dataIndex: 'result',
    title: 'Result',
    width: '166px',
    align: 'center',
    fixed: 'left',
    sorter: false,
    render: (_, record) => {
      let result = 
      record.collar_borehole_value !== null && 
      record.topo_grid_value !== null && 
      populateResultFormula(record.collar_borehole_value - record.topo_grid_value);
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {result}
        </div>
      );
    },
  },
];

const populateResultFormula = (varianceCollar) => {
  switch(true){
    case varianceCollar < -1:
      return 'Out of Standard (Collar below Topo)';
    case varianceCollar >= -1 && varianceCollar <=1:
      return 'In standard';
    case varianceCollar > 1:
      return 'Out of Standard (Collar above Topo)';
    default:
      return '';
  }
};

export { generateVersionResultTopoCollarValidationTable };