import { Base } from './Base';

const Unit = Base.injectEndpoints({
  endpoints: (build) => ({
    getUnitById: build.query({
      query: ({ id, params }) => ({
        url: `/assumption-unit/${id}`,
        method: 'GET',
        ...params
          ? { params }
          : undefined
      })
    }),
    getLatestUnit: build.query({
      query: ({ id, params }) => ({
        url: `/assumption-unit/${id}/latest`,
        method: 'GET',
        ...params
          ? { params }
          : undefined
      })
    }),
    postUnit: build.mutation({
      query: (body) => ({
        url: '/assumption-unit',
        method: 'POST',
        body
      })
    }),
    putUnit: build.mutation({
      query: ({ id, body }) => ({
        url: `/assumption-unit/${id}`,
        method: 'PUT',
        body
      })
    })
  })
});

export const {
  useGetUnitByIdQuery,
  useLazyGetUnitByIdQuery,
  useLazyGetLatestUnitQuery,
  usePostUnitMutation,
  usePutUnitMutation
} = Unit;