import { Alert, Image, Modal, Progress, Tooltip, Upload } from 'antd';
import { useUploadEvidenceMutation } from 'api/DrillingSummary';
import ButtonAccent from 'components/ButtonAccent';
import { isEmpty, toLower } from 'lodash';
import { useEffect, useState } from 'react';
import { TbTrash } from 'react-icons/tb';
import { toast } from 'react-toastify';
import { transformError } from 'utils/ErrorTransformer';
import { convertToMegaBytes } from 'utils/FileUtility';
import { IoDocumentText } from 'react-icons/io5';
const ModalUpload = (props) => {
  const {showModal, setShowModal, plan_id, setPayload, payload} = props;
  const { Dragger } = Upload;
  const [files, setFiles] = useState([]);
  const [isFileHasForbiddenExtensions, setIsFileHasForbiddenExtensions] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [
    uploadEvidence,
    {  
      data: evidenceRes,
      isLoading: evidenceResIsLoading,
      isError: evidenceResIsError,
      error: evidenceResError
    }
  ] = useUploadEvidenceMutation();

  const validateFile = (file) => {
    const forbiddenExtensions = ['exe', 'vb', 'apk', 'sql', 'js', 'scrp'];
    const fileExt = file.name.split('.');
    const drillHoleName = file.name.split('#');
    const ext = fileExt[fileExt.length - 1];
    const drillHoleNames = payload.drill_hole_information.map(item=>item?.drill_hole_name);
    const isFileHasDrillHoleName = drillHoleNames?.includes(drillHoleName[0]);
    const isForbiddenType = forbiddenExtensions.some((extension) => !toLower(extension).includes(ext));
    let isFileNameValid = true;
    if(files.length) {
      let isSameName = files.find(item=>item.name === file?.name);
      if(!isEmpty(isSameName)) {
        isFileNameValid = false;
        setErrorMessage('Cannot upload file with same name, please choose another one');
      }
    }
    if(!isFileHasDrillHoleName) {
      setErrorMessage('Cannot upload this file, file name does not match with every drill hole name you choose before');
    } else {
      setErrorMessage('Cannot upload this type of file, please choose another one');
    }
    return isFileHasDrillHoleName && isForbiddenType && isFileNameValid;
  };

  const generateFormData = (files) => {
    const formData = new FormData();
    files?.forEach(item => formData.append('files', item));    
    return formData;
  };

  const handleOnChange = (info) => {
    const { file } = info;
    if(validateFile(file)) {
      setFiles(prev => [...prev, file]);
      setIsFileHasForbiddenExtensions(false);
    }else {
      setIsFileHasForbiddenExtensions(true);
    }
  };

  const handleDeleteFile = (uid) => {
    let tempFiles = files?.filter(item => item?.uid !== uid);
    setFiles(tempFiles);
  };
  
  const handleCloseModal = () => {
    setShowModal(false);
    setFiles([]);
  };

  const onUpload = () => {
    const payload = generateFormData(files);
    uploadEvidence({body: payload, plan_id});
  };

  const populateUploadResult = () => {
    let data = evidenceRes.drilling_evidence;
    let finalData = payload?.drilling_evidence;
    data?.forEach(item => {
      finalData.forEach((data, index) => {
        if(payload.drill_hole_information[index].drill_hole_name === item.drill_hole_name) {
          finalData[index] = {
            drill_hole_name: item.drill_hole_name,
            soft_pdf_20: {
              date: item.soft_pdf_20 || data.soft_pdf_20?.date,
              files: item.soft_pdf_20_evidence[0] || data.soft_pdf_20?.files,
            },
            soft_pdf_100:  {
              date: item.soft_pdf_100 || data.soft_pdf_100?.date,
              files: item.soft_pdf_100_evidence[0] || data.soft_pdf_100?.files,
            },
            hard_20: {
              date: item.hard_20 || data.hard_20?.date,
              files: item.hard_20_evidence[0] || data.hard_20?.files,
            },
            hard_100: {
              date: item.hard_100 || data.hard_100?.date,
              files: item.hard_100_evidence[0] || data.hard_100?.files,
            },
            photo_core_box: {
              date: item.photo_core_box || data.photo_core_box?.date,
              files: item.photo_core_box_evidence[0] || data.photo_core_box?.files,
            },
            wellcad: {
              date: item.wellcad || data.wellcad?.date,
              files: item.wellcad_evidence[0] || data.wellcad?.files,
            },
            las_file: {
              date: item.las_file || data.las_file?.date,
              files: item.las_file_evidence[0] || data.las_file?.files,
            },
          };
        }
      });
    });
    setPayload(prevState => ({...prevState, drilling_evidence: finalData}));
    handleCloseModal();
  };

  useEffect(() => {
    if(!isEmpty(evidenceRes)){
      populateUploadResult();
    }
  },[evidenceRes]);

  useEffect(() => {
    if (evidenceResIsError) {
      const generateToastId = () => {
        if(evidenceResError) {
          return 'post-evidence';
        }else {
          return 'default';
        }
      };
      toast.error(
        transformError(evidenceResError).message,
        { toastId: `${generateToastId()}-toast-error` }
      );
    }
  }, [evidenceResIsError]);

  const configuration = {
    name: 'file',
    multiple: true,
    showUploadList: false,
    onChange: handleOnChange,
    accept:'*/*',
    beforeUpload:() => false
  };
  return ( 
    <Modal
      title={false}
      footer={false}
      closeIcon={false}
      open={showModal}
      onOk={handleCloseModal}
      onCancel={handleCloseModal}
      width={'45vw'}
      centered
      style={{ overflowY: 'hidden', maxHeight: '80%', flexDirection:'col' }}
    >
      {
        isFileHasForbiddenExtensions && (
          <Alert
            type="error"
            message={errorMessage}
            className="mb-5"
          />
        )
      }
      <div className='flex flex-row justify-between mb-4'>
        <span className='text-[16px] font-[Segoe-UI-Bold]'>Upload Evidence</span>
      </div>
      <Dragger {...configuration} listType="picture" >
        {evidenceResIsLoading ? (
          <Progress
            percent={100}
            status="active"
            strokeColor="#472F92"
            showInfo={false}
          />
        ):(
          <div className="p-3 flex flex-col items-center justify-center bg-white rounded-full h-52 mb-4">
            <Image
              preview={false}
              width={30}
              src={`${process.env.PUBLIC_URL}/assets/icon-upload.svg`} />
            <span className="my-2">Click or drag file to this area to upload</span>
            <ButtonAccent title="Browse File" />
          </div>
        )}
      </Dragger>
      {
        !isEmpty(files) && (
          <div className='h-[28vh] overflow-y-scroll'>
            {files?.map((item, index) => {
              return (
                <div key={`evidence-${index}`} className="p-2 flex flex-row items-center bg-[#2D3D5A10] border-dashed border-2 border-[#2D3D5A] rounded-lg mt-2 justify-between">
                  <div className='flex flex-row'>
                    <IoDocumentText size={40} color='#2D3D5A' />
                    <div className="ml-2 flex flex-col justify-center">
                      <Tooltip placement='topRight' title={item?.name} color='#2D3D5A' trigger={'hover'}>
                        <span className='min-w-[25vw] truncate'>{item?.name}</span>
                      </Tooltip>
                      <span className="text-gray-500/50">
                        {convertToMegaBytes(item?.size )} MB
                      </span>
                    </div>
                  </div>
                  <Tooltip title={'Delete'} trigger={'hover'}>
                    <TbTrash size="28px" color='#EB5545' onClick={()=>handleDeleteFile(item?.uid)} />
                  </Tooltip>
                </div>
              );
            })}
          </div>
        )
      } 
      <div className="mt-5 flex flex-row items-center">
        <div className="ml-auto flex flex-row items-center gap-x-3">
          <ButtonAccent isLoading={evidenceResIsLoading} isBordered title="Back" onClick={handleCloseModal} />
          <ButtonAccent isLoading={evidenceResIsLoading} title="Upload" onClick={onUpload} />
        </div>
      </div>
    </Modal>
  );
};

export default ModalUpload;