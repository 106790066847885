import { Base } from './Base';

const User = Base.injectEndpoints({
  endpoints: (build) => ({
    getCompanies: build.query({
      query: (params) => ({
        url: '/users/me/company',
        method: 'GET',
        ...params
          ? { params }
          : undefined
      })
    }),
    getContractors: build.query({
      query: (params) => ({
        url: '/users/me/contractor',
        method: 'GET',
        ...params
          ? { params }
          : undefined
      })
    }),
    getConfidentLevels: build.query({
      query: (params) => ({
        url: '/users/confident-levels',
        method: 'GET',
        ...params
          ? { params }
          : undefined
      })
    }),
    getSubmissions: build.query({
      query: ({ id }) => ({
        url: `/users/confident-levels/${id}/submissions`,
        method: 'GET'
      })
    }),
    getUsers: build.query({
      query: (params) => ({
        url: '/users',
        method: 'GET',
        ...params
          ? { params }
          : undefined
      })
    }),
    getUserById: build.query({
      query: ({ id }) => ({
        url: `/users/${id}`,
        method: 'GET'
      })
    }),
    postUser: build.mutation({
      query: (body) => ({
        url: '/users',
        method: 'POST',
        body
      })
    }),
    putUser: build.mutation({
      query: ({ id, body }) => ({
        url: `/users/${id}`,
        method: 'PUT',
        body
      })
    }),
    deleteUser: build.mutation({
      query: ({ id }) => ({
        url: `/users/${id}`,
        method: 'DELETE'
      })
    }),
    putNewPassword: build.mutation({
      query: (body) => ({
        url: '/users/password',
        method: 'PUT',
        body
      }),
      transformErrorResponse: (response) => response.data.error
    })
  })
});

export const {
  useGetCompaniesQuery,
  useGetContractorsQuery,
  useGetConfidentLevelsQuery,
  useLazyGetConfidentLevelsQuery,
  useGetSubmissionsQuery,
  useGetUsersQuery,
  useLazyGetUserByIdQuery,
  usePostUserMutation,
  usePutUserMutation,
  useDeleteUserMutation,
  usePutNewPasswordMutation
} = User;