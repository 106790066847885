import { 
  Modal, 
  Image 
} from 'antd';
import ButtonAccent from 'components/ButtonAccent';

const ModalInformation = (props) => {
  const { isModalOpen, setIsModalOpen, errorMessage } = props;

  const handleOk = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        title={false}
        footer={false}
        closeIcon={false}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleOk}
        width={350}
        centered
        style={{ overflowY: 'auto', maxHeight: '80%' }}
      >
        <div className="px-10 flex flex-col items-center text-center justify-center">
          <Image
            preview={false}
            width={150}
            src={`${process.env.PUBLIC_URL}/assets/icon-modal-confirmation.svg`}
            className="mb-5"
          />
          <span className="mb-2 font-bold text-xl">Information!</span>
          <span className="w-full text-center text-gray-500/50">
            {errorMessage}
          </span>
          <div className="w-full mt-10">
            <ButtonAccent
              isDisabled={false}
              isLoading={false}
              title="Ok, Back"
              onClick={() => handleOk()}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalInformation;
