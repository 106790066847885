import { DatePicker, Input } from 'antd';
import SelectAddAccent from 'components/SelectAddAccent';
import { EMPTY } from 'constant';
import dayjs from 'dayjs';
import { capitalize, isEmpty } from 'lodash';
import { RxCross2 } from 'react-icons/rx';

const generateSampleInformationTable = (payload, onChange, removeRowTable, locationData, pitNameData, isDetailMode) => [
  {
    dataIndex: 'action',
    title: 'Action',
    width: 5,
    sorter: false,
    align: 'center',
    fixed: 'left',
    render: (_, __, index) => {
      return (
        <div className="flex justify-center">
          {payload.length <= 1 ? (
            <></>
          ) : (
            <RxCross2
              size={28}
              color={`${isDetailMode? 'gray' : 'red'}`}
              className={`rounded-full p-1 ${isDetailMode ? 'bg-gray-200':'cursor-pointer bg-red-200 '}`}
              onClick={isDetailMode ? null : () => removeRowTable(index)}
            />
          )}
        </div>
      );
    },
  },
  {
    dataIndex: 'sample_id',
    title: 'Sample Id',
    width: '166px',
    align: 'center',
    sorter: false,
    render: (value, _, index) => {
      return (
        <Input
          placeholder={'Enter'}
          value={value}
          disabled={isDetailMode}
          onChange={(e)=>{
            onChange(index, 'sample_information', 'sample_id', e.target.value);
          }}
          status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
        /> 
      );
    },
  },
  {
    dataIndex: 'date',
    title: 'Date',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, _, index) => {
      return (
        <DatePicker
          allowClear
          disabled={isDetailMode}
          value={value? dayjs(value,'DD/MMM/YYYY'):''}
          format={'DD/MMM/YYYY'}
          placeholder="dd/mmm/yyyy"
          className="w-full text-left"
          onChange={(val) => {
            onChange(index, 'sample_information', 'date', val? dayjs(val, 'DD/MMM/YYYY') : null);
            onChange(index, 'sample_information', 'month',val? dayjs(val, 'DD/MMM/YYYY').format('MMM'): null);
          }}
          status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
        />
      );
    },
  },
  {
    dataIndex: 'block',
    title: 'Block',
    width: '166px',
    align: 'center',
    sorter: false,
    render: (value, _, index) => {
      return (
        <Input
          disabled={isDetailMode}
          placeholder={'Enter'}
          value={value}
          onChange={(e) => {
            onChange(index, 'sample_information', 'block', e.target.value);
          }}
          status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
        /> 
      );
    },
  },
  {
    dataIndex: 'location',
    title: 'Location',
    width: '166px',
    align: 'center',
    sorter: false,
    render: (value, _, index) => {
      let areas = locationData.data?.map(area => capitalize(area));
      let areasIsFetching = locationData.isFetching;
      return (
        <SelectAddAccent
          isNotTag
          isDisabled={isDetailMode}
          isSingle= {true}
          value={value || undefined}
          placeholder="Select"
          options={areas}
          isLoading={areasIsFetching}
          className={'w-full text-left'}
          onChange={(val)=>{
            onChange(index, 'sample_information', 'location', val);
          }}
          isError={!isDetailMode && !value}
        />
      );
    },
  },
  {
    dataIndex: 'month',
    title: 'Month',
    width: '166px',
    align: 'center',
    sorter: false,
    render: (value) => {
      return (
        <div className='flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {value}
        </div>
      );
    },
  },
  {
    dataIndex: 'pit',
    title: 'PIT',
    width: '166px',
    align: 'center',
    sorter: false,
    render: (value, _, index) => {
      return (
        <SelectAddAccent
          isNotTag
          isDisabled={isDetailMode}
          value={value || undefined}
          isSingle={true}
          placeholder="Select"
          options={pitNameData.data}
          isLoading={pitNameData.isFetching}
          className="w-full text-left"
          onChange={(val) => {
            onChange(index, 'sample_information', 'pit', val);
          }}
          isError={!isDetailMode && !value}
        />
      );
    },
  },
  {
    dataIndex: 'seam',
    title: 'Coal Seam',
    width: '166px',
    align: 'center',
    sorter: false,
    render: (value, _, index) => {
      return (
        <Input
          placeholder={'Enter'}
          value={value}
          disabled={isDetailMode}
          onChange={(e) => {
            onChange(index, 'sample_information', 'coal_seam', e.target.value);
          }}
          status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
        /> 
      );
    },
  },
  {
    title: 'Coordinate',
    width: '166px',
    align: 'center',
    sorter: false,
    children:[ 
      {
        dataIndex: 'easting',
        title: 'Easting',
        width: '166px',
        align: 'center',
        sorter: false,
        render: (value, _, index) => {
          return (
            <Input
              disabled={isDetailMode}
              placeholder={'Enter'}
              value={value}
              type='number'
              onChange={(e) => {
                onChange(index, 'sample_information', 'easting', e.target.value);
              }}
              status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
            /> 
          );
        },
      },
      {
        dataIndex: 'northing',
        title: 'Northing',
        width: '166px',
        align: 'center',
        sorter: false,
        render: (value, _, index) => {
          return (
            <Input
              disabled={isDetailMode}
              placeholder={'Enter'}
              value={value}
              type='number'
              onChange={(e) => {
                onChange(index, 'sample_information', 'northing', e.target.value);
              }}
              status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
            /> 
          );
        },
      },
      {
        dataIndex: 'latitude',
        title: 'Latitude',
        width: '166px',
        align: 'center',
        sorter: false,
        render: (value, _, index) => {
          return (
            <Input
              disabled={isDetailMode}
              placeholder={'Enter'}
              value={value}
              type='number'
              onChange={(e) => {
                onChange(index, 'sample_information', 'latitude', e.target.value);
              }}
              status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
            /> 
          );
        },
      },
      {
        dataIndex: 'longitude',
        title: 'Longitude',
        width: '166px',
        align: 'center',
        sorter: false,
        render: (value, _, index) => {
          return (
            <Input
              disabled={isDetailMode}
              placeholder={'Enter'}
              value={value}
              type='number'
              onChange={(e) => {
                onChange(index, 'sample_information', 'longitude', e.target.value);
              }}
              status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
            /> 
          );
        },
      },
    ]
  },
  {
    title: 'Elevation (Actual)',
    width: '166px',
    align: 'center',
    sorter: false,
    children:[ 
      {
        dataIndex: 'act_floor',
        title: 'Floor',
        width: '166px',
        align: 'center',
        sorter: false,
        render: (value, _, index) => {
          return (
            <Input
              disabled={isDetailMode}
              placeholder={'Enter'}
              value={value}
              type='number'
              onChange={(e) => {
                onChange(index, 'sample_information', 'act_floor', e.target.value);
              }}
              status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
            /> 
          );
        },
      },
      {
        dataIndex: 'act_roof',
        title: 'Roof',
        width: '166px',
        align: 'center',
        sorter: false,
        render: (value, _, index) => {
          return (
            <Input
              disabled={isDetailMode}
              placeholder={'Enter'}
              value={value}
              type='number'
              onChange={(e) => {
                onChange(index, 'sample_information', 'act_roof', e.target.value);
              }}
              status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
            /> 
          );
        },
      },
    ]
  },
  {
    title: 'Elevation (Model)',
    width: '166px',
    align: 'center',
    sorter: false,
    children:[ 
      {
        dataIndex: 'model_floor',
        title: 'Floor',
        width: '166px',
        align: 'center',
        sorter: false,
        render: (value, _, index) => {
          return (
            <Input
              disabled={isDetailMode}
              placeholder={'Enter'}
              value={value}
              type='number'
              onChange={(e) => {
                onChange(index, 'sample_information', 'model_floor', e.target.value);
              }}
              status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
            /> 
          );
        },
      },
      {
        dataIndex: 'model_roof',
        title: 'Roof',
        width: '166px',
        align: 'center',
        sorter: false,
        render: (value, _, index) => {
          return (
            <Input
              disabled={isDetailMode}
              placeholder={'Enter'}
              value={value}
              type='number'
              onChange={(e) => {
                onChange(index, 'sample_information', 'model_roof', e.target.value);
              }}
              status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
            /> 
          );
        },
      },
    ]
  },
  {
    title: 'Variance Elevation',
    width: '166px',
    align: 'center',
    sorter: false,
    children:[ 
      {
        dataIndex: 'var_floor',
        title: 'Floor',
        width: '166px',
        align: 'center',
        sorter: false,
        render: (value, _, index) => {
          return (
            <Input
              disabled={isDetailMode}
              placeholder={'Enter'}
              value={value}
              type='number'
              onChange={(e) => {
                onChange(index, 'sample_information', 'var_floor', e.target.value);
              }}
              status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
            /> 
          );
        },
      },
      {
        dataIndex: 'var_roof',
        title: 'Roof',
        width: '166px',
        align: 'center',
        sorter: false,
        render: (value, _, index) => {
          return (
            <Input
              disabled={isDetailMode}
              placeholder={'Enter'}
              value={value}
              type='number'
              onChange={(e) => {
                onChange(index, 'sample_information', 'var_roof', e.target.value);
              }}
              status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
            /> 
          );
        },
      },
    ]
  },
];

const generateSampleInformationData = (file) => {
  if(!isEmpty(file)) {
    const data = file.properties[0].sample_information;
    const totalData = data[0].sample_id.length;
    const result = [];
    

    for(let i = 0; i < totalData; i++){
      result.push({
        sample_id:data[0].sample_id[i]?.value,
        date: data[1].date[i]?.value,
        block: data[2].block[i].value,
        location: data[3].location[i]?.value,
        month: data[4].month[i]?.value,
        pit: data[5].pit[i]?.value,
        seam: data[6].seam[i]?.value,
        easting: data[7].easting[i]?.value,
        northing: data[8].northing[i]?.value,
        latitude: data[9].latitude[i]?.value,
        longitude: data[10].longitude[i]?.value,
        act_floor: data[11].act_floor[i]?.value,
        act_roof: data[12].act_roof[i]?.value,
        model_floor: data[13].model_floor[i]?.value,
        model_roof: data[14].model_roof[i]?.value,
        var_floor: data[15].var_floor[i]?.value,
        var_roof: data[16].var_roof[i]?.value,
      });
    }
    return result;
  }
};

export { generateSampleInformationTable, generateSampleInformationData };