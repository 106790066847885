import {
  Form,
  Row,
  Col,
  Input,
  Upload,
  Image,
  DatePicker,
  Alert,
  Spin
} from 'antd';

import {
  useEffect,
  useState
} from 'react';
import dayjs from 'dayjs';
import { HiOutlineTrash } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import { convertToMegaBytes } from 'utils/FileUtility';
import { useUploadFileMutation, useDeleteFileMutation } from 'api/Workflow';
import { 
  useLazyGetInitiationByIdQuery,
  usePostInitiationMutation,
  usePutInitiationMutation
} from 'api/ConfidentLevel';
import { FILES, URL } from 'constant';
import { getPathType } from 'utils/PathUtility';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { transformError } from 'utils/ErrorTransformer';

import Toast from 'components/Toast';
import ButtonAccent from 'components/ButtonAccent';
import LoadingIcon from 'components/LoadingIcon';
import { useModalConfirmationContext } from 'components/ModalConfirmationProvider';

const Initiation = () => {
  const navigate = useNavigate();
  const loc = useLocation();
  const { RangePicker } = DatePicker;

  const confidentLevelId = new URLSearchParams(loc.search).get('id');
  const isEditMode = getPathType(loc) === 'edit';
  const { user } = useSelector((state) => state.auth);

  const { showModal, resetModal } = useModalConfirmationContext();

  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [totalSize, setTotalSize] = useState(0);
  const [files, setFiles] = useState([]);
  const [isFileExceedLimit, setIsFileExceedLimit] = useState(false);
  const [idDelete, setIdDelete] = useState(null);

  const [postInitiation, {
    isLoading: initiationIsLoading,
    isError: initiationIsError,
    error: initiationError,
    isSuccess: initiationIsSuccess
  }] = usePostInitiationMutation();

  const [putInitiation, {
    isLoading: putInitiationIsLoading,
    isError: putInitiationIsError,
    error: putInitiationError,
    isSuccess: putInitiationIsSuccess
  }] = usePutInitiationMutation();

  const [uploadFile, {
    data: fileRes,
    isLoading: fileResIsLoading,
    isError: fileResIsError,
    error: fileResError
  }] = useUploadFileMutation();
  
  const [deleteFile, {
    isLoading: fileDeleteIsLoading,
    isError: fileDeleteIsError,
    error: fileDeleteError
  }] = useDeleteFileMutation();

  const [getInitiationById, {
    data: getInitiation,
    isFetching: getInitiationIsFetching,
    isError: getInitiationIsError,
    error: getInitiationError
  }] = useLazyGetInitiationByIdQuery();

  const generateFormData = (file) => {
    const formData = new FormData();
    
    formData.append('files', file);
    
    return formData;
  };

  const beforeUploadFile = (file) => {
    const { size } = file;
    const tempTotalSize = totalSize + size;

    if (convertToMegaBytes(totalSize) > FILES.MAX) {
      setIsFileExceedLimit(true);

      return;
    } else {
      setTotalSize(tempTotalSize);
    }
  };

  const onChangeFile = (info) => {
    const { file } = info;

    const form = generateFormData(file);
    setIsFileExceedLimit(false);
    uploadFile(form);
  };

  const onRemoveFile = (id) => {
    const isExist = getInitiation?.guidance_files?.some((v) => v.id === id);
    if (isEditMode && isExist) {
      setFiles((prevState) => prevState.filter(v => v.id !== id));
    } else {
      deleteFile({ id: id });
    
      setIdDelete(id);
    }

    resetModal();
  };

  const showDeleteModal = (id) => {
    showModal({
      isShown: true,
      type: 'delete',
      message: 'Are you sure you want to delete this file?',
      onSubmit: () => onRemoveFile(id)
    });
  };

  const onFinish = (values) => {
    const body = {
      title: values.title,
      company_id: user?.company.id,
      date_from: isEditMode ? getInitiation?.date_from : values.periode[0].format('YYYY-MM-DDT00:00:00.000Z'),
      date_to: isEditMode ? getInitiation?.date_to : values.periode[1].format('YYYY-MM-DDT00:00:00.000Z'),
      guidance_files: files
    };

    if (isEditMode) {
      putInitiation({
        id: confidentLevelId,
        body: body
      });

      return;
    }

    postInitiation(body);
  };

  useEffect(() => {
    if (isEditMode) {
      getInitiationById({ id: confidentLevelId });
    }
  }, []);

  useEffect(() => {
    if (fileRes && fileRes.length) {
      setFiles((prevState) => {
        if (prevState && prevState.length) {
          return [
            ...prevState,
            ...fileRes
          ];
        }
  
        return fileRes;
      });
    }
  }, [fileRes]);

  useEffect(() => {
    form.setFieldValue('company', user?.company?.alias_name);
  }, [user]);

  useEffect(() => {
    if (!isEmpty(getInitiation)) {
      form.setFieldValue('title', getInitiation?.title);
      setFiles(getInitiation?.guidance_files);
      let tempTotalSize = 0;
      getInitiation?.guidance_files.forEach((item) => {
        tempTotalSize += convertToMegaBytes(item.size);
      });
      setTotalSize(tempTotalSize);
      form.setFieldValue('periode', [dayjs(getInitiation?.date_from), dayjs(getInitiation?.date_to)]);
    }
  }, [getInitiation]);

  useEffect(() => {
    if (fileDeleteError && fileDeleteError?.originalStatus !== 200) {
      const errorMsg = fileDeleteError;

      toast.error(<Toast message={'ERROR'} detailedMessage={`${transformError(errorMsg).message}`} />);
      return;
    } else {
      setFiles((prevState) => prevState.filter(v => v.id !== idDelete));
    }
  }, [fileDeleteIsError, fileDeleteError]);

  useEffect(() => {
    if (fileResIsError) {
      const errorMsg = fileResError;

      toast.error(<Toast message={'ERROR'} detailedMessage={`${transformError(errorMsg).message}`} />);
      return;
    }

    if (getInitiationIsError) {
      const errorMsg = getInitiationError;

      toast.error(<Toast message={'ERROR'} detailedMessage={`${transformError(errorMsg).message}`} />);
      return;
    }
  }, [
    fileResIsError,
    fileResError,
    getInitiationIsError
  ]);

  useEffect(() => {
    if (initiationIsError) {
      const errorMsg = initiationError;

      toast.error(<Toast message={'ERROR'} detailedMessage={`${transformError(errorMsg).message}`} />);
      return;
    }

    if (initiationIsSuccess) {
      navigate(`/${URL.ACTIVITY_LEVEL}/${URL.MINE_PLANNING_OE}/confident-level`);
        
      return;
    }
  }, [initiationIsError, initiationIsSuccess]);

  useEffect(() => {
    if (putInitiationIsError) {
      const errorMsg = putInitiationError;

      toast.error(<Toast message={'ERROR'} detailedMessage={`${transformError(errorMsg).message}`} />);
      return;
    }

    if (putInitiationIsSuccess) {
      navigate(`/${URL.ACTIVITY_LEVEL}/${URL.MINE_PLANNING_OE}/confident-level`);
        
      return;
    }
  }, [putInitiationIsError, putInitiationIsSuccess]);

  return (
    <Form
      layout="vertical"
      autoComplete="off"
      form={form}
      requiredMark={false}
      labelCol={{ span: 24 }}
      onFinish={(v) => onFinish(v)}
    >
      <div className="p-5 bg-white rounded-lg">
        <span className="font-bold text-lg">{isEditMode ? 'Edit' : 'Create'} Initiation</span>
        {
          getInitiationIsFetching ?
            <div className="flex flex-row justify-center items-center">
              <LoadingIcon />
            </div>
            :
            <>
              <Row className='mt-5' gutter={[32, 0]}>
                <Col span={12}>
                  <Form.Item
                    name="title"
                    label="Title"
                    rules={[{ required: true }]}
                  >
                    <Input placeholder="Enter Title" disabled={isEditMode} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="periode"
                    label="Periode"
                    rules={[{ required: true }]}
                  >
                    <RangePicker
                      allowClear
                      value={values?.periode}
                      picker={'month'}
                      className='w-full'
                      disabled={isEditMode}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="company"
                    label="Company"
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>

              <div>
                <div className='font-bold mb-2'>Guidance</div>
                {
                  isFileExceedLimit && (
                    <Alert
                      type="error"
                      message="File size exceed limit of 25MB, please choose another one."
                      className="mb-5"
                    />
                  )
                }
                <Upload
                  showUploadList={false}
                  accept='image/jpeg, .dwg'
                  beforeUpload={beforeUploadFile}
                  customRequest={(opt) => onChangeFile(opt)}
                >
                  <div className='flex flex-row items-center'>
                    {
                      fileResIsLoading
                        ? <Spin size='small'></Spin>
                        : <Image
                          preview={false}
                          width={35}
                          src={`${process.env.PUBLIC_URL}/assets/icon-upload-guidance.svg`}
                        />
                    }
                    <span className='ml-3'>Upload document up to <span className='text-[#462F92]'>25mb</span></span>
                  </div>
                </Upload>
                {
                  files.length !== 0 &&
                <div className='mt-5 grid grid-cols-3 md:grid-cols-5 xl:grid-cols-7 gap-5'>
                  {
                    files.map((data, index) => {
                      return (
                        <div key={index}>
                          <div className={'relative bg-[#EBEDF8] rounded-md p-2'}>
                            <div className='bg-white absolute -top-1 -right-1 rounded shadow p-1 z-10'>
                              {
                                fileDeleteIsLoading
                                  ? <Spin size='small'></Spin>
                                  : <HiOutlineTrash 
                                    className='text-[#ED3F3F] text-bold cursor-pointer'
                                    onClick={() => showDeleteModal(data.id)}
                                  />
                              }
                            </div>
                            <div>
                              <div className='w-full flex justify-center'>
                                <Image
                                  preview={false}
                                  width={100}
                                  src={data.thumbnail || `${process.env.PUBLIC_URL}/assets/icon-dwg-file.svg`}
                                  className="rounded-lg"
                                />
                              </div>
                            </div>
                          </div>
                          <p className='text-center mt-3 text-[10px] text-[#8B849B] truncate'>
                            {data.fileName}
                          </p>
                        </div>
                      );
                    })
                  }
                </div>
                }
              </div>
            </>
        }
      </div>

      <Form.Item>
        <div className="mt-5 w-full flex flex-row items-center">
          <div className="ml-auto flex flex-row items-center gap-x-3">
            <ButtonAccent
              isLoading={initiationIsLoading || putInitiationIsLoading || getInitiationIsFetching}
              isBordered
              title="Cancel"
              onClick={() => navigate(-1)}
            />
            <ButtonAccent
              isLoading={initiationIsLoading || putInitiationIsLoading || getInitiationIsFetching}
              title={isEditMode ? 'Update' : 'Create'}
              htmlType="submit"
            />
          </div>
        </div>
      </Form.Item>
    </Form>
  );
};

export default Initiation;