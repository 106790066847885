import { Base } from './Base';

const Production = Base.injectEndpoints({
  endpoints: (build) => ({
    getLossTimeById: build.query({
      query: ({ id }) => ({
        url: `/production-lost-time/${id}`,
        method: 'GET'
      })
    }),
    getLatestLossTime: build.query({
      query: ({ id }) => ({
        url: `/production-lost-time/${id}/latest`,
        method: 'GET'
      })
    }),
    postLossTime: build.mutation({
      query: (body) => ({
        url: '/production-lost-time',
        method: 'POST',
        body
      })
    }),
    putLossTime: build.mutation({
      query: ({ id, body }) => ({
        url: `/production-lost-time/${id}`,
        method: 'PUT',
        body
      })
    })
  })
});

export const {
  useGetLossTimeByIdQuery,
  useLazyGetLossTimeByIdQuery,
  useLazyGetLatestLossTimeQuery,
  usePostLossTimeMutation,
  usePutLossTimeMutation
} = Production;