import ButtonAccent from 'components/ButtonAccent';
import CategoriesList from 'components/CategoriesList';
import UploadDocumentExcel from 'components/UploadExcel';
import ModalInformation from 'pages/DrillingPlan/components/ModalInformation';
import { useCallback, useEffect, useState } from 'react';
import { useTableWrapper } from 'utils/TableResponsive';
import { useLocation, useNavigate } from 'react-router';
import { getPathType } from 'utils/PathUtility';
import { useModalConfirmationContext } from 'components/ModalConfirmationProvider';
import { EMPTY, URL } from 'constant';
import PreventNavigation from 'constant/PreventNavigation';
import LoadingIcon from 'components/LoadingIcon';
import SampleInformationTopoCollarValidation from './SampleInformation';
import ResurveyTopoCollarValidation from './Resurvey';
import TopoCollarValueTopoCollarValidation from './TopoCollarValue';
import VarianceTopoCollarValidation from './Variance';
import { generateBodyTopoCollarValidation, generateTopoCollarInitiateData, topoCollarValidationCategories } from 'constant/TopoColarValidation/TopoCollarValidationData';
import TopoVersionResultTopoCollarValidation from './TopoVersionResult';
import _, { isEmpty } from 'lodash';
import { useLazyGetDrillHoleNameListQuery } from 'api/DrillingSummary';
import { toast } from 'react-toastify';
import { transformError } from 'utils/ErrorTransformer';
import { useLazyExportTopoCollarValidationQuery, useLazyGetTopoCollarValidationByIdQuery, usePostTopoCollarMutation, useUploadTopoCollarExcelMutation } from 'api/TopoCollarValidation';
import { Tag } from 'antd';

const InputTopoCollarValidation = () => {
  const [data, setData] = useState(null);
  const [file, setFile] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('Sample Information');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [payload, setPayload] = useState(_.cloneDeep(generateTopoCollarInitiateData));
  const [actualsData, setActualsData] = useState([]);
  const { wrapperWidth } = useTableWrapper();
  const [errorExcel, setErrorExcel] = useState(null);
  const [transformErrorUpload, setTransformErrorUpload] = useState(null);
  const [actualProperty, setActualProperty] = useState({
    planId: null,
    company: null,
    latestVersion: null,
    drillingStage: null,
    year: null
  });
  const [successMessage, setSuccessMessage] = useState(null);
  const location = useLocation();
  const actualState = location.state;
  const navigate = useNavigate();
  const isDetailMode = getPathType(location) === 'detail';
  const isEditMode = getPathType(location) === 'edit';
  const topoCollarId = new URLSearchParams(location.search).get('id');
  const {showModal, resetModal} = useModalConfirmationContext();

  const [
    getDrillHoleNameList,
    {
      data: drillHoleNameData,
      isFetching: drillHoleNameIsFetching,
      isError: drillHoleNameIsError,
      error: drillHoleNameError,
    },
  ] = useLazyGetDrillHoleNameListQuery({ refetchOnMountOrArgChange: true });

  const [
    uploadTopoCollarExcel,
    {
      data: excelRes,
      isLoading: excelResIsLoading,
      isError: excelResIsError,
      error: excelResError  
    }
  ] = useUploadTopoCollarExcelMutation();

  const [
    postTopoCollar, 
    {
      isLoading: postIsLoading,
      isSuccess: postIsSuccess,
      isError: postIsError,
      error: postError
    }] = usePostTopoCollarMutation();

  const [
    getTopoCollarValidationById,
    {
      data: detailTopoCollarValidationData,
      isFetching: detailTopoCollarValidationIsFetching,
      isError: detailTopoCollarValidationIsError,
      error: detailTopoCollarValidationError
    }
  ] = useLazyGetTopoCollarValidationByIdQuery( { refetchOnMountOrArgChange: true });

  const [exportTopoCollarValidation, {
    isLoading: exportExcelIsLoading,
    isError: exportExcelIsError,
    error: exportExcelError
  }] = useLazyExportTopoCollarValidationQuery();

  const onChange = (attribute, id, value) => {
    let tempData = [...payload];
    let choosedData = tempData.find(item => item.id === id);
    choosedData[attribute] = value === undefined ? null : value;
    setPayload([...tempData]);
  };

  const handleBackButton = () => {
    if(isDetailMode) {
      navigate(`/${URL.ACTIVITY_LEVEL}/${URL.GEOLOGY_OE}/topo-collar-validation`);
    } else {
      showModal({
        isShown: true,
        type: 'confirmation',
        title:'Are you sure want to leave this page?',
        message: 'Changes you made may not be saved',
        onSubmit: () => {
          navigate(`/${URL.ACTIVITY_LEVEL}/${URL.GEOLOGY_OE}/topo-collar-validation`);
          resetModal();
        }
      });
    }
  };

  const downloadFile = (url) => {
    const docLink = document.createElement('a');
    docLink.href = url;
    document.body.appendChild(docLink);
    docLink.click();
    document.body.removeChild(docLink);
  };

  const handleDeleteFile = () => {
    setData(null);
  };

  const addRow = () => {
    let tempData = _.cloneDeep(payload);
    let tempInitiate = _.cloneDeep(generateTopoCollarInitiateData);
    tempData.push({
      ...tempInitiate[0],
      id: tempData[tempData.length - 1].id + 1,
    });
    setPayload([...tempData]);
  };

  const removeRowById = (id) => {
    let tempData = _.cloneDeep(payload);
    let result =  tempData.filter(item => item.id !== id);
    setPayload([...result]);
  };

  const populateCategoriesModule = () => {
    switch(selectedCategory){
      case 'Sample Information':
        return (
          <SampleInformationTopoCollarValidation
            isDetailMode={isDetailMode}
            payload={payload}
            drillHoleNameProperties={{
              data: actualsData,
              isFetching: drillHoleNameIsFetching,
            }}
            removeRowById={removeRowById}
            onChange={onChange}
          />
        );
      case 'Resurvey':
        return (
          <ResurveyTopoCollarValidation payload={payload} />
        );
      case 'Topo & Collar Value':
        return (
          <TopoCollarValueTopoCollarValidation
            isDetailMode={isDetailMode}
            payload={payload}
            onChange={onChange}
          />
        );
      case 'Variance':
        return (
          <VarianceTopoCollarValidation payload={payload} />
        );
      case 'Topo Version & Result':
        return (
          <TopoVersionResultTopoCollarValidation 
            isDetailMode={isDetailMode}
            payload={payload}
            onChange={onChange}
          />
        );
      default:
        return;
    }
  };

  const populatActualInfo = () => {
    let validation = (isDetailMode || isEditMode) && !isEmpty(detailTopoCollarValidationData);
    setActualProperty({
      planId: validation? detailTopoCollarValidationData?.goe_drilling_plan_id : actualState?.planId,
      company: validation? detailTopoCollarValidationData?.minesite : actualState?.company,
      latestVersion: validation? detailTopoCollarValidationData?.version : actualState?.latestVersion,
      drillingStage: validation? detailTopoCollarValidationData?.drilling_stage : actualState?.drillingStage,
      year: validation? detailTopoCollarValidationData?.year : actualState?.year
    });
  };

  const populateDrillingStage = () => {
    if(actualProperty.drillingStage){
      if(actualProperty.drillingStage === 'PREPRODUCTION') {
        return 'Pre-production';
      }else {
        return 'Exploration';
      }
    }
    return EMPTY.STRING;
  };

  const jsonParseMessage = () => {
    try {
      return JSON.parse(errorExcel.data.error.message);;  
    } catch{
      return null;
    }
  };

  const populateInvalidAttribute = (attribute) => {
    switch(attribute){
      case 'collar_borehole_value':
      case 'topo_grid_value':
        toast.error('all field is mandatory in Topo & Collar Value',
          { toastId: 'validate-topo-collar-value-toast-error' }
        );
        return setSelectedCategory('Topo & Collar Value');
      case 'topo_version':
        toast.error('all field is mandatory in Topo Version & Result',
          { toastId: 'validate-topo-vesion-result-value-toast-error' }
        );
        return setSelectedCategory('Topo Version & Result');
      default:
        toast.error('Some field is mandatory in Sample Information ',
          { toastId: 'validate-sample-information-value-toast-error' }
        );
        return setSelectedCategory('Sample Information');
    }
  };

  const validateContent = () => {
    let validation = true;
    let attributeNull = [];
    payload.forEach(item => {
      Object.keys(item).forEach(key => {
        if((item[key] === null || item[key]==='') && key !== 'id') {
          validation = false;
          attributeNull.push({id: item.id, key});
        }else{
          if(attributeNull.length){
            attributeNull = attributeNull.filter(attribute=> !_.isEqual(attribute, {id: item.id, key}));
          }
        }
      });
    });
    if(validation === false) return populateInvalidAttribute(attributeNull[attributeNull.length - 1]?.key);
    return validation;
  };

  const onFinish = (isDraft) => {
    if(isDraft || validateContent()){
      const fileInfo = data?.file_info;
      const body = generateBodyTopoCollarValidation({
        content: payload,
        files: file,
        year: actualProperty.year,
        minesite: actualProperty.company,
        file_info: file ? null : fileInfo ?? null,
        is_draft: isDraft,
        drilling_stage: actualProperty.drillingStage,
        version: actualProperty.latestVersion,
      });
      setSuccessMessage(isDraft ? 'you have successfully saved draft of Topo vs Collar Validation ' 
        :'You have successfully submitted Topo vs Collar Validation');
      postTopoCollar({body, plan_id: actualProperty.planId});
    }
    
  };

  const mappingExcelData = () => {
    let tempPayload = payload;
    let tempData = _.cloneDeep(data?.properties);
    tempPayload.forEach((item, index)=> {
      if(item.drill_hole_name){
        let isSameDrillHoleName = tempData.find(data => data.drill_hole_name === item.drill_hole_name);
        if(isEmpty(isSameDrillHoleName)){
          tempData.unshift({
            ...item,
            id: tempData[tempData.length - 1].id + index + 1
          });
        }
      }
    });
    setPayload([...tempData]);
  };

  const handleCloseSite = useCallback((ev) => ev.preventDefault() ,[]);
  
  useEffect(() => {
    PreventNavigation(isDetailMode, handleCloseSite);
  }, []);

  useState(() => {
    populatActualInfo();
  },[actualState]);

  useEffect(() => {
    if(!isEmpty(data)){
      mappingExcelData();
    }
  },[data]);
  
  useEffect(() => {
    if (actualProperty.planId && actualProperty.latestVersion) {
      getDrillHoleNameList({ 
        drillingPlanId: actualProperty.planId, 
        version: actualProperty.latestVersion, 
        params:{ include_actual: true }  
      });
    }
  }, [actualProperty]);

  useEffect(() => {
    if(!isEmpty(drillHoleNameData)) {
      setActualsData(drillHoleNameData.data);
    }
  },[drillHoleNameIsFetching]);

  useEffect(() => {
    if(topoCollarId && (isDetailMode || isEditMode)) {
      getTopoCollarValidationById({id: topoCollarId});
    }
  }, [topoCollarId]);

  useEffect(() => {
    if(!isEmpty(detailTopoCollarValidationData)){
      setData({...detailTopoCollarValidationData?.content, file_info: detailTopoCollarValidationData?.file_info});
      populatActualInfo();
    }
  }, [detailTopoCollarValidationData, detailTopoCollarValidationIsFetching]);

  useEffect(() => {
    if (drillHoleNameIsError || detailTopoCollarValidationIsError || exportExcelIsError) {
      const generateToastId = () => {
        switch (true) {
          case drillHoleNameError:
            return 'get-drill-hole-name-list';
          case detailTopoCollarValidationError:
            return 'get-drill-hole-name-list';
          case exportExcelError:
            return 'get-drill-hole-name-list';
          default:
            return 'default';
        }
      };
      
      toast.error(
        transformError(drillHoleNameError || detailTopoCollarValidationError || exportExcelError).message,
        { toastId: `${generateToastId()}-toast-error` }
      );
    }
  }, [drillHoleNameIsError, detailTopoCollarValidationIsError, exportExcelIsError]);

  useEffect(() => {
    if (!isEmpty(errorExcel)) {
      setTransformErrorUpload(null);
      if (errorExcel.data) {
        const transformErrorUpload = errorExcel.status <= 400 ? jsonParseMessage(errorExcel.data.error.message) : null;
        setTransformErrorUpload(transformErrorUpload);
      }
      setIsModalOpen(true);
    }
  }, [errorExcel]);

  useEffect(() => {
    if (postIsSuccess) {
      toast.success(successMessage, { toastId: 'post-quality-toast-success' });
      navigate(`/${URL.ACTIVITY_LEVEL}/${URL.GEOLOGY_OE}/topo-collar-validation`);
    }

    if (postIsError) {
      toast.error(
        transformError(postError).message,
        { toastId: 'post-coal-recovery-toast-error' }
      );
    }
  }, [postIsSuccess, postIsError]);

  return (
    <div className="flex flex-col gap-4">
      {
        (!isDetailMode || (isDetailMode && !isEmpty(data?.file_info))) && (
          <div
            className="bg-white p-5 overflow-x-auto rounded-2xl"
            style={{ maxWidth: wrapperWidth }}
          >
            <div className=" mb-8 flex flex-row justify-between">
              <div className=" text-[18px] font-bold">Upload Data</div>
            </div>
            <UploadDocumentExcel
              onDownload={() => downloadFile(data.file_info?.url)}
              uploadExcel={uploadTopoCollarExcel}
              isEditMode={isEditMode}
              isDetailMode={isDetailMode}
              onDelete={handleDeleteFile} 
              onChange={setData}
              getFile={setFile}
              planId={actualProperty.planId}
              latestVersion={actualProperty.latestVersion}
              value={data}
              getErrorExcel={setErrorExcel}
              excelRes={excelRes}
              excelResError={excelResError}
              excelResIsError={excelResIsError}
              excelResIsLoading={excelResIsLoading}
            />
          </div>
        )
      }
      
      <div
        className="bg-white p-5 overflow-x-auto rounded-2xl"
        style={{ maxWidth: wrapperWidth }}
      >
        <div className=" mb-8 flex flex-row justify-between">
          <div className='flex flex-row gap-2'>
            <ButtonAccent size={'2xl'} title="Download Template" 
              onClick={() => downloadFile(`${process.env.REACT_APP_BASE_URL}/Topo_Collar_Validation_Template.xlsx`)}  
            />
            {isDetailMode && detailTopoCollarValidationData?.status === 'Submitted'  && (
              <ButtonAccent 
                isBordered 
                size={'2xl'} 
                title="Export Excel" 
                isLoading={exportExcelIsLoading}
                onClick={()=>{
                  exportTopoCollarValidation({
                    id: topoCollarId, 
                    company: actualProperty.company?.alias_name, 
                    year: actualProperty.year,
                    drillingStage: populateDrillingStage()
                  });
                }}
              />
            )}
          </div>
          <Tag className="px-3 py-2 font-bold rounded-2xl bg-[#2BB8A4]/[0.1] text-[#01B59C] border-none">
            <span className='text-sm'>
              {
              `V${actualProperty.latestVersion} / 
              ${actualProperty.year} / 
              ${actualProperty.company?.alias_name} / 
              Topo vs Collar Validation / 
              ${populateDrillingStage()}`
              }
            </span>
          </Tag>
        </div>
        {(isEditMode || isDetailMode) && detailTopoCollarValidationIsFetching ?
          (
            <LoadingIcon size={'sm'}/>
          ):(
            <> 
              {populateCategoriesModule()}
              <CategoriesList
                isActual={true}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                categories={topoCollarValidationCategories}
                onAdd={addRow}
                isDetailMode={isDetailMode}
              />
            </>
          )}
      </div>
      <div className="mt-5 flex flex-row items-center">
        {!isDetailMode && detailTopoCollarValidationData?.status !== 'Submited' &&
          <ButtonAccent isBordered title="Save As Draft" onClick={() => onFinish(true)} isLoading={postIsLoading} /> 
        }
        <div className="ml-auto flex flex-row items-center gap-x-3">
          <ButtonAccent isBordered title="Back" onClick={handleBackButton} isLoading={postIsLoading}/>
          { !isDetailMode && <ButtonAccent title="Next" onClick={() => onFinish(false)} isLoading={postIsLoading}/> }
        </div>
      </div>
      <ModalInformation
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        errorMessage={
          !isEmpty(transformErrorUpload) ?
            transformErrorUpload?.invalid_on?.map((v, i) => {
              return (<div>
                <span>{`${i + 1}. Invalid data at row ${v.row}`}</span>
                <br />
                <span>{`and column ${v.column}`}</span>
              </div>);
            })
            : transformError(errorExcel).message
        }
      />
    </div>
  );
};

export default InputTopoCollarValidation;
