import { Input } from 'antd';
import SelectSearchAccent from 'components/SelectSearchAccent';
import { EMPTY } from 'constant';
import { capitalize, isEmpty } from 'lodash';
import { GoPlus } from 'react-icons/go';
import { RxCross2 } from 'react-icons/rx';
const generateSampleInformationCoreRecoveryTable = (
  isDetailMode,
  payload, 
  drillHolenameProperties, 
  onChange, 
  onDeleteRow,
  onAddSampleId,
  deleteSampleId,
  showModal,
  resetModal
) =>[
  {
    dataIndex: 'action',
    title: 'Action',
    width: 5,
    sorter: false,
    align: 'center',
    fixed: 'left',
    render: (_, record, index) => {
      let isParent = record?.hasOwnProperty('drill_hole_name');   
      let dataDrillHoleName = payload.filter(item => item?.hasOwnProperty('drill_hole_name')); 
      return (
        <div className="flex justify-center">
          {payload.length <= 1 || dataDrillHoleName.length <= 1 ? (
            <></>
          ) : (isParent && !isDetailMode) && (
            <RxCross2
              size={28}
              color={'red'}
              className={'rounded-full p-1 cursor-pointer bg-red-200 '}
              onClick={() => {
                onDeleteRow(record);
              }}
            />
          )}
        </div>
      );
    },
  },
  {
    dataIndex: 'drill_hole_name',
    title: 'Drill Hole Name',
    width: '166px',
    align: 'center',
    fixed: 'left',
    sorter: false,
    render: (value, record, index) => {
      let isParent = record?.hasOwnProperty('drill_hole_name');
      let choosedDrillHoleName = payload?.map(item=>item.drill_hole_name !== '' && item.drill_hole_name);
      let drillHoleNames = drillHolenameProperties.data.map(item => item.drill_hole_name);
      let options = drillHoleNames.filter(item => !choosedDrillHoleName?.includes(item) );
      let drillHoleIsFetching = drillHolenameProperties.isFetching;
      return isParent && (
        <SelectSearchAccent
          isSingle={true}
          isDisabled={isDetailMode}
          value={value || undefined}
          placeholder="Select"
          options={options}
          isLoading={drillHoleIsFetching}
          className={'w-full text-left'}
          onChange={(val) => {            
            let choosedDrillHole = drillHolenameProperties.data.find(item=>item.drill_hole_name === val);
            let totalChildren = payload[payload.length-1].localChildrenId;
            if(record.drill_hole_name && val !== record.drill_hole_name){
              showModal({
                isShown: true,
                type: 'confirmation',
                title:'Are you sure you want to change the drill hole data?',
                message: 'This action cannot be undone. Please confirm your decision.',
                onSubmit: () => {
                  onChangeRelatedData(onChange, record, val, 'drill_hole_name');
                  for(let i = 0; i <= totalChildren ; i++) {
                    populateDataFromDrillHoleName(onChange, record, i, choosedDrillHole);
                  }
                  resetModal();
                }
              });
            } else {
              onChangeRelatedData(onChange, record, val, 'drill_hole_name' );
              for(let i = 0; i <= totalChildren ; i++) {
                populateDataFromDrillHoleName(onChange, record, i, choosedDrillHole);
              }
            }
          }}
          isError={!isDetailMode && !value}
        />
      );
    },
  },
  {
    dataIndex: 'sample_id',
    title: 'Sample ID',
    align: 'center',
    fixed: 'left',
    sorter: false,
    render: (value, record, index) => {
      let isParent = record?.hasOwnProperty('drill_hole_name');
      return (
        <div className='flex flex-row gap-2'>
          {
            !isDetailMode && 
            <>
              {!isParent? (
                <RxCross2
                  size={28}
                  color={'red'}
                  className={'rounded-full p-1 cursor-pointer bg-red-200 '}
                  onClick={() => deleteSampleId(record)}
                />
              ):(
                <GoPlus
                  size={28}
                  color={'green'}
                  className={'rounded-full p-1 cursor-pointer bg-green-200'}
                  onClick={() => {
                    onAddSampleId(record?.localId);
                  }} 
                />
              )}
            </>
          }
          <Input
            placeholder={'Enter Sample Id'}
            value={value}
            onChange={(e) => {
              onChangeRelatedData(onChange, record, e.target.value, 'sample_id');
            }}
            disabled={isDetailMode}
            status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
          /> 
        </div>
      );
    },
  },
  {
    dataIndex: 'month',
    title: 'Month',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {value}
        </div>
      );
    },
  },
  {
    dataIndex: 'area',
    title: 'Area',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {capitalize(value)}
        </div>
      );
    },
  },
  {
    dataIndex: 'pit_location',
    title: 'Pit Location',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {value}
        </div>
      );
    },
  },
  {
    dataIndex: 'pit_year',
    title: 'Pit Year',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {value}
        </div>
      );
    },
  },
];

const populateDataFromDrillHoleName = (onChange, record, localChildrenId, choosedDrillHole) => {
  onChange('sample_information', record?.localId, localChildrenId, 'month', choosedDrillHole?.month);
  onChange('sample_information', record?.localId, localChildrenId, 'area', choosedDrillHole?.area);
  onChange('sample_information', record?.localId, localChildrenId, 'pit_location', choosedDrillHole?.pit_location);
  onChange('sample_information', record?.localId, localChildrenId, 'pit_year', choosedDrillHole?.pit_year);
  onChange('resurvey', record?.localId, localChildrenId, 'resurvey_easting', choosedDrillHole?.resurvey_easting);
  onChange('resurvey', record?.localId, localChildrenId, 'resurvey_northing', choosedDrillHole?.resurvey_northing);
  onChange('resurvey', record?.localId, localChildrenId, 'resurvey_latitude', choosedDrillHole?.resurvey_latitude);
  onChange('resurvey', record?.localId, localChildrenId, 'resurvey_longitude', choosedDrillHole?.resurvey_longitude);
};

const onChangeRelatedData = (onChange, record, val, attribute) => {
  onChange('sample_information', record?.localId, record?.localChildrenId, attribute, val);
  onChange('coal_seam_recovery', record?.localId, record?.localChildrenId, attribute, val);
  onChange('thickness', record?.localId, record?.localChildrenId, attribute, val);
  onChange('resurvey', record?.localId, record?.localChildrenId, attribute, val);
};

const generateSampleInformationCoreRecoveryData = (data) => {
  if(!isEmpty(data)) {
    let dataSampleInformation = data?.find(item => item.sample_information)?.sample_information;
    let result = [];
    dataSampleInformation?.forEach((sample, indexSample) => {
      sample?.children?.forEach((child, index) => {
        result.push({
          localId: sample?.localId || indexSample + 1,
          ...(index === 0) && {drill_hole_name:sample.drill_hole_name},
          ...child
        });
      });
    });
    return result;
  }
};

export { generateSampleInformationCoreRecoveryTable, generateSampleInformationCoreRecoveryData };