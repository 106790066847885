import { RxCross2 } from 'react-icons/rx';
import { GoPlus } from 'react-icons/go';
import { InputNumber } from 'antd';
import { EMPTY } from 'constant';
import { isEmpty, isNumber } from 'lodash';

const generateDrillHoleValidationTable = (
  payload, 
  onChange, 
  onDeleteRow,
  onAddType,
  onDeleteType,
  isDetailMode
) => [
  {
    dataIndex: 'action',
    title: 'Action',
    width: 5,
    sorter: false,
    align: 'center',
    fixed: 'left',
    render: (_, record, __) => {
      let isParent = record?.hasOwnProperty('drill_hole_name');   
      let dataDrillHoleName = payload.filter(item => item?.hasOwnProperty('drill_hole_name')); 
      return (
        <div className="flex justify-center">
          {payload.length <= 1 || dataDrillHoleName.length <= 1 ? (
            <></>
          ) : isParent && !isDetailMode && (
            <RxCross2
              size={28}
              color={'red'}
              className={'rounded-full p-1 cursor-pointer bg-red-200 '}
              onClick={() => {
                onDeleteRow(record);
              }}
            />
          )}
        </div>
      );
    },
  },
  {
    dataIndex: 'drill_hole_name',
    title: 'Drill Hole Name',
    width: '166px',
    align: 'center',
    fixed: 'left',
    sorter: false,
    render: (value, record) => {
      let isParent = record?.hasOwnProperty('drill_hole_name');
      return isParent && (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {value}
        </div>
      );
    },
  },
  {
    dataIndex: 'seam',
    title: 'Seam',
    width: '166px',
    align: 'center',
    fixed: 'left',
    sorter: false,
    render: (value, record) => {
      let isParent = record?.hasOwnProperty('drill_hole_name');
      return isParent && (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {value}
        </div>
      );
    },
  },
  {
    dataIndex: 'type',
    title: 'Type',
    width: '166px',
    align: 'center',
    fixed: 'left',
    sorter: false,
    render: (value, record) => {
      let isParent = record?.hasOwnProperty('drill_hole_name');
      return (
        <div className='flex flex-row gap-2'>
          {!isDetailMode && (
            !isParent? (
              <RxCross2
                size={28}
                color={'red'}
                className={'rounded-full p-1 cursor-pointer bg-red-200 '}
                onClick={() => onDeleteType(record)}
              />
            ):(
              <GoPlus
                size={28}
                color={'green'}
                className={'rounded-full p-1 cursor-pointer bg-green-200'}
                onClick={onAddType} 
              />
            )
          )}
          <div className='w-full flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
            {value}
          </div>
        </div>
      );
    },
  },
  {
    dataIndex: 'drill_hole_data',
    title: 'Drill Hole Data',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, record, index) => {
      return (
        <InputNumber
          disabled={isDetailMode}
          className='w-full'
          placeholder={'Enter Drill Hole Data'}
          value={value}
          onChange={async (val) => {
            onChange('drill_hole_validation', record?.localId, record?.localChildrenId, 'drill_hole_data', val);

            await calculateDiff(record, onChange, val, record.model_data);
            await calculateModelDH(record, onChange, val, record.model_data);

            calculateResult(
              record,
              onChange,
              record.diff,
              record.percent_diff,
              record.model_drill_hole,
              payload[index].type
            );
          }}
          status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
        />
      );
    },
  },
  {
    dataIndex: 'model_data',
    title: 'Model Data',
    width: '196px',
    align: 'center',
    sorter: false,
    render: (value, record, index) => {
      return (
        <InputNumber
          disabled={isDetailMode}
          className='w-full'
          placeholder={'Enter Model Data'}
          value={value}
          onChange={async (val) => {
            onChange('drill_hole_validation', record?.localId, record?.localChildrenId, 'model_data', val);

            await calculateDiff(record, onChange, record.drill_hole_data, val);
            await calculateModelDH(record, onChange, record.drill_hole_data, val);

            calculateResult(
              record,
              onChange,
              record.diff,
              record.percent_diff,
              record.model_drill_hole,
              payload[index].type
            );
          }}
          status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
        />
      );
    },
  },
  {
    dataIndex: 'diff',
    title: 'Diff',
    width: '196px',
    align: 'center',
    sorter: false,
    render: (value) => {
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {value}
        </div>
      );
    },
  },
  {
    dataIndex: 'percent_diff',
    title: '% Diff',
    width: '196px',
    align: 'center',
    sorter: false,
    render: (value) => {
      const populateValue= ()=>{
        let finalResult = value * 100;
        if(isNumber(finalResult)){
          if(Number(finalResult) === finalResult && finalResult % 1 !== 0){
            return `${finalResult.toFixed(2)}`;
          }else {
            return `${finalResult}`;
          }
        }
        return finalResult;
      };
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {populateValue()}%
        </div>
      );
    },
  },
  {
    dataIndex: 'model_drill_hole',
    title: 'Model < Drill Hole',
    width: '196px',
    align: 'center',
    sorter: false,
    render: (value) => {
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {value}
        </div>
      );
    },
  },
  {
    dataIndex: 'result',
    title: 'Result',
    width: '196px',
    align: 'center',
    sorter: false,
    render: (value) => {
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {value}
        </div>
      );
    },
  },
];

const calculateDiff = (record, onChange, dh_data, model_data) => {
  let totalDiff = '';
  const temp_dh_data = dh_data ?? 0;
  
  if (model_data) {
    totalDiff = temp_dh_data - model_data;
    totalDiff = parseFloat(totalDiff);
  }

  onChange(
    'drill_hole_validation',
    record?.localId,
    record?.localChildrenId,
    'diff',
    Number(totalDiff)
  );

  calculatePercentDiff(record, onChange, totalDiff, model_data);
};

const calculatePercentDiff = (record, onChange, diff, model_data) => {
  let totalPercentDiff = '';
  const temp_diff = diff ?? 0;
  
  if (model_data) {
    totalPercentDiff = (temp_diff) / model_data;
    totalPercentDiff = Number(totalPercentDiff);
  }

  onChange(
    'drill_hole_validation',
    record?.localId,
    record?.localChildrenId,
    'percent_diff',
    Number(totalPercentDiff)
  );
};

const calculateModelDH = (record, onChange, dh_data, model_data) => {
  let modelDH = 'No Data';
  
  if (model_data && dh_data) {
    if ((model_data < dh_data)) {
      modelDH = 'Yes';
    } else {
      modelDH = 'No';
    }
  }

  onChange('drill_hole_validation', record?.localId, record?.localChildrenId, 'model_drill_hole', modelDH);
};

const calculateResult = (record, onChange, diff, percent_diff, model_drill_hole, type) => {
  let result = '';

  if (type === 'Thickness') {
    if (model_drill_hole === 'Yes') {
      if (percent_diff > 0.2) {
        result = 'IS > M (>20%)';
      } else if (percent_diff <= 0.2 && percent_diff > 0.1) {
        result = 'IS > M (10%-20%)';
      } else if (percent_diff <= 0.1 && percent_diff >= 0) {
        result = 'IS > M (10%)';
      }
    }
    
    if (model_drill_hole === 'No') {
      if (percent_diff < -0.2) {
        result = 'M > IS (>-20%)';
      } else if (percent_diff >= -0.2 && percent_diff < -0.1) {
        result = 'M > IS (10%-20%)';
      } else if (percent_diff >= -0.1 && percent_diff <= 0) {
        result = 'M > IS (10%)';
      }
    }
  }

  if (type === 'CV') {
    if (model_drill_hole === 'Yes') {
      if (diff > 200 && model_drill_hole) {
        result = 'IS > M (>200 kcal)';
      } else if (diff <= 200 && diff > 100) {
        result = 'IS > M (100 kcal-200 kcal)';
      } else if (diff <= 100 && diff >= 0) {
        result = 'IS > M (100 kcal)';
      }
    }
    
    if (model_drill_hole === 'No') {
      if (diff < -200 && model_drill_hole) {
        result = 'M > IS (>-200 kcal)';
      } else if (diff >= -200 && diff < -100) {
        result = 'M > IS (100 kcal-200 kcal)';
      } else if (diff >= -100 && diff <= 0) {
        result = 'M > IS (100 kcal)';
      }
    }
  }

  if (type === 'TS') {
    if (model_drill_hole === 'Yes') {
      if (diff > 0.4) {
        result = 'IS > M (>0.4)';
      } else if (diff <= 0.4 && diff > 0.2) {
        result = 'IS > M (0.2-0.4)';
      } else if (diff <= 0.2 && diff >= 0) {
        result = 'IS > M (0.2)';
      }
    }
    
    if (model_drill_hole === 'No') {
      if (diff < -0.4) {
        result = 'M > IS (>0.4)';
      } else if (diff >= -0.4 && diff < -0.2) {
        result = 'M > IS (0.2-0.4)';
      } else if (diff >= -0.2 && diff <= 0) {
        result = 'M > IS (0.2)';
      }
    }
  }

  if (type === 'Roof') {
    if (model_drill_hole === 'Yes') {
      if (diff > 2) {
        result = 'IS > M (>2m)';
      } else if (diff <= 2 && diff > 1) {
        result = 'IS > M (1m-2m)';
      } else if (diff <= 1 && diff >= 0) {
        result = 'IS > M (1m)';
      }
    }
    
    if (model_drill_hole === 'No') {
      if (diff < -2) {
        result = 'M > IS (>2m)';
      } else if (diff >= -2 && diff < -1) {
        result = 'M > IS (1m-2m)';
      } else if (diff >= -1 && diff <= 0) {
        result = 'M > IS (1m)';
      }
    }
  }

  onChange('drill_hole_validation', record?.localId, record?.localChildrenId, 'result', result);
};

const generateDrillHoleValidationData = (data) => {
  if(!isEmpty(data)) {
    let dataValidation = data?.find(item => item.drill_hole_validation)?.drill_hole_validation;
    let result = [];
    dataValidation?.forEach((validation, indexParent) => {
      validation?.children?.forEach((child, index) => {
        result.push({
          ...(index === 0) && {drill_hole_name:validation.drill_hole_name},
          ...child,
          seam: validation?.seam,
          localId: validation?.localId || indexParent + 1
        });
      });
    });
    return result;
  }
};

export { generateDrillHoleValidationTable, generateDrillHoleValidationData, calculateResult };