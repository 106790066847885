import { InputNumber } from 'antd';

const generateTopoColarValueTopoCollarValidationTable = ( isDetailMode, onChange ) => [
  {
    dataIndex: 'drill_hole_name',
    title: 'Drill Hole Name',
    width: '166px',
    align: 'center',
    fixed: 'left',
    sorter: false,
    render: (value) => {
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {value}
        </div>
      );
    },
  },
  {
    dataIndex: 'collar_borehole_value',
    title: 'Collar Borehole Value',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, record) => {
      return (
        <InputNumber
          disabled={isDetailMode}
          className='w-full'
          placeholder={'Enter Collar Borehole Value'}
          value={value}
          type='number'
          onChange={value => {
            onChange('collar_borehole_value', record.id, value);
          }}
        /> 
      );
    },
  },
  {
    dataIndex: 'topo_grid_value',
    title: 'Topo Grid Value',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, record) => {
      return (
        <InputNumber
          disabled={isDetailMode}
          className='w-full'
          placeholder={'Enter Topo Grid Value'}
          value={value}
          type='number'
          onChange={value => {
            onChange('topo_grid_value', record.id, value);
          }}
        /> 
      );
    },
  },
];

export { generateTopoColarValueTopoCollarValidationTable };