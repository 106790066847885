import {
  Card,
  Form,
  Input,
  InputNumber,
  Spin,
  Switch,
  Checkbox,
  Row,
  Col
} from 'antd';
import Title from 'antd/es/typography/Title';
import { FaMicrosoft, FaWhatsappSquare } from 'react-icons/fa';

import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { getPathType } from 'utils/PathUtility';
import { toast } from 'react-toastify';
import { transformError } from 'utils/ErrorTransformer';

import Toast from 'components/Toast';
import ButtonAccent from 'components/ButtonAccent';
import {
  useLazyGetUserByIdQuery,
  usePostUserMutation,
  usePutUserMutation
} from 'api/User';
import {
  useGetMasterDataCompaniesQuery,
  useGetMasterDataContractorsQuery
} from 'api/MasterData';
import { useGetRolesQuery } from 'api/Role';
import SelectMultipleAccent from 'components/SelectMultipleAccent';
import { useModalConfirmationContext } from 'components/ModalConfirmationProvider';
import SelectSearchAccent from 'components/SelectSearchAccent';
import { isEmpty } from 'lodash';
import { URL } from 'constant';

const UserForm = () => {
  const navigate = useNavigate();
  const loc = useLocation();
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);

  const { showModal, resetModal } = useModalConfirmationContext();

  const isEditMode = getPathType(loc) === 'edit';
  const userId = new URLSearchParams(loc.search).get('id');

  const [isExternal, setIsExternal] = useState(false);
  const [isMtActive, setIsMtActive] = useState(false);
  const [isWaActive, setIsWaActive] = useState(false);

  const [postUser, {
    isLoading: postUserIsLoading,
    isError: postUserIsError,
    error: postUserError,
    isSuccess: postUserIsSuccess
  }] = usePostUserMutation();

  const [putUser, {
    isLoading: putUserIsLoading,
    isError: putUserIsError,
    error: putUserError,
    isSuccess: putUserIsSuccess
  }] = usePutUserMutation();

  const [getUserById, {
    data: userData,
    isFetching: getUserIsFetching,
    isError: getUserIsError,
    error: getUserError
  }] = useLazyGetUserByIdQuery();

  const {
    data: dataCompanies,
    isFetching: companiesIsFetching
  } = useGetMasterDataCompaniesQuery(
    { refetchOnMountOrArgChange: true }
  );

  const {
    data: dataContractors,
    isFetching: contractorsIsFetching
  } = useGetMasterDataContractorsQuery(
    { refetchOnMountOrArgChange: true }
  );

  const {
    data: dataRoles,
    isFetching: rolesIsFetching
  } = useGetRolesQuery(
    { refetchOnMountOrArgChange: true }
  );

  const onFinish = (values) => {
    const body = {
      username: values.username,
      full_name: values.display_name,
      roles: values.roles,
      company_id: values.company,
      email: values.email,
      is_external: isExternal,
      is_active: values.is_active,
      microsoft_teams: values?.microsoft_teams || null,
      whatsapp_number: values?.whatsapp_number || null,
      contractor_id: values.contractor
    };

    if (values?.password) {
      body.password = values.password;
    }

    if (isEditMode) {
      putUser({ id: userId, body: body });

      return;
    }
    postUser(body);
  };

  useEffect(() => {
    if (isEditMode) {
      getUserById({ id: userId });
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(userData)) {
      setIsExternal(userData.is_external);
      setIsMtActive(Boolean(userData.microsoft_teams));
      setIsWaActive(Boolean(userData.whatsapp_number));
      form.setFieldsValue({
        display_name: userData.full_name,
        username: userData.username,
        roles: userData.roles?.map(v => v.id),
        email: userData.email,
        company: userData.company?.id || userData.user_company?.map(v => v.company_id)[0],
        is_active: true,
        is_external: userData.is_external,
        contractor: userData.contractor_id,
        microsoft_teams: userData.microsoft_teams,
        whatsapp_number: userData.whatsapp_number
      });
    }
  }, [userData]);

  useEffect(() => {
    if (putUserIsSuccess || postUserIsSuccess) {
      switch (true) {
        case ((postUserIsSuccess) || (putUserIsSuccess && userData.email !== values.email)) && isExternal:
          showModal({
            isShown: true,
            type: 'success',
            title: 'Verify Your Email',
            message: `An email has been sent to ${values.email || ''} with a link to verify your account`,
            onSubmit: () => {
              resetModal();
              navigate(`/${URL.ACTIVITY_LEVEL}/${URL.MINE_PLANNING_OE}/user-management`);
            }
          });
          break;
        case (putUserIsSuccess && userData.email === values.email):
          showModal({
            isShown: true,
            type: 'success',
            title: 'Success!',
            message: 'Successfully update user.',
            onSubmit: () => {
              resetModal();
              navigate(`/${URL.ACTIVITY_LEVEL}/${URL.MINE_PLANNING_OE}/user-management`);
            }
          });
          break;
        default:
          showModal({
            isShown: true,
            type: 'success',
            title: 'Success!',
            message: 'Successfully create new user.',
            onSubmit: () => {
              resetModal();
              navigate(`/${URL.ACTIVITY_LEVEL}/${URL.MINE_PLANNING_OE}/user-management`);
            }
          });
          break;
      }
    }
  }, [postUserIsSuccess, putUserIsSuccess]);

  useEffect(() => {
    if (getUserIsError) {
      const errorMsg = getUserError;

      toast.error(<Toast message={'ERROR'} detailedMessage={`${transformError(errorMsg).message}`} />);
      return;
    }
    if (putUserIsError) {
      const errorMsg = putUserError;

      toast.error(<Toast message={'ERROR'} detailedMessage={`${transformError(errorMsg).message}`} />);
      return;
    }
    if (postUserIsError) {
      const errorMsg = postUserError;

      toast.error(<Toast message={'ERROR'} detailedMessage={`${transformError(errorMsg).message}`} />);
      return;
    }
  }, [putUserIsError, postUserIsError, getUserIsError]);

  return(
    <Spin
      tip="Loading"
      size="large"
      spinning={
        isEditMode 
          ? getUserIsFetching || rolesIsFetching || companiesIsFetching || contractorsIsFetching 
          : false
      }
    >
      <Form
        labelCol={{ span: 24 }}
        requiredMark={false}
        onFinish={onFinish}
        layout="vertical"
        form={form}
      >
        <Card className='rounded-2xl'>
          <Title level={4}>{isEditMode ? 'Edit' : 'Create'} User</Title>
          <Row gutter={[32, 0]}>
            <Col span={12}>
              <Form.Item
                name='display_name'
                label='Display Name'
                rules={[
                  {
                    required: true
                  },
                ]}
              >
                <Input placeholder='Enter display name' />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='username'
                label='Username'
                rules={[
                  {
                    required: true
                  },
                ]}
              >
                <Input placeholder='Enter username' disabled={isEditMode} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='roles'
                label='Role'
                rules={[
                  {
                    required: true
                  },
                ]}
              >
                <SelectMultipleAccent
                  labelKey='name'
                  valueKey='id'
                  options={dataRoles}
                  placeholder='Select role'
                  isLoading={rolesIsFetching}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='email'
                label='Email'
                rules={[
                  { required: true, type: 'email' }
                ]}
              >
                <Input placeholder='Ex: user@banpuindo.co.id' disabled={(isEditMode && !isExternal)} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='company'
                label='Company'
                rules={[
                  {
                    required: true
                  },
                ]}
              >
                <SelectSearchAccent
                  labelKey='alias_name'
                  valueKey='id'
                  options={dataCompanies}
                  placeholder='Select company'
                  isLoading={companiesIsFetching}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='is_active'
                valuePropName='checked'
                label='Is Active'
              >
                <Switch disabled={(!isEditMode && isExternal)} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <div className='flex flex-row'>
                <Form.Item
                  name='is_external'
                  valuePropName='checked'
                  label='External'
                >
                  <Checkbox
                    checked={isExternal}
                    disabled={(isEditMode)}
                    onChange={(e) =>  {
                      setIsExternal(e.target.checked);
                      if (e.target.checked) {
                        form.setFieldValue('is_active', false);
                      }
                    }}
                  />
                </Form.Item>
                {
                  isExternal &&
                  <Form.Item
                    name='contractor'
                    label='Contractor'
                    rules={[
                      {
                        required: isExternal
                      },
                    ]}
                    className='w-full'
                  >
                    <SelectSearchAccent
                      labelKey='alias_name'
                      valueKey='id'
                      options={dataContractors}
                      placeholder='Select contractor'
                      isLoading={contractorsIsFetching}
                    />
                  </Form.Item>
                }
              </div>
            </Col>
            {
              (isEditMode && isExternal) &&
              <Col span={12}>
                <Form.Item
                  label="Password"
                  name="password"
                >
                  <Input.Password />
                </Form.Item>
              </Col>
            }
          </Row>
        </Card>
        <Card className='my-4 rounded-2xl'>
          <Title level={4} className=''>Connected Chat</Title>
          <Row gutter={[32, 0]}>
            <Col span={12}>
              <Form.Item
                name='microsoft_teams'
                label={
                  <div>
                    <label className='mr-3'>
                      Microsoft Teams
                    </label>
                    <Switch
                      checked={isMtActive}
                      size='small'
                      onChange={(v) => {
                        if (!v) {
                          form.setFieldValue('microsoft_teams', null);
                        }
                        setIsMtActive(v);
                      }}
                    />
                  </div>
                }
                rules={[
                  {
                    required: isMtActive
                  },
                  { type: 'url', warningOnly: true }
                ]}
              >
                <Input
                  prefix={<FaMicrosoft fill='#6546C3' />}
                  placeholder='Microsoft teams link'
                  disabled={!isMtActive}  
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='whatsapp_number'
                label={
                  <div>
                    <label className='mr-3'>
                      WhatsApp Number
                    </label>
                    <Switch
                      checked={isWaActive}
                      size='small'
                      onChange={(v) => {
                        if (!v) {
                          form.setFieldValue('whatsapp_number', null);
                        }
                        setIsWaActive(v);
                      }}
                    />
                  </div>
                }
                rules={[
                  {
                    required: isWaActive
                  }
                ]}
              >
                <InputNumber
                  prefix={<FaWhatsappSquare fill='#55A853' />}
                  placeholder='Ex: 0813xxxxxxxxx'
                  style={{ width: '100%' }}
                  disabled={!isWaActive}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>

        <Form.Item>
          <div className="flex flex-row gap-x-3 justify-end">
            <ButtonAccent
              isBordered
              isLoading={postUserIsLoading || putUserIsLoading}
              title="Cancel"
              onClick={() => navigate(-1)}
            />
            <ButtonAccent
              title={isEditMode ? 'Update' : 'Submit'}
              isLoading={postUserIsLoading || putUserIsLoading}
              htmlType='submit'
            />
          </div>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default UserForm;