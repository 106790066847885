import { RxCross2 } from 'react-icons/rx';
import { Input } from 'antd';
import { isEmpty } from 'lodash';

const generateRemarkTable = (
  payload,
  isDetailMode,
  onChange,
  onDeleteRow
) => [
  {
    dataIndex: 'action',
    title: 'Action',
    width: 5,
    sorter: false,
    align: 'center',
    fixed: 'left',
    render: (_, record, index) => {
      return (
        <div className="flex justify-center">
          {payload.length <= 1 ? (
            <></>
          ) : (
            <RxCross2
              size={28}
              color={`${isDetailMode? 'gray' : 'red'}`}
              className={`rounded-full p-1 ${isDetailMode? 'bg-gray-200':'cursor-pointer bg-red-200 '}`}
              onClick={() => onDeleteRow(index)}
            />
          )}
        </div>
      );
    },
  },
  {
    dataIndex: 'remarks',
    title: 'Remark',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, _, index) => {
      return (
        <Input
          disabled={isDetailMode}
          placeholder={'Enter remark'}
          value={value}
          onChange={(e) => {
            onChange(index, 'remarks', 'remarks' , e.target.value);
          }}
        /> 
      );
    },
  },
];

const generateReconciliationRemarksData = (file) => {
  if(!isEmpty(file)) {
    const sampleInformation = file.properties[0].sample_information;
    const tempData = file.properties?.[9]?.remarks || file.properties?.[9]?.remark;
    const totalData = sampleInformation[0].sample_id.length;
    const result = [];

    for(let i = 0; i < totalData; i++){
      result.push({
        sample_id: sampleInformation[0].sample_id[i]?.value,
        remarks: tempData[0].remarks?.[i]?.value || tempData[0].remark?.[i]?.value,
      });
    }
    return result;
  }
};

export { generateRemarkTable, generateReconciliationRemarksData };
