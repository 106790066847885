import { Base } from './Base';

const Reconciliation = Base.injectEndpoints({
  endpoints: (build)=>({
    validateCoreRecovery: build.mutation({
      query: (body) => ({
        url: '/goe/drillings/actuals/core-recoveries/validation',
        method: 'POST',
        body
      })
    }),
    postCoreRecovery: build.mutation({
      query: ({ body, planId }) => ({
        url: `/goe/drillings/actuals/${planId}/core-recoveries`,
        method: 'POST',
        body
      })
    }),
    uploadCoreRecoveryExcel: build.mutation({
      query: ({body, plan_id, params}) => ({
        url: `/goe/drillings/actuals/${plan_id}/core-recoveries/upload-excel`,
        method: 'POST',
        body,
        ...params
          ? { params }
          : undefined,
        // 10 minutes of upload timeout
        timeout: Number(10 * (60 * 1000))
      })
    }),
    getCoreRecoveryList: build.query({
      query: (params) => ({
        url: '/goe/drillings/actuals/core-recoveries',
        method: 'GET',
        ...(params)
          ? { params }
          : undefined
      })
    }),
    getCoreRecoveryById: build.query({
      query: ({id}) =>({
        url: `/goe/drillings/actuals/core-recoveries/${id}`,
        method: 'GET'
      })
    }),
    exportCoreRecoveryExcel: build.query({
      query:({id, company, year, drillingStage})=>({
        url:`/goe/drillings/actuals/core-recoveries/${id}/export-excel`,
        method: 'GET',
        responseHandler: async (response) => {
          const docLink = document.createElement('a');
          docLink.href = window.URL.createObjectURL(await response.blob());
          docLink.target = '_blank';
          docLink.download = `core_recovery_of_coal_${year}_${company}_${drillingStage}.xlsx`;
          docLink.click();
        },
        cache: 'no-cache',
      })
    })
  }),
});

export const {
  useValidateCoreRecoveryMutation,
  usePostCoreRecoveryMutation,
  useUploadCoreRecoveryExcelMutation,
  useGetCoreRecoveryListQuery,
  useLazyGetCoreRecoveryByIdQuery,
  useLazyExportCoreRecoveryExcelQuery
} = Reconciliation;

