import {
  createContext,
  useContext,
  useState
} from 'react';
import { Image } from 'antd';

import { EMPTY } from 'constant';

import ButtonAccent from 'components/ButtonAccent';

const ModalConfirmationContext = createContext();
const ModalConfirmation = (props) => {
  const { children } = props;

  const initialState = {
    isShown: false,
    type: 'success',
    message: EMPTY.STRING,
    onSubmit: () => {}
  };
  const [modalData, setModalData] = useState(initialState);
  
  const showModal = (data) => {
    setModalData(data);
  };

  const resetModal = () => {
    setModalData(initialState);
  };
  
  return (
    <ModalConfirmationContext.Provider value={{ showModal, resetModal }}>
      {
        modalData.isShown && (
          <div className="w-full h-full absolute flex flex-col items-center justify-center bg-black/25 left-0 top-0 z-20">
            <div className="p-5 flex flex-col items-center justify-center bg-white rounded-lg">
              <Image
                preview={false}
                width={100}
                src={`${process.env.PUBLIC_URL}/assets/${modalData.type === 'success' ? 'icon-modal-success.svg' : 'icon-modal-confirmation.svg'}`}
                className="mb-5"
              />
              <span className="mb-2 font-bold text-xl">
                {
                  modalData.title
                    ? modalData.title
                    : modalData.type === 'success'
                      ? 'Success!'
                      : 'Confirmation!'
                }
              </span>
              <span className="text-center text-gray-500/50">
                {modalData.message}
              </span>

              <div className="mt-5 flex flex-row items-center justify-center gap-x-5">
                {
                  modalData.type === 'success'
                    ? (
                      <ButtonAccent
                        title="Ok"
                        onClick={() => {modalData.title ? modalData.onSubmit() : showModal(initialState);}}
                      />
                    )
                    : (
                      <>
                        <ButtonAccent
                          isBordered
                          title="Cancel"
                          onClick={() => showModal(initialState)}
                        />
                        <ButtonAccent
                          title={modalData.title ? 'Ok' : 'Delete'}
                          onClick={() => modalData.onSubmit()}
                        />
                      </>
                    )
                }
              </div>
            </div>
          </div>
        )
      }
      {children}
    </ModalConfirmationContext.Provider>
  );
};

const useModalConfirmationContext = () => useContext(ModalConfirmationContext);

export default ModalConfirmation;
export { useModalConfirmationContext };
