import moment from 'moment';
import { toLower, upperFirst } from 'lodash';

import ButtonAccent from 'components/ButtonAccent';

const generateSubmissionColumns = (
  onViewEvidence
) => [
  {
    dataIndex: 'number',
    title: 'No',
    width: 5,
    onCell: (record) => ({
      /**
       * @param {string} record.number
       * because we set any `category` to `record.number`
       * so, any NaN `record.number` will take `question` column
       * */
      colSpan: isNaN(Number(record.number))
        ? 2
        : 1,
      className: 'pr-0'
    })
  },
  {
    dataIndex: 'question',
    title: 'Question',
    width: '300px',
    onCell: (record) => ({
      /**
       * if `record.number` is NaN we make `question` column zero
       * to make room for `record.number`
       * */
      colSpan: isNaN(Number(record.number))
        ? 0
        : 1
    })
  },
  {
    dataIndex: 'rating',
    title: 'Rating',
    width: '400px'
  },
  {
    dataIndex: 'rating_score',
    title: '',
    render: (text, record) => (
      <>
        {
          isNaN(Number(record.number))
            ? (
              <></>
            )
            : (
              <>
                <span className="font-bold">( {text} )</span>
              </>
            )
        }
      </>
    )
  },
  {
    dataIndex: 'evidence',
    title: 'Evidence',
    render: (text, record, index) => (
      <>
        {
          isNaN(Number(record.number))
            ? (
              <></>
            )
            : (
              <ButtonAccent
                title="View Evidence"
                size="xl"
                onClick={() => onViewEvidence(record.evidence)}
                isDisabled={!record.evidence.length}
              />
            )
        }
      </>
    )
  },
  {
    dataIndex: 'information',
    title: 'Information'
  },
  {
    dataIndex: 'score',
    title: 'Score',
    render: (text, record, index) => (
      <>
        {
          isNaN(Number(record.number))
            ? (
              <></>
            )
            : (
              <div className='px-2 py-1 flex flex-row justify-center font-bold rounded-lg bg-[#2D3D5A20] text-[#2D3D5A]'>
                {Number(text).toFixed(1)}
              </div>
            )
        }
      </>
    )
  },
  {
    dataIndex: 'pic',
    title: 'PIC'
  },
  {
    dataIndex: 'status',
    title: 'Status',
    render: (text, record, index) => (
      <>
        {
          isNaN(Number(record.number))
            ? (
              <></>
            )
            : (
              <div className='whitespace-nowrap px-2 py-1 flex flex-row justify-center font-bold rounded-lg bg-[#2D3D5A20] text-[#2D3D5A]'>
                {upperFirst(toLower(text))}
              </div>
            )
        }
      </>
    )
  },
  {
    dataIndex: 'submitted_date',
    title: 'Submtted Date',
    render: (text) => (
      <>{text ? moment(text).format('DD MMMM YYYY') : ''}</>
    )
  },
];

const generateSubmissionData = (data) => {
  if (data) {
    const transformedData = [];
    
    data.data.forEach((category) => {
      transformedData.push({
        number: category.category
      });
      
      category.questions.forEach((question) => {
        transformedData.push({
          number: question.number,
          question: question.question,
          rating: question.rating,
          evidence: question.evidence,
          information: question.information,
          score: question.score,
          pic: question.pic,
          status: question.status,
          submitted_date: question.submitted_date,
          rating_score: question.rating_score
        });
      });
    });
    
    return transformedData;
  }
  
  return [];
};

export {
  generateSubmissionColumns,
  generateSubmissionData
};