import moment from 'moment/moment';
import { toast } from 'react-toastify';
import { transformError } from 'utils/ErrorTransformer';
import { Base } from './Base';

const OmeQ = Base.injectEndpoints({
  endpoints: (build) => ({
    getOmeQs: build.query({
      query: (params) => ({
        url: `/ome/maintenance/q?${params}`,
        method: 'GET',
      }),
    }),
    getOmeQById: build.query({
      query: ({ id }) => ({
        url: `/ome/maintenance/q/${id}`,
        method: 'GET',
      }),
    }),
    postOmeQ: build.mutation({
      query: (body) => ({
        url: '/ome/maintenance/q',
        method: 'POST',
        body,
      }),
    }),
    putOmeQ: build.mutation({
      query: ({ id, body }) => ({
        url: `/ome/maintenance/q/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteOmeQ: build.mutation({
      query: ({ id }) => ({
        url: `/ome/maintenance/q/${id}`,
        method: 'DELETE',
      }),
    }),
    bulkCreateOmeQ: build.mutation({
      query: (body) => ({
        url: '/ome/maintenance/q/bulk-create',
        method: 'POST',
        body,
      }),
    }),
    postOmeQExcel: build.mutation({
      query: (body) => ({
        url: '/ome/maintenance/q/upload-excel',
        method: 'POST',
        body,
        timeout: Number(10 * (60 * 1000)),
      }),
    }),
    exportOmeQExcel: build.query({
      query: (params) => ({
        url: `/ome/maintenance/q/export-excel?${params}`,
        method: 'GET',
        responseHandler: async (response) => {
          if (response.status === 200) {
            const docLink = document.createElement('a');

            docLink.href = window.URL.createObjectURL(await response.blob());
            docLink.target = '_blank';
            docLink.download = `ome_q_${moment(new Date()).format(
              'DD_MM_YYYY_HH_mm_ss'
            )}.xlsx`;
            docLink.click();
          } else {
            toast.error(transformError(response).message, {
              toastId: 'export-excel-ome-q-error-toast',
            });
          }
        },
        cache: 'no-cache',
      }),
    }),
  }),
});

export const {
  useGetOmeQsQuery,
  useLazyGetOmeQsQuery,
  useLazyGetOmeQByIdQuery,
  usePostOmeQMutation,
  usePutOmeQMutation,
  useDeleteOmeQMutation,
  useBulkCreateOmeQMutation,
  usePostOmeQExcelMutation,
  useLazyExportOmeQExcelQuery,
} = OmeQ;
