import { ListModule } from 'constant/ListModuleActivityLevel';
import { generateMenu } from 'constant/Menu';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { usePermissionManager } from 'utils/PermissionManager';

const ActivityLevel = () => {
  const navigate = useNavigate();
  const [isThereSubMenu, setIsThereSubMenu] = useState({});
  const { isUserHasPermissionInMenu } = usePermissionManager();
  const goToPath = (title, children, menuName, path) => {
    document.title = title;
    if(!isEmpty(children)) {
      setIsThereSubMenu(prev=> ({...prev, [title]:!prev[title]}));
    }else {
      if (path.includes('maintenance-oe')) {
        navigate(path);

        return;
      }

      let registeredMenu = generateMenu(menuName).filter((item) => isUserHasPermissionInMenu(true, item.name));
      if(!isEmpty(registeredMenu)) {
        let url = registeredMenu[0].url || path;
        navigate(url);
      }else {
        toast.error(
          `You Don’t Have Permission to Access any ${title} Menu`,
          { toastId: `${menuName}-toast-error` }
        );
      }
    }
  };

  return (
    <div className='h-[43vw]'>
      <div className="grid grid-cols-5 gap-4">
        {ListModule.map((module, index) => {
          return (
            <div className='flex flex-row relative' key={index}>
              <div
                onClick={module.active ? () => goToPath(module.name, module?.children, module?.aliasName, module.path) : null}
                key={index}
                className={`flex flex-col gap-2 rounded-2xl bg-white p-3 items-center justify-center w-full ${
              module.active ? 'cursor-pointer' : 'opacity-50'
            }`}
              >
                <img
                  src={module.active ? module.picture : module.disabledPicture}
                  alt=""
                  width={74}
                  height={74}
                />
                <div
                  className={`text-[14px] text-center ${
                module.active
                  ? 'text-[#232323] font-semibold'
                  : 'text-[#AAAAAA]'
              }  `}
                >
                  {module.name}
                </div>
              </div>
              {!isEmpty(module.children) && isThereSubMenu[module.name] && (
                <div className='absolute right-[-205px] z-50 bg-white p-2 top-[15px] rounded-lg w-[200px] gap-1 flex flex-col'>
                  {
                    module.children.map((child, index) => {
                      return (
                        <div 
                          key={index}
                          className='cursor-pointer hover:bg-[#B1BACB50] text-[14px] rounded-lg p-2'
                          onClick={() => goToPath(module.name, null, child?.aliasName, child.path)}
                        >
                          {child.name}
                        </div>
                      );
                    })
                  }
                </div>
              )}
            
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ActivityLevel;
