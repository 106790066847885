import { generateCoalSeamRecoveryData, generateCoalSeamRecoveryTable } from 'constant/TableCoreRecovery/TableCoalSeamRecovery';
import TableDrillingSumarry from 'pages/DrillingPlan/DrillingSummary/TableDrillingSummary';

const CoalSeamRecoveryCoreRecovery = (props) => {
  const {isDetailMode, payload, onAddSampleId, onDeleteSampleId, onDeleteRow, onChange} = props;
  const tableData = generateCoalSeamRecoveryData(payload);
  const columns = generateCoalSeamRecoveryTable(isDetailMode, payload, onAddSampleId, onDeleteSampleId, onDeleteRow, onChange);
  return <TableDrillingSumarry columns={columns} tableData={tableData} />;
};

export default CoalSeamRecoveryCoreRecovery;