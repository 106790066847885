
import { generateResurveyTopoCollarValidationTable } from 'constant/TopoColarValidation/TableResurvey';
import TableDrillingSumarry from 'pages/DrillingPlan/DrillingSummary/TableDrillingSummary';

const ResurveyTopoCollarValidation = (props) => {
  const {payload} = props;
  const columns = generateResurveyTopoCollarValidationTable();
  return <TableDrillingSumarry columns={columns} tableData={payload} />;
};

export default ResurveyTopoCollarValidation;