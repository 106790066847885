const generateVarianceTopoCollarValidationTable = () => [
  {
    dataIndex: 'drill_hole_name',
    title: 'Drill Hole Name',
    width: '166px',
    align: 'center',
    fixed: 'left',
    sorter: false,
    render: (value) => {
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {value}
        </div>
      );
    },
  },
  {
    dataIndex: 'variance_between_topo_collar',
    title: 'Variance Between Topo & Collar',
    width: '166px',
    align: 'center',
    fixed: 'left',
    sorter: false,
    render: (_, record) => {
      let result = 
      record.collar_borehole_value && 
      record.topo_grid_value && 
      record.collar_borehole_value - record.topo_grid_value;
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {result}
        </div>
      );
    },
  },
  {
    dataIndex: 'range_of_variance',
    title: 'Range Of Variance',
    width: '166px',
    align: 'center',
    fixed: 'left',
    sorter: false,
    render: (_, record) => {
      let result = 
      record.collar_borehole_value && 
      record.topo_grid_value && 
      populateRangeOfVarianceFormula(record.collar_borehole_value - record.topo_grid_value);
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {result}
        </div>
      );
    },
  },
];

const populateRangeOfVarianceFormula = (varianceCollar) => {
  switch(true){
    case varianceCollar < -3:
      return 'Less than -3';
    case varianceCollar <= -2:
      return '-3 to -2';
    case varianceCollar < -1:
      return '-2 to -1';
    case varianceCollar <= 1:
      return '-1 to 1';
    case varianceCollar <= 2:
      return '1 to 2';
    case varianceCollar <= 3:
      return '2 to 3';
    case varianceCollar > 3:
      return 'More than 3';
    default:
      return '';
  }
};


export { generateVarianceTopoCollarValidationTable };