import { DatePicker, Select, Tooltip } from 'antd';
import SelectAddAccent from 'components/SelectAddAccent';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import moment from 'moment';
import { FiEye, FiUpload } from 'react-icons/fi';
import { GoPlus } from 'react-icons/go';
import { RxCross2 } from 'react-icons/rx';

const generatePermitTimelineTable = (
  addPermitTimeLine, 
  deletePermitTimeline, 
  payload, 
  setPayload, 
  properties, 
  version, 
  isDetailMode,
  onUpload
) => [
  {
    dataIndex: 'action',
    title: 'Action',
    width: 5,
    sorter: false,
    align: 'center',
    render: (_, record, index) => {
      return (
        <div className="flex justify-center">
          {index === 0 ? (
            <GoPlus
              size={28}
              color={`${isDetailMode? 'gray' : 'green'}`}
              className={`rounded-full p-1 ${isDetailMode? 'bg-gray-200':'cursor-pointer bg-green-200 '}`}
              onClick={() => isDetailMode? null : addPermitTimeLine()}
            />
          ) : (
            <RxCross2
              size={28}
              color={`${isDetailMode? 'gray' : 'red'}`}
              className={`rounded-full p-1 ${isDetailMode? 'bg-gray-200':'cursor-pointer bg-red-200 '}`}
              onClick={() => isDetailMode? null : deletePermitTimeline(record)}
            />
          )}
        </div>
      );
    },
  },
  {
    dataIndex: 'drilling_plan_version',
    title: 'Drilling Plan Version',
    width: '120px',
    sorter: false,
    align: 'center',
    render: () => <div>V{version}</div>
  },
  {
    dataIndex: 'update_permit_information_date',
    title: 'Update Permit Information Date',
    width: '120px',
    align: 'center',
    sorter: false,
    render: (value) => {
      return <div>{moment(value).format('DD/MMM/YYYY')}</div>;
    },
  },
  {
    dataIndex: 'permit_type',
    title: 'Permit Type',
    align: 'center',
    width: '166px',
    sorter: false,
    render: (value, record) => {
      return (
        <Select
          value={value||undefined}
          allowClear
          disabled={isDetailMode}
          placeholder="Select"
          options={[
            { label: 'Exploitation', value: 'EXPLOITATION' },
            { label: 'Exploration', value: 'EXPLORATION' },
          ]}
          className="w-full text-left"
          onChange={(val)=>{
            let tempIndex = payload.indexOf(record);
            payload[tempIndex].permit_type = val;
            setPayload([...payload]);
          }}
        />
      );
    },
  },
  {
    dataIndex: 'area',
    title: 'Area',
    width: '166px',
    align: 'center',
    sorter: false,
    render: (value, record) => {
      let areas = properties?.areas?.areasData?.map(area => capitalize(area));
      let areasIsFetching = properties?.areas?.areasIsFetching;
      return (
        <SelectAddAccent
          isNotTag
          isSingle= {true}
          isDisabled={isDetailMode}
          value={value? capitalize(value) : undefined}
          placeholder="Select"
          options={areas}
          isLoading={areasIsFetching}
          className={'w-full text-left'}
          onChange={(val)=>{
            let tempIndex = payload.indexOf(record);
            payload[tempIndex].area = val;
            setPayload([...payload]);
          }}
        />
      );
    },
  },
  {
    dataIndex: 'pit_name',
    title: 'PIT Name',
    width: '166px',
    align: 'center',
    sorter: false,
    render: (value, record) => {
      return (
        <SelectAddAccent
          isNotTag
          value={value || undefined}
          isSingle={true}
          placeholder="Select"
          isDisabled={isDetailMode}
          options={properties.pits.pitsData}
          isLoading={properties.pits.pitsIsFetching}
          className="w-full text-left"
          onChange={(val)=>{
            let tempIndex = payload.indexOf(record);
            payload[tempIndex].pit_name = val;
            setPayload([...payload]);
          }}
        />
      );
    },
  },
  {
    dataIndex: 'expected_granted_permit',
    title: 'Expected Granted Permit',
    width: '166px',
    align: 'center',
    sorter: false,
    render: (value, record) => {
      let tempIndex = payload.indexOf(record);
      return (
        <DatePicker
          allowClear
          value={value? dayjs(value,'DD/MM/YYYY'):''}
          format={'DD/MMM/YYYY'}
          disabled={isDetailMode}
          placeholder="dd/mm/yyyy"
          className="w-full text-left"
          onChange={(val) => {
            payload[tempIndex].expected_granted_permit = val;
            setPayload([...payload]);
          }}
        />
      );
    },
  },
  {
    dataIndex: 'files_info',
    title: 'Upload Evidence',
    width: '166px',
    align: 'center',
    sorter: false,
    render: (value, record) => {
      return (
        <div className="flex flex-row items-center justify-center gap-x-3">
          <div className='text-[12px] text-[#01B59C]'>
            {value.length ? (value.length > 1 ? `${value.length} Files Uploaded` : `${value.length} File Uploaded`): '' }
          </div>
          <div
            className="p-1 rounded-full bg-[#01B59C] cursor-pointer"
            onClick={() => {
              onUpload(record.id);
            }}
          >
            {
              !value.length && !isDetailMode
                ? (
                  <div className='flex flex-row gap-1'>
                    <Tooltip title={'Upload'} trigger={'hover'}>
                      <FiUpload
                        size="18px"
                        className="text-white"
                      />
                    </Tooltip>
                  </div>
                )
                : (
                  <Tooltip title={'View Detail'} trigger={'hover'}>
                    <FiEye
                      size="18px"
                      className="text-white"
                    />
                  </Tooltip>
                  
                )
            }
          </div>
        </div>
      );
    },
  },
];

export { generatePermitTimelineTable };
