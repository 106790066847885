import { isEmpty } from 'lodash';

const preventNonNumber = (ev) => {
  if (/^[0-9.]$/.test(ev.key)
    || ev.key === 'Backspace'
    || ev.key === 'Tab'
  ) {
    return;
  }

  ev.preventDefault();
};

const formatWithEnLocale = (value) => {
  /**
   * @param {string} value
   * value can be 0, so we should explicitly check `null` or `undefined`
   * `isEmpty(0)` is false
   * */
  if (isEmpty(value)) {
    return undefined;
  }

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'code',
    minimumFractionDigits: 0,
    maximumFractionDigits: 5
  }).format(value)
    .replace('USD', '')
    .trim();
};

const parseFromEnLocale = (value) => {
  try {
    /**
     * @param (string) value
     * remove any comma(s) from `NumberFormat`
     * */
    const cleanedValue = value.replace(/,/g, '');

    /**
     * parse `normalizedValue` to check its validity
     * */
    const parsedValue = parseFloat(cleanedValue);

    if (isNaN(parsedValue)) {
      return undefined;
    }

    return parsedValue;
  } catch (error) {
    /**
     * Expected console to give clarity if parser break
     * */
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export {
  formatWithEnLocale,
  parseFromEnLocale,
  preventNonNumber
};