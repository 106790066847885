
import { generateVersionResultTopoCollarValidationTable } from 'constant/TopoColarValidation/TableVersionResult';
import TableDrillingSumarry from 'pages/DrillingPlan/DrillingSummary/TableDrillingSummary';

const TopoVersionResultTopoCollarValidation = (props) => {
  const {isDetailMode, payload, onChange} = props;
  const columns = generateVersionResultTopoCollarValidationTable(isDetailMode, onChange);
  return <TableDrillingSumarry columns={columns} tableData={payload} />;
};

export default TopoVersionResultTopoCollarValidation;