import { ConfigProvider, Table } from 'antd';

import LoadingIcon from 'components/LoadingIcon';

const TableProduction = (props) => {
  const {
    isLoading,
    dataSource,
    columns,
    rowKey,
    summary,
    scrollWidth,
    customRow,
    headerBg,
    headerColor
  } = props;

  const data = dataSource?.map((v, idx) => ({ ...v, key: `${rowKey || 'key'}-${idx + 1}` }));

  const generateCustomRow = (record, index) => {
    if (customRow) {
      return customRow(record, index);
    }

    return index % 2 === 0 ? 'bg-white' : 'bg-gray-100';
  };

  return (
    <>
      {
        isLoading
          ? (
            <div className="flex flex-row justify-center items-center">
              <LoadingIcon />
            </div>
          )
          : (
            <ConfigProvider  theme={{
              components: {
                Table: {
                  headerBg: headerBg || '#2D3D5A',
                  headerColor: headerColor ||'white',
                },
              },
            }}>
              <Table
                pagination={false}
                dataSource={data}
                columns={columns}
                scroll={{ x: scrollWidth || 0 }}
                rowClassName={(record, index) => generateCustomRow(record, index)}
                summary={summary}
              />
            </ConfigProvider>
          )
      }
    </>
  );
};

export default TableProduction;