import { useEffect, useState } from 'react';
import { GoPlus } from 'react-icons/go';
import { Form, Select } from 'antd';
import { useGetMasterDataGoeCompaniesQuery, useGetMasterDataLossesQuery } from 'api/MasterData';
import { generateImprovementTabel, initialImprovementData } from 'constant/GOEDashboard/TableImprovement';
import TableDrillingSumarry from 'pages/DrillingPlan/DrillingSummary/TableDrillingSummary';
import ButtonAccent from 'components/ButtonAccent';
import { useDeleteImprovementMutation, useLazyGetListImprovementRequirementQuery, usePostImprovementsMutation } from 'api/GeologyDashboard';
import _, { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { transformError } from 'utils/ErrorTransformer';
import { useModalConfirmationContext } from 'components/ModalConfirmationProvider';

const Improvement = () => {
  const [payload, setPayload] = useState([]);
  const [detailLossOptions, setDetailLossOptions] = useState([]);
  const [form] = Form.useForm();
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [deletedId, setDeletedId] = useState(null);
  const {showModal, resetModal} = useModalConfirmationContext();
  const { 
    data: dataCompanies, 
    isFetching: companiesIsFetching, 
  } = useGetMasterDataGoeCompaniesQuery({ refetchOnMountOrArgChange: true });
  const {
    data: availabilityData,
    isFetching: availabilityIsFetching
  } = useGetMasterDataLossesQuery(
    { type: 'GOE_AVAILABILITY_LOSS', menu: 'dashboard' },
    { refetchOnMountOrArgChange: true }
  );

  const {
    data: qualityData,
    isFetching: qualityIsFetching
  } = useGetMasterDataLossesQuery(
    { type: 'GOE_QUALITY_LOSS', menu: 'dashboard'},
    { refetchOnMountOrArgChange: true }
  );

  const {
    data: performanceData,
    isFetching: performanceIsFetching
  } = useGetMasterDataLossesQuery(
    { type: 'GOE_PERFORMANCE_LOSS', menu: 'dashboard' },
    { refetchOnMountOrArgChange: true }
  );

  const [
    getListImprovementRequirement, 
    { 
      data: improvementsData, 
      isFetching: improvementsIsFetching,
      error: improvementsError,
      isError: improvementsIsError,
      isSuccess: improvementsSuccess
    }
  ] = useLazyGetListImprovementRequirementQuery();

  const [
    deleteImprovement,
    {
      isLoading: deleteImprovementIsLoading,
      isError: deleteKeyImprovementIsError,
      error: deleteKeyImprovementError,
      isSuccess: deleteImprovementIsSuccess
    },
  ] = useDeleteImprovementMutation();

  const [
    postImprovements,
    {
      isLoading: postImprovementsIsLoading,
      isError: postImprovementsIsError,
      error: postImprovementsError,
      isSuccess: postImprovementsIsSuccess
    },
  ] = usePostImprovementsMutation();

  const populateDetailLossOptions = (category, localId) => {
    let tempOptions = _.cloneDeep(detailLossOptions);
    if(tempOptions.length === 0){
      tempOptions.push({localId, options: populateChoosedDetailLossOptions(category)});
    }else {
      let filter = tempOptions.find(item => item?.localId === localId);
      if(isEmpty(filter)){
        tempOptions.push({localId, options: populateChoosedDetailLossOptions(category)});
      } else {
        const index = tempOptions.indexOf(filter);
        filter.options = populateChoosedDetailLossOptions(category);
        tempOptions[index] = filter;
      }
    }
    setDetailLossOptions([...tempOptions]);
  };

  const populateChoosedDetailLossOptions = (category) => {
    switch(category){
      case 'AVAILABILITY_LOSS':
        return availabilityData?.map(data=>({label:data.information, value: data.id}));
      case 'PERFORMANCE_LOSS':
        return performanceData?.map(data=>({label:data.information, value: data.id}));
      case 'QUALITY_LOSS':
        return qualityData?.map(data=>({label:data.information, value: data.id}));
      default:
        return setDetailLossOptions([]);
    }
  };

  const isDetailLossLoading = () => {
    return availabilityIsFetching || performanceIsFetching || qualityIsFetching;
  };

  const resetFilter = () => {
    form.setFieldValue('mine_site', undefined);
    form.setFieldValue('drilling_stage', undefined);
    setPayload([]);
    setIsFilterApplied(false);
  };
  
  const onChange = (index, categoryName, value) => {
    let temp = [...payload];
    temp[index][categoryName] = value;
    setPayload([...temp]);
  };

  const onAdd = () => {
    if(payload.length) {
      let temp = payload;
      temp.push({ ...initialImprovementData, localId: payload[payload.length - 1].localId + 1 });
      setPayload([...temp]);
    }
  };

  const onDeleteRow = (record) => {
    if(record?.id) {
      deleteImprovement({id: record?.id});
      setDeletedId(record?.localId);
    }else {
      deleteRowLocal(record?.localId);
    }
  };

  const deleteRowLocal = (id) => {
    let temp = payload.find((val) => val.localId === id);
    if (temp) {
      setPayload((prevState) =>
        prevState.filter((state) => state.localId !== temp.localId)
      );
    }
  };

  const onApplyFilter = (values) => {
    getListImprovementRequirement({
      minesite: values.mine_site,
      drilling_stage: values.drilling_stage,
    });
  };

  const validation = (payload) => {
    let validation = true;
    payload?.forEach(item => {
      Object.keys(item).forEach(key => {
        if(!item[key] && key !== 'submit_status' && key !== 'localId' ){
          validation = false;
        }
      });
    });
    let filter = form.getFieldsValue();
    Object.keys(filter).forEach(key => {
      if(!filter[key]) {
        validation = false;
      }
    });
    return validation;
  };

  const populateDetailLossPayload = (value) => {
    const options = [...availabilityData, ...performanceData, ...qualityData];
    const data = options.find(item => item?.id === value || item?.information === value);
    return data?.id;
  };

  const submitAll = ()=>{
    showModal({
      isShown: true,
      type: 'confirmation',
      title:'Are you sure want to submit all data?',
      message: 'all documents with draft status will also be submitted',
      onSubmit: () => {
        onSubmit(payload, false);
        resetModal();
      }
    });
  };

  const onSubmit = (payload, isDraft) => {
    let tempPayload = _.cloneDeep(payload);
    const selectedCompany = dataCompanies?.find(item=> item.alias_name === form.getFieldValue('mine_site'));
    let finalPayload = tempPayload.map(item => {
      return {
        ...(item?.id) && {id:item?.id},
        minesite: {id: selectedCompany?.id, name: selectedCompany?.name, alias_name: selectedCompany?.alias_name} || null,
        drilling_stage: form.getFieldValue('drilling_stage') || null,
        category_loss: item.category_loss?.toUpperCase() || null,
        project_name: item.project_name || null,
        detail_loss_id: populateDetailLossPayload(item.detail_loss) || null,
        status: item.status || null,
        is_draft: item?.submit_status === 'Submitted' || item?.submit_status === 'isBeingUpdated'? false : isDraft,
        pic: item.pic || null,
        start_date: item.start_date || null,
        due_date: item.due_date || null,
      };
    }); 
    if(isDraft || validation(tempPayload)) {
      setSuccessMessage(isDraft ? 
        'Improvement Requirement successfully saved as draft'
        : 'Improvement Requirement successfully submitted'
      );
      postImprovements(finalPayload);
    }else {
      toast.error(
        'Fields have not filled yet. Please fill all fields before submit',
        { toastId: 'validate-data-toast-error' }
      );
    }
  };

  const calculatePixel = (remVal) => {
    return `${window.innerWidth - remVal * 18}px`;
  };

  useEffect(() => {
    if(postImprovementsIsSuccess) {
      toast.success(successMessage, { toastId: 'validate-data-toast-success' });
      let filter = form.getFieldsValue();
      onApplyFilter(filter);
    }
  },[postImprovementsIsSuccess]);

  useEffect(() => {
    if(deleteImprovementIsSuccess) {
      toast.success('Improvement requirement successfully deleted',
        { toastId: 'validate-data-toast-success' });
      deleteRowLocal(deletedId);
    }
  },[deleteImprovementIsLoading]);

  useEffect(() => {
    if(improvementsSuccess) {
      if(improvementsData?.length) {
        let tempData = _.cloneDeep(improvementsData);
        setPayload(tempData.map((item, index) => ({...item, localId: index})));
      }else {
        let tempData = _.cloneDeep(initialImprovementData);
        setPayload([tempData]);
      }
      setIsFilterApplied(true);  
    }
  },[improvementsData, improvementsIsFetching]);

  useEffect(() => {
    if (improvementsIsError || postImprovementsIsError || deleteKeyImprovementIsError) {
      const generateToastId = () => {
        switch (true) {
          case improvementsError:
            return 'get-improvement';
          case postImprovementsError:
            return 'post-improvement';
          case deleteKeyImprovementError:
            return 'delete-improvement';
          default:
            return 'default';
        }
      };
      
      toast.error(
        transformError(improvementsError || postImprovementsError || deleteKeyImprovementError).message,
        { toastId: `${generateToastId()}-toast-error` }
      );
    }
  }, [improvementsIsError, postImprovementsIsError, deleteKeyImprovementIsError]);
  return (
    <div className="flex flex-col p-4 gap-4">
      <Form form={form} onFinish={onApplyFilter}>
        <div className={'grid-cols-2 flex gap-4 p-4 bg-[#B1BACB20] rounded-lg'}>
          <div className='w-full'>
            <span className="font-bold">Mine Site</span>
            <Form.Item
              name="mine_site"
              rules={
                [
                  {
                    required: true,
                    message: 'Please input Mine Site',
                  },
                ]
              }
            >
              <Select
                placeholder="Select mine site"
                options={dataCompanies?.map(item=>({label:item?.alias_name, value: item?.alias_name}))}
                className={`mt-2 w-full h-12 ${companiesIsFetching && 'animate-pulse animate-infinite'}`}
                disabled={isFilterApplied}
              />
            </Form.Item>
          </div>
          <div className='w-full'>
            <span className="font-bold">Drilling Stage</span>
            <Form.Item
              name="drilling_stage"
              rules={
                [
                  {
                    required: true,
                    message: 'Please input Drilling Stage',
                  },
                ]
              }
            >
              <Select
                placeholder="Select drilling stage"
                options={[
                  { label: 'Pre-production', value: 'PREPRODUCTION' },
                  { label: 'Exploration', value: 'EXPLORATION' },
                ]}
                className="mt-2 w-full h-12"
                disabled={isFilterApplied}
              />
            </Form.Item>
          </div>
          <div className="w-1/5 flex flex-row justify-center items-center gap-2">
            <ButtonAccent
              title="Apply Filter"
              htmlType="submit"
              isLoading={improvementsIsFetching}
            />
            <ButtonAccent
              title="Reset"
              onClick={resetFilter}
              isLoading={improvementsIsFetching}
            />
          </div>
        </div>
      </Form>
      <div style={{ maxWidth: calculatePixel(20) }}>
        <TableDrillingSumarry 
          columns={
            generateImprovementTabel(
              onChange, 
              payload, 
              onDeleteRow, 
              detailLossOptions, 
              populateDetailLossOptions, 
              isDetailLossLoading,
              onSubmit
            )
          } 
          tableData={payload} 
        />
      </div>
      <div className="flex  bg-[#2D3D5A] items-center px-6 py-4 gap-4">
        <GoPlus
          size={28}
          color={'green'}
          className={'rounded-full p-1 cursor-pointer bg-green-200'}
          onClick={onAdd}
        /> 
      </div>
      <div className="mt-5 flex flex-row items-center">
        <ButtonAccent 
          size={'xl'} 
          isBordered 
          title="Save As Draft All" 
          onClick={() => onSubmit(payload, true)}
          isLoading={postImprovementsIsLoading}
        />
        <div className="ml-auto flex flex-row items-center gap-x-3">
          <ButtonAccent 
            title="Submit All" 
            onClick={() => submitAll()} 
            isLoading={postImprovementsIsLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default Improvement;