import { isEmpty } from 'lodash';

const generateDrillingDepthTable = () => [
  {
    dataIndex: 'plan_drilling_depth',
    title: 'Plan Drilling Depth (m)',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className='flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {Number(value).toFixed(2)}
        </div>
      );
    },
  },
];

const generateDrillingDepthData = (file) => {
  if(!isEmpty(file)) {
    const dataDrillHoleName = file.properties[0].drill_hole_information[0].drill_hole_name;
    const data = file.properties[3].drilling_depth;
    let finalResult = dataDrillHoleName.map((drillHoleName, index) => {
      return data.reduce((result, currentObject, indexObject) => {
        const key = Object.keys(currentObject)[0];
        const value = currentObject[key][index]?.value;
        result[key] = value;
        if(indexObject === data?.length -1){
          result['drill_hole_name'] = drillHoleName?.value;
        }
        return result;
      }, {});
    });
    return finalResult;
  }
};

export { generateDrillingDepthTable, generateDrillingDepthData };
