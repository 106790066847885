import {
  useEffect,
  useRef,
  useState
} from 'react';
import {
  Alert,
  Image,
  Progress,
  Tooltip,
  Upload
} from 'antd';
import { AiOutlineClose } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { isEmpty, toLower } from 'lodash';

import { useUploadFileMutation, useDeleteFileMutation } from 'api/Workflow';
import { FILES } from 'constant';
import { convertToMegaBytes } from 'utils/FileUtility';
import { transformError } from 'utils/ErrorTransformer';

import ButtonAccent from 'components/ButtonAccent';
import { useImagePreviewContext } from 'components/ImagePreviewProvider';
import { useUploadActualExcelMutation, useUploadExcelMutation } from 'api/DrillingSummary';
import { TbTrash } from 'react-icons/tb';

const { Dragger } = Upload;
const InputFileAccent = (props) => {
  const {
    noTitle = false,
    isResetAfter = false,
    isAllTypes = false,
    isDetailMode = false,
    value,
    fileType,
    className,
    onDelete = () => {},
    onChange = () => {},
    onDrop = () => {},
    onPreview = () => {},
    getFile = () => {},
    getErrorExcel = () => {},
    buttonTitle,
    isActual,
    id,
    version,
    fileUploaded
  } = props;

  const [file, setFile] = useState(null);
  const [tempFile, setTempFile] = useState(null);
  const [isFileExceedLimit, setIsFileExceedLimit] = useState(false);
  const [isFileHasForbiddenExtensions, setIsFileHasForbiddenExtensions] = useState(false);
  const [isFileNameInvalid, setIsFileNameInvalid] = useState(false);
  const actionRef = useRef(FILES.ACTION_CHANGE);

  const { showPreview } = useImagePreviewContext();
  
  const [uploadFile, {
    data: fileRes,
    isLoading: fileResIsLoading,
    isError: fileResIsError,
    error: fileResError
  }] = useUploadFileMutation();

  const [uploadExcel, {
    data: excelRes,
    isLoading: excelResIsLoading,
    isError: excelResIsError,
    error: excelResError
  }] = useUploadExcelMutation();

  const [uploadActualExcel, {
    data: excelActualRes,
    isLoading: excelActualResIsLoading,
    isError: excelActualResIsError,
    error: excelActualResError
  }] = useUploadActualExcelMutation();
  
  const [deleteFile, {
    isLoading: fileDeleteIsLoading,
    isError: fileDeleteIsError,
    error: fileDeleteError
  }] = useDeleteFileMutation();
  
  const generateFormData = (file) => {
    const formData = new FormData();
    
    formData.append('files', file);
    
    return formData;
  };

  const checkFileHasForbiddenExtensions = (file) => {
    const forbiddenExtensions = ['exe', 'vb', 'apk', 'sql', 'js', 'scrp'];
    const fileExt = file.name.split('.');
    const ext = fileExt[fileExt.length - 1];
    
    return forbiddenExtensions.some((extension) => toLower(extension).includes(ext));
  };

  const checkFileBeforeUpload = (uploadFile, callback) => {
    setIsFileNameInvalid(false);

    if (convertToMegaBytes(uploadFile.size) > FILES.MAX) {
      setIsFileExceedLimit(true);
      setFile(null);

      return;
    }

    if (isAllTypes) {
      if (checkFileHasForbiddenExtensions(uploadFile)) {
        setIsFileHasForbiddenExtensions(true);
        setFile(null);

        return;
      }
    }

    if(fileUploaded && fileUploaded.length) {
      const isSameName = fileUploaded.find(item => {
        const index = item.fileName.indexOf('_');
        const prevFileName = item.fileName.slice(index + 1);

        return prevFileName === uploadFile?.name;
      });

      if(!isEmpty(isSameName)) {
        setIsFileNameInvalid(true);
        setFile(null);

        return;
      }
    }


    callback();
  };

  const handleOnChange = (info) => {
    const { file } = info;

    actionRef.current = FILES.ACTION_CHANGE;

    checkFileBeforeUpload(file, () => {
      const form = generateFormData(file);

      setIsFileHasForbiddenExtensions(false);
      setIsFileExceedLimit(false);
      if(fileType && !isActual) {
        setTempFile(file);
        uploadExcel({body: form, plan_id: id});
      } else if(isActual) {
        setTempFile(file);
        uploadActualExcel({body: form, plan_id: id, params:{latest_version: version}});
      }else {
        uploadFile(form);
      }
    });

    getErrorExcel(null);
  };

  const handleOnDrop = (ev) => {
    const { dataTransfer } = ev;
    const { files } = dataTransfer;

    actionRef.current = FILES.ACTION_DROP;

    checkFileBeforeUpload(files[0], () => {
      const form = generateFormData(files[0]);

      setIsFileHasForbiddenExtensions(false);
      setIsFileExceedLimit(false);
      if(fileType && !isActual) {
        uploadExcel({body: form, plan_id: id});
      } else if(!isActual) {
        uploadFile(form);
      }
    });
  };
  
  const handleDeleteFile = () => {
    if(fileType) {
      setFile(null);
      onDelete();
    } else{
      deleteFile({ id: file.id });
    }
  };

  useEffect(() => {
    setFile(value || null);
  }, [value]);

  useEffect(() => {
    switch (actionRef.current) {
      case FILES.ACTION_CHANGE:
        onChange(file);
        break;
      case FILES.ACTION_DROP:
        onDrop(file);
        break;
      default:
        onChange(file);
        break;
    }

    if (isResetAfter) {
      setFile(null);
    }
  }, [file]);

  useEffect(() => {
    if (fileRes && fileRes.length) {
      setFile(fileRes[0]);
    }
    if(!isEmpty(excelRes)) {
      getFile(tempFile);
      setFile(excelRes);
    }
    if(!isEmpty(excelActualRes)){
      getFile(tempFile);
      setFile(excelActualRes);
    }
  }, [fileRes, excelRes, excelActualRes]);

  useEffect(() => {
    if (excelResIsError) {
      getErrorExcel(excelResError);
      return;
    }

    if(excelActualResIsError) {
      getErrorExcel(excelActualResError);
      return;
    }

    if (fileResIsError || fileDeleteIsError) {
      if (fileDeleteError && fileDeleteError?.originalStatus === 200) {
        setFile(null);

        return;
      }

      toast.error(
        transformError(
          fileResIsError ? fileResError : fileDeleteError).message,
        { toastId: `toast-${fileResIsError ? 'upload' : 'delete'}-error` }
      );
    }
  }, [
    excelResError,
    excelResIsError,
    excelActualResError,
    excelActualResIsError,
    fileResIsError,
    fileResError,
    fileDeleteIsError,
    fileDeleteError
  ]);

  return (
    <div className={`p-5 flex flex-col bg-white rounded-lg ${className}`}>
      {
        noTitle
          ? (<></>)
          : (
            <span className="mb-5 font-bold text-lg">Upload File</span>
          )
      }
      {
        isFileExceedLimit && (
          <Alert
            type="error"
            message="File size exceed limit of 25MB, please choose another one."
            className="mb-5"
          />
        )
      }
      {
        isFileHasForbiddenExtensions && (
          <Alert
            type="error"
            message="Cannot upload this type of file, please choose another one"
            className="mb-5"
          />
        )
      }
      {
        isFileNameInvalid && (
          <Alert
            type="error"
            message="Cannot upload file with same name, please choose another one"
            className="mb-5"
          />
        )
      }
      {
        (isEmpty(file) && !isDetailMode)
          ? (
            <Dragger
              showUploadList={false}
              name="file"
              maxCount={1}
              accept={isAllTypes
                ? '*/*'
                : fileType? fileType : `${FILES.PNG},${FILES.JPG},${FILES.JPEG}`
              }
              beforeUpload={() => false}
              onChange={handleOnChange}
              onDrop={handleOnDrop}
            >
              <div className="p-5 flex flex-col items-center justify-center bg-[#2D3D5A10] border-dashed border-2 border-[#2D3D5A] rounded-lg">
                {
                  (fileResIsLoading || fileDeleteIsLoading || excelResIsLoading || excelActualResIsLoading)
                    ? (
                      <Progress
                        percent={100}
                        status="active"
                        strokeColor="#472F92"
                        showInfo={false}
                      />
                    )
                    : (
                      <>
                        <div className="p-3 flex flex-col items-center justify-center bg-white rounded-full">
                          <Image
                            preview={false}
                            width={30}
                            src={`${process.env.PUBLIC_URL}/assets/icon-upload.svg`}
                          />
                        </div>
                        <span className="mt-2">Drag and Drop your file here</span>
                        <span className="mb-5 text-gray-500/50">
                          {(!fileType && !isAllTypes) ? 'File must be in .png, .jpg, .jpeg,': 'Upload document'} max 25MB
                        </span>
                        <ButtonAccent title="Browse File" />
                      </>
                    )
                }
              </div>
            </Dragger>
          )
          : (
            <div className="p-5 flex flex-row items-center bg-[#2D3D5A10] border-dashed border-2 border-[#2D3D5A] rounded-lg">
              <Image
                preview={false}
                width={100}
                src={file?.thumbnail || `${process.env.PUBLIC_URL}/assets/icon-excel.svg`}
                className="rounded-lg cursor-pointer"
                onClick={() => showPreview({
                  name: file?.fileName,
                  size: file?.size,
                  url: file?.thumbnail
                })}
              />
              <div className="ml-5 flex flex-col justify-center">
                <Tooltip placement='topRight' title={file?.fileName || file?.file_info?.name} color='#2D3D5A' trigger={'hover'}>
                  <span className='w-[35vw] truncate'>{file?.fileName || file?.file_info?.name}</span>
                </Tooltip>
                <span className="text-gray-500/50">
                  {convertToMegaBytes(file?.size || file?.file_info?.size )} MB
                </span>
              </div>
              {
                fileType && <ButtonAccent
                  title={buttonTitle}
                  onClick={onPreview}
                  className={'ml-4 mb-2 w-32'}
                />
              }
              
              {
                isDetailMode
                  ? (<></>)
                  : (
                    <div
                      className="ml-auto p-1 bg-white rounded-full cursor-pointer"
                      onClick={handleDeleteFile}
                    >
                      {fileType && !isDetailMode ? (
                        <Tooltip title={'Delete'} trigger={'hover'}>
                          <TbTrash size="18px" color='#EB5545' />
                        </Tooltip>
                      ) : (
                        <AiOutlineClose size="18px" className="text-[#2D3D5A]" />
                      )}
                    </div>
                  )
              }
            </div>
          )
              
      }
    </div>
  );
};

export default InputFileAccent;