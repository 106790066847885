
import { generateResurveyData, generateResurveyTable } from 'constant/TableCoreRecovery/TableResurvey';
import TableDrillingSumarry from 'pages/DrillingPlan/DrillingSummary/TableDrillingSummary';

const Resurvey = (props) => {
  const { payload, onChange, onAdd, onDeleteRow, onAddSampleId, deleteSampleId, isDetailMode } = props;
  const tableData = generateResurveyData(payload);
  const columns = generateResurveyTable(
    tableData, 
    onChange, 
    onAdd, 
    onDeleteRow,
    onAddSampleId,
    deleteSampleId,
    isDetailMode
  );
  return <TableDrillingSumarry columns={columns} tableData={tableData}/>;
};

export default Resurvey;