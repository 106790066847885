import { Base } from './Base';

const PlanStop = Base.injectEndpoints({
  endpoints: (build) => ({
    getPlanStops: build.query({
      query: (params) => ({
        url: '/ome/maintenance/plan-stop',
        method: 'GET',
        ...(params ? { params } : undefined),
      }),
    }),
    getPlanStopById: build.query({
      query: ({ id }) => ({
        url: `/ome/maintenance/plan-stop/${id}`,
        method: 'GET',
      }),
    }),
    postPlanStop: build.mutation({
      query: (body) => ({
        url: '/ome/maintenance/plan-stop',
        method: 'POST',
        body,
      }),
    }),
    putPlanStop: build.mutation({
      query: ({ id, body }) => ({
        url: `/ome/maintenance/plan-stop/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    deletePlanStop: build.mutation({
      query: ({ id }) => ({
        url: `/ome/maintenance/plan-stop/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetPlanStopsQuery,
  useLazyGetPlanStopByIdQuery,
  usePostPlanStopMutation,
  usePutPlanStopMutation,
  useDeletePlanStopMutation,
} = PlanStop;
