import { DatePicker, Form, Input } from 'antd';
import { useLazyGetOmeApByIdQuery, usePostOmeApMutation, usePutOmeApMutation } from 'api/OmeAp';
import { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { toast } from 'react-toastify';
import ButtonAccent from 'components/ButtonAccent';
import { isEmpty } from 'lodash';
import Toast from 'components/Toast';
import { transformError } from 'utils/ErrorTransformer';
import { useGetMasterDataOmeAreasQuery, useGetMasterDataOmeLocationsQuery, useLazyGetMasterDataOmeMaintenanceParameterQuery } from 'api/MasterData';
import SelectAccent from 'components/SelectAccent';
import dayjs from 'dayjs';

const FormOmeAP = (props) => {
  const { isEdit, omeApId, onSubmit, sectionData, sectionIsFetching = false } = props;

  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [optionAvailabilityData, setOptionAvailabilityData] = useState([]);
  const [optionPerformanceData, setOptionPerformanceData] = useState([]);
  const [optionDetailedAData, setOptionDetailedAData] = useState([]);
  const [optionDetailedPData, setOptionDetailedPData] = useState([]);
  const [isStartActualGreater, setIsStartActualGreater] = useState(false);
  const [isActualTimeGreater, setIsActualTimeGreater] = useState(false);
  const [timeData, setTimeData] = useState({
    plan_time: null,
    actual_time: null,
    exceed_time: null
  });

  const [
    postOmeAp,
    {
      isLoading: postOmeApIsLoading,
      isError: postOmeApIsError,
      error: postOmeApError,
      isSuccess: postOmeApIsSuccess,
    },
  ] = usePostOmeApMutation();

  const [
    getOmeApById,
    {
      data: omeApData,
      isError: getOmeApIsError,
      error: getOmeApError,
    },
  ] = useLazyGetOmeApByIdQuery();

  const [
    putOmeAp,
    {
      isLoading: putOmeApIsLoading,
      isError: putOmeApIsError,
      error: putOmeApError,
      isSuccess: putOmeApIsSuccess,
    },
  ] = usePutOmeApMutation();

  const {
    data: locationData,
    isFetching: locationIsFetching,
  } = useGetMasterDataOmeLocationsQuery({ refetchOnMountOrArgChange: true });

  const {
    data: areaData,
    isFetching: areaIsFetching,
  } = useGetMasterDataOmeAreasQuery({ refetchOnMountOrArgChange: true });

  const [
    getOptionParameter,
    {
      error: optionParameterError,
      isError: optionParameterIsError,
      isFetching: optionParameterIsFetching
    }
  ] = useLazyGetMasterDataOmeMaintenanceParameterQuery();

  const fetchGetOptAvailabilityLoss = async () => {
    const optAL = await getOptionParameter({type: 'Availability loss'}).unwrap();

    setOptionAvailabilityData(optAL);
  };

  const fetchGetOptPerformanceLoss = async () => {
    const optPL = await getOptionParameter({type: 'Performance loss'}).unwrap();

    setOptionPerformanceData(optPL);
  };

  const fetchGetOptDetailed = async (subType, typeLoss) => {
    const optDetail = await getOptionParameter({ subType }).unwrap();

    if (typeLoss === 'A') {
      setOptionDetailedAData(optDetail);
      return;
    }
    if (typeLoss === 'P') {
      setOptionDetailedPData(optDetail);
      return;
    }
  };

  useEffect(() => {
    fetchGetOptAvailabilityLoss();
    fetchGetOptPerformanceLoss();
  }, []);

  useEffect(() => {
    if (values?.time_start_actual && values?.time_start_plan) {
      const startActual = new Date(values.time_start_actual);
      const startPlan = new Date(values.time_start_plan);
      setIsStartActualGreater(startActual > startPlan);

      if (startActual <= startPlan) {
        form.setFieldsValue({
          ...form,
          detailed_a_loss: null
        });
        setOptionDetailedAData([]);
      }
    }

    if (values?.time_end_plan && values?.time_start_plan) {
      let endPlan = new Date(values.time_end_plan);
      const startPlan = new Date(values.time_start_plan);
      endPlan = moment(endPlan).subtract(startPlan.getSeconds(), 's');
      endPlan = moment(endPlan).subtract(startPlan.getMinutes(), 'm');
      endPlan = moment(endPlan).subtract(startPlan.getHours(), 'h');

      setTimeData((prevState)=> {
        return {
          ...prevState,
          plan_time: new Date(endPlan)
        };
      });
    }

    if (values?.time_start_actual && values?.time_end_actual) {
      let endActual = new Date(values.time_end_actual);
      const startActual = new Date(values.time_start_actual);
      endActual = moment(endActual).subtract(startActual.getSeconds(), 's');
      endActual = moment(endActual).subtract(startActual.getMinutes(), 'm');
      endActual = moment(endActual).subtract(startActual.getHours(), 'h');

      setTimeData((prevState)=> {
        return {
          ...prevState,
          actual_time: new Date(endActual)
        };
      });
    }
  }, [values, omeApData]);

  useEffect(() => {
    setIsActualTimeGreater(timeData.actual_time > timeData.plan_time);
      
    if (timeData.actual_time <= timeData.plan_time) {
      form.setFieldsValue({
        ...form,
        detailed_p_loss: null
      });
      setOptionDetailedPData([]);
    }
  }, [timeData]);

  const onFinish = (values) => {
    const body = {
      'date': moment(new Date(values.date)).format('YYYY-MM-DD'),
      'ome_section_id': values.ome_section_id,
      'work_order': values.work_order,
      'description': values.description,
      'ome_location_id': values.ome_location_id,
      'ome_area_id': values.ome_area_id,
      'pic': values.pic,
      'unit_status': values.unit_status,
      'time_start_plan': moment(new Date(values.time_start_plan)).format('HH:mm:ss'),
      'time_end_plan': moment(new Date(values.time_end_plan)).format('HH:mm:ss'),
      'time_start_actual': moment(new Date(values.time_start_actual)).format('HH:mm:ss'),
      'time_end_actual': moment(new Date(values.time_end_actual)).format('HH:mm:ss'),
      'ome_availability_loss_id': values.detailed_a_loss,
      'ome_performance_loss_id': values.detailed_p_loss,
      'note_progress_pekerjaan': values.note_progress_pekerjaan,
      'crew': values.crew
    };

    if (isEdit) {
      putOmeAp({ id: omeApId, body });
    } else {
      postOmeAp(body);
    }
  };

  useEffect(() => {
    if (isEdit) {
      getOmeApById({ id: omeApId });
    }
  }, [omeApId, isEdit]);

  useEffect(() => {
    if (!isEmpty(omeApData)) {
      if (!isEmpty(omeApData.ome_availability_loss?.name)) {
        fetchGetOptDetailed(omeApData.ome_availability_loss.name, 'A');
      }
      if (!isEmpty(omeApData.ome_performance_loss?.name)) {
        fetchGetOptDetailed(omeApData.ome_performance_loss.name, 'P');
      }
      form.setFieldsValue({
        'date': dayjs(omeApData.date),
        'ome_section_id': omeApData.ome_section.id,
        'work_order': omeApData.work_order,
        'description': omeApData.description,
        'ome_location_id': omeApData.ome_location.id,
        'ome_area_id': omeApData.ome_area.id,
        'pic': omeApData.pic,
        'unit_status': omeApData.unit_status,
        'time_start_plan': dayjs(omeApData.time_start_plan, 'HH:mm:ss'),
        'time_end_plan': dayjs(omeApData.time_end_plan, 'HH:mm:ss'),
        'time_start_actual': dayjs(omeApData.time_start_actual, 'HH:mm:ss'),
        'time_end_actual': dayjs(omeApData.time_end_actual, 'HH:mm:ss'),
        'ome_availability_loss_id': omeApData.ome_availability_loss.name,
        'detailed_a_loss': omeApData.ome_availability_loss.id,
        'ome_performance_loss_id': omeApData.ome_performance_loss.name,
        'detailed_p_loss': omeApData.ome_performance_loss.id,
        'note_progress_pekerjaan': omeApData.note_progress_pekerjaan,
        'crew': omeApData.crew
      });
    }
  }, [omeApData]);

  useEffect(() => {
    if (getOmeApIsError) {
      toast.error(
        transformError(getOmeApError).message,
        { toastId: 'ome-ap-id-error' }
      );
    }
    if (optionParameterIsError) {
      toast.error(
        transformError(optionParameterError).message,
        { toastId: 'ome-ap-option-error' }
      );
    }
    if (postOmeApIsSuccess) {
      toast.success(
        <Toast message={'SUCCESS'} detailedMessage={'Successfuly create new data.'} />,
        { toastId: 'ome-ap-post-success' }
      );
      onSubmit();
    }
    if (putOmeApIsSuccess) {
      toast.success(
        <Toast message={'SUCCESS'} detailedMessage={'Successfuly update the data.'} />,
        { toastId: 'ome-ap-put-success' }
      );
      onSubmit();
    }
    if (putOmeApIsError) {
      toast.error(transformError(putOmeApError).message, {
        toastId: 'put-ome-ap-error-toast',
      });
    }
    if (postOmeApIsError) {
      toast.error(transformError(postOmeApError).message, {
        toastId: 'post-ome-ap-error-toast',
      });
    }
  }, [
    postOmeApIsSuccess,
    putOmeApIsSuccess,
    getOmeApIsError,
    optionParameterIsError,
    putOmeApIsError,
    postOmeApIsError
  ]);

  return (
    <Form
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      style={{
        maxWidth: 600,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      autoComplete="off"
      labelAlign="left"
      colon={false}
      requiredMark={false}
      form={form}
    >
      <Form.Item
        label="Date"
        name="date"
        rules={[
          {
            required: true,
            message: 'Please input date',
          },
        ]}
      >
        <DatePicker format="DD-MM-YYYY" allowClear className='w-full' />
      </Form.Item>

      <Form.Item
        label="Section"
        name="ome_section_id"
        rules={[
          {
            required: true,
            message: 'Please input section',
          },
        ]}
      >
        <SelectAccent
          isStringify={false}
          isLoading={sectionIsFetching}
          labelKey="name"
          valueKey="id"
          value={values?.ome_section_id}
          options={sectionData || []}
        />
      </Form.Item>

      <Form.Item
        label="Work Order"
        name="work_order"
        rules={[
          {
            required: true,
            message: 'Please input work order',
          },
        ]}
      >
        <Input placeholder='Please input work order' />
      </Form.Item>

      <Form.Item
        label="Description"
        name="description"
        rules={[
          {
            required: true,
            message: 'Please input description',
          },
        ]}
      >
        <Input.TextArea rows={2} placeholder='Please input description' />
      </Form.Item>

      <Form.Item
        label="Location"
        name="ome_location_id"
        rules={[
          {
            required: true,
            message: 'Please input location',
          },
        ]}
      >
        <SelectAccent
          isStringify={false}
          isLoading={locationIsFetching}
          labelKey="name"
          valueKey="id"
          value={values?.ome_location_id}
          options={locationData || []}
        />
      </Form.Item>

      <Form.Item
        label="Area"
        name="ome_area_id"
        rules={[
          {
            required: true,
            message: 'Please input area',
          },
        ]}
      >
        <SelectAccent
          isStringify={false}
          isLoading={areaIsFetching}
          labelKey="name"
          valueKey="id"
          value={values?.ome_area_id}
          options={areaData || []}
        />
      </Form.Item>

      <Form.Item
        label="PIC"
        name="pic"
        rules={[
          {
            required: true,
            message: 'Please input PIC',
          },
        ]}
      >
        <Input placeholder='Please input pic' />
      </Form.Item>

      <Form.Item
        label="Unit Status"
        name="unit_status"
        rules={[
          {
            required: true,
            message: 'Please input unit status',
          },
        ]}
      >
        <Input placeholder='Please input unit status' />
      </Form.Item>

      <Form.Item
        label="Plan Time Start"
        name="time_start_plan"
        rules={[
          {
            required: true,
            message: 'Please input plan time start',
          },
        ]}
      >
        <DatePicker
          format="HH:mm:ss"
          picker="time"
          className='w-full'
          onChange={() => 
            form.setFieldsValue({
              ...form,
              ome_availability_loss_id: null,
              detailed_a_loss: null,
              ome_performance_loss_id: null,
              detailed_p_loss: null
            })
          }
        />
      </Form.Item>

      <Form.Item
        label="Plan Time End"
        name="time_end_plan"
        rules={[
          {
            required: true,
            message: 'Please input plan time end',
          },
        ]}
      >
        <DatePicker
          format="HH:mm:ss"
          picker="time"
          className='w-full'
          onChange={() => 
            form.setFieldsValue({
              ...form,
              ome_availability_loss_id: null,
              detailed_a_loss: null,
              ome_performance_loss_id: null,
              detailed_p_loss: null
            })
          }
        />
      </Form.Item>

      <Form.Item
        label="Actual Time Start"
        name="time_start_actual"
        rules={[
          {
            required: true,
            message: 'Please input actual time start',
          },
        ]}
      >
        <DatePicker
          format="HH:mm:ss"
          picker="time"
          className='w-full'
          onChange={() => 
            form.setFieldsValue({
              ...form,
              ome_availability_loss_id: null,
              detailed_a_loss: null,
              ome_performance_loss_id: null,
              detailed_p_loss: null
            })
          }
        />
      </Form.Item>

      <Form.Item
        label="Actual Time End"
        name="time_end_actual"
        rules={[
          {
            required: true,
            message: 'Please input actual time end',
          },
        ]}
      >
        <DatePicker
          format="HH:mm:ss"
          picker="time"
          className='w-full'
          onChange={() => 
            form.setFieldsValue({
              ...form,
              ome_availability_loss_id: null,
              detailed_a_loss: null,
              ome_performance_loss_id: null,
              detailed_p_loss: null
            })
          }
        />
      </Form.Item>

      <Form.Item
        label="Availability Loss"
        name="ome_availability_loss_id"
        rules={[
          {
            required: isStartActualGreater,
            message: 'Please input availability loss',
          },
        ]}
      >
        <SelectAccent
          isStringify={false}
          value={values?.ome_availability_loss_id}
          options={optionAvailabilityData}
          onChange={(v) => {
            fetchGetOptDetailed(v, 'A');
          }}
          onClear={() => {
            form.setFieldsValue({
              ...form,
              detailed_a_loss: null
            });
          }}
          isDisabled={!isStartActualGreater}
        />
      </Form.Item>

      <Form.Item
        label="Detailed A Loss"
        name="detailed_a_loss"
        rules={[
          {
            required: (Boolean(optionDetailedAData?.length)),
            message: 'Please input detail a loss',
          },
        ]}
      >
        <SelectAccent
          isStringify={false}
          labelKey="detail_loss"
          valueKey="id"
          isLoading={optionParameterIsFetching}
          value={values?.detailed_a_loss}
          options={optionDetailedAData}
          isDisabled={!Boolean(optionDetailedAData?.length)}
        />
      </Form.Item>

      <Form.Item
        label="Performance Loss"
        name="ome_performance_loss_id"
        rules={[
          {
            required: isActualTimeGreater ,
            message: 'Please input performance loss',
          },
        ]}
      >
        <SelectAccent
          isStringify={false}
          value={values?.ome_performance_loss_id}
          options={optionPerformanceData}
          onChange={(v) => {
            fetchGetOptDetailed(v, 'P');
          }}
          onClear={() => {
            form.setFieldsValue({
              ...form,
              detailed_p_loss: null
            });
          }}
          isDisabled={!isActualTimeGreater}
        />
      </Form.Item>

      <Form.Item
        label="Detailed P Loss"
        name="detailed_p_loss"
        rules={[
          {
            required: (Boolean(optionDetailedPData?.length)),
            message: 'Please input detailed p loss',
          },
        ]}
      >
        <SelectAccent
          isStringify={false}
          labelKey="detail_loss"
          valueKey="id"
          isLoading={optionParameterIsFetching}
          value={values?.detailed_p_loss}
          options={optionDetailedPData}
          isDisabled={!Boolean(optionDetailedPData?.length)}
        />
      </Form.Item>

      <Form.Item
        label="Note"
        name="note_progress_pekerjaan"
        rules={[
          {
            required: true,
            message: 'Please input note',
          },
        ]}
      >
        <Input.TextArea rows={4} placeholder='Please input note' />
      </Form.Item>

      <Form.Item
        label="Crew"
        name="crew"
        rules={[
          {
            required: true,
            message: 'Please input crew',
          },
        ]}
      >
        <Input placeholder='Please input crew' />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <ButtonAccent
          title={isEdit ? 'Update' : 'Submit'}
          htmlType="submit"
          isLoading={putOmeApIsLoading || postOmeApIsLoading}
          isDisabled={putOmeApIsLoading || postOmeApIsLoading}
          className="w-full"
        />
      </Form.Item>
    </Form>
  );
};

export default FormOmeAP;