import { Input, Tooltip } from 'antd';
import { RxCross2 } from 'react-icons/rx';

const generateKeyHighlightIssueRequirement = (onChange, payload, onDeleteRow) => [
  {
    dataIndex: 'action',
    title: 'Action',
    width: 5,
    sorter: false,
    align: 'center',
    fixed: 'left',
    render: (_, record) => {
      return (
        <div className="flex justify-center">
          {payload.length <= 1 || record?.status === 'Submitted' ? (
            <></>
          ) : (
            <Tooltip trigger={'hover'} title={'Delete this data'}>
              <RxCross2
                size={28}
                color={'red'}
                className={'rounded-full p-1 cursor-pointer bg-red-200'}
                onClick={() => onDeleteRow(record)}
              />
            </Tooltip>
          )}
        </div>
      );
    },
  },
  {
    dataIndex: 'availability_key_issue',
    title: 'Availability',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, record, index) => {
      return (
        <Input
          disabled={record?.status === 'Submitted'}
          placeholder={'Enter performance'}
          value={value}
          onChange={(e) => {
            onChange(index, 'availability_key_issue' , e.target.value);
          }}
        /> 
      );
    },
  },
  {
    dataIndex: 'performance_key_issue',
    title: 'Performance',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, record, index) => {
      return (
        <Input
          disabled={record?.status === 'Submitted'}
          placeholder={'Enter performance'}
          value={value}
          onChange={(e) => {
            onChange(index, 'performance_key_issue' , e.target.value);
          }}
        /> 
      );
    },
  },
  {
    dataIndex: 'quality_key_issue',
    title: 'Quality',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, record, index) => {
      return (
        <Input
          disabled={record?.status === 'Submitted'}
          placeholder={'Enter quality'}
          value={value}
          onChange={(e) => {
            onChange(index, 'quality_key_issue' , e.target.value);
          }}
        /> 
      );
    },
  },
];

export { generateKeyHighlightIssueRequirement };