import { 
  generateDrillingMachineTable
} from 'constant/TableDrillingSumarry/TableDrillingMachine';
import TableDrillingSumarry from '../TableDrillingSummary';

const DrillingMachine = (props) => {

  const { drillingMachinehData, drillHoleName } = props;
  const columns = generateDrillingMachineTable(false);
  columns.unshift(drillHoleName);

  return <TableDrillingSumarry 
    columns={ columns }
    tableData={ drillingMachinehData }
  />;
};

export default DrillingMachine;