import moment from 'moment';
import { Form, InputNumber } from 'antd';
import { toLower } from 'lodash';

import { EMPTY, UNIT } from 'constant';
import { generateFormat } from 'utils/PeriodUtility';
import {
  formatWithEnLocale,
  parseFromEnLocale,
  preventNonNumber
} from 'utils/CurrencyFormatter';


const topHeaderColumns = [
  { dataIndex: 'sr_by_equipment', title: 'SR by Equipment' },
  { dataIndex: 'unit', title: 'Unit' }
];

const headerColumns = [
  { dataIndex: 'description', title: 'Description' },
  { dataIndex: 'unit', title: 'Unit' }
];

const coalExposeColumns = [
  { 
    dataIndex: 'description',
    title: 'Description',
    sorter: true,
  },
  { dataIndex: 'unit', title: 'Unit', sorter: true }
];

const footerColumns = [
  { dataIndex: 'coal_quality', title: 'Coal Quality' },
  { dataIndex: 'unit', title: 'Unit' }
];

const headerDefaultDataOb = [
  { codeKey: 'overburden', sr_by_equipment: 'Over Burden', unit: 'BCM' }
];

const headerDefaultDataCoal = [
  { codeKey: 'coal', sr_by_equipment: 'Coal', unit: 'Ton' }
];

const generateTopHeaderColumns = (unit, periodName, periods) => {
  const loweredUnit = toLower(unit);
  const isWeekly = toLower(periodName) === 'weekly';
  const isMonthly = toLower(periodName) === 'monthly';
  const momentPeriod = (item) => moment(item, generateFormat(periods, loweredUnit, true, false));

  const generateDataIndex = (item) => momentPeriod(item)
    .format(generateFormat(periods, loweredUnit, false, true));

  const generateTitle = (item) => momentPeriod(item)
    .format(generateFormat(periods, loweredUnit, false, false));

  if (periods && periods.length) {
    if (isWeekly || isMonthly) {
      return [
        ...topHeaderColumns,
        {
          dataIndex: isWeekly
            ? 'week'
            : moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY'),
          title: isWeekly
            ? 'Week'
            : moment(periods[0], 'DD MMM YYYY').format('MMM YYYY'),
          render: (text) => (
            <span>
              {
                text
                  ? Number(text.toFixed(1)).toLocaleString('en-US')
                  : text
              }
            </span>
          )
        }
      ];
    }

    return [
      ...topHeaderColumns,
      ...periods.map((item) => {
        return {
          dataIndex: generateDataIndex(item),
          title: generateTitle(item),
          render: (text) => (
            <span>
              {
                text
                  ? Number(text.toFixed(1)).toLocaleString('en-US')
                  : text
              }
            </span>
          )
        };
      })
    ];
  }

  return topHeaderColumns;
};

const generateHeaderColumns = (type, unit, periodName, periods, isDetailMode, onChange) => {
  const loweredUnit = toLower(unit);
  const isWeekly = toLower(periodName) === 'weekly';
  const isMonthly = toLower(periodName) === 'monthly';
  const momentPeriod = (item) => moment(item, generateFormat(periods, loweredUnit, true, false));

  const generateDataIndex = (item) => momentPeriod(item)
    .format(generateFormat(periods, loweredUnit, false, true));

  const generateTitle = (item) => momentPeriod(item)
    .format(generateFormat(periods, loweredUnit, false, false));

  const generatePeriodFormat = (period) => {
    switch (true) {
      case isWeekly:
        return 'week';
      case isMonthly:
        return moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY');
      default:
        return momentPeriod(unit, period);
    }
  };

  if (periods && periods.length) {
    const headDef = type === 'expose' ? coalExposeColumns : type === 'footer' ? footerColumns : headerColumns;

    if (isWeekly || isMonthly) {
      return [
        ...headDef,
        {
          dataIndex: isWeekly
            ? 'week'
            : moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY'),
          title: isWeekly
            ? 'Week'
            : moment(periods[0], 'DD MMM YYYY').format('MMM YYYY'),
          render: (text, record, index) => {
            if (type !== 'footer') {
              return (
                <Form.Item
                  name={`${type}_${generatePeriodFormat(periods)}_${index + 1}`}
                  rules={[{ required: true, message: '' }]}
                  className='mb-0'
                >
                  <InputNumber
                    key={`key-${generatePeriodFormat(periods)}-${index + 1}`}
                    min={1}
                    formatter={formatWithEnLocale}
                    parser={parseFromEnLocale}
                    onKeyDown={preventNonNumber}
                    value={record[`${generatePeriodFormat(periods)}`]}
                    onChange={(ev) => onChange(ev, index, `${generatePeriodFormat(periods)}`)}
                    className="w-32"
                    disabled={isDetailMode}
                  />
                </Form.Item>
              );
            } else {
              return (
                <>{text}</>
              );
            }
          }
        }
      ];
    }

    return [
      ...headDef,
      ...periods.map((item) => {
        return {
          dataIndex: generateDataIndex(item),
          title: generateTitle(item),
          render: (text, record, index) => {
            if (type !== 'footer') {
              return (
                <Form.Item
                  name={`${type}_${generateDataIndex(item)}_${index + 1}`}
                  rules={[{ required: true, message: '' }]}
                  className='mb-0'
                >
                  <InputNumber
                    key={`key-${generateDataIndex(item)}-${index + 1}`}
                    min={1}
                    formatter={formatWithEnLocale}
                    parser={parseFromEnLocale}
                    onKeyDown={preventNonNumber}
                    value={record[generateDataIndex(item)]}
                    onChange={(ev) => onChange(ev, index, generateDataIndex(item))}
                    className="w-32"
                    disabled={isDetailMode}
                  />
                </Form.Item>
              );
            } else {
              return (
                <>{text}</>
              );
            }
          }
        };
      })
    ];
  }

  return headerColumns;
};

const generateHeaderData = (type, unit, periodName, periods, data) => {
  const loweredUnit = toLower(unit);
  const isWeekly = toLower(periodName) === 'weekly';
  const isMonthly = toLower(periodName) === 'monthly';
  const momentPeriod = (item) => moment(item, generateFormat(periods, loweredUnit, true, false));
  const headerDefaultData = type === 'coal' ? headerDefaultDataCoal : headerDefaultDataOb;

  if (data) {
    const summary = data.contents?.find((content) => content.code === 'summary');
    const sumProdProp = summary?.properties?.find((prop) => prop.code === 'sum_production');

    if (periods && periods.length) {
      return headerDefaultData.map((item) => {
        const newItem = { ...item };

        switch (true) {
          case isWeekly: {
            const propDate = sumProdProp?.properties
              ?.find((prop) =>
                moment(
                  moment(prop.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                  'YYYY-MM-DD'
                ).isSame(
                  moment(periods[0], 'DD MMM YYYY').format('YYYY-MM-DD'),
                  'day'
                )
              );

            newItem.week = propDate
              ? propDate.value
              : EMPTY.STRING;

            return newItem;
          }
          case isMonthly: {
            const propDate = sumProdProp?.properties
              ?.find((prop) =>
                moment(
                  moment(prop.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                  'YYYY-MM-DD'
                ).isSame(
                  moment(periods[0], 'DD MMM YYYY').format('YYYY-MM-DD'),
                  'day'
                )
              );

            newItem[moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY')] = propDate
              ? propDate.value
              : EMPTY.STRING;

            return newItem;
          }
          default: {
            const itemPeriod = (period) => momentPeriod(period)
              .format(generateFormat(periods, loweredUnit, false, true));

            periods.forEach((period) => {
              const propDate = sumProdProp?.properties
                ?.find((prop) =>
                  moment(
                    moment(prop.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                    'YYYY-MM-DD'
                  ).isSame(
                    momentPeriod(period).format('YYYY-MM-DD'),
                    'day'
                  )
                );

              newItem[itemPeriod(period)] = propDate
                ? propDate.value
                : EMPTY.STRING;
            });

            return newItem;
          }
        }
      });
    }

    return headerDefaultData;
  }

  return headerDefaultData;
};

const tableDataColumns = [
  {
    dataIndex: 'name',
    title: 'Equipment Name'
  },
  {
    dataIndex: 'alias_name',
    title: 'Equipment Type',
    render: (text) => {
      const splitAliasName = text.split('-');
      
      return (
        <span>
          {
            splitAliasName.length
              ? splitAliasName[0]
              : EMPTY.STRING
          }
        </span>
      );
    }
  },
  {
    dataIndex: 'alias_name',
    title: 'Equipment No',
    render: (text) => {
      const splitAliasName = text.split('-');

      return (
        <span>
          {
            splitAliasName.length
              ? splitAliasName[1]
              : EMPTY.STRING
          }
        </span>
      );
    }
  }
];

const generateTableColumns = (isInput, isPercentage, model, unit, periods, onChange) => {
  const loweredUnit = toLower(unit);
  const momentPeriod = (item) => moment(item, generateFormat(periods, loweredUnit, true, false));

  const generateDataIndex = (item) => momentPeriod(item)
    .format(generateFormat(periods, loweredUnit, false, true));

  const generateTitle = (item) => momentPeriod(item)
    .format(generateFormat(periods, loweredUnit, false, false));
  
  return [
    ...tableDataColumns,
    ...periods.map((item) => ({
      dataIndex: generateDataIndex(item),
      title: generateTitle(item),
      render: (text, record, index) => {
        switch (true) {
          case isInput:
            return (
              <Form.Item
                name={`${generateDataIndex(item)}_${model}_${index + 1}`}
                rules={[{ required: true, message: '' }]}
                className='mb-0'
              >
                <InputNumber
                  key={`key-${model}-${index + 1}`}
                  min={1}
                  formatter={formatWithEnLocale}
                  parser={parseFromEnLocale}
                  onKeyDown={preventNonNumber}
                  value={record[generateDataIndex(item)]}
                  onChange={(ev) => onChange(ev, record, generateDataIndex(item))}
                  className="w-32"
                />
              </Form.Item>
            );
          case isPercentage:
            return (
              <span>{text} %</span>
            );
          default:
            return (
              <span>{text}</span>
            );
        }
      }
    }))
  ];
};

const generateBody = (
  isDraft,
  id,
  unit,
  periodName,
  periods,
  dataSrEquipment,
  dataPitReserve,
  dataCoalExpose,
  dataFooter
) => {
  const isWeekly = toLower(periodName) === 'weekly';
  const isMonthly = toLower(periodName) === 'monthly';

  const momentPeriod = (unit, item) => {
    return moment(item, toLower(unit) === UNIT.DAY ? 'DD MMM YYYY' : 'MMM YYYY')
      .format(toLower(unit) === UNIT.DAY ? 'DD_MMM_YYYY' : 'MMM_YYYY');
  };

  const momentDatePeriod = (unit, item) => {
    return moment(item, toLower(unit) === UNIT.DAY ? 'DD MMM YYYY' : 'MMM YYYY')
      .format('DD-MM-YYYY');
  };

  const generatePeriodFormat = (period) => {
    switch (true) {
      case isWeekly:
        return 'week';
      case isMonthly:
        return moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY');
      default:
        return momentPeriod(unit, period);
    }
  };

  const generateTotal = (period) => {
    const sum = dataCoalExpose?.reduce((accumulator, currentValue) => {
      return parseFloat(accumulator) + parseFloat(currentValue[generatePeriodFormat(period)]);
    }, 0);

    return sum || 0;
  };

  const generateTotalSr = (period) => {
    const sum = dataPitReserve?.reduce((accumulator, currentValue) => {
      return parseFloat(accumulator) + parseFloat(currentValue[generatePeriodFormat(period)]);
    }, 0);

    const totalSr = sum > 0 && generateTotal(period) > 0 ? sum / generateTotal(period) : 0;

    return totalSr || 0;
  };

  const generateGrandTotalCoalQuality = (period) => {
    const sum = dataFooter?.reduce((accumulator, currentValue) => {
      return parseFloat(accumulator) + parseFloat(currentValue[generatePeriodFormat(period)]);
    }, 0);

    return sum || 0;
  };

  return {
    assumption_id: id,
    is_draft: isDraft,
    contents: [
      {
        code: 'sr_by_equipment',
        properties: dataSrEquipment.map((item) => ({
          code: item.codeKey,
          properties: periods.map((period) => ({
            type: periodName,
            date: momentDatePeriod(unit, period),
            value: item[generatePeriodFormat(period)]
          }))
        }))
      },
      {
        code: 'pit_reserve',
        properties: periods.map((period) => ({
          type: periodName,
          date: momentDatePeriod(unit, period),
          properties: [
            {
              code: 'overburden',
              value: dataPitReserve[0][generatePeriodFormat(period)]
            },
            {
              code: 'total',
              unit: 'Ton',
              value: generateTotal(period)
            },
            {
              code: 'sr',
              value: generateTotalSr(period)
            },
            {
              code: 'coal_expose',
              properties: dataCoalExpose.map((coal) => ({
                code: 'seam_details',
                value: [
                  {
                    code: 'description',
                    value: coal['description'].length === 1 ? coal['description'][0] : coal['description']
                  },
                  {
                    code: 'unit',
                    value: coal['unit']
                  },
                  {
                    code: 'coal',
                    value: coal[generatePeriodFormat(period)]
                  },
                  {
                    code: 'quality',
                    value: coal['quality']
                  }
                ]
              }))
            },
          ]
        }))
      },
      {
        code: 'coal_quality',
        properties: [
          {
            code: 'hcv',
            unit: 'ton',
            properties: periods.map((period) => ({
              type: periodName,
              date: momentDatePeriod(unit, period),
              value: dataFooter.find(v => v.coal_quality === 'HCV')[generatePeriodFormat(period)]
            }))
          },
          {
            code: 'lcv',
            unit: 'ton',
            properties: periods.map((period) => ({
              type: periodName,
              date: momentDatePeriod(unit, period),
              value: dataFooter.find(v => v.coal_quality === 'LCV')[generatePeriodFormat(period)]
            }))
          },
          {
            code: 'Unknown',
            unit: 'ton',
            properties: periods.map((period) => ({
              type: periodName,
              date: momentDatePeriod(unit, period),
              value: dataFooter.find(v => v.coal_quality === 'Unknown')[generatePeriodFormat(period)]
            }))
          },
          {
            code: 'grand_total',
            unit: 'ton',
            properties: periods.map((period) => ({
              type: periodName,
              date: momentDatePeriod(unit, period),
              value: generateGrandTotalCoalQuality(period)
            }))
          },
        ]
      }
    ]
  };
};

export {
  generateTopHeaderColumns,
  generateHeaderColumns,
  generateHeaderData,
  generateTableColumns,
  generateBody,
};