import { useModalConfirmationContext } from 'components/ModalConfirmationProvider';
import { generateSampleInformationTopoCollarTable } from 'constant/TopoColarValidation/TableSampleInformation';
import TableDrillingSumarry from 'pages/DrillingPlan/DrillingSummary/TableDrillingSummary';

const SampleInformationTopoCollarValidation = (props) => {
  const { isDetailMode, payload, drillHoleNameProperties, onChange, removeRowById } = props;
  const {showModal, resetModal} = useModalConfirmationContext();
  const columns = generateSampleInformationTopoCollarTable(
    isDetailMode,
    payload,
    drillHoleNameProperties,
    onChange,
    removeRowById,
    showModal,
    resetModal
  );
  return (
    <TableDrillingSumarry columns={columns} tableData={payload} />
  );
};

export default SampleInformationTopoCollarValidation;
