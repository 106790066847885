import { Dropdown } from 'antd';
import React from 'react';

import {
  AiFillCaretLeft,
  AiFillCaretRight,
  AiOutlineBars,
} from 'react-icons/ai';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { GoPlus } from 'react-icons/go';
import { FaCheck } from 'react-icons/fa6';
const CategoriesList = (props) => {
  const {
    categories,
    selectedCategory,
    setSelectedCategory,
    isActual,
    onAdd = () => {},
    isDetailMode
  } = props;
  const categoryLabel = (category) => {
    return (
      <div onClick={() => setSelectedCategory(category)} className='flex flex-row items-center gap-2'>
        {selectedCategory === category ? <FaCheck size={12} color='#2D3D5A' />:<div className='w-[11px]' />} 
        <div className='text-[12px]'>{category}</div>
      </div>
    );
  };
  const LeftArrow = () => {
    const { isFirstItemVisible, scrollPrev } =
      React.useContext(VisibilityContext);
    return (
      <div className="h-full flex flex-wrap items-center mx-3">
        <AiFillCaretLeft
          size={25}
          disabled={isFirstItemVisible}
          onClick={() => scrollPrev()}
          className="cursor-pointer"
          color="white"
        />
      </div>
    );
  };
  const RightArrow = () => {
    const { isLastItemVisible, scrollNext } =
      React.useContext(VisibilityContext);
    return (
      <div className="h-full flex flex-wrap items-center mx-3">
        <AiFillCaretRight
          size={25}
          disabled={isLastItemVisible}
          onClick={() => scrollNext()}
          className="cursor-pointer"
          color="white"
        />
      </div>
    );
  };
  const Menu = () =>
    categories.map((category, index) => {
      return (
        <div
          onClick={() => setSelectedCategory(category)}
          className={`${
            selectedCategory === category
              ? 'bg-[#D7DFEE] text-black font-bold font-[Segoe-UI-Bold] '
              : 'text-white'
          } cursor-pointer p-3 border w-fit text-xs whitespace-nowrap rounded-md  border-[#D7DFEE]`}
          key={index}
        >
          {category}
        </div>
      );
    });
  return (
    <div className="overflow-x-hidden">
      <div className="flex flex-row bg-[#2D3D5A] items-center px-6 py-4 gap-4">
        {isActual && !isDetailMode ? (
          <GoPlus
            size={28}
            color={'green'}
            className={'rounded-full p-1 cursor-pointer bg-green-200'}
            onClick={onAdd}
          /> 
        ): (
          <div className='w-[28px]' />
        )}
        <Dropdown
          menu={{
            items: categories.map((category, index) => ({
              key: index,
              label: categoryLabel(category),
            })),
          }}
          placement="topRight"
          trigger={['click']}
          arrow
        >
          <div className="rounded-full bg-white p-2 cursor-pointer">
            <AiOutlineBars size={15} />
          </div>
        </Dropdown>

        <div className="w-full">
          <ScrollMenu
            wrapperClassName="w-fit max-w-[65vw]"
            itemClassName="px-2 items-center"
            scrollContainerClassName=" flex items-center overflow-hidden px-2"
            transitionBehavior={'smooth'}
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
          >
            {Menu()}
          </ScrollMenu>
        </div>
      </div>
    </div>
  );
};

export default CategoriesList;
