import { Button, DatePicker, Form } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useLazyGetMasterDataOmeMaintenanceParameterQuery } from 'api/MasterData';
import {
  useLazyGetPlanStopByIdQuery,
  usePostPlanStopMutation,
  usePutPlanStopMutation,
} from 'api/PlanStop';
import SelectAccent from 'components/SelectAccent';
import Toast from 'components/Toast';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { transformError } from 'utils/ErrorTransformer';

const FormPlanStop = ({ isEdit, planStopId, onSubmit }) => {
  const [form] = Form.useForm();
  const [planStopSubType, setplanStopSubType] = useState([]);

  const [
    getPlanStopParameters,
    {
      data: planStopParamsData,
      isFetching: planStopParamsIsFetching,
      error: planStopParamsError,
      isError: planStopParamsIsError,
    },
  ] = useLazyGetMasterDataOmeMaintenanceParameterQuery();

  const [
    getPlanStopDetailParameters,
    { error: planStopDetailParamsError, isError: planStopDetailParamsIsError },
  ] = useLazyGetMasterDataOmeMaintenanceParameterQuery();

  const [
    postPlanStop,
    {
      isError: postPlanStopIsError,
      error: postPlanStopError,
      isSuccess: postPlanStopIsSuccess,
    },
  ] = usePostPlanStopMutation();

  const [
    getPlanStopById,
    {
      data: planStopData,
      isError: getPlanStopIsError,
      error: getPlanStopError,
    },
  ] = useLazyGetPlanStopByIdQuery();

  const [
    putPlanStop,
    {
      isError: putPlanStopIsError,
      error: putPlanStopError,
      isSuccess: putPlanStopIsSuccess,
    },
  ] = usePutPlanStopMutation();

  const fetchPlanStopDetail = async (val) => {
    const data = await getPlanStopDetailParameters({
      subType: val,
    }).unwrap();

    setplanStopSubType(data);
  };

  const onFinish = (values) => {
    const body = {
      plan_stop: values.plan_stop,
      detailed_plan_stop: values.detailed_plan_stop,
      remarks: values.remarks,
      date: moment(new Date(values.date)).format('DD-MM-YYYY'),
      time_start: moment(new Date(values.time_start)).format('HH:mm'),
      time_end: moment(new Date(values.time_end)).format('HH:mm'),
    };

    if (isEdit) {
      putPlanStop({ id: planStopId, body });
    } else {
      postPlanStop(body);
    }
  };

  useEffect(() => {
    getPlanStopParameters({
      type: '1. Plan stop',
    });
  }, []);

  useEffect(() => {
    if (isEdit) {
      getPlanStopById({ id: planStopId });
    }
  }, [planStopId, isEdit]);

  useEffect(() => {
    if (!isEmpty(planStopData)) {
      form.setFieldsValue({
        plan_stop: planStopData?.plan_stop,
        detailed_plan_stop: planStopData?.detailed_plan_stop,
        remarks: planStopData?.remarks,
        date: dayjs(planStopData?.date),
        time_start: dayjs(planStopData?.time_start, 'HH:mm:ss'),
        time_end: dayjs(planStopData?.time_end, 'HH:mm:ss'),
      });
    }
  }, [planStopData]);

  useEffect(() => {
    if (planStopParamsIsError) {
      toast.error(transformError(planStopParamsError).message, {
        toastId: 'plan-stop-params-error-toast',
      });
    }
    if (planStopDetailParamsIsError) {
      toast.error(transformError(planStopDetailParamsError).message, {
        toastId: 'plan-stop-detail-params-error-toast',
      });
    }
    if (getPlanStopIsError) {
      toast.error(transformError(getPlanStopError).message, {
        toastId: 'get-plan-stop-detail-error-toast',
      });
    }
    if (postPlanStopIsError) {
      toast.error(transformError(postPlanStopError).message, {
        toastId: 'post-plan-stop-error-toast',
      });
    }
    if (putPlanStopIsError) {
      toast.error(transformError(putPlanStopError).message, {
        toastId: 'put-plan-stop-error-toast',
      });
    }
    if (postPlanStopIsSuccess) {
      toast.success(
        <Toast
          message={'SUCCESS'}
          detailedMessage={'Successfuly create new data.'}
        />,
        {
          toastId: 'post-plan-stop-success-toast',
        }
      );
      onSubmit();
    }
    if (putPlanStopIsSuccess) {
      toast.success(
        <Toast
          message={'SUCCESS'}
          detailedMessage={'Successfuly update the data.'}
        />,
        {
          toastId: 'put-plan-stop-success-toast',
        }
      );
      onSubmit();
    }
  }, [
    planStopParamsIsError,
    planStopDetailParamsIsError,
    getPlanStopIsError,
    postPlanStopIsError,
    putPlanStopIsError,
    postPlanStopIsSuccess,
    putPlanStopIsSuccess,
  ]);

  return (
    <Form
      name="basic"
      form={form}
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      style={{
        maxWidth: 600,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      autoComplete="off"
      labelAlign="left"
      colon={false}
      requiredMark={false}
    >
      <Form.Item
        name="date"
        label="Date"
        rules={[{ required: true, message: 'Please input section' }]}
      >
        <DatePicker format="DD-MM-YYYY" allowClear className="w-full" />
      </Form.Item>
      <Form.Item
        name="time_start"
        label="Time Start"
        rules={[{ required: true, message: 'Please input section' }]}
      >
        <DatePicker.TimePicker format="HH:mm" allowClear className="w-full" />
      </Form.Item>
      <Form.Item
        name="time_end"
        label="Time End"
        rules={[{ required: true, message: 'Please input section' }]}
      >
        <DatePicker.TimePicker format="HH:mm" allowClear className="w-full" />
      </Form.Item>
      <Form.Item
        name="plan_stop"
        label="Plan Stop"
        rules={[
          {
            required: true,
            message: 'Please input section',
          },
        ]}
      >
        <SelectAccent
          allowClear
          placeholder="Select section"
          isStringify={false}
          options={planStopParamsData}
          onChange={fetchPlanStopDetail}
          isLoading={planStopParamsIsFetching}
        />
      </Form.Item>
      <Form.Item
        name="detailed_plan_stop"
        label="Detail"
        rules={[
          {
            required: true,
            message: 'Please input section',
          },
        ]}
      >
        <SelectAccent
          allowClear
          placeholder="Select section"
          labelKey="detail_loss"
          valueKey="detail_loss"
          options={planStopSubType}
        />
      </Form.Item>
      <Form.Item
        name="remarks"
        label="Remark"
        rules={[
          {
            required: false,
            message: 'Please input section',
          },
        ]}
      >
        <TextArea
          placeholder="Enter remarks"
          autoSize={{ minRows: 2, maxRows: 6 }}
        />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit" block>
          {isEdit ? 'Update' : 'Submit'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FormPlanStop;
