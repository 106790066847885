const { Base } = require('./Base');

const DrillingSummary = Base.injectEndpoints({
  endpoints: (build) => ({
    uploadExcel: build.mutation({
      query: ({body, plan_id}) => ({
        url: `/goe/drillings/plans/${plan_id}/summary/upload-excel`,
        method: 'POST',
        body,
        // 10 minutes of upload timeout
        timeout: Number(10 * (60 * 1000))
      })
    }),
    uploadActualExcel: build.mutation({
      query: ({body, plan_id, params}) => ({
        url: `/goe/drillings/actuals/${plan_id}/summary/upload-excel`,
        method: 'POST',
        body,
        ...params
          ? { params }
          : undefined,
        // 10 minutes of upload timeout
        timeout: Number(10 * (60 * 1000))
      })
    }),
    uploadEvidence: build.mutation({
      query: ({body, plan_id}) => ({
        url: `/goe/drillings/actuals/${plan_id}/summary/upload-evidences`,
        method: 'POST',
        body,
        // 10 minutes of upload timeout
        timeout: Number(10 * (60 * 1000))
      })
    }),
    postSummary: build.mutation({
      query: ({ id, body }) => ({
        url: `/goe/drillings/plans/${id}/summary`,
        method: 'POST',
        body
      })
    }),
    postActualSummary: build.mutation({
      query: ({ id, body }) => ({
        url: `/goe/drillings/actuals/${id}/summary`,
        method: 'POST',
        body
      })
    }),
    getDetailDrillingSummaryById: build.query({
      query: ({drillingPlanId, version}) => ({
        url: `/goe/drillings/plans/${drillingPlanId}/summary?version=${version}`,
        method: 'GET'
      })
    }),
    getDrillHoleNameList: build.query({
      query: ({drillingPlanId, version, params}) => ({
        url: `/goe/drillings/plans/${drillingPlanId}/summary/drill-holes?version=${version}`,
        method: 'GET',
        ...params
          ? {params}
          : undefined
      })
    }),
    getListPlanName: build.query({
      query: ({ params }) => ({
        url: '/goe/drillings/plans/summary',
        method: 'GET',
        ...params
          ? { params }
          : undefined
      })
    }),
    getDrillingSummaryActualList: build.query({
      query: (params) => ({
        url: '/goe/drillings/actuals/summary',
        method:'GET',
        ...params
          ? {params}
          : undefined

      })
    }),
    getDetailDrillingSummaryActualById: build.query({
      query: ({id}) => ({
        url: `/goe/drillings/actuals/summary/${id}`,
        method:'GET',
      })
    }),
    exportSummaryActualExcel: build.query({
      query:({id, company, year, drillingStage})=>({
        url:`/goe/drillings/actuals/summary/${id}/export-excel`,
        method: 'GET',
        responseHandler: async (response) => {
          const docLink = document.createElement('a');
          docLink.href = window.URL.createObjectURL(await response.blob());
          docLink.target = '_blank';
          docLink.download = `actual_drilling_summary_${year}_${company}_${drillingStage}.xlsx`;
          docLink.click();
        },
        cache: 'no-cache',
      })
    })
  })
});

export const { 
  useUploadExcelMutation, 
  useUploadActualExcelMutation,
  usePostSummaryMutation,
  useLazyGetDetailDrillingSummaryByIdQuery,
  useLazyGetDrillHoleNameListQuery,
  useLazyGetListPlanNameQuery,
  useGetDrillingSummaryActualListQuery,
  useLazyGetDetailDrillingSummaryActualByIdQuery,
  usePostActualSummaryMutation,
  useUploadEvidenceMutation,
  useLazyExportSummaryActualExcelQuery
} = DrillingSummary;



