import { DatePicker, Form } from 'antd';
import { useGetMasterDataGoeCompaniesQuery } from 'api/MasterData';
import ButtonAccent from 'components/ButtonAccent';
import SelectSearchAccent from 'components/SelectSearchAccent';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { transformError } from 'utils/ErrorTransformer';
import { getFullPath } from 'utils/PathUtility';
import { createSearchParams } from 'react-router-dom';
import { useModalConfirmationContext } from 'components/ModalConfirmationProvider';
import PreventNavigation from 'constant/PreventNavigation';
import { useValidateReconciliationMutation } from 'api/Reconciliation';
import { ACTION } from 'constant';
import ModalInformation from 'pages/DrillingPlan/components/ModalInformation';

const ReconciliationForm = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [informationModal, setInformationModal] = useState(false);
  const {showModal, resetModal} = useModalConfirmationContext();

  const [
    validatePlan,
    {
      isLoading: validatePlanIsLoading,
      isError: validatePlanIsError,
      error: validatePlanError,
      isSuccess: validatePlanIsSuccess
    },
  ] = useValidateReconciliationMutation();

  const { data: dataCompanies, isFetching: companiesIsFetching } =
    useGetMasterDataGoeCompaniesQuery({ refetchOnMountOrArgChange: true });

  const onFinish = (values) => {
    const company = dataCompanies.find((val) => val.id === values.company);
    const payload = {
      year: moment(new Date(values.year)).format('YYYY'),
      company: {
        id: company.id,
        name: company.name,
        alias_name: company.alias_name,
      }
    };
    validatePlan(payload);
  };

  const handleBackButton = () => {
    showModal({
      isShown: true,
      type: 'confirmation',
      title:'Are you sure want to leave this page?',
      message: 'Changes you made may not be saved',
      onSubmit: () => {
        navigate(-1);
        resetModal();
      }
    });
  };

  useEffect(() => {
    if (validatePlanIsError) {
      setInformationModal(true);
      return;
    }
  }, [validatePlanIsError]);

  useEffect(() => {
    if (validatePlanIsSuccess) {
      goToPath(null, ACTION.CREATE, 'input-actual');
    }
  }, [validatePlanIsSuccess]);

  const populateState = () => {
    let company = dataCompanies.find((val) => val.id === form.getFieldValue('company'));
    let year = moment(new Date(form.getFieldValue('year'))).format('YYYY');
    return {company:company, year};
  };

  const goToPath = (id, type, child)=> navigate({
    pathname: getFullPath({
      parent: 'reconciliation',
      child: child,
      type
    },'geology-oe'),
    ...(id)
      ? { search: createSearchParams({ id }).toString() }
      : undefined
  },{ state: { ...populateState()  } });

  const handleCloseSite = useCallback((ev) => ev.preventDefault() ,[]);
  
  useEffect(() => {
    PreventNavigation(false, handleCloseSite);
  }, []);

  return (
    <div>
      <Form form={form} onFinish={onFinish}>
        <div className="flex grid-cols-3 bg-white p-6 gap-6 rounded-2xl items-center justify-center">
          <label className="w-full">
            <span className="font-bold">Year</span>
            <Form.Item
              name="year"
              rules={[
                {
                  required: true,
                  message: 'Please input Year',
                },
              ]}
            >
              <DatePicker.YearPicker
                allowClear
                disabledDate={(current) => current.isBefore(moment())}
                className="mt-2 w-full h-12"
              />
            </Form.Item>
          </label>
          <label className="w-full">
            <span className="font-bold">Mine Site</span>
            <Form.Item
              name="company"
              rules={
                [
                  {
                    required: true,
                    message: 'Please input Mine Site',
                  },
                ]
              }
            >
              <SelectSearchAccent
                labelKey="alias_name"
                valueKey="id"
                options={dataCompanies}
                placeholder="Select mine site"
                isLoading={companiesIsFetching}
                className={'h-12 mt-2'}
              />
            </Form.Item>
          </label>
        </div>
        <div className="flex flex-row justify-between mt-16">
          <ButtonAccent
            isBordered
            title="Cancel"
            isLoading={validatePlanIsLoading}
            onClick={handleBackButton}
          />
          <ButtonAccent
            title="Next"
            isLoading={validatePlanIsLoading}
            htmlType="submit"
          />
        </div>
      </Form>
      <ModalInformation
        isModalOpen={informationModal}
        setIsModalOpen={setInformationModal}
        errorMessage={`${transformError(validatePlanError).message}`}
      />
    </div>
  );
};

export default ReconciliationForm;
