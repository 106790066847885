import { DatePicker, Divider, Drawer, Form, Spin } from 'antd';
import { useGetMasterDataGoeCompaniesQuery } from 'api/MasterData';
import { useDeletePlanStopMutation, useGetPlanStopsQuery } from 'api/PlanStop';
import ButtonAccent from 'components/ButtonAccent';
import { useModalConfirmationContext } from 'components/ModalConfirmationProvider';
import SelectAccent from 'components/SelectAccent';
import TableData from 'components/TableData';
import Toast from 'components/Toast';
import { DEFAULT, EMPTY } from 'constant';
import { tablePlanStopColumns } from 'constant/Maintenance/TablePlanStop';
import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { transformError } from 'utils/ErrorTransformer';
import FormPlanStop from './Form';

const MaintenancePlanStop = () => {
  const initialFilter = {
    start_year: EMPTY.STRING,
    end_year: EMPTY.STRING,
    company: EMPTY.STRING,
  };

  const loc = useLocation();
  const { showModal, resetModal } = useModalConfirmationContext();
  const [filter, setFilter] = useState(initialFilter);
  const [isModalShown, setIsModalShown] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [planStopId, setPlanStopId] = useState('');
  const [loading, setLoading] = useState(false);
  const { page, pageSize, sortBy, orderBy, search } = useSelector(
    (state) => state.table
  );
  const [formFilter] = Form.useForm();

  const {
    data: planStops,
    refetch: planStopsRefetch,
    isFetching: planStopsIsFetching,
    isError: planStopsIsError,
    error: planStopsError,
  } = useGetPlanStopsQuery(
    {
      page,
      limit: pageSize,
      sort_by: sortBy,
      order_by: orderBy,
      ...filter,
      ...(search ? { search } : undefined),
    },
    { refetchOnMountOrArgChange: true }
  );

  const [
    deletePlanStop,
    {
      isLoading: deletePlanStopIsLoading,
      isError: deletePlanStopIsError,
      error: deletePlanStopError,
      isSuccess: deletePlanStopIsSuccess,
    },
  ] = useDeletePlanStopMutation();

  const {
    data: masterDataCompanies,
    isFetching: masterDataCompaniesIsFetching,
  } = useGetMasterDataGoeCompaniesQuery({ refetchOnMountOrArgChange: true });

  const calculatePixel = (remVal) => {
    return `${window.innerWidth - remVal * 16}px`;
  };

  const onSetModal = () => {
    if (isModalShown) {
      setIsEdit(false);
    } else {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
    setIsModalShown(!isModalShown);
  };

  const onEditModal = (id) => {
    setIsEdit(true);
    setIsModalShown(!isModalShown);
    setPlanStopId(id);
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const onSubmitForm = () => {
    onSetModal();
    setTimeout(() => {
      planStopsRefetch();
    }, 1000);
  };

  const onDeleteModal = (id) => {
    showModal({
      isShown: true,
      type: 'delete',
      message: 'Are you sure you want to delete ?',
      onSubmit: () => {
        deletePlanStop({ id });
        resetModal();
        setTimeout(() => {
          planStopsRefetch();
          if (deletePlanStopIsError) {
            toast.error(deletePlanStopError, {
              toastId: 'delete-plan-stop-error-toast',
            });
          } else {
            toast.success('Data Deleted', {
              toastId: 'delete-plan-stop-success-toast',
            });
          }
        }, 1000);
      },
    });
  };

  const onFilter = (val, key) => {
    if (!val && filter[key]) {
      setFilter((prevState) => ({
        ...prevState,
        [key]: EMPTY.STRING,
      }));
    }
  };

  const submitFilter = (values) => {
    setFilter({
      start_year: values.start_year
        ? moment(new Date(values.start_year)).format('YYYY')
        : EMPTY.STRING,
      end_year: values.end_year
        ? moment(new Date(values.end_year)).format('YYYY')
        : EMPTY.STRING,
      company: values.company,
    });
  };

  useEffect(() => {
    if (planStopsIsError) {
      const errorMsg = planStopsError;

      toast.error(
        <Toast
          message={'ERROR'}
          detailedMessage={`${transformError(errorMsg).message}`}
        />
      );
      return;
    }
  }, [planStopsIsError]);

  return (
    <div className="mt-5 bg-white p-6 rounded-2xl">
      <Drawer
        destroyOnClose
        title={`${isEdit ? 'Edit Record' : 'Add New Record'} Plan Stop`}
        placement={isEdit ? 'right' : 'left'}
        onClose={onSetModal}
        open={isModalShown}
        loading={loading}
        maskClosable={false}
        key={isEdit ? 'right' : 'left'}
        width={'35vw'}
        styles={{
          headerStyle: { backgroundColor: '#EEE9FB' },
          bodyStyle: { backgroundColor: '#EEE9FB' },
        }}
      >
        {loading ? (
          <div className="w-full text-center">
            <Spin className="m-auto" />{' '}
          </div>
        ) : (
          <FormPlanStop
            isEdit={isEdit}
            planStopId={planStopId}
            onSubmit={onSubmitForm}
          />
        )}
      </Drawer>
      <Form form={formFilter} onFinish={submitFilter}>
        <div className="grid-cols-2 flex gap-4 p-4 bg-[#B1BACB20] rounded-lg">
          <div className="w-full">
            <span className="font-bold">Company</span>
            <Form.Item name="company">
              <SelectAccent
                allowClear
                className="mt-2 w-full h-10"
                placeholder="Select company"
                labelKey="alias_name"
                valueKey="alias_name"
                isStringify={false}
                options={masterDataCompanies}
                isLoading={masterDataCompaniesIsFetching}
                onChange={(val) => onFilter(val, 'company')}
              />
            </Form.Item>
          </div>
          <div className="w-full">
            <span className="font-bold">Start Year</span>
            <Form.Item name="start_year">
              <DatePicker.YearPicker
                allowClear
                className="mt-2 w-full h-10"
                onChange={(val) => onFilter(val, 'start_year')}
              />
            </Form.Item>
          </div>
          <div className="w-full">
            <span className="font-bold">End Year</span>
            <Form.Item name="end_year">
              <DatePicker.YearPicker
                allowClear
                className="mt-2 w-full h-10"
                onChange={(val) => onFilter(val, 'end_year')}
              />
            </Form.Item>
          </div>
          <div className="w-1/5 flex flex-row justify-center items-center gap-2 mt-1.5">
            <ButtonAccent title="View Report" htmlType="submit" />
          </div>
        </div>
      </Form>
      <Divider />
      <div
        className="mt-5 overflow-x-auto"
        style={{ maxWidth: calculatePixel(23) }}
      >
        <TableData
          onS
          scrollWidth="max-content"
          rowKey="id"
          isTransparent
          dataSource={planStops?.data}
          totalData={planStops?.total_data}
          isLoading={planStopsIsFetching}
          columns={tablePlanStopColumns(
            loc.pathname,
            sortBy,
            orderBy === DEFAULT.KEY_SORT_ASC
              ? DEFAULT.SORT_ASC
              : DEFAULT.SORT_DESC,
            onEditModal,
            onDeleteModal
          )}
        >
          {<ButtonAccent title="New Record" onClick={onSetModal} />}
        </TableData>
      </div>
    </div>
  );
};

export default MaintenancePlanStop;
