import { RxDashboard, RxDotFilled } from 'react-icons/rx';
import {
  HiOutlineDocumentText,
  HiOutlineDocumentSearch,
  HiOutlineUser
} from 'react-icons/hi';
import { BsGear } from 'react-icons/bs';
import { Image } from 'antd';

const mainMenu = [
  {
    id: 1,
    name: 'ITM Level',
    url: '/itm-level',
    isOpen: null,
    icon: () => <Image
      preview={false}
      src={`${process.env.PUBLIC_URL}/assets/logo-side-itm.svg`}
    />,
    children: []
  },
  {
    id: 2,
    name: 'Mine Site Level',
    url: '/mine-site-level',
    isOpen: null,
    icon: () => <Image
      preview={false}
      src={`${process.env.PUBLIC_URL}/assets/logo-side-mine-site-level.svg`}
    />,
    children: []
  },
  {
    id: 3,
    name: 'Activity Level',
    url: '/activity-level',
    isOpen: null,
    icon: () => <Image
      preview={false}
      src={`${process.env.PUBLIC_URL}/assets/logo-side-activity-level.svg`}
    />,
    children: []
  },
  {
    id: 4,
    name: 'OE Assessment',
    url: '/oe-assessment',
    isOpen: null,
    icon: () => <Image
      preview={false}
      src={`${process.env.PUBLIC_URL}/assets/logo-side-oe-assessment.svg`}
    />,
    children: []
  },
  {
    path: 5,
    name: 'Executive Summary',
    url: '/executive-summary',
    isOpen: null,
    icon: () => <Image
      preview={false}
      src={`${process.env.PUBLIC_URL}/assets/logo-side-executive-summary.svg`}
    />,
    children: []
  }
];

const menuMpoe = [
  {
    id: 1,
    name: 'Dashboard',
    url: '/activity-level/mine-planning-oe/dashboard',
    isOpen: null,
    icon: (isActive) => <RxDashboard
      size="24px"
      className={isActive ? 'text-white' : 'text-white'}
    />,
    children: []
  },
  {
    id: 5,
    name: 'User Management',
    url: '/activity-level/mine-planning-oe/user-management',
    isOpen: null,
    icon: (isActive) => <HiOutlineUser
      size="24px"
      className={isActive ? 'text-white' : 'text-white'}
    />,
    children: []
  },
  {
    id: 2,
    name: 'Assumption',
    url: '/activity-level/mine-planning-oe/assumption',
    isOpen: null,
    icon: (isActive) => <HiOutlineDocumentSearch
      size="24px"
      className={isActive ? 'text-white' : 'text-white'}
    />,
    children: []
  },
  {
    id: 3,
    name: 'Profile',
    url: '/activity-level/mine-planning-oe/profile',
    isOpen: null,
    icon: (isActive) => <HiOutlineUser
      size="24px"
      className={isActive ? 'text-white' : 'text-white'}
    />,
    children: []
  },
  {
    path: 4,
    name: 'Confident Level',
    url: '/activity-level/mine-planning-oe/confident-level',
    isOpen: null,
    icon: (isActive) => <HiOutlineDocumentText
      size="24px"
      className={isActive ? 'text-white' : 'text-white'}
    />,
    children: []
  },
  {
    path: 6,
    name: 'Admin',
    url: null,
    isOpen: false,
    icon: (isActive) => <BsGear
      size="24px"
      className={isActive ? 'text-white' : 'text-white'}
    />,
    children: [
      {
        path: 61,
        name: 'Manage Group',
        url: '/activity-level/mine-planning-oe/group-management',
        icon: null
      },
      {
        path: 62,
        name: 'Logs',
        url: '/activity-level/mine-planning-oe/logs',
        icon: null
      }
    ]
  }
];

const menuGeology = [
  {
    path: 1,
    name: 'Drilling',
    url: null,
    isOpen: false,
    icon: () => <Image
      preview={false}
      src={`${process.env.PUBLIC_URL}/assets/logo-side-drilling.svg`}
    />,
    children: [
      {
        path: 11,
        name: 'Input Drilling Plan Data',
        url: '/activity-level/geology-oe/drilling-plan',
        icon: null
      },
      {
        path: 12,
        name: 'Input Actual Drilling Data',
        url: null,
        icon: null,
        children: [
          {
            path: 111,
            name: 'Summary Drilling',
            url: '/activity-level/geology-oe/summary-drilling',
            icon: null
          },
          {
            path: 112,
            name: 'Core Recovery of Coal',
            url: '/activity-level/geology-oe/core-recovery-of-coal',
            icon: null
          },
          {
            path: 113,
            name: 'Topo vs Collar Validation',
            url: '/activity-level/geology-oe/topo-collar-validation',
            icon: null
          },
          {
            path: 114,
            name: 'Model vs Drill Hole Validation',
            url: '/activity-level/geology-oe/model-drill-hole-validation',
            icon: null
          },
        ]
      }
    ]
  },
  {
    id: 2,
    name: 'Reconciliation',
    url: '/activity-level/geology-oe/reconciliation',
    isOpen: null,
    icon: () => <Image
      preview={false}
      src={`${process.env.PUBLIC_URL}/assets/logo-side-reconciliation.svg`}
    />,
    children: []
  },
  {
    id: 2,
    name: 'Key Highlight & Improvement',
    url: '/activity-level/geology-oe/key-highlight-improvement',
    isOpen: null,
    icon: () => <Image
      preview={false}
      width={24}
      src={`${process.env.PUBLIC_URL}/assets/logo-goe-dashboard.svg`}
    />,
    children: []
  },
];

const menuGeologyDashboard = [
  {
    path: 1,
    name: 'Geology Dashboard',
    url: null,
    isOpen: false,
    icon: () => <Image
      preview={false}
      width={24}
      src={`${process.env.PUBLIC_URL}/assets/logo-goe-dashboard.svg`}
    />,
    children: [
      {
        path: 11,
        name: 'OME',
        url: '/activity-level/geology-dashboard',
        icon: null
      },
      {
        path: 12,
        name: 'GOE Measurement',
        url: null,
        icon: null,
      },
      {
        path: 13,
        name: 'Overall Drilling Progress & Timeline',
        url: null,
        icon: null,
      },
      {
        path: 14,
        name: 'Performance Drilling',
        url: null,
        icon: null,
      },
      {
        path: 15,
        name: 'Drill Hole Database',
        url: null,
        icon: null,
      },
      {
        path: 16,
        name: 'Reconciliation',
        url: null,
        icon: null,
      },
    ]
  },
];

const menuMaintenance = [
  {
    id: 1,
    name: 'Plan Stop',
    url: '/activity-level/maintenance-oe/plan-stop',
    isOpen: null,
    icon: (isActive) => <RxDotFilled
      size="24px"
      className={isActive ? 'text-white' : 'text-white'}
    />,
    children: []
  },
  {
    id: 2,
    name: 'OME AP',
    url: '/activity-level/maintenance-oe/ome-ap',
    isOpen: null,
    icon: (isActive) => <RxDotFilled
      size="24px"
      className={isActive ? 'text-white' : 'text-white'}
    />,
    children: []
  },
  {
    id: 3,
    name: 'OME Q',
    url: '/activity-level/maintenance-oe/ome-q',
    isOpen: null,
    icon: (isActive) => <RxDotFilled
      size="24px"
      className={isActive ? 'text-white' : 'text-white'}
    />,
    children: []
  },
  {
    id: 4,
    name: 'OE',
    url: '/activity-level/maintenance-oe/oe',
    isOpen: null,
    icon: (isActive) => <RxDotFilled
      size="24px"
      className={isActive ? 'text-white' : 'text-white'}
    />,
    children: []
  },
];

const generateMenu = (
  params
) => {
  switch (true) {
    case params === 'mpoe':
      return menuMpoe;
    case params === 'geology_oe':
      return menuGeology;
    case params === 'geology_dashboard':
      return menuGeologyDashboard;
    case params === 'maintenance_oe':
      return menuMaintenance;
    case params === 'ome_ap':
      return menuMaintenance;
    default:
      return mainMenu;
  }
};

export {
  generateMenu
};