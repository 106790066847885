import { isEmpty, max } from 'lodash';

import { useDispatch } from 'react-redux';
import { saveState, resetState } from 'store/Assumption';

const useAssumptionManager = () => {
  const dispatch = useDispatch();
  /**
   * Do not change this data particular index
   * you can change it when assumption has new form or
   * paths should re-order
   * */
  const assumptionPaths = [
    'form',
    'production-loss-time',
    'select-over-burden-unit',
    'over-burden-hours-capacity',
    'select-coal-unit',
    'coal-hours-capacity',
    'over-burden-hauler',
    'pit-reserve',
    'main-plan-scenario',
    'summary-production'
  ];

  const createBackQueryParams = (url) => {
    const baseUrl = url.split('/');
    const currentPath = baseUrl[2];
    const maxIndex = assumptionPaths.findIndex((value) => value === currentPath);

    return assumptionPaths
      .filter((value, index) => index < maxIndex)
      .map((value, index) => (index + 1));
  };

  const isPathHasBackQueryParams = (url, backQueries) => {
    const baseUrl = url.split('/');
    const currentPath = baseUrl[2];
    const currentPathIndex = assumptionPaths.findIndex((value) => value === currentPath);

    return isEmpty(backQueries)
      ? false
      : backQueries.some((menuIndex) => Number(menuIndex) === (currentPathIndex + 1));
  };

  const isPathMaxBackQueryParams = (url, backQueries) => {
    const baseUrl = url.split('/');
    const currentPath = baseUrl[2];
    const currentPathIndex = assumptionPaths.findIndex((value) => value === currentPath);

    if (isEmpty(backQueries)) {
      return true;
    }

    const maxBackQuery = max(backQueries.map((menuIndex) => Number(menuIndex)));

    return currentPathIndex === maxBackQuery;
  };

  const saveLatestState = async (key, state) => {
    await dispatch(saveState({ key, value: state }));
  };

  const resetLatestState = async (key) => {
    await dispatch(resetState({ key }));
  };

  return {
    createBackQueryParams,
    isPathHasBackQueryParams,
    isPathMaxBackQueryParams,
    saveLatestState,
    resetLatestState
  };
};

export { useAssumptionManager };