import { useEffect, useState } from 'react';
import { DatePicker, Form, Select } from 'antd';
import { GoPlus } from 'react-icons/go';
import { useGetMasterDataGoeCompaniesQuery } from 'api/MasterData';
import ButtonAccent from 'components/ButtonAccent';
import { generateKeyHighlightIssueRequirement } from 'constant/GOEDashboard/TableKeyHighlighIssueRequirement';
import TableDrillingSumarry from 'pages/DrillingPlan/DrillingSummary/TableDrillingSummary';
import { useDeleteKeyHighlightsMutation, useLazyGetListKeyHighlightsQuery, usePostKeyHighlightsMutation } from 'api/GeologyDashboard';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { transformError } from 'utils/ErrorTransformer';
import _ from 'lodash';

const KeyHighlightIssueRequirement = () => {
  const initialData = {
    localId: 1,
    availability_key_issue: '',
    quality_key_issue: '',
    performance_key_issue: ''
  };
  const [payload, setPayload] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [form] = Form.useForm();
  const [successMessage, setSuccessMessage] = useState('');
  const [showButton, setShowButton] = useState(false);
  const [deletedId, setDeletedId] = useState(null);
  const { 
    data: dataCompanies, 
    isFetching: companiesIsFetching, 
  } = useGetMasterDataGoeCompaniesQuery({ refetchOnMountOrArgChange: true });

  const [
    postKeyHighlights,
    {
      isLoading: postKeyHighlightsIsLoading,
      isError: postKeyHighlightsIsError,
      error: postKeyHighlightsError,
      isSuccess: postKeyHighlightsIsSuccess
    },
  ] = usePostKeyHighlightsMutation();

  const [
    deleteKeyHighlights,
    {
      isLoading: deleteKeyHighlightsIsLoading,
      isError: deleteKeyHighlightsIsError,
      error: deleteKeyHighlightsError,
      isSuccess: deleteKeyHighlightsIsSuccess
    },
  ] = useDeleteKeyHighlightsMutation();

  const [
    getListKeyHighlights, 
    { 
      data: keyHighLightsData, 
      isFetching: keyHighlightIsFetching,
      error: keyHighlightError,
      isError: keyHighlightIsError,
      isSuccess: keyHighlightSuccess
    }
  ] = useLazyGetListKeyHighlightsQuery();
  
  const resetFilter = () => {
    form.setFieldValue('year', undefined);
    form.setFieldValue('mine_site', undefined);
    form.setFieldValue('month', undefined);
    form.setFieldValue('drilling_stage', undefined);
    setPayload([]);
    setIsFilterApplied(false);
  };
  
  const onChange = (index, categoryName, value) => {
    let temp = [...payload];
    temp[index][categoryName] = value;
    setPayload([...temp]);
  };

  const onAdd = () => {
    if(payload.length){
      let temp = payload;
      temp.push({ ...initialData, localId: payload[payload.length - 1 ].localId + 1 });
      setPayload([...temp]);
    }
  };

  const onDeleteRow = (record) => {
    if(record?.id) {
      deleteKeyHighlights({id: record?.id});
      setDeletedId(record?.localId);
    }else {
      deleteRowLocal(record?.localId);
    }
  };

  const deleteRowLocal = (id) => {
    let temp = payload.find((val) => val.localId === id);
    if (temp) {
      setPayload((prevState) =>
        prevState.filter((state) => state.localId !== temp.localId)
      );
    }
  };

  const onApplyFilter = (values) => {
    getListKeyHighlights({
      year: dayjs(values.year).format('YYYY'),
      month_number: dayjs(values.month).format('M'),
      minesite: values.mine_site,
      drilling_stage: values.drilling_stage,
    });
  };

  const validationData = (payload) => {
    let validation = true;
    payload?.forEach(item => {
      Object.keys(item).forEach(key => {
        if(!item[key]){
          validation = false;
        }
      });
    });
    let filter = form.getFieldsValue();
    Object.keys(filter).forEach(key => {
      if(!filter[key]) {
        validation = false;
      }
    });
    return validation;
  };

  const onSubmit = (isDraft) => {
    let tempPayload = _.cloneDeep(payload);
    const selectedCompany = dataCompanies?.find(item=> item.alias_name === form.getFieldValue('mine_site'));
    let temp = tempPayload.map(item => {
      delete item?.localId;
      if(item?.status) delete item?.status;
      return {
        year: Number(dayjs(form.getFieldValue('year')).format('YYYY')),
        month_number: Number(dayjs(form.getFieldValue('month')).format('M')),
        minesite: {id: selectedCompany?.id, name: selectedCompany?.name, alias_name: selectedCompany?.alias_name},
        drilling_stage: form.getFieldValue('drilling_stage'),
        is_draft: isDraft,
        ...item,
      };
    });
    if(isDraft || validationData(tempPayload)) {
      setSuccessMessage(isDraft ? 
        'Key Highlight & Issue Requirement successfully saved as draft'
        : 'Key Highlight & Issue Requirement successfully submitted'
      );
      postKeyHighlights(temp);
    }else {
      toast.error(
        'Fields have not filled yet. Please fill all fields before submit',
        { toastId: 'validate-data-toast-error' }
      );
    }
  };

  useEffect(() => {
    if(payload.length){
      let status = payload[0]?.status;
      setShowButton(status !== 'Submitted');
    } else {
      setShowButton(false);
    }
  },[payload]);

  useEffect(() => {
    if(keyHighlightSuccess) {
      if(keyHighLightsData?.length) {
        let tempData = _.cloneDeep(keyHighLightsData);
        setPayload(tempData.map((item, index) => ({...item, localId: index})));
      }else {
        setPayload([initialData]);
      }
      setIsFilterApplied(true);  
    }
  },[keyHighLightsData, keyHighlightIsFetching]);

  useEffect(() => {
    if(postKeyHighlightsIsSuccess) {
      toast.success(successMessage, { toastId: 'validate-data-toast-success' });
      let filter = form.getFieldsValue();
      onApplyFilter(filter);
    }
  },[postKeyHighlightsIsSuccess]);

  useEffect(() => {
    if(deleteKeyHighlightsIsSuccess) {
      toast.success('Key Highlight & Issue Requirement successfully deleted',
        { toastId: 'validate-data-toast-success' });
      deleteRowLocal(deletedId);
    }
  },[deleteKeyHighlightsIsLoading]);

  useEffect(() => {
    if (keyHighlightIsError || postKeyHighlightsIsError || deleteKeyHighlightsIsError) {
      const generateToastId = () => {
        switch (true) {
          case keyHighlightError:
            return 'get-key-highlight';
          case postKeyHighlightsError:
            return 'post-key-highlight';
          case deleteKeyHighlightsError:
            return 'delete-key-highlight';
          default:
            return 'default';
        }
      };
      
      toast.error(
        transformError(keyHighlightError || postKeyHighlightsError || deleteKeyHighlightsError ).message,
        { toastId: `${generateToastId()}-toast-error` }
      );
    }
  }, [keyHighlightIsError, postKeyHighlightsIsError, deleteKeyHighlightsIsError]);
  
  return (
    <div className="flex flex-col p-4 gap-4">
      <Form form={form} onFinish={onApplyFilter}>
        <div className={'grid-cols-4 flex gap-4 p-4 bg-[#B1BACB20] rounded-lg'}>
          <div className='w-full'>
            <span className="font-bold">Year of Period</span>
            <Form.Item
              name="year"
              rules={[
                {
                  required: true,
                  message: 'Please input Year',
                },
              ]}
            >
              <DatePicker.YearPicker 
                className="mt-2 w-full h-12" 
                disabled={isFilterApplied}
              />
            </Form.Item>
          </div>
          <div className='w-full'>
            <span className="font-bold">Month</span>
            <Form.Item
              name="month"
              rules={[
                {
                  required: true,
                  message: 'Please input Month',
                },
              ]}
            >
              <DatePicker.MonthPicker
                format={'MMMM'}
                className="mt-2 w-full h-12"
                disabled={isFilterApplied}
              />
            </Form.Item>
            
          </div>
          <div className='w-full'>
            <span className="font-bold">Mine Site</span>
            <Form.Item
              name="mine_site"
              rules={
                [
                  {
                    required: true,
                    message: 'Please input Mine Site',
                  },
                ]
              }
            >
              <Select
                placeholder="Select mine site"
                options={dataCompanies?.map(item=>({label:item?.alias_name, value: item?.alias_name}))}
                className={`mt-2 w-full h-12 ${companiesIsFetching && 'animate-pulse animate-infinite'}`}
                disabled={isFilterApplied}
              />
            </Form.Item>
          </div>
          <div className='w-full'>
            <span className="font-bold">Drilling Stage</span>
            <Form.Item
              name="drilling_stage"
              rules={
                [
                  {
                    required: true,
                    message: 'Please input Drilling Stage',
                  },
                ]
              }
            >
              <Select
                placeholder="Select drilling stage"
                options={[
                  { label: 'Pre-production', value: 'PREPRODUCTION' },
                  { label: 'Exploration', value: 'EXPLORATION' },
                ]}
                className="mt-2 w-full h-12"
                disabled={isFilterApplied}
              />
            </Form.Item>
          </div>
          <div className="w-1/5 flex flex-row justify-center items-center gap-2">
            <ButtonAccent
              title="Apply Filter"
              htmlType="submit"
              isLoading={keyHighlightIsFetching}
            />
            <ButtonAccent
              title="Reset"
              onClick={resetFilter}
              isLoading={keyHighlightIsFetching}
            />
          </div>
        </div>
      </Form>
      <TableDrillingSumarry
        columns={generateKeyHighlightIssueRequirement(onChange, payload, onDeleteRow)}
        tableData={payload} 
      />
      
      {showButton && (
        <>
          <div className="flex flex-row bg-[#2D3D5A] items-center px-6 py-4 gap-4">
            <GoPlus
              size={28}
              color={'green'}
              className={'rounded-full p-1 cursor-pointer bg-green-200'}
              onClick={onAdd} />
          </div><div className="mt-5 flex flex-row items-center">
            <ButtonAccent
              isBordered
              title="Save As Draft"
              onClick={() => onSubmit(true)}
              isLoading={postKeyHighlightsIsLoading || deleteKeyHighlightsIsLoading} />
            <div className="ml-auto flex flex-row items-center gap-x-3">
              <ButtonAccent
                isLoading={postKeyHighlightsIsLoading || deleteKeyHighlightsIsLoading}
                title="Submit"
                onClick={() => onSubmit(false)} />
            </div>
          </div>
        </>      
      )}
    </div>
  );
};

export default KeyHighlightIssueRequirement;