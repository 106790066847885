import { Base } from './Base';

const oe = Base.injectEndpoints({
  endpoints: (build) => ({
    getOes: build.query({
      query: (params) => ({
        url: '/ome/maintenance/oe',
        method: 'GET',
        ...params
          ? { params }
          : undefined
      }),
    }),
    getOeById: build.query({
      query: ({ id }) => ({
        url: `/ome/maintenance/oe/${id}`,
        method: 'GET',
      }),
    }),
    postOe: build.mutation({
      query: (body) => ({
        url: '/ome/maintenance/oe',
        method: 'POST',
        body,
      }),
    }),
    putOe: build.mutation({
      query: ({ id, body }) => ({
        url: `/ome/maintenance/oe/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteOe: build.mutation({
      query: ({ id }) => ({
        url: `/ome/maintenance/oe/${id}`,
        method: 'DELETE',
      }),
    }),
    exportOeExcel: build.query({
      query:(params)=>({
        url:'/ome/maintenance/oe/export-excel',
        method: 'GET',
        ...params
          ? { params }
          : undefined,
        responseHandler: async (response) => {
          const docLink = document.createElement('a');
          docLink.href = window.URL.createObjectURL(await response.blob());
          docLink.target = '_blank';
          docLink.download = `oe_${params.year}.xlsx`;
          docLink.click();
        },
        cache: 'no-cache',
      })
    })
  }),
});

export const {
  useLazyGetOesQuery,
  useLazyGetOeByIdQuery,
  usePostOeMutation,
  usePutOeMutation,
  useDeleteOeMutation,
  useLazyExportOeExcelQuery
} = oe;
