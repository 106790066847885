import { useNavigate } from 'react-router';
import { Button, Image } from 'antd';

import { useDispatch } from 'react-redux';
import { logout } from 'store/Auth/Thunk';

const NotFound = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logoutUser = async () => {
    try {
      await dispatch(logout());

      navigate('/login');
    } catch (err) {
      navigate('/login');
    }
  };

  return (
    <div className="p-5 w-full h-screen flex flex-col bg-[#2D3D5A]">
      <Image
        preview={false}
        width={100}
        src={`${process.env.PUBLIC_URL}/assets/logo-itm-white.svg`}
      />

      <div className="mt-24 flex flex-col items-center justify-center">
        <Image
          preview={false}
          src={`${process.env.PUBLIC_URL}/assets/icon-modal-confirmation.svg`}
        />
        <span className="mt-5 text-white text-lg text-center font-bold">
          You do not have some mandatory data (ex: company), please ask your administrator
        </span>
        <span className="text-white text-sm text-center font-bold">
          Some data is a must, because we need it for processing data
        </span>
      </div>

      <Button
        className="mt-5 w-64 bg-white text-[#2D3D5A] font-bold self-center"
        onClick={logoutUser}
      >
        Log Out
      </Button>
    </div>
  );
};

export default NotFound;