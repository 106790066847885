import { Tooltip } from 'antd';
import SelectSearchAccent from 'components/SelectSearchAccent';
import { RxCross2 } from 'react-icons/rx';

const generateSampleInformationTopoCollarTable = (
  isDetailMode, 
  payload, 
  drillHolenameProperties,
  onChange,
  removeRowById,
  showModal,
  resetModal
) => [
  {
    dataIndex: 'action',
    title: 'Action',
    width: 5,
    sorter: false,
    align: 'center',
    fixed: 'left',
    render: (_, record) => {
      return (
        <div className="flex justify-center">
          {payload.length <= 1  ? (
            <></>
          ) : !isDetailMode && (
            <Tooltip title={'Delete Row'} trigger={'hover'} >
              <RxCross2
                size={28}
                color={'red'}
                className={'rounded-full p-1 cursor-pointer bg-red-200 '}
                onClick={() => removeRowById(record.id)}
              /> 
            </Tooltip>
          )}
        </div>
      );
    },
  },
  {
    dataIndex: 'drill_hole_name',
    title: 'Drill Hole Name',
    width: '166px',
    align: 'center',
    fixed: 'left',
    sorter: false,
    render: (value, record) => {
      let choosedDrillHoleName = payload?.map(item => item.drill_hole_name !== '' && item.drill_hole_name);
      let drillHoleNames = drillHolenameProperties.data.map(item => item.drill_hole_name);
      let options = drillHoleNames.filter(item => !choosedDrillHoleName?.includes(item) );
      let drillHoleIsFetching = drillHolenameProperties.isFetching;
      return (
        <SelectSearchAccent
          isSingle={true}
          isDisabled={isDetailMode}
          value={value || undefined}
          placeholder="Select"
          options={options}
          isLoading={drillHoleIsFetching}
          className={'w-full text-left'}
          onChange={(val) => {
            let choosedDrillHoleNames = drillHolenameProperties?.data?.find(item=> item.drill_hole_name === val);
            if(record.drill_hole_name && val !== record.drill_hole_name){
              showModal({
                isShown: true,
                type: 'confirmation',
                title:'Are you sure you want to change the drill hole data?',
                message: 'This action cannot be undone. Please confirm your decision.',
                onSubmit: () => {
                  populateDrillHolenameData(onChange, record.id, choosedDrillHoleNames, val);
                  resetModal();
                }
              });
            }else {
              populateDrillHolenameData(onChange, record.id, choosedDrillHoleNames, val);
            }
          }}
          isError={!isDetailMode && !value}
        />
      );
    },
  },
  {
    dataIndex: 'month',
    title: 'Month',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {value}
        </div>
      );
    },
  },
  {
    dataIndex: 'pit_location',
    title: 'Pit Location',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {value}
        </div>
      );
    },
  },
  {
    dataIndex: 'pit_year',
    title: 'Pit Year',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {value}
        </div>
      );
    },
  },
];

const populateDrillHolenameData = (onChange, id, choosedDrillHoleNames, val) => {
  onChange('drill_hole_name', id, val);
  onChange('month', id, val? choosedDrillHoleNames?.month : null);
  onChange('pit_location', id, val? choosedDrillHoleNames?.pit_location : null);
  onChange('pit_year', id, val ? choosedDrillHoleNames?.pit_year : null);
  onChange('resurvey_latitude', id, val ? choosedDrillHoleNames?.resurvey_latitude : null);
  onChange('resurvey_longitude', id, val ?  choosedDrillHoleNames?.resurvey_longitude : null);
  onChange('resurvey_easting', id, val ? choosedDrillHoleNames?.resurvey_easting : null);
  onChange('resurvey_northing', id, val ? choosedDrillHoleNames?.resurvey_northing : null );
};

export { generateSampleInformationTopoCollarTable };