import { Input } from 'antd';
import SelectAddAccent from 'components/SelectAddAccent';
import { RxCross2 } from 'react-icons/rx';
import { pendingPlanDepthFormula, pendingTimeFormula, startLateTimeFormula } from './TableTimeDepthAnalysis';
import { EMPTY } from 'constant';
import { calculateCategoryALoss, calculateCategoryPLoss, calculateCategoryQLoss, calculatePercentA, calculatePercentOme, calculatePercentP } from './TableOme';

const generateDrillingHoleInformationActualTable = (
  isDetailMode, 
  payload, 
  onChange, 
  drillHolenameProperties, 
  onDeleteRow,
  otherData
) => [
  {
    dataIndex: 'action',
    title: 'Action',
    width: 5,
    sorter: false,
    align: 'center',
    fixed: 'left',
    render: (_, __, index) => {
      return (
        <div className="flex justify-center">
          {payload.length <= 1 ? (
            <></>
          ) : (
            <RxCross2
              size={28}
              color={`${isDetailMode? 'gray' : 'red'}`}
              className={`rounded-full p-1 ${isDetailMode? 'bg-gray-200':'cursor-pointer bg-red-200 '}`}
              onClick={() => onDeleteRow(index)}
            />
          )}
        </div>
      );
    },
  },
  {
    dataIndex: 'drill_hole_name',
    title: 'Drill Hole Name',
    width: '166px',
    align: 'center',
    sorter: false,
    render: (value, _, index) => {
      let choosedDrillHoleName = payload?.map(item=>item.drill_hole_name !== '' && item.drill_hole_name);
      let drillHoleNames = drillHolenameProperties?.data?.map(item => item.drill_hole_name);
      let options = drillHoleNames?.filter(item => !choosedDrillHoleName?.includes(item) );
      let drillHoleIsFetching = drillHolenameProperties.isFetching;
      return (
        <SelectAddAccent
          isNotTag
          isDisabled={isDetailMode}
          isSingle={true}
          value={value || undefined}
          placeholder="Select"
          options={options}
          isLoading={drillHoleIsFetching}
          className={'w-full text-left'}
          onChange={(val) => {
            onChange(index, 'drill_hole_information', 'drill_hole_name' ,val);
            onChange(index, 'drill_hole_information', 'check_permit', populateCheckPermitFormula(drillHolenameProperties.data, val));
          }}
          isError={!isDetailMode && !value}
        />
      );
    },
  },
  {
    dataIndex: 'case_drill',
    title: 'Case Drill',
    width: '166px',
    align: 'center',
    sorter: false,
    render: (value, record, index) => {
      const options = [
        'As plan',
        'Re-drill',
        'Additional',
        'Cancel',
        'Pending'
      ];
      const populateTimeDepthAnalysis = () =>{
        onChange(index, 'time_&_depth_analysis', 'start_late_time', 
          startLateTimeFormula(index, drillHolenameProperties.data, otherData, record)
        );
        onChange(index,'time_&_depth_analysis', 'pending_time', 
          pendingTimeFormula(index, drillHolenameProperties.data, otherData, record)
        );
        onChange(index,'time_&_depth_analysis', 'pending_plan_depth', 
          pendingPlanDepthFormula(index, drillHolenameProperties.data, otherData, record)
        );
      };
      const populateOme = () => {
        onChange(index, 'ome', 'categories_a_loss', 
          calculateCategoryALoss(index, drillHolenameProperties.data, otherData)
        );
        onChange(index, 'ome', 'categories_p_loss', 
          calculateCategoryPLoss(index, drillHolenameProperties.data, otherData)
        );
        onChange(index, 'ome', 'categories_q_loss', 
          calculateCategoryQLoss(index, drillHolenameProperties.data, otherData)
        );
        onChange(index, 'ome', 'a', 
          calculatePercentA(index, drillHolenameProperties.data, otherData)
        );
        onChange(index, 'ome', 'p', 
          calculatePercentP(index, drillHolenameProperties.data, otherData)
        );
        onChange(index, 'ome', 'ome', 
          calculatePercentOme(index, otherData)
        );
      };
      return (
        <SelectAddAccent
          isNotTag
          isDisabled={isDetailMode}
          isSingle= {true}
          value={value || undefined}
          placeholder="Select"
          options={options}
          className={'w-full text-left'}
          onChange={(val)=>{
            onChange(index, 'drill_hole_information', 'case_drill' ,val);
            populateTimeDepthAnalysis();
            populateOme();
          }}
          isError={!isDetailMode && !value}
        />
      );
    },
  },
  {
    dataIndex: 'check_permit',
    title: 'Check Permit',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className='flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {value}
        </div>
      );
    },
  },
  {
    dataIndex: 'geologist',
    title: 'Geologist',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, _, index) => {
      return (
        <Input
          disabled={isDetailMode}
          placeholder={'Enter geologist'}
          value={value}
          onChange={(e) => {
            onChange(index, 'drill_hole_information', 'geologist' ,e.target.value);
          }}
          status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
        /> 
      );
    },
  },
];

const populateCheckPermitFormula = (plansData, drillHoleName) => {
  if(drillHoleName) {
    let choosedPlan = plansData.find(item => item.drill_hole_name === drillHoleName[0]);
    let result = '';
    let expected_granted_permit = choosedPlan?.expected_granted_permit;
    let dateStartPlan = choosedPlan?.date_start_drill_plan;
    if(expected_granted_permit > dateStartPlan){
      result = 'Revise Plan Start';
    }
    return result;
  }
  return EMPTY.STRING;
};

const generateDrillingHoleInformationActualData = (file) => {
  const data = file.properties[0].drill_hole_information;
  let finalResult = data[0].drill_hole_name.map((_, index) => {
    return data.reduce((result, currentObject) => {
      const key = Object.keys(currentObject)[0];
      const value = currentObject[key][index].value;
      result[key] = value;
      return result;
    }, {});
  });
  return finalResult;
};

export {generateDrillingHoleInformationActualTable, generateDrillingHoleInformationActualData};