import { drillHoleName } from 'constant/TableDrillingSummary';
import { generateRemarkTable } from 'constant/TableDrillingSummaryActual/TableRemark';
import TableDrillingSumarry from 'pages/DrillingPlan/DrillingSummary/TableDrillingSummary';

const Remark = (props) => {

  const { isDetailMode, remarkData, onChange, onDeleteRow, otherData } = props;
  const columns = generateRemarkTable(
    remarkData,
    isDetailMode,
    onChange,
    onDeleteRow
  );

  const populateDrillHoleName = () => {
    let finalData = remarkData.map((data, index) => {
      return {
        ...data, 
        drill_hole_name: otherData.drill_hole_information[index].drill_hole_name,
      };
    });
    return finalData;
  };
  columns.splice(1, 0, drillHoleName);

  return <TableDrillingSumarry 
    columns={ columns }
    tableData={ populateDrillHoleName() }
  />;
};

export default Remark;