import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { createSearchParams } from 'react-router-dom';

import {
  Select,
  Divider,
  DatePicker
} from 'antd';
import { userManagementColumns } from 'constant/TableUserManagement';
import {
  ACTION,
  DEFAULT,
  PERMISSION
} from 'constant';
import { getFullPath } from 'utils/PathUtility';
import { useGetUsersQuery, usePutUserMutation } from 'api/User';
import { toast } from 'react-toastify';
import { transformError } from 'utils/ErrorTransformer';
import { usePermissionManager } from 'utils/PermissionManager';

import Toast from 'components/Toast';
import FilterContainer from 'components/FilterContainer';
import TableData from 'components/TableData';
import ButtonAccent from 'components/ButtonAccent';
import { useModalConfirmationContext } from 'components/ModalConfirmationProvider';

const UserManagement = () => {
  const loc = useLocation();
  const navigate = useNavigate();
  const {
    page,
    pageSize,
    sortBy,
    orderBy,
    search
  } = useSelector((state) => state.table);

  const initialFilter = {};
  const [filter, setFilter] = useState(initialFilter);

  const { showModal, resetModal } = useModalConfirmationContext();
  const { isUserHasPermissionInFeature } = usePermissionManager();

  const {
    data: users,
    refetch: usersRefetch,
    isFetching: usersIsFetching,
    isError: usersIsError,
    error: usersError
  } = useGetUsersQuery(
    {
      page,
      limit: pageSize,
      sort_by: sortBy,
      order_by: orderBy,
      ...filter,
      ...search
        ? { search }
        : undefined
    },
    { refetchOnMountOrArgChange: true }
  );

  const [putUser, {
    isError: putUserIsError,
    error: putUserError,
    isSuccess: putUserIsSuccess
  }] = usePutUserMutation();

  const calculatePixel = (remVal) => {
    /**
     * `1rem` is `16px`
     * */
    return `${(window.innerWidth - (remVal * 16))}px`;
  };

  const onFilter = (key, value) => {
    setFilter((prevState) => ({
      ...prevState,
      [key]: key === 'created_at' ? moment(value).format('YYYY-MM-DD') : value
    }));
  };

  const resetFilter = () => {
    setFilter(initialFilter);
  };

  const goToPathByRole = (id, type) => navigate({
    pathname: getFullPath({
      parent: 'user-management',
      child: 'form',
      ...(type === ACTION.EDIT)
        ? { type }
        : undefined
    }),
    ...(type === ACTION.EDIT)
      ? { search: createSearchParams({ id }).toString() }
      : undefined
  });

  const onAction = (id, type) => {
    /**
     * @param {string} id
     * @param {string} type
     * */
    goToPathByRole(id, type);
  };

  const onChangeStatus = (id, status) => {
    const body = {
      is_active: !status
    };

    showModal({
      isShown: true,
      type: 'delete',
      title: 'Confirmation!',
      message: `Are you sure you want to ${status ? 'deactivate' : 'activate'}  this user?`,
      onSubmit: () => {
        putUser({ id: id, body: body });
        resetModal();
      }
    });
  };

  useEffect(() => {
    if (putUserIsError) {
      const errorMsg = putUserError;

      toast.error(<Toast message={'ERROR'} detailedMessage={`${transformError(errorMsg).message}`} />);
      return;
    }
    if (putUserIsSuccess) {
      usersRefetch();
      return;
    }
  }, [putUserIsError, putUserIsSuccess]);

  useEffect(() => {
    if (usersIsError) {
      const errorMsg = usersError;

      toast.error(<Toast message={'ERROR'} detailedMessage={`${transformError(errorMsg).message}`} />);
      return;
    }
  }, [usersIsError]);

  return (
    <>
      <div className="p-5 bg-white rounded-lg">
        <FilterContainer onReset={resetFilter}>
          <label>
            <span className="font-bold">Type</span>
            <Select
              placeholder="Select type"
              value={filter['type'] || null}
              options={[{label:'Internal', value:'internal'}, {label:'External', value:'external'}]}
              className="mt-2 w-full"
              onChange={(val) => onFilter('type', val)}
            />
          </label>
          <label>
            <span className="font-bold">Created Date</span>
            <DatePicker
              allowClear={false}
              className="mt-2 w-full"
              value={filter['created_at'] ? moment(filter['created_at']) : null}
              onChange={(val) => onFilter('created_at', val)}
            />
          </label>
        </FilterContainer>
        <Divider />
        <div
          className="overflow-x-auto"
          style={{ maxWidth: calculatePixel(23) }}
        >
          <TableData
            scrollWidth="max-content"
            isTransparent
            rowKey="id"
            isLoading={usersIsFetching}
            columns={
              userManagementColumns(
                loc.pathname,
                isUserHasPermissionInFeature,
                sortBy,
                orderBy === DEFAULT.KEY_SORT_ASC
                  ? DEFAULT.SORT_ASC
                  : DEFAULT.SORT_DESC,
                onAction,
                onChangeStatus
              )
            }
            dataSource={users?.data}
            totalData={users?.total_data}
          >
            {
              isUserHasPermissionInFeature(loc.pathname, PERMISSION.CREATE) && (
                <ButtonAccent
                  title="Create"
                  onClick={() => navigate(getFullPath({
                    parent: 'user-management',
                    child: 'form',
                    type: 'create'
                  }))}
                />
              )
            }
          </TableData>
        </div>
      </div>
    </>
  );
};

export default UserManagement;