import { Base } from './Base';

const Workflow = Base.injectEndpoints({
  endpoints: (build) => ({
    uploadFile: build.mutation({
      query: (body) => ({
        url: '/workflow/file-storage',
        method: 'POST',
        body,
        // 10 minutes of upload timeout
        timeout: Number(10 * (60 * 1000))
      })
    }),
    deleteFile: build.mutation({
      query: ({ id }) => ({
        url: `/workflow/file-storage/${id}`,
        method: 'DELETE'
      })
    })
  })
});

export const { useUploadFileMutation, useDeleteFileMutation } = Workflow;