import moment from 'moment';
import { Base } from './Base';

const OmeAp = Base.injectEndpoints({
  endpoints: (build) => ({
    getOmeAps: build.query({
      query: (params) => ({
        url: `/ome/maintenance/ap?${params}`,
        method: 'GET',
      }),
    }),
    getOmeApById: build.query({
      query: ({ id }) => ({
        url: `/ome/maintenance/ap/${id}`,
        method: 'GET',
      }),
    }),
    postOmeAp: build.mutation({
      query: (body) => ({
        url: '/ome/maintenance/ap',
        method: 'POST',
        body,
      }),
    }),
    bulkCreateOmeAp: build.mutation({
      query: (body) => ({
        url: '/ome/maintenance/ap/bulk-create',
        method: 'POST',
        body,
      }),
    }),
    postOmeApExcel: build.mutation({
      query: (body) => ({
        url: '/ome/maintenance/ap/upload-excel',
        method: 'POST',
        body,
        timeout: Number(10 * (60 * 1000))
      }),
    }),
    putOmeAp: build.mutation({
      query: ({ id, body }) => ({
        url: `/ome/maintenance/ap/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteOmeAp: build.mutation({
      query: ({ id }) => ({
        url: `/ome/maintenance/ap/${id}`,
        method: 'DELETE',
      }),
    }),
    exportOmeApExcel: build.query({
      query:(params)=>({
        url:`/ome/maintenance/ap/export-excel?${params}`,
        method: 'GET',
        responseHandler: async (response) => {
          const docLink = document.createElement('a');
          docLink.href = window.URL.createObjectURL(await response.blob());
          docLink.target = '_blank';
          docLink.download = `ome_ap_${moment(new Date()).format('DD_MM_YYYY_HH_mm_ss')}.xlsx`;
          docLink.click();
        },
        cache: 'no-cache',
      })
    })
  }),
});

export const {
  useGetOmeApsQuery,
  useLazyGetOmeApsQuery,
  useLazyGetOmeApByIdQuery,
  usePostOmeApMutation,
  useBulkCreateOmeApMutation,
  usePostOmeApExcelMutation,
  usePutOmeApMutation,
  useDeleteOmeApMutation,
  useLazyExportOmeApExcelQuery
} = OmeAp;
