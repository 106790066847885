import { Base } from './Base';

const Role = Base.injectEndpoints({
  endpoints: (build) => ({
    getRoles: build.query({
      query: () => ({
        url: '/roles',
        method: 'GET'
      })
    }),
  })
});

export const {
  useGetRolesQuery
} = Role;