import { Alert, Image, Progress, Tooltip, Upload } from 'antd';
import ButtonAccent from 'components/ButtonAccent';
import { isEmpty, toLower } from 'lodash';
import { useEffect, useState } from 'react';
import { TbTrash } from 'react-icons/tb';
import { convertToMegaBytes } from 'utils/FileUtility';
const { Dragger } = Upload;
const UploadDocumentExcel = (props) => {
  const {
    uploadExcel = () => {},
    getErrorExcel = () => {}, 
    onDelete = () => {}, 
    onChange = () => {}, 
    value,
    getFile = () => {},
    excelRes,
    excelResError,
    excelResIsError,
    excelResIsLoading,
    isDetailMode,
    isEditMode,
    onDownload,
    planId,
    latestVersion
  } = props;
  const [file, setFile] = useState(!isEmpty(value)? value : null);
  const [tempFile, setTempFile] = useState(null);
  const [isFileHasForbiddenExtensions, setIsFileHasForbiddenExtensions] = useState(false);
  const generateFormData = (file) => {
    const formData = new FormData();
    formData.append('files', file);
    return formData;
  };

  const checkFileHasForbiddenExtensions = (file) => {
    const forbiddenExtensions = ['exe', 'vb', 'apk', 'sql', 'js', 'scrp'];
    const fileExt = file.name.split('.');
    const ext = fileExt[fileExt.length - 1];
    return forbiddenExtensions.some((extension) => toLower(extension).includes(ext));
  };

  const checkFileBeforeUpload = (file, callback) => {
    if (checkFileHasForbiddenExtensions(file)) {
      setIsFileHasForbiddenExtensions(true);
      setFile(null);
      return;
    }
    callback();
  };

  const handleOnChange = (info) => {
    const { file } = info;
    checkFileBeforeUpload(file, () => {
      const form = generateFormData(file);
      setIsFileHasForbiddenExtensions(false);
      setTempFile(file);
      uploadExcel({
        body: form,
        ...(planId) && {plan_id: planId},
        ...(latestVersion) && {params:{latest_version: latestVersion}}
      });
    });

    getErrorExcel(null);
  };

  const populateFileInfo = (file) => {
    return file?.fileName || file?.file_info?.fileName || file?.file_info?.name;
  };
  
  const handleDeleteFile = () => {
    setFile(null);
    onDelete();
  };

  useEffect(() => {
    onChange(file);
  }, [file]);

  useEffect(() => {
    if(!isEmpty(value)){
      setFile(value);
    }
  },[value]);

  useEffect(() => {
    if(!isEmpty(excelRes)) {
      getFile(tempFile);
      setFile(excelRes);
    }
  }, [excelRes]);

  useEffect(() => {
    if (excelResIsError) {
      getErrorExcel(excelResError);
      return;
    }
  }, [
    excelResError,
    excelResIsError,
  ]);

  return (
    <div className={'p-5 flex flex-col bg-white rounded-lg'}>
      {
        isFileHasForbiddenExtensions && (
          <Alert
            type="error"
            message="Cannot upload this type of file, please choose another one"
            className="mb-5"
          />
        )
      }
      {
        (((!isDetailMode || isEditMode) && !populateFileInfo(file)))
          ? (
            <Dragger
              showUploadList={false}
              name="file"
              maxCount={1}
              accept={
                '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
              }
              beforeUpload={() => false}
              onChange={handleOnChange}
            >
              <div className="p-5 flex flex-col items-center justify-center bg-[#2D3D5A10] border-dashed border-2 border-[#2D3D5A] rounded-lg">
                {
                  (excelResIsLoading)
                    ? (
                      <Progress
                        percent={100}
                        status="active"
                        strokeColor="#472F92"
                        showInfo={false}
                      />
                    )
                    : (
                      <>
                        <div className="p-3 flex flex-col items-center justify-center bg-white rounded-full">
                          <Image
                            preview={false}
                            width={30}
                            src={`${process.env.PUBLIC_URL}/assets/icon-upload.svg`}
                          />
                        </div>
                        <span className="my-2">Drag and Drop your file here</span>
                        <ButtonAccent title="Browse File" />
                      </>
                    )
                }
              </div>
            </Dragger>
          ): (
            <div className="p-5 flex flex-row items-center bg-[#2D3D5A10] border-dashed border-2 border-[#2D3D5A] rounded-lg">
              <Image
                preview={false}
                width={100}
                src={`${process.env.PUBLIC_URL}/assets/icon-excel.svg`}
                className="rounded-lg cursor-pointer"
              />
              <div className="ml-5 flex flex-col justify-center">
                <Tooltip placement='topRight' title={populateFileInfo(file)} color='#2D3D5A' trigger={'hover'}>
                  <span className='w-[35vw] truncate'>{populateFileInfo(file)}</span>
                </Tooltip>
                <span className="text-gray-500/50">
                  {convertToMegaBytes(file?.size || file?.file_info?.size )} MB
                </span>
              </div>
              {
                isDetailMode || isEditMode
                  ? (
                    <>
                      <ButtonAccent
                        title={'Download File'}
                        onClick={onDownload}
                        className={'ml-4 mb-2 w-32'}
                      />
                      {isEditMode && (
                        <div
                          className="ml-auto p-1 bg-white rounded-full cursor-pointer"
                          onClick={handleDeleteFile}
                        >
                          <Tooltip title={'Delete'} trigger={'hover'}>
                            <TbTrash size="18px" color='#EB5545' />
                          </Tooltip>
                        </div>
                      )
                      }
                    </>
                  )
                  : (
                    <div
                      className="ml-auto p-1 bg-white rounded-full cursor-pointer"
                      onClick={handleDeleteFile}
                    >
                      <Tooltip title={'Delete'} trigger={'hover'}>
                        <TbTrash size="18px" color='#EB5545' />
                      </Tooltip>
                    </div>
                  )
              }
            </div>
          )   
      }
    </div>
  );
};

export default UploadDocumentExcel;