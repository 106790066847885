import ButtonAccent from 'components/ButtonAccent';
import CategoriesList from 'components/CategoriesList';
import InputFileAccent from 'components/InputFileAccent';
import { categories } from 'constant/TableDrillingSummary';
import { useCallback, useEffect, useState } from 'react';
import { useTableWrapper } from 'utils/TableResponsive';
import DrillingDateActual from './DrillingDate';
import { useLazyExportSummaryActualExcelQuery, useLazyGetDetailDrillingSummaryActualByIdQuery, useLazyGetDrillHoleNameListQuery, usePostActualSummaryMutation } from 'api/DrillingSummary';
import DrillingHoleInformationActual from './DrillingHoleInformation';
import { toast } from 'react-toastify';
import { transformError } from 'utils/ErrorTransformer';
import { generateBody, generateInitialDrillingSummaryActualData } from 'constant/TableDrillingSummaryActual/TableDrillingSummaryData';
import TimeDepthAnalysisActual from './TimeDepthAnalysisTable';
import DrillingHoleLocationActual from './DrillingHoleLocation';
import { useSelector } from 'react-redux';
import _, { isArray, isEmpty, isNumber } from 'lodash';
import DrillingDepth from './DrillingDepth';
import DrillingSamplesInformation from './DrillingSamplesInformation';
import Ome from './OME';
import Remark from './Remark';
import { useLocation, useNavigate } from 'react-router';
import { getPathType } from 'utils/PathUtility';
import ModalInformation from 'pages/DrillingPlan/components/ModalInformation';
import { generateDrillingHoleInformationActualData } from 'constant/TableDrillingSummaryActual/TableDrillingHoleInformation';
import { generateDrillingHoleLocationActualData } from 'constant/TableDrillingSummaryActual/TableDrillingHoleLocation';
import { generateDrillingDepthActualData } from 'constant/TableDrillingSummaryActual/TableDrillingDepth';
import { generateDrillingDateActualData } from 'constant/TableDrillingSummaryActual/TableDrillingDateActual';
import { generateDrillingTimeDepthAnalysisActualData } from 'constant/TableDrillingSummaryActual/TableTimeDepthAnalysis';
import { generateDrillingSamplesInformationActualData } from 'constant/TableDrillingSummaryActual/TableDrillingSamplesInformation';
import { generateDrillingOmeActualData } from 'constant/TableDrillingSummaryActual/TableOme';
import { generateDrillingRemarksActualData } from 'constant/TableDrillingSummaryActual/TableRemark';
import LoadingIcon from 'components/LoadingIcon';
import { useGetMasterDataLossesQuery } from 'api/MasterData';
import DrillingEvidenceActual from './DrillingEvidence';
import { generateDrillingEvidenceData } from 'constant/TableDrillingSummaryActual/TableDrillingEvidence';
import ModalUpload from './DrillingEvidence/ModalUpload';
import { useModalConfirmationContext } from 'components/ModalConfirmationProvider';
import { URL } from 'constant';
import PreventNavigation from 'constant/PreventNavigation';
import { Tag } from 'antd';
const DrillingSummaryActual = () => {
  const { wrapperWidth } = useTableWrapper();
  let initialData = _.cloneDeep(generateInitialDrillingSummaryActualData);
  const [payload, setPayload] = useState({...initialData});
  const [plansData, setPlansData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(
    'Drill Hole Information'
  );
  const [file, setFile] = useState(null);
  const [data, setData] = useState(null);
  const [errorExcel, setErrorExcel] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [transformErrorUpload, setTransformErrorUpload] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const isDetailMode = getPathType(location) === 'detail';
  const isEditMode = getPathType(location) === 'edit';
  const drillingSummaryActualId = new URLSearchParams(location.search).get('id');
  const {showModal, resetModal} = useModalConfirmationContext();
  let inputActualData = useSelector(state => state.drillingActual.input_actual);
  const [drillingPlanId, setDrillingPlanId] = useState(null);
  const [drillingVersion, setDrillingPlanVersion] = useState(null);
  const [drillingPlanYear, setDrillingPlanYear] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [company, setCompany] = useState(null);
  const [drillingPlanType, setDrillingPlanType] = useState(null);
  const [showModalUpload, setShowModalUpload] = useState(false);
  const finalCategories = categories.filter(
    (category) => category !== 'Drilling Machine(RIG)'
  );

  const populateType = (type) => {
    if(type === 'PREPRODUCTION'){
      return 'Pre-production';
    }else{
      return 'Exploration';
    }
  };

  const [
    getDrillHoleNameList,
    {
      data: drillHoleNameData,
      isFetching: drillHoleNameIsFetching,
      isError: drillHoleNameIsError,
      error: drillHoleNameError,
    },
  ] = useLazyGetDrillHoleNameListQuery({ refetchOnMountOrArgChange: true });

  const [
    getDetailDrillingSummaryActualById,
    {
      data: detailDrillingData,
      isFetching: detailDrillingIsFetching,
      isError: detailDrillingIsError,
      error: detailDrillingError
    }
  ] = useLazyGetDetailDrillingSummaryActualByIdQuery({refetchOnMountOrArgChange: true});

  const {
    data: availabilityData,
    isFetching: availabilityIsFetching
  } = useGetMasterDataLossesQuery(
    { type: 'GOE_AVAILABILITY_LOSS' },
    { refetchOnMountOrArgChange: true }
  );

  const {
    data: qualityData,
    isFetching: qualityIsFetching
  } = useGetMasterDataLossesQuery(
    { type: 'GOE_QUALITY_LOSS' },
    { refetchOnMountOrArgChange: true }
  );

  const {
    data: performanceData,
    isFetching: performanceIsFetching
  } = useGetMasterDataLossesQuery(
    { type: 'GOE_PERFORMANCE_LOSS' },
    { refetchOnMountOrArgChange: true }
  );

  const [postSummary, {
    isLoading: postIsLoading,
    isSuccess: postIsSuccess,
    isError: postIsError,
    error: postError
  }] = usePostActualSummaryMutation();

  const [exportSummaryActualExcel, {
    isLoading: exportExcelIsLoading,
    isError: exportExcelIsError,
    error: exportExcelError
  }] = useLazyExportSummaryActualExcelQuery();

  const onChange = (index, categoryName, attributeName, value) => {
    let temp = {...payload};
    if(categoryName === 'drilling_evidence'){
      if(isArray(value)) {
        temp[categoryName][index][attributeName]['date'] = value[0];
      }else {
        temp[categoryName][index][attributeName]['date'] = value;
      }
    }else {
      if(isArray(value)) {
        temp[categoryName][index][attributeName] = value[0];
      }else {
        temp[categoryName][index][attributeName] = value;
      }
    }
    setPayload({...temp});
  };

  const populateTableActual = () => {
    switch (selectedCategory) {
      case 'Drill Hole Information':
        return (
          <DrillingHoleInformationActual
            isDetailMode={isDetailMode} 
            otherData={payload}
            drillHoleInformationData={payload.drill_hole_information}
            onChange={onChange}
            drillHoleNameProperties={{
              data: plansData,
              isFetching: drillHoleNameIsFetching,
            }}
            onDeleteRow={removeRowTable}
          />
        );
      case 'Drill Hole Location':
        return (
          <DrillingHoleLocationActual
            isDetailMode={isDetailMode}
            drillingHoleLocationData={payload.drill_hole_location}
            onChange={onChange}
            onDeleteRow={removeRowTable}
            otherData={payload}
          />
        );
      case 'Drilling Date':
        return (
          <DrillingDateActual 
            otherData={payload}
            plansData={plansData}
            drillingDateActualData={payload.drilling_date} 
            onChange={onChange}
            onDeleteRow={removeRowTable}
            isDetailMode={isDetailMode}
          />
        );
      case 'Drilling Depth':
        return (
          <DrillingDepth
            otherData={payload}
            isDetailMode={isDetailMode}
            drillingDepthData={payload.drilling_depth} 
            onChange={onChange}
            onDeleteRow={removeRowTable}
            plansData={plansData}
          />
        );
      case 'Time & Depth Analysis':
        return (
          <TimeDepthAnalysisActual
            otherData={payload}
            isDetailMode={isDetailMode}
            timeDepthAnalysisActualData={payload['time_&_depth_analysis']}
            onDeleteRow={removeRowTable}
          />
        );
      case 'Drilling Evidence':
        return (
          <DrillingEvidenceActual
            downloadFile={downloadFile} 
            drillingEvidanceData={payload.drilling_evidence}
            otherData={payload}
            onChange={onChange}
            isDetailMode={isDetailMode}
            onDeleteRow={removeRowTable}
          />
        );
      case 'Drilling Samples Information':
        return(
          <DrillingSamplesInformation
            isDetailMode={isDetailMode}
            drillingSamplesInformationData={payload.drilling_samples_information} 
            onChange={onChange}
            onDeleteRow={removeRowTable}
            otherData={payload}
          />
        );
      case 'OME':
        return(
          <Ome
            isDetailMode={isDetailMode}
            omeData={payload.ome} 
            onChange={onChange}
            onDeleteRow={removeRowTable}
            availabilityData={{
              data: availabilityData,
              isFetching: availabilityIsFetching
            }}
            qualityData={{
              data: qualityData,
              isFetching: qualityIsFetching
            }}
            performanceData={{
              data: performanceData,
              isFetching: performanceIsFetching
            }}
            otherData={payload}
            plansData={plansData}
          />
        );
      case 'Remark':
        return(
          <Remark
            isDetailMode={isDetailMode}
            remarkData={payload.remarks} 
            onChange={onChange}
            onDeleteRow={removeRowTable}
            otherData={payload}
          />
        );
      default:
        return;
    }
  };

  const downloadFile = (file, type) => {
    const docLink = document.createElement('a');
    docLink.href = type? file : file?.url;
    document.body.appendChild(docLink);
    docLink.click();
    document.body.removeChild(docLink);
  };

  const handleDeleteFile = () => {
    setData(null);
    setFile(null);
  };

  function generateActualData(key, data) {
    switch (key) {
      case 'drill_hole_information':
        return generateDrillingHoleInformationActualData(data);
      case 'drill_hole_location':
        return generateDrillingHoleLocationActualData(data);
      case 'drilling_date':
        return generateDrillingDateActualData(data);
      case 'drilling_depth':
        return generateDrillingDepthActualData(data);
      case 'time_&_depth_analysis':
        return generateDrillingTimeDepthAnalysisActualData(data);
      case 'drilling_evidence':
        return generateDrillingEvidenceData(data);
      case 'drilling_samples_information':
        return generateDrillingSamplesInformationActualData(data);
      case 'ome':
        return generateDrillingOmeActualData(data);
      case 'remarks':
        return generateDrillingRemarksActualData(data);
      default:
        return [];
    }
  }

  const mappingExcelData = (data) => {
    let mappedData ={};
    let detailData ={};
    let drillHoleNameData = payload?.drill_hole_information;
    const populateFormula = (drillHoleName) => {
      let choosedPlan = plansData.find(item => item.drill_hole_name ===  drillHoleName);
      let result = '';
      let expected_granted_permit = choosedPlan?.expected_granted_permit;
      let dateStartPlan = choosedPlan?.date_start_drill_plan;
      if(expected_granted_permit > dateStartPlan){
        result = 'Revise Plan Start';
      }
      return result;
    };
    Object.keys(payload).forEach(key => {
      let tempData = generateActualData(key, data);
      payload[key].forEach((item, index) => {
        if(drillHoleNameData[index].drill_hole_name){
          let isSameDrillHoleName = tempData.find(data=>data?.drill_hole_name === drillHoleNameData[index]?.drill_hole_name);
          if(isEmpty(isSameDrillHoleName)){
            tempData.push(item);
          }
        }
      });
      let detailTemp = generateActualData(key, data);
      if(key === 'drill_hole_information') {
        tempData[0].check_permit = populateFormula(tempData[0].drill_hole_name);
        detailTemp[0].check_permit = populateFormula(detailTemp[0].drill_hole_name);
      }
      mappedData[key] = tempData;
      detailData[key] = detailTemp;
    });
    setPayload(isDetailMode || isEditMode? {...detailData} : {...mappedData});
  };

  const handleBackButton = () => {
    if(isDetailMode) {
      navigate(`/${URL.ACTIVITY_LEVEL}/${URL.GEOLOGY_OE}/summary-drilling`);
    } else {
      showModal({
        isShown: true,
        type: 'confirmation',
        title:'Are you sure want to leave this page?',
        message: 'Changes you made may not be saved',
        onSubmit: () => {
          navigate(`/${URL.ACTIVITY_LEVEL}/${URL.GEOLOGY_OE}/summary-drilling`);
          resetModal();
        }
      });
    }
  };

  const mandatoryValidationEvidence = () => {
    let validation = true;
    let temp = _.cloneDeep(payload.drilling_evidence);
    temp.forEach((evidence) => {
      let keys = Object.keys(evidence);
      keys.forEach(key => {
        if((isEmpty(evidence[key]?.files) || !evidence[key]?.date) && key !== 'drill_hole_name'){
          validation = false;
        }
      });
    });
    return validation;
  };

  const mandatoryValidationDrillHoleInformation = () => {
    let validation = true;
    let temp = _.cloneDeep(payload.drill_hole_information);
    temp.forEach((v) => {
      let keys = Object.keys(v);
      keys.forEach(key => {
        if(!isNumber(v[key]) && (!v[key] && key !== 'check_permit')){
          validation = false;
        }
      });
    });
    return validation;
  };

  const mandatoryValidationDrillHoleLocation = () => {
    let validation = true;
    let temp = _.cloneDeep(payload.drill_hole_location);
    temp.forEach((v) => {
      let keys = Object.keys(v);
      keys.forEach(key => {
        if(!isNumber(v[key]) && (!v[key] && key !== 'drill_hole_name')){
          validation = false;
        }
      });
    });
    return validation;
  };

  const mandatoryValidationDrillingDepth = () => {
    let validation = true;
    let temp = _.cloneDeep(payload.drilling_depth);
    let exception = ['drill_hole_name', 'depth_deviation', 'depth_recovery', 'total_oh', 'total_core'];
    temp.forEach((v) => {
      let keys = Object.keys(v);
      keys.forEach(key => {
        if(!isNumber(v[key]) && (!v[key] && !exception.includes(key))){
          validation = false;
        }
      });
    });
    return validation;
  };

  const mandatoryValidationDrillingDate = () => {
    const exceptionKey = [
      'drill_hole_name',
      'check_date_final_log_wellcad',
      'drilling_rate_by_final_log',
      'speed',
      'actual_rate',
      'exceed_time'
    ];
    let validation = true;
    let temp = _.cloneDeep(payload.drilling_date);
    temp.forEach((v) => {
      let keys = Object.keys(v);
      keys.forEach(key => {
        if(!isNumber(v[key]) && (!v[key] && !exceptionKey.includes(key))){
          validation = false;
        }
      });
    });
    return validation;
  };

  const mandatoryValidationDrillingSamplesInformation = () => {
    let validation = true;
    let temp = _.cloneDeep(payload.drilling_samples_information);
    temp.forEach((v) => {
      let keys = Object.keys(v);
      keys.forEach(key => {
        if(!isNumber(v[key]) && (!v[key] && key !== 'drill_hole_name')){
          validation = false;
        }
      });
    });
    return validation;
  };

  const validateSubmit = () => {
    switch (false) {
      case mandatoryValidationDrillHoleInformation():
        toast.error('Some field is mandatory in Drill Hole Information',
          { toastId: 'validate-drill-hole-information-toast-error' }
        );

        setSelectedCategory('Drill Hole Information');
        return false;
      case mandatoryValidationDrillHoleLocation():
        toast.error('Some field is mandatory in Drill Hole Location',
          { toastId: 'validate-drill-hole-location-toast-error' }
        );
        
        setSelectedCategory('Drill Hole Information');
        return false;
      case mandatoryValidationDrillingDepth():
        toast.error('Some field is mandatory in Drilling Depth',
          { toastId: 'validate-drilling-depth-toast-error' }
        );

        setSelectedCategory('Drilling Depth');
        return false;
      case mandatoryValidationDrillingDate():
        toast.error('Some field is mandatory in Drilling Date',
          { toastId: 'validate-drilling-date-toast-error' }
        );

        setSelectedCategory('Drilling Date');
        return false;
      case mandatoryValidationEvidence():
        toast.error('File(s) in Drilling Evidence have not been uploaded. Please upload the file(s) first. ',
          { toastId: 'validate-evidence-toast-error' }
        );

        setSelectedCategory('Drilling Evidence');
        return false;
      case mandatoryValidationDrillingSamplesInformation():
        toast.error('Some field is mandatory in Drilling Samples Information',
          { toastId: 'validate-drillisang-samples-information-toast-error' }
        );

        setSelectedCategory('Drilling Samples Information');
        return false;
      default:
        return true;
    }
  };

  const onSubmit = (isDraft) => {
    if (!isDraft) {
      if (!validateSubmit()) {
        return;
      }
    }

    const fileInfo = data?.file_info || detailDrillingData?.file_info;
    const body = generateBody({
      payload: payload,
      files: file,
      year: Number(drillingPlanYear),
      company: !isEmpty(detailDrillingData) ? detailDrillingData.company : inputActualData?.company,
      plan_type: drillingPlanType,
      version: drillingVersion,
      file_info: file ? null : fileInfo ?? null,
      is_draft: isDraft
    });
    setSuccessMessage(isDraft ? 'you have successfully saved draft of drilling summary actual ' 
      :'You have successfully submitted drilling summary actual');
    postSummary({ id: drillingPlanId, body});
  };

  const jsonParseMessage = () => {
    try {
      return JSON.parse(errorExcel.data.error.message);;  
    } catch{
      return null;
    }
  };

  const addRowTable = () => {
    const temp = _.cloneDeep(payload);

    Object.entries(temp).forEach(([key, value]) => {
      const tempValue = {...value[0]};
      Object.entries(tempValue).forEach(([keyV, _]) => {
        tempValue[keyV] = '';
      });
      temp[key].push(tempValue);
    });

    setPayload({...temp});
  };

  const removeRowTable = (index) => {
    const temp = _.cloneDeep(payload);

    Object.entries(temp).forEach(([key, _]) => {
      temp[key].splice(index, 1);
    });

    setPayload({...temp});
  };

  const populateDrillingPlanInfo = () => {
    let validation = (isDetailMode || isEditMode) && !isEmpty(detailDrillingData);
    let year = validation? detailDrillingData?.year :inputActualData?.planning_name.name.split(' ')[2]; 
    let company = validation? detailDrillingData?.company?.alias_name : inputActualData?.company?.alias_name;
    let planType = validation? detailDrillingData?.plan_type : inputActualData?.planning_type;
    let planId = validation? detailDrillingData?.goe_drilling_plan_id: inputActualData?.planning_name.goe_drilling_plan_id;
    let version = validation? detailDrillingData?.version : inputActualData?.planning_name.latest_version;
    setDrillingPlanYear(year);
    setCompany(company);
    setDrillingPlanType(planType);
    setDrillingPlanId(planId);
    setDrillingPlanVersion(version);
  };

  const handleCloseSite = useCallback((ev) => ev.preventDefault() ,[]);
  
  useEffect(() => {
    PreventNavigation(isDetailMode, handleCloseSite);
  }, []);

  useEffect(() => {
    if(!isEmpty(data)){
      mappingExcelData(data);
    }
  },[data]);

  useEffect(() => {
    if (drillingPlanId) {
      getDrillHoleNameList({ drillingPlanId, version: drillingVersion });
    }
  }, [drillingPlanId, drillingVersion]);

  useEffect(() => {
    if(!isEmpty(drillHoleNameData)) {
      setPlansData(drillHoleNameData.data);
    }
  },[drillHoleNameData]);

  useEffect(() => {
    if (drillHoleNameIsError || detailDrillingIsError || postIsError) {
      const generateToastId = () => {
        switch (true) {
          case drillHoleNameError:
            return 'get-drill-hole-name';
          case detailDrillingError:
            return 'get-detail-drilling-summary-actual';
          case postError:
            return 'post-summary-actual';
          default:
            return 'default';
        }
      };
      
      toast.error(
        transformError(drillHoleNameError || detailDrillingError || postError).message,
        { toastId: `${generateToastId()}-toast-error` }
      );
    }
  }, [drillHoleNameIsError, detailDrillingIsError, postIsError]);

  useEffect(() => {
    if (!isEmpty(errorExcel)) {
      setTransformErrorUpload(null);

      if (errorExcel.data) {
        const transformErrorUpload = errorExcel.status <= 400 ? jsonParseMessage(errorExcel.data.error.message) : null;
        setTransformErrorUpload(transformErrorUpload);
      }

      setIsModalOpen(true);
    }

    if (exportExcelIsError) {
      toast.error(
        transformError(exportExcelError).message,
        { toastId: 'export-excel-actual-toast-error' }
      );
    }
  }, [errorExcel, exportExcelIsError]);

  useEffect(()=>{
    if(drillingSummaryActualId && (isDetailMode || isEditMode)) {
      getDetailDrillingSummaryActualById({id: drillingSummaryActualId});
    }
  },[drillingSummaryActualId, isDetailMode, isEditMode]);

  useEffect(()=>{
    if(!isEmpty(detailDrillingData)){
      mappingExcelData(detailDrillingData?.content);
      setData({...detailDrillingData?.file_info, ...detailDrillingData?.content});
    }
  },[detailDrillingData]);

  useEffect(()=>{
    populateDrillingPlanInfo();
  },[inputActualData, isDetailMode, detailDrillingData, isEditMode]);

  useEffect(() => {
    if (postIsSuccess) {
      toast.success(successMessage, { toastId: 'post-quality-toast-success' });
      navigate(`/${URL.ACTIVITY_LEVEL}/${URL.GEOLOGY_OE}/summary-drilling`);
    }
  }, [postIsSuccess]);

  return (
    <div className="flex flex-col gap-4">
      {isDetailMode && isEmpty(data?.fileName)? <></>: (
        <div
          className="bg-white p-5 overflow-x-auto rounded-2xl"
          style={{ maxWidth: wrapperWidth }}
        >
          <div className=" mb-8 flex flex-row justify-between">
            <div className=" text-[18px] font-bold">Upload Data</div>
          </div>
          <InputFileAccent
            fileType={
              '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
            }
            version={drillingVersion}
            isActual={true}
            id={drillingPlanId}
            onChange={isDetailMode ? () => {} : setData}
            isDetailMode={isDetailMode}
            getFile={setFile}
            value={(isDetailMode || isEditMode) && !isEmpty(data?.fileName) ? data : null}
            getErrorExcel={setErrorExcel}
            onDrop={isDetailMode ? () => {} : setData}
            onDelete={handleDeleteFile}
            onPreview={isDetailMode ? () => downloadFile(data) : () => {}}
            buttonTitle={isDetailMode? 'Download File': 'Preview'}
            noTitle={true}
          />
        </div>
      )}
      <div
        className="bg-white p-5 overflow-x-auto rounded-2xl"
        style={{ maxWidth: wrapperWidth }}
      >
        <div className=" mb-8 flex flex-row justify-between">
          {selectedCategory === 'Drilling Evidence' && !isDetailMode? (
            <ButtonAccent size={'xl'} title="Upload Evidence" onClick={() => setShowModalUpload(true)} />
          ):(
            <div className="text-[18px] font-bold flex flex-row gap-4">
              <ButtonAccent size={'2xl'} title="Download Template" 
                onClick={() => downloadFile(`${process.env.REACT_APP_BASE_URL}/Drilling_Summary_Template.xlsx`, 'template')}  
              />
              {isDetailMode && detailDrillingData?.status !== 'Draft' && (
                <ButtonAccent 
                  className='mr-3'
                  isBordered 
                  size={'2xl'} 
                  title="Export Excel" 
                  isLoading={exportExcelIsLoading}
                  onClick={() => 
                    exportSummaryActualExcel({
                      id: drillingSummaryActualId, 
                      company: company, 
                      year: drillingPlanYear,
                      drillingStage: populateType(drillingPlanType)
                    })
                  }  
                />
              )}
            </div>
          )}
          <div className='flex items-center'>
            <Tag className="px-3 py-2 font-bold rounded-2xl bg-[#2BB8A4]/[0.1] text-[#01B59C] border-none">
              <span className='text-sm'>
                {
                `
                  ${drillingPlanYear} / 
                  ${company} / 
                  Drilling Actual / 
                  ${populateType(drillingPlanType)}
                `
                }
              </span>
            </Tag>
          </div>
        </div>
        {(isEditMode || isDetailMode) && detailDrillingIsFetching ?
          (
            <LoadingIcon size={'sm'}/>
          ):(
            <> 
              {populateTableActual()}
              <CategoriesList
                isActual={true}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                categories={finalCategories}
                onAdd={addRowTable}
                isDetailMode={isDetailMode}
              />
            </>
          )}
       
      </div>
      <div className="mt-5 flex flex-row items-center">
        {
          !isDetailMode && detailDrillingData?.status !== 'Submitted' && (
            <ButtonAccent isBordered title="Save As Draft" isLoading={postIsLoading} onClick={() => onSubmit(true)} />
          )}
        <div className="ml-auto flex flex-row items-center gap-x-3">
          <ButtonAccent isBordered title="Back" isLoading={postIsLoading} onClick={handleBackButton} />
          {!isDetailMode && 
            <ButtonAccent title="Next" isLoading={postIsLoading} onClick={() => onSubmit(false)} />
          }
        </div>
      </div>
      <ModalInformation
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        errorMessage={
          !isEmpty(transformErrorUpload) ?
            transformErrorUpload?.invalid_on?.map((v, i) => {
              return (<div>
                <span>{`${i + 1}. Invalid data at row ${v.row}`}</span>
                <br />
                <span>{`and column ${v.column}`}</span>
              </div>);
            })
            : transformError(errorExcel).message
        }
      />
      <ModalUpload 
        showModal={showModalUpload} 
        setShowModal={setShowModalUpload} 
        plan_id={drillingPlanId} 
        setPayload={setPayload}
        payload={payload}
      />
    </div>
  );
};

export default DrillingSummaryActual;
