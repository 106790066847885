const generateInitialReconciliationData = () => ({
  sample_information: [
    {
      sample_id: '',
      date: '',
      block: '',
      location: '',
      month: '',
      coal_seam: '',
      easting: '',
      northing: '',
      act_floor: '',
      act_roof: '',
      model_floor: '',
      model_roof: '',
      var_floor: '',
      var_roof: '',
    },
  ],
  'true_thickness_&_percentage': [
    {
      model_thk: '',
      insitu_thk: '',
      var_thk: '',
      seam_percentage: ''
    },
  ],
  tm: [
    {
      model_tm: '',
      insitu_tm: '',
      var_tm: ''
    },
  ],
  moist: [
    {
      model_moist: '',
      insitu_moist: '',
      var_moist: ''
    },
  ],
  vm: [
    {
      model_vm: '',
      insitu_vm: '',
      var_vm: ''
    },
  ],
  ash: [
    {
      model_ash: '',
      insitu_ash: '',
      var_ash: ''
    },
  ],
  fc: [
    {
      model_fc: '',
      insitu_fc: '',
      var_fc: ''
    },
  ],
  ts: [
    {
      model_ts: '',
      insitu_ts: '',
      var_ts: ''
    },
  ],
  cv: [
    {
      model_cv: '',
      insitu_cv: '',
      var_cv: ''
    },
  ],
  remarks:[
    {
      remarks: ''
    }
  ]
});

const generateBody = (payload) => {
  const content = {
    code: 'reconciliation',
    properties: Object.entries(payload.payload).map(([key, value]) => {
      let tempValue = {...value[0]};

      if (key !== 'sample_information') {
        delete tempValue.sample_id;
      }
      return {
        [key]: Object.entries(tempValue).map(([cKey, _]) => {
          return {
            [cKey]: value.map((v, index) => {
              return {
                index: index,
                value: v[cKey]
              };
            })
          };
        })
      };
    })
  };

  const company = {
    id: payload.company.id,
    alias_name: payload.company.alias_name,
    name: payload.company.name
  };

  const formData = new FormData();
  formData.append('files', payload.files ? payload.files : []);
  formData.append('year', payload.year);
  formData.append('company', JSON.stringify(company));
  formData.append('content', JSON.stringify(content));
  formData.append('is_draft', payload.is_draft);
  formData.append('file_info', payload.file_info ? '' : JSON.stringify(payload.file_info));

  return formData;
};

export { generateInitialReconciliationData, generateBody };