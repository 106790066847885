import ButtonAccent from 'components/ButtonAccent';
import CategoriesList from 'components/CategoriesList';
import { categories, drillHoleName } from 'constant/TableDrillingSummary';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { getPathType } from 'utils/PathUtility';
import { useTableWrapper } from 'utils/TableResponsive';
import DrillingHoleInformation from './DrillingHoleInformation';
import DrillingDate from './DrillingDate';
import DrillingHoleLocation from './DrillingHoleLocation';
import TimeDepthAnalysis from './Time&DepthAnalysis';
import { useLazyGetPlanDetailByIdQuery } from 'api/DrillingPlan';
import { toast } from 'react-toastify';
import { transformError } from 'utils/ErrorTransformer';
import DrillingDepth from './DrillingDepth';
import InputFileAccent from 'components/InputFileAccent';
import DrillingMachine from './DrillingMachine';
import { generateDataDrillingHoleInformation } from 'constant/TableDrillingSumarry/TableDrillingHoleInformation';
import { Image, Tag } from 'antd';
import { generateDrillingDateData } from 'constant/TableDrillingSumarry/TableDrillingDate';
import { generateDrillingHoleLocationData } from 'constant/TableDrillingSumarry/TableDrillingHoleLocation';
import { generateDrillingDepthData } from 'constant/TableDrillingSumarry/TableDrillingDepth';
import { generateDrillingTimeDepthAnalysisData } from 'constant/TableDrillingSumarry/TableTimeDepthAnalysis';
import { generateDrillingMachineData } from 'constant/TableDrillingSumarry/TableDrillingMachine';
import { useLazyGetDetailDrillingSummaryByIdQuery, usePostSummaryMutation } from 'api/DrillingSummary';
import { useModalConfirmationContext } from 'components/ModalConfirmationProvider';
import { URL } from 'constant';
import ModalInformation from '../components/ModalInformation';
import { isEmpty } from 'lodash';
import PreventNavigation from 'constant/PreventNavigation';
import LoadingIcon from 'components/LoadingIcon';

const DrillingSummary = () => {
  const [selectedCategory, setSelectedCategory] = useState(
    'Drill Hole Information'
  );
  const location = useLocation();
  const navigate = useNavigate();
  const { wrapperWidth } = useTableWrapper();
  const isDetailMode = getPathType(location) === 'detail';
  const drillingPlanId = new URLSearchParams(location.search).get('id');
  const drillingSummaryVersion = new URLSearchParams(location.search).get(
    'version'
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [file, setFile] = useState(null);
  const [data, setData] = useState(null);
  const finalCategories = categories.filter(category => 
    category !== 'Drilling Evidence' && 
    category !=='Drilling Samples Information' && 
    category !=='OME' &&
    category !=='Remark'
  );
  const {showModal, resetModal} = useModalConfirmationContext();
  const [errorExcel, setErrorExcel] = useState(null);
  const [transformErrorUpload, setTransformErrorUpload] = useState(null);

  const populateColumns = () => {
    switch (selectedCategory) {
      case 'Drill Hole Information':
        return <DrillingHoleInformation drillingHoleInformationData={generateDataDrillingHoleInformation(data)} />;
      case 'Drill Hole Location':
        return <DrillingHoleLocation drillHoleName={drillHoleName} drillingHoleLocationData={generateDrillingHoleLocationData(data)}  />;
      case 'Drilling Date':
        return <DrillingDate drillHoleName={drillHoleName} drillingDateData={generateDrillingDateData(data)} />;
      case 'Time & Depth Analysis':
        return <TimeDepthAnalysis drillHoleName={drillHoleName} drillingDepthAnalysis={generateDrillingTimeDepthAnalysisData(data)} />;
      case 'Drilling Depth':
        return <DrillingDepth drillHoleName={drillHoleName} drillingDepthData={generateDrillingDepthData(data)}/>;
      case 'Drilling Machine(RIG)':
        return <DrillingMachine drillHoleName={drillHoleName} drillingMachinehData={generateDrillingMachineData(data)} />;
      default:
        break;
    }
  };

  const handleDeleteFile = () => {
    setData(null);
    setFile(null);
    setShowPreview(false);
  };

  const handleBackButton = () => {
    if(isDetailMode) {
      navigate(`/${URL.ACTIVITY_LEVEL}/${URL.GEOLOGY_OE}/drilling-plan`);
    } else {
      showModal({
        isShown: true,
        type: 'confirmation',
        title:'Are you sure want to leave this page?',
        message: 'Changes you made may not be saved',
        onSubmit: () => {
          navigate(`/${URL.ACTIVITY_LEVEL}/${URL.GEOLOGY_OE}/drilling-plan`);
          resetModal();
        }
      });
    }
  };

  const [
    getPlanDetailById,
    {
      data: drillingPlanData,
      isFetching: drillingPlanIsFetching,
      isError: drillingPlanDataIsError,
      error: drillingPlanDataError,
    },
  ] = useLazyGetPlanDetailByIdQuery({ refetchOnMountOrArgChange: true });

  const [
    getDetailDrillingSummaryById,
    {
      data: drillingSummaryData,
      isFetching: drillingSummaryIsFetching,
      isError: drillingSummaryDataIsError,
      error: drillingSummaryDataError,
    },
  ] = useLazyGetDetailDrillingSummaryByIdQuery({ refetchOnMountOrArgChange: true });

  const version = drillingPlanData?.latest_version;
  const year = drillingPlanData?.year;
  const company = drillingPlanData?.company.alias_name;

  useEffect(() => {
    if (drillingPlanId) {
      getPlanDetailById({ idPlan: drillingPlanId });
    }
    if(isDetailMode && drillingPlanId && drillingSummaryVersion) {
      getDetailDrillingSummaryById({drillingPlanId: drillingPlanId, version: drillingSummaryVersion});
    }
  }, [drillingPlanId]);

  useEffect(() => {
    if (drillingPlanDataIsError) {
      toast.error(transformError(drillingPlanDataError).message);
    }
  }, [drillingPlanDataIsError]);

  const [postSummary, {
    isLoading: postIsLoading,
    isSuccess: postIsSuccess,
    isError: postIsError,
    error: postError
  }] = usePostSummaryMutation();

  const handleNextButton = () => {
    let generateContent = {};
    Object.keys(data)
      .filter(key => !['file_info'].includes(key))
      .map(key => generateContent[key] = data[key]);
    
    const body = new FormData();
    body.append('files', file);
    body.append('content', JSON.stringify(generateContent));
    body.append('version', drillingSummaryVersion);

    postSummary({
      id: drillingPlanId,
      body
    });
  };

  useEffect(() => {
    if (postIsSuccess) {
      toast.success(
        'You have successfully submitted drilling summary',
        { toastId: 'post-quality-toast-success' }
      );
      
      navigate(`/${URL.ACTIVITY_LEVEL}/${URL.GEOLOGY_OE}/drilling-plan`);
    }
  }, [postIsSuccess]);

  useEffect(() => {
    if (postIsError || drillingSummaryDataIsError) {
      const generateToastId = () => {
        switch (true) {
          case postIsError:
            return 'post-summary';
          case drillingSummaryDataIsError:
            return 'get-drilling-summary-detail';
          default:
            return 'default';
        }
      };
      
      toast.error(
        transformError(postError || drillingSummaryDataError).message,
        { toastId: `${generateToastId()}-toast-error` }
      );
    }
  }, [
    postIsError,
    drillingSummaryDataIsError
  ]);

  const jsonParseMessage = () => {
    try {
      return JSON.parse(errorExcel.data.error.message);;  
    } catch{
      return null;
    }
  };
  
  useEffect(() => {
    if (!isEmpty(errorExcel)) {
      setTransformErrorUpload(null);

      if (errorExcel.data) {
        const transformErrorUpload = errorExcel.status <= 400 ? jsonParseMessage(errorExcel.data.error.message) : null;
        setTransformErrorUpload(transformErrorUpload);
      }

      setIsModalOpen(true);
    }
  }, [errorExcel]);

  const downloadFile = (file, type) => {
    const docLink = document.createElement('a');
    docLink.href = type? file: file?.url;

    document.body.appendChild(docLink);
    docLink.click();
    document.body.removeChild(docLink);
  };


  useEffect(() => {
    if(!isEmpty(drillingSummaryData) && isDetailMode){
      setData(drillingSummaryData?.content);
      setFile(drillingSummaryData?.file_info);
    } 
  }, [drillingSummaryData]);

  useEffect(()=>{
    if(isDetailMode && !isEmpty(data)){
      setShowPreview(true);
    }
  },[data]);

  const handleCloseSite = useCallback((ev) => ev.preventDefault() ,[]);
  
  useEffect(() => {
    PreventNavigation(isDetailMode, handleCloseSite);
  }, []);


  return (
    <div className="flex flex-col gap-4">
      <div
        className="bg-white p-5 overflow-x-auto rounded-2xl"
        style={{ maxWidth: wrapperWidth }}
      >
        <div className=" mb-8 flex flex-row justify-between">
          <div className=" text-[18px] font-bold">{isDetailMode ? 'Download Data' :'Upload Data'}</div>
        </div>
        <InputFileAccent
          fileType={
            '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
          }
          onChange={isDetailMode ? () => {} : setData}
          id={drillingPlanId}
          isDetailMode={isDetailMode}
          getFile={setFile}
          value={isDetailMode? file : null}
          getErrorExcel={setErrorExcel}
          onDrop={isDetailMode ? () => {} : setData}
          onDelete={handleDeleteFile}
          onPreview={isDetailMode ? () => downloadFile(file) : () => setShowPreview((prev) => !prev)}
          buttonTitle={isDetailMode? 'Download File': 'Preview'}
          noTitle={true}
        />
      </div>
      <div
        className="bg-white p-5 overflow-x-auto rounded-2xl"
        style={{ maxWidth: wrapperWidth }}
      >
        <div className=" mb-8 flex flex-row justify-between">
          <ButtonAccent size={'2xl'} title="Download Template" 
            onClick={() => downloadFile(`${process.env.REACT_APP_BASE_URL}/Drilling_Summary_Template.xlsx`, 'template')}  
          />
          <Tag className={`px-3 py-2 font-bold rounded-2xl bg-[#2BB8A4]/[0.1] text-[#01B59C] border-none ${
            drillingPlanIsFetching && 'animate-pulse animate-infinite'
          }`}>
            <span className='text-sm'>
              {drillingPlanIsFetching
                ? 'Loading...'
                : `V${
                  isDetailMode ? drillingSummaryVersion : version
                } / ${year} / ${company} / Drilling Plan`}
            </span>
          </Tag>
        </div>
        <>
          {isDetailMode && drillingSummaryIsFetching ? (
            <LoadingIcon size={'sm'} /> 
          ) : (
            showPreview ? (
              <>
                {populateColumns()}
                <CategoriesList
                  isActual={false}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  categories={finalCategories}
                />
              </>
            ) : (
              <div className="flex flex-col justify-center items-center mb-10">
                <Image
                  preview={false}
                  width={100}
                  src={`${process.env.PUBLIC_URL}/assets/icon-empty-data.svg`}
                  className="rounded-lg mb-8"
                />
                <div className="text-[18px] font-[Segoe-UI-Bold] font-bold">
              No Data Displayed
                </div>
                <div className='text-[14px]'>The data is not available at the moment. Please upload the neccesary data to populate the information</div>
              </div>
            )
          )}
        </>
      </div>
      
      <div className="mt-5 flex flex-row items-center">
        <div className="ml-auto flex flex-row items-center gap-x-3">
          <ButtonAccent isBordered title="Back" onClick={() => handleBackButton()} />
          {
            !isDetailMode && (
              <ButtonAccent isLoading={postIsLoading} isDisabled={!Boolean(file)} title="Next" onClick={() => handleNextButton()} />
            )
          }
        </div>
      </div>
      <ModalInformation
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        errorMessage={
          !isEmpty(transformErrorUpload) ?
            transformErrorUpload?.invalid_on?.map((v, i) => {
              return v?.drill_hole_name ? (
                <div>
                  <span>{`${i + 1}. Invalid data on drill hole name ${v?.drill_hole_name}`}</span>
                  <br />
                  <span>{`on combination area ${v?.area} and pit location ${v?.pit_location}`}</span>
                </div>
              ) : (
                <div>
                  <span>{`${i + 1}. Invalid data at row ${v.row}`}</span>
                  <br />
                  <span>{`and column ${v.column}`}</span>
                </div>
              );
            })
            : transformError(errorExcel).message
        }
      />
    </div>
  );
};

export default DrillingSummary;
