import { isEmpty } from 'lodash';
import { RxCross2 } from 'react-icons/rx';
import { InputNumber } from 'antd';
import { calculateVariance } from './TableTrueThicknessPercentage';
import { EMPTY } from 'constant';

const generateTmTable = (
  payload,
  isDetailMode,
  onChange,
  onDeleteRow
) => [
  {
    dataIndex: 'action',
    title: 'Action',
    width: 5,
    sorter: false,
    align: 'center',
    fixed: 'left',
    render: (_, __, index) => {
      return (
        <div className="flex justify-center">
          {payload.length <= 1 ? (
            <></>
          ) : (
            <RxCross2
              size={28}
              color={`${isDetailMode? 'gray' : 'red'}`}
              className={`rounded-full p-1 ${isDetailMode? 'bg-gray-200':'cursor-pointer bg-red-200 '}`}
              onClick={() => onDeleteRow(index)}
            />
          )}
        </div>
      );
    },
  },
  {
    title: 'TM',
    width: '166px',
    align: 'center',
    sorter: false,
    children:[ 
      {
        dataIndex: 'model_tm',
        title: 'Model',
        width: '196px',
        sorter: false,
        align: 'center',
        render: (value, record, index) => {
          return (
            <InputNumber
              disabled={isDetailMode}
              className='w-full'
              placeholder={'Enter model'}
              type='number'
              value={value}
              onChange={(val)=>{
                onChange(index, 'tm', 'model_tm' ,val);
                calculateVariance(record.insitu_tm, val, index, onChange, 'tm', 'var_tm');
              }}
              status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
            /> 
          );
        },
      },
      {
        dataIndex: 'insitu_tm',
        title: 'Insitu',
        width: '196px',
        sorter: false,
        align: 'center',
        render: (value, record, index) => {
          return (
            <InputNumber
              disabled={isDetailMode}
              className='w-full'
              placeholder={'Enter insitu'}
              value={value}
              type='number'
              onChange={(val)=>{
                onChange(index, 'tm', 'insitu_tm' ,val);
                calculateVariance(record.model_tm, val, index, onChange, 'tm', 'var_tm');
              }}
              status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
            /> 
          );
        },
      },
      {
        dataIndex: 'var_tm',
        title: 'Variance',
        width: '196px',
        sorter: false,
        align: 'center',
        render: (value) => {
          return (
            <div className='flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
              { value }
            </div>
          );
        },
      },
    ]
  }
];

const generateTmData = (file) => {
  if(!isEmpty(file)) {
    const dataSampleInformation = file.properties[0].sample_information;
    const totalData = dataSampleInformation[0].sample_id.length;
    const data = file.properties[2].tm;
    const result = [];

    for(let i = 0; i < totalData; i++){
      result.push({
        sample_id: dataSampleInformation[0].sample_id[i]?.value,
        model_tm: data[0].model_tm[i]?.value,
        var_tm: data[1].var_tm[i].value,
        insitu_tm: data[2].insitu_tm[i]?.value,
      });
    }
    return result;
  }
};

export { generateTmData, generateTmTable };