import { DatePicker, InputNumber } from 'antd';
import { EMPTY } from 'constant';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { RxCross2 } from 'react-icons/rx';

const generateDrillingSamplesInformationTable = (
  payload,
  isDetailMode,
  onChange,
  onDeleteRow
) => [
  {
    dataIndex: 'action',
    title: 'Action',
    width: 5,
    sorter: false,
    align: 'center',
    fixed: 'left',
    render: (_, __, index) => {
      return (
        <div className="flex justify-center">
          {payload.length <= 1 ? (
            <></>
          ) : (
            <RxCross2
              size={28}
              color={`${isDetailMode? 'gray' : 'red'}`}
              className={`rounded-full p-1 ${isDetailMode? 'bg-gray-200':'cursor-pointer bg-red-200 '}`}
              onClick={() => onDeleteRow(index)}
            />
          )}
        </div>
      );
    },
  },
  {
    dataIndex: 'lab_complete_date',
    title: 'Lab Complete Date',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, _, index) => {
      return (
        <DatePicker
          allowClear
          value={value? dayjs(value,'DD/MM/YYYY'):''}
          format={'DD/MM/YYYY'}
          disabled={isDetailMode}
          placeholder="dd/mm/yyyy"
          className="w-full text-left"
          onChange={(val)=>{
            onChange(index, 'drilling_samples_information', 'lab_complete_date' , dayjs(val).format('DD/MM/YYYY'));
          }}
          status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
        />
      );
    },
  },
  {
    dataIndex: 'number_of_coal_sample',
    title: 'Number of Coal Sample',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, _, index) => {
      return (
        <InputNumber
          disabled={isDetailMode}
          className='w-full'
          placeholder={'Enter number of coal sample'}
          value={value}
          onChange={(val)=>{
            onChange(index, 'drilling_samples_information', 'number_of_coal_sample' ,val);
          }}
          status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
        /> 
      );
    },
  },
  {
    dataIndex: 'number_of_amd_sample',
    title: 'Number of AMD Sample',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, _, index) => {
      return (
        <InputNumber
          disabled={isDetailMode}
          className='w-full'
          placeholder={'Enter number of amd sample'}
          value={value}
          onChange={(val)=>{
            onChange(index, 'drilling_samples_information', 'number_of_amd_sample' ,val);
          }}
        /> 
      );
    },
  },
  {
    dataIndex: 'number_of_geotech_sample',
    title: 'Number of Geotech Sample',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, _, index) => {
      return (
        <InputNumber
          disabled={isDetailMode}
          className='w-full'
          placeholder={'Enter number of geotech sample'}
          value={value}
          onChange={(val)=>{
            onChange(index, 'drilling_samples_information', 'number_of_geotech_sample' ,val);
          }}
        /> 
      );
    },
  }
];

const generateDrillingSamplesInformationActualData = (file)=>{
  if(!isEmpty(file)) {
    const dataDrillHoleName = file.properties[0].drill_hole_information[0].drill_hole_name;
    const data = file.properties?.[6]?.drilling_samples_information || file.properties?.[6]?.drilling_sample_information;
    let finalResult = dataDrillHoleName.map((drillHoleName, index) => {
      return data.reduce((result, currentObject, indexObject) => {
        const key = Object.keys(currentObject)[0];
        const value = currentObject[key][index]?.value;
        result[key] = value;
        if(indexObject === data?.length -1){
          result['drill_hole_name'] = drillHoleName?.value;
        }
        return result;
      }, {});
    });
    return finalResult;
  }
};

export { generateDrillingSamplesInformationTable, generateDrillingSamplesInformationActualData };
