import { isEmpty, isNumber } from 'lodash';
import { RxCross2 } from 'react-icons/rx';
import { InputNumber } from 'antd';
import { actualRateFormula, drillingRateByFinalLogFormula, speedFormula } from './TableDrillingDateActual';
import { EMPTY } from 'constant';
import { calculateCategoryQLoss, calculatePercentOme, calculatePercentQ } from './TableOme';

const generateDrillingDepthTable = (
  payload,
  isDetailMode,
  onChange,
  onDeleteRow,
  planData,
  otherData
) => [
  {
    dataIndex: 'action',
    title: 'Action',
    width: 5,
    sorter: false,
    align: 'center',
    fixed: 'left',
    render: (_, record, index) => {
      return (
        <div className="flex justify-center">
          {payload.length <= 1 ? (
            <></>
          ) : (
            <RxCross2
              size={28}
              color={`${isDetailMode? 'gray' : 'red'}`}
              className={`rounded-full p-1 ${isDetailMode? 'bg-gray-200':'cursor-pointer bg-red-200 '}`}
              onClick={() => onDeleteRow(index)}
            />
          )}
        </div>
      );
    },
  },
  {
    dataIndex: 'actual_drilling_depth',
    title: 'Actual Drilling Depth',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, record, index) => {
      return (
        <InputNumber
          disabled={isDetailMode}
          className='w-full'
          placeholder={'Enter actual drilling depth'}
          formatter={(value) => value ? `${parseFloat(value).toFixed(2)}` : ''}
          value={value}
          onChange={(val)=>{
            onChange(index, 'drilling_depth', 'actual_drilling_depth' ,val);
            onChange(index, 'drilling_date', 'actual_rate', actualRateFormula(index, otherData));
            onChange(index, 'drilling_date', 'drilling_rate_by_final_log', drillingRateByFinalLogFormula(index, otherData));
            onChange(index, 'drilling_date', 'speed', speedFormula(index, otherData, planData, record));
            calculateDepthDeviation(record, val, planData, onChange, index, otherData);
            onChange(index, 'ome', 'category_q_loss', 
              calculateCategoryQLoss(index, planData, otherData)
            );
          }}
          status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
        /> 
      );
    },
  },
  {
    dataIndex: 'geophysical_depth',
    title: 'Geophysical Depth',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, record, index) => {
      return (
        <InputNumber
          disabled={isDetailMode}
          className='w-full'
          placeholder={'Enter geophysical depth'}
          formatter={(value) => value ? `${parseFloat(value).toFixed(2)}` : ''}
          value={value}
          onChange={(val)=>{
            onChange(index, 'drilling_depth', 'geophysical_depth' ,val);
            calculateDepthRecovery(record, val, onChange, index);
          }}
          status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
        /> 
      );
    },
  },
  {
    dataIndex: 'vertical_depth',
    title: 'Vertical Depth',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, _, index) => {
      return (
        <InputNumber
          disabled={isDetailMode}
          className='w-full'
          placeholder={'Enter vertical depth'}
          formatter={(value) => value ? `${parseFloat(value).toFixed(2)}` : ''}
          value={value}
          onChange={(val)=>{
            onChange(index, 'drilling_depth', 'vertical_depth' ,val);
          }}
          status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
        /> 
      );
    },
  },
  {
    dataIndex: 'invoice_depth',
    title: 'Invoice Depth',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, _, index) => {
      return (
        <InputNumber
          disabled={isDetailMode}
          className='w-full'
          placeholder={'Enter invoice depth'}
          formatter={(value) => value ? `${parseFloat(value).toFixed(2)}` : ''}
          value={value}
          onChange={(val)=>{
            onChange(index, 'drilling_depth', 'invoice_depth' ,val);
          }}
          status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
        /> 
      );
    },
  },
  {
    dataIndex: 'total_oh',
    title: 'Total OH',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, _, index) => {
      return (
        <InputNumber
          disabled={isDetailMode}
          className='w-full'
          placeholder={'Enter total oh'}
          formatter={(value) => value ? `${parseFloat(value).toFixed(2)}` : ''}
          value={value}
          onChange={(val)=>{
            onChange(index, 'drilling_depth', 'total_oh' ,val);
          }}
        /> 
      );
    },
  },
  {
    dataIndex: 'total_core',
    title: 'Total Core',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, _, index) => {
      return (
        <InputNumber
          disabled={isDetailMode}
          className='w-full'
          placeholder={'Enter total core'}
          formatter={(value) => value ? `${parseFloat(value).toFixed(2)}` : ''}
          value={value}
          onChange={(val)=>{
            onChange(index, 'drilling_depth', 'total_core' ,val);
          }}
        /> 
      );
    },
  },
  {
    dataIndex: 'depth_deviation',
    title: 'Depth Deviation (m)',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, record) => {
      return (
        <div className='flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {isNumber(value) ?  Number(value).toFixed(2): ''}
        </div>
      );
    },
  },
  {
    dataIndex: 'depth_recovery',
    title: '% Depth Recovery',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      const populateValue= ()=>{
        if(isNumber(value)){
          if(Number(value) === value && value % 1 !== 0){
            return `${value.toFixed(0)}%`;
          }else {
            return `${value}%`;
          }
        }
        return value;
      };
      return (
        <div className='flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {populateValue()}
        </div>
      );
    },
  },
];

const generateDrillingDepthActualData = (file) => {
  if(!isEmpty(file)) {
    const dataDrillHoleName = file.properties[0].drill_hole_information[0].drill_hole_name;
    const data = file.properties[3].drilling_depth;
    let finalResult = dataDrillHoleName.map((drillHoleName, index) => {
      return data.reduce((result, currentObject, indexObject) => {
        const key = Object.keys(currentObject)[0];
        const value = currentObject[key][index]?.value;
        result[key] = value;
        if(indexObject === data?.length -1){
          result['drill_hole_name'] = drillHoleName?.value;
        }
        return result;
      }, {});
    });
    return finalResult;
  }
};

const calculateDepthDeviation = (record, value, planData, onChange, index, otherData) => {
  let totalDepthDeviation = 0;
  if (!isEmpty(planData)) {
    const planDrillingDepthData = planData.find(v => v.drill_hole_name === record.drill_hole_name).plan_drilling_depth;
    totalDepthDeviation = planDrillingDepthData - value;
  }

  onChange(index, 'drilling_depth', 'depth_deviation' ,totalDepthDeviation);
  onChange(index, 'ome', 'q', calculatePercentQ(index, planData, otherData));
  onChange(index, 'ome', 'ome', calculatePercentOme(index, otherData));
  if (record.geophysical_depth) {
    calculateDepthRecovery(record, record.geophysical_depth, onChange, index, value);
  }
};

const calculateDepthRecovery = (record, value, onChange, index, actualDrillingDepth) => {
  let totalDepthRecovery = 0;
  if (record.actual_drilling_depth) {
    totalDepthRecovery = Number(value / (actualDrillingDepth || record.actual_drilling_depth) * 100);
  }

  onChange(index, 'drilling_depth', 'depth_recovery' ,totalDepthRecovery);
};

export { generateDrillingDepthTable, generateDrillingDepthActualData  };
