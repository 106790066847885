import moment from 'moment';
import { FiTrash } from 'react-icons/fi';
import { GiPencil } from 'react-icons/gi';
import { isEmpty, toLower } from 'lodash';

import { UNIT } from 'constant';
import { generateFormat } from 'utils/PeriodUtility';

const tableColumns = (
  isDetailMode,
  onEdit,
  onDelete
) => [
  {
    dataIndex: 'scenario',
    title: 'Scenario',
    render: (text) => {
      const createMarkdown = () => {
        return { __html: text };
      };
      
      return (
        <div dangerouslySetInnerHTML={createMarkdown()} />
      );
    }
  },
  {
    title: 'Action',
    render: (text, record) => (
      <>
        {
          isDetailMode
            ? (<></>)
            : (
              <div className="flex flex-row items-center gap-x-5">
                <div
                  className="p-1 bg-[#2D3D5A] rounded-full cursor-pointer"
                  onClick={() => onEdit(record)}
                >
                  <GiPencil className="text-white" />
                </div>
                <div
                  className="p-1 bg-pink-700 rounded-full cursor-pointer"
                  onClick={() => onDelete(record)}
                >
                  <FiTrash className="text-white" />
                </div>
              </div>
            )
        }
      </>
    )
  }
];

const generateTableData = (unit, periods, periodName, data) => {
  const loweredUnit = toLower(unit);
  const isQuarterly = toLower(periodName) === 'quarterly';

  if (isEmpty(data)) {
    return [];
  }

  const selectedAttachment = data.contents.properties.find((item) => item.code === 'attachment');
  const selectedScenario = data.contents.properties.find((item) => item.code === 'scenario');
  
  if (!isEmpty(selectedAttachment) && !isEmpty(selectedScenario)) {
    if (isQuarterly) {
      const formatForDataIndex = (item) => moment(
        item,
        generateFormat(periods, loweredUnit, true, false)
      ).format(generateFormat(periods, loweredUnit, false, true));

      const findSelectedItem = (period, data) => {
        const selectedData = data.properties.find((propItem) => moment(
          moment(
            period,
            generateFormat(periods, loweredUnit, true, false)
          ).format('DD-MM-YYYY'),
          'DD-MM-YYYY'
        ).isSame(
          moment(
            propItem.date,
            'DD-MM-YYYY'
          ),
          'month'
        ));

        if (!isEmpty(selectedData)) {
          return selectedData.value;
        }

        return null;
      };
      
      const generateObjectValue = (data) => {
        const newItem = {};

        periods.forEach((period) => {
          newItem[formatForDataIndex(period)] = findSelectedItem(period, data);
        });

        return !isEmpty(newItem)
          ? newItem
          : null;
      };

      return {
        attachment: generateObjectValue(selectedAttachment),
        scenario: generateObjectValue(selectedScenario)
      };
    }
    
    return {
      attachment: selectedAttachment.properties[0],
      scenario: selectedScenario.properties[0]
    };
  }
  
  return null;
};

const generateBody = (
  isDraft,
  id,
  unit,
  periodName,
  periods,
  file,
  scenarios,
  quarterlyData
) => {
  const isQuarterly = toLower(periodName) === 'quarterly';
  const momentDatePeriod = (unit, item) => {
    return moment(item, toLower(unit) === UNIT.DAY ? 'DD MMM YYYY' : 'MMM YYYY')
      .format('DD-MM-YYYY');
  };
  
  const selectQuarterlyFile = (period) => {
    const selectedData = quarterlyData.find((item) => moment(
      moment(period, 'MMM YYYY').format('YYYY-MM-DD'),
      'YYYY-MM-DD'
    ).isSame(
      moment(item.key, 'MMM_YYYY').format('YYYY-MM-DD'),
      'day'
    ));

    if (selectedData) {
      return selectedData.file;
    }

    return null;
  };

  const selectQuarterlyScenarios = (period) => {
    const selectedData = quarterlyData.find((item) => moment(
      moment(period, 'MMM YYYY').format('YYYY-MM-DD'),
      'YYYY-MM-DD'
    ).isSame(
      moment(item.key, 'MMM_YYYY').format('YYYY-MM-DD'),
      'day'
    ));

    if (selectedData) {
      return selectedData.scenarios.map((item) => item.scenario);
    }

    return [];
  };

  return {
    assumption_id: id,
    is_draft: isDraft,
    contents: {
      code: 'mp_scenario',
      properties: [
        {
          code: 'attachment',
          properties: periods.map((period) => ({
            type: periodName,
            date: momentDatePeriod(unit, period),
            value: isQuarterly
              ? selectQuarterlyFile(period)
              : file
          }))
        },
        {
          code: 'scenario',
          properties: periods.map((period) => ({
            type: periodName,
            date: momentDatePeriod(unit, period),
            value: isQuarterly
              ? selectQuarterlyScenarios(period)
              : scenarios.map((item) => item.scenario)
          }))
        }
      ]
    }
  };
};

export {
  tableColumns,
  generateTableData,
  generateBody
};