import moment from 'moment';
import { toLower } from 'lodash';

import { UNIT } from 'constant';

const convertPeriodToTags = (unit, startDate, endDate) => {
  const rangeDates = [];
  const start = moment(startDate);
  const end = moment(endDate);

  if (!unit || !startDate || !endDate) {
    return rangeDates;
  }
  
  while (start.isSameOrBefore(end)) {
    rangeDates.push(
      start.format(toLower(unit) === UNIT.DAY
        ? 'DD MMM YYYY'
        : 'MMM YYYY')
    );
    start.add(1, toLower(unit) === UNIT.DAY ? 'days' : 'months');
  }

  return rangeDates;
};

const generateFormat = (periods, unit, isSource, isIndexFormat) => {
  if (Array.isArray(periods)) {
    switch (true) {
      case isSource:
        return unit === UNIT.DAY
          ? 'DD MMM YYYY'
          : 'MMM YYYY';
      case isIndexFormat:
        return unit === UNIT.DAY
          ? 'DD_MMM_YYYY'
          : 'MMM_YYYY';
      default:
        return unit === UNIT.DAY
          ? 'DD MMM'
          : 'MMM';
    }
  }

  switch (true) {
    case isSource:
      return 'MMM YYYY';
    case isIndexFormat:
      return 'MMM_YYYY';
    default:
      return 'MMM';
  }
};

export {
  convertPeriodToTags,
  generateFormat
};