import { generateDrillingDateTable } from 'constant/TableDrillingSumarry/TableDrillingDate';
import TableDrillingSumarry from '../TableDrillingSummary';

const DrillingDate = (props) => {
  const { drillHoleName, drillingDateData } = props;
  const columns = generateDrillingDateTable();
  columns.unshift(drillHoleName);
  
  return <TableDrillingSumarry columns={columns} tableData={drillingDateData} />;
};

export default DrillingDate;