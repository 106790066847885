import { useLocation, useNavigate } from 'react-router';
import { useState, useRef, useEffect } from 'react';
import { createSearchParams } from 'react-router-dom';
import {
  Form,
  Table,
  Image
} from 'antd';
import { isEmpty, toLower } from 'lodash';
import {
  getPathType, 
  getFullPath, 
  shouldRedirect 
} from 'utils/PathUtility';
import { useGetAssumptionByIdQuery } from 'api/Assumption';
import { useGetLossTimeByIdQuery } from 'api/Production';
import { useGetHourCapacityByIdQuery } from 'api/HourCapacity';
import { useGetUnitByIdQuery } from 'api/Unit';
import { generateFormat } from 'utils/PeriodUtility';
import { EMPTY, UNIT, URL } from 'constant';
import { TYPE } from 'constant/MasterData';
import moment from 'moment';
import {
  generateHeaderColumns,
  generateHeaderData,
  generateBody,
} from 'constant/TableOverBurdenHauler';
import { convertPeriodToTags } from 'utils/PeriodUtility';
import { toast } from 'react-toastify';
import { transformError } from 'utils/ErrorTransformer';
import { useAssumptionManager } from 'utils/AssumptionManager';

import Toast from 'components/Toast';
import TableRaw from 'components/TableRaw';
import BannerPitPeriod from 'components/BannerPitPeriod';
import ButtonAccent from 'components/ButtonAccent';
import ModalTypeTruck from './ModalTypeTruck';
import { useModalConfirmationContext } from 'components/ModalConfirmationProvider';
import { useLazyGetMasterDataTrucksQuery } from 'api/MasterData';
import {
  usePostOverBurdenHaulerMutation,
  useLazyGetOverBurdenHaulerByIdQuery,
  usePutOverBurdenHaulerMutation,
  useLazyGetLatestOverBurdenHaulerQuery
} from 'api/OverBurdenHauler';
import LoadingText from 'components/LoadingText';

const OverBurdenHauler = () => {
  const [form] = Form.useForm();
  const loc = useLocation();
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState();
  const [headerColumns, setHeaderColumns] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [activeTableTab, setActiveTableTab] = useState('');
  const [tabList, setTabList] = useState([]);

  const { showModal, resetModal } = useModalConfirmationContext();
  const {
    createBackQueryParams,
    isPathHasBackQueryParams,
    isPathMaxBackQueryParams
  } = useAssumptionManager();

  const isDraftRef = useRef(false);
  const isWeeklyRef = useRef(false);
  const isMonthlyRef = useRef(false);
  const periodRef = useRef([]);
  const periodNameRef = useRef(EMPTY.STRING);
  const unitRef = useRef(UNIT.MONTH);
  const lossTimeRef = useRef(null);
  const unitsRef = useRef(null);
  const obHaulerIdRef = useRef(null);
  const [truckOption, setTruckOption] = useState([]);
  const [dataTable, setDataTable] = useState({});
  const [tableIndex, setTableIndex] = useState(null);
  const [truckIndex, setTruckIndex] = useState(null);
  const [isValid, setIsValid] = useState(true);
  const [isEditTruck, setIsEditTruck] = useState(true);
  const [modalTruckForm, setModalTruckForm] = useState({
    type_truck: null,
    dump_area: '',
    distance: 0,
    maintenance_hours: 0,
    productivity: 0,
    quantity: 0
  });

  const assumptionId = new URLSearchParams(loc.search).get('id');
  const backQueries = new URLSearchParams(loc.search).getAll('isBack');
  const isBack = isPathHasBackQueryParams(loc.pathname, backQueries);
  const isCreateMode = getPathType(loc) === 'create';
  const isDetailMode = getPathType(loc) === 'detail';
  const isEditMode = getPathType(loc) === 'edit';
  const isNeedToFetchDetail = Boolean(isBack || isDetailMode || isEditMode);

  const tableColumns = [
    {
      dataIndex: 'name',
      title: 'Equipment Name',
      render: (text, record, index) => (
        <span>Overburden {index + 1}</span>
      )
    },
    { dataIndex: 'equip_type', title: 'Equip Type', sorter: true },
    { dataIndex: 'equip_no', title: 'Equip No', sorter: true },
    { 
      dataIndex: 'type_truck',
      title: 'Type Truck',
      sorter: true,
      render: (data, record, index) => (
        <>
          <div>
            {
              data.map((v, idxItem) => {
                return (
                  <div key={`key-${idxItem}-${index}`} className='flex justify-between'>
                    <div>{v.name}</div>
                    {
                      !isDetailMode &&
                      <div>
                        <Image
                          className='cursor-pointer rounded-full'
                          preview={false}
                          src={`${process.env.PUBLIC_URL}/assets/button-edit-type.svg`}
                          onClick={() => {
                            setModalTruckForm({
                              type_truck: JSON.stringify(v),
                              dump_area: record.dump_area[idxItem],
                              distance: record.distance[idxItem],
                              maintenance_hours: record.maintenance_hours[idxItem],
                              productivity: record.productivity[idxItem],
                              quantity: record.quantity[idxItem]
                            });

                            setIsEditTruck(true);
                            setTableIndex(index);
                            setTruckIndex(idxItem);

                            setOpenModal(true);
                          }}
                        />
                        <Image
                          className='cursor-pointer rounded-full'
                          preview={false}
                          src={`${process.env.PUBLIC_URL}/assets/button-delete-type.svg`}
                          onClick={() => deleteTypeTruck(index, idxItem)}
                        />
                      </div>
                    }
                  </div>
                );
              })
            }
            {
              !isDetailMode &&
              <ButtonAccent
                key={`key-${activeTableTab.codeKey}-${data.key}`}
                title="Add Truck"
                onClick={() => {
                  setIsEditTruck(false);
                  setTableIndex(index);

                  setModalTruckForm({
                    type_truck: null,
                    dump_area: null,
                    distance: null,
                    maintenance_hours: 0,
                    productivity: null,
                    quantity: null
                  });

                  setOpenModal(true);
                }}
              />
            }
          </div>
        </>
      )
    },
    { dataIndex: 'dump_area', title: 'Dump Area', sorter: true,
      render: (data) => (
        <>
          {
            data.map((v, idx) => {
              return (
                <div key={idx} className='mb-3'>{v}</div>
              );
            })
          }
        </>
      )
    },
    { dataIndex: 'distance', title: 'Distance', sorter: true,
      render: (data) => (
        <>
          {
            data.map((v, idx) => {
              return (
                <div key={idx} className='mb-3'>{Number(v).toLocaleString('en-US')}</div>
              );
            })
          }
        </>
      )
    },
    { dataIndex: 'maintenance_hours', title: 'Maintenance Hours', sorter: true,
      render: (data) => (
        <>
          {
            data.map((v, idx) => {
              return (
                <div key={idx} className='mb-3'>{Number(v).toLocaleString('en-US')}</div>
              );
            })
          }
        </>
      )
    },
    { dataIndex: 'productivity', title: 'Productivity', sorter: true,
      render: (data) => (
        <>
          {
            data.map((v, idx) => {
              return (
                <div key={idx} className='mb-3'>{Number(v).toLocaleString('en-US')}</div>
              );
            })
          }
        </>
      )
    },
    { dataIndex: 'quantity', title: 'Quantity', sorter: true,
      render: (data) => (
        <>
          {
            data.map((v, idx) => {
              return (
                <div key={idx} className='mb-3'>{Number(v).toLocaleString('en-US')}</div>
              );
            })
          }
        </>
      )
    },
    { dataIndex: 'effective_working_hour', title: 'Effective Working Hours', sorter: true,
      render: (data) => (
        <>
          {
            data.map((v, idx) => {
              return (
                <div key={idx} className='mb-3'>{Number(Number(v).toFixed(1)).toLocaleString('en-US')}</div>
              );
            })
          }
        </>
      )
    },
    { dataIndex: 'hauler_production', title: 'Hauler Production', sorter: true,
      render: (data) => (
        <>
          {
            data.map((v, idx) => {
              return (
                <div key={idx} className='mb-3'>{Number(Number(v).toFixed(1)).toLocaleString('en-US')}</div>
              );
            })
          }
        </>
      )
    },
    { dataIndex: 'excavator_production', title: 'Excavator Production', sorter: true,
      render: (data) => (
        <>
          {
            (data && data.length) 
              ? Number(Number(data[0]).toFixed(1)).toLocaleString('en-US')
              : <>0</>
          }
        </>
      )
    },
    { dataIndex: 'match_factor', title: 'Match Factor', sorter: true,
      render: (data) => (
        <>
          {
            (data && data.length) 
              ? data.reduce((accumulator, currentValue) => {
                return (parseFloat(accumulator) + parseFloat(currentValue)).toFixed(1);
              }, 0)
              : <>0</>
          }
        </>
      )
    },
  ];

  const {
    data: assumption,
    isFetching: assumptionIsFetching,
    isError: assumptionIsError,
    error: assumptionError
  } = useGetAssumptionByIdQuery(
    { id: assumptionId },
    { refetchOnMountOrArgChange: true }
  );

  const {
    data: lossTime,
    isFetching: lossTimeIsFetching,
    isError: lossTimeIsError,
    error: lossTimeError
  } = useGetLossTimeByIdQuery(
    { id: assumptionId },
    { refetchOnMountOrArgChange: true }
  );

  const {
    data: obCapacity,
    isError: obCapacityIsError,
    error: obCapacityError
  } = useGetHourCapacityByIdQuery(
    { id: assumptionId,
      params: { type: TYPE.OVER_BURDEN }
    },
    { refetchOnMountOrArgChange: true }
  );

  const [getTrucksOption, {
    isError: trucksIsError,
    error: truckError
  }] = useLazyGetMasterDataTrucksQuery(
    { refetchOnMountOrArgChange: false }
  );

  const [getObHaulerById, {
    data: obHaulerByIdData,
    isFetching: obHaulerByIdDataIsFetching,
    isError: obHaulerByIdDataIsError,
    error: obHaulerByIdDataError
  }] = useLazyGetOverBurdenHaulerByIdQuery();

  const [getLatestObHauler, {
    data: latestObHaulerData,
    isFetching: latestObHaulerDataIsFetching,
    isError: latestObHaulerDataIsError,
    error: latestObHaulerDataError
  }] = useLazyGetLatestOverBurdenHaulerQuery();
  
  const {
    data: unit,
    isFetching: unitIsFetching,
    isError: unitIsError,
    error: unitError
  } = useGetUnitByIdQuery(
    {
      id: assumptionId,
      params: { type: TYPE.OVER_BURDEN }
    },
    { refetchOnMountOrArgChange: true }
  );

  const [postObHauler, {
    isLoading: obHaulerIsLoading,
    isSuccess: obHaulerIsSuccess,
    isError: obHaulerIsError,
    error: obHaulerError
  }] = usePostOverBurdenHaulerMutation();
  
  const [putObHauler, {
    isLoading: putObHaulerIsLoading,
    isSuccess: putObHaulerIsSuccess,
    isError: putObHaulerIsError,
    error: putObHaulerError
  }] = usePutOverBurdenHaulerMutation();

  const goToPath = (type) => navigate({
    pathname: getFullPath({
      parent: 'assumption',
      child: type === 'back'
        ? 'coal-hours-capacity'
        : 'pit-reserve',
      type: getPathType(loc)
    }),
    search: createSearchParams({
      id: assumptionId,
      ...type === 'back'
        ? {
          isBack: isBack
            ? backQueries
            : createBackQueryParams(loc.pathname)
        }
        : {
          ...isPathMaxBackQueryParams(loc.pathname, backQueries)
            ? undefined
            : { isBack: backQueries }
        }
    }).toString()
  });

  useEffect(() => {
    Object.keys(dataTable).forEach((key) => {
      setIsValid(dataTable?.[key]?.some(item => item.type_truck.length));
    });
  }, [dataTable]);

  const onAddTruck = (values) => {
    setDataTable((prevState) => {
      const getDateData = isWeeklyRef ? periodRef.current[0] : activeTableTab.codeKey;
      const equipmentName = prevState[activeTableTab.codeKey][tableIndex].equipment_name;
      const typeTruck = JSON.parse(values.type_truck);

      let dataIndex = isWeeklyRef.current ? 'week' : generateDataIndex(activeTableTab.codeKey);
      if (isMonthlyRef.current) {
        dataIndex = moment(periodRef.current[0], 'DD MMM YYYY').format('MMM_YYYY');
      }

      const haulHrs = getHaulHrs(values.distance, typeTruck.haul_speed);
      const returnHrs = getReturnHrs(values.distance, typeTruck.return_speed);
      const cycleTimeHr = typeTruck.loading_hours + haulHrs + typeTruck.dump_hours + returnHrs;
      const productivity = typeTruck.hauler_capacity / cycleTimeHr;
      const availableHrs = headerData.find(v => v.codeKey === 'available_hours')[dataIndex];

      /**
       * Get total_lost_time by periode
       * */
      const overBurdenProdLossTime = lossTime.contents.find((content) => content.code === 'production_lost_time_ob');
      const totalLossTime = overBurdenProdLossTime.properties.find((prop) => prop.code === 'total_lost_time');
      const totalLossTimeProps = totalLossTime.properties.find((item) => toLower(item.code) === 'monthly');
      const totalLossTimeData = totalLossTimeProps.properties.find((prop) => moment(getDateData).format('DD-MM-YYYY') === prop.date);

      const effectiveWorkingHours = availableHrs - totalLossTimeData.value - values.maintenance_hours;

      /**
       * Get production OB Capacity by periode
       * */
      const equipment = obCapacity.contents.find((item) => item.code === 'equipments');
      const equipmentProp = equipment.properties.map((item) => {
        const properties = item.value.find((value) => value.value === equipmentName);
        if (properties) {
          return item.properties;
        }
        return null;
      });
      const productivityObCapacity = equipmentProp.filter(v => v)[0].find(i => i.code === 'production');
      const excavatorProduction = productivityObCapacity.properties
        .find(item => moment(getDateData).format('DD-MM-YYYY') === item.date).value;
      const haulerProduction = productivity * values.quantity * effectiveWorkingHours;
      const matchFactor = haulerProduction / excavatorProduction;

      /**
       * Set Data Table
       * */
      const tempPrevState = [ ...prevState[activeTableTab.codeKey] ];
      const tempDataTruck = { ...tempPrevState[tableIndex] };

      const dataValue = {
        distance: values.distance,
        dump_area: values.dump_area,
        maintenance_hours: values.maintenance_hours,
        quantity: values.quantity,
        type_truck: typeTruck,
        productivity: parseFloat(productivity),
        effective_working_hour: effectiveWorkingHours,
        hauler_production: parseFloat(haulerProduction),
        excavator_production: excavatorProduction,
        match_factor: parseFloat(matchFactor),
        haul_hours: parseFloat(haulHrs).toFixed(3),
        return_hours: parseFloat(returnHrs).toFixed(3),
        cycle_time_hour: parseFloat(cycleTimeHr).toFixed(3)
      };

      if (!isEditTruck) {
        const newDataValue = {
          equipment_name: equipmentName,
          equip_type: tempDataTruck.equip_type,
          equip_no: tempDataTruck.equip_no,
        };

        Object.keys(dataValue).forEach(key => {
          newDataValue[key] = [ ...tempDataTruck[key], dataValue[key] ];
        });

        const tempData = prevState[activeTableTab.codeKey].map((v, index) => {
          if (tableIndex === index) {
            v = newDataValue;
          }
  
          return v;
        });
  
        return { ...prevState, [activeTableTab.codeKey]: tempData };
      } else {
        Object.keys(dataValue).forEach(key => {
          tempDataTruck[key][truckIndex] = dataValue[key];
        });
  
        return { ...prevState, [activeTableTab.codeKey]: tempPrevState };
      }
    });

    setModalTruckForm({});
  };

  const generateDataIndex = (item) => momentPeriod(item)
    .format(generateFormat(periodRef.current, toLower(unitRef.current), false, true));

  const getHaulHrs = (distance, haulSpeed) => {
    return (2 * (0.2 / (haulSpeed / 2))) + (((distance / 1000) - 0.2 - 0.2) / haulSpeed);
  };

  const getReturnHrs = (distance, returnSpeed) => {
    return (2 * (0.2 / (returnSpeed / 2))) + (((distance / 1000) - 0.2 - 0.2) / returnSpeed);
  };

  const deleteTypeTruck = (index, idxItem) => {
    showModal({
      isShown: true,
      type: 'delete',
      message: 'Are You sure you want to delete this type truck?',
      onSubmit: () => {
        const excludedKeys = [
          'equipment_name',
          'equip_type',
          'equip_no'
        ];
        const tempDataTable = dataTable;
        Object.keys(tempDataTable[activeTableTab.codeKey][index]).forEach((key) => {
          if (!excludedKeys.includes(key)) {
            tempDataTable[activeTableTab.codeKey][index][key].splice(idxItem, 1);
          }
        });
        setDataTable({ ...tempDataTable });

        resetModal();
      }
    });
  };

  const generateMinePlanningGuidanceRowStyle = (record, index) => {
    return index % 2 === 0 ? 'bg-white align-top' : 'bg-gray-100 align-top';
  };

  const fetchTrucksList = async (contractorCode) => {
    const listTruck = await getTrucksOption({contractorCode: contractorCode}).unwrap();

    setTruckOption(listTruck);
  };

  const calculatePixel = (remVal) => {
    /**
     * `1rem` is `16px`
     * */
    return `${(window.innerWidth - (remVal * 16))}px`;
  };

  const redirectPathToCreate = () => navigate({
    pathname: getFullPath({
      parent: 'assumption',
      child: 'over-burden-hauler',
      type: 'create'
    }),
    search: createSearchParams({ id: assumptionId }).toString()
  });

  const momentPeriod = (item) => moment(item, toLower(unitRef.current) === UNIT.DAY ? 'DD MMM YYYY' : 'MMM YYYY');

  const generateSummary = (key) => {
    let total = 0;
    dataTable?.[activeTableTab.codeKey]?.map(item => {
      if (key === 'excavator_production') {
        total += parseFloat(item[key][0]);
      } else {
        item[key].forEach(value => {
          total += parseFloat(value);
        });
      }
      return total;
    });
    return total 
      ? key === 'match_factor' || key === 'excavator_production'
      || key === 'effective_working_hour' || key === 'hauler_production'
        ? Number(Number(total).toFixed(1)).toLocaleString('en-US')
        : Number(parseFloat(total).toFixed(0)).toLocaleString('en-US')
      : 0;
  };

  const postOverBurdenHauler = (isDraft) => {
    isDraftRef.current = isDraft;
    
    const body = generateBody(
      isDraftRef.current,
      assumptionId,
      unitRef.current,
      periodNameRef.current,
      periodRef.current,
      headerData,
      unitsRef.current.contents,
      dataTable
    );

    if (isBack || isEditMode) {
      putObHauler({ id: obHaulerIdRef.current, body });

      return;
    }

    postObHauler(body);
  };

  useEffect(() => {
    if (isEditMode && shouldRedirect(obHaulerByIdData)) {
      redirectPathToCreate();

      return;
    }

    if (!isEmpty(obHaulerByIdData)
      && !Boolean(obHaulerByIdData?.error)
      && isNeedToFetchDetail
      && truckOption.length
    ) {
      obHaulerIdRef.current = obHaulerByIdData.id;

      const excludedKeys = [
        'equipment_name',
        'equip_type',
        'equip_no'
      ];

      const momentDatePeriod = (item) => {
        return moment(item, toLower(unitRef.current) === UNIT.DAY ? 'DD MMM YYYY' : 'MMM YYYY')
          .format('DD-MM-YYYY');
      };

      const generateDataByPeriod = (period) => unit?.contents?.map((item) => {
        let newItem = { ...item };
        const selectedEquipments = obHaulerByIdData?.contents?.find((item) => item.code === 'overburden_hauler');
        const detailEquipment = selectedEquipments?.properties?.find(
          (item) => item.value.some(
            (propItem) => propItem.code === 'equipment_no' && newItem.alias_name.includes(propItem.value)
          )
        );

        if (detailEquipment) {
          const selectedTruckList = detailEquipment?.properties?.find((item) => item.code === 'truck_list');
          const dataByPeriod = selectedTruckList?.properties?.find(v => v.date === period);
          const trucks = dataByPeriod.properties;
          const dataValue = {
            equipment_name: newItem.name,
            equip_type: newItem.alias_name.split('-')[0],
            equip_no: newItem.alias_name.split('-')[1],
            type_truck: [],
            dump_area: [],
            distance: [],
            maintenance_hours: [],
            productivity: [],
            quantity: [],
            effective_working_hour: [],
            hauler_production: [],
            excavator_production: [],
            match_factor: [],
            haul_hours: [],
            return_hours: [],
            cycle_time_hour: []
          };
          trucks.forEach(truckItem => {
            const dataTruck = truckItem.properties;
            Object.keys(dataValue).forEach(key => {
              if (!excludedKeys.includes(key)) {
                if (key === 'type_truck') {
                  dataValue[key].push(
                    truckOption.find(
                      (item) => item.name === dataTruck.find(item => item.code === key).value
                    )
                  );
                  return;
                }
                dataValue[key].push(dataTruck.find((item) => item.code === key).value);
              }
            });
          });

          return dataValue;
        }

        newItem = [];

        return newItem;
      });

      switch (true) {
        case isWeeklyRef.current: {
          setDataTable({
            week: generateDataByPeriod(momentDatePeriod(periodRef.current[0]))
          });
          break;
        }
        case isMonthlyRef.current: {
          setDataTable({
            [moment(periodRef.current[0], 'DD MMM YYYY').format('MMM_YYYY')]: generateDataByPeriod(momentDatePeriod(periodRef.current[0]))
          });
          break;
        }
        default: {
          const tempDataTable = periodRef.current.map((period) => ({
            [generateDataIndex(period)]: generateDataByPeriod(momentDatePeriod(period))
          }));

          const dataReducer = tempDataTable.reduce(
            (obj, v) => Object.assign(obj, {[Object.keys(v)]: Object.values(v)[0]}), {});

          setDataTable({ ...dataReducer });
          break;
        }
      }
    }
  }, [obHaulerByIdData, truckOption]);

  useEffect(() => {
    if (isCreateMode
      && !isBack
    ) {
      if (!isEmpty(latestObHaulerData)
      && !Boolean(latestObHaulerData?.error)
      && !isEmpty(latestObHaulerData?.previous_contents)
      && truckOption.length
      ) {
        const excludedKeys = [
          'equipment_name',
          'equip_type',
          'equip_no'
        ];
  
        const generateDataByPeriod = (idx) => unit?.contents?.map((item) => {
          let newItem = { ...item };
          const selectedEquipments = latestObHaulerData?.previous_contents?.find((item) => item.code === 'overburden_hauler');
          const detailEquipment = selectedEquipments?.properties?.find(
            (item) => item.value.some(
              (propItem) => propItem.code === 'equipment_no' && newItem.alias_name.includes(propItem.value)
            )
          );

          if (detailEquipment) {
            const selectedTruckList = detailEquipment?.properties?.find((item) => item.code === 'truck_list');
            const selectedTruck = selectedTruckList?.properties?.map(v => {
              const trucks = v.properties;
              const data = {
                equipment_name: newItem.name,
                equip_type: newItem.alias_name.split('-')[0],
                equip_no: newItem.alias_name.split('-')[1],
                type_truck: [],
                dump_area: [],
                distance: [],
                maintenance_hours: [],
                productivity: [],
                quantity: [],
                effective_working_hour: [],
                hauler_production: [],
                excavator_production: [],
                match_factor: [],
                haul_hours: [],
                return_hours: [],
                cycle_time_hour: []
              };

              trucks.forEach(truckItem => {
                const dataTruck = truckItem.properties;
                Object.keys(data).forEach(key => {
                  if (!excludedKeys.includes(key)) {
                    if (key === 'type_truck') {
                      data[key].push(
                        truckOption.find(
                          (item) => item.name === dataTruck.find(item => item.code === key).value
                        )
                      );
                      return;
                    }

                    data[key].push(dataTruck.find((item) => item.code === key).value);
                  }
                });
              });

              return data;
            });

            return isWeeklyRef.current || isMonthlyRef.current ? selectedTruck[0] : selectedTruck[idx];
          }

          newItem = [];

          return newItem;
        });

        switch (true) {
          case isWeeklyRef.current: {
            setDataTable({
              week: generateDataByPeriod()
            });
            break;
          }
          case isMonthlyRef.current: {
            setDataTable({
              [moment(periodRef.current[0], 'DD MMM YYYY').format('MMM_YYYY')]: generateDataByPeriod()
            });
            break;
          }
          default: {
            const tempDataTable = periodRef.current.map((period, idx) => ({
              [generateDataIndex(period)]: generateDataByPeriod(idx)
            }));
  
            const dataReducer = tempDataTable.reduce(
              (obj, v) => Object.assign(obj, {[Object.keys(v)]: Object.values(v)[0]}), {});
  
            setDataTable({ ...dataReducer });
            break;
          }
        }
      }
    }
  }, [latestObHaulerData, truckOption]);

  useEffect(() => {
    if (assumption && unit && lossTime) {
      lossTimeRef.current = lossTime;

      if (isCreateMode && !isBack) {
        getLatestObHauler({
          id: assumptionId
        });
      }

      if (isNeedToFetchDetail) {
        getObHaulerById({
          id: assumptionId
        });
      }

      unitsRef.current = unit;

      unitRef.current = toLower(assumption?.category?.unit);
      periodNameRef.current = toLower(assumption?.category?.name);
      periodRef.current = convertPeriodToTags(
        unitRef.current,
        assumption?.start_date,
        assumption?.end_date
      );
      isWeeklyRef.current = periodNameRef.current === 'weekly';
      isMonthlyRef.current = periodNameRef.current === 'monthly';
    
      /**
     * Header Section
     * */
      setHeaderColumns(
        generateHeaderColumns(
          unitRef.current,
          periodNameRef.current,
          periodRef.current
        )
      );

      setHeaderData(
        generateHeaderData(
          unitRef.current,
          periodNameRef.current,
          periodRef.current,
          lossTime
        )
      );

      fetchTrucksList(assumption?.contractor?.alias_name);

      const defaultData = unitsRef?.current?.contents?.map(i => {
        return {
          equipment_name: i.name,
          equip_type: i.alias_name.split('-')[0],
          equip_no: i.alias_name.split('-')[1],
          type_truck: [],
          dump_area: [],
          distance: [],
          maintenance_hours: [],
          productivity: [],
          quantity: [],
          effective_working_hour: [],
          hauler_production: [],
          excavator_production: [],
          match_factor: [],
          haul_hours: [],
          return_hours: [],
          cycle_time_hour: []
        };
      });

      switch (true) {
        case isWeeklyRef.current: {
          setTabList([{ codeKey: 'week', title: 'Week' }]);

          setActiveTableTab({ codeKey: 'week', title: 'Week' });

          setDataTable({
            week: defaultData
          });
          break;
        }
        case isMonthlyRef.current: {
          setTabList([{
            codeKey: moment(periodRef.current[0], 'DD MMM YYYY').format('MMM_YYYY'),
            title: moment(periodRef.current[0], 'DD MMM YYYY').format('MMM YYYY')
          }]);

          setActiveTableTab({
            codeKey: moment(periodRef.current[0], 'DD MMM YYYY').format('MMM_YYYY'),
            title: moment(periodRef.current[0], 'DD MMM YYYY').format('MMM YYYY')
          });

          setDataTable({
            [moment(periodRef.current[0], 'DD MMM YYYY').format('MMM_YYYY')]: defaultData
          });
          break;
        }
        default: {
          setTabList(
            periodRef.current.map((period) => ({
              codeKey: generateDataIndex(period),
              title: period
            }))
          );

          setActiveTableTab({
            codeKey: generateDataIndex(periodRef.current[0]),
            title: periodRef.current[0]
          });

          const dataByPeriod = periodRef.current.map((period) => ({
            [generateDataIndex(period)]: defaultData
          }));

          setDataTable(
            dataByPeriod.reduce(
              (obj, item) => Object.assign(obj, {[Object.keys(item)[0]]: Object.values(item)[0]}), {})
          );
          break;
        }
      }
    }

  }, [assumption, unit, lossTime]);

  useEffect(() => {
    if (assumptionIsError) {
      const errorMsg = assumptionError;

      toast.error(<Toast message={'ERROR'} detailedMessage={`${transformError(errorMsg).message}`} />);
      return;
    }
    if (lossTimeIsError) {
      const errorMsg = lossTimeError;

      toast.error(<Toast message={'ERROR'} detailedMessage={`${transformError(errorMsg).message}`} />);
      return;
    }
    if (unitIsError) {
      const errorMsg = unitError;

      toast.error(<Toast message={'ERROR'} detailedMessage={`${transformError(errorMsg).message}`} />);
      return;
    }
    if (obCapacityIsError) {
      const errorMsg = obCapacityError;

      toast.error(<Toast message={'ERROR'} detailedMessage={`${transformError(errorMsg).message}`} />);
      return;
    }
    if (trucksIsError) {
      const errorMsg = truckError;

      toast.error(<Toast message={'ERROR'} detailedMessage={`${transformError(errorMsg).message}`} />);
      return;
    }
  }, [assumptionIsError, lossTimeIsError, unitIsError, obCapacityIsError, trucksIsError]);

  useEffect(() => {
    if (obHaulerByIdDataIsError || latestObHaulerDataIsError) {
      const errorMsg = obHaulerByIdDataError || latestObHaulerDataError;

      toast.error(<Toast message={'ERROR'} detailedMessage={`${transformError(errorMsg).message}`} />);
      return;
    }
  }, [obHaulerByIdDataIsError, latestObHaulerDataIsError]);

  useEffect(() => {
    if (obHaulerIsSuccess || putObHaulerIsSuccess) {
      if (isDraftRef.current) {
        navigate(`/${URL.ACTIVITY_LEVEL}/${URL.MINE_PLANNING_OE}/assumption`);
        
        return;
      }
      
      goToPath('next');
      
      return;
    }
    
    if (obHaulerIsError || putObHaulerIsError) {
      const errorMsg = obHaulerError || putObHaulerError;

      toast.error(<Toast message={'ERROR'} detailedMessage={`${transformError(errorMsg).message}`} />);
      
      return;
    }
    
    return () => {};
  }, [
    obHaulerIsSuccess,
    obHaulerIsError,
    putObHaulerIsSuccess,
    putObHaulerIsError
  ]);

  return (
    <div className="flex flex-col">
      <Form
        layout="vertical"
        autoComplete="off"
        form={form}
        requiredMark={false}
        onFinish={() => postOverBurdenHauler(false)}
      >
        <BannerPitPeriod
          isLoading={assumptionIsFetching}
          companyName={assumption?.company?.alias_name}
          contractorName={assumption?.contractor?.alias_name}
          pitName={assumption?.pit?.name}
          periodMode={assumption?.category?.name}
          periods={assumption
            ? periodRef.current
            : []}
        />

        <div className="my-5 p-5 flex flex-col bg-white rounded-lg">
          <span className="mb-5 font-bold text-lg">Over Burden Hauler</span>

          <div
            className="overflow-x-auto"
            style={{ maxWidth: calculatePixel(20) }}
          >
            <TableRaw
              isLoading={assumptionIsFetching || lossTimeIsFetching}
              scrollWidth="max-content"
              columns={headerColumns}
              dataSource={headerData}
            />
          </div>
        </div>
        {
          !(isWeeklyRef.current || isMonthlyRef.current) &&
          <div className="flex flex-row gap-x-3 mb-5">
            {
              tabList.map((item, index) => (
                <div
                  key={`type-${index + 1}`}
                  className={`p-2 flex flex-row rounded-lg gap-x-3 cursor-pointer
                  ${activeTableTab.codeKey === item.codeKey ? 'bg-[#2D3D5A]' : 'bg-[#F2F5FC]'}`}
                  onClick={() => setActiveTableTab(item)}
                >
                  <div className={`w-2 rounded ${activeTableTab.codeKey === item.codeKey ? 'bg-white' : 'bg-[#2D3D5A]'}`} />
                  <span className={`font-bold text-sm text-center self-center ${activeTableTab.codeKey === item.codeKey ? 'text-white' : 'text-[#2D3D5A]'}`}>
                    {item.title}
                  </span>
                </div>
              ))
            }
          </div>
        }
        <div className="p-5 bg-white flex flex-col rounded-lg w-[80vw]">
          <div className="ml-auto">
            {
              (obHaulerByIdDataIsFetching || latestObHaulerDataIsFetching) && (<LoadingText />)
            }
          </div>
          <TableRaw
            isLoading={unitIsFetching}
            columns={tableColumns}
            dataSource={dataTable[activeTableTab.codeKey]}
            scrollWidth={2000}
            customRow={(record, index) => generateMinePlanningGuidanceRowStyle(record, index)}
            summary={() => (
              <Table.Summary.Row className="font-bold bg-pink-700/25 text-pink-700">
                <Table.Summary.Cell index={0} className="rounded-tl-lg rounded-bl-lg" />
                <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={2} />
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                <Table.Summary.Cell index={6}></Table.Summary.Cell>
                <Table.Summary.Cell index={7}>{generateSummary('productivity')}</Table.Summary.Cell>
                <Table.Summary.Cell index={8}>{generateSummary('quantity')}</Table.Summary.Cell>
                <Table.Summary.Cell index={9}>{generateSummary('effective_working_hour')}</Table.Summary.Cell>
                <Table.Summary.Cell index={10}>{generateSummary('hauler_production')}</Table.Summary.Cell>
                <Table.Summary.Cell index={11}>{generateSummary('excavator_production')}</Table.Summary.Cell>
                <Table.Summary.Cell index={12} className="rounded-tr-lg rounded-br-lg">{generateSummary('match_factor')}</Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          />
        </div>

        <Form.Item>
          <div className="mt-5 w-full flex flex-row items-center">
            <ButtonAccent
              isLoading={obHaulerIsLoading || putObHaulerIsLoading}
              isDisabled={isDetailMode}
              size="lg"
              title="Save As Draft"
              onClick={() => postOverBurdenHauler(true)}
            />

            <div className="ml-auto flex flex-row items-center gap-x-3">
              <ButtonAccent
                isLoading={obHaulerIsLoading || putObHaulerIsLoading}
                isBordered
                title="Back"
                onClick={() => goToPath('back')}
              />
              <ButtonAccent
                isLoading={obHaulerIsLoading || putObHaulerIsLoading}
                isDisabled={!isValid}
                title="Next"
                onClick={() => isDetailMode
                  ? goToPath('next')
                  : postOverBurdenHauler(false)}
              />
            </div>
          </div>
        </Form.Item>
      </Form>
      <ModalTypeTruck
        openModal={openModal}
        setOpenModal={setOpenModal}
        trucks={truckOption}
        onAddTruck={(v) => onAddTruck(v)}
        truckData={modalTruckForm}
        isEditTruck={isEditTruck}
        data={dataTable[activeTableTab.codeKey]}
      />
    </div>
  );
};

export default OverBurdenHauler;