import { isEmpty, isNull } from 'lodash';

import { EMPTY, URL } from 'constant';

const getPathType = (location) => {
  if (location) {
    const { pathname } = location;
    const paths = pathname.split('/');

    return paths.length
      ? paths[paths.length - 1]
      : 'create';
  }

  return 'create';
};

const getFullPath = (path,type) => {
  if (!isEmpty(path)) {
    if(type === 'geology-oe'){
      return `/${URL.ACTIVITY_LEVEL}/${URL.GEOLOGY_OE}/${path.parent}/${path.child}/${path.type}`;
    }else{
      return `/${URL.ACTIVITY_LEVEL}/${URL.MINE_PLANNING_OE}/${path.parent}/${path.child}/${path.type}`;
    }
  }

  return EMPTY.STRING;
};

const shouldRedirect = (data) => Boolean(isNull(data) || Boolean(data?.error));

export {
  getPathType,
  getFullPath,
  shouldRedirect
};