
import { generateVarianceTopoCollarValidationTable } from 'constant/TopoColarValidation/TableVariance';
import TableDrillingSumarry from 'pages/DrillingPlan/DrillingSummary/TableDrillingSummary';

const VarianceTopoCollarValidation = (props) => {
  const {payload} = props;
  const columns = generateVarianceTopoCollarValidationTable();
  return <TableDrillingSumarry columns={columns} tableData={payload} />;
};

export default VarianceTopoCollarValidation;