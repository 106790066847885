import ButtonAccent from 'components/ButtonAccent';
import CategoriesList from 'components/CategoriesList';
import UploadDocumentExcel from 'components/UploadExcel';
import { generateBody, generateCoreRecoveryOfCoalCategories, initiateCoreRecoveryData } from 'constant/TableCoreRecovery/TableCoreRecovery';
import ModalInformation from 'pages/DrillingPlan/components/ModalInformation';
import { useCallback, useEffect, useState } from 'react';
import { useTableWrapper } from 'utils/TableResponsive';
import SampleInformationCoreRecovery from './SampleInformation';
import CoalSeamRecoveryCoreRecovery from './CoalSeamRecovery';
import { useLazyGetDrillHoleNameListQuery } from 'api/DrillingSummary';
import _, { isEmpty, isNumber } from 'lodash';
import { toast } from 'react-toastify';
import { transformError } from 'utils/ErrorTransformer';
import Resurvey from './Resurvey';
import Thickness from './Thickness';
import { useLazyExportCoreRecoveryExcelQuery, useLazyGetCoreRecoveryByIdQuery, usePostCoreRecoveryMutation, useUploadCoreRecoveryExcelMutation } from 'api/CoreRecovery';
import { useLocation, useNavigate } from 'react-router';
import moment from 'moment';
import { getPathType } from 'utils/PathUtility';
import { useModalConfirmationContext } from 'components/ModalConfirmationProvider';
import { URL } from 'constant';
import PreventNavigation from 'constant/PreventNavigation';
import LoadingIcon from 'components/LoadingIcon';
import { Tag } from 'antd';

const InputCoreRecovery = () => {
  let initiate = _.cloneDeep(initiateCoreRecoveryData);
  const [data, setData] = useState(null);
  const [file, setFile] = useState(null);
  const [payload, setPayload] = useState(initiate);
  const [selectedCategory, setSelectedCategory] = useState('Sample Information');
  const [transformErrorUpload, setTransformErrorUpload] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [plansData, setPlansData] = useState([]);
  const { wrapperWidth } = useTableWrapper();
  const [errorExcel, setErrorExcel] = useState(null);
  const location = useLocation();
  const coreRecoveryId = new URLSearchParams(location.search).get('id');
  const inputActualData = location.state;
  const [year, setYear] = useState(null);
  const [company, setCompany] = useState({});
  const [drillingStage, setDrillingStage] = useState(null);
  const [planId, setPlanId] = useState(null);
  const [version, setVersion] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();
  const isDetailMode = getPathType(location) === 'detail';
  const isEditMode = getPathType(location) === 'edit';
  const {showModal, resetModal} = useModalConfirmationContext();

  const [
    getDrillHoleNameList,
    {
      data: drillHoleNameData,
      isFetching: drillHoleNameIsFetching,
      isError: drillHoleNameIsError,
      error: drillHoleNameError,
    },
  ] = useLazyGetDrillHoleNameListQuery({ refetchOnMountOrArgChange: true });

  const [
    uploadCoreRecoveryExcel,
    {
      data: excelRes,
      isLoading: excelResIsLoading,
      isError: excelResIsError,
      error: excelResError  
    }
  ] = useUploadCoreRecoveryExcelMutation();

  const [postCoreRecovery, {
    isLoading: postIsLoading,
    isSuccess: postIsSuccess,
    isError: postIsError,
    error: postError
  }] = usePostCoreRecoveryMutation();

  const [
    getCoreRecoveryById,
    {
      data: detailCoreRecoveryData,
      isFetching: detailCoreRecoveryIsFetching,
      isError: detailCoreRecoveryIsError,
      error: detailCoreRecoveryError
    }
  ] = useLazyGetCoreRecoveryByIdQuery(
    { refetchOnMountOrArgChange: true }
  );

  const [exportCoreRecoveryExcel, {
    isLoading: exportExcelIsLoading,
    isError: exportExcelIsError,
    error: exportExcelError
  }] = useLazyExportCoreRecoveryExcelQuery();

  const populateType = (type) => {
    if(type === 'PREPRODUCTION'){
      return 'Pre-production';
    }else{
      return 'Exploration';
    }
  };

  const handleBackButton = () => {
    if(isDetailMode) {
      navigate(`/${URL.ACTIVITY_LEVEL}/${URL.GEOLOGY_OE}/core-recovery-of-coal`);
    } else {
      showModal({
        isShown: true,
        type: 'confirmation',
        title:'Are you sure want to leave this page?',
        message: 'Changes you made may not be saved',
        onSubmit: () => {
          navigate(`/${URL.ACTIVITY_LEVEL}/${URL.GEOLOGY_OE}/core-recovery-of-coal`);
          resetModal();
        }
      });
    }
  };

  const downloadFile = (url) => {
    const docLink = document.createElement('a');
    docLink.href = url;
    document.body.appendChild(docLink);
    docLink.click();
    document.body.removeChild(docLink);
  };

  const handleDeleteFile = () => {
    setData(null);
  };

  const deleteDrillHoleName = (record) => {
    let dataClone = _.cloneDeep(payload);
    let result = [];
    dataClone.forEach(item => {
      let objectTemp = {};
      Object.keys(item).forEach(key => {
        objectTemp[key] = item[key]?.filter(sample=>sample.localId !== record.localId);
      });
      result.push(objectTemp);
    });
    setPayload(result);
  };

  const onAddSampleId = (localId) => {
    let tempData = _.cloneDeep(payload);
    tempData.forEach((item, index) => {
      Object.keys(item).forEach((key, indexParent) => {
        item[key].forEach((child, indexChild)=>{
          let initiateChildren = tempData[index][key][indexChild].children[indexParent];
          if(child?.localId === localId){
            let payloadChildren = child?.children;
            let children = {
              ...initiateChildren,
              sample_id: '',
              localChildrenId: payloadChildren[payloadChildren.length - 1]?.localChildrenId + 1 
            };
            if(children.localChildrenId !== 1){
              delete children.drill_hole_name;
            }
            child?.children.push(children);
          }
        });        
        
      });
    });
    setPayload([...tempData]);
  };

  const deleteSampleId = (record) => {
    let tempData = _.cloneDeep(payload);
    tempData.forEach((item) => {
      Object.keys(item).forEach((key) => {
        item[key].forEach((child) => {
          if(child?.localId === record?.localId){
            let payloadChildren = child?.children;
            child.children = payloadChildren.filter(child=> child.localChildrenId !== record.localChildrenId);
          } 
        });
      });
    });
    setPayload([...tempData]);
  };

  const onChange = (category, localId, localChildrenId, attribute, value) => {
    let tempData = [...payload];
    let tempCategory = tempData.find((item)=> item[category]);
    let tempParent = tempCategory[category]?.find(item => item.localId === localId);
    if(attribute === 'drill_hole_name'){
      tempParent[attribute] = value ?? null;
    }else{
      let tempChild = tempParent?.children?.find(item => item.localChildrenId === localChildrenId);
      tempChild[attribute] = value ?? null;
    }
    setPayload([...tempData]);
  };

  const populateCategoriesModule = () => {
    switch(selectedCategory){
      case 'Sample Information':
        return (
          <SampleInformationCoreRecovery 
            isDetailMode={isDetailMode}
            payload={payload}
            onChange={onChange} 
            onAddSampleId={onAddSampleId}
            deleteSampleId={deleteSampleId}
            drillHoleNameProperties={{
              data: plansData,
              isFetching: drillHoleNameIsFetching,
            }}
            onDeleteRow={deleteDrillHoleName}
          />
        );
      case 'Resurvey':
        return (
          <Resurvey
            isDetailMode={isDetailMode}
            onChange={onChange}
            payload={payload} 
            onAddSampleId={onAddSampleId}
            deleteSampleId={deleteSampleId}
            onDeleteRow={deleteDrillHoleName}
          />
        );
      case 'Thickness':
        return (
          <Thickness
            isDetailMode={isDetailMode}
            onChange={onChange}
            payload={payload} 
            onAddSampleId={onAddSampleId}
            deleteSampleId={deleteSampleId}
            onDeleteRow={deleteDrillHoleName}
          />
        );
      case 'Coal Seam & Recovery':
        return (
          <CoalSeamRecoveryCoreRecovery
            isDetailMode={isDetailMode}
            onChange={onChange} 
            payload={payload} 
            onAddSampleId={onAddSampleId}
            deleteSampleId={deleteSampleId}
            onDeleteRow={deleteDrillHoleName}
          />
        );
      default:
        return;
    }
  };

  const onAddDrillHoleName = () => {
    let tempData = _.cloneDeep(payload);
    let result = [];
    tempData.forEach((item, index) => {
      let temp = Object.keys(item).map((key) => {
        let properties = initiateCoreRecoveryData[index][key]?.map(temp => {
          return {
            ...temp,
            localId: item[key][item[key].length - 1].localId + 1
          };
        });
        return {  
          [key]:[   
            ...item[key],
            ...properties
          ]
        };
      });
      result.push(...temp);
    });
    setPayload(result);
  };

  const jsonParseMessage = () => {
    try {
      return JSON.parse(errorExcel.data.error.message);;  
    } catch{
      return null;
    }
  };

  const mappingExcelData = (excelData) => {
    let tempExcel = _.cloneDeep(excelData.properties);
    let thicknesProperties = [];
    tempExcel?.forEach((parent, parentIndex) => {
      Object.keys(parent).forEach((key) => {
        parent[key].forEach((child, childIndex)=>{
          let isSameDrillHoleName = parent[key]?.find(item => item.drill_hole_name === payload[parentIndex][key][childIndex]?.drill_hole_name);
          parent[key][childIndex] = {...child, localId: childIndex + 1};
          if(isEmpty(isSameDrillHoleName)){
            if(!isEmpty(payload[parentIndex][key][childIndex]) && payload[parentIndex][key][childIndex]?.drill_hole_name ){
              parent[key].unshift({
                ...payload[parentIndex][key][childIndex],
                localId: parent[key][childIndex]?.localId + 1
              });
            }
          }         
          child.children.forEach((grandChild, grandIndex) => {
            if(grandChild?.hasOwnProperty('geophysical_depth_thickness')) {
              grandChild.geophysical_depth_thickness = Number(grandChild?.geophysical_depth_to - grandChild?.geophysical_depth_from);
              thicknesProperties.push(grandIndex);
            }
            if(grandChild?.hasOwnProperty('coal_recovery')) {
              let geoThickness = tempExcel[2]?.thickness[childIndex]?.children[grandIndex]?.geophysical_depth_thickness;
              let drillThickness = tempExcel[2]?.thickness[childIndex]?.children[grandIndex]?.drill_hole_thickness;
              grandChild.coal_recovery = geoThickness?  Number(drillThickness / geoThickness) : 'Incomplete Data';
            }
            child.children[grandIndex] = {...grandChild, localChildrenId: grandIndex};
            if(!isEmpty(isSameDrillHoleName)){
              let isSameSampleId = payload[parentIndex][key][childIndex]?.children?.find(item=>item?.sample_id === grandChild?.sample_id);
              if(isEmpty(isSameSampleId)){
                child.children?.unshift({
                  ...payload[parentIndex][key][childIndex]?.children[grandIndex],
                  localChildrenId: child.children[grandIndex]?.localChildrenId + 1
                });
              }
            }
          });
        });
      });
    });
    setPayload([...tempExcel]);
  };

  const mandatoryValidationSampleInformation = () => {
    let validation = true;
    const tempPayload = payload.find(v => Object.keys(v).includes('sample_information'));
    let temp = _.cloneDeep(tempPayload.sample_information);
    temp.forEach((v) => {
      let keys = Object.keys(v);
      
      keys.forEach(key => {
        if (key === 'drill_hole_name' && !v[key]) {
          validation = false;
        }

        if (key === 'children') {
          v[key].forEach(child => {
            Object.entries(child).forEach(([cKey, cValue]) => {
              if((cKey === 'sample_id' && !cValue)){
                validation = false;
              }
            });
          });
        }
      });
    });
    return validation;
  };

  const mandatoryValidationThickness = () => {
    let validation = true;
    const tempPayload = payload.find(v => Object.keys(v).includes('thickness'));
    let temp = _.cloneDeep(tempPayload.thickness);
    temp.forEach((v) => {
      let keys = Object.keys(v);
      
      keys.forEach(key => {
        if(key === 'children') {
          v[key].forEach(child => {
            Object.entries(child).forEach(([cKey, cValue]) => {
              if(
                !isNumber(cValue) &&
                (!['sample_id', 'geophysical_depth_thickness', 'localChildrenId'].includes(cKey) && !cValue)
              ){
                validation = false;
              }
            });
          });
        }
      });
    });
    return validation;
  };

  const mandatoryValidationCoalSeam = () => {
    let validation = true;
    const tempPayload = payload.find(v => Object.keys(v).includes('coal_seam_recovery'));
    let temp = _.cloneDeep(tempPayload.coal_seam_recovery);
    temp.forEach((v) => {
      let keys = Object.keys(v);
      
      keys.forEach(key => {
        if(key === 'children') {
          v[key].forEach(child => {
            Object.entries(child).forEach(([cKey, cValue]) => {
              if(!isNumber(cValue) && (cKey === 'coal_seam' && !cValue)){
                validation = false;
              }
            });
          });
        }
      });
    });
    return validation;
  };

  const validateSubmit = () => {
    switch (false) {
      case mandatoryValidationSampleInformation():
        toast.error('Some field is mandatory in Sample Information',
          { toastId: 'validate-sample-information-toast-error' }
        );

        setSelectedCategory('Sample Information');
        return false;
      case mandatoryValidationThickness():
        toast.error('Some field is mandatory in Thickness',
          { toastId: 'validate-thickness-toast-error' }
        );
        
        setSelectedCategory('Thickness');
        return false;
      case mandatoryValidationCoalSeam():
        toast.error('Some field is mandatory in Coal Seam & Recovery',
          { toastId: 'validate-coal-seam-toast-error' }
        );

        setSelectedCategory('Coal Seam & Recovery');
        return false;
      default:
        return true;
    }
  };

  useEffect(() => {
    if (planId) {
      getDrillHoleNameList({ drillingPlanId: planId, version, params:{include_actual:true} });
    }
  }, [planId, version]);

  useEffect(() => {
    if(!isEmpty(drillHoleNameData)) {
      setPlansData(drillHoleNameData.data);
    }
  },[drillHoleNameData]);

  useEffect(() => {
    if (drillHoleNameIsError || detailCoreRecoveryIsError || exportExcelIsError) {
      const generateToastId = () => {
        switch (true) {
          case drillHoleNameError:
            return 'get-drill-hole-name';
          case detailCoreRecoveryError:
            return 'get-detail-core-recovery';
          case exportExcelError:
            return 'export-excel-core-recovery';
          default:
            return 'default';
        }
      };
      
      toast.error(
        transformError(drillHoleNameError || detailCoreRecoveryError || exportExcelError).message,
        { toastId: `${generateToastId()}-toast-error` }
      );
    }
  }, [drillHoleNameIsError, detailCoreRecoveryIsError, exportExcelIsError]);
  
  useEffect(() => {
    if (!isEmpty(errorExcel)) {
      setTransformErrorUpload(null);
      if (errorExcel.data) {
        const transformErrorUpload = errorExcel.status <= 400 ? jsonParseMessage(errorExcel.data.error.message) : null;
        setTransformErrorUpload(transformErrorUpload);
      }
      setIsModalOpen(true);
    }
  }, [errorExcel]);

  useEffect(() => {
    if(!isEmpty(data)){
      mappingExcelData(data);
    }
  },[data]);

  const onSubmit = (isDraft) => {
    if (!isDraft) {
      if (!validateSubmit()) {
        return;
      }
    }

    const fileInfo = data?.file_info || detailCoreRecoveryData?.file_info;
    const body = generateBody({
      payload: payload,
      files: file,
      year: Number(moment(year).format('YYYY')),
      minesite: company,
      file_info: file ? null : fileInfo ?? null,
      is_draft: isDraft,
      drilling_stage: drillingStage,
      version: version,
    });
    setSuccessMessage(isDraft ? 'you have successfully saved draft of core recovery of coal ' 
      :'You have successfully submitted core recovery of coal');
    postCoreRecovery({ body, planId });
  };

  const populatePlanInfo = () => {
    let validation = (isDetailMode || isEditMode) && !isEmpty(detailCoreRecoveryData);
    let year = validation? detailCoreRecoveryData?.year.toString() : inputActualData?.year; 
    let company = validation? detailCoreRecoveryData?.minesite : inputActualData?.company;
    let drilling_stage = validation? detailCoreRecoveryData?.drilling_stage : inputActualData?.drillingStage;
    let planId = validation? detailCoreRecoveryData?.goe_drilling_plan_id : inputActualData?.planId;
    let version = validation? detailCoreRecoveryData?.version : inputActualData?.latestVersion;
    setYear(year);
    setCompany(company);
    setDrillingStage(drilling_stage);
    setPlanId(planId);
    setVersion(version);
  };

  const handleCloseSite = useCallback((ev) => ev.preventDefault() ,[]);
  
  useEffect(() => {
    PreventNavigation(isDetailMode, handleCloseSite);
  }, []);
  
  useEffect(() => {
    if(isDetailMode || isEditMode) getCoreRecoveryById({id: coreRecoveryId});
  }, [isDetailMode, isEditMode]);

  useEffect(()=>{
    if(!isEmpty(detailCoreRecoveryData)){
      mappingExcelData(detailCoreRecoveryData?.content);
      setData({...detailCoreRecoveryData?.file_info, ...detailCoreRecoveryData?.content});
    }
  },[detailCoreRecoveryData]);

  useEffect(()=>{
    populatePlanInfo();
  },[inputActualData, isDetailMode, detailCoreRecoveryData, isEditMode]);

  useEffect(() => {
    if (postIsSuccess) {
      toast.success(successMessage, { toastId: 'post-quality-toast-success' });
      navigate(`/${URL.ACTIVITY_LEVEL}/${URL.GEOLOGY_OE}/core-recovery-of-coal`);
    }

    if (postIsError) {
      toast.error(
        transformError(postError).message,
        { toastId: 'post-coal-recovery-toast-error' }
      );
    }
  }, [postIsSuccess, postIsError]);

  return (
    <div className="flex flex-col gap-4">
      {
        isDetailMode && isEmpty(data?.fileName)? <></> : (
          <div
            className="bg-white p-5 overflow-x-auto rounded-2xl"
            style={{ maxWidth: wrapperWidth }}
          >
            <div className=" mb-8 flex flex-row justify-between">
              <div className=" text-[18px] font-bold">Upload Data</div>
            </div>
            <UploadDocumentExcel
              onDownload={() => downloadFile(data.url)}
              uploadExcel={uploadCoreRecoveryExcel}
              isEditMode={isEditMode}
              isDetailMode={isDetailMode}
              planId={planId}
              latestVersion={version}
              getErrorExcel={setErrorExcel}
              onDelete={handleDeleteFile} 
              onChange={setData}
              getFile={setFile}
              value={data}
              excelRes={excelRes}
              excelResError={excelResError}
              excelResIsError={excelResIsError}
              excelResIsLoading={excelResIsLoading}
            />
          </div>
        )
      }
      
      <div
        className="bg-white p-5 overflow-x-auto rounded-2xl"
        style={{ maxWidth: wrapperWidth }}
      >
        <div className=" mb-8 flex flex-row justify-between">
          <div className='flex flex-row gap-2'>
            <ButtonAccent size={'2xl'} title="Download Template" 
              onClick={() => downloadFile(`${process.env.REACT_APP_BASE_URL}/Core_Recovery_of_Coal_Template.xlsx`)}  
            />
            {isDetailMode && detailCoreRecoveryData?.status !== 'Draft' && (
              <ButtonAccent 
                isBordered 
                size={'2xl'} 
                title="Export Excel" 
                isLoading={exportExcelIsLoading}
                onClick={() => 
                  exportCoreRecoveryExcel({
                    id: coreRecoveryId, 
                    company: company.alias_name, 
                    year: moment(year).format('YYYY'),
                    drillingStage: populateType(drillingStage)
                  })
                }  
              />
            )}
          </div>
          <Tag className="px-3 py-2 font-bold rounded-2xl bg-[#2BB8A4]/[0.1] text-[#01B59C] border-none">
            <span className='text-sm'>
              {
                ` V${version} /
                  ${moment(year).format('YYYY')} / 
                  ${company?.alias_name} / 
                  Core Recovery of Coal / 
                  ${populateType(drillingStage)}
                `
              }
            </span>
          </Tag>
        </div>
        {(isEditMode || isDetailMode) && detailCoreRecoveryIsFetching ?
          (
            <LoadingIcon size={'sm'}/>
          ):(
            <> 
              {populateCategoriesModule()}
              <CategoriesList
                isActual={true}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                categories={generateCoreRecoveryOfCoalCategories()}
                onAdd={onAddDrillHoleName}
                isDetailMode={isDetailMode}
              />
            </>
          )}
      </div>
      <div className="mt-5 flex flex-row items-center">
        { !isDetailMode && detailCoreRecoveryData?.status !== 'Submitted' && (
          <ButtonAccent isBordered title="Save As Draft" isLoading={postIsLoading} onClick={() => onSubmit(true)} /> 
        )
        }
        <div className="ml-auto flex flex-row items-center gap-x-3">
          <ButtonAccent isBordered isLoading={postIsLoading} title="Back" onClick={handleBackButton}/>
          { !isDetailMode && <ButtonAccent title="Next" isLoading={postIsLoading} onClick={() => onSubmit(false)} /> }
        </div>
      </div>
      <ModalInformation
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        errorMessage={
          !isEmpty(transformErrorUpload) ?
            transformErrorUpload?.invalid_on?.map((v, i) => {
              return (<div>
                <span>{`${i + 1}. Invalid data at row ${v.row}`}</span>
                <br />
                <span>{`and column ${v.column}`}</span>
              </div>);
            })
            : transformError(errorExcel).message
        }
      />
    </div>
  );
};

export default InputCoreRecovery;
