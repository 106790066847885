import ButtonAccent from 'components/ButtonAccent';
import CategoriesList from 'components/CategoriesList';
import {  reconciliationCategories } from 'constant/TableReconciliation';
import ModalInformation from 'pages/DrillingPlan/components/ModalInformation';
import { useCallback, useEffect, useState } from 'react';
import { useTableWrapper } from 'utils/TableResponsive';
import SampleInformation from './SampleInformation';
import UploadDocumentExcel from 'components/UploadExcel';
import {useLazyExportExcelQuery, useLazyGetReconciliationByIdQuery, usePostReconciliationMutation, useUploadReconciliationExcelMutation } from 'api/Reconciliation';
import { generateBody, generateInitialReconciliationData } from 'constant/TableReconciliation/TableReconciliationData';
import _, { isArray, isEmpty, isNumber } from 'lodash';
import { generateSampleInformationData } from 'constant/TableReconciliation/TableSampleInformation';
import { transformError } from 'utils/ErrorTransformer';
import { useGetMasterDataAreasQuery, useGetMasterDataPitsQuery } from 'api/MasterData';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router';
import moment from 'moment';
import TrueThicknessPercentage from './TrueThicknessPercentage';
import Tm from './TM';
import Moist from './MOIST';
import Vm from './VM';
import Ash from './ASH';
import Fc from './FC';
import Ts from './TS';
import Cv from './CV';
import Remark from './Remark';
import { generateTrueThicknessPercentageData } from 'constant/TableReconciliation/TableTrueThicknessPercentage';
import { generateTmData } from 'constant/TableReconciliation/TableTM';
import { generateMoistData } from 'constant/TableReconciliation/TableMoist';
import { generateVmData } from 'constant/TableReconciliation/TableVm';
import { generateAshData } from 'constant/TableReconciliation/TableAsh';
import { generateFcData } from 'constant/TableReconciliation/TableFc';
import { generateTsData } from 'constant/TableReconciliation/TableTs';
import { generateCvData } from 'constant/TableReconciliation/TableCv';
import { generateReconciliationRemarksData } from 'constant/TableReconciliation/TableRemark';
import { getPathType } from 'utils/PathUtility';
import LoadingIcon from 'components/LoadingIcon';
import PreventNavigation from 'constant/PreventNavigation';
import { useModalConfirmationContext } from 'components/ModalConfirmationProvider';
import { URL } from 'constant';
import { Tag } from 'antd';

const InputReconciliation = () => {
  const { wrapperWidth } = useTableWrapper();
  const [selectedCategory, setSelectedCategory] = useState('Sample Information');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [data, setData] = useState(null);
  const [errorExcel, setErrorExcel] = useState(null);
  const [transformErrorUpload, setTransformErrorUpload] = useState(null);
  const [payload, setPayload] = useState(generateInitialReconciliationData());
  const location = useLocation();
  const isDetailMode = getPathType(location) === 'detail';
  const isEditMode = getPathType(location) === 'edit';
  const reconciliationId = new URLSearchParams(location.search).get('id');
  const navigate = useNavigate();
  const {showModal, resetModal} = useModalConfirmationContext();
  const { company, year } = location.state;
  const [successMessage, setSuccessMessage] = useState('');
  const [
    uploadReconciliationExcel,
    {
      data: excelRes,
      isLoading: excelResIsLoading,
      isError: excelResIsError,
      error: excelResError  
    }
  ] = useUploadReconciliationExcelMutation();

  const {
    data: areasData,
    isFetching: areasIsFetching,
    isError: areasDataIsError,
    error: areasDataError,
  } = useGetMasterDataAreasQuery({ refetchOnMountOrArgChange: true });

  const {
    data: pits,
    isFetching: pitIsFetching,
    isError: pitIsError,
    error: pitError,
  } = useGetMasterDataPitsQuery(
    { companyCode:company?.alias_name },
    { refetchOnMountOrArgChange: true }
  );

  const [
    getReconciliationById,
    {
      data: detailReconciliationData,
      isFetching: detailReconciliationIsFetching,
      isError: detailReconciliationIsError,
      error: detailReconciliationError
    }
  ] = useLazyGetReconciliationByIdQuery( { refetchOnMountOrArgChange: true });

  const [postReconciliation, {
    isLoading: postIsLoading,
    isSuccess: postIsSuccess,
    isError: postIsError,
    error: postError
  }] = usePostReconciliationMutation();

  const [exportExcel, {
    isLoading: exportExcelIsLoading,
    isError: exportExcelIsError,
    error: exportExcelError
  }] = useLazyExportExcelQuery();

  const onChange = (index, categoryName, attributeName, value) => {
    let temp = {...payload};
    if(isArray(value)){
      temp[categoryName][index][attributeName] = value[0];
    }else {
      temp[categoryName][index][attributeName] = value;
    }
    setPayload({...temp});
  };

  const addRowTable = () => {
    const temp = _.cloneDeep(payload);
    Object.entries(temp).forEach(([key, value]) => {
      const tempValue = {...value[0]};
      Object.entries(tempValue).forEach(([keyV, _]) => {
        tempValue[keyV] = '';
      });
      temp[key].push(tempValue);
    });

    setPayload({...temp});
  };

  const removeRowTable = (index) => {
    const temp = _.cloneDeep(payload);

    Object.entries(temp).forEach(([key, _]) => {
      temp[key].splice(index, 1);
    });

    setPayload({...temp});
  };

  const populateReconciliationCategories = () => {
    switch(selectedCategory){
      case 'Sample Information':
        return (
          <SampleInformation
            isDetailMode={isDetailMode}
            sampleInformationData={payload.sample_information} 
            onChange={onChange}
            removeRowTable={removeRowTable}
            locationData={{
              data: areasData,
              isFetching: areasIsFetching
            }}
            pitNameData={{
              data: pits,
              isFetching: pitIsFetching
            }}
          />
        );
      case 'True Thickness & Percentage':
        return (
          <TrueThicknessPercentage
            isDetailMode={isDetailMode}
            trueThicknessData={payload['true_thickness_&_percentage']} 
            onChange={onChange}
            onDeleteRow={removeRowTable}
            isDetail={false}
            otherData={payload}
          />
        );
      case 'TM':
        return (
          <Tm
            isDetailMode={isDetailMode}
            tmData={payload.tm} 
            onChange={onChange}
            onDeleteRow={removeRowTable}
            isDetail={false}
            otherData={payload}
          />
        );
      case 'MOIST':
        return (
          <Moist
            isDetailMode={isDetailMode}
            moistData={payload.moist} 
            onChange={onChange}
            onDeleteRow={removeRowTable}
            isDetail={false}
            otherData={payload}
          />
        );
      case 'VM':
        return (
          <Vm
            isDetailMode={isDetailMode}
            vmData={payload.vm} 
            onChange={onChange}
            onDeleteRow={removeRowTable}
            isDetail={false}
            otherData={payload}
          />
        );
      case 'ASH':
        return (
          <Ash
            isDetailMode={isDetailMode}
            ashData={payload.ash} 
            onChange={onChange}
            onDeleteRow={removeRowTable}
            isDetail={false}
            otherData={payload}
          />
        );
      case 'FC':
        return (
          <Fc
            isDetailMode={isDetailMode}
            fcData={payload.fc} 
            onChange={onChange}
            onDeleteRow={removeRowTable}
            isDetail={false}
            otherData={payload}
          />
        );
      case 'TS':
        return (
          <Ts
            isDetailMode={isDetailMode}
            tsData={payload.ts} 
            onChange={onChange}
            onDeleteRow={removeRowTable}
            isDetail={false}
            otherData={payload}
          />
        );
      case 'CV':
        return (
          <Cv
            isDetailMode={isDetailMode}
            cvData={payload.cv} 
            onChange={onChange}
            onDeleteRow={removeRowTable}
            isDetail={false}
            otherData={payload}
          />
        );
      case 'Remark':
        return (
          <Remark
            isDetailMode={isDetailMode}
            remarkData={payload.remarks} 
            onChange={onChange}
            onDeleteRow={removeRowTable}
            isDetail={false}
            otherData={payload}
          />
        );
      default:
        return;
    }
  };

  function generateActualData(key, data) {
    switch (key) {
      case 'sample_information':
        return generateSampleInformationData(data);
      case 'true_thickness_&_percentage':
        return generateTrueThicknessPercentageData(data);
      case 'tm':
        return generateTmData(data);
      case 'moist':
        return generateMoistData(data);
      case 'vm':
        return generateVmData(data);
      case 'ash':
        return generateAshData(data);
      case 'fc':
        return generateFcData(data);
      case 'ts':
        return generateTsData(data);
      case 'cv':
        return generateCvData(data);
      case 'remarks':
        return generateReconciliationRemarksData(data);
      default:
        return [];
    }
  }

  const replaceDuplicateDrillHoles=(data) => {
    const uniqueDrillHoles = {};
    for (let i = data.length - 1; i >= 0; i--) {
      const sample_id = data[i].sample_id;
      if (!uniqueDrillHoles[sample_id]) {
        uniqueDrillHoles[sample_id] = true;
      } else {
        data[i]={};
      }
    }
    const result = data.filter(item => Object.keys(item).length !== 0);
    return result;
  };

  const mappingExcelData = (data) => {
    let mappedData ={};
    let detailData ={};
    Object.keys(payload).forEach(key => {
      mappedData[key] = [...payload[key], ...generateActualData(key, data)];
      mappedData.sample_information = replaceDuplicateDrillHoles(mappedData?.sample_information);
      detailData[key] = generateActualData(key, data);
    });
    setPayload((isDetailMode || isEditMode)? {...detailData} : {...mappedData});
  };

  const handleDeleteFile = () => {
    setData(null);
  };

  const mandatoryValidationSampleInformation = () => {
    let validation = true;
    let temp = _.cloneDeep(payload.sample_information);
    temp.forEach((v) => {
      let keys = Object.keys(v);
      keys.forEach(key => {
        if(!isNumber(v[key]) && !v[key]){
          validation = false;
        }
      });
    });
    return validation;
  };

  const mandatoryValidationTrueThickness = () => {
    let validation = true;
    let temp = _.cloneDeep(payload['true_thickness_&_percentage']);
    temp.forEach((v) => {
      let keys = Object.keys(v);
      keys.forEach(key => {
        if(!isNumber(v[key]) && (!v[key] && !['sample_id', 'seam_percentage'].includes(key))){
          validation = false;
        }
      });
    });
    return validation;
  };

  const mandatoryValidationTm = () => {
    let validation = true;
    let temp = _.cloneDeep(payload.tm);
    temp.forEach((v) => {
      let keys = Object.keys(v);
      keys.forEach(key => {
        if(!isNumber(v[key]) && (!v[key] && !['sample_id', 'var_tm'].includes(key))){
          validation = false;
        }
      });
    });
    return validation;
  };

  const mandatoryValidationMoist = () => {
    let validation = true;
    let temp = _.cloneDeep(payload.moist);
    temp.forEach((v) => {
      let keys = Object.keys(v);
      keys.forEach(key => {
        if(!isNumber(v[key]) && (!v[key] && !['sample_id', 'var_moist'].includes(key))){
          validation = false;
        }
      });
    });
    return validation;
  };

  const mandatoryValidationVm = () => {
    let validation = true;
    let temp = _.cloneDeep(payload.vm);
    temp.forEach((v) => {
      let keys = Object.keys(v);
      keys.forEach(key => {
        if(!isNumber(v[key]) && (!v[key] && !['sample_id', 'var_vm'].includes(key))){
          validation = false;
        }
      });
    });
    return validation;
  };

  const mandatoryValidationAsh = () => {
    let validation = true;
    let temp = _.cloneDeep(payload.ash);
    temp.forEach((v) => {
      let keys = Object.keys(v);
      keys.forEach(key => {
        if(!isNumber(v[key]) && (!v[key] && !['sample_id', 'var_ash'].includes(key))){
          validation = false;
        }
      });
    });
    return validation;
  };

  const mandatoryValidationFc = () => {
    let validation = true;
    let temp = _.cloneDeep(payload.fc);
    temp.forEach((v) => {
      let keys = Object.keys(v);
      keys.forEach(key => {
        if(!isNumber(v[key]) && (!v[key] && !['sample_id', 'var_fc'].includes(key))){
          validation = false;
        }
      });
    });
    return validation;
  };

  const mandatoryValidationTs = () => {
    let validation = true;
    let temp = _.cloneDeep(payload.ts);
    temp.forEach((v) => {
      let keys = Object.keys(v);
      keys.forEach(key => {
        if(!isNumber(v[key]) && (!v[key] && !['sample_id', 'var_ts'].includes(key))){
          validation = false;
        }
      });
    });
    return validation;
  };

  const mandatoryValidationCv = () => {
    let validation = true;
    let temp = _.cloneDeep(payload.cv);
    temp.forEach((v) => {
      let keys = Object.keys(v);
      keys.forEach(key => {
        if(!isNumber(v[key]) && (!v[key] && !['sample_id', 'var_cv'].includes(key))){
          validation = false;
        }
      });
    });
    return validation;
  };

  const validateSubmit = () => {
    switch (false) {
      case mandatoryValidationSampleInformation():
        toast.error('Some field is mandatory in Sample Information',
          { toastId: 'validate-sample-information-toast-error' }
        );

        setSelectedCategory('Sample Information');
        return false;
      case mandatoryValidationTrueThickness():
        toast.error('Some field is mandatory in True Thickness & Percentage',
          { toastId: 'validate-true-thickness-toast-error' }
        );

        setSelectedCategory('True Thickness & Percentage');
        return false;
      case mandatoryValidationTm():
        toast.error('Some field is mandatory in TM',
          { toastId: 'validate-tm-toast-error' }
        );

        setSelectedCategory('TM');
        return false;
      case mandatoryValidationMoist():
        toast.error('Some field is mandatory in MOIST',
          { toastId: 'validate-moist-toast-error' }
        );

        setSelectedCategory('MOIST');
        return false;
      case mandatoryValidationVm():
        toast.error('Some field is mandatory in VM',
          { toastId: 'validate-vm-toast-error' }
        );

        setSelectedCategory('VM');
        return false;
      case mandatoryValidationAsh():
        toast.error('Some field is mandatory in ASH',
          { toastId: 'validate-ash-toast-error' }
        );

        setSelectedCategory('ASH');
        return false;
      case mandatoryValidationFc():
        toast.error('Some field is mandatory in FC',
          { toastId: 'validate-fc-toast-error' }
        );

        setSelectedCategory('FC');
        return false;
      case mandatoryValidationTs():
        toast.error('Some field is mandatory in TS',
          { toastId: 'validate-ts-toast-error' }
        );

        setSelectedCategory('TS');
        return false;
      case mandatoryValidationCv():
        toast.error('Some field is mandatory in CV',
          { toastId: 'validate-cv-toast-error' }
        );

        setSelectedCategory('CV');
        return false;
      default:
        return true;
    }
  };

  const onSubmit = (isDraft) => {
    if (!isDraft) {
      if (!validateSubmit()) {
        return;
      }
    }

    const fileInfo = data?.file_info || detailReconciliationData?.file_info;
    const body = generateBody({
      payload: payload,
      files: file,
      year: Number(moment(year).format('YYYY')),
      company: company,
      file_info: file ? null : fileInfo ?? null,
      is_draft: isDraft
    });
    setSuccessMessage(isDraft ? 'you have successfully saved draft of reconciliation ' 
      :'You have successfully submitted reconciliation');
    postReconciliation({ body });
  };

  const jsonParseMessage = () => {
    try {
      return JSON.parse(errorExcel.data.error.message);;  
    } catch{
      return null;
    }
  };

  const downloadFile = (url) => {
    const docLink = document.createElement('a');
    docLink.href = url;
    document.body.appendChild(docLink);
    docLink.click();
    document.body.removeChild(docLink);
  };

  const handleBackButton = () => {
    if(isDetailMode) {
      navigate(`/${URL.ACTIVITY_LEVEL}/${URL.GEOLOGY_OE}/reconciliation`);
    } else {
      showModal({
        isShown: true,
        type: 'confirmation',
        title:'Are you sure want to leave this page?',
        message: 'Changes you made may not be saved',
        onSubmit: () => {
          navigate(`/${URL.ACTIVITY_LEVEL}/${URL.GEOLOGY_OE}/reconciliation`);
          resetModal();
        }
      });
    }
  };

  const handleCloseSite = useCallback((ev) => ev.preventDefault() ,[]);
  
  useEffect(() => {
    PreventNavigation(isDetailMode, handleCloseSite);
  }, []);
  
  useEffect(() => {
    if(isDetailMode || isEditMode) getReconciliationById({id: reconciliationId});
  }, [isDetailMode, isEditMode]);

  useEffect(() => {
    if(!isEmpty(detailReconciliationData) && (isDetailMode || isEditMode)){
      setData({...detailReconciliationData?.content, ...detailReconciliationData?.file_info});
    }
  },[detailReconciliationData]);

  useEffect(() => {
    if(!isEmpty(data)){
      mappingExcelData(data);
    }
  },[data]);

  useEffect(() => {
    if (!isEmpty(errorExcel)) {
      setTransformErrorUpload(null);

      if (errorExcel.data) {
        const transformErrorUpload = errorExcel.status <= 400 ? jsonParseMessage(errorExcel.data.error.message) : null;
        setTransformErrorUpload(transformErrorUpload);
      }

      setIsModalOpen(true);
    }
  }, [errorExcel]);

  useEffect(() => {
    if (areasDataIsError || pitIsError || detailReconciliationIsError) {
      const generateToastId = () => {
        switch (true) {
          case areasDataError:
            return 'get-location';
          case pitError:
            return 'get-pit-name-list';
          case detailReconciliationError:
            return 'get-reconciliation-detail';
          default:
            return 'default';
        }
      };

      toast.error(
        transformError(areasDataError || pitError || detailReconciliationError ).message,
        { toastId: `${generateToastId()}-toast-error` }
      );
    }
  }, [areasDataIsError, pitIsError, detailReconciliationIsError]);

  useEffect(() => {
    if (postIsSuccess) {
      toast.success(successMessage, { toastId: 'post-quality-toast-success' });
      navigate(`/${URL.ACTIVITY_LEVEL}/${URL.GEOLOGY_OE}/reconciliation`);
    }

    if (postIsError || exportExcelIsError) {
      toast.error(
        transformError(postError || exportExcelError).message,
        { toastId: 'post-reconciliation-toast-error' }
      );
    }
  }, [postIsSuccess, postIsError, exportExcelIsError]);

  return (
    <div className="flex flex-col gap-4">
      {isDetailMode && isEmpty(data?.fileName) ? <></> : (
        <div
          className="bg-white p-5 overflow-x-auto rounded-2xl"
          style={{ maxWidth: wrapperWidth }}
        >
          <div className=" mb-8 flex flex-row justify-between">
            <div className=" text-[18px] font-bold">Upload Data</div>
          </div>
          <UploadDocumentExcel
            onDownload={() => downloadFile(data.url)}
            isDetailMode={isDetailMode}
            isEditMode={isEditMode}
            uploadExcel={uploadReconciliationExcel}
            getErrorExcel={setErrorExcel}
            onDelete={handleDeleteFile} 
            onChange={setData}
            getFile={setFile}
            value={data}
            excelRes={excelRes}
            excelResError={excelResError}
            excelResIsError={excelResIsError}
            excelResIsLoading={excelResIsLoading}
          />
        </div>
      )}
      <div
        className="bg-white p-5 overflow-x-auto rounded-2xl"
        style={{ maxWidth: wrapperWidth }}
      >
        <div className=" mb-8 flex flex-row justify-between">
          <div className='flex flex-row gap-2'>
            <ButtonAccent size={'2xl'} title="Download Template" 
              onClick={() => downloadFile(`${process.env.REACT_APP_BASE_URL}/Reconciliation_Template.xlsx`)}  
            />
            {isDetailMode && detailReconciliationData?.status !== 'Draft' && (
              <ButtonAccent 
                isBordered 
                size={'2xl'} 
                title="Export Excel" 
                isLoading={exportExcelIsLoading}
                onClick={() => 
                  exportExcel({
                    id: reconciliationId, 
                    company: company?.alias_name, 
                    year: moment(year).format('YYYY')
                  })
                }  
              />
            )}
          </div>
          <Tag className="px-3 py-2 font-bold rounded-2xl bg-[#2BB8A4]/[0.1] text-[#01B59C] border-none">
            <span className='text-sm'>
              {
                `
                  ${moment(year).format('YYYY')} / 
                  ${company?.alias_name} / 
                  Reconciliation
                `
              }
            </span>
          </Tag>
        </div>
        
        {(isDetailMode || isEditMode) && detailReconciliationIsFetching ? <LoadingIcon size={'sm'}/> :
          <> 
            {populateReconciliationCategories()}
            <CategoriesList
              isActual={true}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              categories={reconciliationCategories()}
              onAdd={addRowTable}
              isDetailMode={isDetailMode}
            />
          </>
        }
      </div>
      <div className="mt-5 flex flex-row items-center">
        { !isDetailMode && detailReconciliationData?.status !== 'Submitted' &&  (
          <ButtonAccent isBordered title="Save As Draft" isLoading={postIsLoading} onClick={() => onSubmit(true)} />
        )}
        <div className="ml-auto flex flex-row items-center gap-x-3">
          <ButtonAccent isBordered title="Back" isLoading={postIsLoading} onClick={handleBackButton} />
          { !isDetailMode && <ButtonAccent title="Next" isLoading={postIsLoading} onClick={() => onSubmit(false)} /> }
        </div>
      </div>
      <ModalInformation
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        errorMessage={
          !isEmpty(transformErrorUpload) ?
            transformErrorUpload?.invalid_on?.map((v, i) => {
              return (<div>
                <span>{`${i + 1}. Invalid data at row ${v.row}`}</span>
                <br />
                <span>{`and column ${v.column}`}</span>
              </div>);
            })
            : transformError(errorExcel).message
        }
      />
    </div>
  );
};

export default InputReconciliation;