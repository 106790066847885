import { isObject } from 'lodash';

const generateInitialDrillingSummaryActualData = {
  drill_hole_information:[
    {
      drill_hole_name: '',
      case_drill: '',
      check_permit: '',
      geologist: '',
    }
  ],
  drill_hole_location:[
    {
      resurvey_easting: '',
      resurvey_northing: '',
      resurvey_elevation: '',
      resurvey_latitude: '',
      resurvey_longitude: ''
    }
  ],
  drilling_date:[
    {
      date_start_drill_actual: '',
      date_final_log_actual: '',
      date_geophysical_logging_date_date_vertical_logging: '',
      check_date_final_log_wellcad: '',
      drilling_rate_by_final_log: '',
      speed: '',
      exceed_time: ''
    }
  ],
  drilling_depth:[
    {
      actual_drilling_depth: '',
      geophysical_depth: '',
      vertical_depth: '',
      invoice_depth: '',
      total_oh: '',
      total_core: '',
      depth_deviation: '',
      depth_recovery: '',
    }
  ],
  'time_&_depth_analysis':[
    {
      actual_time: '',
      start_late_time: '',
      pending_time: '',
      pending_plan_depth: '',
    }
  ],
  drilling_evidence:[
    {
      soft_pdf_20: {
        date:'',
        files:''
      },
      soft_pdf_100: {
        date:'',
        files:''
      },
      hard_20: {
        date:'',
        files:''
      },
      hard_100: {
        date:'',
        files:''
      },
      photo_core_box: {
        date:'',
        files:''
      },
      wellcad: {
        date:'',
        files:''
      },
      las_file: {
        date:'',
        files:''
      },
    }
  ],
  drilling_samples_information:[
    {
      lab_complete_date: '',
      number_of_coal_sample: '',
      number_of_amd_sample: '',
      number_of_geotech_sample: ''
    }
  ],
  ome:[
    {
      categories_a_loss: '',
      categories_p_loss: '',
      categories_q_loss: '',
      availability_loss_1: '',
      availability_loss_2: '',
      performance_loss_1: '',
      performance_loss_2: '',
      quality_loss_1: '',
      quality_loss_2: '',
      a: '',
      p: '',
      q: '',
      ome: ''
    }
  ],
  remarks:[
    {
      remark: ''
    }
  ]
};

const generateBody = (payload) => {
  const content = {
    code: 'drilling_summary',
    properties: Object.entries(payload.payload).map(([key, value]) => {
      let tempValue = {...value[0]};

      if (key !== 'drill_hole_information') {
        delete tempValue.drill_hole_name;
      }
      return {
        [key]: Object.entries(tempValue).map(([cKey, _]) => {
          return {
            [cKey]: value.map((v, index) => {
              return {
                index: index,
                value: isObject(v[cKey]) ? {date: v[cKey]?.date, files:v[cKey]?.files?.id} : v[cKey]
              };
            })
          };
        })
      };
    })
  };

  const company = {
    id: payload.company.id,
    alias_name: payload.company.alias_name,
    name: payload.company.name
  };

  const formData = new FormData();
  formData.append('files', payload.files ? payload.files : []);
  formData.append('year', payload.year);
  formData.append('company', JSON.stringify(company));
  formData.append('plan_type', payload.plan_type);
  formData.append('content', JSON.stringify(content));
  formData.append('version', payload.version);
  formData.append('is_draft', payload.is_draft);
  formData.append('file_info', payload.files ? '' : JSON.stringify(payload.file_info));

  return formData;
};

export {
  generateInitialDrillingSummaryActualData,
  generateBody
};