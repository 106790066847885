import moment from 'moment';
import { InputNumber } from 'antd';
import {
  every,
  isEmpty,
  toLower
} from 'lodash';

import { EMPTY, UNIT } from 'constant';
import { TYPE } from 'constant/MasterData';
import { generateFormat } from 'utils/PeriodUtility';
import {
  formatWithEnLocale,
  parseFromEnLocale,
  preventNonNumber
} from 'utils/CurrencyFormatter';

const stateKey = {
  maintenance: {
    key: 'maintenance',
    column: 'maintenance_column',
    table: 'maintenance_table',
    summary: 'maintenance_summary',
    change: 'maintenance_change'
  },
  pa: {
    key: 'pa',
    column: 'pa_column',
    table: 'pa_table',
    summary: 'pa_summary',
    change: 'pa_change'
  },
  ma: {
    key: 'ma',
    column: 'ma_column',
    table: 'ma_table',
    summary: 'ma_summary',
    change: 'ma_change'
  },
  ua: {
    key: 'ua',
    column: 'ua_column',
    table: 'ua_table',
    summary: 'ua_summary',
    change: 'ua_change'
  },
  ewh: {
    key: 'ewh',
    column: 'ewh_column',
    table: 'ewh_table',
    summary: 'ewh_summary',
    change: 'ewh_change'
  },
  productivity: {
    key: 'productivity',
    column: 'productivity_column',
    table: 'productivity_table',
    summary: 'productivity_summary',
    change: 'productivity_change'
  },
  production: {
    key: 'production',
    column: 'production_column',
    table: 'production_table',
    summary: 'production_summary',
    change: 'production_change'
  }
};

const headerColumns = [
  { dataIndex: 'description', title: 'Description' },
  { dataIndex: 'unit', title: 'Unit' }
];

const headerDefaultData = [
  { codeKey: 'calendar_days', description: 'Calendar Days', unit: 'Days' },
  { codeKey: 'holiday', description: 'Holiday', unit: 'Days' },
  { codeKey: 'working_days', description: 'Working Days', unit: 'Days' },
  { codeKey: 'total_hours', description: 'Total Hours', unit: 'Hours' },
  { codeKey: 'available_hours', description: 'Available Hours', unit: 'Hours' },
];

const generateHeaderColumns = (unit, periodName, periods) => {
  const loweredUnit = toLower(unit);
  const isWeekly = toLower(periodName) === 'weekly';
  const isMonthly = toLower(periodName) === 'monthly';
  const momentPeriod = (item) => moment(item, generateFormat(periods, loweredUnit, true, false));

  const generateDataIndex = (item) => momentPeriod(item)
    .format(generateFormat(periods, loweredUnit, false, true));

  const generateTitle = (item) => momentPeriod(item)
    .format(generateFormat(periods, loweredUnit, false, false));

  if (periods && periods.length) {
    if (isWeekly || isMonthly) {
      return [
        ...headerColumns,
        {
          dataIndex: isWeekly
            ? 'week'
            : moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY'),
          title: isWeekly
            ? 'Week'
            : moment(periods[0], 'DD MMM YYYY').format('MMM YYYY')
        }
      ];
    }
    
    return [
      ...headerColumns,
      ...periods.map((item) => {
        return {
          dataIndex: generateDataIndex(item),
          title: generateTitle(item)
        };
      })
    ];
  }

  return headerColumns;
};

const generateHeaderData = (unit, periodName, periods, data) => {
  const loweredUnit = toLower(unit);
  const isWeekly = toLower(periodName) === 'weekly';
  const isMonthly = toLower(periodName) === 'monthly';
  const momentPeriod = (item) => moment(item, generateFormat(periods, loweredUnit, true, false));
  
  if (data) {
    const overBurdenProdLossTime = data.contents.find((content) => content.code === 'production_lost_time_ob');
    const lossTime = overBurdenProdLossTime.properties.find((prop) => prop.code === 'lost_time');

    if (periods && periods.length) {
      return headerDefaultData.map((item) => {
        const newItem = { ...item };
        const propItem = lossTime.properties.find((prop) => {
          switch (true) {
            case prop.code === item.codeKey:
              return true;
            case prop.code === 'total_hour':
              return true;
            case prop.code === 'available_hour':
              return true;
            default:
              return false;
          }
        });

        switch (true) {
          case isWeekly: {
            const propDate = propItem.properties
              .find((prop) =>
                moment(
                  moment(prop.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                  'YYYY-MM-DD'
                ).isSame(
                  moment(periods[0], 'DD MMM YYYY').format('YYYY-MM-DD'),
                  'day'
                )
              );

            newItem.week = propDate
              ? propDate.value
              : EMPTY.STRING;

            return newItem;
          }
          case isMonthly: {
            const propDate = propItem.properties
              .find((prop) =>
                moment(
                  moment(prop.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                  'YYYY-MM-DD'
                ).isSame(
                  moment(periods[0], 'DD MMM YYYY').format('YYYY-MM-DD'),
                  'day'
                )
              );

            newItem[moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY')] = propDate
              ? propDate.value
              : EMPTY.STRING;

            return newItem;
          }
          default: {
            const itemPeriod = (period) => momentPeriod(period)
              .format(generateFormat(periods, loweredUnit, false, true));

            periods.forEach((period) => {
              const propDate = propItem.properties
                .find((prop) =>
                  moment(
                    moment(prop.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                    'YYYY-MM-DD'
                  ).isSame(
                    momentPeriod(period).format('YYYY-MM-DD'),
                    'day'
                  )
                );

              newItem[itemPeriod(period)] = propDate
                ? propDate.value
                : EMPTY.STRING;
            });

            return newItem;
          }
        }
      });
    }

    return headerDefaultData;
  }

  return headerDefaultData;
};

const tableDataColumns = [
  {
    dataIndex: 'name',
    title: 'Equipment Name',
    render: (text, record, index) => (
      <span>Overburden {index + 1}</span>
    )
  },
  {
    dataIndex: 'alias_name',
    title: 'Equipment Type',
    render: (text) => {
      const splitAliasName = text.split('-');
      
      return (
        <span>
          {
            splitAliasName.length
              ? splitAliasName[0]
              : EMPTY.STRING
          }
        </span>
      );
    }
  },
  {
    dataIndex: 'alias_name',
    title: 'Equipment No',
    render: (text) => {
      const splitAliasName = text.split('-');

      return (
        <span>
          {
            splitAliasName.length
              ? splitAliasName[1]
              : EMPTY.STRING
          }
        </span>
      );
    }
  }
];

const generateTableColumns = (
  isCanBeZero,
  isDetailMode,
  isInput,
  isPercentage,
  model,
  unit,
  periodName,
  periods,
  onChange
) => {
  const loweredUnit = toLower(unit);
  const isWeekly = toLower(periodName) === 'weekly';
  const isMonthly = toLower(periodName) === 'monthly';
  const isYearly = toLower(periodName) === 'yearly';
  const momentPeriod = (item) => moment(item, generateFormat(periods, loweredUnit, true, false));

  const generateDataIndex = (item) => momentPeriod(item)
    .format(generateFormat(periods, loweredUnit, false, true));

  const generateTitle = (item) => momentPeriod(item)
    .format(generateFormat(periods, loweredUnit, false, false));

  const generateUnitFormat = (item) => {
    switch (true) {
      case isWeekly:
        return 'week';
      case isMonthly:
        return moment(item, 'DD MMM YYYY').format('MMM_YYYY');
      default:
        return generateDataIndex(item);
    }
  };

  const generateDynamicColumns = () => {
    const isError = (value) => {
      if (isCanBeZero) {
        return Boolean(value !== null && value !== undefined)
          ? EMPTY.STRING
          : 'error';
      }

      return Boolean(value)
        ? EMPTY.STRING
        : 'error';
    };
    
    if (isWeekly) {
      return [{
        dataIndex: 'week',
        title: 'Week',
        render: (text, record, index) => {
          switch (true) {
            case isInput:
              return (
                <InputNumber
                  key={`key-week-${model}-${index + 1}`}
                  disabled={isDetailMode}
                  min={EMPTY.NUMBER}
                  value={record.week}
                  onChange={(ev) => onChange(ev, record, generateUnitFormat(periods[0]))}
                  onKeyDown={preventNonNumber}
                  status={isError(record.week)}
                  parser={parseFromEnLocale}
                  formatter={formatWithEnLocale}
                  className="w-32"
                />
              );
            case isPercentage:
              return (
                <span>
                  {
                    text
                      ? text.toFixed(1)
                      : text
                  } %
                </span>
              );
            default:
              return (
                <span>
                  {
                    text
                      ? Number(text.toFixed(1)).toLocaleString('en-US')
                      : 0
                  }
                </span>
              );
          }
        }
      }];
    }

    return isMonthly
      ? [{
        dataIndex: moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY'),
        title:  moment(periods[0], 'DD MMM YYYY').format('MMM YYYY'),
        render: (text, record, index) => {
          switch (true) {
            case isInput:
              return (
                <InputNumber
                  key={`key-monthly-${model}-${index + 1}`}
                  disabled={isDetailMode}
                  min={EMPTY.NUMBER}
                  value={record[moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY')]}
                  onChange={(ev) => onChange(ev, record, generateUnitFormat(periods[0]))}
                  onKeyDown={preventNonNumber}
                  status={isError(record[moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY')])}
                  parser={parseFromEnLocale}
                  formatter={formatWithEnLocale}
                  className="w-32"
                />
              );
            case isPercentage:
              return (
                <span>
                  {
                    text
                      ? text.toFixed(1)
                      : text
                  } %
                </span>
              );
            default:
              return (
                <span>
                  {
                    text
                      ? Number(text.toFixed(1)).toLocaleString('en-US')
                      : 0
                  }
                </span>
              );
          }
        }
      }]
      : periods.map((item) => {
        return {
          dataIndex: generateDataIndex(item),
          title: generateTitle(item),
          render: (text, record, index) => {
            switch (true) {
              case isInput:
                return (
                  <InputNumber
                    key={`key-${model}-${index + 1}`}
                    disabled={isDetailMode}
                    min={EMPTY.NUMBER}
                    value={record[generateDataIndex(item)]}
                    onChange={(ev) => onChange(ev, record, generateUnitFormat(item))}
                    onKeyDown={preventNonNumber}
                    status={isError(record[generateDataIndex(item)])}
                    parser={parseFromEnLocale}
                    formatter={formatWithEnLocale}
                    className={isYearly ? 'w-14' : 'w-32'}
                  />
                );
              case isPercentage:
                return (
                  <span>
                    {
                      text
                        ? text.toFixed(1)
                        : text
                    } %
                  </span>
                );
              default:
                return (
                  <span>
                    {
                      text
                        ? Number(text.toFixed(1)).toLocaleString('en-US')
                        : 0
                    }
                  </span>
                );
            }
          }
        };
      });
  };
  
  return [
    ...tableDataColumns,
    ...generateDynamicColumns()
  ];
};

const generateTableData = (
  unit,
  periodName,
  periods,
  data,
  detailData,
  code
) => {
  if (data && data.length) {
    const loweredUnit = toLower(unit);
    const isWeekly = toLower(periodName) === 'weekly';
    const isMonthly = toLower(periodName) === 'monthly';
    const momentPeriod = (item) => moment(item, generateFormat(periods, loweredUnit, true, false));
    const selectedEquipments = detailData?.find((item) => item.code === 'equipments');
    const excludedCode = Boolean(code === 'maintenance_hours' || code === 'productivity');
    
    return data.map((item) => {
      const newItem = { ...item };

      const detailEquipment = selectedEquipments?.properties?.find(
        (item) => item.value.some(
          (propItem) => propItem.code === 'equipment_no' && newItem.alias_name.includes(propItem.value)
        )
      );
      
      switch (true) {
        case isWeekly: {
          if (detailEquipment && code) {
            const selectedEquipmentByCode = detailEquipment.properties.find((item) => item.code === code);
            newItem.week = selectedEquipmentByCode.properties[0].value || 0;

            return newItem;
          }

          newItem.week = excludedCode
            ? undefined
            : 0;

          return newItem;
        }
        case isMonthly: {
          if (detailEquipment && code) {
            const selectedEquipmentByCode = detailEquipment.properties.find((item) => item.code === code);
            newItem[moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY')] =
              selectedEquipmentByCode.properties[0].value || 0;

            return newItem;
          }

          newItem[moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY')] = excludedCode
            ? undefined
            : 0;

          return newItem;
        }
        default: {
          if (detailEquipment && code) {
            const selectedEquipmentByCode = detailEquipment.properties.find((item) => item.code === code);
            periods.forEach((period) => {
              const selectedEquipmentPropByDate = selectedEquipmentByCode.properties.find(
                (propItem) => propItem.date === momentPeriod(period).format('DD-MM-YYYY')
              );
              
              newItem[
                momentPeriod(period).format(generateFormat(periods, loweredUnit, false, true))
              ] = selectedEquipmentPropByDate.value || 0;
            });

            return newItem;
          }
          
          periods.forEach((period) => {
            newItem[
              momentPeriod(period).format(generateFormat(periods, loweredUnit, false, true))
            ] = excludedCode
              ? undefined
              : 0;
          });

          return newItem;
        }
      }
    });
  }
  
  return [];
};

const generateSummaries = (unit, periodName, periods) => {
  const loweredUnit = toLower(unit);
  const isWeekly = toLower(periodName) === 'weekly';
  const isMonthly = toLower(periodName) === 'monthly';
  const momentPeriod = (item) => moment(item, generateFormat(periods, loweredUnit, true, false));

  const generateDataIndex = (item) => momentPeriod(item)
    .format(generateFormat(periods, loweredUnit, false, true));

  switch (true) {
    case isWeekly:
      return [{ week: 0 }];
    case isMonthly:
      return [{
        [moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY')]: 0
      }];
    default:
      return periods.map((item) => ({
        [generateDataIndex(item)]: 0
      }));
  }
};

const pickSummaryData = (
  unit,
  periodName,
  periods,
  item,
  itemIndex
) => {
  const loweredUnit = toLower(unit);
  const isWeekly = toLower(periodName) === 'weekly';
  const isMonthly = toLower(periodName) === 'monthly';

  const reduceFractionDigit = (value) => {
    if (value) {
      return value.toFixed(1);
    }

    return value;
  };

  switch (true) {
    case isWeekly:
      return reduceFractionDigit(item.week);
    case isMonthly:
      return reduceFractionDigit(item[moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY')]);
    default:
      return reduceFractionDigit(item[
        moment(
          periods[itemIndex],
          generateFormat(periods, loweredUnit, true, false)
        ).format(generateFormat(periods, loweredUnit, false, true))
      ]);
  }
};

const calculatePhysicalAvailability = (
  headers,
  unit,
  periodName,
  periods,
  period,
  paItem,
  maintenanceItem
) => {
  /**
   * PA formula : ((Available Hours - Maintenance) / Available Hours) * 100
   * */
  const isWeekly = toLower(periodName) === 'weekly';
  const isMonthly = toLower(periodName) === 'monthly';
  const availableHrs = headers.find((item) => item.codeKey === 'available_hour' || item.codeKey === 'available_hours');
  const momentPeriod = (unit, item) => {
    return moment(item, toLower(unit) === UNIT.DAY ? 'DD MMM YYYY' : 'MMM YYYY')
      .format(toLower(unit) === UNIT.DAY ? 'DD_MMM_YYYY' : 'MMM_YYYY');
  };

  if (paItem.id === maintenanceItem.id) {
    let currPeriodAvailableHrs;
    let currMaintenanceHrs;
    
    switch (true) {
      case isWeekly: {
        currPeriodAvailableHrs = availableHrs?.week;
        currMaintenanceHrs = maintenanceItem?.week;

        if (currPeriodAvailableHrs && currMaintenanceHrs) {
          return {
            ...paItem,
            week: (
              ((currPeriodAvailableHrs - currMaintenanceHrs) / currPeriodAvailableHrs) * 100
            )
          };
        }
        
        return {
          ...paItem,
          week: 0
        };
      }
      case isMonthly: {
        const periodFormat = moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY');
        if (availableHrs && maintenanceItem) {
          currPeriodAvailableHrs = availableHrs[periodFormat];
          currMaintenanceHrs = maintenanceItem[periodFormat];

          if (currPeriodAvailableHrs && currMaintenanceHrs) {
            return {
              ...paItem,
              [periodFormat]: (
                ((currPeriodAvailableHrs - currMaintenanceHrs) / currPeriodAvailableHrs) * 100
              )
            };
          }

          return {
            ...paItem,
            [periodFormat]: 0
          };
        }

        return {
          ...paItem,
          [periodFormat]: 0
        };
      }
      default: {
        if (availableHrs && maintenanceItem) {
          currPeriodAvailableHrs = availableHrs[momentPeriod(unit, period)];
          currMaintenanceHrs = maintenanceItem[momentPeriod(unit, period)];

          if (currPeriodAvailableHrs && currMaintenanceHrs) {
            return {
              ...paItem,
              [momentPeriod(unit, period)]: (
                ((currPeriodAvailableHrs - currMaintenanceHrs) / currPeriodAvailableHrs) * 100
              )
            };
          }

          return {
            ...paItem,
            [momentPeriod(unit, period)]: 0
          };
        }

        return {
          ...paItem,
          [momentPeriod(unit, period)]: 0
        };
      }
    }
  }

  return { ...paItem };
};

const calculateMechanicalAvailability = (
  lossData,
  headers,
  unit,
  periodName,
  periods,
  period,
  maItem,
  maintenanceItem
) => {
  /**
   * MA formula : ((Available Hours - Production Lost Time - Maintenance) / (Available Hours - Production Lost Time) * 100)
   * */
  const isWeekly = toLower(periodName) === 'weekly';
  const isMonthly = toLower(periodName) === 'monthly';
  const overBurdenProdLossTime = lossData?.contents.find((content) => content.code === 'production_lost_time_ob');
  const totalLossTime = overBurdenProdLossTime?.properties.find((prop) => prop.code === 'total_lost_time');
  const totalLossTimeProps = totalLossTime?.properties.find((item) => toLower(item.code) === 'monthly');
  
  const availableHrs = headers.find((item) => item.codeKey === 'available_hour' || item.codeKey === 'available_hours');
  const momentPeriod = (unit, item) => {
    return moment(item, toLower(unit) === UNIT.DAY ? 'DD MMM YYYY' : 'MMM YYYY')
      .format(toLower(unit) === UNIT.DAY ? 'DD_MMM_YYYY' : 'MMM_YYYY');
  };

  if (maItem.id === maintenanceItem.id) {
    let currPeriodAvailableHrs;
    let currMaintenanceHrs;
    let currLossTime;
    
    switch (true) {
      case isWeekly: {
        currPeriodAvailableHrs = availableHrs?.week;
        currMaintenanceHrs = maintenanceItem?.week;
        currLossTime = totalLossTimeProps?.properties.find((prop) =>
          moment(
            moment(prop.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
            'YYYY-MM-DD'
          ).isSame(
            moment(periods[0], 'DD MMM YYYY').format('YYYY-MM-DD'),
            'day'
          )
        );

        if (currPeriodAvailableHrs && currMaintenanceHrs && currLossTime) {
          return {
            ...maItem,
            week: (
              ((currPeriodAvailableHrs - currLossTime.value - currMaintenanceHrs) / (currPeriodAvailableHrs - currLossTime.value)) * 100
            )
          };
        }

        return { ...maItem, week: 0 };
      }
      case isMonthly: {
        const periodFormat = moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY');

        if (availableHrs && maintenanceItem && totalLossTimeProps) {
          currPeriodAvailableHrs = availableHrs[periodFormat];
          currMaintenanceHrs = maintenanceItem[periodFormat];
          currLossTime = totalLossTimeProps.properties.find((prop) =>
            moment(
              moment(prop.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
              'YYYY-MM-DD'
            ).isSame(
              moment(periods[0], 'DD MMM YYYY').format('YYYY-MM-DD'),
              'day'
            )
          );

          if (currPeriodAvailableHrs && currMaintenanceHrs && currLossTime) {
            return {
              ...maItem,
              [periodFormat]: (
                ((currPeriodAvailableHrs - currLossTime.value - currMaintenanceHrs) / (currPeriodAvailableHrs - currLossTime.value)) * 100
              )
            };
          }

          return {
            ...maItem,
            [periodFormat]: 0
          };
        }

        return { ...maItem, [periodFormat]: 0 };
      }
      default: {
        if (availableHrs && maintenanceItem && totalLossTimeProps) {
          currPeriodAvailableHrs = availableHrs[momentPeriod(unit, period)];
          currMaintenanceHrs = maintenanceItem[momentPeriod(unit, period)];
          currLossTime = totalLossTimeProps.properties.find((prop) =>
            moment(
              moment(prop.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
              'YYYY-MM-DD'
            ).isSame(
              moment(period, toLower(unit) === UNIT.DAY ? 'DD MMM YYYY' : 'MMM YYYY').format('YYYY-MM-DD'),
              'day'
            )
          );

          if (currPeriodAvailableHrs && currMaintenanceHrs && currLossTime) {
            return {
              ...maItem,
              [momentPeriod(unit, period)]: (
                ((currPeriodAvailableHrs - currLossTime.value - currMaintenanceHrs) / (currPeriodAvailableHrs - currLossTime.value)) * 100
              )
            };
          }

          return {
            ...maItem,
            [momentPeriod(unit, period)]: 0
          };
        }
        
        return { ...maItem, [momentPeriod(unit, period)]: 0 };
      }
    }
  }

  return { ...maItem };
};

const calculateUseOfAvailability = (
  lossData,
  headers,
  unit,
  periodName,
  periods,
  period,
  uaItem,
  maintenanceItem
) => {
  /**
   * UA formula : ((Available Hours - Production Lost Time - Maintenance) / (Available Hour - Maintenance) * 100)
   * */
  const isWeekly = toLower(periodName) === 'weekly';
  const isMonthly = toLower(periodName) === 'monthly';
  const overBurdenProdLossTime = lossData?.contents.find((content) => content.code === 'production_lost_time_ob');
  const totalLossTime = overBurdenProdLossTime?.properties.find((prop) => prop.code === 'total_lost_time');
  const totalLossTimeProps = totalLossTime?.properties.find((item) => toLower(item.code) === 'monthly');

  const availableHrs = headers.find((item) => item.codeKey === 'available_hour' || item.codeKey === 'available_hours');
  const momentPeriod = (unit, item) => {
    return moment(item, toLower(unit) === UNIT.DAY ? 'DD MMM YYYY' : 'MMM YYYY')
      .format(toLower(unit) === UNIT.DAY ? 'DD_MMM_YYYY' : 'MMM_YYYY');
  };

  if (uaItem.id === maintenanceItem.id) {
    let currPeriodAvailableHrs;
    let currMaintenanceHrs;
    let currLossTime;
    
    switch (true) {
      case isWeekly: {
        currPeriodAvailableHrs = availableHrs?.week;
        currMaintenanceHrs = maintenanceItem?.week;
        currLossTime = totalLossTimeProps?.properties.find((prop) =>
          moment(
            moment(prop.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
            'YYYY-MM-DD'
          ).isSame(
            moment(periods[0], 'DD MMM YYYY').format('YYYY-MM-DD'),
            'day'
          )
        );

        if (currPeriodAvailableHrs && currMaintenanceHrs && currLossTime) {
          return {
            ...uaItem,
            week: (
              ((currPeriodAvailableHrs - currLossTime.value - currMaintenanceHrs) / (currPeriodAvailableHrs - currMaintenanceHrs)) * 100
            )
          };
        }

        return { ...uaItem, week: 0 };
      }
      case isMonthly: {
        const periodFormat = moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY');

        if (availableHrs && maintenanceItem && totalLossTimeProps) {
          currPeriodAvailableHrs = availableHrs[periodFormat];
          currMaintenanceHrs = maintenanceItem[periodFormat];
          currLossTime = totalLossTimeProps.properties.find((prop) =>
            moment(
              moment(prop.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
              'YYYY-MM-DD'
            ).isSame(
              moment(periods[0], 'DD MMM YYYY').format('YYYY-MM-DD'),
              'day'
            )
          );

          if (currPeriodAvailableHrs && currMaintenanceHrs && currLossTime) {
            return {
              ...uaItem,
              [periodFormat]: (
                ((currPeriodAvailableHrs - currLossTime.value - currMaintenanceHrs) / (currPeriodAvailableHrs - currMaintenanceHrs)) * 100
              )
            };
          }

          return {
            ...uaItem,
            [periodFormat]: 0
          };
        }

        return { ...uaItem, [periodFormat]: 0 };
      }
      default: {
        if (availableHrs && maintenanceItem && totalLossTimeProps) {
          currPeriodAvailableHrs = availableHrs[momentPeriod(unit, period)];
          currMaintenanceHrs = maintenanceItem[momentPeriod(unit, period)];
          currLossTime = totalLossTimeProps.properties.find((prop) =>
            moment(
              moment(prop.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
              'YYYY-MM-DD'
            ).isSame(
              moment(period, toLower(unit) === UNIT.DAY ? 'DD MMM YYYY' : 'MMM YYYY').format('YYYY-MM-DD'),
              'day'
            )
          );

          if (currPeriodAvailableHrs && currMaintenanceHrs && currLossTime) {
            return {
              ...uaItem,
              [momentPeriod(unit, period)]: (
                ((currPeriodAvailableHrs - currLossTime.value - currMaintenanceHrs) / (currPeriodAvailableHrs - currMaintenanceHrs)) * 100
              )
            };
          }

          return {
            ...uaItem,
            [momentPeriod(unit, period)]: 0
          };
        }

        return { ...uaItem, [momentPeriod(unit, period)]: 0 };
      }
    }
  }
  
  return { ...uaItem };
};

const calculateEffectiveWorkingHours = (
  headers,
  unit,
  periodName,
  periods,
  period,
  ewhItem,
  paItem,
  uaItem
) => {
  /**
   * EWH formula : ((Total Hours - ( (1 - (PA / 100)) * 24 * Calendar Days) ) * (UA / 100))
   * */
  const isWeekly = toLower(periodName) === 'weekly';
  const isMonthly = toLower(periodName) === 'monthly';
  const totalHours = headers.find((item) => item.codeKey === 'total_hour' || item.codeKey === 'total_hours');
  const calendarDays = headers.find((item) => item.codeKey === 'calendar_day' || item.codeKey === 'calendar_days');
  const momentPeriod = (unit, item) => {
    return moment(item, toLower(unit) === UNIT.DAY ? 'DD MMM YYYY' : 'MMM YYYY')
      .format(toLower(unit) === UNIT.DAY ? 'DD_MMM_YYYY' : 'MMM_YYYY');
  };
  const calculateWithFormula = (totalHrs, paHrs, calendarDays, uaHrs) => {
    const paCalculation = Number(1 - (paHrs / 100));
    const paWithCalendar = Number(paCalculation * 24 * calendarDays);
    const paCalendarWithTotalHours = Number(totalHrs - paWithCalendar);
    const uaCalculation = Number(uaHrs / 100);
    return Number(paCalendarWithTotalHours * uaCalculation);
  };
  
  if (ewhItem.id === paItem.id && ewhItem.id === uaItem.id) {
    let currTotalHours;
    let currCalendarDays;
    let currPaHrs;
    let currUaHrs;
    
    switch (true) {
      case isWeekly: {
        currTotalHours = totalHours?.week;
        currCalendarDays = calendarDays?.week;
        currPaHrs = paItem?.week;
        currUaHrs = uaItem?.week;

        if (currTotalHours && currCalendarDays && currPaHrs && currUaHrs) {
          return {
            ...ewhItem,
            week: calculateWithFormula(currTotalHours, currPaHrs, currCalendarDays, currUaHrs)
          };
        }

        return {
          ...ewhItem,
          week: 0
        };
      }
      case isMonthly: {
        const periodFormat = moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY');

        if (totalHours && calendarDays && paItem && uaItem) {
          currTotalHours = totalHours[periodFormat];
          currCalendarDays = calendarDays[periodFormat];
          currPaHrs = paItem[periodFormat];
          currUaHrs = uaItem[periodFormat];

          if (currTotalHours && currCalendarDays && currPaHrs && currUaHrs) {
            return {
              ...ewhItem,
              [periodFormat]: calculateWithFormula(currTotalHours, currPaHrs, currCalendarDays, currUaHrs)
            };
          }

          return {
            ...ewhItem,
            [periodFormat]: 0
          };
        }

        return {
          ...ewhItem,
          [periodFormat]: 0
        };
      }
      default: {
        if (totalHours && calendarDays && paItem && uaItem) {
          currTotalHours = totalHours[momentPeriod(unit, period)];
          currCalendarDays = calendarDays[momentPeriod(unit, period)];
          currPaHrs = paItem[momentPeriod(unit, period)];
          currUaHrs = uaItem[momentPeriod(unit, period)];

          if (currTotalHours && currCalendarDays && currPaHrs && currUaHrs) {
            return {
              ...ewhItem,
              [momentPeriod(unit, period)]: calculateWithFormula(currTotalHours, currPaHrs, currCalendarDays, currUaHrs)
            };
          }

          return {
            ...ewhItem,
            [momentPeriod(unit, period)]: 0
          };
        }

        return {
          ...ewhItem,
          [momentPeriod(unit, period)]: 0
        };
      }
    }
  }
  
  return { ...ewhItem };
};

const calculateOverBurdenProduction = (
  unit,
  periodName,
  periods,
  period,
  obProdItem,
  ewhItem,
  productivityItem
) => {
  /**
   * Overburden Production formula : Productivity * EWH
   * */
  const isWeekly = toLower(periodName) === 'weekly';
  const isMonthly = toLower(periodName) === 'monthly';
  const momentPeriod = (unit, item) => {
    return moment(item, toLower(unit) === UNIT.DAY ? 'DD MMM YYYY' : 'MMM YYYY')
      .format(toLower(unit) === UNIT.DAY ? 'DD_MMM_YYYY' : 'MMM_YYYY');
  };
  
  if (obProdItem.id === productivityItem.id && obProdItem.id === ewhItem.id) {
    let currProductivityHrs;
    let currEwhHrs;
    
    switch (true) {
      case isWeekly: {
        currProductivityHrs = productivityItem?.week;
        currEwhHrs = ewhItem?.week;

        if (currProductivityHrs && currEwhHrs) {
          return {
            ...obProdItem,
            week: (currProductivityHrs * currEwhHrs)
          };
        }

        return {
          ...obProdItem,
          week: 0
        };
      }
      case isMonthly: {
        const periodFormat = moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY');

        if (productivityItem && ewhItem) {
          currProductivityHrs = productivityItem[periodFormat];
          currEwhHrs = ewhItem[periodFormat];

          if (currProductivityHrs && currEwhHrs) {
            return {
              ...obProdItem,
              [periodFormat]: (currProductivityHrs * currEwhHrs)
            };
          }

          return {
            ...obProdItem,
            [periodFormat]: 0
          };
        }

        return {
          ...obProdItem,
          [periodFormat]: 0
        };
      }
      default: {
        if (productivityItem && ewhItem) {
          currProductivityHrs = productivityItem[momentPeriod(unit, period)];
          currEwhHrs = ewhItem[momentPeriod(unit, period)];

          if (currProductivityHrs && currEwhHrs) {
            return {
              ...obProdItem,
              [momentPeriod(unit, period)]: (currProductivityHrs * currEwhHrs)
            };
          }

          return {
            ...obProdItem,
            [momentPeriod(unit, period)]: 0
          };
        }

        return {
          ...obProdItem,
          [momentPeriod(unit, period)]: 0
        };
      }
    }
  }
  
  return { ...obProdItem };
};

const calculateSummary = (
  unit,
  periodName,
  periods,
  sum
) => {
  const isWeekly = toLower(periodName) === 'weekly';
  const isMonthly = toLower(periodName) === 'monthly';
  const momentPeriod = (unit, item) => {
    return moment(item, toLower(unit) === UNIT.DAY ? 'DD MMM YYYY' : 'MMM YYYY')
      .format(toLower(unit) === UNIT.DAY ? 'DD_MMM_YYYY' : 'MMM_YYYY');
  };
  
  switch (true) {
    case isWeekly: {
      return [{ week: sum.week }];
    }
    case isMonthly: {
      const periodFormat = moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY');
      return [{
        [periodFormat]: sum[periodFormat]
      }];
    }
    default: {
      return periods.map((period) => ({
        [momentPeriod(unit, period)]: sum[momentPeriod(unit, period)]
      }));
    }
  }
};

const generateBody = (
  isDraft,
  id,
  unit,
  periodName,
  periods,
  headers,
  equipments,
  maintenanceHoursData,
  physicalData,
  mechanicalData,
  useOfData,
  effectiveWorkingData,
  productivityData,
  overBurdenProdData,
  physicalAvgData,
  mechanicalAvgData,
  useOfAvgData,
  effectiveWorkingAvgData,
  productivityAvgData,
  overBurdenProdAvgData
) => {
  const isWeekly = toLower(periodName) === 'weekly';
  const isMonthly = toLower(periodName) === 'monthly';
  const momentPeriod = (unit, item) => {
    return moment(item, toLower(unit) === UNIT.DAY ? 'DD MMM YYYY' : 'MMM YYYY')
      .format(toLower(unit) === UNIT.DAY ? 'DD_MMM_YYYY' : 'MMM_YYYY');
  };

  const momentDatePeriod = (unit, item) => {
    return moment(item, toLower(unit) === UNIT.DAY ? 'DD MMM YYYY' : 'MMM YYYY')
      .format('DD-MM-YYYY');
  };
  
  const generatePeriodFormat = (period) => {
    switch (true) {
      case isWeekly:
        return 'week';
      case isMonthly:
        return moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY');
      default:
        return momentPeriod(unit, period);
    }
  };

  const generateEqTypeNumber = (aliasName, isType) => {
    if (aliasName) {
      const aliasSplit = aliasName.split('-');

      if (aliasSplit && aliasSplit.length) {
        if (isType) {
          return aliasSplit[0] || EMPTY.STRING;
        }

        return aliasSplit[1] || EMPTY.STRING;
      }

      return EMPTY.STRING;
    }

    return EMPTY.STRING;
  };
  
  const generateData = (hourData, equipment, period) => {
    const selectedData = hourData.find((item) => item.id === equipment.id);
    
    if (selectedData) {
      return selectedData[generatePeriodFormat(period)];
    }
    
    return EMPTY.NUMBER;
  };

  const generateSum = (sumData, period) => {
    const selectedData = sumData.find((item) => {
      const key = Object.keys(item)[0];

      switch (true) {
        case isWeekly:
          return key === 'week';
        case isMonthly:
          return moment(
            moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY')
          ).isSame(key, 'month');
        default:
          return moment(
            momentPeriod(unit, period)
          ).isSame(key, 'day');
      }
    });

    if (selectedData) {
      return selectedData[generatePeriodFormat(period)];
    }

    return EMPTY.NUMBER;
  };

  return {
    assumption_id: id,
    is_draft: isDraft,
    type: TYPE.OVER_BURDEN,
    contents: [
      {
        code: 'period_details',
        properties: headers.map((item) => ({
          code: item.codeKey,
          properties: periods.map((period) => ({
            type: periodName,
            date: momentDatePeriod(unit, period),
            value: item[generatePeriodFormat(period)]
          }))
        }))
      },
      {
        code: 'equipments',
        properties: equipments.map((item) => ({
          code: 'equipment_details',
          value: [
            {
              code: 'equipment_name',
              value: item.name
            },
            {
              code: 'equipment_type',
              value: generateEqTypeNumber(item.alias_name, true)
            },
            {
              code: 'equipment_no',
              value: generateEqTypeNumber(item.alias_name, false)
            }
          ],
          properties: [
            {
              code: 'maintenance_hours',
              properties: periods.map((period) => ({
                type: periodName,
                date: momentDatePeriod(unit, period),
                value: generateData(maintenanceHoursData, item, period)
              }))
            },
            {
              code: 'pa',
              properties: periods.map((period) => ({
                type: periodName,
                date: momentDatePeriod(unit, period),
                value: generateData(physicalData, item, period)
              }))
            },
            {
              code: 'ma',
              properties: periods.map((period) => ({
                type: periodName,
                date: momentDatePeriod(unit, period),
                value: generateData(mechanicalData, item, period)
              }))
            },
            {
              code: 'ua',
              properties: periods.map((period) => ({
                type: periodName,
                date: momentDatePeriod(unit, period),
                value: generateData(useOfData, item, period)
              }))
            },
            {
              code: 'ewh',
              properties: periods.map((period) => ({
                type: periodName,
                date: momentDatePeriod(unit, period),
                value: generateData(effectiveWorkingData, item, period)
              }))
            },
            {
              code: 'productivity',
              properties: periods.map((period) => ({
                type: periodName,
                date: momentDatePeriod(unit, period),
                value: generateData(productivityData, item, period)
              }))
            },
            {
              code: 'production',
              properties: periods.map((period) => ({
                type: periodName,
                date: momentDatePeriod(unit, period),
                value: generateData(overBurdenProdData, item, period)
              }))
            }
          ]
        }))
      },
      {
        code: 'summary',
        properties: [
          {
            code: 'average_pa',
            properties: periods.map((period) => ({
              type: periodName,
              date: momentDatePeriod(unit, period),
              value: generateSum(physicalAvgData, period)
            }))
          },
          {
            code: 'average_ma',
            properties: periods.map((period) => ({
              type: periodName,
              date: momentDatePeriod(unit, period),
              value: generateSum(mechanicalAvgData, period)
            }))
          },
          {
            code: 'average_ua',
            properties: periods.map((period) => ({
              type: periodName,
              date: momentDatePeriod(unit, period),
              value: generateSum(useOfAvgData, period)
            }))
          },
          {
            code: 'average_ewh',
            properties: periods.map((period) => ({
              type: periodName,
              date: momentDatePeriod(unit, period),
              value: generateSum(effectiveWorkingAvgData, period)
            }))
          },
          {
            code: 'average_productivity',
            properties: periods.map((period) => ({
              type: periodName,
              date: momentDatePeriod(unit, period),
              value: generateSum(productivityAvgData, period)
            }))
          },
          {
            code: 'sum_production',
            properties: periods.map((period) => ({
              type: periodName,
              date: momentDatePeriod(unit, period),
              value: generateSum(overBurdenProdAvgData, period)
            }))
          }
        ]
      }
    ]
  };
};

const validateData = (
  codeKey,
  unit,
  periodName,
  periods,
  maintenanceData,
  productivityData
) => {
  const loweredUnit = toLower(unit);
  const isWeekly = toLower(periodName) === 'weekly';
  const isMonthly = toLower(periodName) === 'monthly';
  const momentPeriod = (item) => moment(item, generateFormat(periods, loweredUnit, true, false));

  if (isEmpty(maintenanceData) || isEmpty(productivityData)) {
    return false;
  }
  
  switch (true) {
    case isWeekly: {
      const validations = [
        every(maintenanceData.map((item) => Boolean(item.week !== null && item.week !== undefined))),
        every(productivityData.map((item) => Boolean(item.week)))
      ];

      if (codeKey) {
        switch (codeKey) {
          case 'maintenance':
            return validations[0];
          case 'productivity':
            return validations[1];
          default:
            return false;
        }
      }
      
      return every(validations);
    }
    case isMonthly: {
      const validations = [
        every(maintenanceData.map(
          (item) => Boolean(
            item[moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY')] !== null
          && item[moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY')] !== undefined
          )
        )),
        every(productivityData.map(
          (item) => Boolean(item[moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY')])
        ))
      ];

      if (codeKey) {
        switch (codeKey) {
          case 'maintenance':
            return validations[0];
          case 'productivity':
            return validations[1];
          default:
            return false;
        }
      }

      return every(validations);
    }
    default: {
      const validations = [
        every(
          periods.map((period) =>
            every(
              maintenanceData.map(
                (item) => Boolean(
                  item[momentPeriod(period).format(generateFormat(periods, loweredUnit, false, true))] !== null
                  && item[momentPeriod(period).format(generateFormat(periods, loweredUnit, false, true))] !== undefined
                )
              )
            )
          )
        ),
        every(
          periods.map((period) =>
            every(
              productivityData.map(
                (item) => Boolean(
                  item[momentPeriod(period).format(generateFormat(periods, loweredUnit, false, true))]
                  && item[momentPeriod(period).format(generateFormat(periods, loweredUnit, false, true))]
                )
              )
            )
          )
        )
      ];

      if (codeKey) {
        switch (codeKey) {
          case 'maintenance':
            return validations[0];
          case 'productivity':
            return validations[1];
          default:
            return false;
        }
      }

      return every(validations);
    }
  }
};

export {
  generateHeaderColumns,
  generateHeaderData,
  generateTableColumns,
  generateTableData,
  generateSummaries,
  generateBody,
  calculatePhysicalAvailability,
  calculateMechanicalAvailability,
  calculateUseOfAvailability,
  calculateEffectiveWorkingHours,
  calculateOverBurdenProduction,
  calculateSummary,
  pickSummaryData,
  validateData,
  stateKey
};