
import {
  generateDrillHoleValidationData,
  generateDrillHoleValidationTable
} from 'constant/ModelDrillHoleValidation/TableDrillHoleValidation';
import TableDrillingSumarry from 'pages/DrillingPlan/DrillingSummary/TableDrillingSummary';

const DrillHoleValidationModel = (props) => {
  const { isDetailMode, payload, onChange, onDeleteRow, onAddType, onDeleteType } = props;
  const tableData = generateDrillHoleValidationData(payload);
  const columns = generateDrillHoleValidationTable(
    tableData, 
    onChange, 
    onDeleteRow,
    onAddType,
    onDeleteType,
    isDetailMode
  );
  return <TableDrillingSumarry columns={columns} tableData={tableData} />;
};

export default DrillHoleValidationModel;