import { Image, Spin } from 'antd';

const LoadingIcon = (props) => {
  const { size } = props;

  const changeIconSize = () => {
    switch (size) {
      case 'xs':
        return 25;
      case 'sm':
        return 35;
      default:
        return 85;
    }
  };
  
  return (
    <div className="flex flex-col justify-center items-center">
      <Image
        preview={false}
        width={changeIconSize()}
        src={`${process.env.PUBLIC_URL}/assets/logo-oe.svg`}
        className="animate-pulse animate-infinite"
      />
      <Spin size="small" className="my-2" />
    </div>
  );
};

export default LoadingIcon;