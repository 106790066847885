import { Button, DatePicker, Form, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import {
  useGetMasterDataOmeAssetsQuery,
  useGetMasterDataOmeCasesQuery,
  useGetMasterDataOmeSectionsQQuery,
  useGetMasterDataOmeSystemsQuery,
} from 'api/MasterData';
import {
  useLazyGetOmeQByIdQuery,
  usePostOmeQMutation,
  usePutOmeQMutation,
} from 'api/OmeQ';
import SelectAccent from 'components/SelectAccent';
import Toast from 'components/Toast';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import moment from 'moment/moment';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { transformError } from 'utils/ErrorTransformer';

const FormOmeQ = ({ isEdit, omeQId, onSubmit }) => {
  const [form] = Form.useForm();

  const {
    data: masterDataSections,
    isFetching: masterDataSectionsIsFetching,
    error: masterDataSectionsError,
    isError: masterDataSectionsIsError,
  } = useGetMasterDataOmeSectionsQQuery({ refetchOnMountOrArgChange: true });

  const {
    data: masterDataCases,
    isFetching: masterDataCasesIsFetching,
    error: masterDataCasesError,
    isError: masterDataCasesIsError,
  } = useGetMasterDataOmeCasesQuery({ refetchOnMountOrArgChange: true });

  const {
    data: masterDataSystems,
    isFetching: masterDataSystemsIsFetching,
    error: masterDataSystemsError,
    isError: masterDataSystemsIsError,
  } = useGetMasterDataOmeSystemsQuery({ refetchOnMountOrArgChange: true });

  const {
    data: masterDataAssets,
    isFetching: masterDataAssetsIsFetching,
    error: masterDataAssetsError,
    isError: masterDataAssetsIsError,
  } = useGetMasterDataOmeAssetsQuery({ refetchOnMountOrArgChange: true });

  const [
    postOmeQ,
    {
      isError: postOmeQIsError,
      error: postOmeQError,
      isSuccess: postOmeQIsSuccess,
    },
  ] = usePostOmeQMutation();

  const [
    getOmeQById,
    { data: omeQData, isError: getOmeQIsError, error: getOmeQError },
  ] = useLazyGetOmeQByIdQuery();

  const [
    putOmeQ,
    {
      isError: putOmeQIsError,
      error: putOmeQError,
      isSuccess: putOmeQIsSuccess,
    },
  ] = usePutOmeQMutation();

  const onFinish = (values) => {
    const body = {
      date: moment(new Date(values.date)).format('DD-MM-YYYY'),
      ome_section_q_id: values.section,
      work_order: values.work_order,
      wo_description: values.wo_description,
      ome_case_id: values.case,
      ome_system_id: values.system,
      description: values.description,
      ome_asset_id: values.asset,
      pic: values.pic,
      time_start: moment(new Date(values.time_start)).format('HH:mm'),
      time_end: moment(new Date(values.time_end)).format('HH:mm'),
      note: values.note,
    };

    if (isEdit) {
      putOmeQ({ id: omeQId, body });
    } else {
      postOmeQ(body);
    }
  };

  useEffect(() => {
    if (isEdit) {
      getOmeQById({ id: omeQId });
    }
  }, [omeQId, isEdit]);

  useEffect(() => {
    if (!isEmpty(omeQData)) {
      form.setFieldsValue({
        date: dayjs(omeQData?.date, 'DD-MM-YYYY'),
        section: omeQData?.ome_section_q?.id,
        work_order: omeQData?.work_order,
        wo_description: omeQData?.wo_description,
        case: omeQData?.ome_case?.id,
        system: omeQData?.ome_system?.id,
        description: omeQData?.description,
        asset: omeQData?.ome_asset?.id,
        pic: omeQData?.pic,
        time_start: dayjs(omeQData?.time_start, 'HH:mm:ss'),
        time_end: dayjs(omeQData?.time_end, 'HH:mm:ss'),
        note: omeQData?.note,
      });
    }
  }, [omeQData]);

  useEffect(() => {
    if (getOmeQIsError) {
      toast.error(transformError(getOmeQError).message, {
        toastId: 'get-ome-q-error-toast',
      });
    }
    if (masterDataSectionsIsError) {
      toast.error(transformError(masterDataSectionsError).message, {
        toastId: 'master-data-section-error-toast',
      });
    }
    if (masterDataCasesIsError) {
      toast.error(transformError(masterDataCasesError).message, {
        toastId: 'master-data-case-error-toast',
      });
    }
    if (masterDataSystemsIsError) {
      toast.error(transformError(masterDataSystemsError).message, {
        toastId: 'master-data-system-error-toast',
      });
    }
    if (masterDataAssetsIsError) {
      toast.error(transformError(masterDataAssetsError).message, {
        toastId: 'master-data-Asset-error-toast',
      });
    }
    if (postOmeQIsError) {
      toast.error(transformError(postOmeQError).message, {
        toastId: 'post-plan-stop-error-toast',
      });
    }
    if (putOmeQIsError) {
      toast.error(transformError(putOmeQError).message, {
        toastId: 'put-plan-stop-error-toast',
      });
    }
    if (postOmeQIsSuccess) {
      toast.success(
        <Toast
          message={'SUCCESS'}
          detailedMessage={'Successfuly create new data.'}
        />,
        {
          toastId: 'post-plan-stop-success-toast',
        }
      );
      onSubmit();
    }
    if (putOmeQIsSuccess) {
      toast.success(
        <Toast
          message={'SUCCESS'}
          detailedMessage={'Successfuly update the data.'}
        />,
        {
          toastId: 'put-plan-stop-success-toast',
        }
      );
      onSubmit();
    }
  }, [
    getOmeQIsError,
    masterDataSectionsIsError,
    masterDataCasesIsError,
    masterDataSystemsIsError,
    masterDataAssetsIsError,
    postOmeQIsError,
    putOmeQIsError,
    postOmeQIsSuccess,
    putOmeQIsSuccess,
  ]);

  return (
    <Form
      name="basic"
      form={form}
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      style={{
        maxWidth: 600,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      autoComplete="off"
      labelAlign="left"
      colon={false}
      requiredMark={false}
    >
      <Form.Item
        name="date"
        label="Date"
        rules={[{ required: true, message: 'Please input section' }]}
      >
        <DatePicker format="DD-MM-YYYY" allowClear className="w-full" />
      </Form.Item>
      <Form.Item
        name="section"
        label="Section"
        rules={[
          {
            required: true,
            message: 'Please input section',
          },
        ]}
      >
        <SelectAccent
          allowClear
          placeholder="Select section"
          labelKey="name"
          valueKey="id"
          options={masterDataSections}
          isLoading={masterDataSectionsIsFetching}
        />
      </Form.Item>
      <Form.Item
        name="work_order"
        label="Work Order"
        rules={[
          {
            required: true,
            message: 'Please input section',
          },
        ]}
      >
        <Input placeholder="Enter detail" />
      </Form.Item>
      <Form.Item
        name="wo_description"
        label="Work Description"
        rules={[
          {
            required: true,
            message: 'Please input section',
          },
        ]}
      >
        <Input placeholder="Enter work description" />
      </Form.Item>
      <Form.Item
        name="case"
        label="Case"
        rules={[
          {
            required: true,
            message: 'Please input section',
          },
        ]}
      >
        <SelectAccent
          allowClear
          placeholder="Select case"
          labelKey="name"
          valueKey="id"
          options={masterDataCases}
          isLoading={masterDataCasesIsFetching}
        />
      </Form.Item>
      <Form.Item
        name="system"
        label="System"
        rules={[
          {
            required: true,
            message: 'Please input section',
          },
        ]}
      >
        <SelectAccent
          allowClear
          placeholder="Select system"
          labelKey="name"
          valueKey="id"
          options={masterDataSystems}
          isLoading={masterDataSystemsIsFetching}
        />
      </Form.Item>
      <Form.Item
        name="description"
        label="Description"
        rules={[
          {
            required: true,
            message: 'Please input section',
          },
        ]}
      >
        <Input placeholder="Enter description" />
      </Form.Item>
      <Form.Item
        name="asset"
        label="Asset"
        rules={[
          {
            required: true,
            message: 'Please input section',
          },
        ]}
      >
        <SelectAccent
          allowClear
          placeholder="Select asset"
          labelKey="name"
          valueKey="id"
          options={masterDataAssets}
          isLoading={masterDataAssetsIsFetching}
        />
      </Form.Item>
      <Form.Item
        name="pic"
        label="PIC"
        rules={[
          {
            required: true,
            message: 'Please input section',
          },
        ]}
      >
        <Input placeholder="Enter pic" />
      </Form.Item>
      <Form.Item
        name="time_start"
        label="Time Start"
        rules={[{ required: true, message: 'Please input section' }]}
      >
        <DatePicker.TimePicker format="HH:mm" allowClear className="w-full" />
      </Form.Item>
      <Form.Item
        name="time_end"
        label="Time End"
        rules={[{ required: true, message: 'Please input section' }]}
      >
        <DatePicker.TimePicker format="HH:mm" allowClear className="w-full" />
      </Form.Item>
      <Form.Item
        name="note"
        label="Note"
        rules={[
          {
            required: true,
            message: 'Please input section',
          },
        ]}
      >
        <TextArea
          placeholder="Enter note"
          autoSize={{ minRows: 2, maxRows: 6 }}
        />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit" block>
          {isEdit ? 'Update' : 'Submit'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FormOmeQ;
