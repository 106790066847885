import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { DatePicker, Divider, Drawer, Spin, Upload, Modal, Form, Alert } from 'antd';
import { toast } from 'react-toastify';

import { useSelector } from 'react-redux';
import {
  DEFAULT,
  FILES
} from 'constant';
import { generateParamBulkCreate, maintenanceOmeAPColumns } from 'constant/Maintenance/TableOmeAP';
import { transformError } from 'utils/ErrorTransformer';
import { useTableWrapper } from 'utils/TableResponsive';
import { convertToMegaBytes } from 'utils/FileUtility';

import Toast from 'components/Toast';
import TableData from 'components/TableData';
import ButtonAccent from 'components/ButtonAccent';
import FormOmeAP from './Form';
import TableRaw from 'components/TableRaw';
import {
  useBulkCreateOmeApMutation,
  useDeleteOmeApMutation,
  useLazyExportOmeApExcelQuery,
  useLazyGetOmeApsQuery,
  usePostOmeApExcelMutation
} from 'api/OmeAp';
import { isArray, isEmpty } from 'lodash';
import { useModalConfirmationContext } from 'components/ModalConfirmationProvider';
import { useGetMasterDataGoeCompaniesQuery, useGetMasterDataOmeSectionsQuery } from 'api/MasterData';
import SelectAccent from 'components/SelectAccent';
import moment from 'moment';

const MaintenanceOmeAP = () => {
  const loc = useLocation();
  const {
    page,
    pageSize,
    sortBy,
    orderBy,
    search
  } = useSelector((state) => state.table);

  const { showModal, resetModal } = useModalConfirmationContext();
  const { wrapperWidth } = useTableWrapper();
  const [isEdit, setIsEdit] = useState(false);
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [isFileExceedLimit, setIsFileExceedLimit] = useState(false);
  const [isModalShown, setIsModalShown] = useState(false);
  const [fileExcel, setFileExcel] = useState([]);
  const [omeApId, setOmeApId] = useState(null);
  const [isExportDisable, setIsExportDisable] = useState(true);

  const [
    getListOmeAp,
    {
      data: omeApData,
      isFetching: omeApIsFetching,
      error: omeApError,
      isError: omeApIsError
    }
  ] = useLazyGetOmeApsQuery();

  const [uploadExcel, {
    data: fileRes,
    isLoading: fileResIsLoading,
    isError: fileResIsError,
    error: fileResError,
    isSuccess: fileResIsSuccess
  }] = usePostOmeApExcelMutation();

  const [
    postBulkCreateOmeAp,
    {
      isLoading: bulkCreateOmeApIsLoading,
      isError: bulkCreateOmeApIsError,
      error: bulkCreateOmeApError,
      isSuccess: bulkCreateOmeApIsSuccess,
    },
  ] = useBulkCreateOmeApMutation();

  const { 
    data: dataCompanies, 
    isFetching: companiesIsFetching, 
  } = useGetMasterDataGoeCompaniesQuery({ refetchOnMountOrArgChange: true });
  
  const {
    data: sectionData,
    isFetching: sectionIsFetching
  } = useGetMasterDataOmeSectionsQuery({ refetchOnMountOrArgChange: true });

  const generateFormData = (file) => {
    const formData = new FormData();
    
    formData.append('file', file);
    
    return formData;
  };

  const [
    deleteOmeAp,
    {
      isError: deleteOmeApIsError,
      error: deleteOmeApError,
      isSuccess: deleteOmeApIsSuccess,
    },
  ] = useDeleteOmeApMutation();

  const [exportExcelOmeAp, {
    isLoading: exportExcelIsLoading,
    isError: exportExcelIsError,
    error: exportExcelError
  }] = useLazyExportOmeApExcelQuery();

  const fetchListOmeAp = (section, dates) => {
    const params = new URLSearchParams({
      page,
      limit: pageSize,
      sort_by: sortBy,
      order_by: orderBy,
      ...search
        ? { search }
        : undefined,
    });

    if (!isEmpty(section)) {
      if (isArray(section)) {
        section?.forEach(v => {params.append('section', v);});
      }

      params.append('sections', section);
    }

    if (!isEmpty(dates)) {
      dates?.forEach(v => {
        params.append(
          'dates', moment(new Date(v)).format('YYYY-MM-DD')
        );
      });
    }

    getListOmeAp(params.toString());
  };

  useEffect(() => {
    fetchListOmeAp();
  }, []);

  useEffect(() => {
    fetchListOmeAp();
  }, [
    page,
    pageSize,
    sortBy,
    orderBy,
    search
  ]);

  useEffect(() => {
    if (!isEmpty(fileRes))
      setFileExcel(fileRes);
  }, [fileRes]);

  const onApplyFilter = (values) => {
    fetchListOmeAp(values.section, values.date);
    setIsExportDisable(isEmpty(values?.date) && isEmpty(values?.section));
  };

  const onSubmit = () => {
    const body = generateParamBulkCreate(fileExcel);

    postBulkCreateOmeAp(body);
  };

  const onSetModal = () => {
    if (isModalShown) {
      setIsEdit(false);
    } else {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
    setIsModalShown(!isModalShown);
  };

  const onEdit = (id) => {
    setOmeApId(id);
    setIsEdit(true);
    setIsModalShown(!isModalShown);
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const onSubmitForm = () => {
    onSetModal();
    setTimeout(() => {
      fetchListOmeAp();
    }, 1000);
  };

  const onDelete = (id) => {
    showModal({
      isShown: true,
      type: 'delete',
      message: 'Are you sure you want to delete ?',
      onSubmit: () => {
        deleteOmeAp({ id });
        resetModal();
        setTimeout(() => {
          fetchListOmeAp();
          if (deleteOmeApIsError) {
            toast.error(deleteOmeApError, {
              toastId: 'delete-plan-stop-error-toast',
            });
          } else {
            toast.success('Data Deleted', {
              toastId: 'delete-plan-stop-success-toast',
            });
          }
        }, 1000);
      },
    });
  };

  const onClose = () => {
    setIsModalOpen(false);
  };

  const showModalPreview = () => {
    setIsModalOpen(true);
  };

  const beforeUploadFile = (file) => {
    const { size } = file;
    const tempTotalSize = totalSize + size;

    if (convertToMegaBytes(totalSize) > FILES.MAX) {
      setIsFileExceedLimit(true);

      return;
    } else {
      setTotalSize(tempTotalSize);
    }
  };

  const onChangeFile = (info) => {
    const { file } = info;

    const form = generateFormData(file);
    setIsFileExceedLimit(false);
    uploadExcel(form);
  };

  useEffect(() => {
    if (omeApIsError) {
      toast.error(
        transformError(omeApError).message,
        { toastId: 'ome-ap-list-error' }
      );
    }
    if (fileResIsError) {
      toast.error(
        transformError(fileResError).message,
        { toastId: 'ome-ap-upload-error' }
      );
    }
    if (fileResIsSuccess) {
      toast.success(
        <Toast message={'SUCCESS'} detailedMessage={'Successfuly upload excel document.'} />,
        { toastId: 'ome-ap-upload-success' }
      );

      showModalPreview();
    }
    if (exportExcelIsError) {
      toast.error(
        transformError(exportExcelError).message,
        { toastId: 'ome-ap-upload-error' }
      );
    }
  }, [
    omeApIsError,
    fileResIsError,
    fileResIsSuccess,
    exportExcelIsError
  ]);

  useEffect(() => {
    if (bulkCreateOmeApIsSuccess) {
      toast.success(
        <Toast message={'SUCCESS'} detailedMessage={'Successfuly submit all data.'} />,
        { toastId: 'ome-ap-bulk-success' }
      );

      onClose();
      fetchListOmeAp();
      setFileExcel([]);
    }
    if (deleteOmeApIsSuccess) {
      toast.success(
        <Toast message={'SUCCESS'} detailedMessage={'Successfuly delete related document.'} />,
        { toastId: 'ome-ap-delete-success' }
      );

      fetchListOmeAp();
    }
    if (bulkCreateOmeApIsError) {
      toast.error(
        transformError(bulkCreateOmeApError).message,
        { toastId: 'ome-ap-list-error' }
      );

      setFileExcel([]);
    }
  }, [bulkCreateOmeApIsSuccess, deleteOmeApIsSuccess, bulkCreateOmeApIsError]);

  return (
    <div className="p-5 bg-white rounded-lg w-full">
      <Modal
        title="Modal Preview"
        centered
        footer={null}
        open={isModalOpen}
        onOk={onClose}
        onCancel={onClose}
        width={1000}
      >
        <div
          className="overflow-x-auto my-5"
        >
          <TableRaw
            isLoading={false}
            scrollWidth="max-content"
            columns={
              maintenanceOmeAPColumns(
                loc.pathname,
                sortBy,
                orderBy === DEFAULT.KEY_SORT_ASC
                  ? DEFAULT.SORT_ASC
                  : DEFAULT.SORT_DESC,
                () => {},
                () => {}
              )
            }
            dataSource={fileExcel}
          />
        </div>
        <div className='w-full text-right'>
          <ButtonAccent
            title="Submit"
            onClick={onSubmit}
            isDisabled={bulkCreateOmeApIsLoading}
            isLoading={bulkCreateOmeApIsLoading}
          />
        </div>
      </Modal>
      <Drawer
        destroyOnClose
        title={`${isEdit ? 'Edit Record' : 'Add New Record'} OME Maintenance AP`}
        placement={isEdit ? 'right' : 'left'}
        onClose={onSetModal}
        open={isModalShown}
        loading={loading}
        maskClosable={false}
        key={isEdit ? 'right' : 'left'}
        width={'35vw'}
        styles={{
          headerStyle: { backgroundColor: '#EEE9FB' },
          bodyStyle: { backgroundColor: '#EEE9FB'}
        }}
      >
        {
          loading 
            ? <div className='w-full text-center'><Spin className='m-auto' /> </div>
            : <FormOmeAP
              isEdit={isEdit}
              omeApId={omeApId}
              onSubmit={onSubmitForm}
              sectionData={sectionData}
              sectionIsFetching={sectionIsFetching}
            />
        }
      </Drawer>

      <Form form={form} onFinish={onApplyFilter}>
        <div className={'grid-cols-2 flex gap-4 p-4 bg-[#B1BACB20] rounded-lg'}>
          <div className='w-full'>
            <span className="font-bold">Company</span>
            <Form.Item
              name="mine_site"
            >
              <SelectAccent
                className='mt-2'
                isStringify={false}
                isLoading={companiesIsFetching}
                labelKey="alias_name"
                valueKey="alias_name"
                options={dataCompanies || []}
                size="large"
              />
            </Form.Item>
          </div>
          <div className='w-full'>
            <span className="font-bold">Section</span>
            <Form.Item
              name="section"
            >
              <SelectAccent
                className='mt-2'
                isStringify={false}
                isLoading={sectionIsFetching}
                labelKey="name"
                valueKey="name"
                options={sectionData || []}
                size="large"
              />
            </Form.Item>
          </div>
          <div className='w-full'>
            <span className="font-bold">Datetime</span>
            <Form.Item
              name="date"
            >
              <DatePicker.RangePicker
                size="large"
                allowClear
                className="mt-2 w-full"
              />
            </Form.Item>
          </div>
          <div className="w-1/5 flex flex-row justify-center items-center gap-2">
            <ButtonAccent
              title="Apply Filter"
              htmlType="submit"
              isLoading={false}
            />
          </div>
        </div>
      </Form>
      <Divider />
      {
        isFileExceedLimit && (
          <Alert
            type="error"
            message="File size exceed limit of 25MB, please choose another one."
            className="mb-5"
          />
        )
      }
      <div className="flex justify-between p-5">
        <div className="flex">
          <Upload
            className='mr-3'
            showUploadList={false}
            accept='.xlsx, .xlsm, .xlsb, .xls, .xml, .xlam, .xla'
            beforeUpload={beforeUploadFile}
            customRequest={(opt) => onChangeFile(opt)}
            disabled={fileResIsLoading}
          >
            <div className='flex flex-row items-center'>
              <ButtonAccent title='Upload' isDisabled={fileResIsLoading} isLoading={fileResIsLoading}></ButtonAccent>
              {
                fileResIsLoading
                  && <Spin className='mx-5' size='small'></Spin>
              }
            </div>
          </Upload>
          <ButtonAccent
            title="View Report"
            onClick={showModalPreview}
            isDisabled={!fileExcel.length}
          />
        </div>
        <ButtonAccent 
          isBordered 
          size={'xl'} 
          title="Export Excel" 
          isLoading={exportExcelIsLoading}
          onClick={() => {
            const params = new URLSearchParams({
              page,
              limit: pageSize,
              sort_by: sortBy,
              order_by: orderBy,
              ...search
                ? { search }
                : undefined,
            });
            
            if (!isEmpty(values.section) && isArray(values.section)) {
              values.section?.forEach(v => {params.append('section', v);});
            } else {
              params.append('section', values.section);
            }
            values.date?.forEach(v => {params.append('dates', moment(new Date(v)).format('YYYY-MM-DD'));});

            exportExcelOmeAp(params.toString());
          }}  
          isDisabled={isEmpty(omeApData?.data) || isExportDisable}
        />
      </div>
      <div
        className="mt-5 overflow-x-auto"
        style={{ maxWidth: wrapperWidth }}
      >
        <TableData
          isTransparent
          rowKey="id"
          isLoading={omeApIsFetching}
          columns={
            maintenanceOmeAPColumns(
              loc.pathname,
              sortBy,
              orderBy === DEFAULT.KEY_SORT_ASC
                ? DEFAULT.SORT_ASC
                : DEFAULT.SORT_DESC,
              onEdit,
              onDelete
            )
          }
          dataSource={omeApData?.data}
          totalData={omeApData?.total_data}
          isBordered={true}
        >
          <ButtonAccent
            title="New Record"
            onClick={onSetModal}
          />
        </TableData>
      </div>
    </div>
  );
};

export default MaintenanceOmeAP;