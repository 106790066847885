import { generateDrillingDepthTable } from 'constant/TableDrillingSumarry/TableDrillingDepth';
import TableDrillingSumarry from '../TableDrillingSummary';

const DrillingDepth = (props) => {
  const { drillingDepthData, drillHoleName } = props;
  const columns = generateDrillingDepthTable(false);
  columns.unshift(drillHoleName);
  return (
    <TableDrillingSumarry columns={columns} tableData={drillingDepthData} />
  );
};

export default DrillingDepth;
