import { ConfigProvider, Table, Tag } from 'antd';
import { useLazyGetPlanDetailByIdQuery } from 'api/DrillingPlan';
import ButtonAccent from 'components/ButtonAccent';
import { generatePermitTimelineTable } from 'constant/TablePermitTimeline';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { useLazyGetPermitTimelineByIdQuery, usePostPermitTimelineMutation } from 'api/PermitTimeline';
import { transformError } from 'utils/ErrorTransformer';
import ModalInformation from '../components/ModalInformation';
import {
  useGetMasterDataAreasQuery,
  useGetMasterDataPitsQuery,
} from 'api/MasterData';
import moment from 'moment';
import { getFullPath, getPathType } from 'utils/PathUtility';
import { createSearchParams } from 'react-router-dom';
import { ACTION } from 'constant';
import LoadingIcon from 'components/LoadingIcon';
import PreventNavigation from 'constant/PreventNavigation';
import { useModalConfirmationContext } from 'components/ModalConfirmationProvider';
import { isArray, isEmpty } from 'lodash';
import dayjs from 'dayjs';
import ModalUpload from './ModalUpload';
const PermitTimeline = () => {
  const initialData = {
    id: 1,
    permit_type: null,
    area: null,
    pit_name: null,
    expected_granted_permit: '',
    files_info: []
  };
  const [isSaveAsDraft, setIsSaveAsDraft] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [payload, setPayload] = useState([{ ...initialData }]);
  const [informationModal, setInformationModal] = useState(false);
  const [informationMessage, setInformationMessage] = useState('');
  const [showModalUpload, setShowModalUpload] = useState(false);
  const [choosedId, setChoosedId] = useState(null);
  const drillingPlanId = new URLSearchParams(location.search).get('id');
  const permitTimelineVersion = new URLSearchParams(location.search).get('version');
  const isEditMode = getPathType(location) === 'edit';
  const isDetailMode = getPathType(location) === 'detail';
  const [properties, setProperties] = useState({
    areas: { areasData: null, areasIsFetching: true },
    pits: { pitsData: null, pitsIsFetching: true },
  });
  const {showModal, resetModal} = useModalConfirmationContext();

  const [
    getPlanDetailById,
    {
      data: drillingPlanData,
      isFetching: drillingPlanIsFetching,
      isError: drillingPlanDataIsError,
      error: drillingPlanDataError,
    },
  ] = useLazyGetPlanDetailByIdQuery({ refetchOnMountOrArgChange: true });

  const version = drillingPlanData?.latest_version;
  const year = drillingPlanData?.year;
  const company = drillingPlanData?.company.alias_name;

  const {
    data: areasData,
    isFetching: areasIsFetching,
    isError: areasDataIsError,
    error: areasDataError,
  } = useGetMasterDataAreasQuery({ refetchOnMountOrArgChange: true });

  const {
    data: pits,
    isFetching: pitIsFetching,
    isError: pitIsError,
    error: pitError,
  } = useGetMasterDataPitsQuery(
    { companyCode: company },
    { refetchOnMountOrArgChange: true }
  );

  const [
    postPermitTimeline,
    {
      isLoading: postPermitTimelineIsLoading,
      isError: postPermitTimelineIsError,
      error: postPermitTimelineError,
      isSuccess: postPermitTimelineIsSuccess,
    },
  ] = usePostPermitTimelineMutation();

  const [getPermitTimelineById, {
    data: permitTimelineByIdData,
    isFetching: permitTimelineByIdDataIsFetching,
    isError: permitTimelineByIdDataIsError,
    error: permitTimelineByIdDataError,
    isSuccess: permitTimelineByIdDataSuccess
  }] = useLazyGetPermitTimelineByIdQuery();

  const addPermitTimeLine = () => {
    let temp = payload;
    temp.push({ ...initialData, id: payload[payload.length - 1].id + 1 });
    setPayload([...temp]);
  };

  const deletePermitTimeline = (record) => {
    let temp = payload.find((val) => val.id === record.id);
    if (temp) {
      setPayload((prevState) =>
        prevState.filter((state) => state.id !== temp.id)
      );
    }
  };

  const handleBackButton = () => {
    if(isDetailMode) {
      navigate(-1);
    } else {
      showModal({
        isShown: true,
        type: 'confirmation',
        title:'Are you sure want to leave this page?',
        message: 'Changes you made may not be saved',
        onSubmit: () => {
          navigate(-1);
          resetModal();
        }
      });
    }
  };

  const requiredValidation = () => {
    let validation = true;
    payload.forEach((_, index) => {
      let tempPayload = payload[index];
      for (const key in tempPayload) {
        if (isEmpty(tempPayload[key]) && key !== 'id') {
          validation = false;
          setInformationMessage('Permit Timeline must be fully completed');
          break;
        }
      }
    });
    return validation;
  };

  const submitHandle = (isDraft) => {
    setIsSaveAsDraft(isDraft);
    if (requiredValidation() || isDraft) {
      let newPayload = payload.map(data => {
        delete data.id;
        return { 
          permit_type: data.permit_type || '',
          area: data.area?  isArray(data.area) ? data.area[0].toUpperCase() : data.area : '', 
          pit_name: data?.pit_name? isArray(data.pit_name)? data.pit_name[0] : data.pit_name :'', 
          expected_granted_permit:data.expected_granted_permit? dayjs(data.expected_granted_permit, 'DD/MM/YYYY').format('DD/MM/YYYY'): '',
          file_ids: data?.files_info.map(item => item.id)
        };
      });

      let finalPayload = {
        content: newPayload,
        version,
        is_draft: isDraft,
        update_permit_date: moment(new Date()).format('DD/MM/YYYY'),
        submitted_date: isDraft ? '' : new Date()
      };
      postPermitTimeline({body: finalPayload, idPlan: drillingPlanId});
    } else {
      setInformationModal(true);
    }
  };

  const goToPath = (child, type) => navigate({
    pathname: getFullPath({
      parent: 'drilling-plan',
      child: child,
      type
    },'geology-oe'),
    ...{ search: createSearchParams({ id: drillingPlanId, version }).toString() }
  });

  const onUpload = (id) => {
    setChoosedId(id);
    setShowModalUpload(prev => !prev);
  };

  useEffect(() => {
    if (drillingPlanDataIsError) {
      toast.error(transformError(drillingPlanDataError).message);
    }
    if (areasDataIsError && !isDetailMode) {
      toast.error(transformError(areasDataError).message);
    }
    if (pitIsError && !isDetailMode) {
      toast.error(transformError(pitError).message);
    }
    if(postPermitTimelineIsError){
      setInformationMessage(transformError(postPermitTimelineError).message);
      setInformationModal(true);
    }
    if(permitTimelineByIdDataIsError && isEditMode) {
      setInformationMessage(transformError(permitTimelineByIdDataError).message);
      setInformationModal(true);
    }
  }, [drillingPlanDataIsError, areasDataIsError, pitIsError, postPermitTimelineIsError, permitTimelineByIdDataIsError, isDetailMode]);

  useEffect(() => {
    setProperties((prevValue) => ({
      ...prevValue,
      areas: { areasData, areasIsFetching },
    }));
  }, [areasIsFetching]);

  useEffect(() => {
    setProperties((prevValue) => ({
      ...prevValue,
      pits: { pitsData: pits, pitsIsFetching: pitIsFetching },
    }));
  }, [pitIsFetching]);

  useEffect(() => {
    if (drillingPlanId) {
      getPlanDetailById({ idPlan: drillingPlanId });
    }
  }, [drillingPlanId]);

  useEffect(() => {
    if((isEditMode||isDetailMode) && permitTimelineVersion && drillingPlanId){
      getPermitTimelineById({ id: drillingPlanId, params: { version: permitTimelineVersion }});
    }
  },[isEditMode, permitTimelineVersion, drillingPlanId, isDetailMode]);

  useEffect(() => {
    if(permitTimelineByIdDataSuccess && permitTimelineByIdData?.content){
      let temp = [];
      permitTimelineByIdData.content.forEach((data, index)=>{
        temp.push({...temp, id: index, ...data});
      });
      setPayload(temp);
    }
  },[permitTimelineByIdDataSuccess]);

  useEffect(() => {
    if ( postPermitTimelineIsSuccess ) {
      toast.success(
        `You have successfully ${isSaveAsDraft ? 'save permit timeline as draft' : 'submitted permit timeline'}`,
        { toastId: 'post-permit-timeline-toast-success' }
      );
      if ( isSaveAsDraft ) {
        navigate('/activity-level/geology-oe/drilling-plan');
      }else {
        goToPath('drilling-summary', ACTION.CREATE);
      }
    }
  }, [postPermitTimelineIsSuccess]);
  
  const handleCloseSite = useCallback((ev) => ev.preventDefault() ,[]);
  
  useEffect(() => {
    PreventNavigation(isDetailMode, handleCloseSite);
  }, []);


  return (
    <div className=" rounded-2xl bg-white px-6 py-8">
      <div className=" mb-10 flex flex-row justify-between">
        <div className=" text-[18px] font-bold">Permit Timeline</div>
        <Tag className={`px-3 py-2 font-bold rounded-2xl bg-[#2BB8A4]/[0.1] text-[#01B59C] border-none ${
            drillingPlanIsFetching && 'animate-pulse animate-infinite'
          }`}>
          <span className='text-sm'>
            {drillingPlanIsFetching
              ? 'Loading...'
              : `V${isDetailMode? permitTimelineVersion : version} / ${year} / ${company} / Drilling Plan`}
          </span>
        </Tag>
      </div>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              borderColor: 'white',
              colorBgContainer: '#2D3D5A10',
              colorSplit: 'red',
              lineWidth: 4,
              headerBg: '#2D3D5A',
              headerColor: 'white',
              rowHoverBg: 'unset',
            },
          },
        }}
      >
        {
          (isDetailMode||isEditMode) &&  permitTimelineByIdDataIsFetching ?  (
            <LoadingIcon size="sm" />
          ) : (
            <Table
              pagination={false}
              bordered
              dataSource={payload}
              columns={generatePermitTimelineTable(
                addPermitTimeLine,
                deletePermitTimeline,
                payload,
                setPayload,
                properties,
                isDetailMode? permitTimelineVersion: version,
                isDetailMode,
                onUpload
              )}
            />
          )
        }
      </ConfigProvider>
      <div className="mt-5 w-full flex flex-row items-center">
        {
          !isDetailMode && <ButtonAccent
            isBordered
            isDisabled={postPermitTimelineIsLoading}
            isLoading={postPermitTimelineIsLoading}
            size="lg"
            title="Save As Draft"
            onClick={() => submitHandle(true)}
          />
        }
        <div className="ml-auto flex flex-row items-center gap-x-3">
          <ButtonAccent
            isBordered
            title="Back"
            onClick={() => handleBackButton()}
          />
          {
            !isDetailMode && (
              <ButtonAccent
                isDisabled={postPermitTimelineIsLoading}
                isLoading={postPermitTimelineIsLoading}
                title="Next"
                onClick={() => submitHandle(false)}
              />
            )
          }
        </div>
        <ModalInformation
          isModalOpen={informationModal}
          setIsModalOpen={setInformationModal}
          errorMessage={informationMessage}
        />
        <ModalUpload
          isDetailMode={isDetailMode}
          showModal={showModalUpload}
          setShowModal={setShowModalUpload} 
          setPayload={setPayload} 
          payload={payload}
          choosedId={choosedId}
        />
      </div>
    </div>
  );
};

export default PermitTimeline;
