import moment from 'moment';
import {
  createContext,
  useContext,
  useState
} from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { isEmpty, toLower } from 'lodash';

const SideCarApprovalHistoryContext = createContext();
const SideCarApprovalHistory = (props) => {
  const { children } = props;
  
  const [isSideCarShown, setIsSideCarShown] = useState(false);
  const [approvalHistory, setApprovalHistory] = useState([]);
  const [typeHistory, setTypeHistory] = useState('');

  const showSideCar = (data, type) => {
    setIsSideCarShown(true);
    setApprovalHistory(data);
    setTypeHistory(type);
  };
  
  const hideSideCar = () => {
    setIsSideCarShown(false);
    setApprovalHistory([]);
  };
  
  const generateMessage = (item) => {
    if (isEmpty(item)) {
      return;
    }

    const { action, comment, display_name } = item;

    return (
      <div className="flex flex-col">
        <span>
          {typeHistory !== 'drilling' ? 'Mine plan assumption' : 'Planning'} has been <span className="font-bold font-[Segoe-UI-Bold] text-[#2D3D5A]">{toLower(action)} by {display_name}</span>
        </span>
        <div className="mt-1 p-3 bg-[#2D3D5A50] flex flex-col rounded-lg text-[#2D3D5A]">
          <span>{comment}</span>
        </div>
      </div>
    );
  };
  
  return (
    <SideCarApprovalHistoryContext.Provider value={{ showSideCar, hideSideCar }}>
      {
        isSideCarShown && (
          <div className="w-full h-full absolute flex flex-row bg-black/25 left-0 top-0 z-20">
            <div className="ml-auto w-80 h-full flex flex-col bg-white rounded-tl-lg rounded-bl-lg">
              <div className="p-5 flex flex-row items-center rounded-tl-lg rounded-bl-lg shadow-lg">
                <span className="text-lg font-bold">History Approval</span>
                <div
                  className="ml-auto p-1 bg-[#2D3D5A20] rounded-full cursor-pointer"
                  onClick={hideSideCar}
                >
                  <AiOutlineClose size="18px" className="text-[#2D3D5A]" />
                </div>
              </div>
              <div className="p-5 max-h-[calc(100%-5rem)] overflow-y-auto">
                {
                  (approvalHistory && approvalHistory.length)
                    ? (
                      <>
                        {
                          approvalHistory.map((item) => (
                            <div className="flex flex-col mb-3">
                              <div className="flex flex-row items-center gap-x-3">
                                <div className="w-4 h-4 rounded-full border-4 border-[#2D3D5A]" />
                                <span className="text-sm font-bold">
                                  {
                                    item.submit_doc_date
                                      ? moment(item.submit_doc_date, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY HH:mm')
                                      : 'unknown'
                                  }
                                </span>
                              </div>
                              <div className="ml-[calc(1rem-10px)] flex flex-row items-center border-l-2 border-dashed border-[#2D3D5A] gap-x-3">
                                <div className="p-3 rounded-lg bg-white">
                                  {generateMessage(item)}
                                </div>
                              </div>
                            </div>
                          ))
                        }
                      </>
                    )
                    : <></>
                }
              </div>
            </div>
          </div>
        )
      }
      {children}
    </SideCarApprovalHistoryContext.Provider>
  );
};

const useSideCarContext = () => useContext(SideCarApprovalHistoryContext);

export default SideCarApprovalHistory;
export { useSideCarContext };