import { Alert, Image, Modal, Progress, Tooltip, Upload } from 'antd';
import ButtonAccent from 'components/ButtonAccent';
import _, { isEmpty, toLower } from 'lodash';
import { useEffect, useState } from 'react';
import { TbTrash } from 'react-icons/tb';
import { toast } from 'react-toastify';
import { transformError } from 'utils/ErrorTransformer';
import { convertToMegaBytes } from 'utils/FileUtility';
import { IoDocumentText } from 'react-icons/io5';
import { useDeleteFileMutation, useUploadFileMutation } from 'api/Workflow';
import { FiDownload } from 'react-icons/fi';
const ModalUpload = (props) => {
  const {showModal, setShowModal, setPayload, payload, choosedId, isDetailMode} = props;
  const { Dragger } = Upload;
  const [files, setFiles] = useState([]);
  const [isFileHasForbiddenExtensions, setIsFileHasForbiddenExtensions] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [choosedDeleteId, setChoosedDeleteId] = useState(null);

  const [uploadFile, {
    data: evidenceRes,
    isLoading: evidenceResIsLoading,
    isError: evidenceResIsError,
    error: evidenceResError
  }] = useUploadFileMutation();

  const [deleteFile, {
    isLoading: fileDeleteIsLoading,
    isError: fileDeleteIsError,
    error: fileDeleteError
  }] = useDeleteFileMutation();

  const validateFile = (file) => {
    const forbiddenExtensions = ['exe', 'vb', 'apk', 'sql', 'js', 'scrp'];
    const fileExt = file.name.split('.');
    const ext = fileExt[fileExt.length - 1];
    const isForbiddenType = forbiddenExtensions.some((extension) => !toLower(extension).includes(ext));
    let isFileNamevalid = true;
    if(files.length){
      let isSameName = files.find(item=>item.name === file?.name);
      if(!isEmpty(isSameName)) {
        isFileNamevalid = false;
        setErrorMessage('Cannot upload file with same name, please choose another one');
      }
    } else { 
      setErrorMessage('Cannot upload this type of file, please choose another one');
    }
    return isForbiddenType && isFileNamevalid;
  };

  const generateFormData = (files) => {
    const formData = new FormData();
    files?.forEach(item => {
      if(!item.hasOwnProperty('id')){
        formData.append('files', item);
      }
    });
    return formData;
  };

  const handleOnChange = (info) => {
    const { file } = info;
    if(validateFile(file)) {
      setFiles(prev => [...prev, file]);
      setIsFileHasForbiddenExtensions(false);
    }else {
      setIsFileHasForbiddenExtensions(true);
    }
  };

  const handleDeleteFile = (item) => {
    if(item.hasOwnProperty('id')) {
      deleteFile({ id: item?.id });
      setChoosedDeleteId(item.id);
    } else {
      let tempFiles = files?.filter(file => file?.uid !== item.uid);
      setFiles(tempFiles);
    }
  };

  
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const onUpload = () => {
    const payload = generateFormData(files);
    uploadFile(payload);
  };

  const populateUploadResult = () => {
    let tempFiles = _.cloneDeep(files);
    let filteredFiles = tempFiles.filter(temp=>!temp.hasOwnProperty('uid'));
    evidenceRes.forEach(item=>{
      filteredFiles.push(item);
    });
    setFiles([...filteredFiles]);
    let tempPayload = payload;
    let choosedPayload = tempPayload.find(item => item.id === choosedId);
    choosedPayload.files_info = filteredFiles;
    setPayload([...tempPayload]);
    toast.success('Success upload evidence for this row');
    handleCloseModal();
  };

  const onDownload = (file) => {
    const docLink = document.createElement('a');
    docLink.href = file.url;

    document.body.appendChild(docLink);
    docLink.click();
    document.body.removeChild(docLink);
  };

  useEffect(() => {
    if(!isEmpty(evidenceRes)){
      populateUploadResult();
    }
  },[evidenceRes]);

  useEffect(() => {
    if(!isEmpty(payload) && showModal){
      let choosedObject = payload.find(item => item.id === choosedId);
      setFiles(choosedObject?.files_info);
    }
  },[payload, showModal]);

  useEffect(() => {
    if (evidenceRes || fileDeleteIsError) {
      if (fileDeleteError && fileDeleteError?.originalStatus === 200) {
        let tempFile = files.filter(item => item.id !== choosedDeleteId);
        setFiles([...tempFile]);
        let tempPayload = payload;
        let choosedPayload = tempPayload.find(item => item.id === choosedId);
        choosedPayload.files_info = tempFile;
        return;
      }

      toast.error(
        transformError(
          evidenceResIsError ? evidenceResError : fileDeleteError).message,
        { toastId: `toast-${evidenceResError ? 'upload' : 'delete'}-error` }
      );
    }
  }, [evidenceResIsError, fileDeleteIsError]);

  const configuration = {
    name: 'file',
    multiple: true,
    showUploadList: false,
    onChange: handleOnChange,
    accept:'*/*',
    beforeUpload:() => false
  };
  return ( 
    <Modal
      title={false}
      footer={false}
      closeIcon={false}
      open={showModal}
      onOk={handleCloseModal}
      onCancel={handleCloseModal}
      width={'45vw'}
      centered
      style={{ overflowY: 'hidden', maxHeight: '80%', flexDirection:'col' }}
    >
      {
        isFileHasForbiddenExtensions && (
          <Alert
            type="error"
            message={errorMessage}
            className="mb-5"
          />
        )
      }
      <div className='flex flex-row justify-between mb-4'>
        <span className='text-[16px] font-[Segoe-UI-Bold]'>Upload Evidence</span>
      </div>
      {
        !isDetailMode && (
          <Dragger {...configuration} listType="picture" >
            {evidenceResIsLoading  || fileDeleteIsLoading ? (
              <Progress
                percent={100}
                status="active"
                strokeColor="#472F92"
                showInfo={false}
              />
            ):(
              <div className="p-3 flex flex-col items-center justify-center bg-white rounded-full h-52 mb-4">
                <Image
                  preview={false}
                  width={30}
                  src={`${process.env.PUBLIC_URL}/assets/icon-upload.svg`} />
                <span className="my-2">Click or drag file to this area to upload</span>
                <ButtonAccent title="Browse File" />
              </div>
            )}
          </Dragger>
        )
      }
      {
        !isEmpty(files) && (
          <div className='h-[28vh] overflow-y-scroll'>
            {files?.map((item, index) => {
              return (
                <div key={`evidence-${index}`} className="gap-3 p-2 flex flex-row items-center bg-[#2D3D5A10] border-dashed border-2 border-[#2D3D5A] rounded-lg mt-2 justify-between">
                  <div className='flex flex-row w-full'>
                    <IoDocumentText size={40} color='#2D3D5A' />
                    <div className="ml-2 flex flex-col justify-center">
                      <Tooltip placement='topRight' title={item?.name || item?.fileName} color='#2D3D5A' trigger={'hover'}>
                        <span className='max-w-[25vw] truncate'>{item?.name || item?.fileName}</span>
                      </Tooltip>
                      <span className="text-gray-500/50">
                        {convertToMegaBytes(item?.size )} MB
                      </span>
                    </div>
                  </div>
                  <div className='flex flex-row gap-2 items-center'>
                    {
                      item?.hasOwnProperty('id') && (
                        <div
                          className="p-1 rounded-full bg-[#01B59C] cursor-pointer"
                          onClick={() => onDownload(item)}
                        >
                          <Tooltip title={'Download'} trigger={'hover'}>
                            <FiDownload
                              size="18px"
                              className="text-white"
                            />
                          </Tooltip>
                        </div>
                      )
                    }
                    {!isDetailMode && (
                      <div
                        className="p-1 bg-pink-700 rounded-full cursor-pointer"
                        onClick={() => handleDeleteFile(item)}
                      >
                        <Tooltip title={'Delete'} trigger={'hover'}>
                          <TbTrash size={'18px'} className="text-white" />
                        </Tooltip>
                      </div>
                    )}
                    
                  </div>
                </div>
              );
            })}
          </div>
        )
      } 
      <div className="mt-5 flex flex-row items-center">
        <div className="ml-auto flex flex-row items-center gap-x-3">
          <ButtonAccent isLoading={evidenceResIsLoading} isBordered title="Back" onClick={handleCloseModal} />
          {!isDetailMode && (
            <ButtonAccent isLoading={evidenceResIsLoading} title="Upload" onClick={onUpload} />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ModalUpload;