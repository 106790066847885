import { drillHoleName } from 'constant/TableDrillingSummary';
import { 
  generateDrillingDepthTable
} from 'constant/TableDrillingSummaryActual/TableDrillingDepth';
import TableDrillingSumarry from 'pages/DrillingPlan/DrillingSummary/TableDrillingSummary';

const DrillingDepth = (props) => {
  const { isDetailMode, drillingDepthData, onChange, onDeleteRow, plansData, otherData} = props;
  const columns = generateDrillingDepthTable(
    drillingDepthData,
    isDetailMode,
    onChange,
    onDeleteRow,
    plansData,
    otherData
  );
  const populateDrillHoleName = () => {
    let finalData = drillingDepthData.map((data, index) => {
      return {
        ...data, 
        drill_hole_name: otherData.drill_hole_information[index].drill_hole_name,
      };
    });
    return finalData;
  };
  columns.splice(1, 0, drillHoleName);

  return <TableDrillingSumarry 
    columns={ columns }
    tableData={ populateDrillHoleName() }
  />;
};

export default DrillingDepth;