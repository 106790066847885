import { drillHoleName } from 'constant/TableDrillingSummary';
import { generateDrillingDateActualTable } from 'constant/TableDrillingSummaryActual/TableDrillingDateActual';
import TableDrillingSumarry from 'pages/DrillingPlan/DrillingSummary/TableDrillingSummary';

const DrillingDateActual = (props) => {
  const {drillingDateActualData, onChange, onDeleteRow, otherData, plansData, isDetailMode} = props;
  const columns = generateDrillingDateActualTable(drillingDateActualData, isDetailMode, onChange, onDeleteRow, otherData, plansData);
  columns.splice(1, 0, drillHoleName);
  const populateDrillHoleName = () => {
    let finalData = drillingDateActualData.map((data, index) => {
      return {
        ...data, 
        drill_hole_name: otherData.drill_hole_information[index].drill_hole_name,
      };
    });
    return finalData;
  };
  return <TableDrillingSumarry columns={columns} tableData={populateDrillHoleName()}/>;
};

export default DrillingDateActual;