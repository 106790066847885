import { isEmpty } from 'lodash';

const generateDrillingHoleLocationTable = () => [
  {
    dataIndex: 'plan_easting',
    title: 'Plan Easting',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className='flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {Number(value).toFixed(2)}
        </div>
      );
    },
  },
  {
    dataIndex: 'plan_northing',
    title: 'Plan Northing',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className='flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {Number(value).toFixed(2)}
        </div>
      );
    },
  },
  {
    dataIndex: 'plan_elevation',
    title: 'Plan Elevation',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className='flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {Number(value).toFixed(2)}
        </div>
      );
    },
  },
  {
    dataIndex: 'plan_latitude',
    title: 'Plan Latitude',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className='flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          { value }
        </div>
      );
    },
  },
  {
    dataIndex: 'plan_longitude',
    title: 'Plan Longitude',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className='flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          { value }
        </div>
      );
    },
  },
];

const generateDrillingHoleLocationData = (file) => {
  if(!isEmpty(file)) {
    const dataDrillHoleName = file.properties[0].drill_hole_information[0].drill_hole_name;
    const data = file.properties[1].drill_hole_location;
    let finalResult = dataDrillHoleName.map((drillHoleName, index) => {
      return data.reduce((result, currentObject, indexObject) => {
        const key = Object.keys(currentObject)[0];
        const value = currentObject[key][index]?.value;
        result[key] = value;
        if(indexObject === data?.length -1){
          result['drill_hole_name'] = drillHoleName?.value;
        }
        return result;
      }, {});
    });
    return finalResult;
  }
};

export { generateDrillingHoleLocationTable, generateDrillingHoleLocationData };
