import { generateDrillingHoleInformationActualTable } from 'constant/TableDrillingSummaryActual/TableDrillingHoleInformation';
import TableDrillingSumarry from 'pages/DrillingPlan/DrillingSummary/TableDrillingSummary';

const DrillingHoleInformationActual = (props) => {
  const {
    otherData,
    isDetailMode,
    drillHoleNameProperties,
    drillHoleInformationData,
    onChange,
    onDeleteRow
  } = props;

  const columns = generateDrillingHoleInformationActualTable(
    isDetailMode,
    drillHoleInformationData,
    onChange,
    drillHoleNameProperties,
    onDeleteRow,
    otherData
  );

  return (
    <TableDrillingSumarry
      columns={columns}
      tableData={drillHoleInformationData}
    />
  );
};

export default DrillingHoleInformationActual;
