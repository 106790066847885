import { createAsyncThunk, clearAllListeners } from '@reduxjs/toolkit';

import { persistentStore } from 'store';
import { Base } from 'api/Base';

const logout = createAsyncThunk(
  'auth/logout',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(Base.util.resetApiState());
      await dispatch(clearAllListeners());

      await persistentStore.flush();
      await persistentStore.purge();

      return { ok: true };
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export { logout };