import { Base } from './Base';

const Group = Base.injectEndpoints({
  endpoints: (build) => ({
    getGroups: build.query({
      query: (params) => ({
        url: '/roles/management',
        method: 'GET',
        ...params
          ? { params }
          : undefined
      })
    }),
    getGroupById: build.query({
      query: ({ id }) => ({
        url: `/roles/management/${id}`,
        method: 'GET'
      })
    }),
    postGroup: build.mutation({
      query: (body) => ({
        url: '/roles/management',
        method: 'POST',
        body
      })
    }),
    putGroup: build.mutation({
      query: ({ id, body }) => ({
        url: `/roles/management/${id}`,
        method: 'PUT',
        body
      })
    }),
    deleteGroup: build.mutation({
      query: ({ id }) => ({
        url: `/roles/management/${id}`,
        method: 'DELETE'
      })
    })
  })
});

export const {
  useGetGroupsQuery,
  useLazyGetGroupByIdQuery,
  usePostGroupMutation,
  usePutGroupMutation,
  useDeleteGroupMutation
} = Group;