import moment from 'moment';
import { InputNumber } from 'antd';
import { toLower } from 'lodash';

import { EMPTY, UNIT } from 'constant';
import { generateFormat } from 'utils/PeriodUtility';
import { formatWithEnLocale, parseFromEnLocale } from 'utils/CurrencyFormatter';

const headerColumns = [
  { dataIndex: 'description', title: 'Description' },
  { dataIndex: 'unit', title: 'Unit' }
];

const headerDefaultData = [
  { codeKey: 'calendar_days', description: 'Calendar Days', unit: 'Days' },
  { codeKey: 'holiday', description: 'Holiday', unit: 'Days' },
  { codeKey: 'working_days', description: 'Working Days', unit: 'Days' },
  { codeKey: 'total_hours', description: 'Total Hours', unit: 'Hours' },
  { codeKey: 'available_hours', description: 'Available Hours', unit: 'Hours' },
];

const generateHeaderColumns = (unit, periodName, periods) => {
  const loweredUnit = toLower(unit);
  const isWeekly = toLower(periodName) === 'weekly';
  const isMonthly = toLower(periodName) === 'monthly';
  const momentPeriod = (item) => moment(item, generateFormat(periods, loweredUnit, true, false));

  const generateDataIndex = (item) => momentPeriod(item)
    .format(generateFormat(periods, loweredUnit, false, true));

  const generateTitle = (item) => momentPeriod(item)
    .format(generateFormat(periods, loweredUnit, false, false));

  if (periods && periods.length) {
    if (isWeekly || isMonthly) {
      return [
        ...headerColumns,
        {
          dataIndex: isWeekly
            ? 'week'
            : moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY'),
          title: isWeekly
            ? 'Week'
            : moment(periods[0], 'DD MMM YYYY').format('MMM YYYY')
        }
      ];
    }
    
    return [
      ...headerColumns,
      ...periods.map((item) => {
        return {
          dataIndex: generateDataIndex(item),
          title: generateTitle(item)
        };
      })
    ];
  }

  return headerColumns;
};

const generateHeaderData = (unit, periodName, periods, data) => {
  const loweredUnit = toLower(unit);
  const isWeekly = toLower(periodName) === 'weekly';
  const isMonthly = toLower(periodName) === 'monthly';
  const momentPeriod = (item) => moment(item, generateFormat(periods, loweredUnit, true, false));
  
  if (data) {
    const overBurdenProdLossTime = data?.contents?.find((content) => content.code === 'production_lost_time_ob');
    const lossTime = overBurdenProdLossTime.properties.find((prop) => prop.code === 'lost_time');

    if (periods && periods.length) {
      return headerDefaultData.map((item) => {
        const newItem = { ...item };
        const propItem = lossTime.properties.find((prop) => {
          switch (true) {
            case prop.code === item.codeKey:
              return true;
            case prop.code === 'total_hour':
              return true;
            case prop.code === 'available_hour':
              return true;
            default:
              return false;
          }
        });

        switch (true) {
          case isWeekly: {
            const propDate = propItem.properties
              .find((prop) =>
                moment(
                  moment(prop.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                  'YYYY-MM-DD'
                ).isSame(
                  moment(periods[0], 'DD MMM YYYY').format('YYYY-MM-DD'),
                  'day'
                )
              );

            newItem.week = propDate
              ? propDate.value
              : EMPTY.STRING;

            return newItem;
          }
          case isMonthly: {
            const propDate = propItem.properties
              .find((prop) =>
                moment(
                  moment(prop.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                  'YYYY-MM-DD'
                ).isSame(
                  moment(periods[0], 'DD MMM YYYY').format('YYYY-MM-DD'),
                  'day'
                )
              );

            newItem[moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY')] = propDate
              ? propDate.value
              : EMPTY.STRING;

            return newItem;
          }
          default: {
            const itemPeriod = (period) => momentPeriod(period)
              .format(generateFormat(periods, loweredUnit, false, true));

            periods.forEach((period) => {
              const propDate = propItem.properties
                .find((prop) =>
                  moment(
                    moment(prop.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                    'YYYY-MM-DD'
                  ).isSame(
                    momentPeriod(period).format('YYYY-MM-DD'),
                    'day'
                  )
                );

              newItem[itemPeriod(period)] = propDate
                ? propDate.value
                : EMPTY.STRING;
            });

            return newItem;
          }
        }
      });
    }

    return headerDefaultData;
  }

  return headerDefaultData;
};

const tableDataColumns = [
  {
    dataIndex: 'name',
    title: 'Equipment Name',
    render: (text, record, index) => (
      <span>Overburden {index + 1}</span>
    )
  },
  {
    dataIndex: 'alias_name',
    title: 'Equipment Type',
    render: (text) => {
      const splitAliasName = text.split('-');
      
      return (
        <span>
          {
            splitAliasName.length
              ? splitAliasName[0]
              : EMPTY.STRING
          }
        </span>
      );
    }
  },
  {
    dataIndex: 'alias_name',
    title: 'Equipment No',
    render: (text) => {
      const splitAliasName = text.split('-');

      return (
        <span>
          {
            splitAliasName.length
              ? splitAliasName[1]
              : EMPTY.STRING
          }
        </span>
      );
    }
  }
];

const generateTableColumns = (isInput, isPercentage, model, unit, periods, onChange) => {
  const loweredUnit = toLower(unit);
  const momentPeriod = (item) => moment(item, generateFormat(periods, loweredUnit, true, false));

  const generateDataIndex = (item) => momentPeriod(item)
    .format(generateFormat(periods, loweredUnit, false, true));

  const generateTitle = (item) => momentPeriod(item)
    .format(generateFormat(periods, loweredUnit, false, false));
  
  return [
    ...tableDataColumns,
    ...periods.map((item) => ({
      dataIndex: generateDataIndex(item),
      title: generateTitle(item),
      render: (text, record, index) => {
        switch (true) {
          case isInput:
            return (
              <InputNumber
                key={`key-${model}-${index + 1}`}
                min={EMPTY.NUMBER}
                value={record[generateDataIndex(item)]}
                onChange={(ev) => onChange(ev, record, generateDataIndex(item))}
                formatter={formatWithEnLocale}
                parser={parseFromEnLocale}
                className="w-32"
              />
            );
          case isPercentage:
            return (
              <span>{text} %</span>
            );
          default:
            return (
              <span>{text}</span>
            );
        }
      }
    }))
  ];
};

const generateTableData = (unit, periods, data) => {
  if (data && data.length) {
    const loweredUnit = toLower(unit);
    const momentPeriod = (item) => moment(item, loweredUnit === UNIT.DAY ? 'DD MMM YYYY' : 'MMM YYYY');
    
    return data.map((item) => {
      const newItem = { ...item };

      periods.forEach((period) => {
        newItem[
          momentPeriod(period).format(loweredUnit === UNIT.DAY
            ? 'DD_MMM_YYYY'
            : 'MMM_YYYY')
        ] = 0;
      });

      return newItem;
    });
  }
  
  return [];
};

const generateBody = (
  isDraft,
  id,
  unit,
  periodName,
  periods,
  headers,
  equipments,
  dataTable
) => {
  const isWeekly = toLower(periodName) === 'weekly';
  const isMonthly = toLower(periodName) === 'monthly';
  const momentPeriod = (unit, item) => {
    return moment(item, toLower(unit) === UNIT.DAY ? 'DD MMM YYYY' : 'MMM YYYY')
      .format(toLower(unit) === UNIT.DAY ? 'DD_MMM_YYYY' : 'MMM_YYYY');
  };

  const momentDatePeriod = (unit, item) => {
    return moment(item, toLower(unit) === UNIT.DAY ? 'DD MMM YYYY' : 'MMM YYYY')
      .format('DD-MM-YYYY');
  };

  const generatePeriodFormat = (period) => {
    switch (true) {
      case isWeekly:
        return 'week';
      case isMonthly:
        return moment(periods[0], 'DD MMM YYYY').format('MMM_YYYY');
      default:
        return momentPeriod(unit, period);
    }
  };

  const generateEqTypeNumber = (aliasName, isType) => {
    if (aliasName) {
      const aliasSplit = aliasName.split('-');

      if (aliasSplit && aliasSplit.length) {
        if (isType) {
          return aliasSplit[0] || EMPTY.STRING;
        }

        return aliasSplit[1] || EMPTY.STRING;
      }

      return EMPTY.STRING;
    }

    return EMPTY.STRING;
  };

  const generateSummary = (tab, key) => {
    let total = 0;
    dataTable?.[tab]?.map(item => {
      item[key].forEach(value => {
        total += parseFloat(value);
      });
      return total;
    });
    return total ? key === 'match_factor' ? parseFloat(total).toFixed(1) : parseFloat(total).toFixed(3) : 0;
  };

  return {
    assumption_id: id,
    is_draft: isDraft,
    contents: [
      {
        code: 'period_details',
        properties: headers.map((item) => ({
          code: item.codeKey,
          properties: periods.map((period) => ({
            type: periodName,
            date: momentDatePeriod(unit, period),
            value: item[generatePeriodFormat(period)]
          }))
        }))
      },
      {
        code: 'overburden_hauler',
        properties: equipments.map((item) => ({
          code: 'equipment_details',
          value: [
            {
              code: 'equipment_name',
              value: item.name
            },
            {
              code: 'equipment_type',
              value: generateEqTypeNumber(item.alias_name, true)
            },
            {
              code: 'equipment_no',
              value: generateEqTypeNumber(item.alias_name, false)
            }
          ],
          properties: [{
            code: 'truck_list',
            properties: periods.map((period) => ({
              type: periodName,
              date: momentDatePeriod(unit, period),
              properties: dataTable[generatePeriodFormat(period)].find(v => v.equipment_name === item.name).type_truck.map((data, index) => ({
                code: 'truck',
                properties: [
                  {
                    code: 'type_truck',
                    value: data.name
                  },
                  {
                    code: 'dump_area',
                    value: dataTable[generatePeriodFormat(period)].find(v => v.equipment_name === item.name).dump_area[index]
                  },
                  {
                    code: 'distance',
                    value: dataTable[generatePeriodFormat(period)].find(v => v.equipment_name === item.name).distance[index]
                  },
                  {
                    code: 'haul_hours',
                    value: dataTable[generatePeriodFormat(period)].find(v => v.equipment_name === item.name).haul_hours[index]
                  },
                  {
                    code: 'return_hours',
                    value: dataTable[generatePeriodFormat(period)].find(v => v.equipment_name === item.name).return_hours[index]
                  },
                  {
                    code: 'cycle_time_hour',
                    value: dataTable[generatePeriodFormat(period)].find(v => v.equipment_name === item.name).cycle_time_hour[index]
                  },
                  {
                    code: 'maintenance_hours',
                    value: dataTable[generatePeriodFormat(period)].find(v => v.equipment_name === item.name).maintenance_hours[index]
                  },
                  {
                    code: 'productivity',
                    value: dataTable[generatePeriodFormat(period)].find(v => v.equipment_name === item.name).productivity[index]
                  },
                  {
                    code: 'quantity',
                    value: dataTable[generatePeriodFormat(period)].find(v => v.equipment_name === item.name).quantity[index]
                  },
                  {
                    code: 'effective_working_hour',
                    value: dataTable[generatePeriodFormat(period)].find(v => v.equipment_name === item.name).effective_working_hour[index]
                  },
                  {
                    code: 'hauler_production',
                    value: dataTable[generatePeriodFormat(period)].find(v => v.equipment_name === item.name).hauler_production[index]
                  },
                  {
                    code: 'excavator_production',
                    value: dataTable[generatePeriodFormat(period)].find(v => v.equipment_name === item.name).excavator_production[index]
                  },
                  {
                    code: 'match_factor',
                    value: dataTable[generatePeriodFormat(period)].find(v => v.equipment_name === item.name).match_factor[index]
                  }
                ]
              }))
            }))
          }]
        }))
      },
      {
        code: 'summary',
        properties: periods.map((period) => ({
          type: periodName,
          date: momentDatePeriod(unit, period),
          properties: [
            {
              code: 'average_productivity',
              value: generateSummary(generatePeriodFormat(period), 'productivity')
            },
            {
              code: 'average_quantity',
              value: generateSummary(generatePeriodFormat(period), 'quantity')
            },
            {
              code: 'average_hauler_production',
              value: generateSummary(generatePeriodFormat(period), 'hauler_production')
            },
            {
              code: 'sum_match_factor',
              value: generateSummary(generatePeriodFormat(period), 'match_factor')
            }
          ]
        }))
      }
    ]
  };
};

export {
  generateHeaderColumns,
  generateHeaderData,
  generateTableColumns,
  generateTableData,
  generateBody,
};