import { Tooltip } from 'antd';
import moment from 'moment';
import { FiEye } from 'react-icons/fi';
import { TbPencil } from 'react-icons/tb';

const generateReconciliationTable = (handleActionButton) => [
  {
    dataIndex: 'year',
    title: 'Year Of Period',
    width: '150px',
    sorter: true,
    render: (value) => {
      return  <div>{value}</div>;
    },
  },
  {
    dataIndex: 'company',
    title: 'Mine Site',
    width: '150px',
    sorter: true,
    render: (value) => {
      return <div>{value}</div>;
    },
  },
  {
    dataIndex: 'submitted_date',
    title: 'Date',
    width: '150px',
    sorter: true,
    render: (value) => {
      return  <div>{value? moment(value).format('DD/MMM/YYYY'): '-'}</div>;
    },
  },
  {
    dataIndex: 'status',
    title: 'Status',
    width: '150px',
    sorter: true,
    render: (value) => {
      if(value === 'Draft'){
        return <div className='bg-[#EBEBEB] text-[#AAAAAA] px-3 py-2 w-fit rounded-lg'>{value}</div>;
      } else {
        return <div className='bg-[#2BB8A410] text-[#2BB8A4] px-3 py-2 w-fit rounded-lg'>Submitted</div>;
      }
    },
  },
  {
    dataIndex: 'action',
    title: 'Action',
    width: '100px',
    align:'center',
    sorter: false,
    render: (_, record) =>
    {
      let year = new Date(`${record.year}`);
      return  (
        <div className="flex flex-row justify-center items-center gap-x-3">
          <div
            className="rounded-full bg-[#2BB8A4] cursor-pointer"
            onClick={() => 
              handleActionButton(record.id, 'detail', record.company, year, record.drilling_stage)}
          >
            <Tooltip title={'View Detail'} trigger={'hover'}>
              <FiEye size="24px" className="p-1 text-white" />
            </Tooltip>
          </div>
          <div
            className="rounded-full bg-[#2BB8A4] cursor-pointer"
            onClick={() => handleActionButton(record.id,'edit', record.company, year, record.drilling_stage)}
          >
            <Tooltip title={'Edit'} trigger={'hover'}>
              <TbPencil
                size="24px"
                className="p-1 text-white"
              />
            </Tooltip>
          </div>
        </div>
      );}
  },
];

const reconciliationCategories = () => [
  'Sample Information',
  'True Thickness & Percentage',
  'TM',
  'MOIST',
  'VM',
  'ASH',
  'FC',
  'TS',
  'CV',
  'Remark'
];

const sampleId =  {
  dataIndex: 'sample_id',
  title: 'Sample Id',
  width: '196px',
  sorter: false,
  align: 'center',
  fixed: 'left',
  render: (value) => {
    return (
      <div className='flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
        { value }
      </div>
    );
  },
};

export { generateReconciliationTable, reconciliationCategories, sampleId };