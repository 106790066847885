import moment from 'moment';
import ReactQuill from 'react-quill';
import {
  useEffect,
  useRef,
  useState
} from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import { AiFillCheckCircle, AiOutlineClose } from 'react-icons/ai';
import { Divider } from 'antd';
import { toast } from 'react-toastify';
import {
  every,
  isEmpty,
  toLower
} from 'lodash';

import { useGetAssumptionByIdQuery } from 'api/Assumption';
import {
  useLazyGetMainPlanScenarioByIdQuery,
  usePostMainPlanScenarioMutation,
  usePutMainPlanScenarioMutation
} from 'api/MainPlanScenario';
import { EMPTY, UNIT, URL } from 'constant';
import {
  tableColumns,
  generateTableData,
  generateBody
} from 'constant/TableMainPlanScenario';
import {
  getFullPath,
  getPathType,
  shouldRedirect
} from 'utils/PathUtility';
import { convertPeriodToTags, generateFormat } from 'utils/PeriodUtility';
import { transformError } from 'utils/ErrorTransformer';
import { useAssumptionManager } from 'utils/AssumptionManager';

import BannerPitPeriod from 'components/BannerPitPeriod';
import InputFileAccent from 'components/InputFileAccent';
import TableRaw from 'components/TableRaw';
import ButtonAccent from 'components/ButtonAccent';
import { useModalConfirmationContext } from 'components/ModalConfirmationProvider';

const MainPlanScenario = () => {
  const loc = useLocation();
  const navigate = useNavigate();

  const [isModalShown, setIsModalShown] = useState(false);
  const [tableTabs, setTableTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [scenarios, setScenarios] = useState([]);
  const [file, setFile] = useState(null);
  const [quarterlyData, setQuarterlyData] = useState([]);
  const [isValid, setIsValid] = useState(false);

  const isDraftRef = useRef(false);
  const isQuarterlyRef = useRef(false);
  const scenarioIdRef = useRef(EMPTY.NUMBER);
  const scenarioRef = useRef(EMPTY.STRING);
  const unitRef = useRef(UNIT.MONTH);
  const periodRef = useRef([]);
  const periodNameRef = useRef(EMPTY.STRING);
  const mainPlanIdRef = useRef(null);
  const currentTypeRef = useRef(EMPTY.STRING);

  const { showModal, resetModal } = useModalConfirmationContext();
  const {
    createBackQueryParams,
    isPathHasBackQueryParams,
    isPathMaxBackQueryParams
  } = useAssumptionManager();

  const assumptionId = new URLSearchParams(loc.search).get('id');
  const backQueries = new URLSearchParams(loc.search).getAll('isBack');
  const isBack = isPathHasBackQueryParams(loc.pathname, backQueries);
  const isDetailMode = getPathType(loc) === 'detail';
  const isEditMode = getPathType(loc) === 'edit';
  const isNeedToFetchDetail = Boolean(isBack || isDetailMode || isEditMode);
  
  const {
    data: assumption,
    isFetching: assumptionIsFetching,
    isError: assumptionIsError,
    error: assumptionError
  } = useGetAssumptionByIdQuery(
    { id: assumptionId },
    { refetchOnMountOrArgChange: true}
  );

  const [getMainPlanById, {
    data: mainPlanByIdData,
    isFetching: mainPlanByIdDataIsFetching,
    isError: mainPlanByIdDataIsError,
    error: mainPlanByIdDataError
  }] = useLazyGetMainPlanScenarioByIdQuery();

  const [postMPScenario, {
    isLoading: mPlanIsLoading,
    isSuccess: mPlanIsSuccess,
    isError: mPlanIsError,
    error: mPlanError
  }] = usePostMainPlanScenarioMutation();

  const [putMPScenario, {
    isLoading: putMPScenarioIsLoading,
    isSuccess: putMPScenarioIsSuccess,
    isError: putMPScenarioIsError,
    error: putMPScenarioError
  }] = usePutMainPlanScenarioMutation();

  const setModalVisibility = () => setIsModalShown((prevState) => !prevState);
  
  const addScenario = () => {
    setScenarios((prevState) => {
      if (isEmpty(prevState) && isQuarterlyRef.current) {
        const newScenarios = [];
        
        quarterlyData.forEach((item) => {
          if (isEmpty(item.scenarios) && item.key === activeTab) {
            newScenarios.push({
              key: item.key,
              id: moment().unix(),
              scenario: scenarioRef.current
            });
          }

          item.scenarios.forEach((scenario) => {
            if (scenario.id === scenarioIdRef.current) {
              newScenarios.push({
                key: item.key,
                id: scenario.id,
                scenario: scenarioRef.current
              });
              
              return;
            }

            newScenarios.push({
              key: item.key,
              id: scenario.id,
              scenario: scenario.scenario
            });
          });
        });

        return newScenarios;
      }

      return scenarioIdRef.current
        ? prevState.map((item) => {
          if (item.id === scenarioIdRef.current) {
            return {
              ...isQuarterlyRef.current
                ? { key: activeTab }
                : undefined,
              id: item.id,
              scenario: scenarioRef.current
            };
          }
          
          return item;
        })
        : [
          ...prevState,
          {
            ...isQuarterlyRef.current
              ? { key: activeTab }
              : undefined,
            id: moment().unix(),
            scenario: scenarioRef.current
          }
        ];
    });

    scenarioIdRef.current = EMPTY.NUMBER;
    scenarioRef.current = EMPTY.STRING;
    setModalVisibility();
  };

  const editScenario = (record) => {
    scenarioIdRef.current = record.id;
    scenarioRef.current = record.scenario;
    
    setModalVisibility();
  };

  const deleteScenario = (record) => {
    showModal({
      isShown: true,
      type: 'delete',
      message: 'Are You sure you want to delete scenario?',
      onSubmit: () => {
        setScenarios((prevState) => {
          return prevState.filter((item) => item.id !== record.id);
        });

        resetModal();
      }
    });
  };

  const closeModalScenario = () => {
    scenarioIdRef.current = EMPTY.NUMBER;
    scenarioRef.current = EMPTY.STRING;
    setModalVisibility();
  };

  const redirectPathToCreate = () => navigate({
    pathname: getFullPath({
      parent: 'assumption',
      child: 'main-plan-scenario',
      type: 'create'
    }),
    search: createSearchParams({ id: assumptionId }).toString()
  });

  const goToPath = (type) => navigate({
    pathname: getFullPath({
      parent: 'assumption',
      child: type === 'back'
        ? 'pit-reserve'
        : 'summary-production',
      type: getPathType(loc)
    }),
    search: createSearchParams({
      id: assumptionId,
      ...type === 'back'
        ? {
          isBack: isBack
            ? backQueries
            : createBackQueryParams(loc.pathname)
        }
        : {
          ...isPathMaxBackQueryParams(loc.pathname, backQueries)
            ? undefined
            : { isBack: backQueries }
        }
    }).toString()
  });

  const generateQuarterlyData = (data) => {
    return data.map((item) => ({
      key: item.codeKey,
      file: null,
      scenarios: []
    }));
  };
  
  const selectQuarterlyData = () => {
    if (activeTab && Boolean(quarterlyData && quarterlyData.length)) {
      return quarterlyData.find((item) => activeTab === item.key);
    }
    
    return {
      key: null,
      file: null,
      scenarios: []
    };
  };

  const transformValidationIcon = (isActive, codeKey) => {
    const monthValidationData = quarterlyData.find((item) => codeKey === item.key);
    const isMonthValid = !isEmpty(monthValidationData)
      ? Boolean(monthValidationData.file && Boolean(monthValidationData.scenarios && monthValidationData.scenarios.length))
      : false;
    
    if (isActive) {
      return isMonthValid
        ? 'text-white'
        : 'text-white/50';
    }

    return isMonthValid
      ? 'text-[#2D3D5A]'
      : 'text-[#2D3D5A]/25';
  };

  const postMainPlanScenario = (isDraft) => {
    isDraftRef.current = isDraft;

    const body = generateBody(
      isDraftRef.current,
      assumptionId,
      unitRef.current,
      periodNameRef.current,
      periodRef.current,
      file,
      scenarios,
      quarterlyData
    );

    if (isBack || isEditMode) {
      const { assumption_id, ...item } = body;
      putMPScenario({
        id: mainPlanIdRef.current,
        body: { ...item }
      });

      return;
    }

    postMPScenario(body);
  };

  useEffect(() => {
    if (isEditMode) {
      currentTypeRef.current = getPathType(loc);

      return;
    }

    if (currentTypeRef.current === 'edit' && getPathType(loc) === 'create') {
      window.location.reload();
    }
  }, [loc]);

  useEffect(() => {
    periodNameRef.current = toLower(assumption?.category?.name);
    unitRef.current = toLower(assumption?.category?.unit);
    periodRef.current = convertPeriodToTags(
      unitRef.current,
      assumption?.start_date,
      assumption?.end_date
    );
    isQuarterlyRef.current = periodNameRef.current === 'quarterly';
    setTableTabs(
      periodRef.current.map((item) => ({
        codeKey: moment(item, generateFormat(periodRef.current, unitRef.current, true, false))
          .format(generateFormat(periodRef.current, unitRef.current, false, true)),
        title: item
      }))
    );
  }, [assumption]);

  useEffect(() => {
    if (isEditMode && shouldRedirect(mainPlanByIdData)) {
      redirectPathToCreate();

      return;
    }

    if (!isEmpty(mainPlanByIdData)) {
      mainPlanIdRef.current = mainPlanByIdData.id;

      if (!isQuarterlyRef.current) {
        const tableData = generateTableData(
          unitRef.current,
          periodRef.current,
          periodNameRef.current,
          mainPlanByIdData
        );

        setFile(tableData.attachment.value);
        setScenarios(
          tableData.scenario.value.map((item, index) => ({
            id: `${index + 1}-${moment().unix()}`,
            scenario: item
          }))
        );
      }

      /**
       * Quarterly Detail Generator
       * */
      if (!isEmpty(tableTabs) && isQuarterlyRef.current) {
        const tableData = generateTableData(
          unitRef.current,
          periodRef.current,
          periodNameRef.current,
          mainPlanByIdData
        );

        setActiveTab(tableTabs[0].codeKey);
        setQuarterlyData(
          tableTabs.map((item) => ({
            key: item.codeKey,
            file: tableData.attachment[item.codeKey],
            scenarios: tableData.scenario[item.codeKey].map((scenario, index) => ({
              id: `${index + 1}-${moment().unix()}`,
              scenario
            }))
          }))
        );
      }
    }
  }, [mainPlanByIdData]);

  useEffect(() => {
    if (!isEmpty(tableTabs)) {
      if (isNeedToFetchDetail) {
        getMainPlanById({ id: assumptionId });

        return;
      }

      setActiveTab(tableTabs[0].codeKey);
      setQuarterlyData(generateQuarterlyData(tableTabs));
    }
  }, [tableTabs]);
  
  useEffect(() => {
    if (isQuarterlyRef.current) {
      setQuarterlyData((prevState) =>
        prevState.map((item) => {
          if (activeTab === item.key) {
            return {
              ...item,
              file
            };
          }
          
          return item;
        })
      );
      
      return;
    }

    setIsValid(Boolean(scenarios && scenarios.length) && Boolean(file));
  }, [file]);

  useEffect(() => {
    if (isQuarterlyRef.current) {
      setQuarterlyData((prevState) =>
        prevState.map((item) => {
          if (activeTab === item.key) {
            return {
              ...item,
              scenarios: scenarios.filter((scenario) => scenario.key === activeTab)
            };
          }

          return item;
        })
      );
      
      return;
    }

    setIsValid(Boolean(scenarios && scenarios.length) && Boolean(file));
  }, [scenarios]);

  useEffect(() => {
    if (isQuarterlyRef.current) {
      const validations = periodRef.current.map(
        (period) => quarterlyData.some(
          (item) => {
            if (item.key === moment(period, 'MMM YYYY').format('MMM_YYYY')) {
              return Boolean(item.file) && Boolean(item.scenarios && item.scenarios.length);
            }
           
            return false;
          })
      );

      setIsValid(every(validations));
    }
  }, [quarterlyData]);
  
  useEffect(() => {
    if (assumptionIsError || mainPlanByIdDataIsError) {
      const generateToastId = () => {
        switch (true) {
          case assumptionIsError:
            return 'assumption';
          case mainPlanByIdDataIsError:
            return 'main-plan-by-id';
          default:
            return 'default';
        }
      };

      toast.error(
        transformError(assumptionError || mainPlanByIdDataIsError).message,
        { toastId: `${generateToastId()}-error` }
      );
    }
  }, [
    assumptionIsError,
    assumptionError,
    mainPlanByIdDataIsError,
    mainPlanByIdDataError
  ]);

  useEffect(() => {
    if (mPlanIsSuccess || putMPScenarioIsSuccess) {
      if (isDraftRef.current) {
        navigate(`/${URL.ACTIVITY_LEVEL}/${URL.MINE_PLANNING_OE}/assumption`);

        return;
      }

      goToPath('next');

      return;
    }

    if (mPlanIsError || putMPScenarioIsError) {
      const generateToastId = () => {
        switch (true) {
          case mPlanIsError:
            return 'main-plan-post';
          case putMPScenarioIsError:
            return 'main-plan-put';
          default:
            return 'default';
        }
      };

      toast.error(
        transformError(mPlanError || putMPScenarioError).message,
        { toastId: `${generateToastId()}-error` }
      );

      return;
    }

    return () => {};
  }, [
    mPlanIsSuccess,
    mPlanIsError,
    mPlanError,
    putMPScenarioIsSuccess,
    putMPScenarioIsError,
    putMPScenarioError
  ]);
  
  return (
    <div className="flex flex-col">
      <BannerPitPeriod
        isLoading={assumptionIsFetching || mainPlanByIdDataIsFetching}
        companyName={assumption?.company?.alias_name}
        contractorName={assumption?.contractor?.alias_name}
        pitName={assumption?.pit?.name}
        periodMode={assumption?.category?.name}
        periods={assumption
          ? periodRef.current
          : []}
      />
      {
        isQuarterlyRef.current
          ? (
            <>
              {
                (tableTabs && tableTabs.length)
                  ? (
                    <div className="mt-5 flex flex-row items-center gap-x-3">
                      {
                        tableTabs.map((item, index) => (
                          <div
                            key={`type-${index + 1}`}
                            className={`p-2 w-full flex flex-row items-center rounded-lg gap-x-3 cursor-pointer
                            ${activeTab === item.codeKey ? 'bg-[#2D3D5A]' : 'bg-white/50'}`}
                            onClick={() => setActiveTab(item.codeKey)}
                          >
                            <div className={`w-2 h-4 rounded ${activeTab === item.codeKey ? 'bg-white' : 'bg-[#2D3D5A]'}`} />
                            <span className={`font-bold text-sm text-center self-center mr-auto ${activeTab === item.codeKey ? 'text-white' : 'text-[#2D3D5A]'}`}>
                              {item.title}
                            </span>
                            <AiFillCheckCircle className={transformValidationIcon(item.codeKey === activeTab, item.codeKey)} />
                          </div>
                        ))
                      }
                    </div>
                  )
                  : (<></>)
              }
            </>
          )
          : (<></>)
      }

      <InputFileAccent
        isDetailMode={isDetailMode}
        value={
          isQuarterlyRef.current
            ? selectQuarterlyData().file
            : file
        }
        onChange={setFile}
        onDrop={setFile}
        className="mt-5"
      />

      <div className="mt-5 p-5 flex flex-col bg-white rounded-lg">
        <div className="mb-5 flex flex-row items-center gap-x-5">
          <span className="font-bold text-lg">Main Plan Scenario</span>
          {
            !isDetailMode && (
              <ButtonAccent
                size="xl"
                title="Add New Scenario"
                onClick={setModalVisibility}
              />
            )
          }
        </div>
        <TableRaw
          columns={
            tableColumns(
              isDetailMode,
              editScenario,
              deleteScenario
            )
          }
          dataSource={
            isQuarterlyRef.current
              ? selectQuarterlyData().scenarios
              : scenarios
          }
        />
      </div>
      {
        isModalShown && (
          <div className="w-full h-full absolute flex flex-row items-center justify-center bg-black/25 left-0 top-0 z-20">
            <div className="p-5 w-2/3 flex flex-col bg-white rounded-lg">
              <div className="flex flex-row">
                <span className="font-bold text-lg">Input Scenario</span>
                <div
                  className="ml-auto p-1 bg-violet-200 rounded-full cursor-pointer"
                  onClick={closeModalScenario}
                >
                  <AiOutlineClose size="18px" className="text-[#2D3D5A]" />
                </div>
              </div>
              <Divider />
              <div className="mb-5 flex flex-col">
                <span className="mb-5 font-bold">Scenario</span>
                <div className="mb-12">
                  <ReactQuill
                    theme="snow"
                    className="h-56"
                    value={scenarioRef.current}
                    onChange={(val) => { scenarioRef.current = val; }}
                  />
                </div>
              </div>
              <div className="flex flex-row justify-between">
                <ButtonAccent
                  isBordered
                  size="xl"
                  title="Cancel"
                  onClick={closeModalScenario}
                />
                <ButtonAccent
                  size="xl"
                  title={scenarioIdRef.current ? 'Edit' : 'Add'}
                  onClick={addScenario}
                />
              </div>
            </div>
          </div>
        )
      }

      <div className="mt-5 w-full flex flex-row items-center">
        <ButtonAccent
          isDisabled={isDetailMode}
          isLoading={mPlanIsLoading || putMPScenarioIsLoading}
          size="lg"
          title="Save As Draft"
          onClick={() => postMainPlanScenario(true)}
        />

        <div className="ml-auto flex flex-row items-center gap-x-3">
          <ButtonAccent
            isBordered
            title="Back"
            onClick={() => goToPath('back')}
          />
          <ButtonAccent
            isDisabled={!isValid}
            isLoading={mPlanIsLoading || putMPScenarioIsLoading}
            title="Next"
            onClick={() => isDetailMode
              ? goToPath('next')
              : postMainPlanScenario(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default MainPlanScenario;