import moment from 'moment';
import {
  useEffect,
  useRef,
  useState
} from 'react';
import { useLocation, useNavigate } from 'react-router';
import { AiOutlineClose } from 'react-icons/ai';
import {
  FiDownload,
  FiEye,
  FiTrash
} from 'react-icons/fi';
import {
  Alert,
  Divider,
  Image,
  Tag
} from 'antd';
import { toast } from 'react-toastify';
import { every, isEmpty } from 'lodash';

import { useGetSubmissionByIdQuery, usePostSubmissionMutation } from 'api/ConfidentLevel';
import { useGetSubmissionsQuery } from 'api/User';
import { DEFAULT, EMPTY, URL } from 'constant';
import {
  generateSubmissionColumns,
  generateSubmissionData,
  generateBody
} from 'constant/TableSubmissionPIC';
import { getPathType } from 'utils/PathUtility';
import { transformError } from 'utils/ErrorTransformer';
import { useTableWrapper } from 'utils/TableResponsive';

import TableRaw from 'components/TableRaw';
import ButtonAccent from 'components/ButtonAccent';
import LoadingIcon from 'components/LoadingIcon';
import InputFileAccent from 'components/InputFileAccent';
import { useImagePreviewContext } from 'components/ImagePreviewProvider';

const PersonInCharge = () => {
  const loc = useLocation();
  const navigate = useNavigate();

  const [submissionData, setSubmissionData] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [isModalShown, setIsModalShown] = useState(false);
  const [rating, setRating] = useState(null);
  const [uploadFiles, setUploadFiles] = useState([]);

  const isDraftRef = useRef(false);
  const modalTypeRef = useRef(EMPTY.STRING);
  const recordRef = useRef(null);

  const { showPreview } = useImagePreviewContext();
  const { wrapperWidth } = useTableWrapper();

  const confidentLevelId = new URLSearchParams(loc.search).get('id');
  const isDetailMode = getPathType(loc) === 'detail';
  
  const MODAL_TYPE = {
    RATING: 'rating',
    EVIDENCE: 'evidence'
  };

  const {
    data: submissionByIdData,
    isFetching: submissionByIdDataIsFetching,
    isError: submissionByIdDataIsError,
    error: submissionByIdDataError
  } = useGetSubmissionByIdQuery(
    { id: confidentLevelId },
    { refetchOnMountOrArgChange: true }
  );

  const {
    data: submissions,
    isFetching: submissionIsFetching,
    isError: submissionIsError,
    error: submissionError
  } = useGetSubmissionsQuery(
    { id: confidentLevelId },
    { refetchOnMountOrArgChange: true }
  );

  const [postSubmission, {
    isLoading: postSubmissionIsLoading,
    isSuccess: postSubmissionIsSuccess,
    isError: postSubmissionIsError,
    error: postSubmissionError
  }] = usePostSubmissionMutation();

  const generateSubmissionRowStyle = (record, index) => {
    if (isNaN(Number(record.number))) {
      return 'font-bold bg-green-700/20 text-green-700';
    }

    return index % 2 === 0 ? 'bg-white' : 'bg-gray-100';
  };

  const onAdd = () => {
    if (modalTypeRef.current === MODAL_TYPE.RATING) {
      setSubmissionData((prevState) =>
        prevState.map((item) => {
          if (item.confident_level_parameter_id === rating.confident_level_parameter_id) {
            return {
              ...item,
              answer: {
                ...item.answer,
                score: rating.score,
                description: rating.description
              }
            };
          }

          return { ...item };
        })
      );
    }

    if (modalTypeRef.current === MODAL_TYPE.EVIDENCE) {
      setSubmissionData((prevState) =>
        prevState.map((item) => {
          if (uploadFiles.some((file) => file.record.confident_level_parameter_id === item.confident_level_parameter_id)) {
            return {
              ...item,
              answer: {
                ...item.answer,
                evidence: uploadFiles
              }
            };
          }

          return { ...item };
        })
      );
    }

    setRating(null);
    setUploadFiles([]);
    setIsModalShown(false);
  };

  const onCancel = () => {
    setRating(null);
    setUploadFiles([]);
    setIsModalShown(false);
  };
  
  const onUploadFile = (file, record) => {
    if (file) {
      setUploadFiles((prevState) => {
        if (prevState && prevState.length) {
          return [
            ...prevState,
            {
              ...file,
              record
            }
          ];
        }

        return [{
          ...file,
          record
        }];
      });
    }
  };

  const onDeleteFile = (file) => {
    setUploadFiles((prevState) => prevState.filter((item) => item.id !== file.id));
  };

  const generateRatingView = (record) => {
    const selectTempAnswer = (item) => {
      setRating({
        ...record,
        score: item.score,
        description: item.description
      });
    };

    if (record.rating_level_guidance && record.rating_level_guidance.length) {
      return record.rating_level_guidance.map((item, index) => (
        <div
          key={`key-rating-${index + 1}`}
          className={`mb-3 p-3 flex flex-row items-center border-2 rounded-lg cursor-pointer
          ${(rating?.score === item.score && rating?.description === item.description)
            ? 'border-[#2D3D5A]'
            : 'border-gray-200'}`}
          onClick={() => selectTempAnswer(item)}
        >
          <span className="w-2/3">
            {item.description}
          </span>
          <span className="ml-auto">( {item.score} )</span>
        </div>
      ));
    }
    
    return <></>;
  };

  const generateUploadView = (record) => {
    return (
      <>
        {
          !isDetailMode && (
            <InputFileAccent
              noTitle
              isResetAfter
              isAllTypes
              onChange={(file) => onUploadFile(file, record)}
              onDrop={(file) => onUploadFile(file, record)}
            />
          )
        }
        {
          (uploadFiles && uploadFiles.length)
            ? (
              <>
                {
                  uploadFiles.map((item, index) => (
                    <div
                      key={`file-upload-${index + 1}`}
                      className="mb-3 p-3 flex flex-row items-center bg-violet-100 rounded-lg"
                    >
                      <Image
                        preview={false}
                        width={100}
                        src={item.thumbnail}
                        className="rounded-lg"
                      />
                      <span className="w-64 ml-3 mr-5 truncate">
                        {item.fileName}
                      </span>
                      <div className="ml-auto flex flex-row items-center gap-x-3">
                        <div
                          className="p-1 rounded-full bg-[#2D3D5A] cursor-pointer"
                          onClick={() => showPreview({
                            name: item.fileName,
                            size: item.size,
                            url: item.thumbnail
                          })}
                        >
                          <FiEye
                            size="18px"
                            className="text-white"
                          />
                        </div>
                        {
                          !isDetailMode && (
                            <div
                              className="p-1 bg-pink-700 rounded-full cursor-pointer"
                              onClick={() => onDeleteFile(item)}
                            >
                              <FiTrash className="text-white" />
                            </div>
                          )
                        }
                      </div>
                    </div>
                  ))
                }
              </>
            )
            : (<></>)
        }
      </>
    );
  };
  
  const onRatingClick = (record) => {
    modalTypeRef.current = MODAL_TYPE.RATING;
    recordRef.current = record;

    setRating({
      ...record,
      score: record.answer?.score,
      description: record.answer?.description
    });
    setIsModalShown(true);
  };
  
  const onUpload = (record) => {
    modalTypeRef.current = MODAL_TYPE.EVIDENCE;
    recordRef.current = record;

    setUploadFiles(record.answer?.evidence);
    setIsModalShown(true);
  };
  
  const onInfoChange = (val, record) => {
    setSubmissionData((prevState) =>
      prevState.map((item) => {
        if (item.confident_level_parameter_id === record.confident_level_parameter_id) {
          return {
            ...item,
            answer: {
              ...item.answer,
              information: val
            },
            information: val
          };
        }

        return { ...item };
      })
    );
  };

  const downloadGuidances = () => {
    if (!isEmpty(submissions)) {
      submissions.guidance_files.forEach((item) => {
        if (!isEmpty(item)) {
          const docLink = document.createElement('a');
          docLink.href = item.url;

          document.body.appendChild(docLink);
          docLink.click();
          document.body.removeChild(docLink);

          return;
        }

        toast.error('Cannot find guidance file(s), please ask your administrator');
      });
    }
  };

  const postSubmissions = (isDraft) => {
    isDraftRef.current = isDraft;

    const body = generateBody(
      isDraft,
      submissions,
      submissionData
    );

    postSubmission(body);
  };

  useEffect(() => {
    if (!isEmpty(submissions)) {
      setSubmissionData(generateSubmissionData(submissions));
    }
  }, [submissions]);
  
  useEffect(() => {
    if (submissionData && submissionData.length) {
      const submissionValidations = submissionData.map((item) => {
        if (item.confident_level_parameter_id) {
          if (item.answer) {
            return Boolean(
              item.answer.score
              && item.answer.description
              && item.information
              && Boolean(item.answer.evidence && item.answer.evidence.length)
            );
          }
          
          return false;
        }
        
        return true;
      });

      setIsValid(every(submissionValidations));
    }
  }, [submissionData]);

  useEffect(() => {
    if (submissionByIdDataIsError
      || submissionIsError
      || postSubmissionIsError
    ) {
      const generateToastId = () => {
        switch (true) {
          case submissionByIdDataIsError:
            return 'submission-by-id';
          case submissionIsError:
            return 'submission';
          case postSubmissionIsError:
            return 'post-submission';
          default:
            return 'default';
        }
      };
      
      toast.error(
        transformError(
          submissionByIdDataError
          || submissionError
          || postSubmissionError
        ).message,
        { toastId: `${generateToastId()}-toast-error` }
      );
    }
  }, [
    submissionByIdDataIsError,
    submissionByIdDataError,
    submissionIsError,
    submissionError,
    postSubmissionIsError,
    postSubmissionError
  ]);
  
  useEffect(() => {
    if (postSubmissionIsSuccess) {
      toast.success(
        `You have successfully ${isDraftRef.current ? 'save submission as draft' : 'submitted submission'}`,
        { toastId: 'post-submission-toast-success' }
      );
      
      navigate(`/${URL.ACTIVITY_LEVEL}/${URL.MINE_PLANNING_OE}/confident-level`);
    }
  }, [postSubmissionIsSuccess]);
  
  return (
    <>
      <div className="p-5 flex flex-col bg-white rounded-xl">
        <div className="flex flex-row items-center">
          <div className="mr-auto flex flex-row items-center">
            <span className="mr-3">PIC Submission</span>
            <Tag className="px-3 font-bold rounded-xl bg-pink-700/25 text-pink-700 border-none">
              {
                !isEmpty(submissionByIdData)
                  ? (
                    <>
                      {moment(submissionByIdData.date_from).format('MMM YYYY')} - {moment(submissionByIdData.date_to).format('MMM YYYY')}
                    </>
                  )
                  : (<></>)
              }
            </Tag>
          </div>

          {
            submissionByIdDataIsFetching
              ? (
                <LoadingIcon size="sm" />
              )
              : (
                <>
                  {
                    Boolean(submissions?.guidance_files && submissions?.guidance_files.length)
                    && (
                      <div className="flex flex-col items-end gap-y-3">
                        <ButtonAccent
                          isBordered
                          title="Download Guidance"
                          size="xl"
                          icon={<FiDownload />}
                          onClick={downloadGuidances}
                        />
                        <span>
                          Last Update Guidance
                          {' '}
                          <span className="font-bold text-[#2D3D5A]">
                            {
                              submissions?.last_updated_at
                                ? moment(submissions?.last_updated_at).format('DD MMM YYYY')
                                : DEFAULT.UNKNOWN
                            }
                          </span>
                        </span>
                      </div>
                    )
                  }
                </>
              )
          }
        </div>
        {
          Boolean(submissions?.guidance_files
            && submissions?.guidance_files
            && submissions?.guidance_files.some((item) => isEmpty(item))
          ) && (
            <Alert
              type="error"
              message="Some Guidance Files cannot be recognized, if you submit this submission it will failed, please ask your administrator"
              className="mt-5 font-bold text-red-400"
            />
          )
        }
        <div
          className="mt-5 overflow-x-auto"
          style={{ maxWidth: wrapperWidth }}
        >
          <TableRaw
            isLoading={submissionIsFetching}
            scrollWidth="max-content"
            columns={
              generateSubmissionColumns(
                isDetailMode,
                onRatingClick,
                onUpload,
                onInfoChange
              )
            }
            dataSource={submissionData}
            customRow={(record, index) => generateSubmissionRowStyle(record, index)}
          />
        </div>
        {
          isModalShown && (
            <div className="py-5 w-full h-full absolute flex flex-row items-start justify-center bg-black/25 left-0 top-0 z-20 overflow-y-auto">
              <div className="p-5 w-1/2 flex flex-col bg-white rounded-lg">
                <div className="flex flex-row items-center">
                  <span className="font-bold">
                    {
                      modalTypeRef.current === MODAL_TYPE.RATING
                        ? 'Add Rating'
                        : 'Upload Evidence'
                    }
                  </span>
                  <div
                    className="ml-auto p-1 bg-violet-200 rounded-full cursor-pointer"
                    onClick={() => setIsModalShown(false)}
                  >
                    <AiOutlineClose size="18px" className="text-[#2D3D5A]" />
                  </div>
                </div>
                <Divider />
                {
                  modalTypeRef.current === MODAL_TYPE.RATING
                    ? generateRatingView(recordRef.current)
                    : generateUploadView(recordRef.current)
                }
                <div className="mt-5 w-full flex flex-row items-center">
                  <div className="ml-auto flex flex-row gap-x-3">
                    <ButtonAccent
                      isBordered
                      title="Cancel"
                      onClick={onCancel}
                    />
                    {
                      !isDetailMode && (
                        <ButtonAccent
                          title="Add"
                          onClick={onAdd}
                          isDisabled={
                            modalTypeRef.current === MODAL_TYPE.EVIDENCE 
                              ? !(uploadFiles && uploadFiles.length) 
                              : false
                          }
                        />
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </div>
      <div className="mt-5 w-full flex flex-row items-center">
        {
          !isDetailMode && (
            <ButtonAccent
              isLoading={postSubmissionIsLoading}
              size="lg"
              title="Save As Draft"
              onClick={() => postSubmissions(true)}
            />
          )
        }

        <div className="ml-auto flex flex-row items-center gap-x-3">
          <ButtonAccent
            isBordered
            title="Back"
            onClick={() => navigate(`/${URL.ACTIVITY_LEVEL}/${URL.MINE_PLANNING_OE}/confident-level`)}
          />

          {
            !isDetailMode && (
              <ButtonAccent
                isLoading={postSubmissionIsLoading}
                isDisabled={!isValid}
                title="Submit"
                onClick={() => postSubmissions(false)}
              />
            )
          }
        </div>
      </div>
    </>
  );
};

export default PersonInCharge;