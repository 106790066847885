import { isEmpty } from 'lodash';
import { RxCross2 } from 'react-icons/rx';
import { InputNumber } from 'antd';
import { EMPTY } from 'constant';

const generateTrueThicknessPercentageTable = (
  payload,
  isDetailMode,
  onChange,
  onDeleteRow
) => [
  {
    dataIndex: 'action',
    title: 'Action',
    width: 5,
    sorter: false,
    align: 'center',
    fixed: 'left',
    render: (_, __, index) => {
      return (
        <div className="flex justify-center">
          {payload.length <= 1 ? (
            <></>
          ) : (
            <RxCross2
              size={28}
              color={`${isDetailMode? 'gray' : 'red'}`}
              className={`rounded-full p-1 ${isDetailMode? 'bg-gray-200':'cursor-pointer bg-red-200 '}`}
              onClick={() => onDeleteRow(index)}
            />
          )}
        </div>
      );
    },
  },
  {
    title: 'True Thickness (m)',
    width: '166px',
    align: 'center',
    sorter: false,
    children:[ 
      {
        dataIndex: 'model_thk',
        title: 'Model',
        width: '196px',
        sorter: false,
        align: 'center',
        render: (value, record, index) => {
          return (
            <InputNumber
              disabled={isDetailMode}
              className='w-full'
              placeholder={'Enter model'}
              value={value}
              type='number'
              onChange={(val)=>{
                onChange(index, 'true_thickness_&_percentage', 'model_thk' ,val);
                calculateVariance(record.insitu_thk, val, index, onChange, 'true_thickness_&_percentage', 'var_thk');
              }}
              status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
            /> 
          );
        },
      },
      {
        dataIndex: 'insitu_thk',
        title: 'Insitu',
        width: '196px',
        sorter: false,
        align: 'center',
        render: (value, record, index) => {
          return (
            <InputNumber
              disabled={isDetailMode}
              className='w-full'
              placeholder={'Enter insitu'}
              value={value}
              type='number'
              onChange={(val)=>{
                onChange(index, 'true_thickness_&_percentage', 'insitu_thk' ,val);
                calculateVariance(record.model_thk, val, index, onChange, 'true_thickness_&_percentage', 'var_thk', 'insitu');
              }}
              status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
            /> 
          );
        },
      },
      {
        dataIndex: 'var_thk',
        title: 'Variance',
        width: '196px',
        sorter: false,
        align: 'center',
        render: (value, _) => {
          return (
            <div className='flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
              { value }
            </div>
          );
        },
      },
    ]
  },
  {
    title: 'Percentage',
    width: '166px',
    align: 'center',
    sorter: false,
    children:[ 
      {
        dataIndex: 'seam_percentage',
        title: 'By Seam',
        width: '196px',
        sorter: false,
        align: 'center',
        render: (value, _) => {
          const populateValue= ()=>{
            if(Number(value) >= 0){
              if(Number(value) % 1 !== 0){
                return `${value.toFixed(2)}%`;
              }else {
                return `${value}%`;
              }
            }
            return value;
          };
          return (
            <div className='flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
              { populateValue() }
            </div>
          );
        },
      },
    ]
  }
];

const calculateVariance = (record, val, index, onChange, categoryName, attributeName, type) => {
  let totalVariance = 0;
  if (record && val) {
    totalVariance = record > val ? parseFloat(record) - parseFloat(val) : parseFloat(val) - parseFloat(record);
  }

  onChange(index, categoryName, attributeName, totalVariance);

  if (totalVariance > 0 && type === 'insitu') {
    const totalPercent = (totalVariance / val) * 100;
    onChange(index, categoryName, 'seam_percentage', parseFloat(totalPercent));
  }
};

const generateTrueThicknessPercentageData = (file) =>{
  if(!isEmpty(file)) {
    const dataSampleInformation = file.properties[0].sample_information;
    const totalData = dataSampleInformation[0].sample_id.length;
    const data = file.properties[1]['true_thickness_&_percentage'];
    const result = [];

    for(let i = 0; i < totalData; i++){
      result.push({
        sample_id: dataSampleInformation[0].sample_id[i]?.value,
        model_thk: data[0].model_thk[i]?.value,
        insitu_thk: data[1].insitu_thk[i]?.value,
        var_thk: data[2].var_thk[i]?.value,
        seam_percentage: data[3].seam_percentage[i].value,
      });
    }
    return result;
  }
};

export { generateTrueThicknessPercentageData, generateTrueThicknessPercentageTable, calculateVariance };