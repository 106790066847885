import { Input } from 'antd';
import { FiUpload } from 'react-icons/fi';
import { isEmpty } from 'lodash';

import ButtonAccent from 'components/ButtonAccent';

const { TextArea } = Input;
const generateSubmissionColumns = (
  isDetailMode,
  onRatingClick,
  onUpload,
  onInfoChange
) => [
  {
    dataIndex: 'number',
    title: 'No',
    onCell: (record) => ({
      /**
       * @param {string} record.number
       * because we set any `category` to `record.number`
       * so, any NaN `record.number` will take `question` column
       * */
      colSpan: isNaN(Number(record.number))
        ? 2
        : 1
    })
  },
  {
    dataIndex: 'question',
    title: 'Question',
    width: '300px',
    onCell: (record) => ({
      /**
       * if `record.number` is NaN we make `question` column zero
       * to make room for `record.number`
       * */
      colSpan: isNaN(Number(record.number))
        ? 0
        : 1
    })
  },
  {
    dataIndex: 'rating_level_guidance',
    title: 'Rating',
    width: '400px',
    render: (text, record, index) => {
      const isAnswered = record?.rating_level_guidance?.some(
        (item) => item.score === record.answer?.score && item.description === record.answer?.description
      );

      return (
        <>
          {
            isNaN(Number(record.number))
              ? (
                <></>
              )
              : isDetailMode
                ? (
                  <div className="w-full flex flex-row items-center">
                    {
                      Boolean(record.answer?.description && record.answer?.score)
                        ? (
                          <>
                            <span className="w-5/6">
                              {record.answer?.description}
                            </span>
                            <span className="px-3 w-1/6 font-bold">( {record.answer?.score} )</span>
                          </>
                        )
                        : (
                          <>
                            <span className="font-bold text-red-400">
                              Not Answered Yet
                            </span>
                          </>
                        )
                    }
                  </div>
                )
                : (
                  <ButtonAccent
                    key={`submission-rating-${index + 1}`}
                    isBordered={!isAnswered}
                    isDisabled={isDetailMode}
                    title="Add Rating"
                    onClick={() => onRatingClick(record)}
                  />
                )
          }
        </>
      ); 
    }
  },
  {
    dataIndex: 'evidence',
    title: 'Evidence',
    render: (text, record, index) => (
      <>
        {
          isNaN(Number(record.number))
            ? (
              <></>
            )
            : (
              <div className="flex flex-row items-center justify-center gap-x-3">
                {
                  (record.answer?.evidence && record.answer?.evidence?.length)
                    ? (
                      <span className="text-[#2D3D5A]">
                        {record.answer?.evidence?.length} File(s) Uploaded
                      </span>
                    )
                    : (<></>)
                }
                <div
                  key={`submission-evidence-${index + 1}`}
                  className="p-1 rounded-full bg-[#2D3D5A] cursor-pointer"
                  onClick={() => onUpload(record)}
                >
                  <FiUpload
                    size="18px"
                    className="text-white"
                  />
                </div>
              </div>
            )
        }
      </>
    )
  },
  {
    dataIndex: 'information',
    title: 'Information',
    render: (text, record, index) => (
      <>
        {
          isNaN(Number(record.number))
            ? (
              <></>
            )
            : (
              <TextArea
                key={`submission-info-${index + 1}`}
                rows={4}
                disabled={isDetailMode}
                value={record.information}
                onChange={(ev) => onInfoChange(ev.target.value, record)}
              />
            )
        }
      </>
    )
  }
];

const generateSubmissionData = (data) => {
  if (data) {
    const transformedData = [];
    
    data.data.forEach((category) => {
      transformedData.push({
        confident_level_parameter_id: category.confident_level_parameter_id,
        answer: null,
        number: category.category,
        question: null,
        rating_level_guidance: null,
        evidence: null,
        information: null
      });
      
      category.questions.forEach((question) => {
        transformedData.push({
          confident_level_parameter_id: question.confident_level_parameter_id,
          answer: question.answer,
          number: question.number,
          question: question.question,
          rating_level_guidance: question.rating_level_guidance,
          evidence: null,
          information: question.answer
            ? question.answer.information
            : null
        });
      });
    });
    
    return transformedData;
  }
  
  return [];
};

const generateBody = (
  isDraft,
  sourceData,
  data
) => {
  const selectedDefaultAnswer = (levelId, item) => {
    const selectedData = sourceData.data.map(
      (item) => item.questions.find(
        (question) => question.confident_level_parameter_id === levelId
      )
    );

    if (!isEmpty(selectedData[0])) {
      return selectedData[0].default_answer;
    }
    
    return item.answer;
  };

  return {
    confident_level_id: sourceData.confident_level_id,
    is_draft: isDraft,
    guidance_files: sourceData.guidance_files,
    questions: data.map((item) => ({
      confident_level_parameter_id: item.confident_level_parameter_id,
      question: item.question,
      answer: item.answer,
      default_answer: selectedDefaultAnswer(item.confident_level_parameter_id, item)
    })).filter((item) => Boolean(item.confident_level_parameter_id))
  };
};

export {
  generateSubmissionColumns,
  generateSubmissionData,
  generateBody
};