import {
  isEmpty,
  startCase,
  toLower
} from 'lodash';

import { useSelector } from 'react-redux';

const usePermissionManager = () => {
  const { user } = useSelector((state) => state.auth);
  const isUserAdmin = user?.roles?.some((role) => toLower(role.name) === 'administrator');

  const isUserHasPermissionInMenu = (isDesktop, menuName) => {
    if (isDesktop) {
      switch (true) {
        case toLower(menuName) === 'profile':
          return false;
        case isUserAdmin:
          return true;
        case isEmpty(user?.roles):
          return false;
        default:
          return user?.roles?.some((role) =>
            role.menus.some((menu) => toLower(menu.menu).includes(toLower(menuName)))
          );
      }
    }

    switch (true) {
      case toLower(menuName) === 'profile':
        return true;
      case isUserAdmin:
        return true;
      case isEmpty(user?.roles):
        return false;
      default:
        return user?.roles?.some((role) =>
          role.menus.some((menu) => toLower(menu.menu).includes(toLower(menuName)))
        );
    }
  };

  const isUserHasPermissionInFeature = (url, featureName) => {
    const baseUrl = url.split('/');
    const menuName = startCase(baseUrl[3]);

    if (isUserAdmin) {
      return true;
    }

    return user?.roles?.some((role) =>
      role.menus.some((menu) => toLower(menu.menu).includes(toLower(menuName))
        && menu.permissions.some(
          (permission) => Boolean(permission[toLower(featureName)])
        )
      )
    );
  };

  return { isUserHasPermissionInMenu, isUserHasPermissionInFeature };
};

export { usePermissionManager };