import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  input_actual: null
};

const drillingActual = createSlice({
  name: 'drillingActual',
  initialState,
  reducers: {
    saveState: (state, action) => {
      const { key, value } = action.payload;

      state[key] = value;
    },
    resetState: (state, action) => {
      const { key } = action.payload;

      state[key] = null;
    },
    resetAllState: (state) => {
      state = initialState;
    }
  }
});

export const {
  saveState,
  resetState,
  resetAllState
} = drillingActual.actions;
export default drillingActual.reducer;