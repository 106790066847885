import { Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { FiEye } from 'react-icons/fi';
import { TbPencil } from 'react-icons/tb';

const generateDrillingActualTable = (handleActionButton) => [
  {
    dataIndex: 'planning_name',
    title: 'Planning Name',
    width: '150px',
    sorter: true,
    render: (value) => {
      return <div>{value}</div>;
    },
  },
  {
    dataIndex: 'mine_site',
    title: 'Mine Site',
    width: '150px',
    sorter: true,
    render: (value) => {
      return <div>{value}</div>;
    },
  },
  {
    dataIndex: 'year_of_period',
    title: 'Year Of Period',
    width: '150px',
    sorter: true,
    render: (value) => {
      return  <div>{value}</div>;
    },
  },
  {
    dataIndex: 'drilling_stage',
    title: 'Drilling Stage',
    width: '150px',
    sorter: true,
    render: (value) => {
      const populateDrillingStage = () =>{
        if(value === 'PREPRODUCTION'){
          return 'Pre-production';
        }else{
          return 'Exploration';
        }
      };
      return <div>{populateDrillingStage()}</div>;
    },
  },
  {
    dataIndex: 'date',
    title: 'Date',
    width: '150px',
    sorter: true,
    render: (value) => {
      return  <div>{value? moment(value).format('DD/MMM/YYYY'): '-'}</div>;
    },
  },
  {
    dataIndex: 'status',
    title: 'Status',
    width: '150px',
    sorter: true,
    render: (value) => {
      if(value === 'Draft'){
        return <div className='bg-[#EBEBEB] text-[#AAAAAA] px-3 py-2 w-fit rounded-lg'>{value}</div>;
      } else {
        return <div className='bg-[#2BB8A410] text-[#2BB8A4] px-3 py-2 w-fit rounded-lg'>Submitted</div>;
      }
    },
  },
  {
    dataIndex: 'version',
    title: 'Version',
    width: '150px',
    sorter: true,
    render: (value) => <div>V{value}</div>
  },
  {
    dataIndex: 'action',
    title: 'Action',
    width: '100px',
    align:'center',
    sorter: false,   
    render: (_, record) =>
    {
      return  (
        <div className="flex flex-row justify-center items-center gap-x-3">
          <div
            className="rounded-full bg-[#2BB8A4] cursor-pointer"
            onClick={() => {handleActionButton(record.id, 'detail');}}
          >
            <Tooltip title={'View Detail'} trigger={'hover'}>
              <FiEye size="24px" className="p-1 text-white" />
            </Tooltip>
          </div>
          <div
            className="rounded-full bg-[#2BB8A4] cursor-pointer"
            onClick={() => {handleActionButton(record.id, 'edit');}}
          >
            <Tooltip title={'Edit'} trigger={'hover'}>
              <TbPencil
                size="24px"
                className="p-1 text-white"
              />
            </Tooltip>
          </div>
        </div>
      );}
  },
];

const generateDrillingActualListData = (actuals) => {
  let finalData = [];
  if(!isEmpty(actuals)){
    finalData = actuals?.data?.map(actual=>({
      id: actual.id,
      planning_name: 'Drilling Summary',
      mine_site: actual?.company,
      year_of_period: actual?.year,
      drilling_stage: actual?.plan_type,
      status: actual?.status,
      date: actual?.submitted_date,
      version: actual?.version
    }));
  }
  return finalData;
};

export { generateDrillingActualTable, generateDrillingActualListData };