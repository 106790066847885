import ButtonAccent from 'components/ButtonAccent';

const FilterContainer = (props) => {
  const {
    children,
    onReset = () => {},
    className
  } = props;

  const determineGridSize = () => {
    if (children) {
      return children.length >= 4
        ? 'grid-cols-3'
        : `grid-cols-${children.length}`;
    }

    return 'grid-cols-3';
  };
  
  return (
    <div className="p-5 flex flex-col bg-[#B1BACB20] rounded-lg">
      <span className="mb-5 font-bold text-lg">Filter</span>
      <div className="flex flex-row">
        <div className={`w-4/5 grid gap-3 ${determineGridSize()} ${className}`}>
          {children}
        </div>
        <div className="w-1/5 flex flex-col justify-end items-end">
          <ButtonAccent
            title="Reset"
            onClick={onReset}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterContainer;