import { sampleId } from 'constant/TableReconciliation';
import { generateMoistTable } from 'constant/TableReconciliation/TableMoist';
import TableDrillingSumarry from 'pages/DrillingPlan/DrillingSummary/TableDrillingSummary';

const Moist = (props) => {
  const { isDetailMode, moistData, onChange, onDeleteRow, otherData } = props;
  const columns = generateMoistTable(
    moistData,
    isDetailMode,
    onChange,
    onDeleteRow
  );

  const populateSampleId = () => {
    let finalData = moistData.map((data, index) => {
      return {
        sample_id: otherData.sample_information[index].sample_id,
        ...data 
      };
    });
    return finalData;
  };
  columns.splice(1, 0, sampleId);

  return <TableDrillingSumarry 
    columns={ columns }
    tableData={ populateSampleId() }
  />;
};

export default Moist;