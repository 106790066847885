import { Tooltip } from 'antd';
import moment from 'moment';
import { FiEye } from 'react-icons/fi';
import { TbPencil } from 'react-icons/tb';

const generateTopoColarValidationTable = (handleActionButton) => [
  {
    dataIndex: 'year',
    title: 'Year Of Period',
    width: '150px',
    sorter: true,
    render: (value) => {
      return  <div>{value}</div>;
    },
  },
  {
    dataIndex: 'minesite',
    title: 'Mine Site',
    width: '150px',
    sorter: true,
    render: (value) => {
      return <div>{value}</div>;
    },
  },
  {
    dataIndex: 'drilling_stage',
    title: 'Drilling Stage',
    width: '150px',
    sorter: true,
    render: (value) => {
      const populateDrillingStage = () =>{
        if(value === 'PREPRODUCTION'){
          return 'Pre-production';
        }else{
          return 'Exploration';
        }
      };
      return <div>{populateDrillingStage()}</div>;
    },
  },
  {
    dataIndex: 'submitted_date',
    title: 'Date',
    width: '150px',
    sorter: true,
    render: (value) => {
      return  <div>{value? moment(value).format('DD/MMM/YYYY'): '-'}</div>;
    },
  },
  {
    dataIndex: 'status',
    title: 'Status',
    width: '150px',
    sorter: true,
    render: (value) => {
      if(value === 'Draft'){
        return <div className='bg-[#EBEBEB] text-[#AAAAAA] px-3 py-2 w-fit rounded-lg'>{value}</div>;
      } else {
        return <div className='bg-[#2BB8A410] text-[#2BB8A4] px-3 py-2 w-fit rounded-lg'>Submitted</div>;
      }
    },
  },
  {
    dataIndex: 'version',
    title: 'Version',
    width: '150px',
    sorter: true,
    render: (value) => {
      return <div>V{value}</div>;
    },
  },
  {
    dataIndex: 'action',
    title: 'Action',
    width: '100px',
    align:'center',
    sorter: false,
    render: (_, record) =>
    {
      return  (
        <div className="flex flex-row justify-center items-center gap-x-3">
          <div
            className="rounded-full bg-[#2BB8A4] cursor-pointer"
            onClick={() => {handleActionButton(record.id, 'detail');}}
          >
            <Tooltip title={'View Detail'} trigger={'hover'}>
              <FiEye size="24px" className="p-1 text-white" />
            </Tooltip>
          </div>
          <div
            className="rounded-full bg-[#2BB8A4] cursor-pointer"
            onClick={() => {handleActionButton(record.id, 'edit');}}
          >
            <Tooltip title={'Edit'} trigger={'hover'}>
              <TbPencil
                size="24px"
                className="p-1 text-white"
              />
            </Tooltip>
          </div>
        </div>
      );}
  },
];

const topoCollarValidationCategories = [
  'Sample Information',
  'Resurvey',
  'Topo & Collar Value',
  'Variance',
  'Topo Version & Result'
];

const generateTopoCollarInitiateData = [
  {
    id: 0,
    drill_hole_name: null,
    month: null,
    pit_location: null,
    pit_year: null,
    resurvey_latitude: null,
    resurvey_longitude: null,
    resurvey_easting: null,
    resurvey_northing: null,
    collar_borehole_value: null,
    topo_grid_value: null,
    topo_version: null
  }
];

const generateBodyTopoCollarValidation = (payload) => {
  const minesite = {
    id: payload.minesite.id,
    alias_name: payload.minesite.alias_name,
    name: payload.minesite.name
  };

  const formData = new FormData();
  formData.append('files', payload.files ? payload.files : []);
  formData.append('year', payload.year);
  formData.append('minesite', JSON.stringify(minesite));
  formData.append('drilling_stage', payload.drilling_stage);
  formData.append('content', JSON.stringify({code:'topo_collar_validation', properties: payload.content}));
  formData.append('is_draft', payload.is_draft);
  formData.append('file_info', payload.files ? '' : JSON.stringify(payload.file_info));
  formData.append('version', payload.version);

  return formData;
};

export { 
  generateTopoColarValidationTable, 
  topoCollarValidationCategories, 
  generateTopoCollarInitiateData, 
  generateBodyTopoCollarValidation 
};