import Storage from 'redux-persist/lib/storage';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';

import AuthReducer from 'store/Auth';
import PreferencesReducer from 'store/Preferences';
import TableReducer from 'store/Table';
import AssumptionReducer from 'store/Assumption';
import DrillingActualReducer from 'store/DrillingActual';
import { Base } from 'api/Base';

const persistConfig = {
  key: 'root-oe',
  version: 1,
  transforms: [
    encryptTransform({
      secretKey: `${process.env.REACT_APP_BASE_SECRET}`
    })
  ],
  storage: Storage
};

const persistentReducer = persistReducer(
  persistConfig,
  combineReducers({
    [Base.reducerPath]: Base.reducer,
    auth: AuthReducer,
    preferences: PreferencesReducer,
    table: TableReducer,
    assumption: AssumptionReducer,
    drillingActual: DrillingActualReducer
  })
);

const store = configureStore({
  reducer: persistentReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER
        ]
      }
    }).concat([Base.middleware])
});

const persistentStore = persistStore(store);

export { persistentStore, store };