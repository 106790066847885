import ButtonAccent from 'components/ButtonAccent';
import CategoriesList from 'components/CategoriesList';
import UploadDocumentExcel from 'components/UploadExcel';
import ModalInformation from 'pages/DrillingPlan/components/ModalInformation';
import { useCallback, useEffect, useState } from 'react';
import { useTableWrapper } from 'utils/TableResponsive';
import { useLocation, useNavigate } from 'react-router';
import { getPathType } from 'utils/PathUtility';
import { useModalConfirmationContext } from 'components/ModalConfirmationProvider';
import { URL } from 'constant';
import PreventNavigation from 'constant/PreventNavigation';
import LoadingIcon from 'components/LoadingIcon';
import DrillHoleInformationModel from './DrillHoleInformation';
import DrillHoleValidationModel from './DrillHoleValidation';
import {
  generateBody,
  generateModelInitiateData,
  modelDrillHoleValidationCategories
} from 'constant/ModelDrillHoleValidation/ModelDrillHoleValidationData';
import _, { isEmpty, isNumber } from 'lodash';
import { useLazyGetDrillHoleNameListQuery } from 'api/DrillingSummary';
import { useLazyExportModelExcelQuery, useLazyGetModelByIdQuery, usePostModelMutation, useUploadModelExcelMutation } from 'api/ModelDrillHoleValidation';
import { toast } from 'react-toastify';
import { transformError } from 'utils/ErrorTransformer';
import moment from 'moment';
import { Tag } from 'antd';

const InputModelDrillHoleValidation = () => {
  let initiate = _.cloneDeep(generateModelInitiateData);
  const [data, setData] = useState(null);
  const [file, setFile] = useState(null);
  const [payload, setPayload] = useState(initiate);
  const [selectedCategory, setSelectedCategory] = useState('Drill Hole Information');
  const [transformErrorUpload, setTransformErrorUpload] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [plansData, setPlansData] = useState([]);
  const { wrapperWidth } = useTableWrapper();
  const [errorExcel, setErrorExcel] = useState(null);
  const location = useLocation();
  const modelId = new URLSearchParams(location.search).get('id');
  const inputActualData = location.state;
  const [year, setYear] = useState(null);
  const [company, setCompany] = useState({});
  const [drillingStage, setDrillingStage] = useState(null);
  const [planId, setPlanId] = useState(null);
  const [version, setVersion] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();
  const isDetailMode = getPathType(location) === 'detail';
  const isEditMode = getPathType(location) === 'edit';
  const {showModal, resetModal} = useModalConfirmationContext();

  const [
    getDrillHoleNameList,
    {
      data: drillHoleNameData,
      isFetching: drillHoleNameIsFetching,
      isError: drillHoleNameIsError,
      error: drillHoleNameError,
    },
  ] = useLazyGetDrillHoleNameListQuery({
    refetchOnMountOrArgChange: true
  });

  const [
    uploadModelExcel,
    {
      data: excelRes,
      isLoading: excelResIsLoading,
      isError: excelResIsError,
      error: excelResError  
    }
  ] = useUploadModelExcelMutation();

  const [postModel, {
    isLoading: postIsLoading,
    isSuccess: postIsSuccess,
    isError: postIsError,
    error: postError
  }] = usePostModelMutation();

  const [
    getModelById,
    {
      data: detailModelData,
      isFetching: detailModelIsFetching,
      isError: detailModelIsError,
      error: detailModelError
    }
  ] = useLazyGetModelByIdQuery(
    { refetchOnMountOrArgChange: true }
  );

  const [exportModelExcel, {
    isLoading: exportExcelIsLoading,
    isError: exportExcelIsError,
    error: exportExcelError
  }] = useLazyExportModelExcelQuery();

  const populateType = (type) => {
    if(type === 'PREPRODUCTION'){
      return 'Pre-production';
    }else{
      return 'Exploration';
    }
  };

  const handleBackButton = () => {
    if(isDetailMode) {
      navigate(`/${URL.ACTIVITY_LEVEL}/${URL.GEOLOGY_OE}/model-drill-hole-validation`);
    } else {
      showModal({
        isShown: true,
        type: 'confirmation',
        title:'Are you sure want to leave this page?',
        message: 'Changes you made may not be saved',
        onSubmit: () => {
          navigate(`/${URL.ACTIVITY_LEVEL}/${URL.GEOLOGY_OE}/model-drill-hole-validation`);
          resetModal();
        }
      });
    }
  };

  const downloadFile = (url) => {
    const docLink = document.createElement('a');
    docLink.href = url;
    document.body.appendChild(docLink);
    docLink.click();
    document.body.removeChild(docLink);
  };

  const handleDeleteFile = () => {
    setData(null);
  };

  const deleteDrillHoleName = (record) => {
    let dataClone = _.cloneDeep(payload);
    let result = [];
    dataClone.forEach(item => {
      let objectTemp = {};
      Object.keys(item).forEach(key => {
        objectTemp[key] = item[key]?.filter(sample=>sample.localId !== record.localId);
      });
      result.push(objectTemp);
    });
    setPayload(result);
  };

  const onAddType = (localId) => {
    let tempData = _.cloneDeep(payload);
    tempData.forEach((item, index) => {
      Object.keys(item).forEach((key, indexParent) => {
        item[key].forEach((child, indexChild)=>{
          let initiateChildren = tempData[index][key][indexChild].children[indexParent];
          if(child?.localId === localId){
            let payloadChildren = child?.children;
            let children = {
              ...initiateChildren,
              type: '',
              localChildrenId: payloadChildren[payloadChildren.length - 1]?.localChildrenId + 1 
            };
            if(children.localChildrenId !== 1){
              delete children.drill_hole_name;
            }
            child?.children.push(children);
          }
        });        
        
      });
    });
    setPayload([...tempData]);
  };

  const onDeleteType = (record) => {
    let tempData = _.cloneDeep(payload);
    tempData.forEach((item) => {
      Object.keys(item).forEach((key) => {
        item[key].forEach((child) => {
          if(child?.localId === record?.localId){
            let payloadChildren = child?.children;
            child.children = payloadChildren.filter(child=> child.localChildrenId !== record.localChildrenId);
          } 
        });
      });
    });
    setPayload([...tempData]);
  };

  const onChange = (category, localId, localChildrenId, attribute, value) => {
    let tempData = [...payload];
    let tempCategory = tempData.find((item)=> item[category]);
    let tempParent = tempCategory[category]?.find(item => item.localId === localId);
    if(['drill_hole_name', 'seam'].includes(attribute)){
      tempParent[attribute] = value || '';
    } else {
      let tempChild = tempParent?.children?.find(item => item.localChildrenId === localChildrenId);
      tempChild[attribute] = value || '';
    }
    setPayload([...tempData]);
  };

  const populateCategoriesModule = () => {
    switch(selectedCategory){
      case 'Drill Hole Information':
        return (
          <DrillHoleInformationModel
            isDetailMode={isDetailMode}
            payload={payload}
            onChange={onChange} 
            onAddType={onAddType}
            onDeleteType={onDeleteType}
            drillHoleNameProperties={{
              data: plansData,
              isFetching: drillHoleNameIsFetching,
            }}
            onDeleteRow={deleteDrillHoleName}
          />
        );
      case 'Drill Hole Validation':
        return (
          <DrillHoleValidationModel
            isDetailMode={isDetailMode}
            onChange={onChange}
            payload={payload} 
            onAddType={onAddType}
            onDeleteType={onDeleteType}
            onDeleteRow={deleteDrillHoleName}
          />
        );
      default:
        return;
    }
  };

  const onAddDrillHoleName = () => {
    let tempData = _.cloneDeep(payload);
    let result = [];
    tempData.forEach((item, index) => {
      let temp = Object.keys(item).map((key) => {
        let properties = generateModelInitiateData[index][key]?.map(temp => {
          return {
            ...temp,
            localId: item[key][item[key].length - 1].localId + 1
          };
        });
        return {  
          [key]:[   
            ...item[key],
            ...properties
          ]
        };
      });
      result.push(...temp);
    });
    setPayload(result);
  };

  const jsonParseMessage = () => {
    try {
      return JSON.parse(errorExcel.data.error.message);;  
    } catch{
      return null;
    }
  };

  const mappingExcelData = (excelData) => {
    let tempExcel = _.cloneDeep(excelData.properties);
    tempExcel?.forEach((parent) => {
      Object.keys(parent).forEach((key) => {
        parent[key].forEach((child, childIndex)=>{
          parent[key][childIndex] = {...child, localId: childIndex + 1};
          child.children.forEach((grandChild, grandIndex) => {
            const dh_data = tempExcel[1]?.drill_hole_validation[childIndex]?.children[grandIndex]?.drill_hole_data ?? 0;
            const model_data = tempExcel[1]?.drill_hole_validation[childIndex]?.children[grandIndex]?.model_data;
            const type = tempExcel[0]?.drill_hole_information[childIndex]?.children[grandIndex]?.type;
            let tempDiff = '';

            if(grandChild?.hasOwnProperty('diff')) {
              tempDiff = model_data ? parseFloat(dh_data - model_data) : '';
              grandChild.diff = tempDiff ? Number(tempDiff) : tempDiff;
            }

            if(grandChild?.hasOwnProperty('percent_diff')) {
              const tempPercentDiff = model_data ? parseFloat((tempDiff) / model_data): '';
              grandChild.percent_diff = tempPercentDiff ? Number(tempPercentDiff) : tempPercentDiff;
            }

            if(grandChild?.hasOwnProperty('model_drill_hole')) {
              const tempModelDH = (dh_data && model_data) 
                ? model_data < dh_data 
                  ? 'Yes' 
                  : 'No' 
                : 'No Data';
              grandChild.model_drill_hole = tempModelDH;
            }

            if(grandChild?.hasOwnProperty('result')) {
              let result = '';

              if (type === 'Thickness') {
                if (grandChild.model_drill_hole === 'Yes') {
                  if (grandChild.percent_diff > 0.2) {
                    result = 'IS > M (>20%)';
                  } else if (grandChild.percent_diff <= 0.2 && grandChild.percent_diff > 0.1) {
                    result = 'IS > M (10%-20%)';
                  } else if (grandChild.percent_diff <= 0.1 && grandChild.percent_diff >= 0) {
                    result = 'IS > M (10%)';
                  }
                }
                
                if (grandChild.model_drill_hole === 'No') {
                  if (grandChild.percent_diff < -0.2) {
                    result = 'M > IS (>-20%)';
                  } else if (grandChild.percent_diff >= -0.2 && grandChild.percent_diff < -0.1) {
                    result = 'M > IS (10%-20%)';
                  } else if (grandChild.percent_diff >= -0.1 && grandChild.percent_diff <= 0) {
                    result = 'M > IS (10%)';
                  }
                }
              }
            
              if (type === 'CV') {
                if (grandChild.model_drill_hole === 'Yes') {
                  if (grandChild.diff > 200 && grandChild.model_drill_hole) {
                    result = 'IS > M (>200 kcal)';
                  } else if (grandChild.diff <= 200 && grandChild.diff > 100) {
                    result = 'IS > M (100 kcal-200 kcal)';
                  } else if (grandChild.diff <= 100 && grandChild.diff >= 0) {
                    result = 'IS > M (100 kcal)';
                  }
                }
                
                if (grandChild.model_drill_hole === 'No') {
                  if (grandChild.diff < -200 && grandChild.model_drill_hole) {
                    result = 'M > IS (>-200 kcal)';
                  } else if (grandChild.diff >= -200 && grandChild.diff < -100) {
                    result = 'M > IS (100 kcal-200 kcal)';
                  } else if (grandChild.diff >= -100 && grandChild.diff <= 0) {
                    result = 'M > IS (100 kcal)';
                  }
                }
              }
            
              if (type === 'TS') {
                if (grandChild.model_drill_hole === 'Yes') {
                  if (grandChild.diff > 0.4) {
                    result = 'IS > M (>0.4)';
                  } else if (grandChild.diff <= 0.4 && grandChild.diff > 0.2) {
                    result = 'IS > M (0.2-0.4)';
                  } else if (grandChild.diff <= 0.2 && grandChild.diff >= 0) {
                    result = 'IS > M (0.2)';
                  }
                }
                
                if (grandChild.model_drill_hole === 'No') {
                  if (grandChild.diff < -0.4) {
                    result = 'M > IS (>0.4)';
                  } else if (grandChild.diff >= -0.4 && grandChild.diff < -0.2) {
                    result = 'M > IS (0.2-0.4)';
                  } else if (grandChild.diff >= -0.2 && grandChild.diff <= 0) {
                    result = 'M > IS (0.2)';
                  }
                }
              }
            
              if (type === 'Roof') {
                if (grandChild.model_drill_hole === 'Yes') {
                  if (grandChild.diff > 2) {
                    result = 'IS > M (>2m)';
                  } else if (grandChild.diff <= 2 && grandChild.diff > 1) {
                    result = 'IS > M (1m-2m)';
                  } else if (grandChild.diff <= 1 && grandChild.diff >= 0) {
                    result = 'IS > M (1m)';
                  }
                }
                
                if (grandChild.model_drill_hole === 'No') {
                  if (grandChild.diff < -2) {
                    result = 'M > IS (>2m)';
                  } else if (grandChild.diff >= -2 && grandChild.diff < -1) {
                    result = 'M > IS (1m-2m)';
                  } else if (grandChild.diff >= -1 && grandChild.diff <= 0) {
                    result = 'M > IS (1m)';
                  }
                }
              }
              
              grandChild.result = result;
            }
            
            child.children[grandIndex] = {...grandChild, localChildrenId: grandIndex};
          });
        });
      });
    });
    setPayload([...tempExcel]);
  };

  const mandatoryValidationDrillHoleInformation = () => {
    let validation = true;
    const tempPayload = payload.find(v => Object.keys(v).includes('drill_hole_information'));
    let temp = _.cloneDeep(tempPayload.drill_hole_information);
    temp.forEach((v) => {
      let keys = Object.keys(v);
      
      keys.forEach(key => {
        if (['drill_hole_name', 'seam'].includes(key) && !v[key]) {
          validation = false;
        }

        if (key === 'children') {
          v[key].forEach(child => {
            Object.entries(child).forEach(([cKey, cValue]) => {
              if((cKey === 'type' && !cValue)){
                validation = false;
              }
            });
          });
        }
      });
    });
    return validation;
  };

  const mandatoryValidationDrillHoleValidation = () => {
    let validation = true;
    const tempPayload = payload.find(v => Object.keys(v).includes('drill_hole_validation'));
    let temp = _.cloneDeep(tempPayload.drill_hole_validation);
    temp.forEach((v) => {
      let keys = Object.keys(v);
      
      keys.forEach(key => {
        if(key === 'children') {
          v[key].forEach(child => {
            Object.entries(child).forEach(([cKey, cValue]) => {
              if(
                !isNumber(cValue) &&
                (['drill_hole_data', 'model_data'].includes(cKey) && !cValue)
              ){
                validation = false;
              }
            });
          });
        }
      });
    });
    return validation;
  };

  const validateSubmit = () => {
    switch (false) {
      case mandatoryValidationDrillHoleInformation():
        toast.error('Some field is mandatory in Drill Hole Information',
          { toastId: 'validate-drill-hole-information-toast-error' }
        );

        setSelectedCategory('Drill Hole Information');
        return false;
      case mandatoryValidationDrillHoleValidation():
        toast.error('Some field is mandatory in Drill Hole Validation',
          { toastId: 'validate-drill-hole-validation-toast-error' }
        );
        
        setSelectedCategory('Drill Hole Validation');
        return false;
      default:
        return true;
    }
  };

  const onSubmit = (isDraft) => {
    if (!isDraft) {
      if (!validateSubmit()) {
        return;
      }
    }

    const fileInfo = data?.file_info || detailModelData?.file_info;
    const body = generateBody({
      payload: payload,
      files: file,
      year: Number(moment(year).format('YYYY')),
      minesite: company,
      file_info: file ? null : fileInfo ?? null,
      is_draft: isDraft,
      drilling_stage: drillingStage,
      version: version,
    });
    setSuccessMessage(isDraft ? 'you have successfully saved draft of model vs drill hole validation' 
      :'You have successfully submitted model vs drill hole validation');
    postModel({ body, planId });
  };

  const populatePlanInfo = () => {
    let validation = (isDetailMode || isEditMode) && !isEmpty(detailModelData);
    let year = validation? detailModelData?.year.toString() : inputActualData?.year; 
    let company = validation? detailModelData?.minesite : inputActualData?.company;
    let drilling_stage = validation? detailModelData?.drilling_stage : inputActualData?.drillingStage;
    let planId = validation? detailModelData?.goe_drilling_plan_id : inputActualData?.planId;
    let version = validation? detailModelData?.version : inputActualData?.latestVersion;
    setYear(year);
    setCompany(company);
    setDrillingStage(drilling_stage);
    setPlanId(planId);
    setVersion(version);
  };

  useEffect(() => {
    if (planId) {
      getDrillHoleNameList({ drillingPlanId: planId, version, params:{include_actual:true} });
    }
  }, [planId, version]);

  useEffect(() => {
    if(!isEmpty(drillHoleNameData)) {
      setPlansData(drillHoleNameData.data);
    }
  },[drillHoleNameData]);

  useEffect(() => {
    if (drillHoleNameIsError || detailModelIsError || exportExcelIsError) {
      const generateToastId = () => {
        switch (true) {
          case drillHoleNameError:
            return 'get-drill-hole-name';
          case detailModelError:
            return 'get-detail-model';
          case exportExcelError:
            return 'export-excel-model';
          default:
            return 'default';
        }
      };
      
      toast.error(
        transformError(drillHoleNameError || detailModelError || exportExcelError).message,
        { toastId: `${generateToastId()}-toast-error` }
      );
    }
  }, [drillHoleNameIsError, detailModelIsError, exportExcelIsError]);

  useEffect(() => {
    if (!isEmpty(errorExcel)) {
      setTransformErrorUpload(null);
      if (errorExcel.data) {
        const transformErrorUpload = errorExcel.status <= 400 ? jsonParseMessage(errorExcel.data.error.message) : null;
        setTransformErrorUpload(transformErrorUpload);
      }
      setIsModalOpen(true);
    }
  }, [errorExcel]);

  useEffect(() => {
    if(!isEmpty(data)){
      mappingExcelData(data);
    }
  },[data]);

  useEffect(() => {
    if(isDetailMode || isEditMode) getModelById({id: modelId});
  }, [isDetailMode, isEditMode]);

  useEffect(()=>{
    if(!isEmpty(detailModelData)){
      mappingExcelData(detailModelData?.content);
      setData({...detailModelData?.file_info, ...detailModelData?.content});
    }
  },[detailModelData]);

  useEffect(()=>{
    populatePlanInfo();
  },[inputActualData, isDetailMode, detailModelData, isEditMode]);

  const handleCloseSite = useCallback((ev) => ev.preventDefault() ,[]);
  
  useEffect(() => {
    PreventNavigation(isDetailMode, handleCloseSite);
  }, []);

  useEffect(() => {
    if (postIsSuccess) {
      toast.success(successMessage, { toastId: 'post-model-toast-success' });
      navigate(`/${URL.ACTIVITY_LEVEL}/${URL.GEOLOGY_OE}/model-drill-hole-validation`);
    }

    if (postIsError) {
      toast.error(
        transformError(postError).message,
        { toastId: 'post-model-toast-error' }
      );
    }
  }, [postIsSuccess, postIsError]);

  return (
    <div className="flex flex-col gap-4">
      {
        isDetailMode && isEmpty(data?.fileName)? <></> : (
          <div
            className="bg-white p-5 overflow-x-auto rounded-2xl"
            style={{ maxWidth: wrapperWidth }}
          >
            <div className=" mb-8 flex flex-row justify-between">
              <div className=" text-[18px] font-bold">Upload Data</div>
            </div>
            <UploadDocumentExcel
              onDownload={() => downloadFile(data.url)}
              uploadExcel={uploadModelExcel}
              isEditMode={isEditMode}
              isDetailMode={isDetailMode}
              planId={planId}
              latestVersion={version}
              getErrorExcel={setErrorExcel}
              onDelete={handleDeleteFile} 
              onChange={setData}
              getFile={setFile}
              value={data}
              excelRes={excelRes}
              excelResError={excelResError}
              excelResIsError={excelResIsError}
              excelResIsLoading={excelResIsLoading}
            />
          </div>
        )
      }
      <div
        className="bg-white p-5 overflow-x-auto rounded-2xl"
        style={{ maxWidth: wrapperWidth }}
      >
        <div className=" mb-8 flex flex-row justify-between">
          <div className='flex flex-row gap-2'>
            <ButtonAccent size={'2xl'} title="Download Template" 
              onClick={() => downloadFile(`${process.env.REACT_APP_BASE_URL}/Model_Drill_Holes_Validation_Template.xlsx`)}  
            />
            {isDetailMode && detailModelData?.status !== 'Draft' && (
              <ButtonAccent 
                isBordered 
                size={'2xl'} 
                title="Export Excel" 
                isLoading={exportExcelIsLoading}
                onClick={() => 
                  exportModelExcel({
                    id: modelId, 
                    company: company.alias_name, 
                    year: moment(year).format('YYYY'),
                    drillingStage: populateType(drillingStage)
                  })
                }  
              />
            )}
          </div>
          <Tag className="px-3 py-2 font-bold rounded-2xl bg-[#2BB8A4]/[0.1] text-[#01B59C] border-none">
            <span className='text-sm'>
              {
                `
                  V${version} /
                  ${moment(year).format('YYYY')} / 
                  ${company?.alias_name} / 
                  Model vs Drill Hole Validation / 
                  ${populateType(drillingStage)}
                `
              }
            </span>
          </Tag>
        </div>
        {(isEditMode || isDetailMode) && detailModelIsFetching ?
          (
            <LoadingIcon size={'sm'}/>
          ):(
            <> 
              {populateCategoriesModule()}
              <CategoriesList
                isActual={true}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                categories={modelDrillHoleValidationCategories}
                onAdd={onAddDrillHoleName}
                isDetailMode={isDetailMode}
              />
            </>
          )}
      </div>
      <div className="mt-5 flex flex-row items-center">
        { !isDetailMode && detailModelData?.status !== 'Submitted' && (
          <ButtonAccent isBordered title="Save As Draft" isLoading={postIsLoading} onClick={() => onSubmit(true)} /> 
        )
        }
        <div className="ml-auto flex flex-row items-center gap-x-3">
          <ButtonAccent isBordered isLoading={postIsLoading} title="Back" onClick={handleBackButton}/>
          { !isDetailMode && <ButtonAccent title="Next" isLoading={postIsLoading} onClick={() => onSubmit(false)} /> }
        </div>
      </div>
      <ModalInformation
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        errorMessage={
          !isEmpty(transformErrorUpload) ?
            transformErrorUpload?.invalid_on?.map((v, i) => {
              return (<div>
                <span>{`${i + 1}. Invalid data at row ${v.row}`}</span>
                <br />
                <span>{`and column ${v.column}`}</span>
              </div>);
            })
            : transformError(errorExcel).message
        }
      />
    </div>
  );
};

export default InputModelDrillHoleValidation;
