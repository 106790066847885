import { useEffect, useReducer } from 'react';
import { Fragment } from 'react';
import { Divider, Image } from 'antd';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, toLower } from 'lodash';

import { generateMenu } from 'constant/Menu';
import { EMPTY } from 'constant';
import { setSideBarExpandState } from 'store/Preferences';
import { usePermissionManager } from 'utils/PermissionManager';
import { useModalConfirmationContext } from 'components/ModalConfirmationProvider';

const NavSide = (props) => {
  const { className } = props;
  const { isSideBarExpanded } = useSelector((state) => state.preferences);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {showModal, resetModal} = useModalConfirmationContext();
  const { isUserHasPermissionInMenu } = usePermissionManager();

  const getMenus = () => {
    if (location.pathname.includes('mine-planning-oe')) {
      return generateMenu('mpoe').filter((item) => isUserHasPermissionInMenu(true, item.name));
    } else if (location.pathname.includes('geology-oe')) {
      return generateMenu('geology_oe').filter((item) => isUserHasPermissionInMenu(true, item.name));
    } else if (location.pathname.includes('geology-dashboard')){
      return generateMenu('geology_dashboard').filter((item) => isUserHasPermissionInMenu(true, item.name));
    } else if (location.pathname.includes('maintenance-oe')){
      return generateMenu('maintenance_oe').filter((item) => item);
    }
    return generateMenu();
  };

  const initialState = {
    desktopMenus: getMenus()
  };

  useEffect(() => {
    stateDispatch({ key: '' });
  }, [location.pathname]);

  const reducer = (state, action) => {
    const { key } = action;
    switch (key) {
      case 'child_visibility': {
        const { id } = action.payload;
        const newDesktopMenus = state.desktopMenus.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              isOpen: !item.isOpen
            };
          }
          
          return { ...item };
        });
        
        return {
          ...state,
          desktopMenus: newDesktopMenus
        };
      }
      default:
        return { 
          ...state, 
          desktopMenus: getMenus() 
        };
    }
  };

  const [state, stateDispatch] = useReducer(reducer, initialState);

  const isCurrentPath = (path) => {
    return path
      ? toLower(location.pathname).includes(toLower(path))
      : false;
  };

  const isChildActive = (item) => {
    let validation = false;
    item.children.forEach(child=>{
      if(!isEmpty(child.children)){
        validation = isChildActive(child);
      }
      if(isCurrentPath(child.url)){
        validation = true;
      }
    });
    return validation;
  };

  const isParentCurrentPath = (parent) => {
    if (isEmpty(parent.children)) {
      return false;
    }

    return parent.children.some((child) => !isEmpty(child.children) ? isParentCurrentPath(child) :isCurrentPath(child.url));
  };

  const goToPath = (path) => {
    if(location.pathname.includes('create') || location.pathname.includes('edit')) {
      showModal({
        isShown: true,
        type: 'confirmation',
        title:'Are you sure want to leave this page?',
        message: 'Changes you made may not be saved',
        onSubmit: () => {
          navigate(path);
          resetModal();
        }
      });
    } else{
      navigate(path);
    }
  };

  return (
    <div className={`p-3 flex flex-col rounded-lg bg-[#2D3D5A] ${className}`}>
      <div className={`flex flex-row ${isSideBarExpanded ? 'items-start':'items-center'} gap-2`}>
        <div className={`flex flex-col ${isSideBarExpanded && 'pl-4'} w-[194px] justify-center items-center`}>
          <Image
            preview={false}
            width={isSideBarExpanded ? 80 : 30}
            src={`${process.env.PUBLIC_URL}/assets/logo-oe.svg`}
          />
        </div>
        <div
          className="ml-auto p-1 rounded bg-white/25 cursor-pointer"
          onClick={() => dispatch(setSideBarExpandState(!isSideBarExpanded))}
        >
          <GiHamburgerMenu className="text-white rounded bg-white/25" />
        </div>
      </div>
      {isSideBarExpanded &&<div className='flex flex-col text-white text-center text-[16px] font-bold'>Operational Excellence <div>Data Platform</div></div>}
      <Divider  className='bg-[#3F4F6E] mb-7'/>
      <div className={`h-full overflow-auto mt-5 mb-auto flex flex-col gap-y-3 ${isSideBarExpanded ? '' : 'items-center'}`}>
        {
          state.desktopMenus.map((item, index) => (
            <Fragment key={`menu-${index + 1}`}>
              {
                isSideBarExpanded
                  ? (
                    <>
                      <div
                        className={`p-2 flex items-center gap-x-2 cursor-pointer
                        ${(isCurrentPath(item.url) || isParentCurrentPath(item))
                          ? 'rounded-lg bg-[#2BB8A4]'
                          : EMPTY.STRING}`}
                        onClick={() => isEmpty(item.children)
                          ? goToPath(item.url)
                          : stateDispatch({
                            key: 'child_visibility',
                            payload: { id: item.id }
                          })
                        }
                      >
                        {
                          item.icon(isCurrentPath(item.url))
                        }
                        <span className={`text-white ${isCurrentPath(item.url) || isChildActive(item) ? 'font-bold font-[Segoe-UI-Bold] text-[16px]' : EMPTY.STRING}`}>
                          {item.name}
                        </span>
                        {
                          !isEmpty(item.children) && (
                            <>
                              {
                                item.isOpen
                                  ? <BiChevronUp
                                    size="24px"
                                    className="!ml-auto text-white"
                                  />
                                  : <BiChevronDown
                                    size="24px"
                                    className="!ml-auto text-white"
                                  />
                              }
                            </>
                          )
                        }
                      </div>
                      {
                        (!isEmpty(item.children) && item.isOpen) && (
                          <div className="pr-3 py-3 flex flex-col gap-y-3 rounded-lg bg-white/5">
                            {
                              item.children.map((child, index) => (
                                <>
                                  <div
                                    key={`child-${index + 1}`}
                                    className="flex flex-row items-center gap-x-3 cursor-pointer"
                                    onClick={() => goToPath(child.url)}
                                  >
                                    <div className={`w-2 h-full rounded-r-full ${isCurrentPath(child.url) || (!isEmpty(child?.children) && isChildActive(child)) ? 'bg-[#F7B500]' : ''}`} />
                                    <div className={`w-2 h-2 rounded-full ${isCurrentPath(child.url)|| (!isEmpty(child?.children) && isChildActive(child)) ? 'bg-white' : 'bg-white/5 border-white border-[0.5px]'}`} />
                                    <div className={`w-full mt-2 ${isCurrentPath(child.url)|| (!isEmpty(child?.children) && isChildActive(child)) ? 'p-1 px-2 rounded-lg' : EMPTY.STRING}`}>
                                      <span className={isCurrentPath(child.url)|| (!isEmpty(child?.children) && isChildActive(child)) ? 'font-bold font-[Segoe-UI-Bold] text-white text-[16px]' : 'text-white'}>
                                        {child.name}
                                      </span>
                                    </div>
                                  </div>
                                  <div>
                                    {
                                      !isEmpty(child?.children) && (
                                        <div className='flex flex-col gap-y-4'>
                                          {child.children.map((grandChildren,index) => (
                                            <div
                                              key={`grand-child-${index + 1}`}
                                              className="flex flex-row items-center gap-x-4 cursor-pointer ml-5"
                                              onClick={() => goToPath(grandChildren.url)}
                                            >
                                              <div className={`w-2 h-full rounded-r-full ${isCurrentPath(grandChildren.url) ? 'bg-[#F7B500]' : ''}`} />
                                              <div className={`w-2 h-2 rounded-full ${isCurrentPath(grandChildren.url) ? 'bg-white' : 'bg-white/5 border-white'}`} />
                                              <div className={`w-full ${isCurrentPath(grandChildren.url) ? 'p-1 px-2 rounded-lg' : EMPTY.STRING}`}>
                                                <span className={isCurrentPath(grandChildren.url) ? 'font-bold font-[Segoe-UI-Bold] text-white text-[16px]' : 'text-white'}>
                                                  {grandChildren.name}
                                                </span>
                                              </div>
                                            </div>
                                          ))}
                                        </div> 
                                      )
                                    }
                                  </div>
                                </>
                              ))
                            }
                          </div>
                        )
                      }
                    </>
                  )
                  : (
                    <div
                      className={`p-2 cursor-pointer ${isCurrentPath(item.url) || isChildActive(item) ? 'rounded-lg bg-[#2BB8A4]' : EMPTY.STRING}`}
                      onClick={() => goToPath(item.url)}
                    >
                      {
                        item.icon(isCurrentPath(item.url))
                      }
                    </div>
                  )
              }
            </Fragment>
          ))
        }
      </div>
    </div>
  );
};

export default NavSide;