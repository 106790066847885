const ACTION = {
  VIEW: 'view',
  CREATE: 'create',
  EDIT: 'edit',
  DELETE: 'delete',
  DETAIL: 'detail',
  SUBMIT: 'submit',
  RE_SUBMIT: 'resubmit',
  APPROVE: 'approve',
  REVISE: 'revise',
  SWITCH_STATUS: 'switch_status'
};

const BREAKPOINTS = {
  LG: 1024
};

const CONFIDENT_LEVEL = {
  TYPE: {
    ALL: 'all',
    MINE_PLANNER: 'Mine Planner',
    PIC: 'PIC Confident Level'
  }
};

const DEFAULT = {
  UNKNOWN: 'unknown',
  MAX_STRING: 8,
  KEY_SORT_ASC: 'asc',
  KEY_SORT_DESC: 'desc',
  PAGE: 1,
  PAGE_SIZE: 10,
  SORT_ASC: 'ascend',
  SORT_DESC: 'descend',
  MULTI_ROLE: 'Multi roles'
};

const EMPTY = {
  STRING: '',
  NUMBER: 0,
  OBJECT: {},
  RECORD: '-'
};

const ERROR = {
  DEFAULT: 'Failed connect to server.',
  NO_ROLES: 'No roles found',
  FORM_NOT_FOUND: 'Cannot find the intended form, please contact your administrator',
  TIMEOUT: 'TIMEOUT_ERROR'
};

const FILES = {
  ACTION_CHANGE: 'change',
  ACTION_DROP: 'drop',
  PNG: 'image/png',
  JPG: 'image/jpg',
  JPEG: 'image/jpeg',
  MAX: 25 // unit in MB
};

const KEY = {
  LAST_ROUTE: 'lastRoute'
};

const PERMISSION = {
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'delete',
  ACKNOWLEDGE: 'acknowledge',
  APPROVE: 'approve'
};

const UNIT = {
  DAY: 'days',
  MONTH: 'months'
};

const URL = {
  ITM_LEVEL: 'itm-level',
  MINE_SITE_LEVEL: 'mine-site-level',
  ACTIVITY_LEVEL: 'activity-level',
  OE_ASSESSMENT: 'oe-assessment',
  EXECUTIVE_SUMMARY: 'executive-summary',
  GEOLOGY_OE: 'geology-oe',
  MINE_PLANNING_OE: 'mine-planning-oe',
  GEOLOGY_DASHBOARD: 'geology-dashboard',
  MAINTENANCE_OE: 'maintenance-oe'
};

const BREADCRUMB_MAP = {
  '/activity-level/mine-planning-oe/dashboard': 'Dashboard',
  '/activity-level/mine-planning-oe/assumption': 'Assumption',
  '/activity-level/mine-planning-oe/assumption/form/create': 'Create Assumption',
  '/activity-level/mine-planning-oe/assumption/form/detail': 'Detail Assumption',
  '/activity-level/mine-planning-oe/assumption/form/edit': 'Edit Assumption',
  '/activity-level/mine-planning-oe/assumption/production-loss-time/create': 'Production Loss Time',
  '/activity-level/mine-planning-oe/assumption/production-loss-time/detail': 'Production Loss Time',
  '/activity-level/mine-planning-oe/assumption/production-loss-time/edit': 'Production Loss Time',
  '/activity-level/mine-planning-oe/assumption/select-over-burden-unit/create': 'Select Overburden',
  '/activity-level/mine-planning-oe/assumption/select-over-burden-unit/detail': 'Select Overburden',
  '/activity-level/mine-planning-oe/assumption/select-over-burden-unit/edit': 'Select Overburden',
  '/activity-level/mine-planning-oe/assumption/over-burden-hours-capacity/create': 'Overburden Hours and Capacity',
  '/activity-level/mine-planning-oe/assumption/over-burden-hours-capacity/detail': 'Overburden Hours and Capacity',
  '/activity-level/mine-planning-oe/assumption/over-burden-hours-capacity/edit': 'Overburden Hours and Capacity',
  '/activity-level/mine-planning-oe/assumption/select-coal-unit/create': 'Select Coal Unit',
  '/activity-level/mine-planning-oe/assumption/select-coal-unit/detail': 'Select Coal Unit',
  '/activity-level/mine-planning-oe/assumption/select-coal-unit/edit': 'Select Coal Unit',
  '/activity-level/mine-planning-oe/assumption/coal-hours-capacity/create': 'Coal Hours and Capacity',
  '/activity-level/mine-planning-oe/assumption/coal-hours-capacity/detail': 'Coal Hours and Capacity',
  '/activity-level/mine-planning-oe/assumption/coal-hours-capacity/edit': 'Coal Hours and Capacity',
  '/activity-level/mine-planning-oe/assumption/pit-reserve/create': 'Pit Reserve',
  '/activity-level/mine-planning-oe/assumption/pit-reserve/detail': 'Pit Reserve',
  '/activity-level/mine-planning-oe/assumption/pit-reserve/edit': 'Pit Reserve',
  '/activity-level/mine-planning-oe/assumption/over-burden-hauler/create': 'Overburden Hauler',
  '/activity-level/mine-planning-oe/assumption/over-burden-hauler/detail': 'Overburden Hauler',
  '/activity-level/mine-planning-oe/assumption/over-burden-hauler/edit': 'Overburden Hauler',
  '/activity-level/mine-planning-oe/assumption/main-plan-scenario/create': 'Main Plan Scenario',
  '/activity-level/mine-planning-oe/assumption/main-plan-scenario/detail': 'Main Plan Scenario',
  '/activity-level/mine-planning-oe/assumption/main-plan-scenario/edit': 'Main Plan Scenario',
  '/activity-level/mine-planning-oe/assumption/summary-production/create': 'Summary Production',
  '/activity-level/mine-planning-oe/assumption/summary-production/detail': 'Summary Production',
  '/activity-level/mine-planning-oe/assumption/summary-production/edit': 'Summary Production',
  '/activity-level/mine-planning-oe/confident-level': 'Confident Level',
  '/activity-level/mine-planning-oe/confident-level/initiation/create': 'Initiation',
  '/activity-level/mine-planning-oe/confident-level/initiation/edit': 'Edit Initiation',
  '/activity-level/mine-planning-oe/confident-level/submission-mine-plan/detail': 'Submission Main Plan',
  '/activity-level/mine-planning-oe/confident-level/submission-pic/create': 'Submission',
  '/activity-level/mine-planning-oe/confident-level/submission-pic/detail': 'Submission',
  '/activity-level/mine-planning-oe/confident-level/submission-pic/edit': 'Submission',
  '/activity-level/mine-planning-oe/user-management': 'User Management',
  '/activity-level/mine-planning-oe/user-management/form/create': 'Create User',
  '/activity-level/mine-planning-oe/user-management/form/edit': 'Edit User',
  '/activity-level/mine-planning-oe/group-management': 'Group Management',
  '/activity-level/mine-planning-oe/group-management/form/create': 'Create Group',
  '/activity-level/mine-planning-oe/group-management/form/edit': 'Edit Group',
  '/activity-level/mine-planning-oe/profile': 'Profile',
  '/activity-level/mine-planning-oe/logs': 'Logs',
  '/activity-level/geology-oe/drilling-plan': 'Drilling Plan',
  '/activity-level/geology-oe/drilling-plan/create-planning/create': 'Create Planning',
  '/activity-level/geology-oe/drilling-plan/create-planning/edit': 'Edit Planning',
  '/activity-level/geology-oe/drilling-plan/create-planning/detail': 'Detail Planning',
  '/activity-level/geology-oe/drilling-plan/quality-checklist/create': 'Create Quality Checklist',
  '/activity-level/geology-oe/drilling-plan/quality-checklist/edit': 'Edit Quality Checklist',
  '/activity-level/geology-oe/drilling-plan/quality-checklist/detail': 'Detail Quality Checklist',
  '/activity-level/geology-oe/drilling-plan/permit-timeline/create': 'Create Permit Timeline',
  '/activity-level/geology-oe/drilling-plan/permit-timeline/edit': 'Edit Permit Timeline',
  '/activity-level/geology-oe/drilling-plan/permit-timeline/detail': 'Detail Permit Timeline',
  '/activity-level/geology-oe/drilling-plan/drilling-summary/create': 'Create Drilling Summary',
  '/activity-level/geology-oe/drilling-plan/drilling-summary/edit': 'Edit Drilling Summary',
  '/activity-level/geology-oe/drilling-plan/drilling-summary/detail': 'Detail Drilling Summary',
  '/activity-level/geology-oe/summary-drilling': 'Summary Drilling',
  '/activity-level/geology-oe/summary-drilling/input-actual/create': 'Create Input Actual',
  '/activity-level/geology-oe/summary-drilling/summary-drilling-actual/create': 'Actual Drilling Summary',
  '/activity-level/geology-oe/summary-drilling/summary-drilling-actual/detail': 'Detail Actual Drilling Summary',
  '/activity-level/geology-oe/summary-drilling/summary-drilling-actual/edit': 'Edit Actual Drilling Summary',
  '/activity-level/geology-oe/reconciliation': 'Reconciliation',
  '/activity-level/geology-oe/reconciliation/input-plan/create': 'Input Plan',
  '/activity-level/geology-oe/reconciliation/input-actual/create': 'Input Actual',
  '/activity-level/geology-oe/reconciliation/input-actual/detail': 'Detail Actual',
  '/activity-level/geology-oe/reconciliation/input-actual/edit': 'Edit Actual',
  '/activity-level/geology-oe/core-recovery-of-coal': 'Core Recovery Of Coal',
  '/activity-level/geology-oe/core-recovery-of-coal/input-plan/create': 'Input Plan',
  '/activity-level/geology-oe/core-recovery-of-coal/input-actual/create': 'Input Actual',
  '/activity-level/geology-oe/core-recovery-of-coal/input-actual/detail': 'Detail Actual',
  '/activity-level/geology-oe/core-recovery-of-coal/input-actual/edit': 'Edit Actual',
  '/activity-level/geology-oe/topo-collar-validation': 'Topo vs Collar Validation',
  '/activity-level/geology-oe/topo-collar-validation/input-plan/create': 'Input Plan',
  '/activity-level/geology-oe/topo-collar-validation/input-actual/create': 'Input Actual',
  '/activity-level/geology-oe/topo-collar-validation/input-actual/edit': 'Edit Actual',
  '/activity-level/geology-oe/topo-collar-validation/input-actual/detail': 'Detail Actual',
  '/activity-level/geology-oe/model-drill-hole-validation': 'Model vs Drill Hole Validation',
  '/activity-level/geology-oe/model-drill-hole-validation/input-plan/create': 'Input Plan',
  '/activity-level/geology-oe/model-drill-hole-validation/input-actual/create': 'Input Actual',
  '/activity-level/geology-oe/model-drill-hole-validation/input-actual/detail': 'Detail Actual',
  '/activity-level/geology-oe/model-drill-hole-validation/input-actual/edit': 'Edit Actual',
  '/activity-level/geology-oe/key-highlight-improvement': 'Key Highlight & Improvement',
  '/activity-level/geology-dashboard': 'OME',
  '/activity-level/maintenance-oe/ome-ap': 'OME AP',
  '/activity-level/maintenance-oe/plan-stop': 'Plan Stop',
  '/activity-level/maintenance-oe/ome-q': 'OME Q',
  '/activity-level/maintenance-oe/oe': 'OE',
  '/itm-level': 'ITM Level',
  '/mine-site-level': 'Mine Site Level',
  '/activity-level': 'Activity Level',
  '/oe-assessment': 'OE Assessment',
  '/executive-summary': 'Executive Summary'
};

export {
  ACTION,
  BREAKPOINTS,
  CONFIDENT_LEVEL,
  DEFAULT,
  EMPTY,
  ERROR,
  FILES,
  KEY,
  PERMISSION,
  UNIT,
  URL,
  BREADCRUMB_MAP
};