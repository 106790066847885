import { DatePicker, Form, Select } from 'antd';
import { useGetMasterDataGoeCompaniesQuery } from 'api/MasterData';
import ButtonAccent from 'components/ButtonAccent';
import SelectSearchAccent from 'components/SelectSearchAccent';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useModalConfirmationContext } from 'components/ModalConfirmationProvider';
import PreventNavigation from 'constant/PreventNavigation';
import ModalInformation from 'pages/DrillingPlan/components/ModalInformation';
import { ACTION } from 'constant';
import { getFullPath } from 'utils/PathUtility';
import { createSearchParams } from 'react-router-dom';
import { useValidateModelMutation } from 'api/ModelDrillHoleValidation';
import { transformError } from 'utils/ErrorTransformer';

const ModelDrillHoleValidationForm = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [informationModal, setInformationModal] = useState(false);
  const {showModal, resetModal} = useModalConfirmationContext();
  
  const { 
    data: dataCompanies,
    isFetching: companiesIsFetching
  } = useGetMasterDataGoeCompaniesQuery({
    refetchOnMountOrArgChange: true
  });

  const handleBackButton = () => {
    showModal({
      isShown: true,
      type: 'confirmation',
      title:'Are you sure want to leave this page?',
      message: 'Changes you made may not be saved',
      onSubmit: () => {
        navigate(-1);
        resetModal();
      }
    });
  };

  const handleCloseSite = useCallback((ev) => ev.preventDefault() ,[]);
  
  useEffect(() => {
    PreventNavigation(false, handleCloseSite);
  }, []);

  const [
    validatePlan,
    {
      data: planData,
      isLoading: validatePlanIsLoading,
      isError: validatePlanIsError,
      error: validatePlanError,
      isSuccess: validatePlanIsSuccess
    },
  ] = useValidateModelMutation();

  const onFinish = (values) => {
    const company = dataCompanies.find((val) => val.id === values.company);
    const payload = {
      year: moment(new Date(values.year)).format('YYYY'),
      minesite: {
        id: company.id,
        name: company.name,
        alias_name: company.alias_name,
      },
      drilling_stage: values.drilling_stage
    };
    validatePlan(payload);
  };

  const populateState = () => {
    let company = dataCompanies.find((val) => val.id === form.getFieldValue('company'));
    let year = moment(new Date(form.getFieldValue('year'))).format('YYYY');
    let drillingStage = form.getFieldValue('drilling_stage');
    let planId = planData?.goe_drilling_plan_id;
    let latestVersion = planData?.latest_version;
    return {company, year, drillingStage, planId, latestVersion};
  };

  const goToPath = (id, type, child)=> navigate({
    pathname: getFullPath({
      parent: 'model-drill-hole-validation',
      child: child,
      type
    },'geology-oe'),
    ...(id)
      ? { search: createSearchParams({ id }).toString() }
      : undefined
  },{ state: { ...populateState()  } });

  useEffect(() => {
    if (validatePlanIsError) {
      setInformationModal(true);
      return;
    }
  }, [validatePlanIsError]);

  useEffect(() => {
    if (validatePlanIsSuccess && planData) {
      goToPath(null, ACTION.CREATE, 'input-actual');
    }
  }, [validatePlanIsSuccess, planData]);

  return (
    <div>
      <Form form={form} onFinish={onFinish}>
        <div className="flex grid-cols-3 bg-white p-6 gap-6 rounded-2xl items-center justify-center">
          <label className="w-full">
            <span className="font-bold">Year</span>
            <Form.Item
              name="year"
              rules={[
                {
                  required: true,
                  message: 'Please input Year',
                },
              ]}
            >
              <DatePicker.YearPicker
                allowClear
                disabledDate={(current) => current.isBefore(moment())}
                className="mt-2 w-full h-12"
              />
            </Form.Item>
          </label>
          <label className="w-full">
            <span className="font-bold">Mine Site</span>
            <Form.Item
              name="company"
              rules={
                [
                  {
                    required: true,
                    message: 'Please input Mine Site',
                  },
                ]
              }
            >
              <SelectSearchAccent
                labelKey="alias_name"
                valueKey="id"
                options={dataCompanies}
                placeholder="Select mine site"
                isLoading={companiesIsFetching}
                className={'h-12 mt-2'}
              />
            </Form.Item>
          </label>
          <div className="w-full">
            <span className="font-bold">Drilling Stage</span>
            <Form.Item
              name="drilling_stage"
              rules={[
                {
                  required: true,
                  message: 'Please input Drilling Stage',
                },
              ]}
            >
              <Select
                allowClear
                placeholder="Select drilling stage"
                options={[
                  { label: 'Pre-production', value: 'PREPRODUCTION' },
                  { label: 'Exploration', value: 'EXPLORATION' },
                ]}
                className="mt-2 w-full h-12"
              />
            </Form.Item>
          </div>
        </div>
        <div className="flex flex-row justify-between mt-16">
          <ButtonAccent
            isBordered
            title="Cancel"
            isLoading={validatePlanIsLoading}
            onClick={handleBackButton}
          />
          <ButtonAccent
            title="Next"
            htmlType="submit"
            isLoading={validatePlanIsLoading}
          />
        </div>
      </Form>
      <ModalInformation
        isModalOpen={informationModal}
        setIsModalOpen={setInformationModal}
        errorMessage={`${transformError(validatePlanError).message}`}
      />
    </div>
  );
};

export default ModelDrillHoleValidationForm;
