const CODE = {
  ASSUMPTION: 'ASSUMPTION_MPOE',
  PERIOD: 'PERIOD_CATEGORY',
  UNIT: 'EQUIPMENT_UNIT'
};

const TYPE = {
  OVER_BURDEN: 'OB',
  COAL: 'COAL'
};

export { CODE, TYPE };