import { Input, Select } from 'antd';
import SelectSearchAccent from 'components/SelectSearchAccent';
import { EMPTY } from 'constant';
import { GoPlus } from 'react-icons/go';
import { RxCross2 } from 'react-icons/rx';
import { isEmpty } from 'lodash';
import { calculateResult } from './TableDrillHoleValidation';

const generateDrillHoleInformationModelTable = (
  isDetailMode,
  payload, 
  drillHolenameProperties, 
  onChange, 
  onDeleteRow,
  onAddType,
  onDeleteType,
  showModal,
  resetModal
) => [
  {
    dataIndex: 'action',
    title: 'Action',
    width: 5,
    sorter: false,
    align: 'center',
    fixed: 'left',
    render: (_, record, __) => {
      let isParent = record?.hasOwnProperty('drill_hole_name');   
      let dataDrillHoleName = payload.filter(item => item?.hasOwnProperty('drill_hole_name')); 
      return (
        <div className="flex justify-center">
          {payload.length <= 1 || dataDrillHoleName.length <= 1 ? (
            <></>
          ) : (isParent && !isDetailMode) && (
            <RxCross2
              size={28}
              color={'red'}
              className={'rounded-full p-1 cursor-pointer bg-red-200 '}
              onClick={() => {
                onDeleteRow(record);
              }}
            /> 
          )}
        </div>
      );
    },
  },
  {
    dataIndex: 'drill_hole_name',
    title: 'Drill Hole Name',
    width: '166px',
    align: 'center',
    fixed: 'left',
    sorter: false,
    render: (value, record, index) => {
      let isParent = record?.hasOwnProperty('drill_hole_name');
      let choosedDrillHoleName = payload?.map(item=>item.drill_hole_name !== '' && item.drill_hole_name);
      let drillHoleNames = drillHolenameProperties.data.map(item => item.drill_hole_name);
      let options = drillHoleNames.filter(item => !choosedDrillHoleName?.includes(item) );
      let drillHoleIsFetching = drillHolenameProperties.isFetching;
      return isParent && (
        <SelectSearchAccent
          isSingle={true}
          isDisabled={isDetailMode}
          value={value || undefined}
          placeholder="Select"
          options={options}
          isLoading={drillHoleIsFetching}
          className={'w-full text-left'}
          onChange={(val) => {            
            let choosedDrillHole = drillHolenameProperties.data.find(item=>item.drill_hole_name === val);
            let totalChildren = payload[payload.length-1].localChildrenId;
            if(record.drill_hole_name && val !== record.drill_hole_name){
              showModal({
                isShown: true,
                type: 'confirmation',
                title:'Are you sure you want to change the drill hole data?',
                message: 'This action cannot be undone. Please confirm your decision.',
                onSubmit: () => {
                  onChangeRelatedData(onChange, record, val, 'drill_hole_name');
                  for(let i = 1; i <= totalChildren ; i++) {
                    populateDataFromDrillHoleName(onChange, record, i, choosedDrillHole);
                  }
                  resetModal();
                }
              });
            } else {
              onChangeRelatedData(onChange, record, val, 'drill_hole_name' );
              for(let i = 1; i <= totalChildren ; i++) {
                populateDataFromDrillHoleName(onChange, record, i, choosedDrillHole);
              }
            }
          }}
          isError={!isDetailMode && !value}
        />
      );
    },
  },
  {
    dataIndex: 'seam',
    title: 'Seam',
    width: '166px',
    align: 'center',
    fixed: 'left',
    sorter: false,
    render: (value, record, index) => {
      let isParent = record?.hasOwnProperty('drill_hole_name');
      return isParent && (
        <Input
          placeholder={'Enter Seam'}
          value={value}
          onChange={(e) => {
            onChangeRelatedData(onChange, record, e.target.value, 'seam');
          }}
          disabled={isDetailMode}
          status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
        /> 
      );
    },
  },
  {
    dataIndex: 'type',
    title: 'Type',
    width: '166px',
    align: 'center',
    fixed: 'left',
    sorter: false,
    render: (value, record, index) => {
      let isParent = record?.hasOwnProperty('drill_hole_name');
      return (
        <div className='flex flex-row gap-2'>
          {
            !isDetailMode && 
            <>
              {!isParent? (
                <RxCross2
                  size={28}
                  color={'red'}
                  className={'rounded-full p-1 cursor-pointer bg-red-200 '}
                  onClick={() => onDeleteType(record)}
                />
              ):(
                <GoPlus
                  size={28}
                  color={'green'}
                  className={'rounded-full p-1 cursor-pointer bg-green-200'}
                  onClick={() => {
                    onAddType(record?.localId);
                  }} 
                />
              )}
            </>
          }
          <Select
            allowClear
            className={'w-full text-left'}
            placeholder={'Select Type'}
            value={value || undefined}
            onChange={(val) => {
              onChangeRelatedData(onChange, record, val, 'type');
              calculateResult(
                record,
                onChange,
                record.diff,
                record.percent_diff,
                record.model_drill_hole,
                payload[index].type
              );
            }}
            disabled={isDetailMode}
            status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
            options={[
              { label: 'Roof', value: 'Roof' },
              { label: 'Thickness', value: 'Thickness' },
              { label: 'CV', value: 'CV' },
              { label: 'TS', value: 'TS' },
            ]}
          />
        </div>
      );
    },
  },
  {
    dataIndex: 'month',
    title: 'Month',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {value}
        </div>
      );
    },
  },
  {
    dataIndex: 'model_version',
    title: 'Model Version',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value, record) => {
      return (
        <Input
          placeholder={'Enter Model Version'}
          value={value}
          onChange={(e) => {
            onChange('drill_hole_information', record?.localId, record?.localChildrenId, 'model_version', e.target.value);
          }}
          disabled={isDetailMode}
          status={!isDetailMode && !value ? 'error' : EMPTY.STRING}
        /> 
      );
    },
  },
  {
    dataIndex: 'pit_location',
    title: 'Pit Location',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {value}
        </div>
      );
    },
  },
  {
    dataIndex: 'pit_year',
    title: 'Pit Year',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {value}
        </div>
      );
    },
  },
  {
    dataIndex: 'resurvey_latitude',
    title: 'Resurvey Latitude',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {value}
        </div>
      );
    },
  },
  {
    dataIndex: 'resurvey_longitude',
    title: 'Resurvey Longitude',
    width: '196px',
    sorter: false,
    align: 'center',
    render: (value) => {
      return (
        <div className=' flex items-center justify-center text-[12px] border border-[#CECECE] bg-[#DEDEDE] h-7 rounded-md'>
          {value}
        </div>
      );
    },
  },
];

const populateDataFromDrillHoleName = (onChange, record, localChildrenId, choosedDrillHole) => {
  onChange('drill_hole_information', record?.localId, localChildrenId, 'month', choosedDrillHole?.month);
  onChange('drill_hole_information', record?.localId, localChildrenId, 'pit_location', choosedDrillHole?.pit_location);
  onChange('drill_hole_information', record?.localId, localChildrenId, 'pit_year', choosedDrillHole?.pit_year);
  onChange('drill_hole_information', record?.localId, localChildrenId, 'resurvey_latitude', choosedDrillHole?.resurvey_latitude);
  onChange('drill_hole_information', record?.localId, localChildrenId, 'resurvey_longitude', choosedDrillHole?.resurvey_longitude);
};

const onChangeRelatedData = (onChange, record, val, attribute) => {
  onChange('drill_hole_information', record?.localId, record?.localChildrenId, attribute, val);
  onChange('drill_hole_validation', record?.localId, record?.localChildrenId, attribute, val);
};

const generateDrillHoleInformationData = (data) => {
  if(!isEmpty(data)) {
    let dataDrillHoleInformation = data?.find(item => item.drill_hole_information)?.drill_hole_information;
    let result = [];
    dataDrillHoleInformation?.forEach((information, indexParent) => {
      information?.children?.forEach((child, index) => {
        result.push({
          ...(index === 0) && {drill_hole_name:information.drill_hole_name},
          ...child,
          seam: information?.seam,
          localId: information?.localId || indexParent + 1
        });
      });
    });
    return result;
  }
};

export { generateDrillHoleInformationModelTable, generateDrillHoleInformationData };