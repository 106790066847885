import { drillHoleName } from 'constant/TableDrillingSummary';
import { generateDrillingTimeDepthAnalysisTable } from 'constant/TableDrillingSummaryActual/TableTimeDepthAnalysis';
import TableDrillingSumarry from 'pages/DrillingPlan/DrillingSummary/TableDrillingSummary';

const TimeDepthAnalysisActual = (props) => {
  const {isDetailMode, timeDepthAnalysisActualData, onDeleteRow, otherData} = props;
  const columns = generateDrillingTimeDepthAnalysisTable(
    timeDepthAnalysisActualData,
    isDetailMode, 
    onDeleteRow
  );
  columns.splice(1, 0, drillHoleName);
  const populateDrillHoleName = () => {
    let finalData = timeDepthAnalysisActualData.map((data, index) => {
      return {
        ...data, 
        drill_hole_name: otherData.drill_hole_information[index].drill_hole_name,
      };
    });
    return finalData;
  };
  return <TableDrillingSumarry columns={columns} tableData={populateDrillHoleName()}/>;
};

export default TimeDepthAnalysisActual;