import { Base } from './Base';

const Reconciliation = Base.injectEndpoints({
  endpoints: (build)=>({
    validateReconciliation: build.mutation({
      query: (body) => ({
        url: '/goe/reconciliations/validate',
        method: 'POST',
        body
      })
    }),
    uploadReconciliationExcel: build.mutation({
      query: ({body}) => ({
        url: '/goe/reconciliations/upload-excel',
        method: 'POST',
        body,
        // 10 minutes of upload timeout
        timeout: Number(10 * (60 * 1000))
      })
    }),
    getReconciliationList: build.query({
      query: (params) => ({
        url: '/goe/reconciliations',
        method: 'GET',
        ...params
          ? {params}
          : undefined
      })
    }),
    getReconciliationById: build.query({
      query: ({id}) =>({
        url: `/goe/reconciliations/${id}`,
        method: 'GET'
      })
    }),
    postReconciliation: build.mutation({
      query: ({ body }) => ({
        url: '/goe/reconciliations',
        method: 'POST',
        body
      })
    }),
    exportExcel: build.query({
      query:({id, company, year})=>({
        url:`/goe/reconciliations/${id}/export-excel`,
        method: 'GET',
        responseHandler: async (response) => {
          const docLink = document.createElement('a');
          docLink.href = window.URL.createObjectURL(await response.blob());
          docLink.target = '_blank';
          docLink.download = `reconciliation_${year}_${company}.xlsx`;
          docLink.click();
        },
        cache: 'no-cache',
      })
    })
  }),
  
});

export const {
  useValidateReconciliationMutation,
  useUploadReconciliationExcelMutation,
  useGetReconciliationListQuery,
  useLazyGetReconciliationByIdQuery,
  usePostReconciliationMutation,
  useLazyExportExcelQuery
} = Reconciliation;

