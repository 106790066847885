import {
  createContext,
  useContext,
  useRef,
  useState
} from 'react';
import { Image, Input } from 'antd';

import { EMPTY } from 'constant';

import ButtonAccent from 'components/ButtonAccent';

const ModalCommentContext = createContext();
const { TextArea } = Input;
const ModalCommentProvider = (props) => {
  const { children } = props;
  
  const initialState = {
    isShown: false,
    message: EMPTY.STRING,
    isShowDescription: true,
    onSubmit: () => {}
  };
  const [modalData, setModalData] = useState(initialState);

  const commentRef = useRef(EMPTY.STRING);
  
  const showCommentModal = (data) => {
    setModalData(data);
  };
  
  const resetCommentModal = () => {
    setModalData(initialState);
  };
  
  return (
    <ModalCommentContext.Provider value={{ showCommentModal, resetCommentModal }}>
      {
        modalData.isShown && (
          <div className="w-full h-full absolute flex flex-col items-center justify-center bg-black/25 left-0 top-0 z-20">
            <div className="p-5 flex flex-col items-center justify-center bg-white rounded-lg">
              <Image
                preview={false}
                width={100}
                src={`${process.env.PUBLIC_URL}/assets/icon-modal-confirmation.svg`}
                className="mb-5"
              />
              <span className="mb-2 font-bold text-xl">
                Confirmation!
              </span>
              <span className="w-64 text-center text-gray-500/50">
                {modalData.message}
              </span>

              {
                modalData.isShowDescription && (
                  <div className="mt-5">
                    <span className="font-bold">Description</span>
                    <TextArea
                      rows={6}
                      placeholder="Enter description"
                      className="mt-2"
                      onChange={(ev) => { commentRef.current = ev.target.value; }}
                    />
                  </div>
                )
              }

              <div className="mt-5 flex flex-row items-center justify-center gap-x-5">
                <ButtonAccent
                  isBordered
                  title="Cancel"
                  onClick={() => showCommentModal(initialState)}
                />
                <ButtonAccent
                  title={modalData.isShowDescription ? 'Yes, Revise' : 'Yes, Approve'}
                  onClick={() => modalData.onSubmit(commentRef.current)}
                />
              </div>
            </div>
          </div>
        )
      }
      {children}
    </ModalCommentContext.Provider>
  );
};

const useModalCommentContext = () => useContext(ModalCommentContext);

export default ModalCommentProvider;
export { useModalCommentContext };